export default {
	steps: {
		billableParty: {
			title: 'Parte soggetta a fatturazione',
			virtualPartnerTitle: 'Nuovo cliente potenziale',
		},
		product: {
			title: 'Prodotto',
			isRenewal: 'È un rinnovo?',
		},
		discounts: {
			title: 'Sconti',
			discretionary: 'Sconto discrezionale',
			special: 'Sconto speciale',
			tier: 'Sconto a livelli',
			segment: 'Sconto segmento',
		},
		price: {
			title: 'Prezzo',
			appliedPromotions: 'Promozioni applicate',
		},
	},
	message: {
		countryCodeNotSelected: 'Seleziona prima il codice del paese',
		requiredStepsNotFulfilled: 'Seleziona una parte soggetta a fatturazione e un prodotto per visualizzare i prezzi',
	},
	goalSeekedPrice: {
		title: 'Prezzo unitario obiettivo',
		originalPrice: 'Prezzo unitario originale',
		finalPrice: 'Prezzo unitario finale',
	},
};
