export default {
	common: {
		discountWaitingToApprove: '此折扣正在等待您的銷售經理核准。',
		selectCurrencyModalTitle: '選取客戶貨幣',
		otherCustomerInformation: '其他',
	},
	entity: {
		contactId: '客戶 ID',
		customerType: '客戶類型',
		discount: '折扣',
		industry: '產業',
	},
	error: {
		createCustomer: '無法建立客戶。',
		updateCustomer: '無法更新客戶。',
	},
	select: {
		title: '選取客戶',
		button: {
			placeholder: '選取客戶',
			label: '客戶',
		},
		filter: {
			placeholder: '尋找客戶',
			placeholderEmail: '透過電子郵件地址尋找客戶',
			placeholderType: '客戶類型',
		},
		type: {
			EMAIL: '電子郵件地址',
			ID: '銷售人員 ID',
		},
	},
	page: {
		create: {
			title: '新客戶',
			success: '您已成功建立新客戶。',
			form: {
				email: '請使用您的客戶可以存取的真實電子郵件地址。',
			},
		},
		update: {
			success: '您已成功更新客戶。',
		},
	},
	actions: {
		edit: '編輯客戶',
		exportAll: '匯出所有客戶',
		newCustomer: '建立新客戶',
		relatedLicenses: '檢視相關授權',
		relatedOrders: '檢視相關訂單',
		relatedQuotes: '檢視相關報價',
		viewLicenses: '檢視授權',
		viewOrders: '檢視訂單',
		viewQuotes: '檢視報價',
		form: {
			create: '另存為新客戶',
			update: '儲存變更',
		},
	},
};
