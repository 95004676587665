import React, { ReactElement, useState } from 'react';
import { useApiHgop } from 'js/hgop/useApiHgop';
import { HgopModal } from 'js/hgop';
import { ModalLoading } from 'js/components/molecules/Modal/ModalLoading';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { IEntityOrder } from 'module/orders';
import { TChargeOrderId, TEventDetailId } from 'types';
import { useApiCancelSubmission } from 'module/purchase/hooks/useApiPurchase';
import { logDebug, logError } from 'js/utils/app';
import { STATUS_SUCCESS } from 'appConstants';
import { TAsyncContainerRef } from 'js/components/molecules/Modal/AsyncContainer';
import { useAppContext } from 'js/contexts';

export type THgopContainer = {
	order: IEntityOrder;
};

export type THgopContainerProps = {
	parentRef: TAsyncContainerRef<THgopContainer>;
	id: TEventDetailId;
	chargeOrderId?: TChargeOrderId;
};

export const HgopContainer = (props: THgopContainerProps): ReactElement | null => {
	const { id, chargeOrderId, parentRef } = props;
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { loadingModalRef } = useAppContext();
	const {
		data: hgop,
		query: { isFetching },
	} = useApiHgop({
		id: chargeOrderId,
		queryConfig: { enabled: Boolean(chargeOrderId) },
	});
	const cancelSubmission = useApiCancelSubmission({ config: { params: { standardOrderId: id } } });
	const [isSuccess, setSuccess] = useState(false);

	if (isFetching) {
		return (
			<ModalLoading
				title={t('common.loadingOrder')}
				disableBeforeUnload
			/>
		);
	}

	if (!hgop || !chargeOrderId) {
		logError('Invalid inputs for HGOP container', { hgop, chargeOrderId });
		parentRef.current?.onCancel();
		return null;
	}

	return (
		<HgopModal
			hgop={hgop}
			id={id}
			chargeOrderId={chargeOrderId}
			onSuccess={() => {
				setSuccess(true);
			}}
			onHide={async () => {
				if (isSuccess) {
					parentRef.current?.onSuccess(STATUS_SUCCESS);
				} else {
					logDebug(`Revert order #${id} to "Save in progress" / "Quote"`);
					loadingModalRef.current?.show({
						title: t('common.savingOrder'),
						disableBeforeUnload: true,
					});
					await cancelSubmission.mutateAsync({});
					loadingModalRef.current?.hide();
					parentRef.current?.onCancel();
				}
			}}
		/>
	);
};
