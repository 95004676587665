import { useApiClosingBalanceList } from 'module/closingBalance/hooks/useApiClosingBalance';
import { logError } from 'js/utils/app';
import { IEntityOrder } from 'module/orders';
import { isClosingBalanceOrder } from 'module/orders/utils/common';

export const useOrderClosingBalance = (order: IEntityOrder) => {
	const { periodActivityUuid: periodActivityId, partner } = order;
	const partnerId = partner?.id;
	const enabled =
		Boolean(periodActivityId) && isClosingBalanceOrder(order) && Boolean(partnerId) && order.cbConsolidatedComplete;

	const {
		data,
		query: { isFetching },
	} = useApiClosingBalanceList({
		filter: { periodActivityId },
		queryConfig: {
			enabled,
			onError(error) {
				logError(`Get closing balance for order #${order.id} failed.`, periodActivityId, error);
			},
		},
	});

	return {
		closingBalance: data?.items?.[0],
		isFetching,
		enabled,
	};
};
