export default {
	steps: {
		billableParty: {
			title: 'Billable Party',
			virtualPartnerTitle: 'New Prospect',
		},
		product: {
			title: 'Product',
			isRenewal: 'Is it renewal?',
		},
		discounts: {
			title: 'Discounts',
			discretionary: 'Discretionary Discount',
			special: 'Special Discount',
			tier: 'Tier Discount',
			segment: 'Segment Discount',
		},
		price: {
			title: 'Price',
			appliedPromotions: 'Applied promotions',
		},
	},
	message: {
		countryCodeNotSelected: 'Select a country code first',
		requiredStepsNotFulfilled: 'Select a Billable Party and Product to see prices',
	},
	goalSeekedPrice: {
		title: 'Goal Seeked Unit Price',
		originalPrice: 'Original Unit Price',
		finalPrice: 'Final Unit Price',
	},
};
