import React, { useCallback } from 'react';
import { useApi } from 'js/hooks/api';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { logError } from 'js/utils/app';
import { useTranslation } from 'react-i18next';
import { downloadFileFromApiResponse } from 'js/utils/file';
import { IAsyncJob } from 'js/asyncJobs';

export const useDownloadAsyncJobFile = () => {
	const api = useApi();
	const [t] = useTranslation();

	return useCallback(
		async (asyncJob: IAsyncJob) => {
			const filename = asyncJob.data.fileName;

			const response = await api
				.get(`/async-job/${asyncJob.id}/download`, {
					responseType: 'blob',
				})
				.catch((error: unknown) => {
					logError(error);
					toast.error(<Toast>{t('components:export.downloadError')}</Toast>);
				});

			if (response?.data) {
				downloadFileFromApiResponse(response, filename!);
			}
		},
		[api, t],
	);
};
