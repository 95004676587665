export default {
	common: {
		forecastedSpend: 'Dépenses prévues',
		currentSpend: 'Dépenses actuelles',
		maxSpend: 'Dépenses maximales',
		limit: 'Limite',
		noLimit: 'Pas de limite',
		searchDistributionPartner: 'Trouver un partenaire de distribution par nom',
		unlimited: 'Illimité',
		unlimitedNote:
			'Les dépenses maximales peuvent dépasser votre limite de crédit si des limites d’utilisation ne sont pas fixées. Dans ce cas, le ou les produit de votre partenaire seront désactivés jusqu’à ce que la limite de crédit soit augmentée ou le solde réduit.',
		valueBefore: 'Avant',
		valueAfter: 'Après avoir enregistré les modifications',
	},
	page: {
		title: 'Facturation flexible',
	},
	actions: {
		edit: 'Gérer vos produits',
		viewOrders: 'Afficher les commandes',
		enableProducts: 'Autoriser les produits',
		enableAllProducts: 'Autoriser tous les produits',
		disableAllProducts: 'Désactiver tous les produits',
	},
	tab: {
		distributionPartners: 'Aperçu des partenaires',
		overview: 'Vue d’ensemble',
		reports: 'Rapports',
	},
	modal: {
		confirmation: 'Êtes-vous sûr ?',
		message:
			'Les changements que vous enregistrez peuvent avoir des conséquences sur votre crédit. Voulez-vous continuer ?',
		apply: 'Application des modifications',
		success: 'Les modifications ont été appliquées',
	},
	entity: {
		usageLimit: 'Limite d’utilisation',
		yourPrice: 'Prix réel',
	},
	invite: {
		info: 'Ce partenaire n’a pas encore créé de compte Business Hub. Vous pouvez mettre en place la facturation flexible pour ce partenaire, mais elle ne sera pas fonctionnelle tant que leur compte Business Hub n’aura pas été créé.',
		action: {
			send: 'Envoyer l’invitation',
			resend: 'Renvoyer l’invitation',
		},
		lastSent: 'La dernière invitation a été envoyée à {{email}} le {{date}}.',
		processing: 'Nous envoyons une invitation au partenaire de distribution',
		success: 'L’invitation a été envoyée',
		description:
			'Envoi d’un e-mail d’invitation à l’inscription au Business Hub. Ce compte sera le premier compte d’administrateur dans le Hub (d’autres administrateurs peuvent être ajoutés par la suite). Une fois enregistré, le nouveau compte du Business Hub sera lié à votre partenaire.',
		form: {
			create: {
				label: 'Créer un Business Hub lors de l’enregistrement de ce partenaire',
				description:
					'Envoi d’un e-mail d’invitation à l’inscription au Business Hub une fois le formulaire enregistré. Ce compte sera le premier compte d’administrateur dans le Hub (d’autres administrateurs peuvent être ajoutés par la suite). Une fois enregistré, le nouveau compte du Business Hub sera lié à votre partenaire.',
			},
			differentEmail: {
				partner: 'Envoi d’une invitation à l’adresse e-mail enregistrée',
				other: 'Envoyer l’invitation à une autre adresse e-mail',
			},
		},
		modal: {
			title: 'Créer un Business Hub pour {{name}}',
		},
	},
	tooltips: {
		unlimitedMaxSpend:
			'Les dépenses maximales peuvent dépasser votre limite de crédit si des limites d’utilisation ne sont pas fixées. Dans ce cas, le ou les produits de votre partenaire seront désactivés jusqu’à ce que la limite de crédit soit augmentée ou le solde réduit.',
		forecastedSpend: {
			distributor:
				'Le montant qui sera dépensé au cours de la période de facturation actuelle si l’utilisation du produit par tous les partenaires reste inchangée.',
			partner:
				'Le montant qui sera dépensé au cours de la période de facturation actuelle si l’utilisation du produit par ce partenaire reste inchangée.',
		},
		currentSpend: {
			distributor:
				'Le montant déjà dépensé pendant la période de facturation en cours pour des produits actifs chez tous les partenaires.',
			partner:
				'Le montant déjà dépensé pendant la période de facturation en cours pour des produits actifs chez ce partenaire.',
		},
		maxSpend: {
			distributor:
				'Le montant maximum qui peut être facturé si tous les produits actifs de tous les partenaires atteignent leurs limites d’utilisation.',
			partner:
				'Le montant maximum qui peut être facturé si tous les produits actifs de ce partenaire atteignent leurs limites d’utilisation.',
		},
	},
	error: {
		usageLimit: {
			title: 'Vos modifications ne peuvent pas être enregistrées à ce stade.',
			message:
				'Les limites d’utilisation pour le ou les produits que vous essayez de définir sont inférieures au total des limites d’utilisation que votre partenaire a établi dans son Business Hub pour ses clients.',
			product:
				'Le total des limites pour <strong>{{product}}</strong> est actuellement fixé à <strong>{{limit}}</strong>.',
			note: 'Nous vous conseillons de contacter votre partenaire pour discuter des limites d’utilisation.<br />Si vous avez besoin d’aide, veuillez contacter notre équipe d’assistance.',
		},
	},
};
