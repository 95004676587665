import React, { ReactElement, useCallback, useState } from 'react';
import { IApiSortBy } from 'types/api';
import { ApiPaginatedListTable } from 'js/components/molecules/DataTable';
import { RouteName } from 'module/RouteName';
import { ICreditStatusListFilter, IEntityCreditStatus } from 'module/creditStatus';
import { useApiCreditStatusList } from 'module/creditStatus/hooks/useApiCreditStatus';
import { FilterPlaceholderAgent } from 'js/layouts/placeholder/FilterPlaceholder';
import { CreditStatusFilter } from 'module/creditStatus/components';
import { buildParametrizedRoute } from 'js/utils/common';
import { useCreditStatusListColumns } from 'module/creditStatus/hooks';
import { useNavigate } from 'react-router-dom';
import { SortDirectionEnum } from 'js/enums';

type TTableData = IEntityCreditStatus;
type TTableDataFilter = ICreditStatusListFilter;

export const List = (): ReactElement => {
	const navigate = useNavigate();

	// Set changeable filter
	const [filter, setFilter] = useState<TTableDataFilter | null>(null);

	// Table args
	const [sort] = useState<IApiSortBy<TTableData>>({ key: 'companyName', direction: SortDirectionEnum.ASC });

	// Define columns
	const columns = useCreditStatusListColumns();

	const onRowClick = useCallback(
		(row: IEntityCreditStatus) => navigate(buildParametrizedRoute(RouteName.CREDIT_STATUS.DETAIL, row.partnerId)),
		[navigate],
	);

	return (
		<>
			<FilterPlaceholderAgent>
				<CreditStatusFilter
					values={filter}
					onChange={setFilter}
				/>
			</FilterPlaceholderAgent>
			<ApiPaginatedListTable<TTableData, TTableDataFilter>
				columns={columns}
				query={useApiCreditStatusList}
				sort={sort}
				useLocation
				filter={filter}
				table={{
					testId: 'creditStatusList',
					enableSorting: false,
					meta: {
						onRowClick,
					},
				}}
			/>
		</>
	);
};
