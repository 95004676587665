import { TUseApiMutationProps } from 'js/queries/useApiMutationQuery';
import { ILoginFormData, TLoginResolve } from 'module/security';
import { useCallback } from 'react';
import { useAuthUserData } from 'module/security/hooks/useAuthUserData';
import { useApiLogin } from 'module/security/hooks/useApiSecurity';
import { getInvalidStateLocation } from 'module/security/utils/common';
import { IAuthTokenResponse } from 'js/reducers/authContextReducer';
import { useAuthContext } from 'js/contexts';

/**
 * Login function to all used API`s, get detail of the current partner
 *
 * @param {TUseApiMutationProps<ILoginFormData, IAuthTokenResponse>} props
 * @return {Function}
 */
export const useLogin = (
	props: TUseApiMutationProps<ILoginFormData, IAuthTokenResponse> = {},
): ((values: ILoginFormData) => Promise<TLoginResolve>) => {
	const { setAuthData } = useAuthContext();
	const { mutateAsync } = useApiLogin(props);
	const authUserData = useAuthUserData();

	return useCallback(
		(values) =>
			new Promise(async (resolve) => {
				try {
					const response = await mutateAsync({
						username: values.username.trim(),
						password: values.password.trim(),
					});

					// Err -> handled by hook props
					if (!response) {
						resolve({ success: false, message: 'state.invalidCredentials' });
						return;
					}

					const data = await authUserData(response.data);
					if (data) {
						setAuthData(data);
						resolve({ success: true, redirectTo: getInvalidStateLocation(data) });
						return;
					}
					resolve({ success: false, message: 'state.invalidCredentials' });
				} catch (e: unknown) {
					resolve({ success: false, message: 'error:unspecifiedError' });
				}
			}),
		[mutateAsync, authUserData, setAuthData],
	);
};
