import type { TFormFieldProps, TRangeDatepickerProps } from '@avast/react-ui-components';
import { RangeDatepicker } from '@avast/react-ui-components';
import React, { ReactElement } from 'react';
import type { FieldProps } from 'formik';
import { Field } from 'formik';
import { formik2Props, TFormikFieldProps } from 'js/components/formik/FormikControl';
import { useFormikSetFieldValue } from 'js/hooks/useFormikSetFieldValue';
import { useFormikOnBlur } from 'js/hooks/useFormikOnBlur';
import { SubmittingIndicatorEnum } from 'js/enums';

type TFormikRangeDatepickerProps = Partial<TRangeDatepickerProps> & TFormFieldProps;

/**
 * @param {TFormikFieldProps} componentProps
 * @returns {ReactElement}
 * @private
 */
export const FormikRangeDatepicker = (componentProps: TFormikFieldProps<TFormikRangeDatepickerProps>): ReactElement => {
	const { name, onChange, onBlur, ...props } = componentProps;
	const formikSetFieldValue = useFormikSetFieldValue(name);
	const formikOnBlur = useFormikOnBlur(name);
	return (
		<Field name={name}>
			{(fieldProps: FieldProps) => (
				<RangeDatepicker
					{...fieldProps.field}
					{...formik2Props<TFormikRangeDatepickerProps>(fieldProps, props, SubmittingIndicatorEnum.DISABLED)}
					onChange={(value) => {
						formikSetFieldValue(value);
						onChange?.(value);
					}}
					onBlur={() => {
						formikOnBlur();
						onBlur?.();
					}}
				/>
			)}
		</Field>
	);
};
