import React, { ReactElement } from 'react';
import { useRegistrationContext } from 'module/registration/context/RegistrationContext';
import { InitRegistration, RegistrationInfo, TaxExemption, Terms } from 'module/registration/pages/steps';
import { Navigate } from 'react-router-dom';
import { RouteName } from 'module/RouteName';
import { RegistrationStepEnum } from 'module/registration/enums';

export const PageDefault = (): ReactElement => {
	const { step } = useRegistrationContext();

	switch (step) {
		case RegistrationStepEnum.INIT:
			return <InitRegistration />;
		case RegistrationStepEnum.TERMS:
			return <Terms />;
		case RegistrationStepEnum.REGISTRATION_INFO:
			return <RegistrationInfo />;
		case RegistrationStepEnum.TAX_EXEMPTION:
			return <TaxExemption />;
		default:
			return (
				<Navigate
					to={RouteName.SECURITY.LOGOUT}
					replace
				/>
			);
	}
};
