import { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/zh-TW');
const locale: TLocale = 'zh-TW';

export const zh_TW: ILocaleConfig = {
	name: 'Chinese (Taiwan)',
	locale,
	language: 'zh',
	default: true,
	format: {
		numbro,
		datetime: locale,
	},
};
