import {
	IEntityProduct,
	IEntityProductGroup,
	IStandardOrderInstance,
	IStandardOrderInstanceProduct,
} from 'module/purchase';
import { MarketSegmentEnum } from 'module/purchase/enums';

export const hasGroupMarketSegment = (group: IEntityProductGroup, marketSegment: MarketSegmentEnum): boolean =>
	group.marketSegment === marketSegment;

export const isGroupConsumer = (group: IEntityProductGroup): boolean =>
	hasGroupMarketSegment(group, MarketSegmentEnum.CONSUMER);

export const isGroupBusiness = (group: IEntityProductGroup): boolean =>
	hasGroupMarketSegment(group, MarketSegmentEnum.BUSINESS);

export const hasOrderProductMarketSegment = (
	product: IStandardOrderInstanceProduct,
	marketSegment: MarketSegmentEnum,
): boolean => product.group.marketSegment === marketSegment;

export const isOrderProductConsumer = (product: IStandardOrderInstanceProduct): boolean =>
	hasOrderProductMarketSegment(product, MarketSegmentEnum.CONSUMER);

export const isOrderProductBusiness = (product: IStandardOrderInstanceProduct): boolean =>
	hasOrderProductMarketSegment(product, MarketSegmentEnum.BUSINESS);

const validityTypeOrder: Record<IEntityProduct['validityType'], number> = {
	DAY: 1,
	MONTH: 2,
	YEAR: 3,
};

export const sortProducts = (a: IEntityProduct, b: IEntityProduct) => {
	const validityTypeOrder_a = validityTypeOrder[a.validityType];
	const validityTypeOrder_b = validityTypeOrder[b.validityType];
	// Sorting by validity type - day, month, year
	if (validityTypeOrder_a > validityTypeOrder_b) {
		return 1;
	}
	if (validityTypeOrder_a < validityTypeOrder_b) {
		return -1;
	}

	// Sorting by validity value
	if (a.validity > b.validity) {
		return 1;
	}
	if (a.validity < b.validity) {
		return -1;
	}

	// Second sorting by bulk quantity
	if (a.bulkQuantity > b.bulkQuantity) {
		return 1;
	}
	if (a.bulkQuantity < b.bulkQuantity) {
		return -1;
	}

	return 0;
};

export const hasOnlyBusinessProducts = (orderState: IStandardOrderInstance): boolean =>
	!orderState.items.some((item) => item.product.isConsumer);

export const hasInstanceZeroValueItem = (orderState: IStandardOrderInstance): boolean =>
	orderState.pricing?.lineitems.some((item) => item.unitPrice <= 0) ?? true;
