import React, { ReactElement } from 'react';
import { IEntityCustomer } from 'module/customers';
import { SelectCustomerInternalCompany } from 'module/customers/components/SelectCustomerInternalCompany';
import { SelectPartnerCustomer } from '.';
import { TPartnerId } from 'types';
import { useSelectCustomerColumns } from 'module/customers/hooks';
import { useAuthContext } from 'js/contexts';

export type TSelectCustomerContainerProps = {
	selectedCustomerId?: IEntityCustomer['id'];
	partnerId?: TPartnerId | null;
	disallowPartnerCustomers?: boolean;
};

type TTableData = IEntityCustomer;

export const SelectCustomerContainer = (
	props: TSelectCustomerContainerProps & {
		onSelect(partner: TTableData): void;
	},
): ReactElement => {
	const { onSelect, partnerId, selectedCustomerId, disallowPartnerCustomers } = props;
	const { isGroupInternal } = useAuthContext();

	// Define columns
	const columns = useSelectCustomerColumns();

	if (isGroupInternal && (!partnerId || disallowPartnerCustomers)) {
		return (
			<SelectCustomerInternalCompany
				onSelect={onSelect}
				columns={columns}
			/>
		);
	}

	return (
		<SelectPartnerCustomer
			selectedCustomerId={selectedCustomerId}
			columns={columns}
			onSelect={onSelect}
			partnerId={partnerId}
		/>
	);
};
