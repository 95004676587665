import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum StandardOrderApprovalFlagEnum {
	NA = 'NA',
	APPROVAL_NOT_REQUIRED = 'Approval Not Required',
	APPROVAL_REQUIRED = 'Approval Required',
	PENDING_APPROVAL = 'Pending Approval',
	APPROVED = 'Approved',
	REJECTED = 'Rejected',
}

export const standardOrderApprovalFlagEnumUtils = generateEnumUtils<StandardOrderApprovalFlagEnum>(
	StandardOrderApprovalFlagEnum,
	{
		translatePath: 'enums:order.approvalFlag',
	},
);
