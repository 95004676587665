export default {
	common: {
		discountWaitingToApprove: 'El descuento está esperando la aprobación del Administrador de ventas.',
		selectCurrencyModalTitle: 'Seleccionar moneda de cliente',
		otherCustomerInformation: 'Otro',
	},
	entity: {
		contactId: 'Id. del cliente',
		customerType: 'Tipo de cliente',
		discount: 'Descuento',
		industry: 'Sector',
	},
	error: {
		createCustomer: 'No se puede crear el cliente.',
		updateCustomer: 'No se puede actualizar el cliente.',
	},
	select: {
		title: 'Seleccionar cliente',
		button: {
			placeholder: 'Seleccionar cliente',
			label: 'Cliente',
		},
		filter: {
			placeholder: 'Buscar cliente',
			placeholderEmail: 'Buscar cliente por correo electrónico',
			placeholderType: 'Tipo de cliente',
		},
		type: {
			EMAIL: 'Correo electrónico',
			ID: 'Id. de Salesforce',
		},
	},
	page: {
		create: {
			title: 'Nuevo cliente',
			success: 'Creó correctamente un nuevo cliente.',
			form: {
				email: 'Use una dirección de correo electrónico genuina a la que el cliente tenga acceso.',
			},
		},
		update: {
			success: 'Actualizó correctamente el cliente.',
		},
	},
	actions: {
		edit: 'Editar cliente',
		exportAll: 'Exportar todos los clientes',
		newCustomer: 'Crear nuevo cliente',
		relatedLicenses: 'Ver licencias relacionadas',
		relatedOrders: 'Ver pedidos relacionados',
		relatedQuotes: 'Ver cotizaciones relacionadas',
		viewLicenses: 'Ver licencias',
		viewOrders: 'Ver pedidos',
		viewQuotes: 'Ver cotizaciones',
		form: {
			create: 'Guardar como nuevo cliente',
			update: 'Guardar cambios',
		},
	},
};
