export default {
	tierQualifyingUnits: 'Unità ammissibili per il livello',
	tier: 'Livello',
	srpUnitPrice: 'Prezzo unitario SKU',
	customerSegment: 'Segmento cliente',
	segmentDiscount: 'Sconto segmento',
	srpAfterSegmentDiscount: 'SRP al netto dello sconto applicato al segmento',
	quantityPriced: 'Quantità prezzata',
	totalPrice: 'cliente',
	discretionaryDiscount: 'Sconto discrezionale',
	unitPriceAfterDiscretionaryDiscount: 'Prezzo unitario al netto dello sconto discrezionale',
	partnerDiscount: 'Sconto partner',
	unitPriceAfterDiscount: 'Prezzo unitario al netto dello sconto',
	finalDiscountedUnitPrice: 'Prezzo unitario scontato finale',
	upgradeCredit: 'Aggiorna credito',
	daysInTerm: 'Giorni nell’intervallo di tempo',
	daysToProrate: 'Giorni da ripartire proporzionalmente',
	prorationRate: 'Tasso di ripartizione proporzionale',
	proratedUnitPrice: 'Prezzo unitario ripartito proporzionalmente',
	proratedSrpUnitPrice: 'Prezzo unitario SRP ripartito proporzionalmente',
	daysInPeriod: 'Giorni nel periodo',
	dailySaleUnitPrice: 'Prezzo unitario di vendita giornaliero',
	customPricing: 'Prezzi personalizzati',
	quantityBasedPromotions: 'Promozioni basate sulla quantità',
	linePriceUnitPriceAfterQuantityBasedPromotions:
		'Prezzo di listino, prezzo unitario al netto delle promozioni basate sulla quantità',
	linePrice: 'Prezzo di listino',
	unitPriceAfterQuantityBasedPromotions: 'Prezzo unitario al netto delle promozioni basate sulla quantità',
	percentageOffPromotions: 'Promozioni con percentuale di sconto',
	unitPriceAfterPercentageOffPromotions: 'Prezzo unitario al netto delle promozioni con percentuale di sconto',
	validFrom: 'Inizio validità',
	validTo: 'Fine validità',
	offQtyOf: 'Quantità scontata di',
	amountOff: 'Importo sconto',
	discountPerUnit: 'Sconto per unità',
	discount: 'Sconto',
};
