import { IPageModuleConfig, IPageModuleLinksConfig, moduleConfigGenerator } from 'module/index';
import { RouteName } from 'module/RouteName';
import { IEntityDistributionPartner, IFormDistributionPartner } from 'module/distributionPartners';
import { buildParametrizedRoute } from 'js/utils/common';
import { YesNoEnum } from 'js/enums';

interface IDistributionPartnerConfig extends IPageModuleConfig, IPageModuleLinksConfig<IEntityDistributionPartner> {
	defaultFormValues: IFormDistributionPartner;
}

export const distributionPartnersConfig = moduleConfigGenerator<IDistributionPartnerConfig>({
	trNamespace: 'moduleDistributionPartners',
	defaultFormValues: {
		name: '',
		contact: {
			firstName: '',
			lastName: '',
			email: '',
		},
		taxId: '',
		website: '',
		billing: {
			street: '',
			city: '',
			postalCode: '',
			countryCode: '',
			stateCode: '',
		},
		flexibleBilling: {
			differentEmail: YesNoEnum.NO,
		},
	},
	menuName: 'app:menu.distributionPartners',
	menuLink: RouteName.DISTRIBUTION_PARTNERS.LIST,
	aclModule: 'distributionPartners',
	detailLink: (distributionPartner) =>
		buildParametrizedRoute(RouteName.DISTRIBUTION_PARTNERS.DETAIL, distributionPartner.id),
});
