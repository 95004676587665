export enum OrderDocumentTypeEnum {
	PAYMENT_INSTRUCTIONS = 'payment_instructions',
	INVOICE = 'invoice',
	VAT_INVOICE = 'vat_invoice',
	CREDIT_MEMO = 'credit_memo',
	GC_SAP_DOCUMENT = 'gc_sap_document',
	GC_DISPLAY_INVOICE_PAGE = 'gc_display_invoice_page',
	PAYG_PRICE_BREAKDOWN_MSP = 'payg_price_breakdown_msp',
	PAYG_REPORTS_ALL = 'payg_reports_all',
	PAYG_PRODUCT_USAGE_REPORT = 'payg_product_usage_report',
}
