import { usePricingHeader } from 'module/purchase/hooks/pricing/usePricingHeader';
import { IEntityProductGroup, IPricingRequestItem } from 'module/purchase';
import { useMemo } from 'react';
import { productGroupsToPricingItems } from 'module/purchase/hooks/pricing/pricingUtils';
import { TUsePricingResponse, usePricing } from 'module/purchase/hooks/pricing/usePricing';
import { useOrderContext } from 'js/contexts';
import { usePriceListContext } from 'js/priceList/PriceListContext';

/**
 * Pricing request for SelectProducts component - one product for each group
 *
 * @param {IEntityProductGroup[]} productGroups
 * @returns {TUsePricingResponse}
 */
export const useSelectProductsPricing = (productGroups: IEntityProductGroup[]): TUsePricingResponse => {
	const { priceListCode } = usePriceListContext();
	const {
		orderState: { customer },
	} = useOrderContext();

	// Prepare pricing fragments
	const header = usePricingHeader(priceListCode);
	const items: IPricingRequestItem[] = useMemo(
		() => productGroupsToPricingItems(productGroups, customer),
		[productGroups, customer],
	);

	return usePricing(header, items, { debounceDelay: 0 });
};
