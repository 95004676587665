export default {
	common: {
		allPartnerType: 'Todos los socios',
		avastId: 'Id. de socio de Avast',
		loadingDetail: 'Cargando detalle de socio',
	},
	error: {
		unableToLoadTerms: 'No se pueden cargar los términos y condiciones.',
		unableToLoadPartnerDetail: 'No se pueden cargar los detalle del socio',
	},
	entity: {
		accountType: 'Tipo',
		accountSubType: 'Subtipo',
		company: 'Empresa',
		email: 'Correo electrónico',
		id: 'Id. de socio',
		salesforceId: 'Id. de Salesforce',
		name: 'Nombre completo',
	},
	select: {
		title: 'Seleccionar socio',
		button: {
			label: 'Socio',
			placeholder: 'Seleccionar socio',
		},
		filter: {
			placeholder: 'Buscar socio',
		},
	},
};
