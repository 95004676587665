export default {
	actions: {
		create: 'Créer une promotion',
		edit: 'Modifier la promotion',
		disable: {
			action: 'Désactiver la promotion',
			success: 'La promotion a été désactivée.',
			loading: 'Désactivation de la promotion...',
			confirmation: {
				title: 'Désactivation de la promotion',
				message: 'Voulez-vous vraiment désactiver cette promotion ? Cette action ne peut pas être annulée.',
			},
		},
		form: {
			create: 'Envoyer la promotion',
			update: 'Mettre à jour la promotion',
		},
		extraFilters: 'Plus de filtres',
		export: 'Exporter les promotions',
	},
	common: {
		effectiveDate: 'Date d’entrée en vigueur',
		filterPromotions: 'Filtrer les promotions',
		partyCriteria: 'Critères relatifs aux parties',
		partyCriteriaInfo: 'Sélectionnez un partenaire ou au moins un critère relatif aux parties pour continuer.',
		productCriteria: 'Critères relatifs aux produits',
		creatingPromotion: 'Création de la promotion',
		updatingPromotion: 'Mise à jour de la promotion',
		invalidPromotionStartDate: {
			title: 'Voulez-vous enregistrer la promotion ?',
			message: 'La date de début de la promotion se situe dans le passé. Vous ne pourrez plus modifier la promotion.',
		},
	},
	error: {
		promotionNotFound: 'Promotion introuvable',
		createPromotion: 'Impossible de créer la promotion.',
		updatePromotion: 'Impossible de mettre à jour la promotion.',
	},
	page: {
		create: {
			title: 'Nouvelle promotion tarifaire',
			success: 'Vous avez créé une nouvelle promotion tarifaire.',
		},
		update: {
			title: 'Mettre à jour la promotion n° {{id}}',
			success: 'Vous avez mis à jour la promotion tarifaire.',
			notEditable:
				'La promotion actuelle ne peut pas être modifiée car il est possible qu’elle ait été utilisée pour une commande.',
		},
	},
	placeholder: {
		types: 'Tous les types de promotion',
		partyTiers: 'Tous les niveaux',
		categories: 'Toutes les catégories',
		licenseOperations: 'Toutes les opérations liées aux licences',
		partyCountries: 'Tous les pays',
		partyTypes: 'Tous les types de parties',
		products: 'Tous les produits',
	},
	entity: {
		id: 'ID de la promotion',
		name: 'Nom de la promotion',
		date: 'Période de validité',
		partyType: 'Type de partie',
		partyType_other: 'Types de partie',
		partyTier: 'Niveau de partie',
		partyTier_other: 'Niveaux de partie',
		partyCountry: 'Pays de la partie',
		partyCountry_other: 'Pays des parties',
		licenseOperation: 'Type d’opération liée aux licences',
		licenseOperation_other: 'Types d’opération liée aux licences',
		category: 'Catégorie de produit',
		product: 'Produit',
		product_other: 'Produits',
		quantityMin: 'Quantité minimum',
		rate: 'Pourcentage de remise',
		scope: 'Champ d’application',
		type: 'Type de promotion',
		unitsFrom: 'Nombre d’unités minimum',
		unitsTo: 'Nombre d’unités maximum',
	},
	tooltip: {
		zeroQuantity:
			'Une quantité de zéro signifie que la promotion sera appliquée quel que soit le nombre de licences commandées.',
	},
};
