import React, { FunctionComponent } from 'react';
import { AppSidebar } from 'js/layouts/AppSidebar';
import classNames from 'classnames';
import { AppHeader } from 'js/layouts/AppHeader';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { Outlet } from 'react-router-dom';
import { useAppContext, useAuthContext } from 'js/contexts';

export const AppLayout: FunctionComponent = () => {
	const { sidebarCollapsed } = useAppContext();
	const { isLogged } = useAuthContext();

	if (isLogged) {
		return (
			<div className={classNames('app', { 'is-collapsed': sidebarCollapsed.value })}>
				<DynamicBreadcrumbsItem href="/">
					<FontAwesomeIcon icon={faHome} />
				</DynamicBreadcrumbsItem>
				<AppHeader />
				<div className="sidebar-container">
					<AppSidebar />
				</div>
				<div
					className="sidebar-overlay"
					onClick={() => sidebarCollapsed.setValue(true)}
				/>
				<div className="content-container">
					<Outlet />
				</div>
			</div>
		);
	}
	return (
		<div className="min-vh-100 d-flex justify-content-center flex-column">
			<Outlet />
		</div>
	);
};
