import { resolveLocaleConfigFromLocaleCode } from 'i18n/utils';
import { ILocaleConfig } from 'types/config';
import { useAuthContext } from 'js/contexts';

export const useCurrentLocaleFormat = (): ILocaleConfig => {
	const {
		authState: { locale },
	} = useAuthContext();
	return resolveLocaleConfigFromLocaleCode(locale.format);
};
