import React, { createContext, Dispatch, PropsWithChildren, RefObject, useContext } from 'react';
import {
	TWidgetActionPayload,
	TWidgetContextState,
	TWidgetContextStateAction,
	TWidgetGrid,
	TWidgetModalProps,
} from 'submodule/widgets/index';
import { useWidgetReducer } from 'submodule/widgets/hooks';
import type { DropResult } from 'react-beautiful-dnd';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { isEditWidgetAction } from 'submodule/widgets/utils';
import { AsyncWidgetModal } from 'submodule/widgets/components/AsyncWidgetModal';

interface IWidgetEditContext {
	dispatch: Dispatch<TWidgetContextStateAction>;
	state: TWidgetContextState;
	onDragEnd: (dropResult: DropResult) => void;
	widgetModal: RefObject<AsyncModal<TWidgetModalProps, number>>;
}

const WidgetEditContext = createContext<IWidgetEditContext>({} as IWidgetEditContext);

WidgetEditContext.displayName = 'WidgetEditContext';

export const useWidgetEditContext = () => useContext(WidgetEditContext);

export const WidgetEditContextProvider = (props: PropsWithChildren<{ config: TWidgetGrid }>) => {
	const { dispatch, state, onDragEnd } = useWidgetReducer({ config: props.config });
	const widgetModal = useAsyncModalRef<TWidgetModalProps>();
	const onSubmit = (widgetActionData: TWidgetActionPayload): void => {
		if (isEditWidgetAction(widgetActionData)) {
			return dispatch({ type: 'EDIT_WIDGET', payload: widgetActionData });
		}
		return dispatch({ type: 'ADD_WIDGET', payload: widgetActionData });
	};

	return (
		<WidgetEditContext.Provider
			value={{
				dispatch,
				state,
				onDragEnd,
				widgetModal,
			}}
		>
			{props.children}
			<AsyncWidgetModal
				forwardedRef={widgetModal}
				onSubmit={onSubmit}
			/>
		</WidgetEditContext.Provider>
	);
};
