import React, { memo, PropsWithChildren, ReactElement } from 'react';
import { BillablePartyText } from 'module/purchase/components/widget/BillablePartyText';
import { useAuthContext } from 'js/contexts';

type TSelectCardState = {
	note: string | ReactElement;
	isBillable?: boolean;
};

const _SelectCardState = (props: PropsWithChildren<TSelectCardState>): ReactElement => {
	const { isBillable, note } = props;
	const { isGroupPartner } = useAuthContext();

	return (
		<div className="state-select">
			<div className="note">
				<p>{note}</p>
			</div>
			<div className="actions-container">
				<div className="actions">{props.children}</div>
				{!isGroupPartner && isBillable !== undefined && <BillablePartyText isBillable={isBillable} />}
			</div>
		</div>
	);
};

export const SelectCardState = memo(_SelectCardState);
