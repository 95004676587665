import React, { ReactElement } from 'react';
import { ButtonComposition } from '@avast/react-ui-components';
import { QuickFilterButton } from './QuickFilterButton';
import { useTranslation } from 'react-i18next';

type TQuickFilterAction<T extends object> = {
	caption: string;
	filter: T;
};

export type TQuickFiltersProps<T extends object> = {
	activeFilter: T;
	setFilter: (values: T) => void;
	caption: string | null;
	actions: TQuickFilterAction<T>[];
};

export const QuickFilters = <Filter extends object>(props: TQuickFiltersProps<Filter>): ReactElement => {
	const [t] = useTranslation();

	return (
		<div className="component__quick-actions">
			{props.caption && (
				<div className="caption">
					<span className="form-label my-0 text-uppercase">{t(props.caption)}:</span>
				</div>
			)}
			<div className="actions">
				<ButtonComposition
					size="xs"
					marginY
					wrap
				>
					{props.actions.map((action, index) => (
						<QuickFilterButton<Filter>
							filter={props.activeFilter}
							key={index}
							values={action.filter}
							onClick={props.setFilter}
						>
							{action.caption}
						</QuickFilterButton>
					))}
				</ButtonComposition>
			</div>
		</div>
	);
};

QuickFilters.defaultProps = {
	caption: 'common:filter.quickFilters',
};
