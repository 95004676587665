import { getHelpVideos } from 'js/help/videos/videoContent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHelpContext } from 'js/help/HelpContext';
import classNames from 'classnames';
import { HelpVideoEnum } from 'js/enums';

type TVideosListProps = {
	activeKey?: HelpVideoEnum;
};

export const VideosList = (props: TVideosListProps) => {
	const { activeKey } = props;
	const [t] = useTranslation('app');
	const { setVideo } = useHelpContext();

	return (
		<ul className="video-list reset">
			{getHelpVideos(t).map((video) => (
				<li
					key={video.key}
					onClick={() => setVideo(video.key)}
					className={classNames({ 'is-active': video.key === activeKey })}
				>
					{video.name}
				</li>
			))}
		</ul>
	);
};
