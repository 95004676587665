export default {
	common: {
		partnerPortal: 'Log in into OMS via Partner Portal',
		forgetPassword: 'Forgot your Password?',
		returnToPartnerPortal: 'Return to the Partner Portal',
		successNewPassword: 'Your new password has been successfully changed.',
		backToLogin: 'Back to Login',
		loginLoading: 'Login into OMS',
	},
	commonText: {
		loginForAvastOnly: 'For Avast internal use only.',
		assistance: 'If you need assistance, please contact your Avast Business Account Manager.',
		signature: 'Thank you, <br />Avast Business',
	},
	form: {
		password: 'Password',
		submit: 'Continue to OMS',
		email: 'E-mail',
	},
	page: {
		login: {
			title: 'Login',
		},
		logout: {
			loading: 'Logout from OMS...',
		},
		sso: {
			title: 'SSO login',
			loading: 'Login into OMS...',
		},
		loginAsAffiliate: {
			title: 'Login as Affiliate',
			loading: 'Login as Affiliate into OMS...',
		},
		orderHold: {
			title: 'Your company has invoice(s) that are {{count}} or more days past their due date.',
			text: 'For this reason your company’s account has been put on hold, which prevents you from making new purchases on credit.',
			textMore:
				'More details about unpaid orders can be found in the Orders section and they have also been shared via email with the primary contact of your company.',
			action: 'View unpaid orders',
		},
		registrationIncomplete: {
			title: 'Could not complete the operation',
			text: 'The OMS registration process has not yet been completed by a Primary (Admin) User at your organization. Once this has been completed, your access will become available.',
		},
		terms: {
			title: 'New terms and conditions',
			text: 'New terms and conditions must be accepted by a Primary (Admin) User at your organization.',
		},
		inactive: {
			title: 'Could not complete the operation',
			text: 'Your access has been denied. Please contact your account administrator for more details.',
		},
		maintenance: {
			scheduled: {
				title: 'Scheduled maintenance',
				text: 'The Order Management System is currently undergoing scheduled maintenance and is temporarily unavailable. We apologize for the inconvenience.',
				end: 'Scheduled End of Maintenance',
			},
		},
		passwordReset: {
			title: 'Reset your password',
			info: 'Reset your password by providing your account email address.',
			success: 'Your password reset link was sent to your email box.',
		},
		newPassword: {
			title: 'Set new Password',
			form: {
				password: 'New Password',
				confirm: 'Confirm password',
			},
			info: 'Please enter a new password.',
			validation: {
				digit: 'Password has to contain at least one digit',
				symbol: 'Password has to contain at least one symbol',
				length: 'Password has to be 8 characters at minimum',
				uppercase: 'Password has to contain at least one upper case',
				lowercase: 'Password has to contain at least one lower case',
				notSame: 'Confirmation password doesn’t match',
				trim: 'Password cannot start or end with space.',
			},
		},
	},
	state: {
		invalidCredentials: 'Username or password is incorrect, please try it again.',
		invalidSso: 'Unable to log in, your session expired. Please log in again.',
		invalidAuthorizationCode: 'Unable to log in with this authorization code.',
		sessionExpired: 'Your session expired, you have to login.',
	},
	error: {
		unableToResetPassword: 'We are currently unable to reset your password.',
	},
};
