import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum CustomersSearchKeyEnum {
	EMAIL = 'email',
	SALESFORCE_ID = 'salesforceId',
}

export const customersSearchKeyEnumUtils = generateEnumUtils<CustomersSearchKeyEnum>(CustomersSearchKeyEnum, {
	translatePath: 'enums:customer.searchKey',
});
