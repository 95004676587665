import React from 'react';
import { get } from 'lodash';
import { FileUpload, TFileUploadBaseProps } from 'js/components/fileUpload/FileUpload';
import { useFormikContext } from 'formik';
import { FormikControl } from 'js/components/formik/FormikControl';

type TFormikFileUploadProps = TFileUploadBaseProps & {
	name: string;
	label?: string;
};

export const FormikFileUpload = (props: TFormikFileUploadProps) => {
	const { name, label, ...rest } = props;
	const { setFieldValue, setFieldTouched, values } = useFormikContext();

	return (
		<FormikControl label={label}>
			<FileUpload
				{...rest}
				name={name}
				value={get(values, name) || null}
				validationOff
				onChange={(value) => {
					setFieldTouched(name, true);
					setFieldValue(name, value, true);
				}}
				onBlur={() => {
					setFieldTouched(name, true, true);
				}}
			/>
		</FormikControl>
	);
};
