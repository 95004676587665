export default {
	error: {
		creditStatusNotFound: 'Kreditstatus nicht gefunden!',
	},
	tabs: {
		detail: 'Kreditübersicht',
		transactions: 'Liste der Transaktionen',
	},
	entity: {
		companyName: 'Unternehmen',
		countryCode: 'Land',
		creditAvailable: 'Kredit verfügbar',
		creditLimit: 'Kreditlimit',
		percentConsumed: '% ausgeschöpft',
		unbilledBalance: 'Nicht abgerechneter Saldo',
		unpaidBalance: 'Unbezahlter Saldo',
	},
	entityShort: {
		creditAvailable: 'Verfügbar',
		creditLimit: 'Grenzwert',
		percentConsumed: '% ausgeschöpft',
		unbilledBalance: 'Nicht abgerechnet',
		unpaidBalance: 'Unbezahlt',
	},
	entityTransaction: {
		amount: 'Betrag',
		createdAt: 'Erstellt am',
		orderId: 'Referenz-ID',
		source: 'Quelle',
	},
	widget: {
		name: 'Typ',
		value: 'Kredit',
	},
};
