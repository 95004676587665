import type { ISelectOption } from '@avast/react-ui-components';
import { useLanguages } from 'submodule/localeSwitch/hooks/useLanguages';
import { TLocaleLanguage } from 'types/config';

export const useLanguageSelectOptions = (): ISelectOption<TLocaleLanguage>[] => {
	const languages = useLanguages();

	return languages.map((item) => ({
		value: item.language,
		label: item.name,
	}));
};
