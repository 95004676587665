import React, { ReactElement } from 'react';
import { Col, Row } from 'js/components/atoms/Row';
import { FormikControl } from 'js/components/formik/FormikControl';
import { useTranslation } from 'react-i18next';
import { IPromotionListFilter, IPromotionListFilterExtra } from 'module/promotions';
import { FormikForm } from 'js/components/formik/FormikForm';
import { Formik } from 'formik';
import { useDataFilterContext } from 'js/components/molecules/DataFilter';
import { promotionPartyTypeEnumUtils, promotionStateEnumUtils, promotionTypeEnumUtils } from 'module/promotions/enums';
import { usePromotionProductSelectOptions } from 'module/promotions/hooks/usePromotionProductSelectOptions';
import { useCountrySelectOptions } from 'js/entities/country/useCountrySelectOptions';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import { licenseOperationEnumUtils } from 'module/licenses/enums';
import { promotionExtraFilterNormalizer } from 'module/promotions/normalizer';

type TPromotionExtraFiltersFormProps = {
	onSubmit(values: IPromotionListFilterExtra): void;
	onCancel(): void;
};

export const PromotionExtraFiltersForm = (props: TPromotionExtraFiltersFormProps): ReactElement => {
	const { onSubmit, onCancel } = props;
	const [t] = useTranslation(promotionsConfig.trNamespace);
	const { values } = useDataFilterContext<IPromotionListFilter>();
	const { options: productSelectOptions, isLoading: isProductSelectOptionsLoading } = usePromotionProductSelectOptions(
		{},
		true,
	);
	const { countrySelectOptions, isLoading } = useCountrySelectOptions();

	return (
		<Formik<IPromotionListFilterExtra>
			initialValues={promotionExtraFilterNormalizer.normalize(values)}
			enableReinitialize
			onSubmit={onSubmit}
		>
			<FormikForm>
				<Row
					multi
					md={2}
				>
					<Col sm={6}>
						<FormikControl label={t('common:entity.status')}>
							<FormikControl.SingleSelect
								name="state"
								options={promotionStateEnumUtils.getSelectOptions()}
							/>
						</FormikControl>

						<FormikControl label={t('common:entity.partner')}>
							<FormikControl.SelectPartner name="partnerId" />
						</FormikControl>

						<FormikControl label={t('common.effectiveDate')}>
							<FormikControl.SingleDatepicker name="effectiveDate" />
						</FormikControl>

						<FormikControl label={t('entity.partyCountry')}>
							<FormikControl.SingleSelect
								name="partnerCountryCode"
								options={countrySelectOptions}
								placeholder={t('placeholder.partyCountries')}
								isLoading={isLoading}
							/>
						</FormikControl>
					</Col>
					<Col sm={6}>
						<FormikControl label={t('entity.partyType')}>
							<FormikControl.SingleSelect
								name="partnerType"
								options={promotionPartyTypeEnumUtils.getSelectOptions()}
								placeholder={t('placeholder.partyTypes')}
							/>
						</FormikControl>
						<FormikControl label={t('entity.product')}>
							<FormikControl.SingleSelect
								isLoading={isProductSelectOptionsLoading}
								name="productSku"
								options={productSelectOptions}
								placeholder={t('placeholder.products')}
							/>
						</FormikControl>
						<FormikControl label={t('entity.licenseOperation')}>
							<FormikControl.SingleSelect
								name="licenseType"
								options={licenseOperationEnumUtils.getSelectOptions()}
								placeholder={t('placeholder.licenseOperations')}
							/>
						</FormikControl>
						<FormikControl label={t('entity.type')}>
							<FormikControl.SingleSelect
								name="type"
								options={promotionTypeEnumUtils.getSelectOptions()}
								placeholder={t('placeholder.types')}
							/>
						</FormikControl>
					</Col>
				</Row>
				<FormikFooterControls
					cancel={{ onClick: onCancel }}
					submit={{ children: t('common.filterPromotions') }}
				/>
			</FormikForm>
		</Formik>
	);
};
