import React, { ReactElement } from 'react';
import { AsyncModal, TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { SelectVariantContent } from 'module/purchase/components/selectProducts/SelectVariantContent';
import { STATUS_SUCCESS } from 'appConstants';
import { TSelectVariantTableProps } from 'module/purchase/components/selectProducts/SelectVariantTable';

export type TAsyncSelectVariantModalProps = TSelectVariantTableProps;

export const AsyncSelectVariantModal = (
	props: TAsyncModalContainerProps<TAsyncSelectVariantModalProps>,
): ReactElement | null => {
	const { forwardedRef } = props;

	return (
		<AsyncModal
			size="lg"
			backdrop="static"
			ref={forwardedRef}
			testId="selectVariant"
		>
			{({ productGroup }) => (
				<SelectVariantContent
					productGroup={productGroup}
					onSelect={() => {
						forwardedRef.current?.onSuccess(STATUS_SUCCESS);
					}}
				/>
			)}
		</AsyncModal>
	);
};
