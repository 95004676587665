import { IRegistrationData } from 'module/registration';
import { IEntityPartner } from 'module/partners';

export const getRegistrationData = (partner: IEntityPartner): IRegistrationData => ({
	companyName: partner.companyName,
	firstName: partner.firstName,
	lastName: partner.lastName,
	email: partner.email,
	phone: partner.phone ?? undefined,
	address: partner.address1 ?? undefined,
	countryCode: partner.countryCode ?? undefined,
	stateCode: partner.stateCode ?? undefined,
	city: partner.city ?? undefined,
	postalCode: partner.postalCode ?? undefined,
	taxId: partner.identifiers?.partyTaxId ?? undefined,
});
