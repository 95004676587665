import { useCallback } from 'react';
import { useApi } from 'js/hooks/api';
import { refreshTokenService } from 'api/refreshTokenService';
import { useAuthContext } from 'js/contexts';
import { noop } from 'lodash';

export const useRefreshToken = () => {
	const {
		authState: { refresh_token, token },
		setAuthToken,
	} = useAuthContext();
	const api = useApi();

	return useCallback(async () => {
		// No refresh token (local, testing envs)
		if (!refresh_token) {
			return;
		}

		const refreshTokenResponse = await refreshTokenService.subscribe(api, refresh_token, token).catch(noop);

		if (refreshTokenResponse) {
			setAuthToken(refreshTokenResponse);
		}
	}, [api, refresh_token, token, setAuthToken]);
};
