import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RouteName } from 'module/RouteName';
import { securityConfig } from 'module/security/securityConfig';
import { LoadingFullScreenPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { H3 } from '@avast/react-ui-components';
import { useLogout } from 'module/security/hooks/useLogout';
import { ILoginPageState, ILogoutPageState } from 'module/security/index';
import { useLocationState } from 'js/hooks/useLocationState';
import { useAppContext, useAuthContext } from 'js/contexts';
import { CONFIG } from 'config';
import { getSalesforceLoginLink } from 'module/security/utils/common';

export const PageLogout = (): ReactElement => {
	const [t] = useTranslation(securityConfig.trNamespace);
	const logout = useLogout();
	const { hideContextModals } = useAppContext();
	const { isGroupPartner } = useAuthContext();
	const navigate = useNavigate();
	const { sessionExpired, redirectUrl, keepState, previousLocation } = useLocationState<ILogoutPageState>();

	// Redirect on init
	useEffect(() => {
		hideContextModals();
		const _logout = async () => {
			await logout(keepState);

			if (redirectUrl) {
				window.location.href = redirectUrl;
				return;
			}

			// Redirect
			let state: ILoginPageState | null = null;

			if (sessionExpired && previousLocation) {
				if (isGroupPartner && CONFIG.ENV !== 'locale') {
					window.location.href = getSalesforceLoginLink(previousLocation.to);
				}

				state = {
					sessionExpired,
					authRedirectFrom: previousLocation,
				};
			}
			navigate(RouteName.SECURITY.LOGIN, { state });
		};

		_logout();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<LoadingFullScreenPlaceholder>
			<H3 bold>{t('page.logout.loading')}</H3>
		</LoadingFullScreenPlaceholder>
	);
};
