import React, { FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import { TAclModules } from 'config/acl';
import { Can } from 'js/components/molecules/Can';
import type { ITestId } from '@avast/react-ui-components';
import { Tooltip, useTestAttributes } from '@avast/react-ui-components';
import classNames from 'classnames';
import { IAuthGuards } from 'types';
import type { NavLinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useAppContext } from 'js/contexts';

type TMenuItemPropsBase = {
	liProps?: React.LiHTMLAttributes<HTMLLIElement>;
	icon: FunctionComponent;
	acl?: TAclModules;
	authGuards?: IAuthGuards;
	text: string;
	isAllowed?: boolean;
	className?: string;
} & ITestId;

type TMenuItemProps =
	| (TMenuItemPropsBase & { onClick: () => void } & { to?: undefined; externalLink?: false })
	| (TMenuItemPropsBase & { to: string; externalLink: true; target?: string } & { onClick?: undefined })
	| (TMenuItemPropsBase & Omit<NavLinkProps, 'className'> & { onClick?: undefined; externalLink?: false });

type TMenuProps = {} & React.HTMLAttributes<HTMLUListElement>;

/**
 * Menu item - if it has children is rendered as submenu
 * @param {React.PropsWithChildren<TMenuItemProps>} props
 * @returns {any}
 * @constructor
 */
const MenuItem = (props: PropsWithChildren<TMenuItemProps>): ReactElement | null => {
	const { children, text, liProps, icon: Icon, acl, authGuards, isAllowed = true, testId, ...rest } = props;
	const { sidebarCollapsed } = useAppContext();
	const testAttributes = useTestAttributes(testId, { type: 'menuItem' });

	const linkContent = (
		<>
			<span className="icon">
				<Icon />
			</span>
			<span className="caption sidebar-collapse-hidden">{text}</span>
		</>
	);

	let link: ReactElement | null = null;
	if (props.onClick) {
		link = (
			<span
				onClick={props.onClick}
				className={classNames('menu-link', rest.className)}
				{...testAttributes}
			>
				{linkContent}
			</span>
		);
	} else if (props.externalLink) {
		link = (
			<a
				href={props.to}
				className={rest.className}
				target={props.target}
				{...testAttributes}
			>
				{linkContent}
			</a>
		);
	} else if (props.to) {
		link = (
			<NavLink
				to={props.to}
				{...rest}
				{...testAttributes}
			>
				{linkContent}
			</NavLink>
		);
	}

	if (!link) {
		return null;
	}

	return (
		<Can
			do={acl || null}
			readList
			authGuards={authGuards}
			isAllowed={isAllowed}
		>
			<li {...liProps}>
				{sidebarCollapsed.value ? <Tooltip content={text}>{link}</Tooltip> : link}
				<MenuList>{children}</MenuList>
			</li>
		</Can>
	);
};

/**
 * Make menu divider
 *
 * @returns {ReactElement}
 * @constructor
 */
const MenuDivider: FunctionComponent = (): ReactElement => <li className="li-divider sidebar-margin-x" />;

/**
 * Container for menu
 * @param {React.PropsWithChildren<TMenuProps>} props
 * @returns {any}
 * @constructor
 */
const MenuList = (props: PropsWithChildren<TMenuProps>) => {
	const { children, ...rest } = props;

	if (!children) {
		return null;
	}

	return <ul {...rest}>{children}</ul>;
};

const MenuGroup = (props: PropsWithChildren<{}>): ReactElement => (
	<>
		{props.children}
		<Menu.Divider />
	</>
);

export class Menu {
	static List = MenuList;
	static Item = MenuItem;
	static Group = MenuGroup;
	static Divider = MenuDivider;
}
