import moduleHomepage from './moduleHomepage';
import moduleLicenses from './moduleLicenses';
import moduleOrders from './moduleOrders';
import moduleCustomers from './moduleCustomers';
import modulePartners from './modulePartners';
import moduleDistributionPartners from './moduleDistributionPartners';
import moduleSecurity from './moduleSecurity';
import modulePurchase from './modulePurchase';
import moduleCreditLimit from './moduleCreditLimit';
import moduleCreditStatus from './moduleCreditStatus';
import modulePromotions from './modulePromotions';
import moduleClosingBalance from './moduleClosingBalance';
import moduleAccount from './moduleAccount';
import moduleRegistration from './moduleRegistration';
import moduleRetail from './moduleRetail';
import modulePriceCalculator from './modulePriceCalculator';
import moduleFlexibleBilling from './moduleFlexibleBilling';

export default {
	moduleHomepage,
	moduleLicenses,
	moduleOrders,
	moduleCustomers,
	modulePartners,
	moduleDistributionPartners,
	moduleSecurity,
	modulePurchase,
	moduleCreditLimit,
	moduleCreditStatus,
	modulePromotions,
	moduleClosingBalance,
	moduleAccount,
	moduleRegistration,
	moduleRetail,
	modulePriceCalculator,
	moduleFlexibleBilling,
};
