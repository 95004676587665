import { ButtonComposition } from '@avast/react-ui-components';
import { LinkButton } from 'js/components/atoms/Button';
import { RouteName } from 'module/RouteName';
import { licensesConfig } from 'module/licenses/licensesConfig';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { homepageConfig } from 'module/homepage/homepageConfig';
import { useAuthContext } from 'js/contexts';

type THomepageActionsProps = {
	className?: string;
};
export const HomepageActions = ({ className }: THomepageActionsProps) => {
	const [t] = useTranslation(homepageConfig.trNamespace);
	const { isAuthorizedBuyer } = useAuthContext();

	if (!isAuthorizedBuyer) {
		return null;
	}

	return (
		<ButtonComposition
			testId="homepageActions"
			className={className}
		>
			<LinkButton
				to={RouteName.LICENSE.LIST}
				size="sm"
				variant="outline-primary"
				testId="licenseOperations"
			>
				{t(licensesConfig.trPrefix('actions.operations'))}
			</LinkButton>
			<LinkButton
				to={RouteName.PURCHASE.DEFAULT}
				size="sm"
				testId="newPurchase"
			>
				{t('common:actions.newPurchase')}
			</LinkButton>
		</ButtonComposition>
	);
};
