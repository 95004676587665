import { useCallback } from 'react';
import { IEntityOrder } from 'module/orders';
import { isEndCustomerQuote, isIspOrder, isOrderInStatus, isQuote } from 'module/orders/utils/common';
import { OrderStatusEnum } from 'module/orders/enums';
import { StandardOrderApprovalFlagEnum } from 'module/purchase/enums';
import { useAuthContext } from 'js/contexts';

export const useIsLocked = () => {
	const { isGroupPartner, isRoleSalesOperations } = useAuthContext();

	return useCallback(
		(order: IEntityOrder): boolean => {
			// Partner could not edit quotes
			if (isQuote(order) && isGroupPartner) {
				return true;
			}

			if (isEndCustomerQuote(order)) {
				return true;
			}

			// Edit
			if (isOrderInStatus(order, OrderStatusEnum.SAVED_IN_PROGRESS)) {
				if (!isGroupPartner && !isRoleSalesOperations) {
					const flags: StandardOrderApprovalFlagEnum[] = [
						StandardOrderApprovalFlagEnum.APPROVED,
						StandardOrderApprovalFlagEnum.PENDING_APPROVAL,
					];

					if (order.discount?.approvalFlag && flags.includes(order.discount.approvalFlag)) {
						return true;
					}
				}
			}

			return (
				!isOrderInStatus(order, OrderStatusEnum.SAVED_IN_PROGRESS) || order.hasEditedUnitPrices || isIspOrder(order)
			);
		},
		[isGroupPartner, isRoleSalesOperations],
	);
};
