import React, { useCallback, useEffect, useState } from 'react';
import { isAppVersionUpToDate } from 'js/utils/app';
import { toast } from 'react-toastify';
import { Button, Toast } from '@avast/react-ui-components';
import { CONFIG } from 'config';
import { useTranslation } from 'react-i18next';
import { useLocationChange } from 'js/hooks/useLocationChange';

export const useAppVersionGuard = () => {
	const [reloadPage, setReloadPage] = useState(false);
	const [t] = useTranslation('common');
	const isEnabled = process.env.REACT_APP_ENV_NAME !== 'locale';

	// Force page reload on URL change
	const onLocationChange = useCallback(() => {
		if (isEnabled && reloadPage) {
			window.location.reload();
		}
	}, [reloadPage, isEnabled]);
	useLocationChange(onLocationChange);

	// App version watcher
	useEffect(() => {
		if (!isEnabled) {
			return;
		}

		let interval: ReturnType<typeof setInterval> | null = null;
		interval = setInterval(() => {
			isAppVersionUpToDate().then((upToDate) => {
				if (upToDate) {
					return;
				}
				if (upToDate === null) {
					clearInterval(interval!);
					return;
				}

				const button = (
					<Button
						size="xs"
						variant="outline-warning"
						onClick={() => window.location.reload()}
					>
						{t('appVersionGuard.action')}
					</Button>
				);

				toast.warning(
					<Toast
						caption={t('appVersionGuard.caption')}
						bottomPlaceholder={button}
					>
						{t('appVersionGuard.message')}
					</Toast>,
					{ autoClose: false, closeOnClick: false, closeButton: false },
				);

				clearInterval(interval!);
				setReloadPage(true);
			});
		}, CONFIG.APP.VERSION_CHECK_TIMEOUT);

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [t, isEnabled]);
};
