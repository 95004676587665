import { useEffect, useState } from 'react';
import { isMobileScreen } from 'js/utils/screenUtils';

export const useIsMobileScreen = (): boolean => {
	const [value, setValue] = useState<boolean>(isMobileScreen());

	useEffect(() => {
		const listener = () => setValue(isMobileScreen());
		window.addEventListener('resize', listener);
		return () => window.removeEventListener('resize', listener);
	}, []);

	return value;
};
