import React from 'react';
import {
	IEntityLicense,
	IEntityLicenseProduct,
	ILinkToLicense,
	TEntityLicenseId,
	TLicenseString,
} from 'module/licenses';
import classNames from 'classnames';
import type { TColorVariant } from '@avast/react-ui-components';
import { logError, logWarn, noValuePlaceholder } from 'js/utils/app';
import { DateTime } from 'luxon';
import {
	EntitlementTypeEnum,
	LicenseContextIdEnum,
	LicenseExternalIdEnum,
	LicenseStatusEnum,
	licenseStatusEnumUtils,
	ProductSegmentEnum,
} from 'module/licenses/enums';
import type { TFunction } from 'i18next';
import { LicenseCustomerName } from 'module/customers/components';
import { TableColumnsDef } from 'types';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { buildParametrizedRoute, isDefined } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { hasLicenseOperations } from 'module/licenses/utils/licenseOperation';
import { constant, has, head, isString, isUndefined } from 'lodash';

/**
 * Get color variant for license status
 *
 * @param {LicenseStatusEnum} value
 * @returns {TColorVariant | undefined}
 */
export const getVariantColorForLicenseStatus = (value: LicenseStatusEnum): TColorVariant | undefined => {
	switch (value) {
		case LicenseStatusEnum.USED:
		case LicenseStatusEnum.ACTIVE:
			return 'success';
		case LicenseStatusEnum.MIGRATED:
		case LicenseStatusEnum.REPLACED:
		case LicenseStatusEnum.INACTIVE:
			return 'warning';
		case LicenseStatusEnum.EXPIRED:
		case LicenseStatusEnum.CANCELLED:
			return 'danger';
		case LicenseStatusEnum.BLACKLISTED:
			return 'dark';
		default:
			logError(`Not supported variant color: ${value}`);
			return undefined;
	}
};

/**
 * Format status as colored HTML element
 *
 * @param {LicenseStatusEnum} status
 * @param {string} value
 * @returns {JSX.Element}
 */
export const licenseStatusFormatter = (status: LicenseStatusEnum, value?: string) => {
	const color = getVariantColorForLicenseStatus(status);
	const className = classNames({
		[`text-${color}`]: Boolean(color),
	});
	return <span className={className}>{value || status}</span>;
};

/**
 * Pricing expect specified date format, so convert Date into the format
 * @param {Date | string} date
 * @returns {string}
 */
export const dateToPricingDate = (date: Date | string | undefined): string | undefined => {
	if (isUndefined(date)) {
		return undefined;
	}

	let dateTime: DateTime;
	if (isString(date)) {
		dateTime = DateTime.fromISO(date);
	} else {
		dateTime = DateTime.fromJSDate(date);
	}

	return dateTime.toFormat('MM/dd/yyyy');
};

/**
 * Extract external ID from license entity
 * @param {IEntityLicense} license
 * @param {LicenseExternalIdEnum} id
 * @returns {string | null}
 */
export const getLicenseExternalId = <T extends string = string>(
	license: IEntityLicense,
	id: LicenseExternalIdEnum,
): T | null => {
	const value = license.externalIds?.find(({ type }) => type === id)?.value;
	return value ? (value as T) : null;
};

/**
 * @param {IEntityLicense} license
 * @param {LicenseContextIdEnum} id
 * @returns {string | null}
 */
export const getLicenseContextValue = (license: IEntityLicense, id: LicenseContextIdEnum): string | null =>
	license.contexts?.find(({ type }) => type === id)?.value || null;

/**
 * @param {IEntityLicense} license
 * @returns {string | null}
 */
export const getWalletKey = (license: IEntityLicense): string | null =>
	getLicenseContextValue(license, LicenseContextIdEnum.WALLET_KEY);

/**
 * @param {IEntityLicense} license
 * @returns {string | null}
 */
export const getLicenseType = (license: IEntityLicense): string | null => {
	// get first license and it's first license handler
	const licenseString = getLicenseString(license);

	return licenseString ? licenseString.handler : null;
};

/**
 * Returns object with additional data for licenses. The object should be flattened in the future.
 *
 * @param {IEntityLicense} license
 * @returns {TLicenseString | undefined}
 */
const getLicenseString = (license: IEntityLicense): TLicenseString | undefined => {
	const licenses = head(license.licenses || []);
	return head(licenses?.licenseStrings || []);
};

/**
 * @param {IEntityLicense} license
 * @returns {number | null}
 */
export const getLicenseId = (license: IEntityLicense): number | null =>
	// get first license id
	license.licenses?.find(constant(true))?.id || null;

/**
 * @param {IEntityLicense} license
 * @returns {string | null}
 */
export const getFulfillmentValue = (license: IEntityLicense): string | null => {
	return license.fulfillment?.value || null;
};

/**
 * Get the entitlementId of the previous license of the entered license.
 * @param {IEntityLicense} license
 * @returns {TEntityLicenseId | undefined}
 */
export const getPreviousLicenseId = (license: IEntityLicense): TEntityLicenseId | undefined => {
	const previousEntitlement = head(license?.linksToPrevious);

	if (previousEntitlement) {
		if (!hasEntitlementLinkType(previousEntitlement, [EntitlementTypeEnum.ACTIVATION])) {
			return previousEntitlement.entitlementId;
		}
	}
};

/**
 * Get the entitlementId of the following license of the entered license.
 * @param {IEntityLicense} license
 * @returns {TEntityLicenseId | undefined}
 */
export const getFollowingLicenseId = (license: IEntityLicense): TEntityLicenseId | undefined => {
	const followingEntitlement = head(license?.linksToFollowing);
	return followingEntitlement?.entitlementId;
};

/**
 * Check if the entitlementLink contains the accepted types.
 * @param {ILinkToLicense} entitlementLink
 * @param {Partial<EntitlementTypeEnum>[]} acceptedTypes
 * @returns {boolean}
 */
export const hasEntitlementLinkType = (
	entitlementLink: ILinkToLicense,
	acceptedTypes: Partial<EntitlementTypeEnum>[],
): boolean => isDefined(entitlementLink.type) && acceptedTypes.includes(entitlementLink.type);

/**
 * Create an SKU code based on entered object.
 * @param {IEntityLicense} license
 * @returns {string} - "avb.5.12m"
 */
export const extractLicenseSku = (license: IEntityLicense): string => {
	const { product, id } = license;
	if (hasAllSkuParts(product)) {
		const { productGroup, validity, validityUnit } = product;
		const { dimension } = product;
		const unitLatter = getSkuUnitLetter(validityUnit);

		return `${[productGroup, dimension, validity].join('.')}${unitLatter}`;
	}

	isString(id) && logWarn(`Missing parts to build Sku for license #'${id}'.`);
	return product?.sku;
};

/**
 * Checks if object contains all necessary props.
 * @param {IEntityLicenseProduct} productData
 * @returns {boolean}
 */
const hasAllSkuParts = (productData: IEntityLicenseProduct): boolean => {
	const skuParts: ReadonlyArray<keyof IEntityLicenseProduct> = [
		'productGroup',
		'dimension',
		'validity',
		'validityUnit',
		'segment',
	];

	return skuParts.every((part) => has(productData, part));
};

/**
 * Creates a letter out of the unit string.
 * @param {string} unit
 * @returns {string} "m"
 */
const getSkuUnitLetter = (unit: string): string => unit.charAt(0).toLowerCase();

export const getBaseLicensesTableColumns = (
	t: TFunction,
	formattedStatus: boolean = false,
): TableColumnsDef<IEntityLicense> => {
	const columnHelper = createColumnHelper<IEntityLicense>();
	const formatter = createColumnFormatterHelper<IEntityLicense>();

	return [
		columnHelper.ellipsis('product.productGroupName', {
			header: t('common:entity.productName'),
			enableSorting: false,
			meta: { sticky: 'start' },
		}),
		columnHelper.text(extractLicenseSku, {
			header: t('common:entity.sku'),
			enableSorting: false,
		}),
		columnHelper.text((row) => getLicenseExternalIds(row).customerId, {
			header: t('common:entity.customer'),
			id: 'customer',
			enableSorting: false,
			meta: {
				formatters: [
					(_, { row }) => (
						<LicenseCustomerName
							license={row.original}
							ellipsis={30}
						/>
					),
				],
			},
		}),
		columnHelper.text('product.validity', {
			header: t('common:entity.validity'),
			enableSorting: false,
			meta: {
				formatters: [
					(validity, { row }) => {
						return t(`common:datePeriod.${row.original.product.validityUnit}`, { count: validity });
					},
				],
			},
		}),
		columnHelper.integer('product.dimension', {
			header: t('common:entity.unitType.SEATS', { count: 0 }),
			enableSorting: false,
		}),
		columnHelper.timeZoneDateTime('expiration', {
			header: t('common:dates.expire'),
			meta: { defaultValue: { value: noValuePlaceholder(t('common:_.NA')) } },
		}),
		columnHelper.text('status', {
			header: t('common:entity.status'),
			id: 'entitlementStatus',
			meta: {
				formatters: formattedStatus
					? [
							licenseStatusEnumUtils.getText,
							(value, row) => licenseStatusFormatter(row.getValue(), value),
							formatter.bold,
					  ]
					: [licenseStatusEnumUtils.getText],
			},
		}),
		columnHelper.link(
			getFulfillmentValue,
			{
				header: t('common:entity.licenseKey'),
				enableSorting: false,
			},
			{
				to: (row) => buildParametrizedRoute(RouteName.LICENSE.DETAIL, row.id),
				copy: true,
			},
		),
	];
};

export const showEntitlementId = (entitlementId: number | string) => {
	const [id] = entitlementId.toString().split('.');

	return id;
};

export const isLicenseCancellable = (license: IEntityLicense) =>
	licenseStatusEnumUtils.validateOneOf(license.status, [
		LicenseStatusEnum.ACTIVE,
		LicenseStatusEnum.INACTIVE,
		LicenseStatusEnum.REPLACED,
	]);

export const isLicenseSegment = (license: IEntityLicense, marketSegment: IEntityLicenseProduct['segment']) =>
	license?.product?.segment === marketSegment;

export const isAllowedLicenseOperations = (
	license: IEntityLicense,
	isAuthorizedBuyer: boolean,
	isGroupPartner: boolean,
) => {
	const basicRules = hasLicenseOperations(license) && isAuthorizedBuyer;
	const { partnerSalesforceId, customerId } = getLicenseExternalIds(license);

	// If billable party is EndCustomer
	if (!isGroupPartner && !partnerSalesforceId && Boolean(customerId)) {
		// Can only do operations on Business licenses
		return basicRules && isLicenseSegment(license, ProductSegmentEnum.SMB);
	}

	return basicRules;
};

export const getLicenseExternalIds = (license: IEntityLicense) => ({
	partnerSalesforceId: getLicenseExternalId(license, LicenseExternalIdEnum.SFDC_PARTNER_ID),
	customerId: getLicenseExternalId(license, LicenseExternalIdEnum.SFDC_CUSTOMER_ID),
	distributionPartnerId: getLicenseExternalId(license, LicenseExternalIdEnum.SFDC_DISTRIBUTION_PARTNER_ID),
	orderId: getLicenseExternalId(license, LicenseExternalIdEnum.SC_ORDER_ID),
	cbPeriodId: getLicenseExternalId(license, LicenseExternalIdEnum.SC_CB_PERIOD_ID),
});

export const exportedForTesting = {
	getLicenseString,
	hasAllSkuParts,
	getSkuUnitLetter,
};
