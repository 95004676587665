import { generateEnumUtilsWithDescription } from 'js/enums/generator/enumFunctions';
import { CustomerSegmentEnum } from 'module/purchase/enums';

export enum DiscountTypeEnum {
	EDUCATION = 'Education',
	GOVERNMENT = 'Government',
	NOT_FOR_PROFIT = 'Not for Profit',
}

interface IDiscountTypeEnumExtend {
	toCustomerSegment(value?: DiscountTypeEnum): CustomerSegmentEnum | undefined;
}

export const discountTypeEnumUtils = generateEnumUtilsWithDescription<DiscountTypeEnum, IDiscountTypeEnumExtend>(
	DiscountTypeEnum,
	{
		translatePath: 'enums:customer.discountType',
		toCustomerSegment(value) {
			switch (value) {
				case DiscountTypeEnum.EDUCATION:
					return CustomerSegmentEnum.EDUCATION;
				case DiscountTypeEnum.GOVERNMENT:
					return CustomerSegmentEnum.GOVERNMENT;
				case DiscountTypeEnum.NOT_FOR_PROFIT:
					return CustomerSegmentEnum.NOT_FOR_PROFIT;
				default:
					return undefined;
			}
		},
	},
);
