import { IStandardOrderInstanceCustomPrice } from 'module/purchase';
import { TCustomerQuoteForm } from 'module/purchase/components/customerQuote/AsyncCustomerQuoteModal';
import { getOrderInstanceItemQuantity } from 'module/purchase/utils/common';
import { useOrderContext } from 'js/contexts';

type TUseEditPrices = {
	getItemUnitPrice(item: IStandardOrderInstanceCustomPrice): number;
	getItemPrice(item: IStandardOrderInstanceCustomPrice): number;
	getTotalPrice(values: TCustomerQuoteForm): number;
	hasInvalidItems(values: TCustomerQuoteForm): boolean;
};

export const useCustomerQuotePrices = (): TUseEditPrices => {
	const { getOrderItem } = useOrderContext();

	/** @inheritDoc */
	const getItemUnitPrice: TUseEditPrices['getItemUnitPrice'] = (item) => item.unitPrice ?? 0;

	/** @inheritDoc */
	const getItemPrice: TUseEditPrices['getItemPrice'] = (item) => {
		const unitPrice = getItemUnitPrice(item);
		const orderItem = getOrderItem(item.id);

		if (unitPrice && orderItem) {
			const qty = getOrderInstanceItemQuantity(orderItem);
			return unitPrice * qty;
		}

		return 0;
	};

	/** @inheritDoc */
	const getTotalPrice: TUseEditPrices['getTotalPrice'] = (values) =>
		values.items.reduce<number>((acc, item) => acc + (getItemPrice(item) || 0), 0);

	/** @inheritDoc */
	const hasInvalidItems: TUseEditPrices['hasInvalidItems'] = (values) =>
		values.items.some((item) => !item.unitPrice && item.unitPrice !== 0);

	return {
		getItemUnitPrice,
		getItemPrice,
		getTotalPrice,
		hasInvalidItems,
	};
};
