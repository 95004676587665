import React, { useMemo } from 'react';
import { UiTable } from '@avast/react-ui-components';
import { IEntityLicense } from 'module/licenses';
import { getBaseLicensesTableColumns } from 'module/licenses/utils/common';
import { useInitiateRefundContext } from 'submodule/refunds/initiate/InitiateRefundContext';

type TSelectRefundLicenseTableProps = {
	licenses: IEntityLicense[];
	onSelect(license: IEntityLicense): void;
};

export const SelectRefundLicenseTable = (props: TSelectRefundLicenseTableProps) => {
	const { licenses, onSelect } = props;
	const { t } = useInitiateRefundContext();

	const columns = useMemo(() => getBaseLicensesTableColumns(t), [t]);
	return (
		<UiTable<IEntityLicense>
			columns={columns}
			data={licenses}
			meta={{
				onRowClick: onSelect,
				isScrollable: true,
				nowrapCell: true,
			}}
		/>
	);
};
