import { checkMimeType, getFileSizeMB } from 'js/components/fileUpload/fileUploadUtils';
import i18n from 'i18n';

type TFileUploadValidate = { valid: true } | { valid: false; error: string };

interface IFileUploadValidateOptions {
	maxSize?: number;
	acceptMimeType?: string;
}

export const fileUploadValidate = (file: File, options: IFileUploadValidateOptions): TFileUploadValidate => {
	const { maxSize, acceptMimeType } = options;

	// File types by mime types
	if (acceptMimeType && !checkMimeType(file, acceptMimeType)) {
		return { valid: false, error: i18n.t('form:error.file.type') };
	}

	// File size
	if (maxSize) {
		const size = getFileSizeMB(file);
		if (size > maxSize) {
			return { valid: false, error: i18n.t('form:error.file.maxSize', { size, maxSize }) };
		}
	}

	return { valid: true };
};
