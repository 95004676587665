export default {
	common: {
		continueToProducts: '繼續使用產品',
		orderValue: '訂單價值',
		remaining: '剩餘',
		partyPrefix: {
			your: '您的',
			partner: '合作夥伴',
			srp: 'SRP',
			customer: '客戶',
			quotedCustomer: '報價的客戶',
		},
		savingOrder: '正在儲存訂單...',
		exportingOrder: '正在匯出訂單...',
		loadingOrder: '正在載入訂單...',
		customerCreditStatus: {
			eligible: '客戶符合信用資格。',
			notEligible: '客戶不符合信用資格。',
		},
		previousLicense: '上一個授權碼',
		currency: {
			changeTooltip: '變更貨幣',
			selectPrompt: '選取客戶貨幣',
		},
		paginationText: '顯示全部 {{total}} 個項目中的<strong>第 {{from}} 個到第 {{to}} 個</strong>。',
	},
	discretionaryDiscount: {
		title: '酌情折扣',
		placeholder: '輸入折扣',
		approvalFlag: '核准狀態',
		amount: '金額',
		amountUnit: '單位價格',
		amountTotal: '總金額',
		status: {
			notSet: '訂單未設定酌情折扣。',
			lineNotSet: '單項未設定酌情折扣。',
			orderLevelDiscountIsSet: '此訂單已套用訂單層級的折扣。單項層級的折扣已停用。',
			lineLevelDiscountIsSet: '此訂單已套用單項層級的折扣。訂單層級的折扣已停用。',
		},
		action: {
			resetLineDiscounts: '移除單項折扣',
		},
		waitingApprovalMessage: '訂單中輸入了酌情折扣，需要銷售營運部門核准。',
	},
	error: {
		submitOrder: {
			title: '無法提交訂單',
			general: '發生錯誤，訂單提交失敗，請重新提交。',
		},
		pricing: {
			title: '無法計算價格',
		},
		context: {
			setPartnerFailed: '無法新增合作夥伴至訂單。',
		},
		creditExceed: {
			title: '此交易將超出您帳戶的可用信用額度。',
			text: '此交易不支援信用付款方式。請使用「購物車結帳」選項。',
		},
		requiredCustomer: {
			title: '需要客戶資訊',
			text: '請選取一位客戶以完成您的購買。',
			action: '選取客戶',
			tooltip: {
				question: '為什麼我們需要客戶資訊才能下訂單？',
				answer:
					'我們會要求您在下訂單時提供客戶資訊，以協助我們收集有關一般客戶的更多見解。透過更深入瞭解客戶，熟悉他們的習慣、需求和喜好，可以讓我們為您和您的客戶提供更優質的服務。',
			},
		},
		requiredDistributionPartner: {
			title: '需要經銷合作夥伴資訊',
			text: '請選取一位經銷合作夥伴以完成您的購買。',
			action: '選取經銷合作夥伴',
			tooltip: {
				question: '為什麼我們需要經銷合作夥伴資訊才能下訂單？',
				answer:
					'我們會要求您在下訂單時提供兩個層級的客戶資訊（經銷合作夥伴和經銷合作夥伴的客戶），以協助我們更深入瞭解他們，熟悉他們的習慣、需求和喜好，讓我們能夠為您和他們提供更優質的服務。',
			},
		},
	},
	components: {
		successOrder: {
			title: '成功！',
			action: {
				NEW_ORDER: '新訂單',
				DETAIL: '訂單詳細資料',
				QUOTE_DETAIL: '報價詳細資料',
				LICENSES: '檢視授權',
				EDIT: '編輯訂單',
				QUOTE_EDIT: '編輯報價',
				PAYMENT_INSTRUCTIONS: '付款指示',
			},
		},
		editPrices: {
			title: '編輯單價',
			message:
				'您只能編輯購物車中目前商品的單價。無法變更從這個畫面儲存的訂單。在繼續操作之前，請確保您的購物車不會再變更。',
			button: '編輯定價',
			columnLabel: '自訂單價',
			submit: '儲存訂單',
			confirmation: {
				message: '這會建立一個可以完成的訂單，但不能以任何方式編輯。您確定嗎？',
				success: '我確定，請儲存訂單',
			},
		},
		customerQuote: {
			error: {
				invalidItems: '需要自訂單價，才能儲存此訂單及預覽或傳送電子郵件。',
			},
			fields: {
				customerEmail: '至',
				partnerEmail: '副本',
				message: '個人訊息',
			},
			submit: '傳送電子郵件',
		},
	},
	page: {
		default: {
			title: '訂單詳細資料',
		},
		products: {
			title: '產品',
		},
		order: {
			title: '檢閱並確認',
		},
	},
	unit: {
		current: '目前 {{type}}',
		toIncrease: '其他 {{type}}',
		toDecrease: '移除 {{type}}',
		total: '總計 {{type}}',
	},
	entity: {
		privateNotes: '私密附註',
		publicNotes: '公開附註',
		opportunityId: '機會編號',
		marketSegment: '行業別',
		validityLabel: '用於 {{value}}',
		validityDescription: '期限長度 {{value}}',
	},
	pricing: {
		basePrice: '從 {{price}}',
		discount: {
			segment: '細分折扣',
			renewal: '續購續訂折扣',
			partner: '合作夥伴折扣',
			discretionary: '酌情折扣',
			promo: '促銷活動折扣',
		},
		details: {
			show: '顯示定價詳細資料',
			hide: '隱藏定價詳細資料',
		},
	},
	actions: {
		addNote: '新增附註',
		addToCart: '新增到購物車',
		goToCart: '前往購物車',
		exportCart: '匯出購物車',
	},
	message: {
		addToCart: {
			item: '- <strong>{{name}}</strong> - {{unit}} {{unitLabel}}，{{quantity}}x',
			title: '產品已新增到購物車。',
		},
		removedFromCart: '產品已從購物車中移除。',
		emptyCart: '所有產品都已從購物車中移除。',
		emptyCartConfirmation: '您確要將所有產品從購物車中移除嗎？',
	},
	table: {
		action: {
			selectVariant: '選取變體',
		},
		filter: {
			search: {
				placeholder: '按名稱或 SKU 尋找',
			},
			marketSegment: '{{type}} 產品',
		},
	},
	tableVariant: {
		priceNote: '價格包含所有客戶折扣',
		tooltip: {
			quantity: '數量值等於為所選產品訂購的單獨授權的數量。',
			unitType: {
				SEATS: '單一授權的套數。',
				SESSIONS: '單一授權的工作階段數量。',
				STORAGE: '',
				DEVICE: '',
			},
		},
	},
	widget: {
		common: {
			billableParty: '計費方',
			nonBillableParty: '非計費方',
		},
		selectCustomerCard: {
			createNew: '建立新客戶',
			note: '此訂單沒有客戶。<br/><strong>稍後註冊授權。</strong>',
			select: '選取客戶',
			title: '客戶',
			error: {
				load: '無法載入客戶',
			},
			tooltips: {
				change: '變更客戶',
				update: '更新客戶',
				cancel: '從訂單中移除客戶',
			},
		},
		selectDistributionPartnerCard: {
			createNew: '建立新經銷合作夥伴',
			note: '此訂單沒有經銷合作夥伴。',
			select: '選取經銷合作夥伴',
			title: '經銷合作夥伴',
			error: {
				load: '無法載入經銷合作夥伴',
			},
			tooltips: {
				change: '變更經銷合作夥伴',
				update: '更新經銷合作夥伴',
				cancel: '從訂單中移除經銷合作夥伴',
			},
		},
		selectPartnerCard: {
			note: '選取合作夥伴以查看折扣後的最終價格。',
			select: '選取合作夥伴',
			title: 'Avast 合作夥伴',
			error: {
				load: '無法載入合作夥伴',
			},
			tooltips: {
				change: '變更 Avast 合作夥伴',
				cancel: '從訂單中移除 Avast 合作夥伴',
			},
		},
	},
	paymentType: {
		SAVE: {
			action: '儲存',
			caption: '儲存供日後使用',
			button: '儲存供日後使用',
			successMessage: '您的訂單已儲存。',
			confirmationCaption: '儲存此訂單？',
		},
		QUOTE: {
			action: '傳送',
			caption: '做為報價傳送',
			button: '做為報價傳送',
			successMessage: '您的報價已排入佇列準備傳送。',
			confirmationCaption: '傳送此報價？',
		},
		END_CUSTOMER_QUOTE: {
			action: '傳送',
			caption: '做為報價傳送',
			button: '做為報價傳送',
			successMessage: '您的報價已排入佇列準備傳送。',
			confirmationCaption: '準備好下訂單了嗎？',
		},
		CUSTOMER_QUOTE: {
			action: '傳送',
			caption: '傳送客戶報價',
			button: '傳送客戶報價',
			successMessage: '您的報價已排入佇列準備傳送。',
			confirmationCaption: '您確定要傳送客戶報價電子郵件嗎？',
			confirmationMessage: '所有客戶單價都會儲存到訂單中，並向客戶傳送電子郵件。<br/>無法撤銷或復原此操作。',
		},
		GLOBAL_COMMERCE_CART_CHECKOUT: {
			action: '傳送',
			caption: '信用卡付款',
			button: '信用卡付款',
			successMessage: '您的訂單已成功下達。',
			confirmationCaption: '準備好下訂單了嗎？',
		},
		GLOBAL_COMMERCE_CLOSING_BALANCE: {
			action: '傳送',
			caption: '期末結餘',
			button: '以期末結餘提交',
			successMessage: '您的訂單已成功下達。',
			confirmationCaption: '準備好下訂單了嗎？',
		},
		GLOBAL_COMMERCE_LINE_OF_CREDIT: {
			action: '傳送',
			caption: '以貸記提交',
			button: '以貸記提交',
			successMessage: '您的訂單已成功下達。',
			confirmationCaption: '準備好下訂單了嗎？',
		},
		ORACLE_CART_CHECKOUT: {
			action: '傳送',
			caption: '透過信用卡付款',
			button: '透過信用卡付款',
			successMessage: '您的訂單已成功付款。',
			confirmationCaption: '準備好下訂單了嗎？',
		},
		ORACLE_LINE_OF_CREDIT: {
			action: '傳送',
			caption: '以貸記提交',
			button: '以貸記提交',
			successMessage: '您的訂單已成功下達。',
			confirmationCaption: '準備好下訂單了嗎？',
		},
		ORACLE_CLOSING_BALANCE: {
			action: '傳送',
			caption: '期末結餘',
			button: '以期末結餘提交',
			successMessage: '您的訂單已成功下達。',
			confirmationCaption: '準備好下訂單了嗎？',
		},
	},
	guards: {
		partnerPriceListGuard: {
			title: '變更合作夥伴確認',
			message: '所選合作夥伴的允許價目表與目前合作夥伴的不同。如果繼續此操作，購物車將會清空。',
		},
		partnerTermsGuard: {
			title: '合作夥伴無法下單',
			message: '所選合作夥伴尚未接受條款與條件。',
		},
		priceListGuard: {
			title: '混合產品價目表',
			messageCaption: '請注意，我們無法在一張訂單中合併 CloudCare 和 OMS 產品。您確定要將此產品新增到購物車嗎？',
			message: '您先前新增的產品將自動移除，並且將此商品新增到購物車。',
		},
		maxCartItemsGuard: {
			title: '超過最大細目數量',
			message: '一張訂單可以包含最多 {{count}} 個細目。請移除一些項目後再繼續。',
			message_other: '一張訂單可以包含最多 {{count}} 個細目。請移除一些項目後再繼續。',
		},
	},
	priceList: {
		mixWarning: '請注意，由於每個產品的定價和折扣各不相同，因此我們無法在一張訂單中合併 CloudCare 和 OMS 產品。',
		code: {
			PREPAID_OMS: 'OMS 產品',
			PREPAID_CC_LEGACY: 'CloudCare 產品',
			PREPAID_CC_MSRP: 'CloudCare 產品',
		},
	},
	tooltips: {
		remove: '從購物車中移除此商品項目',
		emptyCart: '從購物車中移除所有商品項目',
	},
};
