import { TUserAppMessageId, TUserAppMessagesConfig } from 'submodule/AppMessages/types';
import { Reducer } from 'react';
import { logError } from 'js/utils/app';

export type TAppMessagesReducerState = {
	isReady: boolean;
	isChanged?: boolean;
	loading?: boolean;
	messagesConfig: TUserAppMessagesConfig;
	hiddenMessageIds: TUserAppMessageId[];
};

type TAppMessagesReducerAction =
	| { type: 'SET_DATA'; payload: TUserAppMessagesConfig }
	| { type: 'HIDE_MESSAGE'; payload: TUserAppMessageId };

export const appMessagesReducer: Reducer<TAppMessagesReducerState, TAppMessagesReducerAction> = (state, action) => {
	switch (action.type) {
		case 'SET_DATA':
			return {
				isReady: true,
				isChanged: false,
				loading: false,
				messagesConfig: action.payload,
				hiddenMessageIds: action.payload.filter((item) => item.hidden).map((item) => item.id),
			};
		case 'HIDE_MESSAGE':
			return {
				...state,
				isChanged: true,
				messagesConfig: [...state.messagesConfig, { id: action.payload, hidden: true }],
				hiddenMessageIds: [...state.hiddenMessageIds, action.payload],
			};
		default:
			logError('Not supported action', action);
			return state;
	}
};
