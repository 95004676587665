import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum PromotionScopeEnum {
	PARTY_AND_PRODUCT = 'PARTY_AND_PRODUCT',
	PARTY_ONLY = 'PARTY_ONLY',
	PRODUCT_ONLY = 'PRODUCT_ONLY',
}

export const promotionScopeEnumUtils = generateEnumUtils<PromotionScopeEnum>(PromotionScopeEnum, {
	translatePath: 'enums:promotion.scope',
});
