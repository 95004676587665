export default {
	dateRangeType: {
		BILLING: 'Fecha de facturación',
		CREATED: 'Fecha de creación',
		PAYMENT: 'Fecha de pago',
	},
	tierCertification: {
		DISTRIBUTORS: 'Distribuidores',
		TIER_4: 'Nivel 4',
		TIER_3: 'Nivel 3',
		TIER_2: 'Nivel 2',
		TIER_1: 'Nivel 1',
	},
	customer: {
		type: {
			BUSINESS: 'Empresa',
			CONSUMER: 'Cliente',
		},
		discountType: {
			EDUCATION: {
				name: 'Educación',
				description:
					'Institución educativa con descuento adicional: Para aplicar este descuento en su pedido, contacte con el Administrador de ventas.',
			},
			GOVERNMENT: {
				name: 'Gobierno',
				description:
					'Institución gubernamental con descuento adicional: Para aplicar este descuento en su pedido, contacte con el Administrador de ventas.',
			},
			NOT_FOR_PROFIT: {
				name: 'Sin fines de lucro',
				description:
					'Institución sin fines de lucro con descuento adicional. Para aplicar este descuento en su pedido, contacte con el Administrador de ventas.',
			},
		},
		searchKey: {
			EMAIL: 'Correo electrónico',
			SALESFORCE_ID: 'Id. de Salesforce',
		},
	},
	partner: {
		type: {
			DISTRIBUTOR: 'Distribuidor',
			RESELLER: 'Revendedor',
			INTERNAL_COMPANY: 'Empresa interna',
			USER: 'Usuario',
		},
		subType: {
			RETAIL: 'Comercio minorista',
			ISP: 'ISP',
			MSP: 'MSP',
			NONE: 'Ninguno',
		},
		terms: {
			DR: 'Acuerdo de membresía de participante de canal de Digital River',
			CLIENT: 'Acuerdo de socio',
		},
		payAsYouGoPaymentMethod: {
			LINE_OF_CREDIT: {
				name: 'LOC',
				description:
					'Se generará una factura inmediatamente después de finalizar un mes. Se aplicarán términos de pago estándar. Una factura de LOC solo se puede pagar mediante transferencia bancaria. ',
			},
			AUTO_BILLING: {
				name: 'Facturación automática',
				description:
					'Los cargos se enviarán a la tarjeta que tenga guardada automática e inmediatamente después de finalizar un mes. En caso de que falle un intento, se generará una factura de LOC estándar.',
			},
		},
	},
	order: {
		discountCategory: {
			NONE: 'Ninguno',
			DISCRETIONARY: 'A discreción',
		},
		lineItemDiscountType: {
			STANDARD: 'Estándar',
			SPECIAL: 'Especial',
			NONE: 'Ninguno',
		},
		approvalFlag: {
			NA: 'N/A',
			APPROVAL_NOT_REQUIRED: 'Aprobación no requerida',
			APPROVAL_REQUIRED: 'Aprobación requerida',
			PENDING_APPROVAL: 'Pendiente de aprobación',
			APPROVED: 'Aprobado',
			REJECTED: 'Rechazado',
		},
		billablePartyType: {
			PARTNER: 'Socio',
			DISTRIBUTOR: 'Distribuidor',
			END_CUSTOMER: 'Cliente final',
		},
		searchKey: {
			COMMERCE_ORDER_ID: 'Id. de pedido facturable',
			OPPORTUNITY_ID: 'Id. de oportunidad',
			ORDER_NUMBER: 'Número de pedido',
			PO_NUMBER: 'Número de PO',
			QUOTE_NUMBER: 'Número de cotización',
		},
		status: {
			SAVED_IN_PROGRESS: {
				name: 'Proceso de guardado en curso',
				description: 'El pedido se guardó, pero no se envió. Solo se pueden modificar los pedidos en este estado.',
			},
			ISSUED_AS_CUSTOMER_QUOTE: {
				name: 'Emitido como cotización para cliente',
				description:
					'El pedido es una cotización enviada a un cliente. El cliente no puede editarla, sino solo enviarla.',
			},
			ISSUED_AS_QUOTE: {
				name: 'Emitido como cotización',
				description: 'El pedido es una cotización de Avast. No se puede editar, sino solo enviar.',
			},
			POSTED_TO_STATEMENT: {
				name: 'Publicado en una declaración',
				description: 'El pedido se publicó en una declaración mensual.',
			},
			PENDING_SUBMISSION: {
				name: 'Envío pendiente',
				description: '',
			},
			IN_REVIEW: {
				name: 'En revisión',
				description: 'El pedido está esperando su revisión. No se puede editar.',
			},
			SUBMITTED_TO_COMMERCE: {
				name: 'Enviado a comercio',
				description: 'El pedido se envió para facturar.',
			},
			PAYMENT_ERROR: {
				name: 'Error de pago',
				description:
					'Se produjo un error de pago. Se debe volver a enviar el pedido después de verificar que la información de pago sea correcta y esté actualizada.',
			},
			OTHER_ERROR: {
				name: 'Otro error',
				description:
					'Se produjo un error del sistema y persistió después de tres nuevos intentos. Se debe volver a enviar el pedido para volver a probar o se debe contactar con el servicio de asistencia.',
			},
			WAITING_PAYMENT: {
				name: 'Esperando el pago',
				description: 'El pedido se envió para facturar. El pago está vencido.',
			},
			COMPLETE: {
				name: 'Completo',
				description: 'El pago se recibió y el pedido está completo.',
			},
			CANCELED_BY_USER: {
				name: 'Cancelado por el usuario',
				description: 'Un usuario eligió cancelar el pedido.',
			},
			CANCELED_FOR_FAILURE_TO_PAY: {
				name: 'Cancelado por error en el pago',
				description: 'Se canceló el pedido porque se produjo un error en el pago total del pedido.',
			},
			EXPIRED: {
				name: 'Expirado',
				description: 'El pedido no se modificó durante los 30 días posteriores a su creación.',
			},
			NOT_SUBMITTED: {
				name: 'No enviado',
				description: 'El pedido no se envió para facturación.',
			},
		},
	},
	payment: {
		status: {
			PAID: {
				name: 'Pagado',
				description: 'El pedido se procesó correctamente y el pago se recibió.',
			},
			UNPAID: {
				name: 'No pagado',
				description: 'El pedido está esperando el pago o el pago no se recibió aún.',
			},
			PARTIAL_PAID: {
				name: 'Parcialmente pagado',
				description: 'Al pedido le queda un saldo pendiente.',
			},
			NA: {
				name: 'No facturado',
				description: 'El pedido aún no se facturó o está esperando la factura.',
			},
			UNBILLED: {
				name: 'No facturado',
				description: 'El pedido aún no se facturó o está esperando la factura.',
			},
			SETTLED: {
				name: 'Liquidado',
				description: 'El área de recaudación procesó y completó el pedido.',
			},
		},
		type: {
			WIRE_TRANSFER: 'Transferencia bancaria',
			WTOC: 'WTOC',
			CART_SELECTED: 'Carrito seleccionado',
			CLOSING_BALANCE: 'Saldo final',
			LOC: 'LOC',
			AUTO_BILLING: 'Facturación automática',
		},
	},
	licenses: {
		searchKey: {
			LICENSE_KEY: 'Clave de licencia',
			ORDER_NUMBER: 'Número de pedido',
		},
		status: {
			ACTIVE: {
				name: 'Activa',
				description: 'La licencia está en uso.',
			},
			INACTIVE: {
				name: 'Inactiva',
				description: 'El código de activación no se usó.',
			},
			CANCELLED: {
				name: 'Cancelada',
				description: 'La licencia se canceló o se reembolsó.',
			},
			REPLACED: {
				name: 'Reemplazada',
				description: 'Un derecho activo se reemplazó con otro derecho activo durante la operación.',
			},
			EXPIRED: {
				name: 'Expirado',
				description: 'La fecha de expiración de un derecho activo ya transcurrió.',
			},
			USED: {
				name: 'Usada',
				description: 'La licencia se usó.',
			},
			MIGRATED: {
				name: 'Migrada',
				description: 'La licencia migró a la licencia de un producto sucesor.',
			},
			BLACKLISTED: {
				name: 'En lista vedada',
				description: '',
			},
		},
		datepickerDateType: {
			CREATED_AT: 'Fecha de creación',
			EXPIRATION: 'Fecha de expiración',
			REGISTERED_AT: 'Fecha de registro',
		},
	},
	closingBalance: {
		searchKey: {
			ORDER_NUMBER: 'Número de pedido',
		},
	},
	creditStatus: {
		transactionSource: {
			CANCELLED_LICENSE: 'Licencia cancelada',
			CANCELLED_ORDER: 'Pedido cancelado',
			MSP: 'MSP',
			NEW_ORDER: 'Nuevo pedido',
			OMS_REFUND: 'Reembolso',
			PAID_ORDER: 'Pedido pagado',
			SAP: 'SAP',
			SAP_TAX: 'Impuesto',
			OMS_TAX: 'Impuesto',
			ORACLE_TAX: 'Impuesto',
			ADMIN_CUSTOM: 'Ajuste personalizado',
			PAY_AS_YOU_GO: 'Facturación flexible',
			TAX_REFUND: 'Reembolso de impuestos',
			PAY_AS_YOU_GO_CONSOLIDATION: 'Facturación flexible: corrección',
			SETTLED_ORDER: 'Liquidado',
		},
	},
	purchase: {
		marketSegment: {
			BUSINESS: 'Empresa',
			CONSUMER: 'Cliente',
		},
		billablePartyType: {
			PARTNER: 'Socio',
			CUSTOMER: 'Cliente',
		},
		type: {
			PREPAID: 'Prepago',
			FLEXIBLE_BILLING: 'Facturación flexible',
		},
	},
	promotion: {
		scope: {
			PARTY_ONLY: 'Solo parte',
			PRODUCT_ONLY: 'Solo producto',
			PARTY_AND_PRODUCT: 'Parte y producto',
		},
		type: {
			DISCRETIONARY: 'A discreción',
			SALES_CAMPAIGN: 'Campaña de ventas',
		},
		partyType: {
			DISTRIBUTION: 'Distribuidor',
			RESELLER: 'Revendedor',
			END_CUSTOMER: 'Cliente final',
		},
		partyTier: {
			DISTRIBUTORS: 'Distribuidores',
			NONE: 'Ninguno',
			TIER_4: 'Nivel 4',
			TIER_3: 'Nivel 3',
			TIER_2: 'Nivel 2',
			TIER_1: 'Nivel 1',
		},
		state: {
			ENABLED: 'Activada',
			DISABLED: 'Desactivada',
		},
	},
	retail: {
		status: {
			COMPLETE: 'Completo',
			IN_PROGRESS: 'En curso',
		},
	},
	refunds: {
		status: {
			OPEN: 'Abierta',
			FULLY_APPLIED: 'Completamente aplicada',
			VOIDED: 'Anulada',
			PENDING: 'Pendiente',
		},
		type: {
			ORDER: 'Reembolso de pedido',
			LINE: 'Reembolso de artículo de línea',
			KEY: 'Reembolso de clave',
			TAX: 'Reembolso de impuestos',
		},
		reason: {
			CUSTOMER_SATISFACTION_ISSUE: 'Problema de satisfacción del cliente',
			INCORRECT_PRICE_INVOICED: 'Precio incorrecto facturado',
			NOT_DELIVERED: 'No enviado',
			WRONG_PRODUCT: 'Producto incorrecto',
			PRODUCT_NOT_WORKING: 'Producto sin funcionar',
			TAX_EXEMPT: 'Exención de impuestos',
			OTHER_REASON: 'Otro motivo',
		},
	},
	comparativeFeature: {
		ONLINE_MANAGEMENT: {
			name: 'Plataforma de administración en línea',
			description:
				'Configure fácilmente la protección del dispositivo, administre sus suscripciones, supervise y agregue dispositivos, todo desde un solo lugar, dondequiera que sea, y en cualquier momento.',
		},
		DEVICE_PROTECTION: {
			name: 'Protección del dispositivo',
			description:
				'Antivirus galardonado que protege todos sus dispositivos contra software malicioso, phishing, ransomware y otros ciberataques avanzados.',
		},
		DATA_PROTECTION: {
			name: 'Protección de datos',
			description: 'Protege sus datos personales, de empresa y de cliente contra robos.',
		},
		IT_SUPPORT: {
			name: 'Asistencia de TI',
			description:
				'Asistencia rápida y fácil las 24 horas, de lunes a viernes, de la mano de nuestros ingenieros técnicos especializados, que pueden brindarle asistencia a usted y a sus empleados por correo electrónico, chat o teléfono.',
		},
		IDENTITY_PROTECTION: {
			name: 'Protección de identidad',
			description:
				'Incluye protección de la webcam y de contraseñas para resguardar las identidades en línea de sus empleados.',
		},
		UNLIMITED_VPN: {
			name: 'VPN ilimitada',
			description:
				'Nuestra VPN incorporada cifra los datos y protege las conexiones a Internet de sus empleados de modo que mantengan la privacidad al usar redes Wi-Fi públicas.',
		},
		USB_PROTECTION: {
			name: 'Protección de USB',
			description:
				'Impida que sus empleados usen dispositivos de almacenamiento extraíbles no autorizados, como unidades flash, unidades externas, tarjetas de memoria, etc. Bloquee, controle y supervise los puertos USB para evitar robos de datos, pérdidas de datos e infecciones de software malicioso.',
		},
		PATCH_MANAGEMENT: {
			name: 'Administración de parches',
			description:
				'Repara automáticamente vulnerabilidades de software y de aplicaciones de otros fabricantes que puedan ser susceptibles a ciberataques.',
		},
	},
	productGroupTag: {
		NEW: 'Nuevo',
	},
	widgets: {
		label: {
			QUOTES: 'Cotizaciones recientes',
			ORDERS: 'Pedidos recientes',
			CREDIT_STATUS: 'Estado crediticio',
			EXPIRING_LICENSES_NEXT_N_DAYS: 'Licencias que expirarán en los próximos N días',
			EXPIRED_LICENSES_LAST_N_DAYS: 'Licencias que expiraron en los próximos N días',
			CLOSING_BALANCE: 'Declaraciones de saldo final recientes',
		},
		caption: {
			QUOTES: 'Cotizaciones recientes',
			ORDERS: 'Pedidos recientes',
			CREDIT_STATUS: 'Estado crediticio',
			EXPIRING_LICENSES_NEXT_N_DAYS: 'Licencias que expirarán en los próximos {{count}} días',
			EXPIRED_LICENSES_LAST_N_DAYS: 'Licencias que expiraron en los últimos {{count}} días',
			CLOSING_BALANCE: 'Declaraciones de saldo final recientes',
		},
	},
};
