export default {
	entity: {
		createdBy: 'Erstellt von',
	},
	actions: {
		create: 'Kreditlimit erstellen',
	},
	error: {
		createCreditLimit: 'Das Kreditlimit kann nicht erstellt werden.',
	},
	modal: {
		create: {
			title: 'Kreditlimit erstellen',
			loading: 'Kreditlimit wird erstellt',
			success: 'Kreditlimit erfolgreich erstellt',
		},
	},
};
