import { Modal } from '@avast/react-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { IEntityDistributionPartner } from 'module/distributionPartners';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { DistributionPartnerForm } from 'module/distributionPartners/forms/DistributionPartnerForm';
import { useCreateDistributionPartner } from 'module/distributionPartners/hooks';

type TAsyncCreateDistributionPartnerModalProps = {
	onCreate: (distributionPartner: IEntityDistributionPartner) => void;
};

export const AsyncCreateDistributionPartnerModal = (
	props: TAsyncCreateDistributionPartnerModalProps & TAsyncModalContainerProps,
) => {
	const { onCreate, forwardedRef } = props;
	const [t] = useTranslation(distributionPartnersConfig.trNamespace);
	const createDistributionPartner = useCreateDistributionPartner();

	return (
		<>
			<Modal.Header>{t('page.create.title')}</Modal.Header>
			<Modal.Body>
				<DistributionPartnerForm
					onSubmit={async (values, { setSubmitting }) => {
						const distributionPartner = await createDistributionPartner(values);
						setSubmitting(false);

						if (distributionPartner) {
							onCreate(distributionPartner);
						}
					}}
					onCancel={() => {
						forwardedRef.current?.onCancel();
					}}
				/>
			</Modal.Body>
		</>
	);
};
