import { TApiErrorResolve } from 'types/api';
import { registrationConfig } from 'module/registration/registrationConfig';
import i18n from 'i18next';

export const resolveTermsAcceptedApiError: TApiErrorResolve = () => ({
	title: i18n.t(registrationConfig.trPrefix('error.unableToAcceptTerms')),
});

export const sendRegistrationInfoApiError: TApiErrorResolve = () => ({
	title: i18n.t(registrationConfig.trPrefix('error.unableToProcess')),
});

export const sendTaxExemptionFormApiError: TApiErrorResolve = () => ({
	title: i18n.t(registrationConfig.trPrefix('error.unableToProcess')),
});

export const finishingRegistrationApiError: TApiErrorResolve = () => ({
	title: i18n.t(registrationConfig.trPrefix('error.unableToProcess')),
});
