import { RouteName } from 'module/RouteName';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { securityConfig } from 'module/security/securityConfig';
import { IResetPasswordData } from 'module/security';
import { passwordResetApiError } from 'module/security/utils/apiError';
import { useApiResetPassword } from 'module/security/hooks/useApiSecurity';
import { H2 } from '@avast/react-ui-components';
import { useNavigate } from 'react-router-dom';
import { useApiErrorContext, useAppContext } from 'js/contexts';

export const usePasswordReset = () => {
	const [t] = useTranslation(securityConfig.trNamespace);
	const navigate = useNavigate();
	const { loadingModalRef, successModalRef } = useAppContext();
	const { setError } = useApiErrorContext();
	const { mutate: resetPassword } = useApiResetPassword();

	const goToLoginPage = useCallback(() => {
		navigate(RouteName.SECURITY.LOGIN);
	}, [navigate]);

	const resetPasswordRequest = useCallback(
		(values: IResetPasswordData) => {
			loadingModalRef.current?.show({ title: t('common:_.loading') });
			resetPassword(values, {
				async onSuccess() {
					await successModalRef.current?.show({
						content: (
							<H2
								className="text-center h3"
								bold
							>
								{t('page.passwordReset.success')}
							</H2>
						),
						submitButtonText: t('common.backToLogin'),
					});
					goToLoginPage();
				},
				onError(error) {
					setError({
						error,
						resolve: passwordResetApiError,
						onClose: goToLoginPage,
					});
				},
				onSettled() {
					loadingModalRef.current?.hide();
				},
			});
		},
		[goToLoginPage, loadingModalRef, resetPassword, setError, successModalRef, t],
	);

	return {
		resetPasswordRequest,
	};
};
