export default {
	accountInformation: 'Account Information',
	_: {
		dateRangePlaceholder: 'Select date',
		NA: 'N/A',
		no: 'No',
		pageDetailTitle: 'Detail #{{id}}',
		yes: 'Yes',
		loading: 'Loading...',
	},
	boolean: {
		true: 'Yes',
		false: 'No',
	},
	entity: {
		active: 'Active',
		amount: 'Amount',
		company: 'Company',
		companyId: 'Company ID',
		companyName: 'Company Name',
		created: 'Created',
		currency: 'Currency',
		customer: 'Customer',
		customerTotalPrice: 'Cust. Total Price',
		detailId: 'Detail ID',
		description: 'Description',
		disabled: 'Disabled',
		discountAmount: 'Discount Amount',
		discountAmountUnit: 'Unit Discount Amount',
		discountPercent: 'Discount Percent',
		discountType: 'Discount Type',
		discountUnitPrice: 'Discount Unit Price',
		distributionPartner: 'Distribution Partner',
		enabled: 'Enabled',
		id: 'ID',
		licenseKey: 'License Key',
		name: 'Name',
		orderStatus: 'Order Status',
		partnerId: 'Partner ID',
		partner: 'Partner',
		paymentAmount: 'Payment Amount',
		paymentMethod: 'Payment Method',
		paymentStatus: 'Payment Status',
		poNumber: 'PO Number',
		price: 'Price',
		priceWTax: 'Price incl. Tax',
		priceWOTax: 'Price excl. Tax',
		product: 'Product',
		productName: 'Product Name',
		quantity: 'Quantity',
		requestedDiscountType: 'Requested Discount Type',
		secondaryOrderId: 'Secondary Order ID',
		sku: 'SKU',
		srpUnitPrice: 'SRP Unit Price',
		state: 'State',
		status: 'Status',
		subTotalWOTax: 'Sub-total excl. Tax',
		tax: 'Tax',
		total: 'Total',
		totalAmount: 'Total Amount',
		totalAmountWOTax: 'Total Amount excl. Tax',
		totalAmountWTax: 'Total Amount incl. Tax',
		totalPrice: 'Total Price',
		totalTax: 'Total Tax',
		totalWTax: 'Total incl. Tax',
		transaction: 'Transaction',
		unit: 'Unit',
		unit_other: 'Units',
		unitPrice: 'Unit Price',
		unitType: {
			SEATS: 'Seat',
			SEATS_other: 'Seats',
			SESSIONS: 'Session',
			SESSIONS_other: 'Sessions',
			STORAGE: 'Storage',
			STORAGE_other: 'Storage',
			DEVICE: 'Device',
			DEVICE_other: 'Devices',
		},
		validity: 'Validity',
		variantCount: '{{count}} variant',
		variantCount_other: '{{count}} variants',
	},
	contact: {
		firstName: 'First Name',
		lastName: 'Last Name',
		name: 'Contact Name',
		email: 'E-mail',
		billingEmail: 'Billing E-mail',
		phone: 'Phone',
		website: 'Website',
		id: 'Contact ID',
		taxId: 'Tax ID',
		secondaryTaxId: 'Secondary Tax ID',
	},
	address: {
		caption: 'Address',
		city: 'City',
		country: 'Country',
		state: 'State/Province',
		postalCode: 'Postal Code',
		street: 'Street',
	},
	dates: {
		billing: 'Billing Date',
		created: 'Created Date',
		end: 'End Date',
		expire: 'Expiry Date',
		payment: 'Payment Date',
		paymentDue: 'Payment Due Date',
		start: 'Start Date',
	},
	datePeriod: {
		DAY: '{{count}} day',
		DAY_other: '{{count}} days',
		MONTH: '{{count}} month',
		MONTH_other: '{{count}} months',
		YEAR: '{{count}} year',
		YEAR_other: '{{count}} years',
	},
	actions: {
		back: 'Back',
		newPurchase: 'New Purchase',
		cancel: 'Cancel',
		close: 'Close',
		confirm: 'Confirm',
		continue: 'Continue',
		detail: 'View details',
		download: 'Download',
		emptyCart: 'Empty cart',
		logout: 'Logout',
		next: 'Next',
		previous: 'Previous',
		reset: 'Reset',
		retry: 'Retry',
		removeFile: 'Remove file',
		save: 'save',
		select: 'Select',
		submitForm: 'Submit',
		submitChanges: 'Submit Changes',
		show: 'Show',
		showAll: 'Show all',
		showMore: 'Show more',
		showLess: 'Show less',
		copied: 'Successfully copied',
	},
	validator: {
		email: 'Value must be a valid email.',
	},
	table: {
		useFilter: 'Use a filter to start searching',
	},
	filter: {
		quickFilters: 'Quick filters',
		partnerIsRequired: 'Please, select a partner to start a search.',
		typeAtLeastNCharacters: 'Type at least {{count}} characters in filter.',
	},
	header: {
		cart: 'Cart',
		cartIsEmpty: 'Cart is empty',
	},
	productGroupExceptions: {
		premiumRemoteControlQuantityDisabled:
			'As Premium Remote Control can be installed on an unlimited amount of devices, you are not required to change sessions for these items, as the concurrent sessions are based on how many devices you think you may connect to at the same time.',
		cloudBackupStorageInfo:
			'By changing the Storage attribute you are defining how many times you want to purchase a 100GB storage bucket. For E.g. “Storage = 5” means that the final purchased storage will be 500GB for one license.',
	},
	downloadFile: {
		loading: 'Downloading file {{filename}}',
		error: 'Unable to download the file',
	},
	appVersionGuard: {
		caption: 'A new version of OMS is available!',
		message: 'Please reload the page to get the latest version.',
		action: 'Reload',
	},
};
