import React from 'react';
import { DataTable, DataTableCard } from 'js/components/molecules/DataTable';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import { TDataTableCardProps } from 'js/components/molecules/DataTable/DataTableCard';
import { LanguageControl, LocaleFormatControl } from 'submodule/localeSwitch/components';
import { useAllowedLanguages } from 'js/hooks/useAllowedLanguages';
import { useAuthContext } from 'js/contexts';

export const PersonalizationCard = (props: Partial<TDataTableCardProps>) => {
	const { authPartner: partner, authPartnerGroups } = useAuthContext();
	const [t] = useTranslation(accountConfig.trNamespace);
	const allowedLanguages = useAllowedLanguages(authPartnerGroups);

	if (!partner || allowedLanguages.length === 1) {
		return null;
	}

	return (
		<DataTableCard
			caption={t('cards.personalization')}
			variant="simple"
			{...props}
		>
			<DataTable.Tr
				name={t('common.locale.language')}
				nameClassName="align-middle"
			>
				<LanguageControl />
			</DataTable.Tr>
			<DataTable.Tr
				name={t('common.locale.format')}
				nameClassName="align-middle"
			>
				<LocaleFormatControl />
			</DataTable.Tr>
		</DataTableCard>
	);
};
