import { Button } from '@avast/react-ui-components';
import { IconChevronLeft } from 'assets/image/icon';
import React from 'react';
import { useHelpContext } from 'js/help/HelpContext';
import { useTranslation } from 'react-i18next';
import { HelpStepEnum } from 'js/enums';

export const HelpBackButton = () => {
	const [t] = useTranslation('app');
	const { setStep } = useHelpContext();

	return (
		<Button
			className="btn-back"
			onClick={() => setStep(HelpStepEnum.HOME)}
			variant="link"
			size="sm"
			iconLeft={<IconChevronLeft />}
		>
			{t('help.main.title')}
		</Button>
	);
};
