import { generateEnumUtilsWithDescription } from 'js/enums/generator/enumFunctions';

export enum ClosingBalanceOrderStatusEnum {
	COMPLETE = 'Complete',
	NOT_SUBMITTED = 'Not Submitted',
	SUBMITTED_TO_COMMERCE = 'Submitted to Commerce',
	WAITING_PAYMENT = 'Waiting Payment',
	PAYMENT_ERROR = 'Payment Error',
	OTHER_ERROR = 'Other Error',
	CANCELED_BY_USER = 'Canceled by User',
	CANCELED_FOR_FAILURE_TO_PAY = 'Canceled for Failure to Pay',
}

export const closingBalanceOrderStatusEnumUtils = generateEnumUtilsWithDescription<ClosingBalanceOrderStatusEnum>(
	ClosingBalanceOrderStatusEnum,
	{ translatePath: 'enums:order.status' },
);
