import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { Col, Row } from 'js/components/atoms/Row';
import { FormikControl } from 'js/components/formik/FormikControl';
import { useIsOpportunityFieldDisabled } from 'module/purchase/utils/common';
import { useAuthContext } from 'js/contexts';

export const AdditionalData = (): ReactElement => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { isGroupInternal } = useAuthContext();
	const isOpportunityFieldDisabled = useIsOpportunityFieldDisabled();

	return (
		<Row
			align="end"
			multi
			size="sm"
		>
			<Col sm={6}>
				<FormikControl
					label={t('common:entity.poNumber')}
					groupProps={{ className: 'mb-0' }}
					validation={false}
				>
					<FormikControl.Input
						name="additionalInfo.externalPurchaseNumber"
						testId="externalPurchaseNumber"
						size="sm"
					/>
				</FormikControl>
			</Col>
			{isGroupInternal && (
				<Col sm={6}>
					<FormikControl
						label={t('entity.opportunityId')}
						groupProps={{ className: 'mb-0' }}
					>
						<FormikControl.Input
							name="additionalInfo.opportunityId"
							testId="opportunityId"
							disabled={isOpportunityFieldDisabled}
							size="sm"
						/>
					</FormikControl>
				</Col>
			)}
		</Row>
	);
};
