import app from './app';
import error from './error';
import form from './form';
import common from './common';
import enums from './enums';
import components from './components';
import submodules from './submodules';
import pricingMessage from './pricingMessage';
import modules from './modules';

export default {
	app,
	error,
	form,
	common,
	enums,
	components,
	submodules,
	pricingMessage,
	...modules,
};
