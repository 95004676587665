export default {
	actions: {
		addProductsToOrder: 'Aggiungi prodotti',
		cancel: 'Annulla ordine',
		cancelQuote: 'Annulla preventivo',
		createQuote: 'Crea nuovo preventivo',
		cancelLicense: 'Annulla licenza',
		cancelSelectedLicenses: 'Annulla la licenza selezionata',
		cancelSelectedLicenses_zero: 'Annulla le licenze selezionate',
		cancelSelectedLicenses_other: 'Annulla le licenze selezionate ({{count}})',
		detail: 'Visualizza i dettagli dell’ordine',
		detailQuote: 'Visualizza i dettagli del preventivo',
		documents: 'Visualizza i documenti dell’ordine',
		edit: 'Modifica ordine',
		editQuote: 'Modifica preventivo',
		export: {
			default: 'Esporta ordini',
			detailed: 'Esportazione dettagliata',
			quotes: 'Esporta preventivi',
		},
		exportQuotes: 'Esporta preventivi',
		licenses: 'Visualizza licenze',
		placeQuote: 'Inserisci il preventivo come ordine',
		priceDetails: 'Visualizza i dettagli del prezzo',
		thisMonth: 'Questo mese',
		viewClosingBalance: 'Visualizza ordine di chiusura del bilancio',
		pay: 'Paga',
		payEdit: 'Paga/Modifica',
		payEditQuote: 'Paga/Modifica preventivo',
		payQuote: 'Paga preventivo',
	},
	common: {
		flexibleBilling: 'Fatturazione flessibile',
		documents: 'Altri documenti',
		orderNumber: 'Ordine: n. {{id}}',
		quoteNumber: 'Preventivo: n. {{id}}',
		priceDetails: 'Dettagli prezzo',
		licenses: 'Licenze',
		itemLicenses: 'Licenze prodotto',
		paymentInstructions: 'Istruzioni di pagamento',
		pricingMessage: 'Messaggio sui prezzi completo',
		promoMessage: 'Messaggio promozionale completo',
		refunds: 'Rimborsi ordini',
		initiateRefund: 'Avvia rimborso',
	},
	entity: {
		approvalStatus: 'Stato approvazione',
		billablePartyName: 'Nome parte soggetta a fatturazione',
		billablePartyType: 'Tipo parte soggetta a fatturazione',
		created: 'Data di creazione',
		createdBy: 'Creato da',
		creationSource: 'Creato con',
		purchaseType: 'Tipo di acquisto',
		purchaseTypePlaceholder: 'Qualsiasi tipo di acquisto',
		creationSourcePlaceholder: 'Qualunque origine',
		date: 'Data',
		discountApplied: 'Sconto applicato',
		discountInformation: 'Informazioni sullo sconto',
		gcOrderNumber: 'N. ordine commerciale',
		paymentStatusPlaceholder: 'Qualsiasi stato',
		noDiscount: 'Nessuno sconto',
		opportunityId: 'Numero opportunità',
		orderAfterDiscount: 'Ordine al netto dello sconto',
		orderNumber: 'Numero ordine',
		orderNumberShort: 'N. ordine',
		orderStatusPlaceholder: 'Qualsiasi stato',
		originateAsQuote: 'Crea come preventivo',
		quoteNumber: 'Numero preventivo',
		quoteNumberShort: 'N. preventivo',
		quoteStatus: 'Stato preventivo',
		quoteType: 'Tipo preventivo',
		statementId: ' ID rendiconto',
		totalAmount: 'Valore',
	},
	error: {
		orderNotFound: 'Ordine n. {{id}} non trovato',
		orderNotEditable: 'Ordine n. {{id}} non modificabile',
		noProductsInOrder: {
			caption: 'Nessun prodotto nell’ordine',
			text: 'Aggiungi prodotti o annulla l’ordine. Le modifiche non sono applicabili finché l’ordine non viene inviato/salvato.',
		},
		orderChangesAreNotPersist: 'Completa o salva l’ordine per salvare le modifiche.',
		orderCancellation: 'Impossibile annullare l’ordine.',
		orderSubmissionCancellation: 'Impossibile annullare l’invio dell’ordine.',
	},
	discountType: {
		line: 'Sconto sulla linea',
		other: '{{type}} {{category}}',
	},
	tab: {
		customer: 'Cliente',
		discretionaryDiscount: 'Sconto discrez.',
		itemsInOrder: 'Articoli nell’ordine',
		itemsInQuote: 'Articoli nel preventivo',
		licenses: 'Licenze',
		notes: 'Note',
		orderStatus: 'Stato ordine',
		partner: 'Partner Avast',
		quoteStatus: 'Stato preventivo',
	},
	licenses: {
		inProcess: 'Attendi, stiamo richiedendo le licenze per il tuo ordine',
		error: {
			title: 'Impossibile recuperare le licenze per il tuo ordine al momento. Riprova più tardi.',
			detail:
				'Riproveremo automaticamente a recuperare le licenze. L’operazione potrebbe richiedere diversi minuti. Una volta generate, le licenze verranno visualizzate qui, oltre a esserti inviate tramite e-mail.',
		},
	},
	paymentInstructions: {
		title: 'Istruzioni di pagamento',
		description: 'Fornisci alla tua banca le seguenti istruzioni per il bonifico bancario',
		amount: 'Importo',
		referenceNumber: {
			label: 'Numero di riferimento del pagamento',
			note: 'Assicurati di includere questo numero o il pagamento potrebbe non arrivare a destinazione. Se la banca non consente di inserire questo numero per intero, copialo e incollalo nel campo Messaggio per il destinatario.',
		},
		accountHolder: 'Titolare del conto',
		bankName: 'Nome della banca',
		accountNumber: 'Numero di conto',
		iban: {
			label: 'IBAN',
			note: "L'IBAN, o International Bank Account Number, è uno standard globale utilizzato per identificare i conti bancari. Viene utilizzato per semplificare l’instradamento dei pagamenti e prevenire eventuali errori.",
		},
		bic: 'BIC',
		aba: 'ABA/Numero di instradamento',
		ach: 'ACH',
		error: {
			notAvailable: 'Istruzioni per il pagamento non disponibili.',
		},
	},
	cancelOrder: {
		title: 'Annullamento ordine',
		message:
			'Una volta annullato un ordine, non è possibile ripristinarlo. L’ordine sarà ancora visibile, ma non sarà possibile eseguire azioni su di esso.',
		success: 'Ordine annullato con successo.',
		error: 'Si è verificato un errore durante l’annullamento dell’ordine.',
		loading: 'Annullamento ordine n. {{orderNumber}}',
		wtoc: {
			title: 'Annullamento ordine WTOC',
		},
	},
	discountApproval: {
		approved: 'Se lo sconto discrezionale viene approvato, puoi concludere l’ordine.',
		rejected:
			'Lo sconto discrezionale non è stato approvato. Puoi modificare l’ordine e richiedere nuovamente l’approvazione.',
		alert: {
			caption: 'Lo sconto discrezionale è in attesa di approvazione',
			text: 'Esamina lo sconto e decidi se approvare o meno lo sconto discrezionale.',
			reject: 'Rifiuta',
			approve: 'Approva',
		},
		alertApprove: {
			caption: 'Sconto discrezionale non approvato',
			text: 'È ancora possibile approvare lo sconto.',
		},
		confirm: {
			approve: {
				title: 'Approva sconto',
				message: 'Conferma l’approvazione dello sconto.',
				button: 'Conferma - Approva sconto',
			},
			reject: {
				title: 'Rifiuta sconto',
				message: 'Conferma la mancata approvazione dello sconto.',
				button: 'Conferma - Rifiuta sconto',
			},
		},
		status: {
			success: {
				approve: 'Lo sconto è stato approvato con successo.',
				reject: 'Lo sconto è stato respinto con successo.',
			},
			error: 'Modifica stato sconto non riuscita.',
		},
	},
	cancelLicenses: {
		loading: 'Annullamento licenza',
		loading_other: 'Annullamento licenze',
		success: 'Annullamento della licenza completato',
		success_other: 'Annullamento delle licenze completato',
		confirm: {
			title: 'Annulla licenza',
			title_other: 'Annulla licenze',
			message: 'Annullare la licenza?',
			message_other: 'Annullare le licenze?',
			message_note:
				'In caso di annullamento di tutte le licenze rimanenti, l’intero ordine verrà annullato. Il processo di annullamento è irreversibile e irrevocabile.',
		},
	},
};
