import { IFormikContextValidator } from 'types/validator';
import { ITaxExemptionFormFields } from 'module/account';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { yupContextValidator } from 'js/utils/validator';

export const useTaxExemptionFormValidator = (): IFormikContextValidator<ITaxExemptionFormFields> => {
	type Values = ITaxExemptionFormFields;
	const [t] = useTranslation(accountConfig.trNamespace);

	const validationSchema = useMemo(
		() =>
			Yup.object().shape<Values>({
				companyName: Yup.string().ensure().label(t('common:entity.company')).required(),

				startDate: Yup.date().label(t('taxExemption.startDate')).required().nullable(),

				endDate: Yup.date().label(t('taxExemption.endDate')).required().nullable(),

				taxAuthority: Yup.string().ensure().label(t('taxExemption.taxAuthority')).required(),

				file: Yup.mixed()
					.required()
					.label(t('form.taxExemption.file'))
					.fileMaxSize()
					.fileMimeType(accountConfig.taxExemptFile.mimeType),
			}),
		[t],
	);

	return {
		validationSchema,
		validate(values) {
			return yupContextValidator<Values>(validationSchema, values);
		},
	};
};
