export const DEFAULT_ENTITY_VALUE = '–';

// Cache time in milliseconds
export const CACHE_TIME = {
	LOW: 60000, // 1min
	MEDIUM: 600000, // 10min
	HIGH: 3600000, // 60min
	EXTRA_HIGH: 144000000, // 240min
};

export const STATUS_CANCEL = 0;
export const STATUS_SUCCESS = 1;
