import { FormikControl } from 'js/components/formik/FormikControl';
import React from 'react';
import { useFormikContext } from 'formik';
import { IPriceCalculatorForm } from 'module/priceCalculator';
import { getMarketSegments } from 'module/partners/utils/partnerSelectors';

export const PartnerSelect = () => {
	const { setFieldValue, values } = useFormikContext<IPriceCalculatorForm>();

	return (
		<FormikControl>
			<FormikControl.SelectPartner
				name="partnerId"
				size="sm"
				onChange={(_, partner) => {
					setFieldValue('partner', partner);
					if (partner) {
						setFieldValue('countryCode', partner.countryCode, true);
						setFieldValue('currencyCode', partner.currencyCode, true);

						// Market segment
						const partnerMarketSegments = getMarketSegments({
							partner,
							purchaseBillablePartyType: values.billablePartyType,
						});
						if (values.marketSegment && !partnerMarketSegments.includes(values.marketSegment)) {
							setFieldValue('marketSegment', partnerMarketSegments[0]);
							setFieldValue('productGroupCode', undefined);
							setFieldValue('productGroup', null);
						}
					}
				}}
				detailed
			/>
		</FormikControl>
	);
};
