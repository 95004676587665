export default {
	error: {
		closingBalanceNotFound: 'Abschlusssaldo nicht gefunden!',
	},
	page: {
		detail: {
			title: 'Detailnr. {{id}}',
		},
	},
	tab: {
		detail: 'Detailansicht',
		info: 'Informationen',
		statement: 'Abrechnungsansicht',
	},
	entity: {
		amountWOTax: 'Betrag ohne Steuer',
		amountWTax: 'Betrag mit Steuer',
		companyTaxId: 'Firmen-Steuernr.',
		lineDetailId: 'Posten-Detail-ID',
		orderStatusPlaceholder: 'Beliebiger Status',
		paymentStatusPlaceholder: 'Beliebiger Status',
		period: 'Zeitraum',
		term: 'Vertragslaufzeit',
		partnerDiscount: 'Partnerrabatt',
		itemPriceWOTax: 'Einzelpostenpreis exkl. Steuern',
		fulfillmentCompleteDate: 'Bestelldatum',
		srpTotalPrice: 'SRP-Gesamtpreis',
	},
	actions: {
		downloadInvoice: 'Rechnung downloaden',
		export: {
			list: 'Abrechnungen exportieren',
			line: 'Elemente exportieren',
			lineDetail: 'Lizenzen exportieren',
		},
		viewRefunds: 'Rückerstattungen anzeigen',
	},
	common: {
		documents: 'Bestelldokumente',
		initiateRefund: 'Rückerstattung einleiten',
	},
	statement: {
		saleUnitPrice: 'Verkauf Stückpreis',
		pricingInfo: 'Preisangaben',
	},
};
