import React, { ReactElement } from 'react';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { ButtonComposition } from '@avast/react-ui-components';
import { InformationTable } from 'module/closingBalance/components';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { IEntityClosingBalance } from 'module/closingBalance';
import {
	InitiateRefundButton,
	OrderDocumentsButton,
	ViewRefundsButton,
} from 'module/closingBalance/components/buttons';

export const InformationTab = (): ReactElement => {
	const { data } = usePageDetailContext<IEntityClosingBalance>();

	return (
		<DefaultContainer>
			<ButtonComposition
				marginY
				wrap
			>
				<InitiateRefundButton />
				<ViewRefundsButton />
				<OrderDocumentsButton />
			</ButtonComposition>
			<InformationTable data={data} />
		</DefaultContainer>
	);
};
