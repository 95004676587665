import welcome from 'assets/image/help/guide/welcome.png';
import pricing from 'assets/image/help/guide/pricing.png';
import quickFilter from 'assets/image/help/guide/qucikFilter.png';
import licenseOperations from 'assets/image/help/guide/licenseOperations.png';
import shoppingCart from 'assets/image/help/guide/shoppingCart.png';
import quickAccess from 'assets/image/help/guide/quickAccess.png';
import quotes from 'assets/image/help/guide/quotes.png';
import finalStep from 'assets/image/help/guide/finalStep.png';

export const HELP_GUIDE_SLIDES = [
	'welcome',
	'pricing',
	'quickFilter',
	'licenseOperations',
	'shoppingCart',
	'quickAccess',
	'quotes',
	'finalStep',
] as const;
export type THelpGuideSlide = (typeof HELP_GUIDE_SLIDES)[number];

export const guideImage: Record<THelpGuideSlide, string> = {
	welcome,
	pricing,
	quickFilter,
	licenseOperations,
	shoppingCart,
	quickAccess,
	quotes,
	finalStep,
};
