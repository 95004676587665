export default {
	common: {
		forecastedSpend: 'Gasto pronosticado',
		currentSpend: 'Gasto actual',
		maxSpend: 'Gasto máximo',
		limit: 'Límite',
		noLimit: 'Sin límite',
		searchDistributionPartner: 'Encontrar socio de distribución por nombre',
		unlimited: 'Sin límite',
		unlimitedNote:
			'El gasto máximo puede superar su límite de crédito si no se establecen límites de uso. Cuando esto ocurra, los productos de su socio se desactivarán hasta que se incremente el límite de crédito o se reduzca el saldo.',
		valueBefore: 'Antes',
		valueAfter: 'Después de guardar cambios',
	},
	page: {
		title: 'Facturación flexible',
	},
	actions: {
		edit: 'Gestionar productos',
		viewOrders: 'Ver pedidos',
		enableProducts: 'Activar productos',
		enableAllProducts: 'Activar todos los productos',
		disableAllProducts: 'Desactivar todos los productos',
	},
	tab: {
		distributionPartners: 'Visión general de socios',
		overview: 'Visión general',
		reports: 'Informes',
	},
	modal: {
		confirmation: '¿Quiere hacerlo?',
		message: 'Los cambios que está guardando pueden tener implicaciones en su crédito. ¿Desea continuar?',
		apply: 'Aplicando cambios',
		success: 'Los cambios se aplicaron correctamente',
	},
	entity: {
		usageLimit: 'Límite de uso',
		yourPrice: 'Su precio',
	},
	invite: {
		info: 'Este socio aún no ha creado una cuenta en Business Hub. Puede configurar la facturación flexible para este socio, pero no será funcional hasta que se haya establecido su cuenta en Business Hub.',
		action: {
			send: 'Enviar invitación',
			resend: 'Reenviar invitación',
		},
		lastSent: 'La última invitación se envió a {{email}} el {{date}}.',
		processing: 'Estamos enviando una invitación al socio de distribución',
		success: 'Invitación enviada correctamente',
		description:
			'Envía una invitación por correo electrónico para el registro en Business Hub. Esta cuenta será la primera cuenta de administrador en el Hub (se pueden agregar administradores adicionales después). Una vez registrada, la nueva cuenta de Business Hub estará vinculada al registro del socio.',
		form: {
			create: {
				label: 'Crear Business Hub al guardar este socio',
				description:
					'Envía una invitación por correo electrónico para el registro en Business Hub una vez que se guarde este formulario. Esta cuenta será la primera cuenta de administrador en el Hub (se pueden agregar administradores adicionales después). Una vez registrada, la nueva cuenta de Business Hub estará vinculada al registro del socio.',
			},
			differentEmail: {
				partner: 'Enviar invitación al correo electrónico registrado',
				other: 'Enviar invitación a un correo electrónico diferente',
			},
		},
		modal: {
			title: 'Crear Business Hub para {{name}}',
		},
	},
	tooltips: {
		unlimitedMaxSpend:
			'El gasto máximo puede superar su límite de crédito si no se establecen límites de uso. Cuando esto ocurra, los productos de su socio se desactivarán hasta que se incremente el límite de crédito o se reduzca el saldo.',
		forecastedSpend: {
			distributor:
				'La cantidad que se gastará durante el período de facturación actual si el uso del producto en todos los socios se mantiene como está hoy.',
			partner:
				'La cantidad que se gastará durante el período de facturación actual si el uso del producto para este socio se mantiene como está hoy.',
		},
		currentSpend: {
			distributor:
				'La cantidad ya gastada durante el período de facturación actual en productos activos en todos los socios.',
			partner: 'La cantidad ya gastada durante el período de facturación actual en productos activos para este socio.',
		},
		maxSpend: {
			distributor:
				'La cantidad máxima que se puede cobrar si todos los productos activos en todos los socios alcanzan sus límites de uso.',
			partner:
				'La cantidad máxima que se puede cobrar si todos los productos activos para este socio alcanzan sus límites de uso.',
		},
	},
	error: {
		usageLimit: {
			title: 'En este momento, no se pueden guardar sus cambios.',
			message:
				'Los límites de uso de los productos que está tratando de configurar son más bajos que el total de límites de uso que su socio ha establecido en su Business Hub para sus clientes.',
			product:
				'El total de límites para <strong>{{product}}</strong> está actualmente establecido en <strong>{{limit}}</strong>.',
			note: 'Le recomendamos que contacte con su socio para establecer los límites de uso.<br />Si necesita ayuda, contacte con nuestro equipo de soporte.',
		},
	},
};
