import { IPricingRequestItem } from 'module/purchase';
import { IComparativeData } from 'submodule/comparativeFeatures';
import { useLicenseOperationPricing, useLicenseOperationPricingItem } from 'module/licenses/hooks/licenseOperation';
import { TUsePricingResponse } from 'module/licenses/hooks/licenseOperation/useLicenseOperationPricing';

export const useLicenseOperationComparativePricing = (
	comparativeData: IComparativeData | null,
): TUsePricingResponse => {
	const licenseOperationPricingItem = useLicenseOperationPricingItem();

	// Prepare pricing fragments
	const lineItems: IPricingRequestItem[] = [];
	comparativeData?.products.forEach((list) => {
		list.forEach((item) => {
			lineItems.push(licenseOperationPricingItem(item.productGroup, item.product));
		});
	});

	return useLicenseOperationPricing({ lineItems });
};
