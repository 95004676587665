import { useFormikContext } from 'formik';
import { IEntityPromotion, IPromotionForm } from 'module/promotions';
import {
	PromotionPartyTierEnum,
	promotionPartyTierEnumUtils,
	PromotionPartyTypeEnum,
	promotionPartyTypeEnumUtils,
	PromotionScopeEnum,
	promotionScopeEnumUtils,
} from 'module/promotions/enums';
import { Alert, Card } from '@avast/react-ui-components';
import { Col, Row } from 'js/components/atoms/Row';
import { FormikControl } from 'js/components/formik/FormikControl';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import classNames from 'classnames';
import { isEmpty, isString } from 'lodash';
import { FormikLocationSelector } from 'module/promotions/components/FormikLocationSelector';

const useHasPartyBlockError = (): boolean => {
	const { errors, touched, submitCount } = useFormikContext<IEntityPromotion>();
	const partyCriteriaList: Partial<keyof IEntityPromotion>[] = [
		'partnerId',
		'partnerTypeList',
		'partnerTypeList',
		'partnerCountryCodeList',
	];

	const partyCriteriaError = partyCriteriaList.some((item) => isString(errors[item]));
	const partyCriteriaTouched = partyCriteriaList.some((item) => touched[item]);

	// Any field has error and at least one field is touched
	return partyCriteriaError && (partyCriteriaTouched || submitCount > 0);
};

export const PromotionFormPartyBlock = () => {
	const [t] = useTranslation(promotionsConfig.trNamespace);
	const {
		values: { scope, partnerId, partnerTierList, partnerTypeList, partnerCountryCodeList },
	} = useFormikContext<IPromotionForm>();
	const hasError = useHasPartyBlockError();

	const isDisabled = !promotionScopeEnumUtils.validateOneOf(scope, [
		PromotionScopeEnum.PARTY_AND_PRODUCT,
		PromotionScopeEnum.PARTY_ONLY,
	]);
	const isPartnerSelected = Boolean(partnerId);
	const isPartnerDisabled = !isEmpty(partnerTypeList) || !isEmpty(partnerTierList) || !isEmpty(partnerCountryCodeList);

	return (
		<Card className={classNames('mt-3', { 'd-none': isDisabled })}>
			<Card.Header>{t('common.partyCriteria')}</Card.Header>
			<Card.Body>
				{hasError && (
					<Alert
						caption={t('common.partyCriteriaInfo')}
						variant="danger"
					/>
				)}
				<Row size="md">
					<Col sm={6}>
						<FormikControl label={t('common:entity.partner')}>
							<FormikControl.SelectPartner
								name="partnerId"
								disabled={isPartnerDisabled || isDisabled}
							/>
						</FormikControl>
					</Col>
					<Col sm={6}>
						<FormikControl label={t('entity.partyType', { count: 0 })}>
							<FormikControl.MultiSelect<PromotionPartyTypeEnum>
								name="partnerTypeList"
								options={promotionPartyTypeEnumUtils.getSelectOptions()}
								placeholder={t('placeholder.partyTypes')}
								disabled={isPartnerSelected || isDisabled}
								closeMenuOnSelect={false}
							/>
						</FormikControl>
					</Col>
					<Col sm={6}>
						<FormikControl label={t('entity.partyTier', { count: 0 })}>
							<FormikControl.MultiSelect<PromotionPartyTierEnum>
								name="partnerTierList"
								options={promotionPartyTierEnumUtils.getSelectOptions()}
								placeholder={t('placeholder.partyTiers')}
								disabled={isPartnerSelected || isDisabled}
								closeMenuOnSelect={false}
							/>
						</FormikControl>
					</Col>
					<Col sm={6}>
						<FormikLocationSelector
							disabled={isPartnerSelected || isDisabled}
							name="partnerCountryCodeList"
							blurInputOnSelect={false}
						/>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};
