export default {
	page: {
		default: {
			title: 'Il mio account',
		},
		taxExemption: {
			title: 'Certificato di esenzione fiscale',
			shortTitle: 'Esenzione fiscale',
			action: 'Invia nuova richiesta di esenzione fiscale',
		},
		flexibleBilling: {
			title: 'Fatturazione flessibile',
		},
		autoBillingAddCard: {
			title: 'Aggiungi nuova carta',
		},
	},
	error: {
		autoBillingSource: 'Impossibile caricare i dettagli della carta di credito, contatta il tuo account manager.',
	},
	cards: {
		accountInformation: 'Dati account',
		contactInformation: 'Informazioni di contatto',
		addressInformation: 'Informazioni sull’indirizzo',
		terms: 'Termini e Condizioni',
		legacyPaymentOptions: 'Carte salvate',
		personalization: 'Personalizzazione',
	},
	common: {
		locale: {
			language: 'Lingua di sistema',
			format: 'Formattazione data/numero',
		},
	},
	form: {
		creditCard: {
			owner: 'Informazioni sul titolare della carta',
			creditCard: 'Carta di credito',
			number: 'Numero carta',
			expiration: 'Scadenza (MM/AA)',
			cvv: 'CVV',
			submit: 'Salva carta di credito',
			isPreferredPayAsYouGoPaymentMethod: 'Imposta come metodo di pagamento preferito per la fatturazione flessibile',
		},
		taxExemption: {
			title: 'Invia nuova certificazione fiscale',
			taxAuthorityPlaceholder: 'Seleziona autorità fiscale',
			file: 'File esenzione fiscale',
			declaration:
				'Pena accusa di falsa testimonianza, dichiaro che, per quanto a mia conoscenza, le informazioni di cui sopra e la documentazione di supporto sono complete e accurate.',
			success: 'Il modulo di esenzione fiscale è stato inviato.',
		},
	},
	taxExemption: {
		taxAuthority: 'Autorità fiscale',
		startDate: 'Data di inizio esenzione',
		endDate: 'Data di fine esenzione',
		fileName: 'Nome file',
		status: {
			ELIGIBLE: 'Idoneo',
			NOT_ELIGIBLE: 'Non idoneo',
		},
	},
	flexibleBilling: {
		paymentMethod: {
			title: 'Metodo di pagamento',
			subtitle: 'Seleziona l’opzione di pagamento preferita',
			submitButton: 'Salva metodo di pagamento',
			success: 'Il metodo di pagamento preferito è stato salvato',
			addCardFirst: 'Aggiungi prima una carta di credito per consentire la fatturazione automatica.',
		},
	},
	creditCard: {
		entity: {
			number: 'Numero carta',
			expiration: 'Scadenza',
		},
		currentSource: {
			title: 'Fatturazione automatica',
			subtitle: 'La carta salvata',
			loading: 'Recupero dei dati della carta',
			notFound: 'Nessuna carta salvata.',
			create: 'Aggiungi nuova carta',
		},
		message: {
			successCreate: 'La carta è stata salvata.',
			successDelete: 'La carta è stata eliminata.',
			saving: 'Salvataggio carta',
		},
		delete: {
			title: 'Rimuovi la carta dall’account',
			confirmation: 'Rimuovere la carta (•••• {{lastFourDigits}})?',
			loading: 'Eliminazione della carta',
		},
		error: {
			bad_request: 'Carta di credito non valida. Riprova o contatta il tuo account manager. ',
		},
	},
	legacyPaymentOptions: {
		unableToLoad: 'Impossibile caricare le carte memorizzate.',
		noStoredCards: 'Nessuna carta salvata.',
	},
};
