import { PARTNER_GROUP } from 'module/partners/constants';
import { generateEnumUtils } from 'js/enums/generator/enumFunctions';
import { logError } from 'js/utils/app';

export enum TierCertificationEnum {
	TIER_1 = 'TIER_1',
	TIER_2 = 'TIER_2',
	TIER_3 = 'TIER_3',
	TIER_4 = 'TIER_4',
	DISTRIBUTORS = 'DISTRIBUTORS',
}

interface ITierCertificationEnumExtend {
	getId(value?: TierCertificationEnum): number | undefined;
}

export const tierCertificationEnumUtils = generateEnumUtils<TierCertificationEnum, ITierCertificationEnumExtend>(
	TierCertificationEnum,
	{
		translatePath: 'enums:tierCertification',
		getId(value) {
			switch (value) {
				case TierCertificationEnum.TIER_1:
					return PARTNER_GROUP.TIER_1;
				case TierCertificationEnum.TIER_2:
					return PARTNER_GROUP.TIER_2;
				case TierCertificationEnum.TIER_3:
					return PARTNER_GROUP.TIER_3;
				case TierCertificationEnum.TIER_4:
					return PARTNER_GROUP.TIER_4;
				case TierCertificationEnum.DISTRIBUTORS:
					return PARTNER_GROUP.TIER_DISTRIBUTORS;
				default:
					logError(`Not supported tier certification: ${value}`);
					return undefined;
			}
		},
	},
);
