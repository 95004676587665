import React, { ReactElement, ReactNode } from 'react';
import { CustomTable } from 'js/components/molecules/CustomTable';
import { numberFormatter } from 'js/utils/number';
import type { TableProps } from 'react-bootstrap';
import { Table as BSTable } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { FixedLengthArray } from 'type-fest';
import classNames from 'classnames';
import { isNumber } from 'lodash';

export type TPriceSummaryRows<N extends number = 1> = {
	header?: string;
	values: FixedLengthArray<number | ReactElement | null | undefined | false, N>;
	size?: 'sm';
};

export type TPriceSummary<N extends number = 1> = {
	headers: FixedLengthArray<ReactNode, N>;
	rows: TPriceSummaryRows<N>[];
};

export type TPriceSummaryProps = {
	tableConfig?: TableProps;
	isLoading?: boolean;
	currencyCode?: string;
	className?: string;
};

export const PriceSummary = <N extends number = 1>(props: TPriceSummary<N> & TPriceSummaryProps) => {
	const { tableConfig, headers, rows, currencyCode, isLoading, className } = props;
	const hasRowHeader = rows.some((row) => Boolean(row.header));

	return (
		<div className={classNames('text-end', className)}>
			<BSTable
				borderless
				className="table-transparent table-autoHeight table-custom"
				style={{ width: 'auto', display: 'inline-table' }}
				{...tableConfig}
			>
				<thead>
					<tr>
						{hasRowHeader && <CustomTable.Th />}
						{headers
							.filter((header) => header !== false)
							.map((header, index) => (
								<CustomTable.Th
									key={index}
									align="end"
									className="pt-0 pb-1 text-nowrap"
								>
									{header}
								</CustomTable.Th>
							))}
					</tr>
				</thead>
				<tbody>
					{rows.map((row, index) => (
						<tr key={index}>
							{row.header && (
								<CustomTable.Td
									align="right"
									className={classNames('py-0 text-secondary text-nowrap', {
										[`fs-${row.size}`]: Boolean(row.size),
									})}
								>
									{row.header}
								</CustomTable.Td>
							)}
							{row.values
								.filter((value) => value !== false)
								.map((value, index) => (
									<CustomTable.Td
										key={index}
										align="right"
										className="py-0"
									>
										<span
											className={classNames('text-secondary text-nowrap', {
												[`fs-${row.size}`]: Boolean(row.size),
												'h2 fw-bold': !row.size,
											})}
										>
											{(() => {
												if (isLoading) {
													return (
														<Skeleton
															width={160}
															height={26}
														/>
													);
												}

												if (isNumber(value)) {
													return numberFormatter.currency(value, currencyCode);
												}

												return value;
											})()}
										</span>
									</CustomTable.Td>
								))}
						</tr>
					))}
				</tbody>
			</BSTable>
		</div>
	);
};
