export default {
	actions: {
		generate: 'Genera codici di vendita al dettaglio',
		downloadLicenses: 'Scarica licenze richieste',
		refreshListItems: 'Aggiorna voci',
	},
	page: {
		list: 'Ricerca ID merce sfusa',
		licenses: 'Ricerca codici di vendita al dettaglio',
	},
	entity: {
		bulkId: 'ID merce sfusa',
		transactionDate: 'Data richiesta',
	},
	filter: {
		bulkId: {
			placeholder: 'Inserisci ID merce sfusa e vai...',
		},
	},
	generate: {
		submit: 'Invia richiesta',
		submitLoading: 'Salvataggio richiesta...',
		success: 'Richiesta creata',
		partner: {
			headline: 'di dist.',
			select: 'Seleziona partner',
			change: 'Modifica partner',
			placeholder: 'Seleziona un partner commerciale',
		},
		cart: {
			headline: 'Carrello',
			add: 'Aggiungi prodotti',
			removeTooltip: 'Rimuovi questo articolo dal carrello',
			placeholder: 'Aggiungi prodotti al carrello',
		},
		products: {
			headline: 'Seleziona prodotti',
			addAndClose: 'Aggiungi e chiudi',
			success: 'I prodotti sono stati aggiunti al carrello.',
		},
	},
};
