import { useApiCountry } from 'js/entities/country/useApiCountry';
import type { TSelectOptions } from '@avast/react-ui-components';
import { useMemo } from 'react';
import { hasCountryStates } from 'js/entities/country/countryUtils';

interface IUseCountryStateOptions {
	countryStatesOptions: TSelectOptions;
	hasCountryStates: boolean;
	isFetching: boolean;
	isLoading: boolean;
}

export const useCountryStatesOptions = (countryCode?: string | null): IUseCountryStateOptions => {
	const hasStates = hasCountryStates(countryCode);
	const {
		data: country,
		query: { isFetching, isInitialLoading },
	} = useApiCountry({
		filter: { countryCode: countryCode! },
		queryConfig: { enabled: Boolean(countryCode) && hasStates },
	});

	return {
		countryStatesOptions: useMemo(
			() =>
				country?.states.map((state) => ({
					value: state.code,
					label: state.name,
				})) || [],
			[country?.states],
		),
		hasCountryStates: hasStates,
		isLoading: isInitialLoading,
		isFetching,
	};
};
