import { Button } from '@avast/react-ui-components';
import { SelectCustomerModal } from 'module/customers/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { IEntityLicense } from 'module/licenses';
import { useRegisterCustomerToLicense } from 'module/licenses/hooks';
import { useAuthContext } from 'js/contexts';

export const LicenseRegistrationButton = ({ license }: { license: IEntityLicense }) => {
	const [t] = useTranslation(licensesConfig.trNamespace);
	const { authCompanyId, isGroupInternal } = useAuthContext();
	const { registerCustomerToLicense, isEnabled } = useRegisterCustomerToLicense(license);

	return (
		<SelectCustomerModal
			onChange={registerCustomerToLicense}
			partnerId={isGroupInternal ? null : authCompanyId}
			disallowPartnerCustomers
		>
			{({ setModalShow }) => (
				<Button
					variant="outline-primary"
					size="sm"
					onClick={() => setModalShow(true)}
					disabled={!isEnabled}
					testId="registerCustomerToLicense"
				>
					{t('actions.registerTo')}
				</Button>
			)}
		</SelectCustomerModal>
	);
};
