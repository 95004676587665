import React, { ReactElement } from 'react';
import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { FormikForm } from 'js/components/formik/FormikForm';
import { Row } from 'js/components/atoms/Row';
import { FormikCountrySelect } from 'js/components/formik/FormikCountrySelect';
import { useTranslation } from 'react-i18next';
import { ColInput } from 'js/components/molecules/Inputs/ColInput';
import { IEntityCustomer, TEntityCustomerDeepKeys } from 'module/customers/index';
import { customersConfig } from 'module/customers/customersConfig';
import { FormikControl } from 'js/components/formik/FormikControl';
import { useCustomerFormValidator } from 'module/customers/hooks/useCustomerFormValidator';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import { RouteName } from 'module/RouteName';
import { isRequired } from 'module/customers/utils/form';
import { FormikDiscountTypeControl } from 'module/customers/forms/FormikDiscountTypeControl';
import { customerTypeEnumUtils } from 'module/customers/enums';
import { FormikCustomerIndustrySelect } from 'js/components/formik/FormikCustomerIndustrySelect';
import { isCustomerBusiness, isCustomerConsumer } from 'module/customers/utils/customerSelectors';
import { customerFormNormalizer } from 'module/customers/normalizer';
import { useNavigate } from 'react-router-dom';
import { isFunction } from 'lodash';

export type TCustomerFormFields = IEntityCustomer;

type TCustomerFormProps = {
	initialValues?: TCustomerFormFields;
	onCancel?: () => void;
	onSubmit: FormikConfig<TCustomerFormFields>['onSubmit'];
};

/**
 * Form for create and update Customer
 * @param {TCustomerFormProps} props
 * @returns {React.ReactElement}
 * @constructor
 */
const CustomerForm = (props: TCustomerFormProps): ReactElement => {
	const [t] = useTranslation(customersConfig.trNamespace);
	const { validate } = useCustomerFormValidator();
	const navigate = useNavigate();

	const { initialValues = customersConfig.defaultFormValues, onCancel, onSubmit } = props;
	const isUpdate = Boolean(props.initialValues);

	return (
		<Formik<TCustomerFormFields>
			validate={validate}
			initialValues={initialValues}
			onSubmit={(values, formikHelpers) => {
				onSubmit(customerFormNormalizer.denormalize(values), formikHelpers);
			}}
		>
			{({ values }) => (
				<FormikForm testId="customerForm">
					<FormikControl label={t('entity.customerType')}>
						<FormikControl.SingleSelect
							name="contactType"
							options={customerTypeEnumUtils.getSelectOptions()}
							disabled={isUpdate && Boolean(initialValues.contactType)}
							required={isRequired(values, 'contactType')}
							isClearable={false}
						/>
					</FormikControl>

					<FormikDiscountTypeControl />

					<hr />

					{/* Base partner data */}
					<Row size="md">
						<ColInput
							name="companyName"
							label={t('common:entity.company')}
							colProps={{ sm: 12 }}
							hidden={isCustomerConsumer(values)}
							required={isRequired(values, 'companyName')}
						/>
						<ColInput
							name="firstName"
							label={t('common:contact.firstName')}
							colProps={{ sm: 6 }}
							required={isRequired(values, 'firstName')}
						/>
						<ColInput
							name="lastName"
							label={t('common:contact.lastName')}
							colProps={{ sm: 6 }}
							required={isRequired(values, 'lastName')}
						/>
						<ColInput
							name="email"
							label={t('common:contact.email')}
							colProps={{ sm: 12 }}
							required={isRequired(values, 'email')}
							type="email"
							placeholder="@"
							disabled={isUpdate}
							messages={isUpdate ? [] : [[t('page.create.form.email'), 'info']]}
						/>
						<ColInput
							name="phone"
							label={t('common:contact.phone')}
							colProps={{ sm: 6 }}
							required={isRequired(values, 'phone')}
						/>
						<ColInput
							name="website"
							label={t('common:contact.website')}
							colProps={{ sm: 6 }}
							required={isRequired(values, 'website')}
						/>
					</Row>

					<hr />

					{/* Address */}
					<Row size="md">
						<ColInput
							name="billing.street"
							label={t('common:address.street')}
							colProps={{ sm: 12 }}
							required={isRequired(values, 'billing.street')}
						/>
						<ColInput
							name="billing.postalCode"
							label={t('common:address.postalCode')}
							colProps={{ sm: 4 }}
							required={isRequired(values, 'billing.postalCode')}
						/>
						<ColInput
							name="billing.city"
							label={t('common:address.city')}
							colProps={{ sm: 8 }}
							required={isRequired(values, 'billing.city')}
						/>
					</Row>

					<FormikCountrySelect<TCustomerFormFields, TEntityCustomerDeepKeys>
						countryField="billing.countryCode"
						countryStateField="billing.stateCode"
						required={isRequired(values, 'billing.countryCode')}
					/>

					{isCustomerBusiness(values) && (
						<>
							<hr />

							<FormikCustomerIndustrySelect
								name="industry"
								required={isRequired(values, 'industry')}
							/>
						</>
					)}

					<FormikFooterControls
						submit={{ children: t(`actions.form.${isUpdate ? 'update' : 'create'}`) }}
						cancel={{
							onClick() {
								if (isFunction(onCancel)) {
									onCancel();
								} else {
									navigate(RouteName.CUSTOMERS.LIST);
								}
							},
						}}
					/>
				</FormikForm>
			)}
		</Formik>
	);
};

export { CustomerForm };
