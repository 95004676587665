import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum BillablePartyTypeEnum {
	PARTNER = 'Partner',
	DISTRIBUTOR = 'Distributor',
	END_CUSTOMER = 'End Customer',
}

export const billablePartyTypeEnumUtils = generateEnumUtils<BillablePartyTypeEnum>(BillablePartyTypeEnum, {
	translatePath: 'enums:order.billablePartyType',
});
