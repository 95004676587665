export default {
	error: {
		closingBalanceNotFound: 'No se encontró el saldo final.',
	},
	page: {
		detail: {
			title: 'Detalle #{{id}}',
		},
	},
	tab: {
		detail: 'Vista de detalles',
		info: 'Información',
		statement: 'Vista de declaración',
	},
	entity: {
		amountWOTax: 'Importe sin impuestos',
		amountWTax: 'Importe con impuestos',
		companyTaxId: 'Id. de impuesto para empresas',
		lineDetailId: 'Id. de detalle de línea',
		orderStatusPlaceholder: 'Cualquier estado',
		paymentStatusPlaceholder: 'Cualquier estado',
		period: 'Periodo',
		term: 'Plazo',
		partnerDiscount: 'Descuento de socio',
		itemPriceWOTax: 'Precio de artículo de línea sin impuestos',
		fulfillmentCompleteDate: 'Fecha de pedido',
		srpTotalPrice: 'Precio total de SRP',
	},
	actions: {
		downloadInvoice: 'Descargar factura',
		export: {
			list: 'Exportar declaraciones',
			line: 'Exportar artículos',
			lineDetail: 'Exportar licencias',
		},
		viewRefunds: 'Ver reembolsos',
	},
	common: {
		documents: 'Documentos de pedido',
		initiateRefund: 'Iniciar reembolso',
	},
	statement: {
		saleUnitPrice: 'Precio por unidad de venta',
		pricingInfo: 'Información de precios',
	},
};
