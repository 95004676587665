export default {
	common: {
		continue: 'Continuar registro',
		skip: 'Omitir por ahora',
		submitting: 'Enviando los datos...',
		finishing: 'Estamos finalizando el proceso de registro...',
		successRegistration: 'Se registró correctamente',
		successAcceptTerms: 'Los términos y condiciones se aceptaron correctamente. Gracias.',
	},
	steps: {
		init: 'Le damos la bienvenida',
		terms: 'Términos y condiciones',
		registrationInfo: 'Información vital',
		taxExemption: 'Exención de impuestos',
	},
	page: {
		welcome: {
			title: 'Le damos la bienvenida al proceso de registro de comercio electrónico',
			content: 'Se le guiará por los pasos necesarios para realizar el registro y se le solicitará lo siguiente:',
			conditionList: ['Aceptar los términos y condiciones', 'Actualizar y configurar la información de perfil vital'],
		},
		terms: {
			title: 'Revise y acepte nuestros términos y condiciones:',
			button: 'He leído los acuerdos y acepto los términos',
		},
		registrationInfo: {
			contactInformation: 'Información de contacto',
			accountInformation: 'Información de cuenta',
		},
		taxExemption: {
			title: 'Envíe los detalles de su exención de impuestos',
			subtitle: 'Complete la información solicitada sobre la exención de impuestos.',
		},
	},
	error: {
		unableToInit: 'No es posible iniciar el registro.',
		unableToRegistrationToken: 'No es posible obtener el token de registro.',
		unableToStart: 'No es posible comenzar el registro.',
		unableToLoadTerms:
			'No se pueden cargar los términos y condiciones. Contacte con el Administrador de cuentas para obtener ayuda.',
		unableToAcceptTerms: 'No es posible aceptar los términos para el socio actual.',
		unableToProcess: 'No es posible enviar sus datos para procesar en este momento.',
	},
	alert: {
		checkCurrentAccountInfo:
			'Hemos completado la información guardada para su cuenta en nuestros sistemas. Revise esta información y envíe las actualizaciones que sean necesarias. Los cambios que se envíen se registrarán para usar en el futuro.',
		changeAccountDataInPartnerPortal:
			'Si necesita modificar su información de contacto, complete el proceso de registro y visite la sección Mi perfil en el portal para socios de Avast Business para editar su información de perfil.',
	},
};
