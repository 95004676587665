import React from 'react';
import { WidgetRenderer } from 'submodule/widgets/view/WidgetRenderer';
import { Col, Row } from 'js/components/atoms/Row';
import { TWidgetGrid, TWidgetGridRow } from 'submodule/widgets';

type TWidgetsViewProps = { config: TWidgetGrid };

const isEmptyRow = (row: TWidgetGridRow) => row.every((column) => column.length === 0);

export const WidgetsView = ({ config = [] }: TWidgetsViewProps) => (
	<>
		{config.map((row, rowIndex) => {
			if (isEmptyRow(row)) {
				return null;
			}

			return (
				<Row
					key={`row-${rowIndex}`}
					className="mt-2"
				>
					{row.map((column, colIndex) => (
						<Col
							key={`column-${colIndex}`}
							lg={row.length === 1 ? 12 : 6}
						>
							{column.map((item, itemIndex) => (
								<Row
									multi
									key={`item-${itemIndex}`}
								>
									<Col
										className="col-12"
										key={`widget-${item.id}`}
									>
										<WidgetRenderer item={item.widget} />
									</Col>
								</Row>
							))}
						</Col>
					))}
				</Row>
			);
		})}
	</>
);
