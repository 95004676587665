import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { EndComponent, MiddleComponent, StartComponent } from 'submodule/RowDivider';

export type TRowDividerProps = {
	className?: string;
	startComponent?: ReactNode;
	middleComponent?: ReactNode;
	endComponent?: ReactNode;
};

export const RowDivider = (props: TRowDividerProps) => {
	const { className, startComponent, middleComponent, endComponent } = props;

	return (
		<div className={classNames(className, 'd-flex', 'justify-content-between', 'align-items-center')}>
			<StartComponent>{startComponent}</StartComponent>
			<MiddleComponent>{middleComponent}</MiddleComponent>
			<EndComponent>{endComponent}</EndComponent>
		</div>
	);
};
