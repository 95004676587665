export default {
	common: {
		continueToProducts: 'Continuer vers les produits',
		orderValue: 'Valeur de la commande',
		remaining: 'Restant',
		partyPrefix: {
			your: 'Votre',
			partner: 'Partenaire',
			srp: 'SRP',
			customer: 'Client',
			quotedCustomer: 'Devis envoyé au client',
		},
		savingOrder: 'Enregistrement de la commande...',
		exportingOrder: 'Exportation de la commande...',
		loadingOrder: 'Chargement d’une commande...',
		customerCreditStatus: {
			eligible: 'Le client est éligible au crédit.',
			notEligible: 'Le client n’est pas éligible au crédit.',
		},
		previousLicense: 'Clé de licence précédente',
		currency: {
			changeTooltip: 'Modifier la devise',
			selectPrompt: 'Sélectionnez la devise du client',
		},
		paginationText: 'Affichage des articles <strong>{{from}} à {{to}}</strong> sur {{total}}.',
	},
	discretionaryDiscount: {
		title: 'Remise discrétionnaire',
		placeholder: 'Saisissez une remise.',
		approvalFlag: 'État de l’autorisation',
		amount: 'Montant',
		amountUnit: 'Montant unitaire',
		amountTotal: 'Montant total',
		status: {
			notSet: 'Aucune remise discrétionnaire n’a été appliquée à la commande.',
			lineNotSet: 'Aucune remise discrétionnaire n’a été appliquée à l’article.',
			orderLevelDiscountIsSet:
				'Une remise au niveau de la commande a été appliquée à cette commande. Les remises au niveau des articles ont été désactivées.',
			lineLevelDiscountIsSet:
				'Des remises au niveau des articles ont été appliquées à cette commande. La remise au niveau de la commande a été désactivée.',
		},
		action: {
			resetLineDiscounts: 'Supprimer les remises au niveau des articles',
		},
		waitingApprovalMessage:
			'Une remise discrétionnaire a été saisie pour la commande et doit être approuvée par le service des ventes.',
	},
	error: {
		submitOrder: {
			title: 'Impossible d’envoyer une commande',
			general: 'Un problème s’est produit et l’envoi de la commande a échoué. Envoyez-la à nouveau.',
		},
		pricing: {
			title: 'Impossible de calculer le prix.',
		},
		context: {
			setPartnerFailed: 'Échec de l’ajout d’un partenaire à la commande.',
		},
		creditExceed: {
			title: 'Cette transaction dépassera le montant du crédit disponible sur votre compte.',
			text: 'Aucun mode de paiement par crédit n’est disponible pour cette transaction. Utilisez l’option de paiement au niveau du panier.',
		},
		requiredCustomer: {
			title: 'Informations sur le client requises',
			text: 'Sélectionnez un client pour finaliser votre achat.',
			action: 'Sélectionner le client',
			tooltip: {
				question: 'Pourquoi avons-nous besoin d’informations sur le client pour passer une commande ?',
				answer:
					'Lorsque vous passez une commande, nous vous demandons de fournir des informations sur vos clients afin de nous permettre mieux connaître nos clients finaux. En les connaissant mieux et en comprenant leurs habitudes, leurs besoins et leurs préférences nous sommes plus à même de vous servir, vous et vos clients.',
			},
		},
		requiredDistributionPartner: {
			title: 'Informations sur le partenaire de distribution requises',
			text: 'Sélectionnez un partenaire de distribution pour finaliser votre achat.',
			action: 'Sélectionner un partenaire de distribution',
			tooltip: {
				question:
					'Pourquoi avons-nous besoin d’informations sur le partenaire de distribution pour passer une commande ?',
				answer:
					'Lorsque vous passez une commande, nous vous demandons d’inclure les deux niveaux d’informations sur les clients (partenaire de distribution et client du partenaire de distribution) afin de nous permettre de mieux les connaître et de comprendre leurs habitudes, leurs besoins et leurs préférences. Nous pourrons alors mieux vous servir et de mieux les servir.',
			},
		},
	},
	components: {
		successOrder: {
			title: 'Opération réussie !',
			action: {
				NEW_ORDER: 'Nouvelle commande',
				DETAIL: 'Détails de la commande',
				QUOTE_DETAIL: 'Détails du devis',
				LICENSES: 'Afficher des licences',
				EDIT: 'Modifier la commande',
				QUOTE_EDIT: 'Modifier le devis',
				PAYMENT_INSTRUCTIONS: 'Instructions de paiement',
			},
		},
		editPrices: {
			title: 'Modifier les prix unitaires',
			message:
				'Vous ne pouvez modifier que le prix unitaire des articles qui se trouvent dans le panier. Les commandes enregistrées à partir de cet écran ne peuvent pas être modifiées. Assurez-vous que votre panier contient tous les articles dont vous avez besoin avant de continuer.',
			button: 'Modifier la tarification',
			columnLabel: 'Prix unitaire personnalisé',
			submit: 'Enregistrer la commande',
			confirmation: {
				message:
					'Cela permettre de créer une commande qui pourra être traitée, mais qui ne pourra en aucun cas être modifiée. Êtes-vous sûr ?',
				success: 'Oui, enregistrer la commande',
			},
		},
		customerQuote: {
			error: {
				invalidItems:
					'Des prix unitaires personnalisés sont nécessaires pour enregistrer cette commande et pour afficher l’aperçu ou envoyer l’e-mail.',
			},
			fields: {
				customerEmail: 'À',
				partnerEmail: 'CC',
				message: 'Message personnel',
			},
			submit: 'Envoyer l’e-mail',
		},
	},
	page: {
		default: {
			title: 'Détails de la commande',
		},
		products: {
			title: 'Produits',
		},
		order: {
			title: 'Vérifier et confirmer',
		},
	},
	unit: {
		current: '{{type}} actuel(le)',
		toIncrease: '{{type}} supplémentaire',
		toDecrease: 'Supprimer {{type}}',
		total: '{{type}} total(e)',
	},
	entity: {
		privateNotes: 'Note privée',
		publicNotes: 'Note publique',
		opportunityId: 'Numéro d’opportunité',
		marketSegment: 'Segment',
		validityLabel: 'Pour {{value}}',
		validityDescription: 'Période {{value}}',
	},
	pricing: {
		basePrice: 'À partir de {{price}}',
		discount: {
			segment: 'Remise sectorielle',
			renewal: 'Remise pour le renouvellement',
			partner: 'Remise aux partenaires',
			discretionary: 'Remise discrétionnaire',
			promo: 'Remise promotionnelle',
		},
		details: {
			show: 'Afficher le détail des prix',
			hide: 'Masquer le détail des prix',
		},
	},
	actions: {
		addNote: 'Ajouter une note',
		addToCart: 'Ajouter au panier',
		goToCart: 'Accéder au panier',
		exportCart: 'Exporter le panier',
	},
	message: {
		addToCart: {
			item: '- <strong>{{name}}</strong> - {{unit}} {{unitLabel}}, {{quantity}}x',
			title: 'Le produit a été ajouté au panier.',
		},
		removedFromCart: 'Le produit a été supprimé du panier.',
		emptyCart: 'Tous les produits ont été supprimés du panier.',
		emptyCartConfirmation: 'Voulez-vous vraiment supprimer tous les produits du panier ?',
	},
	table: {
		action: {
			selectVariant: 'Sélectionner une variante',
		},
		filter: {
			search: {
				placeholder: 'Rechercher un produit par nom ou par SKU',
			},
			marketSegment: 'Produits {{type}}',
		},
	},
	tableVariant: {
		priceNote: 'Les prix incluent toutes les remises accordées au client.',
		tooltip: {
			quantity:
				'La valeur de la quantité est égale au nombre de licences individuelles commandées pour le produit sélectionné.',
			unitType: {
				SEATS: 'Nombre d’utilisateurs pour une seule licence.',
				SESSIONS: 'Nombre de sessions pour une seule licence.',
				STORAGE: '',
				DEVICE: '',
			},
		},
	},
	widget: {
		common: {
			billableParty: 'Partie facturable',
			nonBillableParty: 'Partie non facturable',
		},
		selectCustomerCard: {
			createNew: 'Créer un client',
			note: 'Pas de client pour cette commande.<br/><strong>Enregistrer les licences plus tard</strong>',
			select: 'Sélectionner un client',
			title: 'Client',
			error: {
				load: 'Le client n’a pas pu être chargé.',
			},
			tooltips: {
				change: 'Modifier le client',
				update: 'Mettre à jour le client',
				cancel: 'Supprimer le client de la commande',
			},
		},
		selectDistributionPartnerCard: {
			createNew: 'Créer un partenaire de distribution',
			note: 'Pas de partenaire de distribution pour cette commande.',
			select: 'Sélectionner un partenaire de distribution',
			title: 'Partenaire de distribution',
			error: {
				load: 'Le partenaire de distribution n’a pas pu être chargé.',
			},
			tooltips: {
				change: 'Modifier le partenaire de distribution',
				update: 'Mettre à jour le partenaire de distribution',
				cancel: 'Supprimer le partenaire de distribution de la commande',
			},
		},
		selectPartnerCard: {
			note: 'Sélectionnez un partenaire pour afficher les prix finaux après remise.',
			select: 'Sélectionner le partenaire',
			title: 'Partenaire Avast',
			error: {
				load: 'Le partenaire n’a pas pu être chargé.',
			},
			tooltips: {
				change: 'Modifier le partenaire Avast',
				cancel: 'Supprimer le partenaire Avast de la commande',
			},
		},
	},
	paymentType: {
		SAVE: {
			action: 'Enregistrer',
			caption: 'Enregistrer pour plus tard',
			button: 'Enregistrer pour plus tard',
			successMessage: 'Votre commande a été enregistrée.',
			confirmationCaption: 'Enregistrer cette commande ?',
		},
		QUOTE: {
			action: 'Envoyer',
			caption: 'Envoyer en tant que devis',
			button: 'Envoyer en tant que devis',
			successMessage: 'Votre devis a été mis en file d’attente pour la livraison.',
			confirmationCaption: 'Envoyer ce devis ?',
		},
		END_CUSTOMER_QUOTE: {
			action: 'Envoyer',
			caption: 'Envoyer en tant que devis',
			button: 'Envoyer en tant que devis',
			successMessage: 'Votre devis a été mis en file d’attente pour la livraison.',
			confirmationCaption: 'Vous souhaitez passer cette commande ?',
		},
		CUSTOMER_QUOTE: {
			action: 'Envoyer',
			caption: 'Envoyer un devis à un client',
			button: 'Envoyer un devis à un client',
			successMessage: 'Votre devis a été mis en file d’attente pour la livraison.',
			confirmationCaption: 'Voulez-vous vraiment envoyer l’e-mail « Devis client » ?',
			confirmationMessage:
				'Tous les prix unitaires du client seront enregistrés dans la commande et un e-mail sera envoyé au client.<br/>Cette action est irréversible.',
		},
		GLOBAL_COMMERCE_CART_CHECKOUT: {
			action: 'Envoyer',
			caption: 'Paiement par carte',
			button: 'Paiement par carte',
			successMessage: 'Votre commande a bien été enregistrée.',
			confirmationCaption: 'Vous souhaitez passer cette commande ?',
		},
		GLOBAL_COMMERCE_CLOSING_BALANCE: {
			action: 'Envoyer',
			caption: 'Solde de clôture',
			button: 'Envoi sur le solde de clôture',
			successMessage: 'Votre commande a bien été enregistrée.',
			confirmationCaption: 'Vous souhaitez passer cette commande ?',
		},
		GLOBAL_COMMERCE_LINE_OF_CREDIT: {
			action: 'Envoyer',
			caption: 'Envoi à crédit',
			button: 'Envoi à crédit',
			successMessage: 'Votre commande a bien été enregistrée.',
			confirmationCaption: 'Vous souhaitez passer cette commande ?',
		},
		ORACLE_CART_CHECKOUT: {
			action: 'Envoyer',
			caption: 'Payer par carte',
			button: 'Payer par carte',
			successMessage: 'Votre commande a été payée.',
			confirmationCaption: 'Vous souhaitez passer cette commande ?',
		},
		ORACLE_LINE_OF_CREDIT: {
			action: 'Envoyer',
			caption: 'Envoi à crédit',
			button: 'Envoi à crédit',
			successMessage: 'Votre commande a bien été enregistrée.',
			confirmationCaption: 'Vous souhaitez passer cette commande ?',
		},
		ORACLE_CLOSING_BALANCE: {
			action: 'Envoyer',
			caption: 'Solde de clôture',
			button: 'Envoi sur le solde de clôture',
			successMessage: 'Votre commande a bien été enregistrée.',
			confirmationCaption: 'Vous souhaitez passer cette commande ?',
		},
	},
	guards: {
		partnerPriceListGuard: {
			title: 'Confirmation de la modification du partenaire',
			message:
				'Le partenaire sélectionné ne dispose pas des mêmes tarifs autorisés que le partenaire actuel. Si vous continuez, le panier sera vidé.',
		},
		partnerTermsGuard: {
			title: 'Le partenaire ne peut pas passer de commande.',
			message: 'Le partenaire sélectionné n’a pas accepté les conditions générales.',
		},
		priceListGuard: {
			title: 'Tarifs pour les produits mixtes',
			messageCaption:
				'Nous ne pouvons pas combiner les produits CloudCare et ceux du système de gestion des commandes dans une même commande. Voulez-vous vraiment ajouter ce produit au panier ?',
			message:
				'Les produits que vous avez ajoutés précédemment seront automatiquement supprimés et cet article sera ajouté au panier.',
		},
		maxCartItemsGuard: {
			title: 'Nombre maximal d’articles dépassé',
			message:
				'Une commande peut contenir un maximum de {{count}} article. Supprimez certains articles pour continuer.',
			message_other:
				'Une commande peut contenir un maximum de {{count}} articles. Supprimez certains articles pour continuer.',
		},
	},
	priceList: {
		mixWarning:
			'Il n’est pas possible de combiner les produits CloudCare et ceux du système de gestion des commandes dans une même commande, car les prix et les remises sont différents pour chaque produit.',
		code: {
			PREPAID_OMS: 'Produits du système de gestion des commandes',
			PREPAID_CC_LEGACY: 'Produits CloudCare',
			PREPAID_CC_MSRP: 'Produits CloudCare',
		},
	},
	tooltips: {
		remove: 'Supprimer cet article du panier',
		emptyCart: 'Supprimer tous les articles du panier',
	},
};
