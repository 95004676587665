import { logError } from 'js/utils/app';
import { TUserAppMessagesConfig } from 'submodule/AppMessages/types';
import { userAppMessagesConfigValidator } from 'submodule/AppMessages/utils';

export const userAppMessagesConfigNormalizer = {
	normalize(config: TUserAppMessagesConfig | null | undefined): TUserAppMessagesConfig | null {
		if (!config) {
			return null;
		}

		try {
			userAppMessagesConfigValidator.validateSync(config, { strict: true });
		} catch (e) {
			logError('userAppMessagesConfigNormalizer', e);
			return null;
		}

		return config;
	},
};
