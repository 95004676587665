export default {
	common: {
		partnerPortal: '透過合作夥伴入口網站登入 OMS',
		forgetPassword: '忘記密碼？',
		returnToPartnerPortal: '返回合作夥伴入口網站',
		successNewPassword: '已成功變更您的新密碼。',
		backToLogin: '返回登入',
		loginLoading: '登入 OMS',
	},
	commonText: {
		loginForAvastOnly: '僅供 Avast 內部使用。',
		assistance: '如果您需要協助，請聯絡您的 Avast Business 客戶經理。',
		signature: '謝謝！ <br />Avast Business 敬上',
	},
	form: {
		password: '密碼',
		submit: '繼續使用 OMS',
		email: '電子郵件地址',
	},
	page: {
		login: {
			title: '登入',
		},
		logout: {
			loading: '登出 OMS...',
		},
		sso: {
			title: 'SSO 登入',
			loading: '登入 OMS...',
		},
		loginAsAffiliate: {
			title: '以關係企業登入',
			loading: '以關係企業登入 OMS...',
		},
		orderHold: {
			title: '貴公司的發票已逾期 {{count}} 天或以上。',
			text: '因此，貴公司的帳戶已被暫停，暫時無法進行新的賒購。',
			textMore:
				'有關未付款訂單的更多詳細資料，請參閱「訂單」區段，我們也已經透過電子郵件將這些資訊提供給貴公司的主要聯絡人。',
			action: '檢視未付款訂單',
		},
		registrationIncomplete: {
			title: '無法完成操作',
			text: '貴公司的主要（管理員）使用者尚未完成 OMS 註冊程序。完成此操作後，您的存取權即會生效。',
		},
		terms: {
			title: '新的條款與條件',
			text: '貴公司的主要（管理員）使用者必須接受新的條款與條件。',
		},
		inactive: {
			title: '無法完成操作',
			text: '您的存取已遭拒。請聯絡您的客戶經理以瞭解更多詳情。',
		},
		maintenance: {
			scheduled: {
				title: '排程維護',
				text: '訂單管理系統目前正在進行排程維護，暫時無法使用。造成不便，我們深感歉意。',
				end: '排程維護結束',
			},
		},
		passwordReset: {
			title: '重設密碼',
			info: '透過提供您的帳戶電子郵件地址來重設您的密碼。',
			success: '您的密碼重設連結已傳送至您的電子郵件信箱。',
		},
		newPassword: {
			title: '設定新密碼',
			form: {
				password: '新密碼',
				confirm: '確認密碼',
			},
			info: '請輸入新密碼。',
			validation: {
				digit: '密碼必須包含至少一個數字',
				symbol: '密碼必須包含至少一個符號',
				length: '密碼必須至少為 8 個字元',
				uppercase: '密碼必須包含至少一個大寫字母',
				lowercase: '密碼必須包含至少一個小寫字母',
				notSame: '確認密碼不相符',
				trim: '密碼的開頭和結尾不得為空格。',
			},
		},
	},
	state: {
		invalidCredentials: '使用者名稱或密碼不正確，請再試一次。',
		invalidSso: '無法登入，您的工作階段已過期。請重新登入。',
		invalidAuthorizationCode: '無法使用此授權碼登入。',
		sessionExpired: '您的工作階段已過期，必須重新登入。',
	},
	error: {
		unableToResetPassword: '我們目前無法重設您的密碼。',
	},
};
