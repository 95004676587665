import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum RetailStatusEnum {
	COMPLETE = 'COMPLETE',
	IN_PROGRESS = 'IN_PROGRESS',
}

export const retailStatusEnumUtils = generateEnumUtils<RetailStatusEnum>(RetailStatusEnum, {
	translatePath: 'enums:retail.status',
});
