export default {
	tierQualifyingUnits: 'Tier Qualifying Units',
	tier: 'Tier',
	srpUnitPrice: 'SRP Unit Price',
	customerSegment: 'Customer Segment',
	segmentDiscount: 'Segment Discount',
	srpAfterSegmentDiscount: 'SRP After Segment Discount',
	quantityPriced: 'Quantity Priced',
	totalPrice: 'Total Price',
	discretionaryDiscount: 'Discretionary Discount',
	unitPriceAfterDiscretionaryDiscount: 'Unit Price After Discretionary Discount',
	partnerDiscount: 'Partner Discount',
	unitPriceAfterDiscount: 'Unit Price After Discount',
	finalDiscountedUnitPrice: 'Final Discounted Unit Price',
	upgradeCredit: 'Upgrade Credit',
	daysInTerm: 'Days in Term',
	daysToProrate: 'Days to Prorate',
	prorationRate: 'Proration Rate',
	proratedUnitPrice: 'Prorated Unit Price',
	proratedSrpUnitPrice: 'Prorated SRP Unit Price',
	daysInPeriod: 'Days In Period',
	dailySaleUnitPrice: 'Daily Sale Unit Price',
	customPricing: 'Custom Pricing',
	quantityBasedPromotions: 'Quantity-based Promotions',
	linePriceUnitPriceAfterQuantityBasedPromotions: 'Line Price, Unit Price After Quantity-based Promotions',
	linePrice: 'Line Price',
	unitPriceAfterQuantityBasedPromotions: 'Unit Price After Quantity-based Promotions',
	promotion: 'Promotion',
	percentageOffPromotions: 'Percentage-Off Promotions',
	unitPriceAfterPercentageOffPromotions: 'Unit Price After Percentage-Off Promotions',
	validFrom: 'Valid From',
	validTo: 'Valid To',
	offQtyOf: 'Off Qty of',
	amountOff: 'Amount Off',
	discountPerUnit: 'Discount Per Unit',
	discount: 'Discount',
};
