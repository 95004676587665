export const API_ERR_CODE = {
	INVALID_HASH: '336ae5fc-f421-4138-aadf-a791f44b667a',
	ACTIVE_ENTITLEMENT_NOT_FOUND: 'e5f560bb-d5c0-4d36-94d8-b94dfb0d48ef',
	PARTNER_NOT_REGISTERED: '6a0c0ad1-327f-4432-8033-cab89ea4851f',
	NEW_TERMS_NOT_ACCEPTED: '162da82c-7acf-46b9-bae9-63898c5ffd63',
	LICENSE_OPERATION_WRONG_SEGMENT: '709aa217-b973-4d79-be98-a88326b6fc2f',
	PARTNER_QUOTE_CURRENCY_MISMATCH: '96d57177-d9a5-468f-bb81-b172db14b0fe',
	SALESFORCE_SENT_PAYLOAD_IN_CONFLICT: '5f272c4d-605f-44f7-ac77-052c238f6bd9',
	PASSWORD_RESET_INVALID_TOKEN: 'dcbac123-7de0-4cab-9bc4-1cb779985993',
	PASSWORD_RESET_EXPIRED_TOKEN: '3719b15b-cea1-4925-a655-54ef56a4e468',
	PASSWORD_ALREADY_USED: 'f0451095-4278-4046-9d10-fc848038feb2',
	GC_INVALID_PAYMENT_SOURCE: '2718ed8f-1a6e-44db-bc4b-553240d915e0',
	TOO_MANY_ENTITLEMENTS_IN_CANCELLATION_REQUEST: '9583dbd9-7b27-41dd-b323-63c5bc9b978b',
	USAGE_LIMIT_IS_LOWER_THEN_TOTAL_USAGE_LIMIT: '6e92b9e7-28a2-43ec-9864-0790aa6ec708',
};
