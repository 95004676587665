import React, { ReactElement, useMemo } from 'react';
import { TDetailArguments } from 'types';
import { TUseApiDetailProps } from 'js/queries/useApiDetailQuery';
import { IEntityPromotion } from 'module/promotions';
import { PageDetailComponent } from 'js/layouts/page/PageDetailComponent';
import { useApiPromotion } from '../hooks/useApiPromotion';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { RouteName } from 'module/RouteName';
import { buildParametrizedRoute } from 'js/utils/common';
import { PromotionDetail } from 'module/promotions/components/PromotionDetail';
import { useRouteParams } from 'js/hooks/useRouteParams';

type TArguments = TDetailArguments;
type TData = IEntityPromotion;
type TQueryProps = TUseApiDetailProps<TData, TArguments, IEntityPromotion>;

export const PageDetail = (): ReactElement | null => {
	const params = useRouteParams<TArguments>();
	const queryProps: TQueryProps = useMemo(() => ({ filter: params }), [params]);

	return (
		<PageDetailComponent<TData>
			query={useApiPromotion}
			trNamespace={promotionsConfig.trNamespace}
			queryProps={queryProps}
			detailLink={buildParametrizedRoute(RouteName.PROMOTIONS.DETAIL, params.id)}
		>
			<PromotionDetail />
		</PageDetailComponent>
	);
};
