import { TChargeOrderId } from 'types';
import { useApiRefunds } from 'submodule/refunds/hooks/useApiRefunds';
import { gt, isString } from 'lodash';

type TUseIsChargeOrderRefunded = {
	isFetching: boolean;
	isRefunded?: boolean;
};

export const useIsChargeOrderRefunded = (chargeOrderId?: TChargeOrderId): TUseIsChargeOrderRefunded => {
	const { data: refunds, query } = useApiRefunds({
		filter: {
			chargeOrderId: chargeOrderId!,
		},
		queryConfig: {
			enabled: isString(chargeOrderId),
		},
	});

	return {
		isFetching: query.isFetching,
		isRefunded: query.isFetching ? undefined : gt(refunds.length, 0),
	};
};
