import { LinkButton } from 'js/components/atoms/Button';
import { RouteName } from 'module/RouteName';
import type { TToastProps } from '@avast/react-ui-components';
import { Toast } from '@avast/react-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';

export const ToastSuccess = (props: TToastProps) => {
	const [t] = useTranslation(licensesConfig.trNamespace);

	return (
		<Toast
			{...props}
			variant="success"
			bottomPlaceholder={<LinkButton to={RouteName.PURCHASE.ORDER}>{t('common.operation.goToCart')}</LinkButton>}
		>
			{t('common.operation.addToCart')}
		</Toast>
	);
};
