export default {
	common: {
		continue: 'Continue Registration',
		skip: 'Skip for Now',
		submitting: 'Submitting your data...',
		finishing: 'We are finalizing your registration...',
		successRegistration: 'You have been successfully registered',
		successAcceptTerms: 'Terms and conditions were successfully accepted. Thank you.',
	},
	steps: {
		init: 'Welcome',
		terms: 'Terms and Conditions',
		registrationInfo: 'Vital Information',
		taxExemption: 'Tax Exemption',
	},
	page: {
		welcome: {
			title: 'Welcome to the E-commerce Registration Process',
			content: 'You will be guided through the steps required for registration and will be asked to:',
			conditionList: ['accept terms and conditions', 'update and confirm your vital profile information'],
		},
		terms: {
			title: 'Please review and agree to our terms and conditions:',
			button: 'I have read the Agreement(s) and I Accept the Terms',
		},
		registrationInfo: {
			contactInformation: 'Contact Information',
			accountInformation: 'Account Information',
		},
		taxExemption: {
			title: 'Please Submit Your Tax Exemption Details',
			subtitle: 'Please fill-in requested information about your tax exemption.',
		},
	},
	error: {
		unableToInit: 'Unable to init registration.',
		unableToRegistrationToken: 'Unable to get registration token.',
		unableToStart: 'Unable to start registration.',
		unableToLoadTerms: 'Unable to load Terms and Conditions. Please contact your Account Manager for assistance.',
		unableToAcceptTerms: 'Unable to accept terms for current partner.',
		unableToProcess: 'We are currently unable to send your data to process.',
	},
	alert: {
		checkCurrentAccountInfo:
			'We have filled in the information currently stored for your account in our systems. Please review this information and submit updates where necessary. Any change that is submitted will be recorded for the future use.',
		changeAccountDataInPartnerPortal:
			'In case you need to change your Contact information, please complete the registration process and visit My Profile section in the Avast Business Partner Portal to edit your profile information.',
	},
};
