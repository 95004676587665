import React from 'react';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import type { TCardProps } from '@avast/react-ui-components';
import { Alert, Card, Modal, UiTable } from '@avast/react-ui-components';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { useApiPartnerTermsList } from 'module/partners/hooks/useApiPartners';
import { IEntityPartnerTerms } from 'module/partners';
import { partnersConfig } from 'module/partners/partnersConfig';
import { partnerTermsEnumUtils } from 'module/partners/enums';
import { useTermsCardColumns } from 'module/account/hooks';
import { useAuthContext } from 'js/contexts';

export const TermsCard = (props: Partial<TCardProps>) => {
	const { authCompany: company } = useAuthContext();
	const [t] = useTranslation(accountConfig.trNamespace);
	const [tPartner] = useTranslation(partnersConfig.trNamespace);
	const { data: terms, query } = useApiPartnerTermsList({ filter: { partnerId: company?.id } });
	const ref = useAsyncModalRef<IEntityPartnerTerms>();
	const columns = useTermsCardColumns(ref);

	if (!company) {
		return null;
	}

	const hasError = query.isError || (!query.isFetching && !terms);
	return (
		<Card {...props}>
			<Card.Header>{t('cards.terms')}</Card.Header>
			<Card.Body
				spacing={hasError}
				className="card-body--table"
			>
				{(() => {
					if (hasError) {
						return (
							<Alert
								variant="warning"
								caption={tPartner('error.unableToLoadTerms')}
							/>
						);
					}

					return (
						<UiTable<IEntityPartnerTerms>
							columns={columns}
							data={terms}
							testId="terms"
							meta={{
								loading: query.isFetching,
								hideHeader: true,
								rowCount: 2,
							}}
						/>
					);
				})()}
				<AsyncModal<IEntityPartnerTerms>
					ref={ref}
					size="lg"
					testId="termsDetail"
				>
					{({ content, name }) => (
						<>
							<Modal.Header>{partnerTermsEnumUtils.getText(name)}</Modal.Header>
							<Modal.Body>
								<div
									className="fs-md p-3 border bg-light rounded"
									style={{ overflowY: 'auto', maxHeight: '500px' }}
									dangerouslySetInnerHTML={{ __html: content }}
								/>
							</Modal.Body>
							<Modal.Footer cancelButton />
						</>
					)}
				</AsyncModal>
			</Card.Body>
		</Card>
	);
};
