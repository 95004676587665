import React, { PropsWithChildren } from 'react';

type TFixedWidthWrapper = {
	width: number;
	max?: boolean;
};

export const FixedWidthWrapper = (props: PropsWithChildren<TFixedWidthWrapper>) => {
	const { children, width, max } = props;

	return (
		<div
			className="d-inline-block"
			style={max ? { maxWidth: `${width}px`, width: '100%' } : { width: `${width}px` }}
		>
			{children}
		</div>
	);
};
