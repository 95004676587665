import { TLoginAsAffiliateFormData, TLoginResolve } from 'module/security';
import { useCallback } from 'react';
import { useAuthUserData } from 'module/security/hooks/useAuthUserData';
import { getInvalidStateLocation } from 'module/security/utils/common';
import { useApiLoginAsAffiliate } from 'module/security/hooks/useApiSecurity';
import { useLogout } from 'module/security/hooks/useLogout';
import { useAuthContext } from 'js/contexts';

export const useLoginAsAffiliate = (): ((values: TLoginAsAffiliateFormData) => Promise<TLoginResolve>) => {
	const { setAuthData } = useAuthContext();
	const logout = useLogout();
	const authUserData = useAuthUserData();
	const { mutateAsync } = useApiLoginAsAffiliate();

	return useCallback(
		(values) =>
			new Promise(async (resolve) => {
				// Do log out first
				await logout();

				const response = await mutateAsync(values).catch(() => {
					resolve({ success: false, message: 'state.invalidAuthorizationCode' });
				});

				// Err -> handled by hook props
				if (!response) {
					resolve({ success: false, message: 'state.invalidAuthorizationCode' });
					return;
				}

				const data = await authUserData(response.data);
				if (data) {
					setAuthData(data);
					resolve({ success: true, redirectTo: getInvalidStateLocation(data) });
					return;
				}
				resolve({ success: false, message: 'state.invalidAuthorizationCode' });
			}),
		[logout, mutateAsync, authUserData, setAuthData],
	);
};
