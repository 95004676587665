export default {
	common: {
		continue: '登録を続行',
		skip: '今はスキップ',
		submitting: 'データを送信中...',
		finishing: '登録を確定中...',
		successRegistration: '正常に登録されました',
		successAcceptTerms: '利用規約に同意しました。ありがとうございます。',
	},
	steps: {
		init: 'こんにちは',
		terms: '利用規約',
		registrationInfo: '重要な情報',
		taxExemption: '非課税',
	},
	page: {
		welcome: {
			title: 'e コマース登録プロセスへようこそ',
			content: '登録に必要な手順を紹介します。必要な操作は次のとおりです。',
			conditionList: ['利用規約に同意する', '重要なプロファイル情報を更新して確定する'],
		},
		terms: {
			title: '以下の利用規約を確認し、同意してください。',
			button: '使用許諾条項を読んだ上で規約に同意します',
		},
		registrationInfo: {
			contactInformation: '連絡先情報',
			accountInformation: 'アカウント情報',
		},
		taxExemption: {
			title: '非課税の詳細を送信してください',
			subtitle: '要求された非課税に関する情報を入力してください。',
		},
	},
	error: {
		unableToInit: '登録を初期化できません。',
		unableToRegistrationToken: '登録トークンを取得できません。',
		unableToStart: '登録を開始できません。',
		unableToLoadTerms:
			'利用規約を読み込むことができません。詳細については、アカウント マネージャーにお問い合わせください。',
		unableToAcceptTerms: '現在のパートナーの規約に同意できません。',
		unableToProcess: '現在、処理するデータを送信できません。',
	},
	alert: {
		checkCurrentAccountInfo:
			'システムに現在保存されているアカウントの情報を入力しました。この情報を確認し、必要に応じて更新を送信してください。送信する変更は、今後使用するために記録されます。',
		changeAccountDataInPartnerPortal:
			'連絡先情報の変更が必要な場合は、登録プロセスを完了し、アバスト ビジネス パートナー ポータルの［マイ プロファイル］セクションにアクセスして、プロファイル情報を編集してください。',
	},
};
