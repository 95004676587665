import React, { PropsWithChildren, ReactElement, TdHTMLAttributes } from 'react';
import classNames from 'classnames';

type TCustomTdProps = TdHTMLAttributes<HTMLTableCellElement> & {
	foot?: boolean;
};

export const _CustomTd = (props: PropsWithChildren<TCustomTdProps>): ReactElement => {
	const { className, children, foot, ...rest } = props;
	const _classNames = classNames(
		'cell',
		{
			'cell-body': !foot,
			'cell-foot': foot,
		},
		className,
	);

	return (
		<td
			role="cell"
			className={_classNames}
			{...rest}
		>
			<div>{children}</div>
		</td>
	);
};
