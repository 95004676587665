import { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/en-GB');
const locale: TLocale = 'en-GB';

export const en_GB: ILocaleConfig = {
	name: 'English (Great Britain)',
	locale,
	language: 'en',
	format: {
		numbro,
		datetime: locale,
	},
};
