import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum StandardOrderDiscountCategoryEnum {
	NONE = 'None',
	DISCRETIONARY = 'Discretionary',
}

export const standardOrderDiscountCategoryEnumUtils = generateEnumUtils<StandardOrderDiscountCategoryEnum>(
	StandardOrderDiscountCategoryEnum,
	{
		translatePath: 'enums:order.discountCategory',
	},
);
