import { IAuthDataUser } from 'js/reducers/authContextReducer';
import { getAuthSecurity } from 'js/contexts';
import { INVALID_PARTNER_STATES } from 'module/security/constants';
import { RouteName } from 'module/RouteName';
import { CONFIG } from 'config';
import { buildUrlFromLocation, object2UrlParams } from 'js/utils/common';
import { NavigateLink } from 'types';

export const getInvalidStateLocation = (data: IAuthDataUser): NavigateLink | null => {
	const authSecurityState = getAuthSecurity(data.partner, data.company);
	const state = INVALID_PARTNER_STATES.find((state) => state.isInvalid(authSecurityState));
	if (!state) {
		return null;
	}

	return {
		to: state.route,
	};
};

export const getSalesforceLoginLink = (backlink?: NavigateLink['to']): string => {
	const baseLoginUrl = `${CONFIG.APP.SALES_FORCE_URL}/idp/login`;
	const redirectTo = buildUrlFromLocation(backlink);
	const parameters: { app: string; RelayState?: string } = {
		app: CONFIG.APP.SALES_FORCE_APP_ID,
	};

	if (redirectTo) {
		const baseUrl = [window.location.origin, process.env.PUBLIC_URL, RouteName.SECURITY.SSO].join('');
		parameters.RelayState = `${baseUrl}?${object2UrlParams({ redirectTo })}`;
	}

	return `${baseLoginUrl}?${object2UrlParams(parameters)}`;
};
