export default {
	dateRangeType: {
		BILLING: '帳單日期',
		CREATED: '建立日期',
		PAYMENT: '付款日期',
	},
	tierCertification: {
		DISTRIBUTORS: '代理商',
		TIER_4: '第 4 層',
		TIER_3: '第 3 層',
		TIER_2: '第 2 層',
		TIER_1: '第 1 層',
	},
	customer: {
		type: {
			BUSINESS: '企業',
			CONSUMER: '消費者',
		},
		discountType: {
			EDUCATION: {
				name: '教育',
				description: '教育機構享有額外折扣，若要對您的訂單套用此折扣，請聯絡您的銷售經理。',
			},
			GOVERNMENT: {
				name: '政府',
				description: '政府單位享有額外折扣，若要對您的訂單套用此折扣，請聯絡您的銷售經理。',
			},
			NOT_FOR_PROFIT: {
				name: '非營利',
				description: '非營利機構享有額外折扣，若要對您的訂單套用此折扣，請聯絡您的銷售經理。',
			},
		},
		searchKey: {
			EMAIL: '電子郵件地址',
			SALESFORCE_ID: '銷售人員 ID',
		},
	},
	partner: {
		type: {
			DISTRIBUTOR: '代理商',
			RESELLER: '經銷商',
			INTERNAL_COMPANY: '公司內部',
			USER: '使用者',
		},
		subType: {
			RETAIL: '零售',
			ISP: 'ISP',
			MSP: 'MSP',
			NONE: '無',
		},
		terms: {
			DR: 'Digital River 通路參與者會員合約',
			CLIENT: '合作夥伴合約',
		},
		payAsYouGoPaymentMethod: {
			LINE_OF_CREDIT: {
				name: 'LOC',
				description: '發票將在月底之後盡快產生。您的標準付款條款將適用。LOC 發票只能透過銀行轉帳支付。 ',
			},
			AUTO_BILLING: {
				name: '自動收費',
				description: '您儲存的卡片會在月底後不久自動扣款。如果嘗試失敗，就會產生標準 LOC 發票。',
			},
		},
	},
	order: {
		discountCategory: {
			NONE: '無',
			DISCRETIONARY: '酌情',
		},
		lineItemDiscountType: {
			STANDARD: '標準',
			SPECIAL: '特別',
			NONE: '無',
		},
		approvalFlag: {
			NA: '不適用',
			APPROVAL_NOT_REQUIRED: '無需核准',
			APPROVAL_REQUIRED: '需要核准',
			PENDING_APPROVAL: '待核准',
			APPROVED: '已核准',
			REJECTED: '已拒絕',
		},
		billablePartyType: {
			PARTNER: '合作夥伴',
			DISTRIBUTOR: '代理商',
			END_CUSTOMER: '一般客戶',
		},
		searchKey: {
			COMMERCE_ORDER_ID: '可計費訂單 ID',
			OPPORTUNITY_ID: '機會 ID',
			ORDER_NUMBER: '訂單編號',
			PO_NUMBER: 'PO 編號',
			QUOTE_NUMBER: '報價編號',
		},
		status: {
			SAVED_IN_PROGRESS: {
				name: '儲存進行中',
				description: '訂單已儲存，但尚未提交。只有處於此狀態的訂單可以進行修改。',
			},
			ISSUED_AS_CUSTOMER_QUOTE: {
				name: '做為客戶報價發佈',
				description: '訂單是提交給客戶的報價。客戶不能對其進行編輯 - 只能提交。',
			},
			ISSUED_AS_QUOTE: {
				name: '做作為報價發佈',
				description: '訂單是 Avast 的報價。不能對其進行編輯 - 只能提交。',
			},
			POSTED_TO_STATEMENT: {
				name: '記入結單',
				description: '訂單已記入月結單中。',
			},
			PENDING_SUBMISSION: {
				name: '待提交',
				description: '',
			},
			IN_REVIEW: {
				name: '審查中',
				description: '訂單正在等待審查。不能對其進行編輯。',
			},
			SUBMITTED_TO_COMMERCE: {
				name: '已提交進行交易',
				description: '訂單已提交進行計費。',
			},
			PAYMENT_ERROR: {
				name: '付款錯誤',
				description: '發生付款錯誤。應在驗證準確且最新的付款資訊後重新提交訂單。',
			},
			OTHER_ERROR: {
				name: '其他錯誤',
				description: '發生系統錯誤，並在重試 3 次後錯誤仍然存在。應重新提交訂單以進行重試，或是聯絡支援人員。',
			},
			WAITING_PAYMENT: {
				name: '等待付款',
				description: '訂單已提交進行計費。付款已到期。',
			},
			COMPLETE: {
				name: '完成',
				description: '付款收訖，訂單完成。',
			},
			CANCELED_BY_USER: {
				name: '使用者已取消',
				description: '使用者選擇取消訂單。',
			},
			CANCELED_FOR_FAILURE_TO_PAY: {
				name: '因付款失敗而取消',
				description: '由於未能全額支付訂單款項，因此取消訂單。',
			},
			EXPIRED: {
				name: '已失效',
				description: '訂單在建立後 30 天內未修改。',
			},
			NOT_SUBMITTED: {
				name: '未提交',
				description: '訂單未提交進行計費。',
			},
		},
	},
	payment: {
		status: {
			PAID: {
				name: '已付款',
				description: '訂單已成功處理，並且已收到付款。',
			},
			UNPAID: {
				name: '未付款',
				description: '訂單正在等待付款或尚未收到付款。',
			},
			PARTIAL_PAID: {
				name: '部分付款',
				description: '訂單尚有未結餘額。',
			},
			NA: {
				name: '未計費',
				description: '該訂單正在等待計費或開立發票。',
			},
			UNBILLED: {
				name: '未計費',
				description: '該訂單正在等待計費或開立發票。',
			},
			SETTLED: {
				name: '已結帳',
				description: '訂單已由收款團隊處理並完成。',
			},
		},
		type: {
			WIRE_TRANSFER: '匯款',
			WTOC: 'WTOC',
			CART_SELECTED: '已選取購物車',
			CLOSING_BALANCE: '期末結餘',
			LOC: 'LOC',
			AUTO_BILLING: '自動收費',
		},
	},
	licenses: {
		searchKey: {
			LICENSE_KEY: '授權碼',
			ORDER_NUMBER: '訂單編號',
		},
		status: {
			ACTIVE: {
				name: '使用中',
				description: '授權已在使用中',
			},
			INACTIVE: {
				name: '非使用中',
				description: '啟動碼尚未使用',
			},
			CANCELLED: {
				name: '已取消',
				description: '授權已取消或已退款',
			},
			REPLACED: {
				name: '已取代',
				description: '在操作過程中，某個有效權利被另一個有效權利取代',
			},
			EXPIRED: {
				name: '已失效',
				description: '有效權利的到期日期已過',
			},
			USED: {
				name: '已使用',
				description: '授權已被使用',
			},
			MIGRATED: {
				name: '已移轉',
				description: '授權已移轉至後續產品授權',
			},
			BLACKLISTED: {
				name: '已列入黑名單',
				description: '',
			},
		},
		datepickerDateType: {
			CREATED_AT: '建立日期',
			EXPIRATION: '到期日期',
			REGISTERED_AT: '註冊日期',
		},
	},
	closingBalance: {
		searchKey: {
			ORDER_NUMBER: '訂單編號',
		},
	},
	creditStatus: {
		transactionSource: {
			CANCELLED_LICENSE: '取消的授權',
			CANCELLED_ORDER: '取消的訂單',
			MSP: 'MSP',
			NEW_ORDER: '新訂單',
			OMS_REFUND: '退款',
			PAID_ORDER: '已付款訂單',
			SAP: 'SAP',
			SAP_TAX: '稅',
			OMS_TAX: '稅',
			ORACLE_TAX: '稅',
			ADMIN_CUSTOM: '自訂調整',
			PAY_AS_YOU_GO: '彈性計費',
			TAX_REFUND: '退稅',
			PAY_AS_YOU_GO_CONSOLIDATION: '彈性計費 - 更正',
			SETTLED_ORDER: '已結帳',
		},
	},
	purchase: {
		marketSegment: {
			BUSINESS: '企業',
			CONSUMER: '消費者',
		},
		billablePartyType: {
			PARTNER: '合作夥伴',
			CUSTOMER: '客戶',
		},
		type: {
			PREPAID: '預付費',
			FLEXIBLE_BILLING: '彈性計費',
		},
	},
	promotion: {
		scope: {
			PARTY_ONLY: '僅限當事人',
			PRODUCT_ONLY: '僅限產品',
			PARTY_AND_PRODUCT: '當事人和產品',
		},
		type: {
			DISCRETIONARY: '酌情',
			SALES_CAMPAIGN: '銷售行銷活動',
		},
		partyType: {
			DISTRIBUTION: '代理商',
			RESELLER: '經銷商',
			END_CUSTOMER: '一般客戶',
		},
		partyTier: {
			DISTRIBUTORS: '代理商',
			NONE: '無',
			TIER_4: '第 4 層',
			TIER_3: '第 3 層',
			TIER_2: '第 2 層',
			TIER_1: '第 1 層',
		},
		state: {
			ENABLED: '已啟用',
			DISABLED: '已停用',
		},
	},
	retail: {
		status: {
			COMPLETE: '完成',
			IN_PROGRESS: '進行中',
		},
	},
	refunds: {
		status: {
			OPEN: '開啟',
			FULLY_APPLIED: '完全適用',
			VOIDED: '已作廢',
			PENDING: '擱置中',
		},
		type: {
			ORDER: '訂單退款',
			LINE: '細目退款',
			KEY: '金鑰退款',
			TAX: '退稅',
		},
		reason: {
			CUSTOMER_SATISFACTION_ISSUE: '客戶滿意度問題',
			INCORRECT_PRICE_INVOICED: '發票價格不正確',
			NOT_DELIVERED: '未交付',
			WRONG_PRODUCT: '產品錯誤',
			PRODUCT_NOT_WORKING: '產品無法運作',
			TAX_EXEMPT: '免稅',
			OTHER_REASON: '其他原因',
		},
	},
	comparativeFeature: {
		ONLINE_MANAGEMENT: {
			name: '線上管理平台',
			description: '隨時隨地輕鬆設定裝置保護、管理訂購授權、監控及新增裝置。',
		},
		DEVICE_PROTECTION: {
			name: '裝置保護',
			description: '屢獲殊榮的防毒軟體可以保護您的所有裝置，抵禦惡意軟體、網路釣魚、勒索軟體和其他更先進的網路攻擊。',
		},
		DATA_PROTECTION: {
			name: '資料防護',
			description: '保護您的個人、企業和客戶資料免於遭竊。',
		},
		IT_SUPPORT: {
			name: 'IT 支援',
			description:
				'我們的技術工程師訓練有素且經驗豐富，可以透過電子郵件、線上交談或電話為您和員工提供全天候（工作日假日除外）的快速、友善的支援服務。',
		},
		IDENTITY_PROTECTION: {
			name: '身分保護',
			description: '包括密碼和網路攝影機保護，可以保護您員工的線上身分。',
		},
		UNLIMITED_VPN: {
			name: '無流量限制的 VPN',
			description: '我們的內建 VPN 會加密您的資料並保護您員工的網際網路連線，讓他們在使用公用 Wi-Fi 網路時保持私密性。',
		},
		USB_PROTECTION: {
			name: 'USB 保護',
			description:
				'防止員工使用未經授權的卸除式儲存裝置，包括快閃磁碟機、外接式磁碟機、記憶卡等。封鎖、控制並監控 USB 連接埠，以阻止資料盜竊、資料遺失和惡意軟體感染。',
		},
		PATCH_MANAGEMENT: {
			name: 'Patch Management',
			description: '自動修補您的軟體和第三方應用程式中容易受到網路攻擊的漏洞。',
		},
	},
	productGroupTag: {
		NEW: '新功能',
	},
	widgets: {
		label: {
			QUOTES: '最近的報價',
			ORDERS: '最近的訂單',
			CREDIT_STATUS: '信用狀態',
			EXPIRING_LICENSES_NEXT_N_DAYS: '未來 N 天到期的授權',
			EXPIRED_LICENSES_LAST_N_DAYS: '最近 N 天到期的授權',
			CLOSING_BALANCE: '最近的期末結餘報表',
		},
		caption: {
			QUOTES: '最近的報價',
			ORDERS: '最近的訂單',
			CREDIT_STATUS: '信用狀態',
			EXPIRING_LICENSES_NEXT_N_DAYS: '未來 {{count}} 天到期的授權',
			EXPIRED_LICENSES_LAST_N_DAYS: '最近 {{count}} 天到期的授權',
			CLOSING_BALANCE: '最近的期末結餘報表',
		},
	},
};
