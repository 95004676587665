import {
	TWidgetConfig,
	TWidgetExpiringLicenseNextNDaysSettings,
	TWidgetSettingsState,
	TWidgetSettingsValues,
	TWidgetState,
} from 'submodule/widgets';
import { useLicenseWidgetColumns } from 'submodule/widgets/tableColumns';
import { tableColumnsToWidgetConfigColumns, tableColumnVisibilityToKeys } from 'submodule/widgets/utils';
import type { TSelectOptions } from '@avast/react-ui-components';
import { useWidgetContext } from 'submodule/widgets/context';
import { useCan } from 'js/hooks/useCan';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { WidgetTypeEnum } from 'submodule/widgets/enums';
import { useAuthContext } from 'js/contexts/AuthContext';

type Settings = TWidgetExpiringLicenseNextNDaysSettings;

export const useExpiringLicensesNextNDaysWidget = (): TWidgetConfig<Settings> => {
	const { t } = useWidgetContext();
	const { isGroupInternal } = useAuthContext();

	// Restrictions
	const isModuleAllowed = useCan({ do: licensesConfig.aclModule, readList: true });

	// Columns
	const { columns: tableColumns, columnVisibility } = useLicenseWidgetColumns();
	const widgetColumns = tableColumnsToWidgetConfigColumns(tableColumns);
	const defaultColumns = tableColumnVisibilityToKeys(tableColumns, columnVisibility);

	return {
		type: WidgetTypeEnum.EXPIRING_LICENSES_NEXT_N_DAYS,
		isAllowed: isModuleAllowed,
		getDefaultState(settings: TWidgetSettingsState<Settings>): TWidgetState<Settings> {
			return {
				type: this.type,
				settings: {
					days: 60,
					limit: 5,
					...settings,
				},
				columns: defaultColumns,
			};
		},
		getSettingsFields(): TWidgetSettingsValues<Settings> {
			const fields: TWidgetSettingsValues<Partial<Settings>> = {
				days: {
					type: 'number',
					label: t('widgets.setting.days'),
					min: 1,
					max: 90,
					required: true,
				},
				limit: {
					type: 'number',
					label: t('widgets.setting.limit'),
					min: 1,
					max: 10,
					required: true,
				},
			};

			if (isGroupInternal) {
				fields.partnerId = {
					type: 'partnerId',
					label: t('common:entity.partner'),
					required: false,
				};
			}

			return fields;
		},
		getAvailableColumns(): TSelectOptions {
			return widgetColumns;
		},
	};
};
