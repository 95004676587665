import {
	IEntityOmsOrder,
	IEntityOrder,
	IEntityOrderBase,
	IEntityOrderBillingSystem,
	IEntityOrderItem,
	IEntityOrderItemBase,
} from 'module/orders';
import { extractBulkQuantityFromSku, isDefined, joinValues } from 'js/utils/common';
import { IStandardOrderInstance } from 'module/purchase';
import {
	BillablePartyTypeEnum,
	CreationSourceEnum,
	EntitlementStatusEnum,
	OrderStatusEnum,
	OrderTypeEnum,
	QuoteTypeEnum,
} from 'module/orders/enums';
import { IPartnerGroups } from 'module/partners';
import { BillingSystemEnum, PaymentStatusEnum, PaymentTypeEnum, paymentTypeEnumUtils } from 'js/enums';
import { castArray, isEmpty } from 'lodash';

export const isOrderInStatus = (
	order: IEntityOrder | IEntityOmsOrder | null,
	status: OrderStatusEnum[] | OrderStatusEnum,
): boolean => isDefined(order?.status) && castArray(status).includes(order?.status!);

export const isOrderPaymentTypeInState = (
	order: IEntityOrder | IEntityOmsOrder | null,
	type: PaymentTypeEnum[] | PaymentTypeEnum,
): boolean => isDefined(order?.payment?.type) && castArray(type).includes(order?.payment?.type!);

export const isClosingBalanceOrder = (order: IEntityOrder | IEntityOmsOrder | null): boolean =>
	isOrderPaymentTypeInState(order, PaymentTypeEnum.CLOSING_BALANCE);

export const isOrderPaymentInStatus = (
	order: IEntityOrder | IEntityOmsOrder | null,
	status: PaymentStatusEnum[] | PaymentStatusEnum,
): boolean => isDefined(order?.payment?.status) && castArray(status).includes(order?.payment?.status!);

export const isGcOrder = (data: IEntityOrderBillingSystem): boolean =>
	data.billingSystem === BillingSystemEnum.GLOBAL_COMMERCE;

export const isNetSuiteOrder = (data: IEntityOrderBillingSystem): boolean =>
	data.billingSystem === BillingSystemEnum.NETSUITE;

export const isIspOrder = (data: IEntityOrder | IStandardOrderInstance | null): boolean =>
	data?.creationSource === CreationSourceEnum.ISPAPI;

export const isPaymentInstructionsEligible = (order: IEntityOrder): boolean => {
	if (!order.chargeOrderId) {
		return false;
	}

	switch (order.billingSystem) {
		case BillingSystemEnum.NETSUITE:
		case BillingSystemEnum.ORACLE:
			return isOrderInStatus(order, OrderStatusEnum.WAITING_PAYMENT);
		default:
			return (
				isOrderInStatus(order, [OrderStatusEnum.WAITING_PAYMENT, OrderStatusEnum.SUBMITTED_TO_COMMERCE]) &&
				!isOrderPaymentTypeInState(order, PaymentTypeEnum.LOC) &&
				Boolean(order.gcOrderId)
			);
	}
};

export const hasOrderDiscretionaryDiscount = (order: IEntityOrder): boolean => Boolean(order?.discount?.applied);

export const hasOrderSavedPricingResponse = (order: IEntityOrderBase): boolean => Boolean(order.pricing);

export const hasQuotedCustomPrices = (lineItems: IEntityOrderItem[]): boolean =>
	lineItems.some((item) => Boolean(item.computedValues.price.quotedCustomer));

export const isOrderUnfinished = (order: IEntityOrder | IEntityOmsOrder | null): boolean =>
	isOrderInStatus(order, [OrderStatusEnum.SAVED_IN_PROGRESS, OrderStatusEnum.ISSUED_AS_QUOTE]);

export const hasOrderAnyNote = (order: IEntityOrder): boolean =>
	Boolean(order.publicNotesHeader) || Boolean(order.privateNotesHeader);

export const isOrderType = (order: IEntityOrderBase, type: OrderTypeEnum): boolean => order.orderType === type;

/**
 * The quote flag is present on the order for the whole order life cycle.
 * @param {IEntityOrder} order
 * @returns {boolean}
 */
export const isQuote = (order: IEntityOrder): boolean => order.quote?.flag === true;

export const isEndCustomerQuote = (order: IEntityOrder): boolean =>
	isQuote(order) && order.quote?.type === QuoteTypeEnum.CUSTOMER;

const hasChargeOrder = (order: IEntityOrder) => !isEmpty(order.chargeOrderId);

export const hasAvailableLicenses = (order: IEntityOrder): boolean => {
	if (!order.status || order.isRetailOrder || order.isPayAsYouGoOrder) {
		return false;
	}

	if (isEndCustomerQuote(order) || isIspOrder(order)) {
		return false;
	}

	switch (order.status) {
		case OrderStatusEnum.COMPLETE:
		case OrderStatusEnum.POSTED_TO_STATEMENT:
			return true;
		case OrderStatusEnum.WAITING_PAYMENT:
			return !isNetSuiteOrder(order) || isOrderPaymentTypeInState(order, PaymentTypeEnum.LOC);
		case OrderStatusEnum.SUBMITTED_TO_COMMERCE:
			return hasImmediatelyAvailableLicenses(order);
		default:
			return false;
	}
};

export const isLicensesGeneratingInProcess = (order: IEntityOrder): boolean => {
	if (order.fulfillmentCompleteDate) {
		return false;
	}

	if (!hasAvailableLicenses(order)) {
		return false;
	}

	return (
		order.orderEntitlementStatus === EntitlementStatusEnum.REQUEST_IN_PROCESS ||
		(order.orderEntitlementStatus === EntitlementStatusEnum.NOT_REQUESTED &&
			isOrderInStatus(order, OrderStatusEnum.SUBMITTED_TO_COMMERCE))
	);
};

export const hasImmediatelyAvailableLicenses = (order: IEntityOrder): boolean =>
	!paymentTypeEnumUtils.validateOneOf(order.payment?.type, [PaymentTypeEnum.WIRE_TRANSFER]);

export const normalizeSku = (sku?: string): string => sku?.replaceAll('-', '.') || '';

/**
 * @param {string} sku
 * @returns {string}
 *
 * @example 'baw-2-12m' => 'baw.*.12m'
 */
export const normalizeBusinessSku = (sku?: string): string => {
	const parts = normalizeSku(sku).split('.');
	if (parts.length === 3) {
		parts[1] = '*';
	}
	return parts.join('.');
};

export const getProductGroupCodeFromSku = (sku: string): string => {
	const [groupName] = sku.split('.');
	return groupName;
};

export const isOrderItemBusiness = (item: IEntityOrderItemBase): boolean => {
	if (Boolean(item.tierLevel)) {
		return item.tierLevel !== 'NA';
	}
	const bulkQuantity = extractBulkQuantityFromSku(item.product?.sku);
	return bulkQuantity === 0;
};

export const isOrderInQuoteStatus = (order: IEntityOrder | null): boolean =>
	isOrderInStatus(order, [OrderStatusEnum.ISSUED_AS_QUOTE, OrderStatusEnum.ISSUED_AS_CUSTOMER_QUOTE]);

export const isEndCustomerOrder = (order: IEntityOrderBase): boolean =>
	order.billableParty?.type === BillablePartyTypeEnum.END_CUSTOMER;

export const getBillablePartyFullName = (order: IEntityOrder | null): string => {
	if (!order) {
		return '';
	}

	return joinValues([order.billableParty?.firstName, order.billableParty?.lastName], ' ');
};

export const getBillablePartyRenderName = (order: IEntityOrder | null): string => {
	if (!order) {
		return '';
	}

	return order.billableParty?.name || getBillablePartyFullName(order);
};

export const getCustomerCurrency = (order: IEntityOrder): string | undefined => {
	if (isEndCustomerOrder(order)) {
		return order.currency;
	}
};

const isOrderReturned = (order: IEntityOrder) => order.isOrderReturned === true;

const hasAvailableNonGcDocuments = (order: IEntityOrder, isOrderRefunded?: boolean): boolean =>
	!isGcOrder(order) &&
	(isOrderInStatus(order, OrderStatusEnum.COMPLETE) ||
		(isOrderInStatus(order, OrderStatusEnum.WAITING_PAYMENT) &&
			isOrderPaymentTypeInState(order, PaymentTypeEnum.LOC)) ||
		isPaymentInstructionsEligible(order) ||
		isOrderType(order, OrderTypeEnum.PAYG) ||
		(isOrderRefunded ?? isOrderReturned(order)));

export const hasAvailableDocuments = (order: IEntityOrder, isOrderRefunded?: boolean): boolean => {
	if (isEndCustomerQuote(order)) {
		return false;
	}

	if (!isOrderInStatus(order, OrderStatusEnum.COMPLETE)) {
		if (!hasAvailableNonGcDocuments(order, isOrderRefunded)) {
			return false;
		}
	}

	return hasChargeOrder(order);
};

export const isOrderRefundable = (order: IEntityOrder, authPartnerGroups: IPartnerGroups): boolean => {
	// Disable GC refunds for non-test accounts
	if (isGcOrder(order) && !authPartnerGroups.isTestAccount) {
		return false;
	}

	if (!order.chargeOrderId) {
		return false;
	}

	// LOC orders
	if (
		isOrderPaymentTypeInState(order, PaymentTypeEnum.LOC) &&
		isOrderInStatus(order, [OrderStatusEnum.COMPLETE, OrderStatusEnum.WAITING_PAYMENT])
	) {
		return true;
	}

	// GC Auto Billing
	if (
		isOrderPaymentTypeInState(order, PaymentTypeEnum.AUTO_BILLING) &&
		isOrderInStatus(order, [OrderStatusEnum.COMPLETE])
	) {
		return true;
	}

	// NS orders
	if (isNetSuiteOrder(order)) {
		return (
			isOrderPaymentTypeInState(order, PaymentTypeEnum.WIRE_TRANSFER) &&
			isOrderPaymentInStatus(order, PaymentStatusEnum.PAID)
		);
	}

	return false;
};

export const isOrderItemCancelled = (item: IEntityOrderItem) => item.pricedQuantity === 0;

export const canShowOrderItemPriceDetails = (orderItem: IEntityOrderItem) => {
	const { pricingMessage, pricing } = orderItem;

	return !isOrderItemCancelled(orderItem) && (pricingMessage || pricing);
};

export const exportedForTesting = {
	hasChargeOrder,
	isOrderReturned,
	hasAvailableNonGcDocuments,
};
