import React, { ReactElement } from 'react';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { usePageDetailContext } from 'js/contexts';
import { IEntityOrder } from 'module/orders';
import { Col, Row } from 'js/components/atoms/Row';
import { DiscountCard, DiscountTable } from 'module/orders/components/DiscretionaryDiscount';
import { Alert } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { hasOrderDiscretionaryDiscount } from 'module/orders/utils/common';
import { DiscountApproval } from 'module/orders/components/DiscretionaryDiscount/DiscountApproval';

export const DiscretionaryDiscountTab = (): ReactElement => {
	const { data } = usePageDetailContext<IEntityOrder>();
	const [t] = useTranslation(ordersConfig.trNamespace);

	if (!hasOrderDiscretionaryDiscount(data)) {
		return (
			<DefaultContainer>
				<Alert caption={t('error:detailNotFound')} />
			</DefaultContainer>
		);
	}

	return (
		<DefaultContainer>
			<DiscountApproval />
			<Row
				multi
				size="sm"
				className="mb-8"
			>
				<Col md={6}>
					<DiscountCard order={data} />
				</Col>
			</Row>
			{data.hasLineDiscount && <DiscountTable order={data} />}
		</DefaultContainer>
	);
};
