import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Toast } from '@avast/react-ui-components';
import { creditLimitConfig } from 'module/creditLimit/creditLimitConfig';
import { CreditLimitForm } from 'module/creditLimit/components/CreditLimitForm';
import { useApiCreateCreditLimit } from 'module/creditLimit/hooks/useApiCreditLimit';
import { toast } from 'react-toastify';
import { createCreditLimitApiError } from 'module/creditLimit/utils/apiError';
import { TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { STATUS_SUCCESS } from 'appConstants';
import { TPartnerId } from 'types';
import type { FormikConfig } from 'formik';
import { ICreditLimitForm } from 'module/creditLimit';
import { useApiErrorContext, useAppContext } from 'js/contexts';

type TCreditLimitCreateContent = TAsyncModalContainerProps & {
	initialPartnerId?: TPartnerId | null;
};

export const CreditLimitCreateModalContent = (props: TCreditLimitCreateContent) => {
	const { forwardedRef, initialPartnerId } = props;
	const [t] = useTranslation(creditLimitConfig.trNamespace);
	const { loadingModalRef } = useAppContext();
	const { setError } = useApiErrorContext();
	const { mutate: createCreditLimit } = useApiCreateCreditLimit();

	// Initial values
	const initialValues = { ...creditLimitConfig.defaultFormValues };
	if (initialPartnerId) {
		initialValues.partnerId = initialPartnerId;
	}

	const handleSubmitting = useCallback<FormikConfig<ICreditLimitForm>['onSubmit']>(
		(values, { setSubmitting }) => {
			loadingModalRef.current?.show({
				title: t('modal.create.loading'),
				ellipsis: true,
			});

			createCreditLimit(values, {
				onSuccess(response) {
					setSubmitting(false);
					loadingModalRef.current?.hide();
					forwardedRef.current?.onSuccess(STATUS_SUCCESS);
					if (Boolean(response?.data)) {
						toast.success(<Toast>{t('modal.create.success')}</Toast>);
					}
				},
				onError(error) {
					setSubmitting(false);
					loadingModalRef.current?.hide();
					setError({ error, resolve: createCreditLimitApiError });
				},
			});
		},
		[createCreditLimit, forwardedRef, loadingModalRef, setError, t],
	);

	return (
		<>
			<Modal.Header>{t('modal.create.title')}</Modal.Header>
			<Modal.Body>
				<CreditLimitForm
					onSubmit={handleSubmitting}
					onCancel={() => forwardedRef.current?.hide()}
					initialValues={initialValues}
				/>
			</Modal.Body>
		</>
	);
};
