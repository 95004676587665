export default {
	actions: {
		create: 'Crear nuevo socio de distribución',
		edit: 'Editar socio de distribución',
		exportAll: 'Exportar todos los socios de distribución',
		form: {
			create: 'Guardar como nuevo socio de distribución',
			update: 'Guardar cambios',
		},
	},
	error: {
		createDistributionPartner: 'No se puede crear el socio de distribución.',
		updateDistributionPartner: 'No se puede actualizar el socio de distribución.',
	},
	page: {
		create: {
			title: 'Nuevo socio de distribución',
			success: 'Creó correctamente un nuevo socio de distribución.',
		},
		update: {
			success: 'Actualizó correctamente un nuevo socio de distribución.',
		},
	},
	select: {
		title: 'Seleccionar socio de distribución',
		button: {
			label: 'Socio de distribución',
			placeholder: 'Seleccionar socio de distribución',
		},
		filter: {
			placeholder: 'Buscar por correo electrónico, nombre o empresa',
		},
	},
};
