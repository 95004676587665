import { Button, H1, Modal } from '@avast/react-ui-components';
import React from 'react';
import { useHelpContext } from 'js/help/HelpContext';
import { Col, Row } from 'js/components/atoms/Row';
import { useTranslation } from 'react-i18next';
import { HelpCard } from 'js/help/components/HelpCard';
import { VideosList } from 'js/help/videos/VideosList';
import { HelpStepEnum } from 'js/enums';

export const HelpHome = () => {
	const [t] = useTranslation('app');
	const { setStep } = useHelpContext();

	return (
		<>
			<Modal.Header>&nbsp;</Modal.Header>
			<Modal.Body className="pb-xl-8 px-xl-8 pt-xl-3 help-home">
				<H1 className="text-center mb-8">{t('help.main.title')}</H1>
				<Row
					multi
					size="sm"
					justify="center"
				>
					<Col md={6}>
						<HelpCard title={t('help.main.guide.title')}>
							<div className="wysiwyg mb-3">
								<p>{t('help.main.guide.text')}</p>
							</div>
							<Button
								size="sm"
								type="button"
								onClick={() => setStep(HelpStepEnum.GUIDE)}
							>
								{t('help.main.guide.action')}
							</Button>
						</HelpCard>
					</Col>
					<Col md={6}>
						<HelpCard title={t('help.main.videos.title')}>
							<VideosList />
						</HelpCard>
					</Col>
				</Row>
			</Modal.Body>
		</>
	);
};
