import { useWidgetValidator } from 'submodule/widgets/hooks/useWidgetValidator';
import type { ArraySchema } from 'yup';
import * as Yup from 'yup';
import { TWidgetGridColumn, TWidgetGridItem, TWidgetGridRow } from 'submodule/widgets';

export type TWidgetValidatorSchema = ArraySchema<TWidgetGridRow>;

export const useWidgetConfigValidator = (): TWidgetValidatorSchema => {
	const widgetValidator = useWidgetValidator();

	const itemValidator = Yup.object().shape<TWidgetGridItem>({
		id: Yup.number().required(),
		widget: widgetValidator.required(),
	});

	const columnValidator = Yup.array().required().min(0).of(itemValidator);

	const rowValidator = Yup.array<TWidgetGridColumn>().required().max(2).of(columnValidator);

	// @ts-ignore
	return Yup.array<TWidgetGridRow>().required().of(rowValidator);
};
