export default {
	dateRangeType: {
		BILLING: 'Billing Date',
		CREATED: 'Created Date',
		PAYMENT: 'Payment Date',
	},
	tierCertification: {
		DISTRIBUTORS: 'Distributors',
		TIER_4: 'Tier 4',
		TIER_3: 'Tier 3',
		TIER_2: 'Tier 2',
		TIER_1: 'Tier 1',
	},
	customer: {
		type: {
			BUSINESS: 'Business',
			CONSUMER: 'Consumer',
		},
		discountType: {
			EDUCATION: {
				name: 'Education',
				description:
					'Educational institution with additional discount, for application of this discount on your Order please contact your Sales Manager.',
			},
			GOVERNMENT: {
				name: 'Government',
				description:
					'Governmental institution with additional discount, for application of this discount on your Order please contact your Sales Manager.',
			},
			NOT_FOR_PROFIT: {
				name: 'Not for Profit',
				description:
					'Not for Profit institution with additional discount, for application of this discount on your Order please contact your Sales Manager.',
			},
		},
		searchKey: {
			EMAIL: 'E-mail',
			SALESFORCE_ID: 'Salesforce Id',
		},
	},
	partner: {
		type: {
			DISTRIBUTOR: 'Distributor',
			RESELLER: 'Reseller',
			INTERNAL_COMPANY: 'Internal Company',
			USER: 'User',
		},
		subType: {
			RETAIL: 'Retail',
			ISP: 'ISP',
			MSP: 'MSP',
			NONE: 'None',
		},
		terms: {
			DR: 'Digital River Channel Participant Membership Agreement',
			CLIENT: 'Partner Agreement',
		},
		payAsYouGoPaymentMethod: {
			LINE_OF_CREDIT: {
				name: 'LOC',
				description:
					'An invoice will be generated shortly after the end of a month. Your standard payment terms will apply. An LOC invoice can only be paid with a bank transfer. ',
			},
			AUTO_BILLING: {
				name: 'Auto-Billing',
				description:
					'Your saved card will be charged automatically shortly after the end of a month. In the case of a failed attempt, a standard LOC invoice will be generated.',
			},
		},
	},
	order: {
		discountCategory: {
			NONE: 'None',
			DISCRETIONARY: 'Discretionary',
		},
		lineItemDiscountType: {
			STANDARD: 'Standard',
			SPECIAL: 'Special',
			NONE: 'None',
		},
		approvalFlag: {
			NA: 'NA',
			APPROVAL_NOT_REQUIRED: 'Approval Not Required',
			APPROVAL_REQUIRED: 'Approval Required',
			PENDING_APPROVAL: 'Pending Approval',
			APPROVED: 'Approved',
			REJECTED: 'Rejected',
		},
		billablePartyType: {
			PARTNER: 'Partner',
			DISTRIBUTOR: 'Distributor',
			END_CUSTOMER: 'End Customer',
		},
		searchKey: {
			COMMERCE_ORDER_ID: 'Billable Order ID',
			OPPORTUNITY_ID: 'Opportunity ID',
			ORDER_NUMBER: 'Order Number',
			PO_NUMBER: 'PO Number',
			QUOTE_NUMBER: 'Quote Number',
		},
		status: {
			SAVED_IN_PROGRESS: {
				name: 'Saved In Progress',
				description: 'The order has been saved but not submitted. Only orders in this status can be modified.',
			},
			ISSUED_AS_CUSTOMER_QUOTE: {
				name: 'Issued as Customer Quote',
				description:
					'The order is a quote submitted to a customer. It cannot be edited by the customer - only submitted.',
			},
			ISSUED_AS_QUOTE: {
				name: 'Issued as Quote',
				description: 'The order is a quote from Avast. It cannot be edited - only submitted.',
			},
			POSTED_TO_STATEMENT: {
				name: 'Posted to Statement',
				description: 'The order has been posted to a monthly statement.',
			},
			PENDING_SUBMISSION: {
				name: 'Pending Submission',
				description: '',
			},
			IN_REVIEW: {
				name: 'In Review',
				description: 'The order is waiting for review. It cannot be edited.',
			},
			SUBMITTED_TO_COMMERCE: {
				name: 'Submitted to Commerce',
				description: 'The order has been submitted for billing.',
			},
			PAYMENT_ERROR: {
				name: 'Payment Error',
				description:
					'Payment error occurred. The order should be resubmitted after verifying accurate and upto date payment information.',
			},
			OTHER_ERROR: {
				name: 'Other Error',
				description:
					'A system error occurred and persisted after 3 retry attempts. The order should be resubmitted for retry or support should be contacted.',
			},
			WAITING_PAYMENT: {
				name: 'Waiting Payment',
				description: 'The order has been submitted for billing. Payment is due.',
			},
			COMPLETE: {
				name: 'Complete',
				description: 'Payment is collected and the order is complete.',
			},
			CANCELED_BY_USER: {
				name: 'Canceled by User',
				description: 'A user chose to cancel the order.',
			},
			CANCELED_FOR_FAILURE_TO_PAY: {
				name: 'Canceled for Failure to Pay',
				description: 'The order was canceled because of failure to pay for the order in full.',
			},
			EXPIRED: {
				name: 'Expired',
				description: 'The order has not been modified for 30 days after it was created.',
			},
			NOT_SUBMITTED: {
				name: 'Not Submitted',
				description: 'The order has not been submitted for billing.',
			},
		},
	},
	payment: {
		status: {
			PAID: {
				name: 'Paid',
				description: 'The order has been successfully processed and the payment has been received.',
			},
			UNPAID: {
				name: 'Unpaid',
				description: 'The order is awaiting payment or the payment has not been received.',
			},
			PARTIAL_PAID: {
				name: 'Partially Paid',
				description: 'The order has an outstanding balance remaining.',
			},
			NA: {
				name: 'Unbilled',
				description: 'The order is pending billing or awaiting invoicing.',
			},
			UNBILLED: {
				name: 'Unbilled',
				description: 'The order is pending billing or awaiting invoicing.',
			},
			SETTLED: {
				name: 'Settled',
				description: 'The order has been processed and completed by Collection Team.',
			},
		},
		type: {
			WIRE_TRANSFER: 'Wire Transfer',
			WTOC: 'WTOC',
			CART_SELECTED: 'Cart Selected',
			CLOSING_BALANCE: 'Closing Balance',
			LOC: 'LOC',
			AUTO_BILLING: 'Auto-Billing',
		},
	},
	licenses: {
		searchKey: {
			LICENSE_KEY: 'License key',
			ORDER_NUMBER: 'Order number',
		},
		status: {
			ACTIVE: {
				name: 'Active',
				description: 'The license in use',
			},
			INACTIVE: {
				name: 'Inactive',
				description: 'The activation code has not been used',
			},
			CANCELLED: {
				name: 'Canceled',
				description: 'The license has been canceled or refunded',
			},
			REPLACED: {
				name: 'Replaced',
				description: 'An active entitlement has been replaced by another active entitlement during operation',
			},
			EXPIRED: {
				name: 'Expired',
				description: 'The expiration date of an active entitlement has passed',
			},
			USED: {
				name: 'Used',
				description: 'The license has been used',
			},
			MIGRATED: {
				name: 'Migrated',
				description: 'The license has been migrated to a successor product license',
			},
			BLACKLISTED: {
				name: 'Blacklisted',
				description: '',
			},
		},
		datepickerDateType: {
			CREATED_AT: 'Created date',
			EXPIRATION: 'Expiry date',
			REGISTERED_AT: 'Registration date',
		},
	},
	closingBalance: {
		searchKey: {
			ORDER_NUMBER: 'Detail ID',
		},
	},
	creditStatus: {
		transactionSource: {
			CANCELLED_LICENSE: 'Canceled License',
			CANCELLED_ORDER: 'Canceled Order',
			MSP: 'MSP',
			NEW_ORDER: 'New Order',
			OMS_REFUND: 'Refund',
			PAID_ORDER: 'Paid Order',
			SAP: 'SAP',
			SAP_TAX: 'Tax',
			OMS_TAX: 'Tax',
			ORACLE_TAX: 'Tax',
			ADMIN_CUSTOM: 'Custom Adjustment',
			PAY_AS_YOU_GO: 'Flexible Billing',
			TAX_REFUND: 'Tax Refund',
			PAY_AS_YOU_GO_CONSOLIDATION: 'Flexible Billing - correction',
			SETTLED_ORDER: 'Settled',
		},
	},
	purchase: {
		marketSegment: {
			BUSINESS: 'Business',
			CONSUMER: 'Consumer',
		},
		billablePartyType: {
			PARTNER: 'Partner',
			CUSTOMER: 'Customer',
		},
		type: {
			PREPAID: 'Prepaid',
			FLEXIBLE_BILLING: 'Flexible Billing',
		},
	},
	promotion: {
		scope: {
			PARTY_ONLY: 'Party Only',
			PRODUCT_ONLY: 'Product Only',
			PARTY_AND_PRODUCT: 'Party and Product',
		},
		type: {
			DISCRETIONARY: 'Discretionary',
			SALES_CAMPAIGN: 'Sales Campaign',
		},
		partyType: {
			DISTRIBUTION: 'Distributor',
			RESELLER: 'Reseller',
			END_CUSTOMER: 'End Customer',
		},
		partyTier: {
			DISTRIBUTORS: 'Distributors',
			NONE: 'None',
			TIER_4: 'Tier 4',
			TIER_3: 'Tier 3',
			TIER_2: 'Tier 2',
			TIER_1: 'Tier 1',
		},
		state: {
			ENABLED: 'Enabled',
			DISABLED: 'Disabled',
		},
	},
	retail: {
		status: {
			COMPLETE: 'Complete',
			IN_PROGRESS: 'In progress',
		},
	},
	refunds: {
		status: {
			OPEN: 'Open',
			FULLY_APPLIED: 'Fully Applied',
			VOIDED: 'Voided',
			PENDING: 'Pending',
		},
		type: {
			ORDER: 'Order Refund',
			LINE: 'Line Item Refund',
			KEY: 'Key Refund',
			TAX: 'Tax Refund',
		},
		reason: {
			CUSTOMER_SATISFACTION_ISSUE: 'Customer Satisfaction Issue',
			INCORRECT_PRICE_INVOICED: 'Incorrect Price Invoiced',
			NOT_DELIVERED: 'Not Delivered',
			WRONG_PRODUCT: 'Wrong Product',
			PRODUCT_NOT_WORKING: 'Product Not Working',
			TAX_EXEMPT: 'Tax Exempt',
			OTHER_REASON: 'Other Reason',
		},
	},
	comparativeFeature: {
		ONLINE_MANAGEMENT: {
			name: 'Online Management Platform',
			description:
				'Easily set up your device protection, manage your subscriptions, monitor, and add devices from one place — anytime, anywhere.',
		},
		DEVICE_PROTECTION: {
			name: 'Device Protection',
			description:
				'Award-winning antivirus to protect all your devices against malware, phishing, ransomware, and other advanced cyberattacks.',
		},
		DATA_PROTECTION: {
			name: 'Data Protection',
			description: 'Protects your personal, business, and customer data from theft.',
		},
		IT_SUPPORT: {
			name: 'IT Support',
			description:
				'24/5 fast and friendly support from our highly trained technical engineers, who can assist you and your employees via email, chat, or phone.',
		},
		IDENTITY_PROTECTION: {
			name: 'Identity Protection',
			description: 'Includes password and webcam protection to safeguard your employees’ online identities.',
		},
		UNLIMITED_VPN: {
			name: 'Unlimited VPN',
			description:
				'Our built-in VPN encrypts your data and secures your employees’ internet connections to make them private when using public Wi-Fi networks.',
		},
		USB_PROTECTION: {
			name: 'USB Protection',
			description:
				'Prevent employees from using unauthorized removable storage devices including flash drives, external drives, memory cards, etc. Block, control, and monitor USB ports to stop data theft, data loss and malware infections.',
		},
		PATCH_MANAGEMENT: {
			name: 'Patch Management',
			description:
				'Automatically fixes vulnerabilities on your software and third-party applications that are susceptible to cyberattacks.',
		},
	},
	productGroupTag: {
		NEW: 'New',
	},
	widgets: {
		label: {
			QUOTES: 'Recent Quotes',
			ORDERS: 'Recent Orders',
			CREDIT_STATUS: 'Credit Status',
			EXPIRING_LICENSES_NEXT_N_DAYS: 'Expiring Licenses next N days',
			EXPIRED_LICENSES_LAST_N_DAYS: 'Expired Licenses last N days',
			CLOSING_BALANCE: 'Recent Closing Balance Statements',
		},
		caption: {
			QUOTES: 'Recent Quotes',
			ORDERS: 'Recent Orders',
			CREDIT_STATUS: 'Credit Status',
			EXPIRING_LICENSES_NEXT_N_DAYS: 'Expiring Licenses next {{count}} days',
			EXPIRED_LICENSES_LAST_N_DAYS: 'Expired Licenses last {{count}} days',
			CLOSING_BALANCE: 'Recent Closing Balance Statements',
		},
	},
};
