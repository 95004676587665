import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { isDecreaseOperation, isIncreaseOperation } from 'module/licenses/utils/licenseOperation';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { ILicenseOperationForm } from 'module/licenses';

export const useLicenseOperationUnitTotal = (): number => {
	const { licenseProductGroupIsBusiness } = useLicenseOperationContext();
	const {
		values: { quantity, unit, unitToIncrease, unitToDecrease, licenseOperation },
	} = useFormikContext<ILicenseOperationForm>();

	return useMemo(() => {
		if (!licenseProductGroupIsBusiness) {
			return quantity;
		}
		if (isIncreaseOperation(licenseOperation)) {
			return unit + unitToIncrease;
		}
		if (isDecreaseOperation(licenseOperation)) {
			return unit - unitToDecrease;
		}
		return unit;
	}, [licenseProductGroupIsBusiness, licenseOperation, unit, quantity, unitToIncrease, unitToDecrease]);
};
