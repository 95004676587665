import { merge, pick } from 'lodash';
import en from './en';
import de from './de';
import es from './es';
import fr from './fr';
import it from './it';
import ja from './ja';
import zh from './zh';
// Import components locales from UI library to EN language
import { i18nComponentsLocales } from '@avast/react-ui-components';
import type { ResourceLanguage } from 'i18next';
import { TLocaleLanguage } from 'types/config';
import { LOCALE } from 'config/locale';

export const languages: Partial<Record<TLocaleLanguage, ResourceLanguage>> = pick(
	{
		en: merge({ components: i18nComponentsLocales }, en),
		de,
		es,
		fr,
		it,
		ja,
		zh,
	},
	LOCALE.LANGUAGES,
);
