import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum PromotionTypeEnum {
	DISCRETIONARY = 'DISCRETIONARY',
	SALES_CAMPAIGN = 'SALES_CAMPAIGN',
}

export const promotionTypeEnumUtils = generateEnumUtils<PromotionTypeEnum>(PromotionTypeEnum, {
	translatePath: 'enums:promotion.type',
});
