import { IEntityPartnerDetail } from 'module/partners';
import { TableColumnsDef } from 'types';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { partnerSubTypeEnumUtils, partnerTypeEnumUtils } from 'module/partners/enums';
import { noValuePlaceholder } from 'js/utils/app';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { partnersConfig } from 'module/partners/partnersConfig';

type TTableData = IEntityPartnerDetail;

export const useSelectPartnerColumns = (): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(partnersConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.text('companyName', {
				header: t('common:entity.company'),
				meta: { formatters: [formatter.bold] },
			}),
			columnHelper.text('firstName', { header: t('common:contact.firstName') }),
			columnHelper.text('lastName', { header: t('common:contact.lastName') }),
			columnHelper.text('email', { header: t('common:contact.email') }),
			columnHelper.text('accountType', {
				header: t('entity.accountType'),
				enableSorting: false,
				meta: {
					formatters: [partnerTypeEnumUtils.getText],
				},
			}),
			columnHelper.text('accountSubType', {
				header: t('entity.accountSubType'),
				enableSorting: false,
				meta: {
					defaultValue: { value: noValuePlaceholder() },
					formatters: [partnerSubTypeEnumUtils.getText],
				},
			}),
		];
	}, [t]);
};
