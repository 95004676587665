export default {
	entity: {
		createdBy: '建立者',
	},
	actions: {
		create: '建立信用額度限制',
	},
	error: {
		createCreditLimit: '無法建立信用額度限制。',
	},
	modal: {
		create: {
			title: '建立信用額度限制',
			loading: '正在建立信用額度限制',
			success: '已成功建立信用額度限制',
		},
	},
};
