import React from 'react';
import { Modal, UiTable } from '@avast/react-ui-components';
import { IEntityOrderDocument } from 'js/chargeOrder';
import { TChargeOrderId, TEventDetailId } from 'types';
import { useChargeOrderDocuments } from 'js/components/chargeOrderDocuments/useChargeOrderDocuments';
import { useChargeOrderDocumentsColumns } from 'js/components/chargeOrderDocuments/hooks';

export type TChargeOrderDocumentsModalContentProps = {
	id: TEventDetailId;
	chargeOrderId: TChargeOrderId;
	title: string;
};

export const ChargeOrderDocumentsModalContent = (props: TChargeOrderDocumentsModalContentProps) => {
	const { id, chargeOrderId, title } = props;
	const { documents, isFetching } = useChargeOrderDocuments(chargeOrderId);
	const columns = useChargeOrderDocumentsColumns(id);

	return (
		<>
			<Modal.Header>
				{title} <small>#{id}</small>
			</Modal.Header>
			<Modal.Body>
				<UiTable<IEntityOrderDocument>
					columns={columns}
					data={documents}
					meta={{
						loading: isFetching,
						isScrollable: true,
						rowCount: 3, // Init loading rows count
						tableProps: { className: 'table-head-autoHeight' },
					}}
				/>
			</Modal.Body>
			<Modal.Footer />
		</>
	);
};
