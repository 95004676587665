export default {
	steps: {
		billableParty: {
			title: 'Partie facturable',
			virtualPartnerTitle: 'Nouveau prospect',
		},
		product: {
			title: 'Produit',
			isRenewal: 'S’agit-il d’un renouvellement ?',
		},
		discounts: {
			title: 'Remises',
			discretionary: 'Remise discrétionnaire',
			special: 'Remise spéciale',
			tier: 'Remise de niveau',
			segment: 'Remise sectorielle',
		},
		price: {
			title: 'Prix',
			appliedPromotions: 'Promotions appliquées',
		},
	},
	message: {
		countryCodeNotSelected: 'Sélectionnez d’abord un code de pays',
		requiredStepsNotFulfilled: 'Sélectionnez une partie facturable et un produit pour afficher les prix',
	},
	goalSeekedPrice: {
		title: 'Prix unitaire ciblé',
		originalPrice: 'Prix unitaire d’origine',
		finalPrice: 'Prix unitaire final',
	},
};
