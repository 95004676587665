export default {
	entity: {
		createdBy: 'Created By',
	},
	actions: {
		create: 'Create Credit Limit',
	},
	error: {
		createCreditLimit: 'Unable to create the Credit Limit.',
	},
	modal: {
		create: {
			title: 'Create Credit Limit',
			loading: 'Creating Credit Limit',
			success: 'Credit Limit successfully created',
		},
	},
};
