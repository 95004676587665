export default {
	steps: {
		billableParty: {
			title: 'Fakturierbare Partei',
			virtualPartnerTitle: 'Neuer potenzieller Kunde',
		},
		product: {
			title: 'Produkt',
			isRenewal: 'Verlängerung?',
		},
		discounts: {
			title: 'Preisnachlässe',
			discretionary: 'Ermessensrabatt',
			special: 'Sonderrabatt',
			tier: 'Stufenrabatt',
			segment: 'Segmentrabatt',
		},
		price: {
			title: 'Preis',
			appliedPromotions: 'Angewandte Werbeaktionen',
		},
	},
	message: {
		countryCodeNotSelected: 'Bitte zuerst Ländercode wählen',
		requiredStepsNotFulfilled: 'Bitte fakturierbare Partei und Produkt wählen, um Preise anzuzeigen',
	},
	goalSeekedPrice: {
		title: 'Angestrebtes Ziel für Einzelpreis',
		originalPrice: 'Original-Einzelpreis',
		finalPrice: 'Endgültiger Einzelpreis',
	},
};
