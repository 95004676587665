export default {
	common: {
		continue: '繼續註冊',
		skip: '暫時略過',
		submitting: '正在提交您的資料...',
		finishing: '我們正在完成您的註冊...',
		successRegistration: '您已註冊成功',
		successAcceptTerms: '已成功接受條款與條件。謝謝。',
	},
	steps: {
		init: '歡迎蒞臨',
		terms: '條款與條件',
		registrationInfo: '重要資訊',
		taxExemption: '免稅',
	},
	page: {
		welcome: {
			title: '歡迎使用電子商務註冊程序',
			content: '我們將引導您完成註冊所需的步驟，並要求您：',
			conditionList: ['接受條款與條件', '更新並確認您的重要設定檔資訊'],
		},
		terms: {
			title: '請檢閱並同意我們的條款與條件：',
			button: '我已閱讀授權合約內容並接受條款',
		},
		registrationInfo: {
			contactInformation: '聯絡資訊',
			accountInformation: '帳戶資訊',
		},
		taxExemption: {
			title: '請提交您的免稅詳細資料',
			subtitle: '請填寫必要的免稅資訊。',
		},
	},
	error: {
		unableToInit: '無法啟動註冊。',
		unableToRegistrationToken: '無法取得註冊 Token。',
		unableToStart: '無法啟動註冊。',
		unableToLoadTerms: '無法載入條款與條件。請聯絡您的客戶經理以尋求協助。',
		unableToAcceptTerms: '無法接受目前合作夥伴的條款。',
		unableToProcess: '我們目前無法傳送您的資料進行處理。',
	},
	alert: {
		checkCurrentAccountInfo:
			'我們已經填寫了您目前儲存在我們系統中的帳戶資訊。請檢閱此資訊並在必要時提交更新。系統會記錄您提交的任何變更，以供日後使用。',
		changeAccountDataInPartnerPortal:
			'如果您需要變更聯絡資訊，請完成註冊程序，然後造訪 Avast Business 合作夥伴入口網站中的「我的設定檔」區段來編輯您的設定檔資訊。',
	},
};
