import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PageLogin, PageLogout, PageNewPassword, PagePasswordReset } from 'module/security/pages';
import { NoAuthRoute } from 'js/routes/NoAuthRoute';
import { DefaultLayout } from 'module/security/layouts/DefaultLayout';
import { Error404 } from 'module/error/page/Error404';

export const SecurityRoutes = () => (
	<Routes>
		<Route
			path="login"
			element={<PageLogin />}
		/>
		<Route
			path="logout"
			element={<PageLogout />}
		/>
		<Route element={<NoAuthRoute />}>
			<Route
				path="password-reset"
				element={<PagePasswordReset />}
			/>
			<Route
				path="password-reset/:token"
				element={<PageNewPassword />}
			/>
		</Route>
		<Route element={<DefaultLayout />}>
			<Route
				path="*"
				element={<Error404 />}
			/>
		</Route>
	</Routes>
);
