import React, { FunctionComponent } from 'react';
import { ModuleContainer } from 'js/layouts/container/ModuleContainer';
import { retailConfig } from 'module/retail/retailConfig';
import { RetailRoutes } from 'module/retail/RetailRoutes';

export const RetailContainer: FunctionComponent = () => (
	<>
		<ModuleContainer moduleMenuConfig={retailConfig} />
		<RetailRoutes />
	</>
);
