export default {
	actions: {
		addProductsToOrder: 'Agregar productos',
		cancel: 'Cancelar pedido',
		cancelQuote: 'Cancelar cotización',
		createQuote: 'Crear nueva cotización',
		cancelLicense: 'Cancelar licencia',
		cancelSelectedLicenses: 'Cancelar licencia seleccionada',
		cancelSelectedLicenses_zero: 'Cancelar licencias seleccionadas',
		cancelSelectedLicenses_other: 'Cancelar licencias seleccionadas ({{count}})',
		detail: 'Ver detalles de pedido',
		detailQuote: 'Ver detalles de cotización',
		documents: 'Ver documentos de pedido',
		edit: 'Editar pedido',
		editQuote: 'Editar cotización',
		export: {
			default: 'Exportar pedidos',
			detailed: 'Exportación detallada',
			quotes: 'Exportar cotizaciones',
		},
		exportQuotes: 'Exportar cotizaciones',
		licenses: 'Ver licencias',
		placeQuote: 'Realizar un pedido de cotización',
		priceDetails: 'Ver detalles de precio',
		thisMonth: 'Este mes',
		viewClosingBalance: 'Ver pedido de saldo final',
		pay: 'Pagar',
		payEdit: 'Pagar/editar',
		payEditQuote: 'Pagar/editar cotización',
		payQuote: 'Pagar cotización',
	},
	common: {
		flexibleBilling: 'Facturación flexible',
		documents: 'Documentos de pedido',
		orderNumber: 'Pedido: #{{id}}',
		quoteNumber: 'Cotización: #{{id}}',
		priceDetails: 'Detalles de precio',
		licenses: 'Licencias',
		itemLicenses: 'Licencias de artículos',
		paymentInstructions: 'Instrucciones de pago',
		pricingMessage: 'Mensaje completo sobre precios',
		promoMessage: 'Mensaje completo sobre promoción',
		refunds: 'Reembolsos de pedidos',
		initiateRefund: 'Iniciar reembolso',
	},
	entity: {
		approvalStatus: 'Estado de aprobación',
		billablePartyName: 'Nombre de parte a la que se puede facturar',
		billablePartyType: 'Tipo de parte a la que se puede facturar',
		created: 'Fecha de creación',
		createdBy: 'Creado por',
		creationSource: 'Fuente de creación',
		purchaseType: 'Tipo de compra',
		purchaseTypePlaceholder: 'Cualquier tipo de compra',
		creationSourcePlaceholder: 'Cualquier fuente',
		date: 'Fecha',
		discountApplied: 'Descuento aplicado',
		discountInformation: 'Información de descuento',
		gcOrderNumber: 'Número de pedido de comercio',
		paymentStatusPlaceholder: 'Cualquier estado',
		noDiscount: 'Sin descuento',
		opportunityId: 'Número de oportunidad',
		orderAfterDiscount: 'Pedido después de descuento',
		orderNumber: 'Número de pedido',
		orderNumberShort: 'Número de pedido',
		orderStatusPlaceholder: 'Cualquier estado',
		originateAsQuote: 'Originar como cotización',
		quoteNumber: 'Número de cotización',
		quoteNumberShort: 'Número de cotización',
		quoteStatus: 'Estado de cotización',
		quoteType: 'Tipo de cotización',
		statementId: ' Id. de declaración',
		totalAmount: 'Valor',
	},
	error: {
		orderNotFound: 'No se encontró el pedido #{{id}}',
		orderNotEditable: 'No se puede editar el pedido #{{id}}',
		noProductsInOrder: {
			caption: 'No hay productos en el pedido',
			text: 'Agrega productos o cancela el pedido. No se pueden aplicar los cambios hasta que se envíe o guarde el pedido.',
		},
		orderChangesAreNotPersist: 'Complete o guarde el pedido para guardar los cambios.',
		orderCancellation: 'No se pudo cancelar el pedido.',
		orderSubmissionCancellation: 'No se pudo cancelar el envío del pedido.',
	},
	discountType: {
		line: 'Descuento de línea',
		other: '{{type}} {{category}}',
	},
	tab: {
		customer: 'Cliente',
		discretionaryDiscount: 'Descuento a discreción',
		itemsInOrder: 'Artículos en el pedido',
		itemsInQuote: 'Artículos en la cotización',
		licenses: 'Licencias',
		notes: 'Notas',
		orderStatus: 'Estado de pedido',
		partner: 'Socio de Avast',
		quoteStatus: 'Estado de cotización',
	},
	licenses: {
		inProcess: 'Espere mientras solicitamos las licencias para su pedido',
		error: {
			title: 'No pudimos recuperar las licencias para su pedido en esta oportunidad. Vuelva a comprobarlo más tarde.',
			detail:
				'Volveremos a intentar automáticamente recuperar las licencias, lo cual puede tardar unos minutos. Una vez generadas las licencias, las verá aquí y también las recibirá por correo electrónico.',
		},
	},
	paymentInstructions: {
		title: 'Instrucciones de pago',
		description: 'Proporcione al banco las siguientes instrucciones de transferencia bancaria',
		amount: 'Importe',
		referenceNumber: {
			label: 'Número de referencia de pago',
			note: 'Asegúrese de incluir este número o, de lo contrario, tal vez el pago no se reciba. En caso de que el banco no permita usar este número completo, cópielo y péguelo en el campo del mensaje para el destinatario.',
		},
		accountHolder: 'Titular de cuenta',
		bankName: 'Nombre de banco',
		accountNumber: 'Número de cuenta',
		iban: {
			label: 'IBAN',
			note: 'IBAN (número de cuenta bancaria internacional) es un estándar global que se usa para identificar cuentas bancarias. Se usa para facilitar el direccionamiento de pagos y evitar errores.',
		},
		bic: 'BIC',
		aba: 'ABA/número de dirección',
		ach: 'ACH',
		error: {
			notAvailable: 'Las instrucciones de pago no están disponibles.',
		},
	},
	cancelOrder: {
		title: 'Cancelación de pedido',
		message:
			'La cancelación de un pedido no se puede revertir ni deshacer. Aún podrá ver el pedido, pero no podrá realizar ninguna acción sobre él.',
		success: 'Pedido correctamente cancelado.',
		error: 'Se produjo un error durante la cancelación del pedido.',
		loading: 'Cancelación del pedido #{{orderNumber}}',
		wtoc: {
			title: 'Cancelación del pedido WTOC',
		},
	},
	discountApproval: {
		approved: 'Se aprobó el descuento a discreción. Puede finalizar el pedido.',
		rejected:
			'Se rechazó el descuento a discreción. Puede editar el pedido y volver a solicitar la aprobación de la solicitud.',
		alert: {
			caption: 'El descuento a discreción está esperando aprobación',
			text: 'Consulte el descuento y decida si aprobar o rechazar el descuento a discreción.',
			reject: 'Rechazar',
			approve: 'Aprobar',
		},
		alertApprove: {
			caption: 'El descuento a discreción se rechazó',
			text: 'Aún es posible aprobar el descuento.',
		},
		confirm: {
			approve: {
				title: 'Aprobar descuento',
				message: 'Confirme la aprobación de este descuento.',
				button: 'Confirmar aprobación de este descuento',
			},
			reject: {
				title: 'Rechazar descuento',
				message: 'Confirme el rechazo de este descuento.',
				button: 'Confirmar rechazo de este descuento',
			},
		},
		status: {
			success: {
				approve: 'El descuento se aprobó correctamente.',
				reject: 'El descuento se rechazó correctamente.',
			},
			error: 'No se pudo realizar el cambio de estado del descuento.',
		},
	},
	cancelLicenses: {
		loading: 'Cancelación de licencia',
		loading_other: 'Cancelación de licencias',
		success: 'La licencia se canceló correctamente',
		success_other: 'Las licencias se cancelaron correctamente',
		confirm: {
			title: 'Cancelar licencia',
			title_other: 'Cancelar licencias',
			message: '¿Realmente desea cancelar la licencia?',
			message_other: '¿Realmente desea cancelar las licencias?',
			message_note:
				'Tenga en cuenta que, en caso de cancelar las licencias restantes, se cancelará todo el pedido. El proceso de cancelación no se puede revertir ni deshacer.',
		},
	},
};
