import React, { ReactElement } from 'react';
import { H4 } from '@avast/react-ui-components';
import { IncreaseAndDecrease, SelectOperation, SelectProduct } from 'module/licenses/components/licenseOperation/form';
import { VariantTable } from 'module/licenses/components/licenseOperation/VariantTable';
import { Col, Row } from 'js/components/atoms/Row';
import { useFormikContext } from 'formik';
import { getLicenseOperationEntities } from 'module/licenses/utils/licenseOperation';
import { FormikControl } from 'js/components/formik/FormikControl';
import { useTranslation } from 'react-i18next';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { ILicenseOperationForm } from 'module/licenses';
import { ProductGroupExceptions } from 'js/components/molecules/Exceptions/ProductGroupExceptions';
import { licenseOperationEnumUtils } from 'module/licenses/enums';
import { ComparativeFeaturesLicenseOperationWidget } from 'submodule/comparativeFeatures/ComparativeFeaturesLicenseOperationWidget';

export const ModalContent = (): ReactElement => {
	const [t] = useTranslation();
	const {
		customerCurrencyOptions,
		licenseProductOperations,
		areRequiredFieldsFulfilled,
		isEndCustomerBillableParty,
		priceListCode,
	} = useLicenseOperationContext();
	const { values } = useFormikContext<ILicenseOperationForm>();
	const { licenseOperation, productGroupCode } = values;
	const transactionType = licenseOperationEnumUtils.getTransactionType(licenseOperation!);
	const { operation: productLicenseOperation, productGroup } = getLicenseOperationEntities(
		licenseProductOperations,
		licenseOperation,
		productGroupCode,
	);

	// Common variables
	const requiredFieldsFulfilled = areRequiredFieldsFulfilled(values);
	const isSingleProductGroup = (productLicenseOperation?.productGroups || []).length === 1;

	return (
		<>
			<Row className="mb-4">
				{isEndCustomerBillableParty && (
					<Col
						lg={requiredFieldsFulfilled ? 4 : 12}
						md={requiredFieldsFulfilled ? 6 : 12}
					>
						<FormikControl label={t('common:entity.currency')}>
							<FormikControl.SingleSelect
								name="licenseBillableParty.currencyCode"
								options={customerCurrencyOptions}
								required
								isClearable={false}
							/>
						</FormikControl>
					</Col>
				)}
				<Col
					lg={requiredFieldsFulfilled ? 4 : 12}
					md={requiredFieldsFulfilled ? 6 : 12}
				>
					<SelectOperation />
				</Col>
			</Row>

			{requiredFieldsFulfilled && (
				<>
					{productLicenseOperation && !isSingleProductGroup && (
						<Row
							className="mb-4"
							size="sm"
						>
							<Col lg={6}>
								<SelectProduct />
							</Col>
						</Row>
					)}

					<ProductGroupExceptions
						priceListCode={priceListCode!}
						productCode={productGroup?.code}
						transactionType={transactionType}
					/>
					<IncreaseAndDecrease />
					<ComparativeFeaturesLicenseOperationWidget />

					{productGroup && (
						<div className="mt-4">
							{isSingleProductGroup && (
								<H4
									type="h4"
									bold
									className="mb-2"
								>
									{productGroup.name} [{productGroup.code}]
								</H4>
							)}
							<VariantTable productGroup={productGroup} />
						</div>
					)}
				</>
			)}
		</>
	);
};
