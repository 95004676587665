export default {
	common: {
		forecastedSpend: 'Forecasted Spend',
		currentSpend: 'Current Spend',
		maxSpend: 'Maximum Spend',
		limit: 'Limit',
		noLimit: 'No Limit',
		searchDistributionPartner: 'Find Distribution Partner by name',
		unlimited: 'Unlimited',
		unlimitedNote:
			'The maximum spend can exceed your credit limit if usage limits are not set. When this happens, your partner’s product(s) will then become deactivated until the credit limit is increased or the balance is reduced.',
		valueBefore: 'Before',
		valueAfter: 'After saving changes',
	},
	page: {
		title: 'Flexible Billing',
	},
	actions: {
		edit: 'Manage Products',
		viewOrders: 'View Orders',
		enableProducts: 'Enable Products',
		enableAllProducts: 'Enable All Products',
		disableAllProducts: 'Disable All Products',
	},
	tab: {
		distributionPartners: 'Partners Overview',
		overview: 'Overview',
		reports: 'Reports',
	},
	modal: {
		confirmation: 'Are you sure?',
		message: 'Changes you’re saving can have implications on your credit. Do you wish to continue?',
		apply: 'Applying changes',
		success: 'Changes were successfully applied',
	},
	entity: {
		usageLimit: 'Usage Limit',
		yourPrice: 'Your Price',
	},
	invite: {
		info: 'This partner has not yet created a Business Hub account. You may set up Flexible Billing for them, but it will not be functional until their Business Hub account has been established.',
		action: {
			send: 'Send invite',
			resend: 'Resend invite',
		},
		lastSent: 'Last invite was sent to {{email}} on {{date}}.',
		processing: 'We are sending invitation to the Distribution Partner',
		success: 'Invitation successfully sended',
		description:
			"Sends an email invitation for Business Hub registration. This account will be the first admin account in the Hub (additional admins can be added there afterwards). Once registered, the new Business Hub account will be linked to the partner's record.",
		form: {
			create: {
				label: 'Create Business Hub when saving this partner',
				description:
					"Sends an email invitation for Business Hub registration once this form is saved. This account will be the first admin account in the Hub (additional admins can be added there afterwards). Once registered, the new Business Hub account will be linked to the partner's record.",
			},
			differentEmail: {
				partner: 'Send invitation to email on record',
				other: 'Send invitation to different email',
			},
		},
		modal: {
			title: 'Create Business Hub for {{name}}',
		},
	},
	tooltips: {
		unlimitedMaxSpend:
			'The maximum spend can exceed your credit limit if usage limits are not set. When this happens, your partner’s product(s) will become deactivated until the credit limit is increased or the balance is reduced.',
		forecastedSpend: {
			distributor:
				'The amount that will be spent during the current billing period if product usage across all partners remains as-is today.',
			partner:
				'The amount that will be spent during the current billing period if product usage for this partner remains as-is today.',
		},
		currentSpend: {
			distributor: 'The amount already spent during current billing period on active products across all partners.',
			partner: 'The amount already spent during current billing period on active products for this partner.',
		},
		maxSpend: {
			distributor:
				'The maximum amount that can be charged if all active products across all partners reach their usage limits.',
			partner:
				'The maximum amount that can be charged if all active products for this partner reach their usage limits.',
		},
	},
	error: {
		usageLimit: {
			title: 'Your changes cannot be saved at this time.',
			message:
				"Usage limit(s) for product(s) that you're trying to set are lower than total of usage limits your partner has established in their Business Hub for their customers.",
			product: 'Total of limits for <strong>{{product}}</strong> is currently set at <strong>{{limit}}</strong>.',
			note: 'We suggest contacting your partner to discuss usage limits.<br />If you need assistance, please contact our support team.',
		},
	},
};
