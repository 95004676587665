import { Button } from '@avast/react-ui-components';
import React from 'react';
import type { EmblaCarouselType } from 'embla-carousel';
import { useTranslation } from 'react-i18next';

type TGuideNextButtonProps = {
	slider?: EmblaCarouselType;
	onFinish(): void;
};

export const GuideNextButton = (props: TGuideNextButtonProps) => {
	const { slider, onFinish } = props;
	const [t] = useTranslation('common');
	const isLast = !slider?.canScrollNext();

	return (
		<Button
			variant="primary"
			onClick={() => (isLast ? onFinish() : slider?.scrollNext())}
			className="px-7"
		>
			{t(`actions.${isLast ? 'close' : 'next'}`)}
		</Button>
	);
};
