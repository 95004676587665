import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import type { IUiTableProps } from '@avast/react-ui-components';
import { UiTable } from '@avast/react-ui-components';
import { IApiSortBy } from 'types/api';
import { IUrlFilter } from 'types/filter';
import { useLocation, useNavigate } from 'react-router-dom';
import { object2UrlParams, orderObject, urlParams2object } from 'js/utils/common';
import { useCompare } from 'js/hooks/useCompare';
import { defaults, isEmpty, isEqual, isString } from 'lodash';
import { TUseApiPaginatedListModule } from 'js/queries/useApiPaginatedListQuery';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { useTranslation } from 'react-i18next';
import { IListMetaDataValues } from 'types/utils';
import type { TableMeta } from '@tanstack/table-core';
import { useTableSortingState } from 'js/components/molecules/DataTable/useTableSortingState';
import { SortDirectionEnum } from 'js/enums';

export type TApiPaginatedListTableProps<Data extends object, Filter extends object, ApiFilter> = Pick<
	IUiTableProps<Data>,
	'columns'
> & {
	query: TUseApiPaginatedListModule<Data, Filter, ApiFilter>;
	limit?: number;
	filterRequired?: boolean | string;
	filterIsEmpty?: (values: Filter) => boolean;
	filter: Filter | null;
	filterStatic?: Filter;
	sort?: IApiSortBy<Data>;
	useLocation?: boolean;
	table?: Partial<IUiTableProps<Data>>;
	className?: string;
	onMetaDataChange?: (values: IListMetaDataValues<Filter>) => void;
};

/**
 * Data table component with manual pagination, sorting, using api to fetch data
 * @param {TApiPaginatedListTableProps} props
 * @returns {ReactElement}
 * @private
 */
export const ApiPaginatedListTable = <Data extends {}, Filter extends {} = {}, ApiFilter = Filter>(
	props: TApiPaginatedListTableProps<Data, Filter, ApiFilter>,
): ReactElement => {
	const {
		query: useQuery,
		filterRequired,
		limit: initialLimit,
		columns,
		sort: initialSort,
		filterIsEmpty = isEmpty,
		filterStatic,
		onMetaDataChange,
	} = props;
	const isFilterLoading = props.filter === null;
	const filter: Filter = props.filter || ({} as Filter);

	// Setup states
	const [locationLoaded, setLocationLoaded] = useState(!props.useLocation);
	const filterUpdated = useCompare<Filter>(filter, true, isFilterLoading);
	const emptyRequiredFilter = Boolean(filterRequired) && filterIsEmpty(filter);
	const [t] = useTranslation();
	const computedFilter = defaults({}, filterStatic, filter);
	const queryDisabled = emptyRequiredFilter || isFilterLoading;
	const navigate = useNavigate();

	/**
	 * Api hook call
	 */
	const {
		setPagination,
		pagination,
		query: { isFetching, isError, refetch },
		data: response,
		sort: sortCalculated,
		setSort,
	} = useQuery({
		filter: computedFilter,
		sortBy: initialSort,
		queryConfig: {
			enabled: !queryDisabled && locationLoaded,
		},
		config: {
			limit: initialLimit,
		},
	});
	const { totalCount = 0, items = [] } = response || {};

	/**
	 * Handle sorting
	 */
	const onSortChange = useCallback(
		(sortBy?: IApiSortBy<Data>) => {
			if (locationLoaded && !isEqual(sortBy, sortCalculated)) {
				setPagination((pagination) => ({ ...pagination, pageIndex: 0 }));
			}
		},
		[setPagination, sortCalculated, locationLoaded],
	);
	const { sorting, setSorting } = useTableSortingState<Data>(setSort, sortCalculated, onSortChange);

	/**
	 * Hook what update URL address on filter changed
	 */
	const _filter = JSON.stringify(orderObject(filter));
	useEffect(() => {
		if (props.useLocation === true && locationLoaded && !isFilterLoading) {
			const params: IUrlFilter<Filter, Data> = {
				page: pagination.pageIndex,
				limit: pagination.pageSize,
				sortBy: sortCalculated?.key,
				sortDirection: sortCalculated?.direction,
				filter: JSON.parse(_filter),
			};
			navigate({ search: object2UrlParams(params) }, { replace: true });
		}
	}, [
		pagination.pageSize,
		_filter,
		props.useLocation,
		pagination.pageIndex,
		isFilterLoading,
		locationLoaded,
		sortCalculated,
		navigate,
	]);

	/**
	 * Reset table when filter is updated
	 */
	useEffect(() => {
		if (filterUpdated) {
			setPagination((pagination) => ({ ...pagination, pageIndex: 0 }));
		}
	}, [setPagination, filterUpdated]);

	/**
	 * Get params from location
	 */
	const location = useLocation();
	useEffect(() => {
		if (!locationLoaded && !isFilterLoading) {
			const locationArguments: IUrlFilter<Filter, Data> = urlParams2object(location.search);
			const { page, sortBy, sortDirection = SortDirectionEnum.DESC, limit } = locationArguments;
			setPagination((values) => ({
				pageIndex: page || values.pageIndex,
				pageSize: limit || values.pageSize,
			}));
			sortBy &&
				setSort({
					key: sortBy,
					direction: sortDirection,
				});
			setLocationLoaded(true);
		}
	}, [locationLoaded, location.search, setPagination, isFilterLoading, setSort]);

	// On data changes
	const _computedFilter = JSON.stringify(orderObject(computedFilter));
	useEffect(() => {
		onMetaDataChange?.({
			totalCount: queryDisabled ? 0 : totalCount,
			filter: JSON.parse(_computedFilter),
		});
	}, [totalCount, onMetaDataChange, _computedFilter, queryDisabled]);

	if (isFilterLoading || !locationLoaded) {
		return <LoadingPlaceholder type="BAR" />;
	}

	// Custom error
	let customError: TableMeta<Data>['customError'];
	if (emptyRequiredFilter) {
		customError = isString(filterRequired) ? filterRequired : t('common:table.useFilter');
	}

	return (
		<UiTable<Data>
			className={props.className}
			data={items}
			columns={columns}
			enableSorting
			manualSorting
			manualPagination={props.table?.enablePagination ?? props.table?.manualPagination ?? true}
			{...props.table}
			state={{
				...props.table?.state,
				pagination,
				sorting,
			}}
			onPaginationChange={setPagination}
			onSortingChange={setSorting}
			meta={{
				loading: emptyRequiredFilter ? isFilterLoading : isFetching,
				rowCount: totalCount,
				customError: customError || isError,
				isScrollable: true,
				scrollTopOnPageChange: true,
				nowrapCell: true,
				onRefetch: emptyRequiredFilter ? undefined : () => refetch(),
				paginationComponent: 'Full',
				...props.table?.meta,
			}}
		/>
	);
};

ApiPaginatedListTable.defaultProps = {
	filter: {},
};
