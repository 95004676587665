import { TUseApiDetailModule, useApiDetailQuery } from 'js/queries/useApiDetailQuery';
import {
	IChargeOrderApiFilter,
	IEntityChargeOrder,
	IEntityGcPaymentInstructions,
	IEntityOrderDocument,
	IEntityPaymentInstructions,
} from 'js/chargeOrder';
import { TUseApiListModule, useApiListQuery } from 'js/queries/useApiListQuery';
import { useCallback } from 'react';
import { chargeOrderEntityNormalizer } from 'js/chargeOrder/normalizer';
import type { AxiosResponse } from 'axios';

const API_CHARGE_ORDER_KEYS = {
	DETAIL: 'charge-order/:id',
	DOCUMENTS: 'charge-order/:chargeOrderId/document',
	PAYMENT_INSTRUCTIONS: 'charge-order/:id/payment-instructions',
	GC_PAYMENT_INSTRUCTIONS: 'charge-order/:id/gc-payment-instructions',
};

export const useApiChargeOrder: TUseApiDetailModule<IEntityChargeOrder> = (props) => {
	return useApiDetailQuery(API_CHARGE_ORDER_KEYS.DETAIL, props, {
		queryConfig: {
			select: useCallback(
				(response: AxiosResponse<IEntityChargeOrder>) => ({
					...response,
					data: chargeOrderEntityNormalizer.normalize(response.data),
				}),
				[],
			),
		},
	});
};

export const useApiChargeOrderPaymentInstructions: TUseApiDetailModule<IEntityPaymentInstructions> = (props) =>
	useApiDetailQuery(API_CHARGE_ORDER_KEYS.PAYMENT_INSTRUCTIONS, props);

export const useApiChargeOrderGcPaymentInstructions: TUseApiDetailModule<IEntityGcPaymentInstructions> = (props) =>
	useApiDetailQuery(API_CHARGE_ORDER_KEYS.GC_PAYMENT_INSTRUCTIONS, props);

export const useApiChargeOrderDocumentsList: TUseApiListModule<IEntityOrderDocument, IChargeOrderApiFilter> = (props) =>
	useApiListQuery(API_CHARGE_ORDER_KEYS.DOCUMENTS, props);
