import React, { ReactElement } from 'react';
import { Button, Modal } from '@avast/react-ui-components';
import { CheckCircleAnimated } from 'assets/image/icon';
import { AsyncModal, TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { STATUS_SUCCESS } from 'appConstants';

export type TAsyncSuccessModalProps = {
	title?: ReactElement;
	content?: ReactElement;
	submitButtonText?: string;
};

export const AsyncSuccessModal = (props: TAsyncModalContainerProps<TAsyncSuccessModalProps>): ReactElement => {
	const { forwardedRef } = props;

	return (
		<AsyncModal<TAsyncSuccessModalProps>
			ref={forwardedRef}
			backdrop="static"
			testId="success"
		>
			{({ title, submitButtonText, content }) => (
				<>
					<Modal.Header className="pb-0">{title}</Modal.Header>
					<Modal.Body className="py-md-8">
						<p className="text-center mb-4">
							<CheckCircleAnimated />
						</p>
						{content}
					</Modal.Body>
					<Modal.Footer
						cancelButton={false}
						className="text-center d-block"
					>
						<Button onClick={() => forwardedRef.current?.onSuccess(STATUS_SUCCESS)}>{submitButtonText}</Button>
					</Modal.Footer>
				</>
			)}
		</AsyncModal>
	);
};
