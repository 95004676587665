import React, { createContext, PropsWithChildren, ReactElement } from 'react';
import invariant from 'invariant';
import { TPageDetailChildrenProps } from 'js/layouts/page/PageDetailComponent';

type TPageDetailContext<Data extends object> = TPageDetailChildrenProps<Data>;
type TPageDetailContextProviderProps<Data extends object> = {
	value: TPageDetailContext<Data>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PageDetailContext = createContext<TPageDetailContext<any>>(undefined as any);
PageDetailContext.displayName = 'PageDetailContext';

/**
 * Typed provider of page detail context
 * @param {PropsWithChildren<TPageDetailContextProviderProps>} props
 * @returns {ReactElement}
 * @constructor
 */
export const PageDetailContextProvider = <Data extends object>(
	props: PropsWithChildren<TPageDetailContextProviderProps<Data>>,
): ReactElement => <PageDetailContext.Provider {...props} />;

/**
 * Hook to get page detail context
 * @returns {TPageDetailContext}
 */
export const usePageDetailContext = <Data extends object>(): TPageDetailContext<Data> => {
	const context = React.useContext<TPageDetailContext<Data>>(PageDetailContext);

	invariant(
		Boolean(context),
		'Page Detail context is undefined, please verify you are calling usePageDetailContext() as child of a <PageDetailContext> component.',
	);

	return context;
};
