import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle/DynamicPageTitle';
import { EnvLabel } from 'js/components/common/EnvLabel';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useLogo } from 'js/layouts/components/HeaderLogo';
import { Link } from 'react-router-dom';
import { RouteName } from 'module/RouteName';

type TSecurityFormLayoutProps = {
	title: string;
	columns?: 1 | 2;
};

export const SecurityFormLayout = (props: PropsWithChildren<TSecurityFormLayoutProps>) => {
	const { title, children, columns = 1 } = props;
	const [t] = useTranslation();
	const logo = useLogo(false);

	return (
		<div className="section__security-login-form">
			<DynamicPageTitleItem text={title} />
			<EnvLabel className="env-label" />

			<div
				className="content-container"
				data-columns={columns}
			>
				<div className="logo-container mb-3 mb-md-6">
					<Link
						to={RouteName.SECURITY.LOGIN}
						className="d-block"
					>
						<img
							src={logo}
							alt={t('app:appName')}
						/>
					</Link>
				</div>
				{children}
			</div>
		</div>
	);
};
