export default {
	requestId: 'Id. de solicitud',
	errorId: 'Id. de error',
	errorDetails: 'Detalles de error',
	api: {
		activeEntitlementNotFound: 'Esta operación de licencia no se puede completar porque ya se reemplazó el derecho.',
		invalidHash: 'Hash no válido.',
		distributionPartnerSalesforceConflict:
			'El contacto proporcionado es de otra organización. Contacte con el Administrador de cuentas para obtener más detalles.',
		passwordResetInvalidToken: 'El token de restablecimiento de contraseña no es válido.',
		passwordResetExpiredToken: 'El token de restablecimiento de contraseña caducó.',
		passwordAlreadyUsed: 'Esta contraseña ya se usó antes. Elija otra contraseña.',
		partnerNotRegistered:
			'Solamente las cuentas registradas pueden realizar pedidos. Pídale al socio que complete el registro en OMS primero.',
		nowTermsNotAccepted:
			'Las cuentas que hayan aceptado los términos y condiciones pueden realizar pedidos. Pídale al socio que acepte los términos y condiciones actualizados.',
		licenseOperationWrongSegment:
			'La parte seleccionada para la facturación no cumple los requisitos para comprar productos para empresas/clientes.',
		partnerQuoteCurrencyMismatch:
			'No se puede procesar el pedido porque la moneda de la cuenta es diferente a la moneda de la cotización. Contacte con el administrador de cuentas y solicite una nueva cotización.',
	},
	page: {
		404: {
			title: 'Mensaje del departamento de TI (página 404)',
			message: 'Se produjo un error. La URL solicitada no existe. Para continuar, haga clic en el siguiente botón.',
			back: 'Volver a la página principal',
		},
	},
	status: {
		networkError: 'No es posible establecer la conexión con el servidor.',
	},
	common: {
		contactSales: 'Contacte con el Administrador de cuentas para obtener ayuda.',
		repeatOrContactSales:
			'Vuelva a intentarlo más tarde o contacte con el Administrador de cuentas para obtener ayuda.',
	},
	detailNotFound: 'No se pudo cargar el detalle o no existe.',
	permissionDenied: 'No tiene permiso para acceder a esta acción o este recurso.',
	sectionDataNotFound: 'No se encontraron datos para esta sección.',
	beforeUnload: 'Si abandona la página ahora, puede perder datos.',
	unspecifiedError: 'No se pudo procesar la solicitud',
	errorDetail: 'Detalles técnicos (se deben proporcionar al informar un error)',
};
