import { TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import {
	IDeletePaymentOptionRequestParams,
	IEntityLegacyPaymentOption,
	ILegacyPaymentOptionRequestParams,
} from 'module/account';
import { TUseApiListModule, TUseApiListQueryOptions, useApiListQuery } from 'js/queries/useApiListQuery';
import { useCallback } from 'react';
import { legacyPaymentOptionNormalizer } from 'module/account/normalizer';
import type { AxiosResponse } from 'axios';
import { TApiListResponse } from 'types/api';
import { CreditCardLegacyBrandEnum } from 'module/account/enums';
import { ApiMutationTypeEnum } from 'js/enums';

export const API_PAYMENT_OPTION_KEYS = {
	LEGACY_LIST: 'affiliate/:affiliateId/payment-option/legacy',
	DELETE: 'affiliate/:affiliateId/payment-option/:id',
};

export const useApiLegacyPaymentOptionList: TUseApiListModule<
	IEntityLegacyPaymentOption,
	ILegacyPaymentOptionRequestParams
> = (props) => {
	const queryConfig: TUseApiListQueryOptions<IEntityLegacyPaymentOption, ILegacyPaymentOptionRequestParams> = {
		select: useCallback(
			(response: AxiosResponse<TApiListResponse<IEntityLegacyPaymentOption>>) => ({
				...response,
				data: legacyPaymentOptionNormalizer.normalizeList(
					response?.data as unknown as TApiListResponse<IEntityLegacyPaymentOption<CreditCardLegacyBrandEnum>>,
				),
			}),
			[],
		),
	};

	return useApiListQuery(API_PAYMENT_OPTION_KEYS.LEGACY_LIST, props, { queryConfig });
};

export const useApiDeletePaymentOption: TUseApiMutationModule<{}, {}, IDeletePaymentOptionRequestParams> = (props) =>
	useApiMutationQuery(API_PAYMENT_OPTION_KEYS.DELETE, ApiMutationTypeEnum.DELETE, props, {
		invalidateQueries: [API_PAYMENT_OPTION_KEYS.LEGACY_LIST],
	});
