import { IEntityOrder } from 'module/orders';
import { isGcOrder, isPaymentInstructionsEligible } from 'module/orders/utils/common';
import {
	useApiChargeOrderGcPaymentInstructions,
	useApiChargeOrderPaymentInstructions,
} from 'js/chargeOrder/useApiChargeOrder';
import React, { ReactElement } from 'react';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { PaymentInstructions } from 'module/orders/components/paymentInstructions/PaymentInstructions';
import { GcPaymentInstructions } from 'module/orders/components/paymentInstructions/GcPaymentInstructions';

interface IPaymentInstructions {
	content?: ReactElement;
	error?: string;
}

export const usePaymentInstructions = (order: IEntityOrder): IPaymentInstructions => {
	const isEligible = isPaymentInstructionsEligible(order);
	const isGc = isGcOrder(order);

	// NetSuite/Oracle
	const { data: standardPaymentInstructions, query: standardQuery } = useApiChargeOrderPaymentInstructions({
		id: order.chargeOrderId,
		queryConfig: { enabled: isEligible && !isGc },
	});

	// GC
	const { data: gcPaymentInstructions, query: gcQuery } = useApiChargeOrderGcPaymentInstructions({
		id: order.chargeOrderId,
		queryConfig: { enabled: isEligible && isGc },
	});

	// Err - Not Eligible
	if (!isEligible) {
		return { error: `The standard order does not support payment instructions, SO #${order.id}` };
	}

	// Err - invalid Charge Order ID
	if (!order.chargeOrderId) {
		return { error: `The standard order has none Charge Order ID, SO #${order.id}` };
	}

	// Resolve query
	const query = isGc ? gcQuery : standardQuery;
	if (query.isFetching) {
		return { content: <LoadingPlaceholder /> };
	}
	if (query.isError) {
		return { error: `There is an error response in usePaymentInstructions, SO #${order.id}` };
	}

	// NS/Oracle result
	if (!isGc) {
		if (standardPaymentInstructions) {
			return { content: <PaymentInstructions paymentInstructions={standardPaymentInstructions} /> };
		}
		return { error: `Payment Instructions doesnt exist for the SO #${order.id}` };
	}

	// GC result
	if (gcPaymentInstructions) {
		return {
			content: <GcPaymentInstructions paymentInstructions={gcPaymentInstructions} />,
		};
	}
	return { error: `Payment Instructions doesnt exist for the GC SO #${order.id}` };
};
