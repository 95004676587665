import { IPriceCalculatorForm } from 'module/priceCalculator';
import { IPricingRequest, IPricingRequestHeader, IPricingRequestItem } from 'module/purchase';
import { BillablePartyTypeEnum, StandardOrderDiscountCategoryEnum } from 'module/orders/enums';
import { CustomerSegmentEnum, PurchaseBillablePartyTypeEnum } from 'module/purchase/enums';
import { TCurrency } from 'types';
import { dateToPricingDate } from 'module/licenses/utils/common';
import { getPartnerRolesAndRoleGroups } from 'module/partners/utils/roles';
import { TransactionTypeEnum } from 'module/licenses/enums';

const getDefaultPricingHeader = (requiredValues: {
	countryCode: string;
	currencyCode: TCurrency;
	billablePartyType: BillablePartyTypeEnum;
}): IPricingRequestHeader => {
	const { countryCode, currencyCode, billablePartyType } = requiredValues;
	return {
		bill_to_country: countryCode,
		product_pricelist_currency_code: currencyCode,
		billablePartyType,
		applyPromoPricing: 'Yes',
		priceRequestSource: 'scUIOrder',
		discountCategory: StandardOrderDiscountCategoryEnum.NONE,
		priceListCode: 'PREPAID_OMS',
	};
};

export const calculatorFormNormalizer = {
	denormalize(form: IPriceCalculatorForm): IPricingRequest | null {
		const request_header = this.denormalizeHeader(form);
		const request_lineitems = this.denormalizeLineItems(form);

		if (request_header && request_lineitems.length !== 0) {
			return {
				request_header,
				request_lineitems,
			};
		}

		return null;
	},

	denormalizeHeader(form: IPriceCalculatorForm): IPricingRequestHeader | null {
		let header: IPricingRequestHeader | null = null;
		const { billablePartyType, partner, countryCode, currencyCode, discount, goalSeekedPrice } = form;

		// Partner
		if (billablePartyType === PurchaseBillablePartyTypeEnum.PARTNER) {
			// Existing partner
			if (partner) {
				const { isRoleDistributor } = getPartnerRolesAndRoleGroups(partner);
				header = getDefaultPricingHeader({
					countryCode: partner.countryCode!,
					currencyCode: partner.currencyCode!,
					billablePartyType: isRoleDistributor ? BillablePartyTypeEnum.DISTRIBUTOR : BillablePartyTypeEnum.PARTNER,
				});
				header.billablePartyID = partner.id;
			}

			// Virtual partner
			else if (countryCode && currencyCode) {
				header = getDefaultPricingHeader({
					countryCode,
					currencyCode,
					billablePartyType: BillablePartyTypeEnum.PARTNER,
				});
				header.virtualAffiliate = {
					currency: currencyCode,
					countryCode,
					tierCertification: discount.tierCertification,
				};

				if (discount.special) {
					header.virtualAffiliate.businessSpecialDiscount = Math.round(discount.special * 100);
				}
			}
		}

		// End customer
		else if (countryCode && currencyCode) {
			header = getDefaultPricingHeader({
				countryCode,
				currencyCode,
				billablePartyType: BillablePartyTypeEnum.END_CUSTOMER,
			});
			header.virtualAffiliate = {
				currency: currencyCode,
				countryCode,
			};
		}

		if (!header) {
			return null;
		}

		// Discretionary
		if (discount.discretionary) {
			header.discountCategory = StandardOrderDiscountCategoryEnum.DISCRETIONARY;
			header.discountOrderPercent = discount.discretionary;
		}

		// Goal Seeked Price
		header.goalSeekedPrice = goalSeekedPrice;
		return header;
	},

	denormalizeLineItems(form: IPriceCalculatorForm): IPricingRequestItem[] {
		const { productGroup, discount, units, isRenewal } = form;
		const items: IPricingRequestItem[] = [];

		productGroup?.products.forEach((product) => {
			const item: IPricingRequestItem = {
				reference_lineitem_id: product.sku,
				product: product.sku,
				customerSegment: CustomerSegmentEnum.COMMERCIAL,
				lineTransactionType: TransactionTypeEnum.NEW,
				unitsToPrice: units,
				unitsForTier: units,
				customPricingInfo: {
					quantity: units,
				},
			};

			if (isRenewal) {
				item.lineTransactionType = TransactionTypeEnum.RENEW;
				item.unitsToIncrease = 0;
				item.unitsQuantityTotal = units;
				item.renewTo = product.sku;
				item.renewalDate = dateToPricingDate(new Date());
			}

			// Segment discount
			if (discount.segment) {
				item.customerSegment = discount.segment;
			}

			items.push(item);
		});

		return items;
	},
};
