import { generateEnumUtilsWithDescription } from 'js/enums/generator/enumFunctions';

export enum OrderStatusEnum {
	SAVED_IN_PROGRESS = 'Saved In Progress',
	ISSUED_AS_CUSTOMER_QUOTE = 'Issued as Customer Quote',
	ISSUED_AS_QUOTE = 'Issued as Quote',
	POSTED_TO_STATEMENT = 'Posted to Statement',
	PENDING_SUBMISSION = 'Pending Submission',
	IN_REVIEW = 'In Review',
	SUBMITTED_TO_COMMERCE = 'Submitted to Commerce',
	PAYMENT_ERROR = 'Payment Error',
	OTHER_ERROR = 'Other Error',
	WAITING_PAYMENT = 'Waiting Payment',
	COMPLETE = 'Complete',
	CANCELED_BY_USER = 'Canceled by User',
	CANCELED_FOR_FAILURE_TO_PAY = 'Canceled for Failure to Pay',
	EXPIRED = 'Expired',
}

interface IOrderStatusEnumExtend {
	getQuoteStatuses(): OrderStatusEnum[];
}

export const orderStatusEnumUtils = generateEnumUtilsWithDescription<OrderStatusEnum, IOrderStatusEnumExtend>(
	OrderStatusEnum,
	{
		translatePath: 'enums:order.status',
		getQuoteStatuses() {
			return [OrderStatusEnum.ISSUED_AS_QUOTE, OrderStatusEnum.ISSUED_AS_CUSTOMER_QUOTE];
		},
	},
);
