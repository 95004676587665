export default {
	error: {
		creditStatusNotFound: 'クレジット ステータスが見つかりません。',
	},
	tabs: {
		detail: 'クレジットの概要',
		transactions: 'トランザクションのリスト',
	},
	entity: {
		companyName: '会社',
		countryCode: '国',
		creditAvailable: 'クレジットが利用可能',
		creditLimit: 'クレジット限度',
		percentConsumed: '% を消費',
		unbilledBalance: '未請求の残高',
		unpaidBalance: '未払いの残高',
	},
	entityShort: {
		creditAvailable: '利用可能',
		creditLimit: '限度',
		percentConsumed: '% を消費',
		unbilledBalance: '未請求',
		unpaidBalance: '未払い',
	},
	entityTransaction: {
		amount: '金額',
		createdAt: '作成日時',
		orderId: '参照 ID',
		source: 'ソース',
	},
	widget: {
		name: '種類',
		value: 'クレジット',
	},
};
