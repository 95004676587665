import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useClearLocationStateOnMount = () => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		// Clear state on init -> not persist after reload
		navigate({ ...location }, { replace: true, state: {} });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
