import type { TUiTableRow } from '@avast/react-ui-components';
import React from 'react';
import { EllipsisText } from 'js/components/atoms/EllipsisText';
import { TEllipsisTextProps } from 'js/components/atoms/EllipsisText/EllipsisText';
import type { TColumnFormatter } from '@tanstack/react-table';

export type TEllipsisOptions = Omit<Partial<TEllipsisTextProps>, 'value'>;

export const ellipsis =
	<Row extends TUiTableRow>(options?: TEllipsisOptions): TColumnFormatter<Row> =>
	// eslint-disable-next-line react/display-name
	(value, cell) => (
		<EllipsisText
			value={cell.getValue()}
			{...options}
		/>
	);
