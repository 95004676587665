export default {
	entity: {
		createdBy: 'Creado por',
	},
	actions: {
		create: 'Crear límite crediticio',
	},
	error: {
		createCreditLimit: 'No es posible crear el límite crediticio.',
	},
	modal: {
		create: {
			title: 'Crear límite crediticio',
			loading: 'Creación de límite crediticio',
			success: 'Límite crediticio creado correctamente',
		},
	},
};
