import { AsyncModal, TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import React from 'react';
import { HelpContextProvider, THelpContextProviderProps } from 'js/help/HelpContext';
import { HelpContentSwitch } from 'js/help/HelpContentSwitch';

export type TAsyncHelpModalProps = THelpContextProviderProps;

export const AsyncHelpModal = (props: TAsyncModalContainerProps<TAsyncHelpModalProps>) => {
	const { forwardedRef } = props;

	return (
		<AsyncModal<TAsyncHelpModalProps>
			ref={forwardedRef}
			className="section__help"
			size="lg"
			testId="help"
		>
			{(props) => (
				<HelpContextProvider {...props}>
					<HelpContentSwitch />
				</HelpContextProvider>
			)}
		</AsyncModal>
	);
};
