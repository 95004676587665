import React, { ReactElement } from 'react';
import { IEntityCustomerBilling } from 'module/customers';
import { customersConfig } from 'module/customers/customersConfig';
import { useTranslation } from 'react-i18next';
import { FormikForm } from 'js/components/formik/FormikForm';
import { Formik } from 'formik';
import { FormikControl } from 'js/components/formik/FormikControl';
import { Modal } from '@avast/react-ui-components';
import * as Yup from 'yup';
import { useCountrySupportedCurrenciesOptions } from 'js/entities/country/useCountrySupportedCurrenciesOptions';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';

type TSelectCustomerCurrencyForm = {
	currencyCode?: string;
};

export type TSelectCustomerCurrencyModalProps = {
	countryCode: IEntityCustomerBilling['countryCode'];
	onSelect: (currencyCode: string) => void;
	onCancel: () => void;
	show: boolean;
	value?: TSelectCustomerCurrencyForm['currencyCode'];
};

export const SelectCustomerCurrencyModal = (props: TSelectCustomerCurrencyModalProps): ReactElement | null => {
	const { onSelect, onCancel, countryCode, show, value } = props;
	const [t] = useTranslation(customersConfig.trNamespace);

	// Currencies
	const {
		options,
		query: { isInitialLoading },
	} = useCountrySupportedCurrenciesOptions(countryCode);

	if (!countryCode) {
		return null;
	}

	return (
		<Modal
			backdrop="static"
			onHide={onCancel}
			show={show}
			size="sm"
			testId="selectCustomerCurrency"
		>
			<Modal.Header>{t('common.selectCurrencyModalTitle')}</Modal.Header>
			<Formik<TSelectCustomerCurrencyForm>
				initialValues={{ currencyCode: value }}
				enableReinitialize
				validationSchema={Yup.object().shape<TSelectCustomerCurrencyForm>({
					currencyCode: Yup.string().required().ensure().label(t('common:entity.currency')),
				})}
				onSubmit={(values, { setSubmitting }) => {
					if (values.currencyCode) {
						onSelect(values.currencyCode);
					}
					setSubmitting(false);
				}}
			>
				{({ values, submitForm }) => (
					<>
						<Modal.Body>
							{isInitialLoading ? (
								<LoadingPlaceholder type="BAR" />
							) : (
								<FormikForm testId="customerCurrencyForm">
									<FormikControl label={t('common:entity.currency')}>
										<FormikControl.SingleSelect
											name="currencyCode"
											value={values.currencyCode}
											options={options}
											required
											isClearable={false}
										/>
									</FormikControl>
								</FormikForm>
							)}
						</Modal.Body>
						<Modal.Footer
							cancelButton
							onSubmit={() => submitForm()}
							submitButton={{ children: t('common:actions.confirm'), disabled: isInitialLoading }}
						/>
					</>
				)}
			</Formik>
		</Modal>
	);
};
