import { TLocale, TLocaleApi } from 'types/config';
import { localesConfig } from 'i18n/locales';

export const localeCodeNormalizer = {
	lengthOfCode: 5, // cs_CZ, en_US, cs-CZ, en-US ...

	normalize(localeCode: TLocaleApi | undefined | null, validate: boolean = false): TLocale | null {
		if (localeCode && localeCode.length === 5) {
			const _localeCode = localeCode.replace('_', '-') as TLocale;
			if (validate) {
				const config = localesConfig[_localeCode];
				if (!config) {
					return null;
				}
			}
			return _localeCode;
		}
		return null;
	},

	denormalize(localeCode: TLocale | undefined | null): TLocaleApi | null {
		if (localeCode && localeCode.length === 5) {
			return localeCode.replace('-', '_') as TLocale;
		}
		return null;
	},
};
