export default {
	table: {
		noData: 'No hay datos disponibles aún',
		error: 'Se produjo un error durante la obtención de datos',
		noDataFilter: 'No hay datos para el filtro',
		retry: 'Reintentar solicitud',
		perPage: 'por página',
		pagination: {
			total: 'Mostrando entre <strong>{{from}} y <0>{{to}}</0></strong> de <0>{{total}}</0> registros.',
		},
	},
	datepicker: {
		goToToday: 'Ir a este mes',
		rangeDivider: 'a',
		startDate: 'Fecha de inicio',
		endDate: 'Fecha de finalización',
		rangeSelector: {
			today: 'Hoy',
			thisWeek: 'Esta semana',
			thisMonth: 'Este mes',
			lastWeek: 'Última semana',
			lastMonth: 'Último mes',
			thisYear: 'Este año',
			lastYear: 'Último año',
			customRange: 'Rango personalizado',
		},
		relative: {
			single: {
				today: 'Hoy',
				tomorrow: 'Mañana',
				yesterday: 'Ayer',
			},
			range: {
				thisWeek: 'Esta semana',
				lastWeek: 'Última semana',
				thisMonth: 'Este mes',
				lastMonth: 'Último mes',
				thisYear: 'Este año',
				lastYear: 'Último año',
			},
		},
	},
	searchComboBox: {
		value: {
			placeholder: 'Introduzca un valor...',
		},
	},
	notes: {
		noContent: 'No hay notas disponibles',
	},
	hgop: {
		shoppingCart: {
			title: 'Carrito de compras',
		},
	},
	chargeOrderDocuments: {
		entity: {
			documentType: 'Tipo de documento',
		},
		type: {
			payment_instructions: 'Instrucciones de pago',
			invoice: 'Factura',
			vat_invoice: 'Factura',
			credit_memo: 'Nota de crédito',
			payg_price_breakdown_msp: 'Facturación flexible: informe de socio',
			payg_product_usage_report: 'Facturación flexible: informe de cliente',
			payg_reports_all: 'Facturación flexible: informes de uso de producto (todo)',
		},
	},
	export: {
		caption: 'Exportación en curso. Este proceso puede tardar unos minutos.',
		autoDownload: 'El archivo se descargará automáticamente.',
		downloadError: 'No es posible descargar el archivo.',
		error: 'Error de exportación. Vuelva a intentarlo más tarde.',
	},
	common: {
		more: 'Ver más',
		apply: 'Aplicar',
	},
	select: {
		placeholder: 'Seleccionar opción',
	},
	pagination: {
		prev: 'Anterior',
		next: 'Siguiente',
		page: 'Página',
		of: 'de',
		miniText: 'Página {{active}} de {{total}}',
	},
	modal: {
		cancel: 'Cancelar',
		submit: 'Enviar',
		apply: 'Aplicar',
	},
	rangeDatepicker: {
		placeholder: 'Seleccionar fecha',
	},
	singleDatepicker: {
		placeholder: 'Seleccionar fecha',
	},
	toast: {
		title: {
			success: '¡Correcto!',
			error: 'Error',
			info: 'Información',
			default: 'Información',
			dark: 'Información',
			warning: 'Advertencia',
		},
	},
	fileUpload: {
		placeholder: '<strong>Seleccione</strong> o arrastre un archivo aquí mismo',
	},
};
