import { ColumnsControl, TypeSelectControl, WidgetSettingsControls } from './controls';
import { FormikControl } from 'js/components/formik/FormikControl';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import { FormikForm } from 'js/components/formik/FormikForm';
import React from 'react';
import { useWidgetContext } from 'submodule/widgets/context';

type TWidgetFormProps = {
	isUpdate?: boolean;
};

export const WidgetForm = ({ isUpdate }: TWidgetFormProps) => {
	const { t } = useWidgetContext();

	return (
		<FormikForm>
			<TypeSelectControl />
			<FormikControl label={t('widgets.config.ownName')}>
				<FormikControl.Input
					size="sm"
					placeholder={t('widgets.config.ownNamePlaceholder')}
					name="label"
				/>
			</FormikControl>
			<WidgetSettingsControls />
			<ColumnsControl />
			<FormikFooterControls
				hr={false}
				submit={{
					children: t(isUpdate ? 'widgets.action.editWidget' : 'widgets.action.addWidget'),
				}}
			/>
		</FormikForm>
	);
};
