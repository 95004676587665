export default {
	actions: {
		addProductsToOrder: '新增產品',
		cancel: '取消訂單',
		cancelQuote: '取消報價',
		createQuote: '建立新報價',
		cancelLicense: '取消授權',
		cancelSelectedLicenses: '取消選取的授權',
		cancelSelectedLicenses_zero: '取消選取的授權',
		cancelSelectedLicenses_other: '取消選取的授權 ({{count}})',
		detail: '檢視訂單詳細資料',
		detailQuote: '檢視報價詳細資料',
		documents: '檢視訂單文件',
		edit: '編輯訂單',
		editQuote: '編輯報價',
		export: {
			default: '匯出訂單',
			detailed: '詳細出口資訊',
			quotes: '匯出報價',
		},
		exportQuotes: '匯出報價',
		licenses: '檢視授權',
		placeQuote: '將報價作為訂單',
		priceDetails: '檢視價格詳細資料',
		thisMonth: '本月',
		viewClosingBalance: '檢視期末結餘訂單',
		pay: '付款',
		payEdit: '付款/編輯',
		payEditQuote: '付款/編輯報價',
		payQuote: '付款報價',
	},
	common: {
		flexibleBilling: '彈性計費',
		documents: '訂單文件',
		orderNumber: '訂單：#{{id}}',
		quoteNumber: '報價：#{{id}}',
		priceDetails: '價格詳細資料',
		licenses: '授權',
		itemLicenses: '項目授權',
		paymentInstructions: '付款指示',
		pricingMessage: '完整定價訊息',
		promoMessage: '完整促銷活動訊息',
		refunds: '訂單退款',
		initiateRefund: '啟動退款',
	},
	entity: {
		approvalStatus: '核准狀態',
		billablePartyName: '計費方名稱',
		billablePartyType: '計費方類型',
		created: '建立日期',
		createdBy: '建立者',
		creationSource: '建立來源',
		purchaseType: '購買類型',
		purchaseTypePlaceholder: '任何購買類型',
		creationSourcePlaceholder: '任何來源',
		date: '日期',
		discountApplied: '適用折扣',
		discountInformation: '折扣資訊',
		gcOrderNumber: '商業訂單編號',
		paymentStatusPlaceholder: '任何狀態',
		noDiscount: '無折扣',
		opportunityId: '機會編號',
		orderAfterDiscount: '折扣後訂單',
		orderNumber: '訂單編號',
		orderNumberShort: '訂單編號',
		orderStatusPlaceholder: '任何狀態',
		originateAsQuote: '源自報價',
		quoteNumber: '報價編號',
		quoteNumberShort: '報價編號',
		quoteStatus: '報價狀態',
		quoteType: '報價類型',
		statementId: ' 結單 ID',
		totalAmount: '值',
	},
	error: {
		orderNotFound: '找不到訂單 #{{id}}',
		orderNotEditable: '訂單 #{{id}} 不可編輯',
		noProductsInOrder: {
			caption: '訂單中沒有產品',
			text: '新增產品或取消訂單。在您提交/儲存訂單之前，變更不會生效。',
		},
		orderChangesAreNotPersist: '請完成或儲存訂單，以儲存變更。',
		orderCancellation: '訂單無法取消。',
		orderSubmissionCancellation: '訂單提交無法取消。',
	},
	discountType: {
		line: '單項折扣',
		other: '{{type}} {{category}}',
	},
	tab: {
		customer: '客戶',
		discretionaryDiscount: '酌情折扣',
		itemsInOrder: '訂單中的項目',
		itemsInQuote: '報價中的項目',
		licenses: '授權',
		notes: '附註',
		orderStatus: '訂單狀態',
		partner: 'Avast 合作夥伴',
		quoteStatus: '報價狀態',
	},
	licenses: {
		inProcess: '我們正在為您的訂單索取授權，請稍候',
		error: {
			title: '我們目前無法擷取您訂單的授權。請稍後再回來查看。',
			detail:
				'我們將自動重試擷取您的授權，這可能需要幾分鐘的時間。產生授權後，您可以在這裡看到，同時也會透過電子郵件收到。',
		},
	},
	paymentInstructions: {
		title: '付款指示',
		description: '向您的銀行提供以下匯款指示',
		amount: '金額',
		referenceNumber: {
			label: '付款參考編號',
			note: '請務必包含此編號，否則可能無法收到您的付款。如果您的銀行不允許您使用此編號的完整長度，請將其複製並貼上到「收款人訊息」欄位中。',
		},
		accountHolder: '帳戶持有人',
		bankName: '銀行名稱',
		accountNumber: '帳號',
		iban: {
			label: 'IBAN',
			note: 'IBAN（即國際銀行帳號）是用於識別銀行帳戶的全球標準。它用於促進付款路線並防止路線錯誤。',
		},
		bic: 'BIC',
		aba: 'ABA/路線號碼',
		ach: 'ACH',
		error: {
			notAvailable: '付款指示不可用。',
		},
	},
	cancelOrder: {
		title: '訂單取消',
		message: '訂單一經取消，就無法撤銷或復原。您仍然可以看到該訂單，但無法對其採取任何操作。',
		success: '訂單已成功取消。',
		error: '取消訂單時發生錯誤。',
		loading: '正在取消訂單 #{{orderNumber}}',
		wtoc: {
			title: '正在取消 WTOC 訂單',
		},
	},
	discountApproval: {
		approved: '酌情折扣已獲核准，您可以完成訂單。',
		rejected: '酌情折扣已被拒絕。您可以編輯訂單並再次要求核准。',
		alert: {
			caption: '酌情折扣正在等待您的核准',
			text: '請檢查折扣，然後決定核准或拒絕酌情折扣。',
			reject: '拒絕',
			approve: '核准',
		},
		alertApprove: {
			caption: '已拒絕酌情折扣',
			text: '仍有可能核准此折扣。',
		},
		confirm: {
			approve: {
				title: '核准折扣',
				message: '請確認您核准此折扣。',
				button: '確認 - 核准此折扣',
			},
			reject: {
				title: '拒絕折扣',
				message: '請確認您拒絕此折扣。',
				button: '確認 - 拒絕此折扣',
			},
		},
		status: {
			success: {
				approve: '已成功核准此折扣。',
				reject: '已成功拒絕此折扣。',
			},
			error: '折扣狀態變更失敗。',
		},
	},
	cancelLicenses: {
		loading: '正在取消授權',
		loading_other: '正在取消授權',
		success: '已成功取消授權',
		success_other: '已成功取消授權',
		confirm: {
			title: '取消授權',
			title_other: '取消授權',
			message: '您確定要取消授權嗎？',
			message_other: '您確定要取消授權嗎？',
			message_note: '請注意，如果取消所有剩餘的授權，將會取消整張訂單。無法撤銷或復原取消程序。',
		},
	},
};
