export default {
	comparativeFeatures: {
		title: 'Nouvelles solutions Avast Business',
		availableAsAddon: 'Disponible comme modules complémentaire',
		equivalentLabel: 'Une meilleure valeur ajoutée. Pour le même prix.',
		action: 'Voir les forfaits',
		message:
			'Nous proposons de nouveaux forfaits de sécurité qui offrent plus de valeur ajoutée à vos clients pour le même prix. Aidez vos clients à choisir le niveau de protection adapté à leur entreprise.',
		price: {
			from: 'Du',
			units: '{{unitType}}',
			units_other: '{{count}} {{unitType}}',
		},
		startShopping: 'Démarrer des achats',
		windowsOnly: 'Non disponible pour MacOS',
	},
	refunds: {
		entity: {
			date: 'Date de remboursement',
			initiatedDate: 'Date d’initiation du remboursement',
			itemId: 'ID de ligne',
			orderLineItemId: 'Numéro de ligne',
			type: 'Type de remboursement',
			reason: 'Motif du remboursement',
			reasonNote: 'Commentaire',
			refundableAmount: 'Disponible pour le remboursement',
			refundAmount: 'Montant du remboursement',
			totalRefunded: 'Total remboursé',
		},
		initiate: {
			title: 'Initier le remboursement de la commande n° {{id}}',
			submit: 'Envoyer la demande',
			process: {
				loading: 'Envoi du remboursement...',
				success: 'Votre demande de remboursement a été envoyée.',
				error: 'Impossible d’envoyer la demande de remboursement.',
			},
			message: {
				ORDER_REFUND:
					'Vous pouvez soit rembourser la totalité du montant de la commande, soit demander un remboursement partiel de la commande en saisissant le montant souhaité (à hauteur du montant maximum disponible).',
				LINE_REFUND:
					'Sélectionnez les produits à rembourser. Vous pouvez soit rembourser la totalité du montant de l’article, soit demander un remboursement partiel de l’article en saisissant le montant souhaité (à hauteur du montant maximum disponible).',
				TAX_REFUND:
					'Lorsque vous sélectionnez cette option, la totalité de la commande sera remboursée et une nouvelle facture hors taxe sera générée automatiquement.',
				KEY_REFUND:
					'Sélectionnez la clé à rembourser. Vous pouvez soit rembourser le montant intégral, soit demander un remboursement partiel en saisissant le montant souhaité (à hauteur du montant maximum disponible).',
			},
		},
		view: {
			title: 'Remboursement liés à la commande n° {{id}}',
		},
		error: {
			noRefundTypeEnabled: 'Aucun type de remboursement n’est autorisé pour cette commande.',
			refundableAmount: 'Impossible de charger les informations relatives aux remboursements liés à la commande.',
			refundedAmount: 'Impossible de charger le montant total remboursé.',
		},
		license: {
			noSelectedLicenses:
				'Aucune licence n’est sélectionnée. Utilisez le champ ci-dessus pour rechercher des licences.',
			search: {
				title: 'Saisissez une clé pour rechercher une licence.',
				searchFailure: 'La clé {{licenseKey}} ne correspond à aucune licence pour cette commande.',
				refundableAmountFailure: 'Impossible de charger un montant remboursable pour la clé {{licenseKey}}.',
				success: 'La clé {{licenseKey}} a été ajoutée au formulaire de remboursement.',
				loadingLicenses: 'Chargement des licences...',
				loadingRefundableAmount: 'Chargement du montant remboursable...',
			},
			select: {
				title: 'Sélectionnez une licence à rembourser.',
			},
		},
	},
	appMessages: {
		taxExemptionCertificate: {
			title:
				'Les certificats téléchargés via le système de gestion des commandes ne sont applicables qu’aux commandes réglées par carte bancaire pour lesquelles le fournisseur est Digital River Inc.',
			contentTop:
				'À compter du <strong>1er février 2023</strong>, le vendeur prenant en charge les achats à crédit (y compris le mode de paiement Solde de clôture, le cas échéant) passera de Digital River Inc. à Gen Digital Inc. Afin de garantir que les achats à crédit continuent d’être exonérés d’impôt, veuillez <strong>envoyer un certificat d’exonération fiscale émis pour Gen Digital Inc.</strong> à notre service fiscal, à l’adresse suivante : <0>{{email}}</0>.',
			contentBottom: 'Nous vous remercions de votre coopération !',
		},
		flexibleBillingAvailable: {
			title: 'Facturation flexible maintenant disponible dans le Business Hub',
			content:
				'Les fournisseurs de services gérés (MSP) qui utilisent la version partenaire multi-entreprises du Business Hub disposent désormais d’une option de paiement à l’utilisation.<br/>Cela aide à optimiser la flexibilité, l’évolutivité et la rentabilité des MSP, en leur permettant d’utiliser les solutions Avast Business de la manière qui correspond le mieux à leurs transactions avec les clients finaux.',
			action: 'En savoir plus',
		},
	},
	widgets: {
		action: {
			addWidget: 'Ajouter un widget',
			editWidget: 'Modifier le widget',
			editWidgets: 'Modifier les widgets',
		},
		common: {
			columnPlaceholder: 'Ajouter un nouveau widget ou déplacer un widget...',
			savingLoader: 'Enregistrement de la modification des widgets...',
			rowTitle: 'Ligne ({{count}} colonne)',
			rowTitle_other: 'Ligne ({{count}} colonnes)',
			changesSaved: 'La modification des widgets a été enregistrée.',
			oneColumnRow: 'Ligne à une colonne',
			twoColumnsRow: 'Ligne à deux colonnes',
		},
		config: {
			type: 'Type',
			ownName: 'Propre nom',
			ownNamePlaceholder: 'Nom du widget',
			columns: 'Colonnes visibles',
			columnsPlaceholder: 'Sélectionnez au moins une colonne.',
		},
		setting: {
			days: 'Jours',
			limit: 'Nombre maximal de lignes',
		},
	},
	locale: {
		language: {
			saving: 'Modification de la langue du système',
			success: 'La langue du système a été modifiée.',
			confirmation: {
				title: 'Modifier la langue du système',
				message:
					'La modification des paramètres de langue de votre compte aura également une incidence sur la langue utilisée pour vos factures et dans les e-mails d’Avast. Voulez-vous vraiment effectuer cette modification ?',
			},
		},
		format: {
			saving: 'Modification du paramètre de formatage des dates et des nombres',
			success: 'Le formatage des dates et des nombres a été modifié.',
		},
	},
};
