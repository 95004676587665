import { PropsWithChildren, ReactElement } from 'react';
import type { ITestId } from '@avast/react-ui-components';
import { RequireExactlyOne } from 'type-fest';

export type TStepperItemProps<T extends string> = RequireExactlyOne<
	{
		to: string;
		value: T;
		isEnabled?: boolean;
	},
	'to' | 'value'
> &
	PropsWithChildren<ITestId>;

/**
 * Stepper navigation item, is proceeded by Stepper component, is not rendered, aka BreadcrumbsItem
 * @param {TStepperItemProps} props
 * @return {React.ReactElement}
 */
export const StepperItem = <T extends string = string>(props: TStepperItemProps<T>): ReactElement => {
	throw new Error(
		'Avast OMS: The `StepperItem` component is not meant to be rendered! ' +
			"It's an abstract component that is only valid as a direct Child of the `Stepper` Component. ",
	);
};
