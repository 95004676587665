import React from 'react';
import type { TVariant } from '@avast/react-ui-components';
import { Alert, Button, IconButton, Tooltip } from '@avast/react-ui-components';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { isDefined } from 'js/utils/common';

type TEndCustomerCurrencyProps = {
	currencyCode?: string;
	onChange: () => void;
};

export const EndCustomerCurrency = (props: TEndCustomerCurrencyProps) => {
	const { currencyCode, onChange } = props;
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const hasCurrencyCode = isDefined(currencyCode);
	const colorVariant: TVariant = hasCurrencyCode ? 'info' : 'danger';

	return (
		<Alert
			variant={colorVariant}
			icon={!hasCurrencyCode}
			className="mt-3 ps-3 pe-2 py-2 align-items-center"
		>
			<div className="d-flex justify-content-between align-items-center">
				<div>
					{hasCurrencyCode ? (
						<p className="text-primary">
							<strong>{t('common:entity.currency')}:</strong> {currencyCode}
						</p>
					) : (
						<strong className="text-danger fs-base">{t('common.currency.selectPrompt')}</strong>
					)}
				</div>
				<Tooltip
					content={t('common.currency.changeTooltip')}
					placement="bottom"
				>
					<div className="flex-shrink-0 ms-2">
						{hasCurrencyCode ? (
							<IconButton
								variant="info"
								size="xs"
								onClick={onChange}
								iconFa={faPencilAlt}
								testId="changeCustomerCurrency"
							/>
						) : (
							<Button
								variant="danger"
								size="xs"
								onClick={onChange}
								testId="selectCustomerCurrency"
							>
								{t('common:actions.select')}
							</Button>
						)}
					</div>
				</Tooltip>
			</div>
		</Alert>
	);
};
