export default {
	entity: {
		createdBy: '作成者',
	},
	actions: {
		create: 'クレジット限度を作成',
	},
	error: {
		createCreditLimit: 'クレジット限度を作成できません。',
	},
	modal: {
		create: {
			title: 'クレジット限度を作成',
			loading: 'クレジット限度を作成中',
			success: 'クレジット限度が正常に作成されました',
		},
	},
};
