import { TApiErrorResolve } from 'types/api';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import i18n from 'i18next';
import { apiErrorResolveError } from 'js/utils/apiError';
import { API_ERROR_DISTRIBUTION_PARTNER } from 'module/distributionPartners/constants';

export const createDistributionPartnerApiError: TApiErrorResolve = (error) => {
	const messages = apiErrorResolveError(error, API_ERROR_DISTRIBUTION_PARTNER);
	return {
		title: i18n.t(distributionPartnersConfig.trPrefix('error.createDistributionPartner')),
		messages,
	};
};

export const updateDistributionPartnerApiError: TApiErrorResolve = (error) => {
	const messages = apiErrorResolveError(error, API_ERROR_DISTRIBUTION_PARTNER);
	return {
		title: i18n.t(distributionPartnersConfig.trPrefix('error.updateDistributionPartner')),
		messages,
	};
};
