export default {
	actions: {
		addProductsToOrder: 'Add products',
		cancel: 'Cancel Order',
		cancelQuote: 'Cancel Quote',
		createQuote: 'Create New Quote',
		cancelLicense: 'Cancel License',
		cancelSelectedLicenses: 'Cancel Selected License',
		cancelSelectedLicenses_zero: 'Cancel Selected Licenses',
		cancelSelectedLicenses_other: 'Cancel Selected Licenses ({{count}})',
		detail: 'View Order Details',
		detailQuote: 'View Quote Details',
		documents: 'View Order Documents',
		edit: 'Edit Order',
		editQuote: 'Edit Quote',
		export: {
			default: 'Export Orders',
			detailed: 'Detailed Export',
			quotes: 'Export Quotes',
		},
		exportQuotes: 'Export Quotes',
		licenses: 'View Licenses',
		placeQuote: 'Place Quote as Order',
		priceDetails: 'View Price Details',
		thisMonth: 'This Month',
		viewClosingBalance: 'View Closing Balance Order',
		pay: 'Pay',
		payEdit: 'Pay / Edit',
		payEditQuote: 'Pay / Edit Quote',
		payQuote: 'Pay Quote',
	},
	common: {
		flexibleBilling: 'Flexible Billing',
		documents: 'Order Documents',
		orderNumber: 'Order: #{{id}}',
		quoteNumber: 'Quote: #{{id}}',
		priceDetails: 'Price Details',
		licenses: 'Licenses',
		itemLicenses: 'Item Licenses',
		paymentInstructions: 'Payment Instructions',
		pricingMessage: 'Full Pricing Message',
		promoMessage: 'Full Promotion Message',
		refunds: 'Order Refunds',
		initiateRefund: 'Initiate Refund',
	},
	entity: {
		approvalStatus: 'Approval Status',
		billablePartyName: 'Billable Party Name',
		billablePartyType: 'Billable Party Type',
		created: 'Created Date',
		createdBy: 'Created By',
		creationSource: 'Creation Source',
		purchaseType: 'Type of purchase',
		purchaseTypePlaceholder: 'Any purchase type',
		creationSourcePlaceholder: 'Any source',
		date: 'Date',
		discountApplied: 'Discount Applied',
		discountInformation: 'Discount Information',
		gcOrderNumber: 'Commerce Order No.',
		paymentStatusPlaceholder: 'Any status',
		noDiscount: 'No Discount',
		opportunityId: 'Opportunity Number',
		orderAfterDiscount: 'Order After Discount',
		orderNumber: 'Order Number',
		orderNumberShort: 'Order NO.',
		orderStatusPlaceholder: 'Any status',
		originateAsQuote: 'Originate as Quote',

		quoteNumber: 'Quote Number',
		quoteNumberShort: 'Quote NO.',
		quoteStatus: 'Quote Status',
		quoteType: 'Quote Type',

		statementId: ' Statement ID',
		totalAmount: 'Value',
	},
	error: {
		orderNotFound: 'Order #{{id}} not found',
		orderNotEditable: 'Order #{{id}} is not editable',
		noProductsInOrder: {
			caption: 'No products in the order',
			text: 'Add products or cancel the order. Changes are not applicable until you submit/save the order.',
		},
		orderChangesAreNotPersist: 'Complete or save the order to save the changes.',
		orderCancellation: 'Order could not be canceled.',
		orderSubmissionCancellation: 'Order submission could not be canceled.',
	},
	discountType: {
		line: 'Line Discount',
		other: '{{type}} {{category}}',
	},
	tab: {
		customer: 'Customer',
		discretionaryDiscount: 'Discret. Discount',
		itemsInOrder: 'Items In Order',
		itemsInQuote: 'Items In Quote',
		licenses: 'Licenses',
		notes: 'Notes',
		orderStatus: 'Order Status',
		partner: 'Avast Partner',
		quoteStatus: 'Quote Status',
	},
	licenses: {
		inProcess: 'Please wait while we request licenses for your order',
		error: {
			title: 'We were unable to retrieve licenses for your order at this time. Please check back later.',
			detail:
				'We will automatically retry to retrieve your licenses, and it may take several minutes. Once the licenses are generated, you will see them here, and you will also receive them in an email.',
		},
	},
	paymentInstructions: {
		title: 'Payment Instructions',
		description: 'Provide your bank with the following wire transfer instructions',
		amount: 'Amount',
		referenceNumber: {
			label: 'Payment reference number',
			note: 'Be sure to include this number or your payment may not be received. In the event your bank does not allow you to use this number in its full length, please copy and paste it into the Message for receiver field.',
		},
		accountHolder: 'Account Holder',
		bankName: 'Bank Name',
		accountNumber: 'Account Number',
		iban: {
			label: 'IBAN',
			note: 'IBAN, or International Bank Account Number, is a global standard used to identify bank accounts. It is used to facilitate the routing of payments and prevent routing errors.',
		},
		bic: 'BIC',
		aba: 'ABA / Routing Number',
		ach: 'ACH',
		error: {
			notAvailable: 'Payment instructions are not available.',
		},
	},
	cancelOrder: {
		title: 'Order cancelation',
		message:
			'Canceling an order cannot be reversed or undone. You will still be able to see the order, but no action can be taken on it.',
		success: 'Order successfully canceled.',
		error: 'Error occurred during order cancelation.',
		loading: 'Canceling the order #{{orderNumber}}',
		wtoc: {
			title: 'Canceling WTOC order',
		},
	},
	discountApproval: {
		approved: 'The Discretionary Discount is approved, you can finish the order.',
		rejected: 'The Discretionary Discount is rejected. You can edit the order and request approval again.',
		alert: {
			caption: 'Discretionary Discount is awaiting your approval',
			text: 'Check the discount and decide whether to approve or reject the discretionary discount.',
			reject: 'Reject',
			approve: 'Approve',
		},
		alertApprove: {
			caption: 'Discretionary Discount was rejected',
			text: 'It is still possible to approve the discount.',
		},
		confirm: {
			approve: {
				title: 'Approve discount',
				message: 'Please confirm your approval of this discount.',
				button: 'Confirm - Approve this Discount',
			},
			reject: {
				title: 'Reject discount',
				message: 'Please confirm your rejection of this discount.',
				button: 'Confirm - Reject this Discount',
			},
		},
		status: {
			success: {
				approve: 'The discount was successfully approved.',
				reject: 'The discount was successfully rejected.',
			},
			error: 'Discount status change failed.',
		},
	},
	cancelLicenses: {
		loading: 'Canceling license',
		loading_other: 'Canceling licenses',
		success: 'License has been canceled successfully',
		success_other: 'Licenses have been canceled successfully',
		confirm: {
			title: 'Cancel license',
			title_other: 'Cancel licenses',
			message: 'Are you sure you want to cancel the license?',
			message_other: 'Are you sure you want to cancel the licenses?',
			message_note:
				'Please note that in case of canceling all remaining licenses, entire order will be canceled. Cancelation process cannot be reversed or undone.',
		},
	},
};
