import React, { ReactElement } from 'react';
import logo from 'assets/image/logo.svg';
import logoOnly from 'assets/image/logo-only.svg';
import { useTranslation } from 'react-i18next';
import { RouteName } from 'module/RouteName';
import { Link } from 'react-router-dom';

type THeaderLogoProps = {
	collapsedLogo?: boolean;
};

export const useLogo = (collapsedLogo?: boolean) => (collapsedLogo ? logoOnly : logo);

export const HeaderLogo = (props: THeaderLogoProps): ReactElement => {
	const [t] = useTranslation();
	const { collapsedLogo } = props;
	const logo = useLogo(collapsedLogo);

	return (
		<div className="logo-container">
			<Link
				to={RouteName.HOMEPAGE.DEFAULT}
				className="d-block"
			>
				<span>
					<img
						src={logo}
						alt={t('appName')}
					/>
				</span>
			</Link>
		</div>
	);
};
