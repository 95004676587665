export default {
	actions: {
		generate: '小売りコードを生成',
		downloadLicenses: 'リクエストしたライセンスをダウンロード',
		refreshListItems: '品目を更新',
	},
	page: {
		list: '一括 ID 検索',
		licenses: '小売りコード検索',
	},
	entity: {
		bulkId: '一括 ID',
		transactionDate: 'リクエスト日',
	},
	filter: {
		bulkId: {
			placeholder: '一括 ID を入力して検索...',
		},
	},
	generate: {
		submit: 'リクエストを送信',
		submitLoading: 'リクエストを保存中...',
		success: 'リクエストが正常に作成されました',
		partner: {
			headline: 'パートナー',
			select: 'パートナーを選択',
			change: 'パートナーを変更',
			placeholder: '小売りパートナーを選択',
		},
		cart: {
			headline: 'かご',
			add: '製品を追加',
			removeTooltip: 'この品目をかごから削除',
			placeholder: '製品をかごに追加',
		},
		products: {
			headline: '製品を選択',
			addAndClose: '追加して閉じる',
			success: '製品をかごに追加しました。',
		},
	},
};
