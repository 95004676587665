import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum OrderSearchKeyEnum {
	QUOTE_NUMBER = 'QUOTE_NUMBER',
	ORDER_NUMBER = 'ORDER_NUMBER',
	COMMERCE_ORDER_ID = 'COMMERCE_ORDER_ID',
	PO_NUMBER = 'PO_NUMBER',
	OPPORTUNITY_ID = 'OPPORTUNITY_ID',
}

export const orderSearchKeyEnumUtils = generateEnumUtils<OrderSearchKeyEnum>(OrderSearchKeyEnum, {
	translatePath: 'enums:order.searchKey',
});
