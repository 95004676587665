import { IPageModuleConfig, IPageModuleLinksConfig, moduleConfigGenerator } from 'module/index';
import { RouteName } from 'module/RouteName';
import { buildParametrizedRoute } from 'js/utils/common';
import { IEntityOrder, IOrderDetailState } from 'module/orders';
import { NavigateLink } from 'types';

interface IQuoteConfigProps {
	quotes: { menuName: string; menuLink: string };
}

interface IOrderConfigProps extends IPageModuleConfig, IPageModuleLinksConfig<IEntityOrder>, IQuoteConfigProps {
	updateLink: (order: IEntityOrder) => string;
	detailLinkLicenses: (order: IEntityOrder) => NavigateLink<IOrderDetailState>;
	detailLinkPaymentInstructions: (order: IEntityOrder) => NavigateLink<IOrderDetailState>;
	detailLinkDocuments: (order: IEntityOrder) => NavigateLink<IOrderDetailState>;
	quoteDetailLink: (quote: IEntityOrder) => string;
	quoteUpdateLink: (quote: IEntityOrder) => string;
}

export const ordersConfig = moduleConfigGenerator<IOrderConfigProps>({
	trNamespace: 'moduleOrders',
	menuName: 'app:menu.orders',
	menuLink: RouteName.ORDER.LIST,
	aclModule: 'orders',
	quotes: {
		menuName: 'app:menu.quotes',
		menuLink: RouteName.QUOTE.LIST,
	},
	updateLink: (order) => buildParametrizedRoute(RouteName.ORDER.UPDATE, order.id),
	detailLink: (order) => buildParametrizedRoute(RouteName.ORDER.DETAIL, order.id),
	detailLinkLicenses: (order) => ({
		to: buildParametrizedRoute(RouteName.ORDER.DETAIL, order.id),
		state: { tab: 'licenses' },
	}),
	detailLinkPaymentInstructions: (order) => ({
		to: buildParametrizedRoute(RouteName.ORDER.DETAIL, order.id),
		state: { show: 'paymentInstructions' },
	}),
	detailLinkDocuments: (order) => ({
		to: buildParametrizedRoute(RouteName.ORDER.DETAIL, order.id),
		state: { show: 'documents' },
	}),
	quoteDetailLink: (quote) => buildParametrizedRoute(RouteName.QUOTE.DETAIL, quote.id),
	quoteUpdateLink: (quote) => buildParametrizedRoute(RouteName.QUOTE.UPDATE, quote.id),
});
