import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { submitOrderApiError } from 'module/purchase/utils/apiError';
import {
	AsyncContainer,
	TAsyncContainerComponentProps,
	useAsyncContainerRef,
} from 'js/components/molecules/Modal/AsyncContainer';
import { ConfirmationModal } from 'module/purchase/components/submitOrder/ConfirmationModal';
import { usePlaceOrder } from 'module/purchase/hooks/usePlaceOrder';
import {
	AsyncSuccessOrderModal,
	useAsyncSuccessOrderModalRef,
} from 'module/purchase/components/submitOrder/AsyncSuccessOrderModal';
import { HgopContainer, THgopContainer } from 'module/purchase/components/submitOrder/HgopContainer';
import { IEntityOrder } from 'module/orders';
import { TPaymentTypeAction } from 'module/purchase';
import { useQueryClient } from '@tanstack/react-query';
import { ordersConfig } from 'module/orders/ordersConfig';
import { useNavigate } from 'react-router-dom';
import { isEndCustomerQuote } from 'module/orders/utils/common';
import { PaymentTypeActionEnum } from 'module/purchase/enums';
import { UserOrderActionEnum } from 'module/orders/enums';
import { navigateLinkToNavigateObject } from 'js/utils/link';
import { useApiErrorContext, useAppContext, useOrderContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';

export type TAsyncSubmitOrderProps = {
	action: TPaymentTypeAction;
};

export const AsyncSubmitOrder = (props: TAsyncContainerComponentProps<TAsyncSubmitOrderProps>): ReactElement | null => {
	const { forwardedRef } = props;

	const [t] = useTranslation(purchaseConfig.trNamespace);
	const navigate = useNavigate();
	const { loadingModalRef } = useAppContext();
	const {
		resetOrder,
		orderState: { action },
	} = useOrderContext();
	const successRef = useAsyncSuccessOrderModalRef();
	const hgopRef = useAsyncContainerRef<THgopContainer>();
	const { setError } = useApiErrorContext();
	const placeOrder = usePlaceOrder();
	const queryClient = useQueryClient();

	// When user cancel placing an order, or data are incomplete
	const onCancel = useCallback(() => {
		loadingModalRef.current?.hide();
		forwardedRef.current?.onCancel();
	}, [forwardedRef, loadingModalRef]);

	// On success - payment is done
	const onSuccess = useCallback(
		(order: IEntityOrder, forceAction?: TPaymentTypeAction) => {
			successRef.current?.show({ order, action: forceAction || action! }).then((link) => {
				resetOrder();
				queryClient.invalidateQueries();

				// Navigate
				const navigateObject = navigateLinkToNavigateObject(link || ordersConfig.detailLink(order));
				navigate(navigateObject.to, navigateObject.options);

				forwardedRef.current?.onSuccess();
			});
		},
		[action, forwardedRef, successRef, resetOrder, queryClient, navigate],
	);

	// When user confirm placing an order
	const onConfirm = useCallback(() => {
		loadingModalRef.current?.show({
			title: t('common.savingOrder'),
			disableBeforeUnload: true,
		});

		placeOrder(
			(order) => {
				loadingModalRef.current?.hide();
				if (
					isDefined(order.chargeOrderId) &&
					order.userOrderAction === UserOrderActionEnum.HGOP &&
					!isEndCustomerQuote(order)
				) {
					hgopRef.current?.show({ order }).then((result) => {
						if (result) {
							onSuccess(order);
						} else {
							onSuccess(order, order.quote?.flag ? PaymentTypeActionEnum.QUOTE : PaymentTypeActionEnum.SAVE);
						}
					});
				} else {
					onSuccess(order);
				}
			},
			(error) => {
				loadingModalRef.current?.hide();
				if (error) {
					setError({
						error,
						onClose() {
							onCancel();
						},
						resolve: submitOrderApiError,
					});
				} else {
					onCancel();
				}
			},
		);
	}, [loadingModalRef, placeOrder, setError, t, onCancel, onSuccess, hgopRef]);

	return (
		<AsyncContainer<TAsyncSubmitOrderProps> ref={forwardedRef}>
			{({ action }) => (
				<>
					<ConfirmationModal
						show={forwardedRef.current?.isOpen() || false}
						onSubmit={onConfirm}
						onCancel={onCancel}
						action={action}
					/>
					<AsyncContainer ref={hgopRef}>
						{({ order }) => (
							<HgopContainer
								id={order.id}
								chargeOrderId={order.chargeOrderId}
								parentRef={hgopRef}
							/>
						)}
					</AsyncContainer>
					<AsyncSuccessOrderModal forwardedRef={successRef} />
				</>
			)}
		</AsyncContainer>
	);
};
