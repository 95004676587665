import { apiDateToDate, dateTimeToFilterDate } from 'js/utils/dateTime';
import type { TRangeDatepickerProps, TRangeDatepickerValue } from '@avast/react-ui-components';
import { defaultRangeSelectorsGenerator, RangeDatepicker } from '@avast/react-ui-components';
import React, { ReactElement } from 'react';
import { TDateRange } from 'types';
import { IDateRangeFilter } from 'types/filter';
import { useTranslation } from 'react-i18next';
import { useDataFilterContext } from 'js/components/molecules/DataFilter';
import { DateTime } from 'luxon';
import { isNil } from 'lodash';

export type TFilterRangeDatepickerProps = Partial<TRangeDatepickerProps>;

/**
 * Parse string value to date value.
 *
 * @param {TDateRange} dateArray
 * @returns {TRangeDatepickerValue}
 */
const value2date = (dateArray?: TDateRange): TRangeDatepickerValue => {
	if (isNil(dateArray)) {
		return null;
	}

	const [startDate, endDate] = dateArray;
	return [apiDateToDate(startDate), apiDateToDate(endDate)];
};

/**
 * Datepicker component with predefined props.
 *
 * @param {TFilterRangeDatepickerProps} props
 * @returns {React.ReactElement}
 */
export const FilterRangeDatepicker = (props: TFilterRangeDatepickerProps): ReactElement => {
	const { onChange, ...rest } = props;
	const [t] = useTranslation('common');
	const { isEnabledField, values, updateFilter } = useDataFilterContext<IDateRangeFilter>();

	return (
		<RangeDatepicker
			selectButton={{ size: 'sm', placeholder: t('_.dateRangePlaceholder') }}
			disabled={isEnabledField ? !isEnabledField('dateRange') : false}
			value={value2date(values.dateRange)}
			onChange={(value) => {
				// Value is set and has two not null values
				if (value?.filter((v) => Boolean(v)).length === 2) {
					updateFilter({
						dateRange: [
							dateTimeToFilterDate(DateTime.fromJSDate(value[0]!)),
							dateTimeToFilterDate(DateTime.fromJSDate(value[1]!)),
						],
					});
				} else {
					updateFilter({
						dateRange: undefined,
					});
				}
			}}
			rangeSelectorsFn={defaultRangeSelectorsGenerator}
			{...rest}
		/>
	);
};
