import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum TransactionTypeEnum {
	NEW = 'New',
	INCREASE = 'Increase',
	INCREASE_AND_UPGRADE = 'Increase and Upgrade',
	RENEW = 'Renew',
	RENEW_AND_UPGRADE = 'Renew and Upgrade',
	RENEW_AND_DECREASE = 'Renew and Decrease',
	RENEW_AND_DOWNGRADE = 'Renew and Downgrade',
	RENEW_AND_INCREASE = 'Renew and Increase',
	UPGRADE = 'Upgrade',
}

export const transactionTypeEnumUtils = generateEnumUtils<TransactionTypeEnum>(TransactionTypeEnum, {});
