export default {
	comparativeFeatures: {
		title: 'Nuevas soluciones de Avast Business',
		availableAsAddon: 'Disponible como complemento',
		equivalentLabel: 'Más valor. Mismo precio.',
		action: 'Ver paquetes',
		message:
			'Tenemos nuevos paquetes de seguridad que proporcionan más valor a sus clientes por el mismo precio. Ayude a que sus clientes elijan el nivel correcto de protección para sus negocios.',
		price: {
			from: 'De',
			units: '{{unitType}}',
			units_other: '{{count}} {{unitType}}',
		},
		startShopping: 'Comenzar a comprar',
		windowsOnly: 'No disponible para MacOS',
	},
	refunds: {
		entity: {
			date: 'Fecha de reembolso',
			initiatedDate: 'Fecha de iniciación de reembolso',
			itemId: 'Id. de línea',
			orderLineItemId: 'Número de línea de pedido',
			type: 'Tipo de reembolso',
			reason: 'Motivo de reembolso',
			reasonNote: 'Comentario',
			refundableAmount: 'Disponible para reembolso',
			refundAmount: 'Importe de reembolso',
			totalRefunded: 'Total reembolsado',
		},
		initiate: {
			title: 'Iniciar reembolso para el pedido #{{id}}',
			submit: 'Enviar solicitud',
			process: {
				loading: 'Enviando reembolso...',
				success: 'Se envió la solicitud de reembolso.',
				error: 'No es posible enviar la solicitud de reembolso.',
			},
			message: {
				ORDER_REFUND:
					'Solicite el reembolso del importe total del pedido, o bien, un reembolso parcial del pedido mediante la introducción del importe deseado (hasta el máximo importe disponible).',
				LINE_REFUND:
					'Seleccione los productos que desee reembolsar. Solicite el reembolso del importe total del artículo de línea, o bien, un reembolso parcial del artículo de línea mediante la introducción del importe deseado (hasta el máximo importe disponible).',
				TAX_REFUND:
					'Al seleccionar esta opción, el pedido total se reembolsará y una nueva factura sin impuestos se generará automáticamente.',
				KEY_REFUND:
					'Seleccione la clave que desee reembolsar. Solicite el reembolso del importe total, o bien, un reembolso parcial mediante la introducción del importe deseado (hasta el máximo importe disponible).',
			},
		},
		view: {
			title: 'Reembolsos para el pedido #{{id}}',
		},
		error: {
			noRefundTypeEnabled: 'No se permite ningún tipo de reembolso para este pedido.',
			refundableAmount: 'No es posible cargar información sobre los reembolsos de pedidos.',
			refundedAmount: 'No es posible cargar el importe total reembolsado.',
		},
		license: {
			noSelectedLicenses: 'No tiene ninguna licencia seleccionada. Use el campo anterior para buscar licencias.',
			search: {
				title: 'Introduzca una clave para buscar una licencia',
				searchFailure: 'La clave {{licenseKey}} no coincide con ninguna licencia para este pedido.',
				refundableAmountFailure: 'No es posible cargar un importe reembolsable para la clave {{licenseKey}}.',
				success: '{{licenseKey}} se agregó al formulario de reembolso.',
				loadingLicenses: 'Cargando licencias...',
				loadingRefundableAmount: 'Cargando importe reembolsable...',
			},
			select: {
				title: 'Seleccione una licencia para reembolsar',
			},
		},
	},
	appMessages: {
		taxExemptionCertificate: {
			title:
				'Tenga en cuenta que los certificados cargados mediante OMS solo se aplican a pedidos con tarjeta de crédito en los que el proveedor sea Digital River Inc.',
			contentTop:
				'A partir del <strong>1 de febrero de 2023</strong>, el proveedor de la línea de compras de crédito (incluida la forma de pago del saldo final, según corresponda) cambiará de Digital River Inc. a Gen Digital Inc. Para garantizar que la línea de compras de crédito siga exenta de impuestos, <strong>envíe un certificado de exención de impuestos emitido por Gen Digital Inc.</strong> a nuestro departamento fiscal a <0>{{email}}</0>.',
			contentBottom: 'Gracias por su colaboración.',
		},
		flexibleBillingAvailable: {
			title: 'Business Hub ya ofrece facturación flexible',
			content:
				'Los MSP (proveedores de servicios gestionados) que utilicen la versión para socios multiempresa de Business Hub ya disponen de una opción de pago por uso.<br/>De este modo, se maximiza la flexibilidad, escalabilidad y rentabilidad de los MSP al permitirles consumir las soluciones de Avast Business de la forma que se adecue mejor a sus transacciones con los clientes.',
			action: 'Más información',
		},
	},
	widgets: {
		action: {
			addWidget: 'Agregar widget',
			editWidget: 'Editar widget',
			editWidgets: 'Editar widgets',
		},
		common: {
			columnPlaceholder: 'Agregar nuevo o mover widget...',
			savingLoader: 'Guardando cambios en los widgets...',
			rowTitle: 'Fila ({{count}} columna)',
			rowTitle_other: 'Fila ({{count}} columnas)',
			changesSaved: 'Se guardaron los cambios en los widgets',
			oneColumnRow: 'Fila de una columna',
			twoColumnsRow: 'Fila de dos columnas',
		},
		config: {
			type: 'Tipo',
			ownName: 'Nombre propio',
			ownNamePlaceholder: 'Nombre de widget',
			columns: 'Columnas visibles',
			columnsPlaceholder: 'Seleccione al menos una columna',
		},
		setting: {
			days: 'Días',
			limit: 'Máximo de filas',
		},
	},
	locale: {
		language: {
			saving: 'Cambio de idioma del sistema',
			success: 'Se cambió el idioma del sistema.',
			confirmation: {
				title: 'Cambiar el idioma del sistema',
				message:
					'El cambio de configuración de idioma de su cuenta también afectará el idioma utilizado en sus facturas y mensajes de correo electrónico de Avast. ¿Realmente quiere realizar este cambio?',
			},
		},
		format: {
			saving: 'Cambio de configuración de formato de fechas y números',
			success: 'Se cambió el formato de fechas y números.',
		},
	},
};
