import { TPartnerId } from 'types';
import { useApiPartner } from 'module/partners/hooks/useApiPartners';
import { noValuePlaceholder } from 'js/utils/app';
import Skeleton from 'react-loading-skeleton';
import React from 'react';
import { getPartnerRenderName } from 'module/partners/utils/partnerSelectors';

type TPartnerNameProps = {
	partnerId?: TPartnerId | null;
};

export const PartnerName = (props: TPartnerNameProps) => {
	const enabled = Boolean(props.partnerId);
	const {
		data: partner,
		query: { isFetching },
	} = useApiPartner({
		id: props.partnerId,
		config: { catchError: false },
		queryConfig: { enabled },
	});

	if (isFetching) {
		return <Skeleton width={120} />;
	}

	if (!enabled || !partner) {
		return noValuePlaceholder();
	}

	return <>{getPartnerRenderName(partner)}</>;
};
