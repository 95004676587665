import React, { ReactElement, useState } from 'react';
import { IApiPartnerCustomerListFilter, IEntityCustomer } from 'module/customers';
import { customersConfig } from 'module/customers/customersConfig';
import { useTranslation } from 'react-i18next';
import { SearchBox } from 'js/components/molecules/SearchBox';
import { useApiPartnerCustomerCount, useApiPartnerCustomerList } from 'module/customers/hooks/useApiCustomers';
import { IEntityPartner } from 'module/partners';
import { ApiListTable } from 'js/components/molecules/DataTable';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import {
	getFilterCharsMessage,
	hasFilterEnoughChars,
	isCustomersApiLimitExceeded,
} from 'module/customers/utils/common';
import { TableColumnsDef } from 'types';
import { SortDirectionEnum } from 'js/enums';

type TTableData = IEntityCustomer;
type TTableFilter = IApiPartnerCustomerListFilter;

type TSelectPartnerCustomerProps = {
	onSelect: (customer: IEntityCustomer) => void;
	selectedCustomerId?: IEntityCustomer['id'];
	partnerId?: IEntityPartner['id'];
	columns: TableColumnsDef<IEntityCustomer>;
};

/**
 * Search customer for specified partner
 * @param {TSelectPartnerCustomerProps} props
 * @return {ReactElement}
 * @constructor
 */
export const SelectPartnerCustomer = (props: TSelectPartnerCustomerProps): ReactElement => {
	const { onSelect, selectedCustomerId, partnerId, columns } = props;

	// Component hooks
	const [t] = useTranslation(customersConfig.trNamespace);
	const [searchValue, setSearchValue] = useState<string>('');

	// Get count of customers
	const {
		data: customersCount,
		query: { isFetching },
	} = useApiPartnerCustomerCount({
		filter: { partnerId: partnerId! },
		queryConfig: { enabled: Boolean(partnerId) },
	});

	if (isFetching) {
		return <LoadingPlaceholder type="BAR" />;
	}

	return (
		<>
			<div className="mb-3">
				<SearchBox
					size="sm"
					placeholder={t('select.filter.placeholder')}
					onChange={setSearchValue}
					autoFocus
				/>
			</div>
			{(() => {
				if (isCustomersApiLimitExceeded(customersCount)) {
					const hasPartner = Boolean(partnerId);
					const hasEnoughChars = hasFilterEnoughChars(searchValue);
					let requiredMessage: string | boolean = false;
					if (!hasPartner) {
						requiredMessage = t('common:filter.partnerIsRequired');
					}
					if (!hasEnoughChars) {
						requiredMessage = getFilterCharsMessage(t);
					}

					return (
						<ApiListTable<TTableData, TTableFilter>
							columns={columns}
							query={useApiPartnerCustomerList}
							filter={{ partnerId, filter: searchValue }}
							filterRequired={requiredMessage}
							filterIsEmpty={() => !hasPartner || !hasEnoughChars}
							limit={8}
							table={{
								testId: 'customers',
								meta: {
									nowrapCell: false,
									onRowClick: onSelect,
									isHighlightedRow: (row) => Boolean(selectedCustomerId) && row.id === selectedCustomerId,
								},
							}}
						/>
					);
				}

				return (
					<ApiListTable<TTableData, TTableFilter>
						columns={columns}
						query={useApiPartnerCustomerList}
						sort={{ direction: SortDirectionEnum.ASC, key: 'companyName' }}
						limit={8}
						filter={{ partnerId }}
						table={{
							testId: 'customers',
							onGlobalFilterChange: setSearchValue,
							state: { globalFilter: searchValue },
							meta: {
								onRowClick: onSelect,
								isHighlightedRow: (row) => Boolean(selectedCustomerId) && row.id === selectedCustomerId,
							},
						}}
						filterRequired
						filterIsEmpty={(filter) => !filter.partnerId}
					/>
				);
			})()}
		</>
	);
};
