export type TScreenSizeName = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type TScreenSize = { name: TScreenSizeName; breakpoint: number };

export const screenSizes: Record<TScreenSizeName, TScreenSize> = {
	xs: { name: 'xs', breakpoint: 576 },
	sm: { name: 'sm', breakpoint: 980 },
	md: { name: 'md', breakpoint: 1292 },
	lg: { name: 'lg', breakpoint: 1500 },
	xl: { name: 'xl', breakpoint: Infinity },
} as const;
