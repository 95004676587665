export default {
	steps: {
		billableParty: {
			title: '計費方',
			virtualPartnerTitle: '新潛在客戶',
		},
		product: {
			title: '產品',
			isRenewal: '這是續購續訂嗎？',
		},
		discounts: {
			title: '折扣',
			discretionary: '酌情折扣',
			special: '特別折扣',
			tier: '層級折扣',
			segment: '細分折扣',
		},
		price: {
			title: '價格',
			appliedPromotions: '套用的促銷活動',
		},
	},
	message: {
		countryCodeNotSelected: '先選取國碼國家碼',
		requiredStepsNotFulfilled: '選取計費方或產品以查看價格',
	},
	goalSeekedPrice: {
		title: '目標尋求單價',
		originalPrice: '原始單價',
		finalPrice: '最終單價',
	},
};
