export default {
	common: {
		discountWaitingToApprove: 'Der Rabatt muss von Ihrem Vertriebsmanager genehmigt werden.',
		selectCurrencyModalTitle: 'Kundenwährung auswählen',
		otherCustomerInformation: 'Sonstige',
	},
	entity: {
		contactId: 'Kunden-ID',
		customerType: 'Kundenkategorie',
		discount: 'Rabatt',
		industry: 'Branche',
	},
	error: {
		createCustomer: 'Kunde kann nicht erstellt werden.',
		updateCustomer: 'Der Kunde kann nicht aktualisiert werden.',
	},
	select: {
		title: 'Kunde auswählen',
		button: {
			placeholder: 'Kunde auswählen',
			label: 'Kunde',
		},
		filter: {
			placeholder: 'Kunde suchen',
			placeholderEmail: 'Kunde nach E-Mail suchen',
			placeholderType: 'Kundenkategorie',
		},
		type: {
			EMAIL: 'E-Mail',
			ID: 'SalesForce-ID',
		},
	},
	page: {
		create: {
			title: 'Neuer Kunde',
			success: 'Sie haben einen neuen Kunden erstellt.',
			form: {
				email: 'Bitte verwenden Sie eine echte E-Mail-Adresse, zu der Ihr Kunde Zugang hat.',
			},
		},
		update: {
			success: 'Sie haben den Kunden aktualisiert.',
		},
	},
	actions: {
		edit: 'Kunde bearbeiten',
		exportAll: 'Alle Kunden exportieren',
		newCustomer: 'Neuen Kunden erstellen',
		relatedLicenses: 'Verbundene Lizenzen anzeigen',
		relatedOrders: 'Verbundene Bestellungen anzeigen',
		relatedQuotes: 'Verbundene Angebote anzeigen',
		viewLicenses: 'Lizenzen anzeigen',
		viewOrders: 'Bestellungen anzeigen',
		viewQuotes: 'Angebote anzeigen',
		form: {
			create: 'Als neuen Kunden speichern',
			update: 'Änderungen speichern',
		},
	},
};
