import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum ClosingBalanceSearchKeyEnum {
	ORDER_NUMBER = 'ORDER_NUMBER',
}

export const closingBalanceSearchKeyEnumUtils = generateEnumUtils<ClosingBalanceSearchKeyEnum>(
	ClosingBalanceSearchKeyEnum,
	{ translatePath: 'enums:closingBalance.searchKey' },
);
