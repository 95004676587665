import { useMemo } from 'react';
import { TApiListResponse } from 'types/api';
import { IEntityProductGroup } from 'module/purchase';
import { useSelectProductsPricing } from 'module/purchase/hooks/pricing/useSelectProductsPricing';
import { TUseSelectProductGroups, useSelectProductsGroups } from 'module/purchase/hooks/useSelectProductsGroups';
import { usePriceListContext } from 'js/priceList/PriceListContext';

export interface IProductGroupWithPrice extends IEntityProductGroup {
	unitPrice: number;
	unitPriceSrp: number;
}

type TUseSelectProductGroupsWithPrices = {
	isPricingLoading: boolean;
	data: TApiListResponse<IProductGroupWithPrice>;
} & Omit<TUseSelectProductGroups, 'data'>;

/**
 * Get product groups and their base prices (quantity = 1)
 * @returns {TUseSelectProductGroups}
 */
export const useSelectProductsGroupsWithPrices = (): TUseSelectProductGroupsWithPrices => {
	const { priceListCode } = usePriceListContext();
	const { data: productGroups, query } = useSelectProductsGroups(priceListCode);
	const { pricing, isPricingLoading } = useSelectProductsPricing(productGroups);

	return {
		query,
		isPricingLoading,
		data: useMemo(
			(): TApiListResponse<IProductGroupWithPrice> =>
				productGroups.map((group) => {
					const pricingItem = pricing?.lineitems.find((item) => item.reference_lineitem_id === group.code);
					return {
						...group,
						unitPrice: pricingItem?.unitPrice || pricingItem?.linePriceWOTax || 0,
						unitPriceSrp: pricingItem?.customerPrice?.unit || 0,
					};
				}),
			[productGroups, pricing],
		),
	};
};
