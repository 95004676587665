import { IEntityPromotion } from 'module/promotions';
import React, { useCallback } from 'react';
import { updatePromotionApiError } from 'module/promotions/utils/apiError';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { useApiDisablePromotion } from 'module/promotions/hooks/useApiPromotion';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { useApiErrorContext, useAppContext } from 'js/contexts';

export const useDisablePromotion = () => {
	const {
		data: promotion,
		query: { refetch },
	} = usePageDetailContext<IEntityPromotion>();
	const [t] = useTranslation(promotionsConfig.trNamespace);
	const { loadingModalRef } = useAppContext();
	const { setError } = useApiErrorContext();
	const { mutateAsync: disablePromotion } = useApiDisablePromotion({
		config: { params: { id: promotion.id } },
	});

	return useCallback(async () => {
		loadingModalRef.current?.show({
			title: t('actions.disable.loading'),
		});

		const result = await disablePromotion({}).catch((error) => {
			setError({
				error,
				resolve: updatePromotionApiError,
			});
		});

		if (result) {
			toast.success(<Toast>{t('actions.disable.success')}</Toast>);
		}
		loadingModalRef.current?.hide();
		refetch();
	}, [t, loadingModalRef, setError, disablePromotion, refetch]);
};
