import React from 'react';
import { IComparativeData } from 'submodule/comparativeFeatures';
import { Tooltip } from '@avast/react-ui-components';
import { ComparativeFeatureEnum, comparativeFeatureEnumUtils } from 'submodule/comparativeFeatures/enums';
import { isFeatureWindowsOnly } from 'submodule/comparativeFeatures/comparativeFeaturesUtils';
import { FeatureStatusCell } from './FeatureStatusCell';

type TFeatureRowProps = {
	comparativeData: IComparativeData;
	feature: ComparativeFeatureEnum;
};

export const FeatureRow = (props: TFeatureRowProps) => {
	const { comparativeData, feature } = props;
	const isMulti = comparativeData.products.length !== 1;

	return (
		<tr>
			<td
				align="left"
				className="td-tooltip"
			>
				<Tooltip
					content={comparativeFeatureEnumUtils.getDescription(feature)}
					placement="top"
				>
					<span className="text-tooltip">
						{comparativeFeatureEnumUtils.getText(feature)}
						{isFeatureWindowsOnly(feature) && '*'}
					</span>
				</Tooltip>
			</td>
			{comparativeData.products.map((list, listIndex) => (
				<React.Fragment key={listIndex}>
					{isMulti && listIndex !== 0 && <td className="is-separator" />}
					{list.map((product, index) => (
						<FeatureStatusCell
							key={`${product.productGroup.code}_${feature}`}
							isNew={index !== 0}
							isMulti={isMulti}
							value={product.comparativeProduct.features.includes(feature)}
							feature={feature}
						/>
					))}
				</React.Fragment>
			))}
		</tr>
	);
};
