import { compact } from 'lodash';
import {
	TWidgetConfig,
	TWidgetGenerator,
	TWidgetGeneratorColumn,
	TWidgetGeneratorRow,
	TWidgetGrid,
	TWidgetGridColumn,
	TWidgetGridItem,
	TWidgetGridRow,
} from 'submodule/widgets';

type TGetId = () => number;

/**
 * Transform widget generator into widget grid
 * @param {TWidgetGenerator} generator
 * @param {TWidgetConfig[]} widgets
 * @return {TWidgetGrid}
 */
export const widgetGeneratorToWidgetGrid = (generator: TWidgetGenerator, widgets: TWidgetConfig[]): TWidgetGrid => {
	let id = 0;
	const getId: TGetId = () => ++id;

	// Get rows and remove falsy rows
	return compact(generator.map((configRow) => widgetGeneratorRowToWidgetGridRow(configRow, widgets, getId)));
};

/**
 * Transform generator column into grid column
 * @param {TWidgetGeneratorRow} row
 * @param {TWidgetConfig[]} widgets
 * @param {TGetId} getId
 * @return {TWidgetGridRow | undefined}
 */
const widgetGeneratorRowToWidgetGridRow = (
	row: TWidgetGeneratorRow,
	widgets: TWidgetConfig[],
	getId: TGetId,
): TWidgetGridRow | undefined => {
	const columns: TWidgetGridColumn[] = compact(
		row.map((column) => widgetGeneratorColumnToWidgetGridColumn(column, widgets, getId)),
	);

	return columns.length ? columns : undefined;
};

/**
 * Transform generator column into grid column
 * @param {TWidgetGeneratorColumn} column
 * @param {TWidgetConfig[]} widgets
 * @param {TGetId} getId
 * @return {TWidgetGridColumn | undefined}
 */
const widgetGeneratorColumnToWidgetGridColumn = (
	column: TWidgetGeneratorColumn,
	widgets: TWidgetConfig[],
	getId: TGetId,
): TWidgetGridColumn | undefined => {
	const items: TWidgetGridItem[] = compact(
		column.map((configWidget) => {
			const widget = widgets.find((widget) => widget.type === configWidget)?.getDefaultState();
			return widget ? { id: getId(), widget } : undefined;
		}),
	);

	return items.length ? items : undefined;
};
