export default {
	common: {
		partnerPortal: 'Iniciar sesión en OMS mediante el portal para socios',
		forgetPassword: '¿Olvidó su contraseña?',
		returnToPartnerPortal: 'Regresar al portal para socios',
		successNewPassword: 'Se cambió correctamente la nueva contraseña.',
		backToLogin: 'Volver al inicio de sesión',
		loginLoading: 'Iniciar sesión en OMS',
	},
	commonText: {
		loginForAvastOnly: 'Exclusivamente para uso interno de Avast.',
		assistance: 'Si necesita asistencia, contacte con el Administrador de cuentas de Avast Business.',
		signature: 'Muchas gracias, <br />Avast Business',
	},
	form: {
		password: 'Contraseña',
		submit: 'Continuar a OMS',
		email: 'Correo electrónico',
	},
	page: {
		login: {
			title: 'Iniciar sesión',
		},
		logout: {
			loading: 'Cerrar sesión de OMS...',
		},
		sso: {
			title: 'Inicio de sesión único',
			loading: 'Iniciar sesión en OMS...',
		},
		loginAsAffiliate: {
			title: 'Iniciar sesión como afiliado',
			loading: 'Iniciar sesión como afiliado en OMS...',
		},
		orderHold: {
			title: 'Su empresa tiene facturas vencidas hace {{count}} o más días.',
			text: 'Por este motivo, la cuenta de su empresa se colocó en espera y no puede realizar nuevas compras en crédito.',
			textMore:
				'En la sección Pedidos, se pueden encontrar más detalles sobre pedidos no pagados, los cuales también se compartieron por correo electrónico con el contacto principal de su empresa.',
			action: 'Ver pedidos no pagados',
		},
		registrationIncomplete: {
			title: 'No se puede completar la operación',
			text: 'Un contacto principal (administrador) de su organización aún no completó el proceso de registro de OMS. Una vez que este proceso se complete, el acceso quedará disponible.',
		},
		terms: {
			title: 'Nuevos términos y condiciones',
			text: 'Un usuario principal (administrador) de su organización debe aceptar los nuevos términos y condiciones.',
		},
		inactive: {
			title: 'No se puede completar la operación',
			text: 'Se rechazó el acceso. Contacte con el administrador de cuentas para obtener más detalles.',
		},
		maintenance: {
			scheduled: {
				title: 'Mantenimiento programado',
				text: 'El sistema de administración de pedidos no está disponible en este momento porque se están llevando a cabo las tareas de mantenimiento programadas. Lamentamos los inconvenientes.',
				end: 'Final de mantenimiento programado',
			},
		},
		passwordReset: {
			title: 'Restablecer contraseña',
			info: 'Proporcione la dirección de correo electrónico de su cuenta para restablecer su contraseña.',
			success: 'El vínculo de restablecimiento de contraseña se envió a su casilla de correo electrónico.',
		},
		newPassword: {
			title: 'Establecer nueva contraseña',
			form: {
				password: 'Nueva contraseña',
				confirm: 'Confirmar contraseña',
			},
			info: 'Introduzca una contraseña nueva.',
			validation: {
				digit: 'La contraseña debe contener al menos un dígito',
				symbol: 'La contraseña debe contener al menos un símbolo',
				length: 'La contraseña debe contener un mínimo de 8 caracteres',
				uppercase: 'La contraseña debe contener al menos una mayúscula',
				lowercase: 'La contraseña debe contener al menos una minúscula',
				notSame: 'La contraseña de confirmación no coincide',
				trim: 'La contraseña no puede empezar ni terminar con espacio.',
			},
		},
	},
	state: {
		invalidCredentials: 'El nombre de usuario o la contraseña no son correctos. Vuelva a intentarlo.',
		invalidSso: 'No es posible iniciar sesión. La sesión expiró. Vuelva a iniciar sesión.',
		invalidAuthorizationCode: 'No es posible iniciar sesión con este código de autorización.',
		sessionExpired: 'La sesión expiró. Debe iniciar sesión.',
	},
	error: {
		unableToResetPassword: 'En este momento, no es posible restablecer su contraseña.',
	},
};
