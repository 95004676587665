export default {
	error: {
		closingBalanceNotFound: 'Closing Balance not found!',
	},
	page: {
		detail: {
			title: 'Detail #{{id}}',
		},
	},
	tab: {
		detail: 'Detail View',
		info: 'Information',
		statement: 'Statement View',
	},
	entity: {
		amountWOTax: 'Amount WO Tax',
		amountWTax: 'Amount W Tax',
		companyTaxId: 'Company Tax ID',
		lineDetailId: 'Line Detail ID',
		orderStatusPlaceholder: 'Any status',
		paymentStatusPlaceholder: 'Any status',
		period: 'Period',
		term: 'Term',
		partnerDiscount: 'Partner Discount',
		itemPriceWOTax: 'Line Item Price excl. Tax',
		fulfillmentCompleteDate: 'Order Date',
		srpTotalPrice: 'SRP Total Price',
	},
	actions: {
		downloadInvoice: 'Download Invoice',
		export: {
			list: 'Export Statements',
			line: 'Export Items',
			lineDetail: 'Export Licenses',
		},
		viewRefunds: 'View Refunds',
	},
	common: {
		documents: 'Order Documents',
		initiateRefund: 'Initiate Refund',
	},
	statement: {
		saleUnitPrice: 'Sales Unit Price',
		pricingInfo: 'Pricing Info',
	},
};
