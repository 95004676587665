export default {
	actions: {
		generate: 'Generar códigos de tienda minorista',
		downloadLicenses: 'Descargar licencias de solicitud',
		refreshListItems: 'Actualizar artículos',
	},
	page: {
		list: 'Búsqueda de Id. masivo',
		licenses: 'Búsqueda de código de tienda minorista',
	},
	entity: {
		bulkId: 'Id. masivo',
		transactionDate: 'Fecha de solicitud',
	},
	filter: {
		bulkId: {
			placeholder: 'Introduzca el Id. masivo y vaya a ...',
		},
	},
	generate: {
		submit: 'Enviar solicitud',
		submitLoading: 'Enviando solicitud...',
		success: 'Solicitud creada correctamente',
		partner: {
			headline: 'Socio',
			select: 'Seleccionar socio',
			change: 'Cambiar socio',
			placeholder: 'Seleccione un socio de tienda minorista',
		},
		cart: {
			headline: 'Carrito',
			add: 'Agregar productos',
			removeTooltip: 'Eliminar este artículo del carrito',
			placeholder: 'Agregar productos al carrito',
		},
		products: {
			headline: 'Seleccionar productos',
			addAndClose: 'Agregar y cerrar',
			success: 'Se agregaron los productos al carrito.',
		},
	},
};
