import React, { ReactElement, useMemo } from 'react';
import { useAuthContext, usePageDetailContext } from 'js/contexts';
import { FlexibleBillingDetailActions } from 'module/flexibleBilling/components/actions/FlexibleBillingDetailActions';
import { OverviewTable } from 'module/flexibleBilling/components/table/OverviewTable';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { DetailHeadline } from 'module/flexibleBilling/components/DetailHeadline';
import { useRouteParams } from 'js/hooks/useRouteParams';
import { TDetailArguments } from 'types';
import {
	DistributionPartnerSpendingOverview,
	FlexibleBillingDetailMenu,
	InviteToFlexibleBillingInfo,
} from 'module/flexibleBilling/components';
import { IApiFlexibleBillingDetailFilter, IFlexibleBillingDistributionPartner } from 'module/flexibleBilling';

type TArguments = TDetailArguments;

export const PageDetail = (): ReactElement => {
	const params = useRouteParams<TArguments>();
	const { authCompanyId, authCompany } = useAuthContext();
	const currency = authCompany?.currencyCode;
	const {
		data: { distributionPartner },
	} = usePageDetailContext<IFlexibleBillingDistributionPartner>();

	// Setup query props/filter for the overview table detail
	const queryProps = useMemo<IApiFlexibleBillingDetailFilter>(
		() => ({
			id: distributionPartner.id!,
			distributorId: authCompanyId,
		}),
		[distributionPartner, authCompanyId],
	);

	return (
		<>
			<DetailHeadline rightContent={<FlexibleBillingDetailActions partnerId={params.id} />} />
			<FlexibleBillingDetailMenu />
			<DefaultContainer>
				<InviteToFlexibleBillingInfo />
				<DistributionPartnerSpendingOverview
					distributionPartnerId={params.id}
					className="mb-4"
				/>
				<OverviewTable
					currency={currency || ''}
					filter={queryProps}
				/>
			</DefaultContainer>
		</>
	);
};
