import { Button } from '@avast/react-ui-components';
import classNames from 'classnames';
import React from 'react';
import type { EmblaCarouselType } from 'embla-carousel';
import { useTranslation } from 'react-i18next';

type TGuidePrevButtonProps = {
	slider?: EmblaCarouselType;
};

export const GuidePrevButton = (props: TGuidePrevButtonProps) => {
	const { slider } = props;
	const [t] = useTranslation('common');
	const isFirst = !slider?.canScrollPrev();

	return (
		<Button
			disabled={isFirst}
			variant="simple"
			onClick={() => !isFirst && slider?.scrollPrev()}
			className={classNames('text-primary', 'px-5', { 'btn-guide-hidden': isFirst })}
		>
			{t('actions.previous')}
		</Button>
	);
};
