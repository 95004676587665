import type { ISelectOption, TSelectOptions } from '@avast/react-ui-components';
import { max } from 'lodash';

/**
 * Get the length of the longest label of keys.
 * @param {TSelectOptions<KeyType>} keys
 * @returns {number}
 */
const getLengthOfLongestLabel = (keys: TSelectOptions): number => {
	const lengthOfKey = (key: ISelectOption) => key.label.replace(/\s/g, '').length;

	return max(keys.map(lengthOfKey)) || 0;
};

/**
 * Get the CSS width value for select component.
 * @param {TSelectOptions<KeyType>} keys
 * @param {number} arrowLength
 * @returns {string}
 */
export const getSelectComponentCSSWidth = (keys: TSelectOptions, arrowLength = 5): string => {
	const width = getLengthOfLongestLabel(keys) + arrowLength;
	return `${width}ch`;
};
