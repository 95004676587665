import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { registrationConfig } from 'module/registration/registrationConfig';
import { finishingRegistrationApiError } from 'module/registration/utils/apiError';
import { RouteName } from 'module/RouteName';
import { useApiRegistrationFinish } from 'module/registration/hooks/useApiRegistration';
import { useRegistrationContext } from 'module/registration/context/RegistrationContext';
import { H2 } from '@avast/react-ui-components';
import { useNavigate } from 'react-router-dom';
import { useApiErrorContext, useAppContext, useAuthContext } from 'js/contexts';

export const useFinishRegistration = () => {
	const { loadingModalRef, successModalRef } = useAppContext();
	const { setError } = useApiErrorContext();
	const [t] = useTranslation(registrationConfig.trNamespace);
	const { partnerId } = useRegistrationContext();
	const { mutateAsync: finishRegistration } = useApiRegistrationFinish({ config: { params: { partnerId } } });
	const { setAuthPartner } = useAuthContext();
	const navigate = useNavigate();

	return useCallback(async () => {
		loadingModalRef.current?.show({ title: t('common.finishing') });

		const finishingResponse = await finishRegistration({}).catch((error) => {
			setError({ error, resolve: finishingRegistrationApiError });
		});

		if (!finishingResponse) {
			loadingModalRef.current?.hide();
			return;
		}

		const registeredPartner = finishingResponse.data;
		successModalRef.current
			?.show({
				content: (
					<>
						<H2
							className="text-center"
							bold
						>
							{t('common.successRegistration')}
						</H2>
					</>
				),
				submitButtonText: t('common:actions.continue'),
			})
			.then(() => {
				setAuthPartner(registeredPartner);
				navigate(RouteName.HOMEPAGE.DEFAULT);
			});

		loadingModalRef.current?.hide();
	}, [t, loadingModalRef, successModalRef, setError, navigate, setAuthPartner, finishRegistration]);
};
