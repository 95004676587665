import { useFormikContext } from 'formik';
import { useCallback } from 'react';

export const useFormikSetFieldValue = (name: string) => {
	const { setFieldTouched, setFieldValue } = useFormikContext();
	return useCallback(
		(value: unknown) => {
			setFieldValue(name, value, true);
			setTimeout(() => {
				setFieldTouched(name, true, true);
			});
		},
		[name, setFieldValue, setFieldTouched],
	);
};
