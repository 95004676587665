import React, { PureComponent } from 'react';
import type { TableProps } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { _DataTableTr } from 'js/components/molecules/DataTable/DataTableTr';
import classNames from 'classnames';
import { DataTableContextProvider, IDataTableContext } from 'js/components/molecules/DataTable/DataTableContext';

export type TDataTableProps = Omit<TableProps, 'variant'> &
	React.TableHTMLAttributes<HTMLTableElement> &
	IDataTableContext & {
		variant?: 'simple' | 'airy';
		contentWidth?: boolean;
	};

export class DataTable extends PureComponent<TDataTableProps> {
	static Tr = _DataTableTr;

	render(): React.ReactNode {
		const { children, className, contentWidth, variant = 'airy', isLoading, ...props } = this.props;
		const _className = classNames('table-detail-data', 'table-transparent', className, {
			[`is-${variant}`]: Boolean(variant),
			'content-width': Boolean(contentWidth),
		});

		return (
			<DataTableContextProvider isLoading={isLoading}>
				<Table
					{...props}
					className={_className}
				>
					<tbody>{children}</tbody>
				</Table>
			</DataTableContextProvider>
		);
	}
}
