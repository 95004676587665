import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import { FormikForm } from 'js/components/formik/FormikForm';
import { FormikControl } from 'js/components/formik/FormikControl';
import { RouteName } from 'module/RouteName';
import { securityConfig } from 'module/security/securityConfig';
import { ILoginFormData, ILoginPageState } from 'module/security';
import { SubmitButton } from 'js/components/atoms/Button';
import { useLogin } from 'module/security/hooks/useLogin';
import { useLogo } from 'js/layouts/components/HeaderLogo';
import { getSalesforceLoginLink } from 'module/security/utils/common';
import { SecurityFormLayout } from 'module/security/layouts';
import { CONFIG } from 'config';
import { Button } from '@avast/react-ui-components';
import { trim } from 'lodash';
import { useLocationState } from 'js/hooks/useLocationState';
import { NavigateLink } from 'types';
import { navigateLinkToNavigateObject } from 'js/utils/link';
import { useAuthContext } from 'js/contexts';

export const PageLogin = (): ReactElement => {
	const [t] = useTranslation(securityConfig.trNamespace);
	const navigate = useNavigate();
	const { isLogged, setAuthToken } = useAuthContext();
	const { authRedirectFrom, sessionExpired } = useLocationState<ILoginPageState>();
	const logoPartnerPortal = useLogo(true);

	// Messages
	const [formError, setFormError] = useState<string | null>(null);
	const [formInfo, setFormInfo] = useState<string | null>(sessionExpired ? 'state.sessionExpired' : null);

	// On login callback
	const onLogin = useCallback(
		(eventRedirectTo?: NavigateLink | null) => {
			const navigateObject = navigateLinkToNavigateObject(
				authRedirectFrom || eventRedirectTo || RouteName.HOMEPAGE.DEFAULT,
			);
			navigate(navigateObject.to, navigateObject.options);
		},
		[navigate, authRedirectFrom],
	);

	// Redirect on init
	useEffect(() => {
		if (isLogged) {
			onLogin();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Query mutation for login
	const login = useLogin({
		config: {
			onNonAuthorized() {
				setFormError('state.invalidCredentials');
			},
		},
	});

	return (
		<SecurityFormLayout
			title={t('page.login.title')}
			columns={2}
		>
			<div className="content">
				<div className="content-col pe-md-4 py-2">
					<Formik<ILoginFormData>
						initialValues={{ password: '', username: '' }}
						onSubmit={async (values, { setSubmitting }) => {
							setFormError(null);
							setFormInfo(null);

							const response = await login(values);
							if (response.success) {
								onLogin(response.redirectTo);
								return;
							}

							setSubmitting(false);
							setFormError(response.message);
						}}
					>
						<FormikForm testId="loginForm">
							{formError && (
								<Alert
									variant="danger"
									className="mb-3"
								>
									{t(formError)}
								</Alert>
							)}
							{formInfo && (
								<Alert
									variant="info"
									className="mb-3"
								>
									{t(formInfo)}
								</Alert>
							)}

							<div className="mb-3 text-muted fs-md text-center">
								<p className="mb-0">{t('commonText.loginForAvastOnly')}</p>
							</div>
							<div className="fields">
								<FormikControl>
									<FormikControl.Input
										name="username"
										size="lg"
										placeholder={t('form.email')}
									/>
								</FormikControl>
								<FormikControl>
									<FormikControl.Input
										type="password"
										size="lg"
										name="password"
										placeholder={t('form.password')}
									/>
								</FormikControl>
							</div>
							<div className="mt-4 d-grid">
								<SubmitButton>{t('form.submit')}</SubmitButton>
							</div>
							<div className="text-center mt-3">
								<Link
									to={RouteName.SECURITY.PASSWORD_RESET}
									className="link"
								>
									{t('common.forgetPassword')}
								</Link>
							</div>
						</FormikForm>
					</Formik>
				</div>
				<div className="separator" />
				<div className="content-col ps-md-4 py-2">
					<a
						href={getSalesforceLoginLink(authRedirectFrom?.to)}
						className="btn btn-white btn-partnerPortal btn-block"
					>
						<img
							src={logoPartnerPortal}
							alt={t('common.partnerPortal')}
						/>
						<span>{t('common.partnerPortal')}</span>
					</a>
					{CONFIG.ENV === 'locale' && (
						<Button
							variant="warning"
							className="mt-3 btn-block"
							style={{ height: '3rem' }}
							onClick={() => {
								// eslint-disable-next-line no-alert
								const token = prompt('Put new JWT token');
								if (token) {
									setAuthToken({ token: trim(token, '"') });
									setTimeout(() => window.location.reload(), 100);
								}
							}}
						>
							Login by JWT token
						</Button>
					)}
				</div>
			</div>
		</SecurityFormLayout>
	);
};
