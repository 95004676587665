import { Dropdown } from '@avast/react-ui-components';
import { SelectCustomerModal } from 'module/customers/components';
import React from 'react';
import { IEntityLicense } from 'module/licenses';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { useRegisterCustomerToLicense } from 'module/licenses/hooks';
import { useAuthContext } from 'js/contexts';

export const RegisterLicenseDropdownItem = ({ license }: { license: IEntityLicense }) => {
	const [t] = useTranslation(licensesConfig.trNamespace);
	const { authCompanyId, isGroupInternal } = useAuthContext();
	const { registerCustomerToLicense, isEnabled } = useRegisterCustomerToLicense(license);

	return isEnabled ? (
		<SelectCustomerModal
			onChange={registerCustomerToLicense}
			partnerId={isGroupInternal ? null : authCompanyId}
			disallowPartnerCustomers
		>
			{({ setModalShow }) => (
				<Dropdown.Item
					onClick={() => setModalShow(true)}
					testId="registerLicenseTo"
				>
					{t('actions.registerTo')}
				</Dropdown.Item>
			)}
		</SelectCustomerModal>
	) : null;
};
