import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum PaymentTypeEnum {
	WIRE_TRANSFER = 'Wire Transfer',
	WTOC = 'WTOC',
	CART_SELECTED = 'Cart Selected',
	CLOSING_BALANCE = 'Closing Balance',
	LOC = 'LOC',
	AUTO_BILLING = 'Auto Billing',
}

export const paymentTypeEnumUtils = generateEnumUtils<PaymentTypeEnum>(PaymentTypeEnum, {
	translatePath: 'enums:payment.type',
});
