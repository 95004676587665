export default {
	actions: {
		generate: 'Produktcodes generieren',
		downloadLicenses: 'Download – Lizenzen anfordern',
		refreshListItems: 'Elemente aktualisieren',
	},
	page: {
		list: 'Massen-ID-Suche',
		licenses: 'Produktcode-Suche',
	},
	entity: {
		bulkId: 'Massen-ID',
		transactionDate: 'Anfragedatum',
	},
	filter: {
		bulkId: {
			placeholder: 'Massen-ID eingeben und gehen zu...',
		},
	},
	generate: {
		submit: 'Anfrage senden',
		submitLoading: 'Anfrage wird gespeichert',
		success: 'Anfrage wurde erfolgreich erstellt',
		partner: {
			headline: 'Partner',
			select: 'Partner wählen',
			change: 'Partner ändern',
			placeholder: 'Einzelhandelspartner',
		},
		cart: {
			headline: 'Warenkorb',
			add: 'Produkte hinzufügen',
			removeTooltip: 'Dieses Element aus dem Einkaufswagen entfernen',
			placeholder: 'Produkte hinzufügen in den Warenkorb',
		},
		products: {
			headline: 'Produkte wählen',
			addAndClose: 'Hinzufügen und schließen',
			success: 'Produkte wurden in den Warenkorb gelegt',
		},
	},
};
