import { DateTime } from 'luxon';
import { TEnv } from 'types/config';

export type TFeatureFlagId = 'NEW_TERMS_AND_CONDITIONS';

interface IFeatureFlag {
	env: TEnv[] | null;
	isDisabled(): boolean;
}

const config: Record<TFeatureFlagId, IFeatureFlag> = {
	NEW_TERMS_AND_CONDITIONS: {
		env: ['production'],
		isDisabled(): boolean {
			const dateTime = '20.03.2024 00:00';
			const disableFrom = DateTime.fromFormat(dateTime, 'dd.MM.yyyy HH:mm');

			return disableFrom.diffNow().milliseconds < 0;
		},
	},
};

export const getFeatureFlag = (id: TFeatureFlagId) => {
	return config[id];
};
