import React from 'react';
import { TWidgetGridRow } from 'submodule/widgets';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import { WidgetRowContainer } from 'submodule/widgets/components/WidgetRowContainer';

type TWidgetPlaceholderRowProps = {
	onDragEnd: (dropResult: DropResult) => void;
	rows: TWidgetGridRow[];
};

export const WidgetRowsContainer = ({ onDragEnd, rows = [] }: TWidgetPlaceholderRowProps) => (
	<DragDropContext onDragEnd={onDragEnd}>
		{rows.map((row, rowIndex) => (
			<WidgetRowContainer
				key={`${rowIndex}-${row.length}`}
				row={row}
				rowIndex={rowIndex}
			/>
		))}
	</DragDropContext>
);
