import { homepageRouteName as HOMEPAGE } from './homepage/RouteName';
import { licensesRouteName as LICENSE } from './licenses/RouteName';
import { orderRouteName as ORDER, quoteRouteName as QUOTE } from './orders/RouteName';
import { securityRouteName as SECURITY } from './security/RouteName';
import { purchaseRouteName as PURCHASE } from './purchase/RouteName';
import { distributionPartnersRouteName as DISTRIBUTION_PARTNERS } from './distributionPartners/RouteName';
import { creditStatusRouteName as CREDIT_STATUS } from './creditStatus/RouteName';
import { creditLimitRouteName as CREDIT_LIMIT } from './creditLimit/RouteName';
import { promotionsRouteName as PROMOTIONS } from './promotions/RouteName';
import { closingBalanceRouteName as CLOSING_BALANCE } from './closingBalance/RouteName';
import { customersRouteName as CUSTOMERS } from './customers/RouteName';
import { accountRouteName as ACCOUNT } from 'module/account/RouteName';
import { registrationRouteName as REGISTRATION } from 'module/registration/RouteName';
import { retailRouteName as RETAIL } from './retail/RouteName';
import { priceCalculatorRouteName as PRICE_CALCULATOR } from './priceCalculator/RouteName';
import { flexibleBillingRouteName } from './flexibleBilling/RouteName';
import { default as ERROR } from './error/RouteName';

export const RouteName = {
	HOMEPAGE,
	LICENSE,
	ORDER,
	QUOTE,
	SECURITY,
	PURCHASE,
	DISTRIBUTION_PARTNERS,
	CREDIT_LIMIT,
	CREDIT_STATUS,
	PROMOTIONS,
	CLOSING_BALANCE,
	CUSTOMERS,
	ACCOUNT,
	REGISTRATION,
	RETAIL,
	PRICE_CALCULATOR,
	FLEXIBLE_BILLING: flexibleBillingRouteName(),
	ERROR,
};
