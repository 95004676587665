import { IEntityProductGroup } from 'module/purchase';
import { hasProductGroupProducts, isConsumerProductGroup } from 'module/purchase/utils/common';

export const filterPosaProductGroups = (productGroups: IEntityProductGroup[]) =>
	productGroups.map(getGroupsWithPosaProducts).filter(hasProductGroupProducts);

const getGroupsWithPosaProducts = (group: IEntityProductGroup) => ({
	...group,
	products: group.products.filter((product) => Boolean(product.isPosaEnabled)),
});

export const isRetailProductGroup = (group: IEntityProductGroup) =>
	isConsumerProductGroup(group) && group.firstPurchaseEnabled;

export const exportedForTesting = {
	getGroupsWithPosaProducts,
};
