export default {
	table: {
		noData: 'Aucune donnée disponible pour l’instant.',
		error: 'Une erreur s’est produite lors de la récupération des données.',
		noDataFilter: 'Pas de données pour le filtre',
		retry: 'Réessayer d’envoyer la demande',
		perPage: 'par page',
		pagination: {
			total: 'Affichage des dossiers <strong>{{from}} à <0>{{to}}</0></strong> sur <0>{{total}}</0>.',
		},
	},
	datepicker: {
		goToToday: 'Accéder au mois en cours',
		rangeDivider: 'à',
		startDate: 'Date de début',
		endDate: 'Date de fin',
		rangeSelector: {
			today: 'Aujourd’hui',
			thisWeek: 'Cette semaine',
			thisMonth: 'Ce mois-ci',
			lastWeek: 'Semaine dernière',
			lastMonth: 'Mois dernier',
			thisYear: 'Cette année',
			lastYear: 'Année dernière',
			customRange: 'Période personnalisée',
		},
		relative: {
			single: {
				today: 'Aujourd’hui',
				tomorrow: 'Demain',
				yesterday: 'Hier',
			},
			range: {
				thisWeek: 'Cette semaine',
				lastWeek: 'Semaine dernière',
				thisMonth: 'Ce mois-ci',
				lastMonth: 'Mois dernier',
				thisYear: 'Cette année',
				lastYear: 'Année dernière',
			},
		},
	},
	searchComboBox: {
		value: {
			placeholder: 'Saisissez une valeur...',
		},
	},
	notes: {
		noContent: 'Aucune note disponible',
	},
	hgop: {
		shoppingCart: {
			title: 'Panier',
		},
	},
	chargeOrderDocuments: {
		entity: {
			documentType: 'Type de document',
		},
		type: {
			payment_instructions: 'Instructions de paiement',
			invoice: 'Facture',
			vat_invoice: 'Facture',
			credit_memo: 'Note de crédit',
			payg_price_breakdown_msp: 'Facturation flexible - Rapport du partenaire',
			payg_product_usage_report: 'Facturation flexible - Rapport du client',
			payg_reports_all: 'Facturation flexible - Rapports liés à l’utilisation des produits (Tous)',
		},
	},
	export: {
		caption: 'Exportation en cours. Ce processus peut prendre quelques minutes.',
		autoDownload: 'Le fichier sera téléchargé automatiquement.',
		downloadError: 'Impossible de télécharger le fichier.',
		error: 'L’exportation a échoué. Veuillez réessayer plus tard.',
	},
	common: {
		more: 'Voir plus',
		apply: 'Appliquer',
	},
	select: {
		placeholder: 'Sélectionner une option',
	},
	pagination: {
		prev: 'Préc.',
		next: 'Suivant',
		page: 'Page',
		of: 'sur',
		miniText: 'Page {{active}} sur {{total}}',
	},
	modal: {
		cancel: 'Annuler',
		submit: 'Envoyer',
		apply: 'Appliquer',
	},
	rangeDatepicker: {
		placeholder: 'Sélectionner une date',
	},
	singleDatepicker: {
		placeholder: 'Sélectionner une date',
	},
	toast: {
		title: {
			success: 'Succès',
			error: 'Erreur',
			info: 'Info',
			default: 'Info',
			dark: 'Info',
			warning: 'Avertissement',
		},
	},
	fileUpload: {
		placeholder: '<strong>Sélectionnez</strong> ou faites glisser un fichier ici',
	},
};
