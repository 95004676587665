export default {
	common: {
		continue: 'Registrierung fortsetzen',
		skip: 'Vorläufig überspringen',
		submitting: 'Ihre Daten werden eingereicht...',
		finishing: 'Wir sind dabei, Ihre Registrierung abzuschließen...',
		successRegistration: 'Sie wurden erfolgreich registriert',
		successAcceptTerms: 'Die Allgemeinen Geschäftsbedingungen wurden akzeptiert. Vielen Dank!',
	},
	steps: {
		init: 'Willkommen',
		terms: 'Allgemeine Geschäftsbedingungen',
		registrationInfo: 'Wichtige Informationen',
		taxExemption: 'Steuerbefreiung',
	},
	page: {
		welcome: {
			title: 'Willkommen beim Registrierungsprozess für E-Commerce',
			content: 'Sie werden durch die für die Registrierung erforderlichen Schritte geführt und aufgefordert:',
			conditionList: [
				'die Allgemeinen Geschäftsbedingungen zu akzeptieren',
				'Ihre wichtigen Profilinformationen zu aktualisieren und zu bestätigen',
			],
		},
		terms: {
			title: 'Bitte lesen Sie unsere Allgemeinen Geschäftsbedingungen und stimmen Sie ihnen zu:',
			button: 'Ich habe die Vereinbarung(en) gelesen und akzeptiere die Bedingungen',
		},
		registrationInfo: {
			contactInformation: 'Kontaktinformationen',
			accountInformation: 'Kontoinformationen',
		},
		taxExemption: {
			title: 'Bitte reichen Sie Ihre Angaben zur Steuerbefreiung ein',
			subtitle: 'Bitte tragen Sie die angeforderten Informationen zu Ihrer Steuerbefreiung ein.',
		},
	},
	error: {
		unableToInit: 'Die Registrierung kann nicht initiiert werden.',
		unableToRegistrationToken: 'Registrierungs-Token kann nicht abgerufen werden.',
		unableToStart: 'Die Registrierung kann nicht gestartet werden.',
		unableToLoadTerms:
			'Allgemeine Geschäftsbedingungen können nicht geladen werden. Wenden Sie sich zur Unterstützung an Ihren Account Manager.',
		unableToAcceptTerms: 'Die Bedingungen können für den derzeitigen Partner nicht akzeptiert werden.',
		unableToProcess: 'Wir sind derzeit nicht in der Lage, Ihre Daten zur Verarbeitung zu übermitteln.',
	},
	alert: {
		checkCurrentAccountInfo:
			'Wir haben die derzeit für Ihr Konto in unseren Systemen gespeicherten Informationen eingetragen. Bitte prüfen Sie diese Angaben und reichen Sie Aktualisierungen ein, wo dies nötig ist. Jede eingereichte Änderung wird zur zukünftigen Verwendung aufgezeichnet.',
		changeAccountDataInPartnerPortal:
			'Falls Sie Ihre Kontaktinformationen ändern müssen, schließen Sie bitte den Registrierungsprozess ab und besuchen Sie den Bereich "Mein Profil" im Avast Business Partnerportal, um Ihre Profilinformationen zu bearbeiten.',
	},
};
