import React, { ReactElement, useCallback, useState } from 'react';
import { Toast, UiTable } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { IEntityProductGroup } from 'module/purchase';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import {
	SelectProductsFilter,
	TSelectProductFilter,
} from 'module/purchase/components/selectProducts/SelectProductsFilter';
import { useFormikContext } from 'formik';
import { IGenerateRetailCodesForm } from 'module/retail';
import { useRetailProductGroupList } from 'module/retail/hooks/useRetailProductGroupList';
import { STATUS_SUCCESS } from 'appConstants';
import { TSelectProductVariantTableProps } from 'module/retail/components/generate/SelectProductVariantTable';
import { SelectProductVariantModal } from 'module/retail/components/generate/SelectProductVariantModal';
import { toast } from 'react-toastify';
import { retailConfig } from 'module/retail/retailConfig';
import { extractProductGroupCode } from 'module/purchase/utils/common';
import { useSelectProductsColumns } from 'module/retail/hooks';

const STATUS_SELECT_VARIANT_CLOSE = 2;

type TTableData = IEntityProductGroup;
type TSelectProductsProps = {
	onAddItem: (close: boolean) => void;
};

export const SelectProducts = (props: TSelectProductsProps): ReactElement => {
	const { onAddItem } = props;
	const {
		values: { partner },
		setValues,
	} = useFormikContext<IGenerateRetailCodesForm>();
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const [filter, setFilter] = useState<TSelectProductFilter>({
		searchValue: '',
		entity: {},
	});
	const selectVariantRef = useAsyncModalRef<TSelectProductVariantTableProps>();

	// Get groups
	const { data: productGroups, query } = useRetailProductGroupList(partner);

	// On Select product group action
	const onSelectProductGroup = useCallback(
		async (productGroup: IEntityProductGroup) => {
			const response = await selectVariantRef.current?.show({ productGroup });
			if (response) {
				onAddItem(response === STATUS_SELECT_VARIANT_CLOSE);
			}
		},
		[selectVariantRef, onAddItem],
	);

	// Columns
	const columns = useSelectProductsColumns(onSelectProductGroup);

	return (
		<>
			<SelectProductsFilter
				filter={filter}
				onChange={setFilter}
			/>

			<UiTable<TTableData>
				testId="products"
				data={productGroups}
				columns={columns}
				enableSorting
				initialState={{ sorting: [{ id: 'name', desc: false }] }}
				state={{ globalFilter: extractProductGroupCode(filter.searchValue) || filter.searchValue }}
				onGlobalFilterChange={(searchValue: string) => setFilter((values) => ({ ...values, searchValue }))}
				meta={{
					customError: query.isError,
					isScrollable: true,
					loading: query.isFetching,
					loadingType: 'BAR',
					onRowClick: onSelectProductGroup,
				}}
			/>

			<AsyncModal
				backdrop="static"
				ref={selectVariantRef}
				testId="selectVariant"
			>
				{({ productGroup }) => (
					<SelectProductVariantModal
						productGroup={productGroup}
						onSelect={(variants, close) => {
							setValues((values) => ({
								...values,
								products: [
									...values.products,
									...variants.map((item) => ({
										quantity: item.quantity!,
										product: item.product,
									})),
								],
							}));
							toast.success(<Toast>{t(retailConfig.trPrefix('generate.products.success'))}</Toast>);
							selectVariantRef.current?.onSuccess(close ? STATUS_SELECT_VARIANT_CLOSE : STATUS_SUCCESS);
						}}
					/>
				)}
			</AsyncModal>
		</>
	);
};
