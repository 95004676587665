import React from 'react';
import { Button } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { IPromotionListFilter } from 'module/promotions';
import { useDataFilterContext } from 'js/components/molecules/DataFilter';
import { ButtonTextBadge } from 'js/components/atoms/Button';
import { promotionExtraFilterNormalizer } from 'module/promotions/normalizer';

type TExtraFiltersButtonProps = {
	onClick(): void;
};

export const ExtraFiltersButton = (props: TExtraFiltersButtonProps) => {
	const [t] = useTranslation(promotionsConfig.trNamespace);
	const { values } = useDataFilterContext<IPromotionListFilter>();
	const extraFiltersCount = promotionExtraFilterNormalizer.count(values);

	return (
		<Button
			size="sm"
			onClick={props.onClick}
			variant="outline-primary"
		>
			<ButtonTextBadge count={extraFiltersCount}>{t('actions.extraFilters')}</ButtonTextBadge>
		</Button>
	);
};
