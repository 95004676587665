import React from 'react';
import type { TUiTableRow } from '@avast/react-ui-components';
import type { TColumnFormatter } from '@tanstack/react-table';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Copy2ClipboardIcon } from 'js/components/molecules/Copy2Clipboard/Copy2ClipboardIcon';

export type TLinkOptions<D extends {}> = Omit<LinkProps, 'to'> & {
	to: (row: D) => string;
	conditionalProps?: (row: D) => Partial<LinkProps>;
	copy?: boolean;
};

export const link =
	<Row extends TUiTableRow>(options: TLinkOptions<Row>): TColumnFormatter<Row> =>
	// eslint-disable-next-line react/display-name
	(value, cell) => {
		const { to, conditionalProps, copy, className, ...props } = options;
		return (
			<div className="d-flex">
				{copy ? <Copy2ClipboardIcon value={cell.getValue()} /> : null}
				<Link
					{...props}
					{...conditionalProps?.(cell.row.original)}
					className={classNames({ 'ms-1': copy }, className)}
					to={to(cell.row.original)}
				>
					{value}
				</Link>
			</div>
		);
	};
