import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { useFormikContext } from 'formik';
import { ILicenseOperationForm } from 'module/licenses';
import { resolvePricingCustomerSegment } from 'module/purchase/hooks/pricing/pricingUtils';
import { useLicenseOperationUnitTotal } from 'module/licenses/hooks/licenseOperation/useLicenseOperationUnitTotal';
import { IEntityProduct, IEntityProductGroup, IPricingRequestItem } from 'module/purchase';
import { isGroupBusiness } from 'module/purchase/utils/selectors';
import {
	isDecreaseOperation,
	isIncreaseOperation,
	isRenewOperation,
	isUpgradeOperation,
	normalizeLicenseOperationKey,
} from 'module/licenses/utils/licenseOperation';
import { dateToPricingDate } from 'module/licenses/utils/common';
import { licenseOperationEnumUtils } from 'module/licenses/enums';
import { orderInstanceNormalizer } from 'module/purchase/normalizer';

export const useLicenseOperationPricingItem = () => {
	const { license, customer } = useLicenseOperationContext();
	const { values } = useFormikContext<ILicenseOperationForm>();
	const { licenseOperation, unit, unitToIncrease, unitToDecrease } = values;
	const customerSegment = resolvePricingCustomerSegment(customer);
	const unitTotal = useLicenseOperationUnitTotal();

	return (productGroup: IEntityProductGroup, product: IEntityProduct): IPricingRequestItem => {
		const isBusiness = isGroupBusiness(productGroup);
		const orderProduct = orderInstanceNormalizer.normalizeProduct(productGroup, product);
		const operation = normalizeLicenseOperationKey(licenseOperation!, { isBusiness });

		const item: IPricingRequestItem = {
			reference_lineitem_id: product.sku,
			product: orderProduct.sku,
			customerSegment,
			lineTransactionType: licenseOperationEnumUtils.getTransactionType(operation),
			unitsToPrice: unitTotal,
			unitsForTier: unitTotal,
			unitSeats: unit,
			unitsQuantityTotal: unitTotal,
			unitsToIncrease: 0,
			licenseExpireDate: dateToPricingDate(license.expiration),
			licenseCreateDate: dateToPricingDate(license.createdAt),
			customPricingInfo: {
				quantity: unitTotal,
				entitlementId: license.id,
				customerId: customer?.id,
			},
		};

		if (isUpgradeOperation(operation)) {
			item.upgradeFromProductID = license.computedValues.productSku;
		}
		if (isRenewOperation(operation)) {
			item.renewTo = orderProduct.sku;
			item.renewalDate = dateToPricingDate(new Date());
		}
		if (isBusiness) {
			if (isIncreaseOperation(operation)) {
				item.unitsToIncrease = unitToIncrease;
			}
			if (isDecreaseOperation(operation)) {
				item.unitsToIncrease = unitToDecrease;
			}
		}

		return item;
	};
};
