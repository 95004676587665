import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { Tooltip } from '@avast/react-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as iconTooltip } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import classNames from 'classnames';

type TUnlimitedValueProps = {
	tooltip?: boolean;
	valueClassName?: string;
};

export const UnlimitedValue = (props: TUnlimitedValueProps) => {
	const [t] = useTranslation(flexibleBillingConfig.trNamespace);
	const value = t('common.unlimited');

	if (!props.tooltip) {
		return <span className={classNames(props.valueClassName)}>{value}</span>;
	}

	return (
		<div className="d-inline-flex align-items-center">
			<span className={classNames(props.valueClassName)}>{value}</span>
			<Tooltip
				content={t(`tooltips.unlimitedMaxSpend`)}
				placement="bottom"
			>
				<FontAwesomeIcon
					className="tooltip-icon fs-md text-warning ms-1"
					icon={iconTooltip}
				/>
			</Tooltip>
		</div>
	);
};
