import React from 'react';
import type { TColumnFormatter } from '@tanstack/table-core';
import { linkButton, TLinkButtonOptions } from 'js/components/table/formatters/linkButton';
import type { TButtonOptions, TDropdownOptions, TUiTableRow } from '@avast/react-ui-components';
import { button, ButtonComposition, dropdown } from '@avast/react-ui-components';

export type TActionsOptions<Row extends TUiTableRow> = {
	link?: TLinkButtonOptions<Row>;
	iconLink?: TLinkButtonOptions<Row> & { icon: TLinkButtonOptions<Row>['iconLeftFa'] };
	button?: TButtonOptions<Row>;
	dropdown?: TDropdownOptions<Row>;
};

export const actions =
	<Row extends TUiTableRow>(options: TActionsOptions<Row>): TColumnFormatter<Row> =>
	// eslint-disable-next-line react/display-name
	(_, cell) => (
		<ButtonComposition
			size="xs"
			marginY={false}
			wrap={false}
			justify="end"
		>
			{options.link && linkButton<Row>(options.link)(null, cell)}
			{options.iconLink &&
				linkButton<Row>({
					...options.iconLink,
					iconLeftFa: options.iconLink.icon,
				})(null, cell)}
			{options.button && button<Row>(options.button)(null, cell)}
			{options.dropdown && dropdown<Row>(options.dropdown)(null, cell)}
		</ButtonComposition>
	);
