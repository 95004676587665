export default {
	accountInformation: 'Informations du compte',
	_: {
		dateRangePlaceholder: 'Sélectionner une date',
		NA: 'N/A',
		no: 'Non',
		pageDetailTitle: 'N° de détail : {{id}}',
		yes: 'Oui',
		loading: 'Chargement...',
	},
	boolean: {
		true: 'Oui',
		false: 'Non',
	},
	entity: {
		active: 'Active',
		amount: 'Montant',
		company: 'Entreprise',
		companyId: 'ID d’entreprise',
		companyName: 'Nom de la société',
		created: 'Créée',
		currency: 'Devise',
		customer: 'Client',
		customerTotalPrice: 'Client Prix total',
		detailId: 'ID de détail',
		description: 'Description',
		disabled: 'Désactivée',
		discountAmount: 'Montant de la remise',
		discountAmountUnit: 'Montant de la remise unitaire',
		discountPercent: 'Pourcentage de remise',
		discountType: 'Type de remise',
		discountUnitPrice: 'Prix unitaire de remise',
		distributionPartner: 'Partenaire de distribution',
		enabled: 'Activée',
		id: 'ID',
		licenseKey: 'Clé de licence',
		name: 'Nom',
		orderStatus: 'État de la commande',
		partnerId: 'ID de partenaire',
		partner: 'Partenaire',
		paymentAmount: 'Montant du paiement',
		paymentMethod: 'Mode de paiement',
		paymentStatus: 'État du paiement',
		poNumber: 'Numéro de BC',
		price: 'Prix',
		priceWTax: 'Prix TTC',
		priceWOTax: 'Prix HT',
		product: 'Produit',
		productName: 'Nom du produit',
		quantity: 'Quantité',
		requestedDiscountType: 'Type de remise demandé',
		secondaryOrderId: 'ID de la commande secondaire',
		sku: 'SKU',
		srpUnitPrice: 'Prix unitaire conseillé',
		state: 'État',
		status: 'État',
		subTotalWOTax: 'Sous-total HT',
		tax: 'Taxe',
		total: 'Total',
		totalAmount: 'Montant total',
		totalAmountWOTax: 'Montant total HT',
		totalAmountWTax: 'Montant total TTC',
		totalPrice: 'Prix total',
		totalTax: 'Taxe totale',
		totalWTax: 'Total TTC',
		transaction: 'Transaction',
		unit: 'Unité',
		unit_other: 'Unités',
		unitPrice: 'Prix unitaire',
		unitType: {
			SEATS: 'Utilisateur',
			SEATS_other: 'Utilisateurs',
			SESSIONS: 'Session',
			SESSIONS_other: 'Sessions',
			STORAGE: 'Stockage',
			STORAGE_other: 'Stockage',
			DEVICE: 'Appareil',
			DEVICE_other: 'Appareils',
		},
		validity: 'Validité',
		variantCount: '{{count}} variante',
		variantCount_other: '{{count}} variantes',
	},
	contact: {
		firstName: 'Prénom',
		lastName: 'Nom',
		name: 'Nom du contact',
		email: 'E-mail',
		billingEmail: 'Adresse e-mail de facturation',
		phone: 'Téléphone',
		website: 'Site Web',
		id: 'ID du contact',
		taxId: 'N° fiscal',
		secondaryTaxId: 'N° fiscal secondaire',
	},
	address: {
		caption: 'Adresse',
		city: 'Ville',
		country: 'Pays',
		state: 'État/Région',
		postalCode: 'Code postal',
		street: 'Rue',
	},
	dates: {
		billing: 'Date de facturation',
		created: 'Date de création',
		end: 'Date de fin',
		expire: 'Date d’expiration',
		payment: 'Date du paiement',
		paymentDue: 'Date d’échéance du paiement',
		start: 'Date de début',
	},
	datePeriod: {
		DAY: '{{count}} jour',
		DAY_other: '{{count}} jours',
		MONTH: '{{count}} mois',
		MONTH_other: '{{count}} mois',
		YEAR: '{{count}} an',
		YEAR_other: '{{count}} ans',
	},
	actions: {
		back: 'Retour',
		newPurchase: 'Nouvel achat',
		cancel: 'Annuler',
		close: 'Fermer',
		confirm: 'Confirmer',
		continue: 'Continuer',
		detail: 'Afficher les détails',
		download: 'Télécharger',
		emptyCart: 'Vider le panier',
		logout: 'Déconnexion',
		next: 'Suivant',
		previous: 'Retour',
		reset: 'Réinitialiser',
		retry: 'Réessayer',
		removeFile: 'Retirer le fichier',
		save: 'Enregistrer',
		select: 'Sélectionner',
		submitForm: 'Envoyer',
		submitChanges: 'Soumettre des modifications',
		show: 'Afficher',
		showAll: 'Afficher tout',
		showMore: 'Afficher plus',
		showLess: 'Afficher moins',
		copied: 'Copie réussie',
	},
	validator: {
		email: 'La valeur doit être une adresse e-mail valide.',
	},
	table: {
		useFilter: 'Utilisez un filtre pour commencer la recherche',
	},
	filter: {
		quickFilters: 'Filtres rapides',
		partnerIsRequired: 'Sélectionnez un partenaire pour lancer une recherche.',
		typeAtLeastNCharacters: 'Saisissez au moins {{count}} caractères dans le filtre.',
	},
	header: {
		cart: 'Panier',
		cartIsEmpty: 'Le panier est vide',
	},
	productGroupExceptions: {
		premiumRemoteControlQuantityDisabled:
			'Comme le Contrôle à distance premium peut être installé sur un nombre illimité d’appareils, vous n’êtes pas obligé de changer de session pour ces éléments. En effet, les sessions simultanées sont basées sur le nombre d’appareils auxquels vous pensez pouvoir vous connecter en même temps.',
		cloudBackupStorageInfo:
			'En modifiant l’attribut Stockage, vous définissez le nombre de fois que vous souhaitez acheter un espace de stockage de 100 Go. Par exemple, “Stockage = 5” signifie que le stockage final acheté sera de 500 Go pour une licence.',
	},
	downloadFile: {
		loading: 'Téléchargement du fichier {{filename}}',
		error: 'Impossible de télécharger le fichier',
	},
	appVersionGuard: {
		caption: 'Une nouvelle version du système de gestion des commandes est disponible !',
		message: 'Rechargez la page pour obtenir la dernière version.',
		action: 'Recharger',
	},
};
