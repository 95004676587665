import React, { ReactElement, useMemo } from 'react';
import { useAuthContext, usePageDetailContext } from 'js/contexts';
import { FlexibleBillingEditDetailActions } from 'module/flexibleBilling/components/actions/FlexibleBillingEditDetailActions';
import { EditOverviewTableActions } from 'module/flexibleBilling/components/actions/EditOverviewTableActions';
import { FormikForm } from 'js/components/formik/FormikForm';
import { OverviewEditTable } from 'module/flexibleBilling/components/table/OverviewEditTable';
import { useApiFlexibleBillingDetail, useFlexibleBillingSubmitHandler } from 'module/flexibleBilling/hooks';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { DetailHeadline } from 'module/flexibleBilling/components/DetailHeadline';
import { Formik } from 'formik';
import {
	FlexibleBillingDetailMenu,
	FlexibleBillingFormInfo,
	InviteToFlexibleBillingInfo,
	UpdateSpendingOverview,
} from 'module/flexibleBilling/components';
import {
	IApiFlexibleBillingDetailFilter,
	IFlexibleBillingDistributionPartner,
	TFlexibleBillingForm,
} from 'module/flexibleBilling';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { useNavigate } from 'react-router-dom';

export const PageUpdate = (): ReactElement => {
	const { authCompany, authCompanyId } = useAuthContext();
	const currency = authCompany?.currencyCode;
	const navigate = useNavigate();
	const {
		data: { distributionPartner },
	} = usePageDetailContext<IFlexibleBillingDistributionPartner>();

	const queryProps = useMemo<IApiFlexibleBillingDetailFilter>(
		() => ({
			id: distributionPartner.id!,
			distributorId: authCompanyId,
		}),
		[distributionPartner, authCompanyId],
	);

	const submit = useFlexibleBillingSubmitHandler(queryProps);

	const {
		data: flexibleBillingData,
		query: { isFetching },
	} = useApiFlexibleBillingDetail({
		filter: queryProps,
	});

	return (
		<Formik<TFlexibleBillingForm>
			initialValues={flexibleBillingData || []}
			enableReinitialize
			onSubmit={async (values, formikHelpers) => {
				const response = await submit(values);
				if (response) {
					navigate(flexibleBillingConfig.detailLinkById(queryProps.id));
				} else {
					formikHelpers.setSubmitting(false);
				}
			}}
		>
			<FormikForm testId="edit-flexible-billing">
				<DetailHeadline rightContent={<FlexibleBillingEditDetailActions isLoading={isFetching} />} />
				<FlexibleBillingDetailMenu />
				<DefaultContainer>
					<InviteToFlexibleBillingInfo />
					<EditOverviewTableActions
						isLoading={isFetching}
						className="mb-3"
					/>
					<OverviewEditTable
						currency={currency || ''}
						isLoading={isFetching}
					/>
					<UpdateSpendingOverview
						className="mt-4"
						currency={currency}
						isLoading={isFetching}
					/>
					<FlexibleBillingFormInfo />
					<FlexibleBillingEditDetailActions
						isLoading={isFetching}
						className="mt-5"
					/>
				</DefaultContainer>
			</FormikForm>
		</Formik>
	);
};
