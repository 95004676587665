import {
	IStandardOrderInstance,
	IStandardOrderInstanceCustomPrice,
	IStandardOrderInstanceItem,
	TStandardOrderInstanceAddItem,
} from 'module/purchase';
import { IEntityPartnerDetail } from 'module/partners';
import { IEntityDistributionPartner } from 'module/distributionPartners';
import { IEntityCustomer } from 'module/customers';
import { Reducer } from 'react';
import { isEqual, omit } from 'lodash';
import { getPartnerGroups } from 'module/partners/utils/partnerSelectors';
import { TLicenseOperationPartyResolution } from 'module/licenses/components/licenseOperation/SelectPartyModal';
import { PRICE_LIST_CODE_DEFAULT } from 'js/priceList/priceListConfig';
import { TPartnerPriceLists, TPriceListCode } from 'js/priceList';
import { getDefaultPriceListCode, isPriceListCodeInPriceLists } from 'js/priceList/priceListUtils';
import { isDefined } from 'js/utils/common';
import { logError } from 'js/utils/app';

export interface IOrderReducerSetPartnerPayload {
	partner: IEntityPartnerDetail;
	partnerPriceLists: TPartnerPriceLists;
}

const orderContextResetState: Pick<IStandardOrderInstance, 'pricing' | 'action' | 'customPrices'> = {
	pricing: null,
	action: null,
	customPrices: [],
};

export const getOrderContextInitialState = (): IStandardOrderInstance => ({
	timestamp: Date.now(),
	isReady: false,
	isLocked: false,
	items: [],
	seqId: 0,
	additionalInfo: {},
	discretionaryDiscount: undefined,
	priceListCode: PRICE_LIST_CODE_DEFAULT,
	...orderContextResetState,
});

export type TOrderStateAction =
	| { type: 'SET'; payload: IStandardOrderInstance }
	| { type: 'INIT'; payload: IStandardOrderInstance | null }
	| { type: 'RESET' }
	| { type: 'SET_AUTH_PARTNER_ID'; payload: IStandardOrderInstance['authPartnerId'] }
	| { type: 'REMOVE_PARTNER' }
	| { type: 'SET_PARTNER'; payload: IOrderReducerSetPartnerPayload }
	| { type: 'SET_CUSTOMER'; payload: IEntityCustomer | null }
	| { type: 'SET_CUSTOMER_CURRENCY'; payload: IStandardOrderInstance['customerCurrencyCode'] }
	| { type: 'SET_DISTRIBUTION_PARTNER'; payload: IEntityDistributionPartner | null }
	| { type: 'ADD_ITEMS'; payload: { items: TStandardOrderInstanceAddItem[]; priceListCode: TPriceListCode } }
	| { type: 'REMOVE_ITEM'; payload: IStandardOrderInstanceItem['id'] }
	| { type: 'REMOVE_ALL_ITEMS' }
	| { type: 'SET_ADDITIONAL_DATA'; payload: IStandardOrderInstance['additionalInfo'] }
	| { type: 'UPDATE_ITEMS'; payload: Required<IStandardOrderInstance>['items'] }
	| { type: 'UPDATE_CUSTOMER_PRICES'; payload: IStandardOrderInstanceCustomPrice[] }
	| { type: 'UPDATE_CUSTOM_PRICES'; payload: IStandardOrderInstance['customPrices'] }
	| {
			type: 'UPDATE_PARTIES';
			payload: {
				partyResolution: TLicenseOperationPartyResolution;
				customerCurrencyCode: IStandardOrderInstance['customerCurrencyCode'];
			};
	  }
	| { type: 'SET_PRICING'; payload: IStandardOrderInstance['pricing'] }
	| { type: 'SET_DISCRETIONARY_DISCOUNT'; payload: IStandardOrderInstance['discretionaryDiscount'] }
	| { type: 'RESET_LINE_DISCRETIONARY_DISCOUNT' }
	| { type: 'SET_ACTION'; payload: IStandardOrderInstance['action'] };

/**
 * Reducer for active order instance
 *
 * @param {IStandardOrderInstance} state
 * @param {TOrderStateAction} action
 * @returns {IStandardOrderInstance}
 */
export const orderStateReducer: Reducer<IStandardOrderInstance, TOrderStateAction> = (
	state: IStandardOrderInstance,
	action: TOrderStateAction,
): IStandardOrderInstance => {
	let nextState: IStandardOrderInstance | null = null;
	let items: IStandardOrderInstanceItem[];
	let partyResolution: TLicenseOperationPartyResolution;
	let customerCurrencyCode: IStandardOrderInstance['customerCurrencyCode'];
	let resetDiscountFlag: boolean;

	switch (action.type) {
		case 'INIT':
			return { ...state, ...action.payload, isReady: true };
		case 'SET':
			if (!isEqual(action.payload, state) && state.timestamp < action.payload.timestamp) {
				return { ...action.payload };
			}
			return state;
		case 'REMOVE_PARTNER':
			nextState = {
				...state,
				...orderContextResetState,
				priceListCode: getOrderContextInitialState().priceListCode,
				partner: null,
				partnerPriceLists: null,
				// Reset Distribution Partner
				distributionPartner: null,
			};
			nextState = resetDiscounts(nextState);
			nextState.items = filterItems(state, nextState);
			break;
		case 'SET_PARTNER': {
			const { partner, partnerPriceLists } = action.payload;

			// Different partner
			// eslint-disable-next-line no-negated-condition
			if (partner.id !== state.partner?.id) {
				nextState = {
					...state,
					...orderContextResetState,
					partner,
					// Reset Distribution Partner
					distributionPartner: null,
				};
				nextState = resetDiscounts(nextState);
			}

			// Same - update partner data
			else {
				nextState = {
					...state,
					...(state.isLocked ? {} : orderContextResetState),
					partner,
				};
			}

			// Change price lists
			nextState.partnerPriceLists = partnerPriceLists;
			if (!isPriceListCodeInPriceLists(partnerPriceLists, state.priceListCode) || nextState.items.length === 0) {
				nextState.priceListCode = getDefaultPriceListCode(partnerPriceLists);
			}

			// Refresh items
			nextState.items = filterItems(state, nextState);
			break;
		}
		case 'SET_CUSTOMER':
			// eslint-disable-next-line no-negated-condition
			if (action.payload?.id !== state.customer?.id) {
				nextState = {
					...state,
					...orderContextResetState,
					customer: action.payload,
					customerCurrencyCode: undefined,
				};

				if (!action.payload && !state.partner) {
					nextState = resetDiscounts(nextState);
				}

				nextState.items = filterItems(state, nextState);
			} else {
				nextState = {
					...state,
					customer: action.payload,
				};
			}
			break;
		case 'SET_CUSTOMER_CURRENCY':
			if (state.customerCurrencyCode !== action.payload) {
				nextState = {
					...state,
					customerCurrencyCode: action.payload,
				};
			}
			break;
		case 'SET_DISTRIBUTION_PARTNER':
			nextState = {
				...state,
				distributionPartner: action.payload,
			};
			break;
		case 'SET_AUTH_PARTNER_ID':
			if (state.authPartnerId === action.payload) {
				return state;
			}
			nextState = { ...getOrderContextInitialState(), authPartnerId: action.payload, isReady: true };
			break;
		case 'RESET':
			nextState = { ...getOrderContextInitialState(), authPartnerId: state.authPartnerId, isReady: true };
			break;

		case 'ADD_ITEMS': {
			nextState = {
				...state,
				...orderContextResetState,
				priceListCode: action.payload.priceListCode,
			};
			let { seqId } = state;
			let items = state.items || [];

			// Clear items from another price list
			if (nextState.priceListCode !== state.priceListCode) {
				items = [];
			}

			action.payload.items.forEach((item) => {
				// Remove previous license operations
				if (isDefined(item.license)) {
					const { id } = item.license;
					items = items.filter((item) => item.license?.id !== id);
				}

				items.push({
					id: ++seqId,
					...item,
				});
			});

			nextState.seqId = seqId;
			nextState.items = items;
			break;
		}
		case 'REMOVE_ITEM':
			nextState = {
				...state,
				...orderContextResetState,
				items: [...(state.items || []).filter((item) => item.id !== action.payload)],
			};
			break;
		case 'REMOVE_ALL_ITEMS':
			nextState = {
				...state,
				...orderContextResetState,
				items: [],
			};
			break;
		case 'SET_ADDITIONAL_DATA':
			if (isEqual(state.additionalInfo, action.payload)) {
				return state;
			}

			nextState = {
				...state,
				additionalInfo: {
					...state.additionalInfo,
					...action.payload,
				},
			};
			break;
		case 'SET_DISCRETIONARY_DISCOUNT':
			if (state.discretionaryDiscount === action.payload) {
				return state;
			}

			nextState = {
				...state,
				...orderContextResetState,
				discretionaryDiscount: action.payload,
			};
			break;
		case 'RESET_LINE_DISCRETIONARY_DISCOUNT':
			if (state.isLocked) {
				return state;
			}

			nextState = {
				...state,
				...orderContextResetState,
				items: state.items.map((item) => omit({ ...item }, 'discretionaryDiscount')),
			};
			break;
		case 'UPDATE_ITEMS':
			items = action.payload.filter((item) => item.quantity && item.unit);

			if (isEqual(items, state.items)) {
				return state;
			}

			nextState = {
				...state,
				...orderContextResetState,
				items,
			};

			if (items.length === 0) {
				nextState = resetDiscounts(nextState);
			}
			break;
		case 'UPDATE_CUSTOMER_PRICES': {
			const items = state.items.map((item) => {
				const payload = action.payload.find((value) => value.id === item.id);
				if (payload) {
					item.savedCustomerPrice = payload?.unitPrice;
				}
				return item;
			});

			nextState = { ...state, items };
			break;
		}
		case 'UPDATE_CUSTOM_PRICES':
			nextState = {
				...state,
				customPrices: action.payload,
			};
			break;
		case 'SET_PRICING':
			nextState = {
				...state,
				pricing: action.payload,
			};
			break;
		case 'SET_ACTION':
			if (state.action !== action.payload) {
				nextState = {
					...state,
					action: action.payload,
				};
			}
			break;

		case 'UPDATE_PARTIES':
			partyResolution = action.payload.partyResolution;
			customerCurrencyCode = action.payload.customerCurrencyCode;

			nextState = {
				...state,
				...orderContextResetState,
				// Partner
				partner: partyResolution.partner,
				partnerPriceLists: partyResolution.partnerPriceLists,
				// Customer
				customer: partyResolution.customer,
				customerCurrencyCode:
					Boolean(partyResolution.customer) && !partyResolution.partner ? customerCurrencyCode : undefined,
				// Distribution Partner
				distributionPartner: partyResolution.distributionPartner,
			};

			// End customer
			if (!partyResolution.partner || !partyResolution.partnerPriceLists) {
				nextState.partnerPriceLists = null;
				nextState.priceListCode = PRICE_LIST_CODE_DEFAULT;
			}
			// Partner
			else if (!isPriceListCodeInPriceLists(partyResolution.partnerPriceLists, state.priceListCode)) {
				nextState.priceListCode = getDefaultPriceListCode(partyResolution.partnerPriceLists);
			}

			// Refresh items
			nextState.items = filterItems(state, nextState);

			// Reset discounts
			resetDiscountFlag = state.partner?.id !== nextState.partner?.id || state.customer?.id !== nextState.customer?.id;

			if (resetDiscountFlag) {
				nextState = resetDiscounts(nextState);
			}

			break;

		default:
			logError('Not supported action', action);
	}

	return nextState ? { ...nextState, timestamp: Date.now() } : state;
};

const resetDiscounts = (state: IStandardOrderInstance): IStandardOrderInstance => ({
	...state,
	discretionaryDiscount: undefined,
	items: state.items.map((item) => ({
		...item,
		discretionaryDiscount: undefined,
	})),
});

const filterItems = (
	state: IStandardOrderInstance,
	nextState: IStandardOrderInstance,
): IStandardOrderInstanceItem[] => {
	// Different price list -> clear items
	if (state.priceListCode !== nextState.priceListCode) {
		return [];
	}

	// Different partners
	if (state.partner?.id !== nextState.partner?.id) {
		const groups = getPartnerGroups(nextState.partner);
		return state.items.filter((item) => {
			if (item.product.isConsumer && groups.isConsumer) {
				return true;
			}
			return item.product.isBusiness && groups.isBusiness;
		});
	}

	// No billable party
	if (!nextState.partner && !nextState.customer) {
		return [];
	}

	return state.items;
};
