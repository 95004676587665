export default {
	appName: '注文管理システム',
	menu: {
		closingBalance: '決算残高',
		creditLimit: 'クレジット限度',
		creditStatus: 'クレジット ステータス',
		customers: '顧客',
		dashboard: 'ホーム',
		distributionPartners: '販売パートナー',
		licenses: 'ライセンス',
		orders: '注文',
		quotes: '見積もり',
		purchase: '新しい購入',
		promotions: 'プロモーション',
		account: 'マイ アカウント',
		help: 'ヘルプとリソース',
		feedback: 'フィードバックを共有',
		retail: '小売りコード',
		priceCalculator: '価格計算ツール',
		flexibleBilling: 'フレキシブル請求',
	},
	help: {
		main: {
			title: 'ヘルプとリソース',
			guide: {
				title: 'OMS には以下の新機能が用意されています。',
				text: '最も重要な変更点を確認してみましょう。',
				action: 'ツアーを開始',
			},
			videos: {
				title: 'ハウツー ビデオを確認',
			},
		},
		guide: {
			paginator: '{{current}} / {{total}}',
			slides: {
				welcome: {
					title: '新しい OMS へようこそ',
					text: [
						'OMS の外観が新しくなりました。新しいデザイン、ユーザーフレンドリなインターフェース、ナビゲーションの改善とスムーズなパフォーマンスによって最高の購入エクスペリエンスが実現します。ホームページのウィジェットでは、最近の注文、見積もり、期限が近いライセンスなどにすばやくアクセスできます。',
					],
				},
				pricing: {
					title: 'ダイナミック プライシング',
					text: [
						'注文の価格改定を待つ必要がなくなりました。また、価格を確認するために製品を買い物かごに追加する必要もありません。価格は製品カタログに直接表示されるようになりました。',
						'シート数や製品数を調整すると、価格が自動的に再計算されて最新の情報を常に確認できます。また、買い物かごの製品を追加/削除すると、注文金額も自動的に再計算されます。',
					],
				},
				quickFilter: {
					title: '消費者向けの製品と企業向けの製品を簡単にフィルタ処理/検索',
					text: [
						'消費者のみ、または企業のみが購入できる製品を簡単に表示するクイック フィルタを追加しました。特定の製品をお探しの場合は、製品名または製品コードの入力を開始するだけです。入力内容に応じてリストが自動的に更新されます。',
					],
				},
				licenseOperations: {
					title: '更新を簡単に実行',
					text: [
						'新しい購入とライセンス処理 (更新やアップグレードなど) を切り離し、ライセンスを表示すると利用可能なすべてのライセンス処理を簡単に確認できるようになりました。［ライセンス］リストまたは［ライセンスの詳細］ページ内の［ライセンス処理］ボタンをクリックするだけです。',
					],
				},
				shoppingCart: {
					title: '買い物かごに簡単にアクセス',
					text: [
						'買い物かごアイコンを追加しました。これにより、準備が整ったら買い物かごに簡単にアクセスして注文を進めることができます。',
						'1 回の注文で新しい購入とライセンス処理 (更新など) を簡単に結合できます。',
					],
				},
				quickAccess: {
					title: '必要なすべてのレコードにすばやくアクセス',
					text: [
						'OMS のさまざまな場所にクイック ボタンとクイック リンクを追加しました。これにより、表示しているレコードに関連する情報に簡単にアクセスできます。［顧客の詳細］ページの［ライセンスを表示］ボタンと［注文を表示］ボタンを使用します。',
						'［ライセンスの詳細］ページで強調表示されているリンクをクリックして、関連する注文を開くか、顧客の詳細を表示します。',
					],
				},
				quotes: {
					title: '簡単にアクセス可能な見積もり',
					text: [
						'アカウント マネージャーから受信する見積もりに対応した個別のメニュー項目を作成しました。これにより、見積もりを簡単に見つけることができます。これらの見積もりを［注文］から探して簡単にアクセスすることもできます。',
					],
				},
				finalStep: {
					title: '新機能は以上です。',
					text: [
						'デザインが一新された新機能のユーザー インターフェースとパフォーマンスの改善により、OMS がさらに使いやすくなりました。',
						'［フィードバックを共有］メニュー項目をクリックして、製品の機能改善のためのフィードバックやご提案をお寄せください。',
						'メニューの［ヘルプとリソース］をクリックすると、概要ガイドを後でいつでもご覧いただけます。',
					],
				},
			},
		},
		videos: {
			title: 'ハウツー ビデオ',
			list: {
				navigations: 'OMS をナビゲート中',
				newPurchase: '新しい発注書を作成中',
				renewal: '更新注文を作成中',
				quotes: 'アカウント マネージャーから見積もりを受信中',
			},
		},
	},
};
