import React from 'react';
import { AuthRoute } from 'js/routes/AuthRoute';
import { ACL_READ_LIST } from 'config/acl';
import { Error404 } from 'module/error/page/Error404';
import { Route, Routes } from 'react-router-dom';
import { creditLimitConfig } from 'module/creditLimit/creditLimitConfig';
import { PageList } from 'module/creditLimit/pages';

export const CreditLimitRoutes = () => {
	const { aclModule } = creditLimitConfig;

	return (
		<Routes>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ_LIST]} />}>
				<Route
					index
					element={<PageList />}
				/>
			</Route>
			<Route
				path="*"
				element={<Error404 />}
			/>
		</Routes>
	);
};
