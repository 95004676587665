import { yupToFormErrors } from 'formik';
import type { ObjectSchema, Shape, ValidateOptions } from 'yup';
import * as Yup from 'yup';

/** Regular expression for url with optional protocol */
export const rUrl =
	'^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$';

export const yupContextValidator = <Values extends {}, Context extends {} = Partial<Values>>(
	validationSchema: ObjectSchema<Shape<undefined | object, Values>>,
	values: Values,
	context?: ValidateOptions<Context>['context'],
) =>
	validationSchema
		.validate(values, { abortEarly: false, context })
		.then(() => ({}))
		.catch((err) => yupToFormErrors(err));

export const isValidEmail = (email?: string): boolean => {
	return Yup.string().email().isValidSync(email);
};
