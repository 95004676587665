import { IAuthContext } from 'js/contexts/AuthContext';
import { useValidateLanguage } from 'js/hooks/useValidateLanguage';
import { useEffect } from 'react';
import { userLocaleInstance } from 'i18n/userLocaleInstance';

export const useUserLocaleWatcher = (context: IAuthContext) => {
	const {
		authPartnerGroups,
		authState: { locale },
	} = context;

	// Prepare user locale values
	const language = useValidateLanguage(authPartnerGroups)(locale.language);
	const { format } = locale;

	// Change in UI app
	useEffect(() => {
		userLocaleInstance.setState({ language, format });
	}, [language, format]);
};
