import React, { ReactElement } from 'react';
import { Stepper, StepperItem } from 'js/components/molecules/Stepper';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { RouteName } from 'module/RouteName';
import { useLocation } from 'react-router-dom';
import { isEmptySelector } from 'js/selectors/order';
import { useOrderContext } from 'js/contexts';

/**
 * Stepper for select products and customer to order
 * @return {React.ReactElement}
 * @constructor
 */
export const PurchaseStepper = (): ReactElement => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { hasOrderParties, orderState } = useOrderContext();
	const { pathname } = useLocation();
	const isEmptyCart = isEmptySelector(orderState);

	return (
		<Stepper
			className="py-3 px-3"
			value={pathname}
			readonlyFromActive
		>
			<StepperItem
				to={RouteName.PURCHASE.DEFAULT}
				testId="party"
			>
				{t('page.default.title')}
			</StepperItem>
			<StepperItem
				to={RouteName.PURCHASE.PRODUCTS}
				isEnabled={hasOrderParties()}
				testId="products"
			>
				{t('page.products.title')}
			</StepperItem>
			<StepperItem
				to={RouteName.PURCHASE.ORDER}
				isEnabled={hasOrderParties() && !isEmptyCart}
				testId="review"
			>
				{t('page.order.title')}
			</StepperItem>
		</Stepper>
	);
};
