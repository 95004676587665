import { IPageModuleConfig, IPageModuleLinksConfig, moduleConfigGenerator } from 'module/index';
import { RouteName } from 'module/RouteName';
import { IEntityCustomer } from 'module/customers';
import { TCustomerFormFields } from 'module/customers/forms/CustomerForm';
import { buildParametrizedRoute } from 'js/utils/common';
import { CustomerTypeEnum } from 'module/customers/enums';

interface ICustomersConfig extends IPageModuleConfig, IPageModuleLinksConfig<IEntityCustomer> {
	defaultFormValues: TCustomerFormFields;
}

export const customersConfig = moduleConfigGenerator<ICustomersConfig>({
	trNamespace: 'moduleCustomers',
	defaultFormValues: {
		email: '',
		companyName: '',
		firstName: '',
		lastName: '',
		phone: '',
		website: '',
		contactType: CustomerTypeEnum.CONSUMER,
		billing: {
			city: '',
			countryCode: '',
		},
	},
	menuName: 'app:menu.customers',
	menuLink: RouteName.CUSTOMERS.LIST,
	aclModule: 'customers',
	detailLink: (customer) => buildParametrizedRoute(RouteName.CUSTOMERS.DETAIL, customer.id),
});
