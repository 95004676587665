import { useFormikContext } from 'formik';
import { IPriceCalculatorForm, IPriceCalculatorFormDiscount } from 'module/priceCalculator';
import { useBillablePartyGuard } from './useBillablePartyGuard';
import { useEffect } from 'react';
import { tierCertificationEnumUtils } from 'js/enums';

type TUseExistingPartnerDiscountsGuard = {
	isSelected: boolean;
};

export const useExistingPartnerDiscountsGuard = (): TUseExistingPartnerDiscountsGuard => {
	const {
		values: { partner },
		setFieldValue,
	} = useFormikContext<IPriceCalculatorForm>();
	const billablePartyGuard = useBillablePartyGuard();

	// Find tier discount
	let tierCertification: IPriceCalculatorFormDiscount['tierCertification'];
	if (partner) {
		tierCertificationEnumUtils.getSelectOptions().some((option) => {
			const optionId = tierCertificationEnumUtils.getId(option.value);
			if (partner.groups?.find((partnerGroup) => partnerGroup.id === optionId)) {
				tierCertification = option.value;
				return true;
			}
			return false;
		});
	}

	// Update values
	useEffect(() => {
		if (partner) {
			setFieldValue('discount.tierCertification', tierCertification);
			setFieldValue('discount.special', Number(partner?.identifiers?.businessSpecialDiscount || 0) / 100);
		}
	}, [partner, tierCertification, setFieldValue]);

	return {
		isSelected: billablePartyGuard.isExistingPartner,
	};
};
