import { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/ja-JP');
const locale: TLocale = 'ja-JP';

export const ja_JP: ILocaleConfig = {
	name: 'Japanese (Japan)',
	locale,
	language: 'ja',
	default: true,
	format: {
		numbro,
		datetime: locale,
	},
};
