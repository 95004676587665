import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'js/components/atoms/Row';
import {
	SelectCustomerCard,
	SelectDistributionPartnerCard,
	SelectPartnerCard,
} from 'module/purchase/components/widget';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { RouteName } from 'module/RouteName';
import { Button } from '@avast/react-ui-components';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'js/contexts/AuthContext';
import { useOrderContext } from 'js/contexts';

export const PageDefault = (): ReactElement => {
	const { isGroupInternal } = useAuthContext();
	const { hasOrderParties, partnerRolesAndRoleGroups } = useOrderContext();
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const navigate = useNavigate();

	// Default step - CUSTOMER
	return (
		<DefaultContainer>
			<Row
				multi
				className="component__select-entity-boxes"
				size="sm"
				justify="center"
			>
				{isGroupInternal && (
					<div className="col-card">
						<SelectPartnerCard />
					</div>
				)}
				{partnerRolesAndRoleGroups.isRoleDistributor && (
					<div className="col-card">
						<SelectDistributionPartnerCard />
					</div>
				)}
				<div className="col-card">
					<SelectCustomerCard />
				</div>
			</Row>

			<p className="mt-4 text-center">
				<Button
					variant="primary"
					disabled={!hasOrderParties()}
					size="sm"
					onClick={() => navigate(RouteName.PURCHASE.PRODUCTS)}
					testId="continueToProducts"
				>
					{t('common.continueToProducts')}
				</Button>
			</p>
		</DefaultContainer>
	);
};
