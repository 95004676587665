export default {
	tierQualifyingUnits: '層級合格單位',
	tier: '層級',
	srpUnitPrice: 'SRP 單價',
	customerSegment: '客戶行業別',
	segmentDiscount: '細分折扣',
	srpAfterSegmentDiscount: '細分折扣後的 SRP',
	quantityPriced: '已定價數量',
	totalPrice: '總價',
	discretionaryDiscount: '酌情折扣',
	unitPriceAfterDiscretionaryDiscount: '酌情折扣後的單價',
	partnerDiscount: '合作夥伴折扣',
	unitPriceAfterDiscount: '折扣後的單價',
	finalDiscountedUnitPrice: '最終折扣單價',
	upgradeCredit: '升級信用',
	daysInTerm: '期限天數',
	daysToProrate: '按比例計算的天數',
	prorationRate: '按比例計算的比率',
	proratedUnitPrice: '按比例計算的單價',
	proratedSrpUnitPrice: '按比例計算的 SRP 單價',
	daysInPeriod: '期間天數',
	dailySaleUnitPrice: '每日銷售單價',
	customPricing: '自訂定價',
	quantityBasedPromotions: '數量折扣促銷活動',
	linePriceUnitPriceAfterQuantityBasedPromotions: '單項價格、數量折扣促銷活動後的單價',
	linePrice: '單項價格',
	unitPriceAfterQuantityBasedPromotions: '數量折扣促銷活動後的單價',
	percentageOffPromotions: '百分比折扣促銷活動',
	unitPriceAfterPercentageOffPromotions: '百分比折扣促銷活動後的單價',
	validFrom: '有效期自',
	validTo: '有效期到',
	offQtyOf: '折扣數量',
	amountOff: '折扣金額',
	discountPerUnit: '每單位折扣',
	discount: '折扣',
};
