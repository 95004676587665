export default {
	actions: {
		detail: 'Afficher les informations de licence',
		export: 'Exporter les licences',
		exportDetailed: 'Exportation détaillée',
		followingLicense: 'Licence suivante',
		lastNDays: 'Expirée au cours des {{count}} derniers jours',
		nextNDays: 'Expirant dans les {{count}} prochains jours',
		operations: 'Opérations liées aux licences',
		precedingLicense: 'Licence précédente',
		registerTo: 'Enregistrer une licence vers...',
		relatedCustomer: 'Afficher le client associé',
		relatedOrder: 'Afficher la commande associée',
	},
	error: {
		disallowed: 'Les opérations liées aux licences ne sont pas autorisées pour cette licence.',
		operation: {
			invalidParty: 'Les parties associées à la licence sont différentes des parties associées à la commande actuelle.',
			alreadyInCart: {
				title: 'Cette licence se trouve déjà dans le panier.',
				message:
					'Vous avez déjà ajouté cette licence à votre panier. Voulez-vous la remplacer par l’opération liée aux licences sélectionnée ?',
			},
			product: {
				noOperationsAvailable: 'Aucune opération liée aux licences n’est disponible.',
			},
			partner: {
				unableToLoad: 'Le partenaire de licence n’a pas pu être chargé.',
			},
			customer: {
				unableToLoad: 'Le client de la licence n’a pas pu être chargé.',
			},
			distributionPartner: {
				unableToLoad: 'Le partenaire de distribution de licences n’a pas pu être chargé.',
			},
			actions: {
				rewriteOperation: 'Remplacer l’opération liée aux licences',
			},
			billablePartyRequired:
				'La licence ne comporte pas de parties facturables. Rendez-vous dans la section « Nouvel achat » et sélectionnez les parties facturables.',
		},
		registration: 'Impossible d’enregistrer la licence.',
		detailNotFound: 'Détail d’autorisation n° {{id}} introuvable',
	},
	common: {
		operation: {
			addToCart: 'L’opération liée aux licences a été ajoutée au panier.',
			goToCart: 'Accéder au panier',
		},
	},
	entity: {
		createdAt: 'Date de création',
		customer: {
			contact: 'Contact client',
			id: 'ID du client',
			email: 'Adresse e-mail du client',
			name: 'Client',
		},
		partner: {
			contact: 'Contact partenaire',
			id: 'ID de partenaire',
			name: 'Partenaire',
		},
		distributionPartner: {
			contact: 'Contact du partenaire de distribution',
			id: 'ID du partenaire de distribution',
			name: 'Partenaire de distribution',
		},
		date: 'Date',
		entitlementId: 'ID d’autorisation',
		licenseId: 'ID de licence',
		licenseType: 'Type de licence',
		modifiedAt: 'Date de modification',
		orderId: 'ID de commande',
		product: {
			termLength: 'Période',
			type: 'Type de produit',
		},
		registeredAt: 'Date d’enregistrement',
		status: 'État de la licence',
		statusShort: 'État',
		statusPlaceholder: 'Tout état',
		walletKey: 'Clé de portefeuille',
	},
	licenseOperation: {
		label: 'Opération liée aux licences',
	},
	registration: {
		registering: 'Enregistrement de la licence',
		success: 'La licence a été enregistrée et associée au client suivant : {{customer}}',
		error: 'Une erreur s’est produite lors de l’enregistrement de la licence.',
		confirmation: {
			title: 'Enregistrement de licence',
			place: 'Êtes-vous sûr de vouloir associer ce client à cette licence ?',
			replace:
				'La licence n° {{licenseId}} est déjà enregistrée. Voulez-vous procéder à l’enregistrement de cette licence ?',
		},
	},
	selectParty: {
		partner: {
			title: 'Sélectionnez un partenaire pour la commande',
			billableParty: 'Ce partenaire sera la partie facturable.',
			requiredBillableParty: 'Une commande doit être associée à un partenaire.',
			emptyBillableParty: 'À l’étape suivante, le client sera sélectionné en tant que partie facturable.',
			license: {
				title: 'Partenaire de licence',
				button: 'Utiliser le partenaire de licence',
				buttonEmpty: 'Supprimer le partenaire de la commande',
				empty: 'La licence ne comprend pas de partenaire.',
			},
			order: {
				title: 'Partenaire de commande',
				button: 'Conserver le partenaire de commande',
				empty: 'La commande ne comprend pas de partenaire.',
				notAllowed: 'Le partenaire ne peut pas effectuer d’opérations liées aux licences sur ce groupe de produits.',
			},
		},
		customer: {
			title: 'Sélectionnez un client pour la commande',
			billableParty: 'Ce client sera la partie facturable.',
			requiredBillableParty: 'Une commande doit être associée à un client.',
			emptyBillableParty: 'Un partenaire sera une partie facturable.',
			license: {
				title: 'Client de la licence',
				button: 'Utiliser le client de la licence',
				buttonEmpty: 'Supprimer le client de la commande',
				empty: 'La licence ne comprend pas de client.',
			},
			order: {
				title: 'Client de la commande',
				button: 'Conserver le client de commande',
				empty: 'La commande ne comprend pas de client.',
			},
		},
		distributionPartner: {
			title: 'Sélectionnez un partenaire de distribution pour la commande',
			license: {
				title: 'Partenaire de distribution de la licence',
				button: 'Utiliser le partenaire de distribution de la licence',
				buttonEmpty: 'Supprimer le partenaire de distribution de la commande',
				empty: 'La licence ne comprend pas de partenaire de distribution.',
				notAllowed: 'Il n’est pas possible d’attribuer un partenaire de distribution au partenaire sélectionné.',
			},
			order: {
				title: 'Partenaire de distribution de la commande',
				button: 'Conserver le partenaire de distribution de la commande',
				empty: 'La commande ne comprend pas de partenaire de distribution.',
				notAllowed: 'Il n’est pas possible d’attribuer un partenaire de distribution au partenaire sélectionné.',
			},
		},
	},
};
