import { Alert, Button, Card, Modal } from '@avast/react-ui-components';
import React, { ReactElement } from 'react';
import { Col, Row } from 'js/components/atoms/Row';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';

export type TSelectPartyModalContentProps = {
	type: 'partner' | 'customer' | 'distributionPartner';
	entityLicense: ReactElement | null;
	entityOrder: ReactElement | null;
	entityOrderNotAllowed?: boolean;
	entityLicenseNotAllowed?: boolean;
	emptyEntityAllowed?: boolean;
	billableParty?: boolean;
	onResolve(resolution: number): void;
};

export const SELECT_PARTY_LICENSE = 1;
export const SELECT_PARTY_ORDER = 2;
export const SELECT_PARTY_BACK = 0;

export const SelectPartyModalContent = (props: TSelectPartyModalContentProps) => {
	const {
		type,
		entityLicense,
		entityOrder,
		billableParty,
		emptyEntityAllowed,
		entityOrderNotAllowed,
		entityLicenseNotAllowed,
		onResolve,
	} = props;
	const [t] = useTranslation(licensesConfig.trNamespace);
	const tr = (type: TSelectPartyModalContentProps['type'], key?: string) => t(`selectParty.${type}.${key}`);

	const isBtnDisabled = !emptyEntityAllowed && billableParty;
	const emptyEntityAlert = billableParty ? (
		<Alert variant={emptyEntityAllowed ? 'info' : 'danger'}>
			{tr(type, emptyEntityAllowed ? 'emptyBillableParty' : 'requiredBillableParty')}
		</Alert>
	) : null;
	const billablePartyAlert = billableParty ? <Alert className="mt-3 mb-0">{tr(type, 'billableParty')}</Alert> : null;

	return (
		<>
			<Modal.Header>{tr(type, 'title')}</Modal.Header>
			<Modal.Body className="pt-lg-3 pb-lg-4">
				<Row>
					<Col sm={6}>
						<Card className="h-100">
							<Card.Header>{tr(type, 'license.title')}</Card.Header>
							<Card.Body>
								{(() => {
									if (!entityLicense) {
										return (
											<>
												<Alert variant="warning">{tr(type, 'license.empty')}</Alert>
												{emptyEntityAlert}
											</>
										);
									}

									let alert: ReactElement | null = billablePartyAlert;
									if (entityLicenseNotAllowed) {
										alert = (
											<Alert
												className="mt-3 mb-0"
												variant="danger"
											>
												{tr(type, 'license.notAllowed')}
											</Alert>
										);
									}

									return (
										<>
											{entityLicense}
											{alert}
										</>
									);
								})()}
							</Card.Body>
							<Card.Footer className="text-center">
								<Button
									disabled={isBtnDisabled && !entityLicense}
									onClick={() => onResolve(SELECT_PARTY_LICENSE)}
									size="sm"
									testId="selectPartyLicense"
								>
									{tr(type, entityLicense ? 'license.button' : 'license.buttonEmpty')}
								</Button>
							</Card.Footer>
						</Card>
					</Col>
					<Col sm={6}>
						<Card className="h-100">
							<Card.Header>{tr(type, 'order.title')}</Card.Header>
							<Card.Body>
								{(() => {
									if (!entityOrder) {
										return (
											<>
												<Alert variant="warning">{tr(type, 'order.empty')}</Alert>
												{emptyEntityAlert}
											</>
										);
									}

									let alert: ReactElement | null = billablePartyAlert;
									if (entityOrderNotAllowed) {
										alert = (
											<Alert
												className="mt-3 mb-0"
												variant="danger"
											>
												{tr(type, 'order.notAllowed')}
											</Alert>
										);
									}

									return (
										<>
											{entityOrder}
											{alert}
										</>
									);
								})()}
							</Card.Body>
							<Card.Footer className="text-center">
								<Button
									disabled={(isBtnDisabled && !entityOrder) || entityOrderNotAllowed}
									onClick={() => onResolve(SELECT_PARTY_ORDER)}
									size="sm"
									testId="selectPartyOrder"
								>
									{tr(type, 'order.button')}
								</Button>
							</Card.Footer>
						</Card>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer
				cancelButton={false}
				className="d-block"
			>
				<Button
					size="sm"
					onClick={() => onResolve(SELECT_PARTY_BACK)}
					iconLeftFa={faChevronLeft}
					variant="outline-primary"
					testId="back"
				>
					{t('common:actions.back')}
				</Button>
			</Modal.Footer>
		</>
	);
};
