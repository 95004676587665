import { IUserLocale } from 'types/config';
import { LOCALE } from 'config/locale';

type TUserLocaleInstanceCallback = (userLocale: IUserLocale) => void;

interface IUserLocaleInstance extends IUserLocale {
	callbacks: TUserLocaleInstanceCallback[];

	init(callback: TUserLocaleInstanceCallback): void;

	setLanguage(language: IUserLocale['language']): void;

	setFormat(format: IUserLocale['format']): void;

	setState(state: IUserLocale): void;

	onChange(): void;
}

export const userLocaleInstance: IUserLocaleInstance = {
	callbacks: [],
	language: LOCALE.DEFAULT_LANGUAGE,
	format: LOCALE.DEFAULT_LOCALE,
	init(cb: TUserLocaleInstanceCallback) {
		this.callbacks.push(cb);
	},
	setLanguage(language) {
		this.language = language;
		this.onChange();
	},
	setFormat(format) {
		this.format = format;
		this.onChange();
	},
	setState(state) {
		if (state.format === this.format && state.language === this.language) {
			return;
		}

		this.format = state.format;
		this.language = state.language;
		this.onChange();
	},
	onChange() {
		this.callbacks.forEach((cb) =>
			cb({
				language: this.language,
				format: this.format,
			}),
		);
	},
};
