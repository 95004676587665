import { TCustomerFormFields } from 'module/customers/forms/CustomerForm';
import { filterPostValues } from 'js/utils/form';
import { isCustomerConsumer } from 'module/customers/utils/customerSelectors';

export const customerFormNormalizer = {
	denormalize(values: TCustomerFormFields): TCustomerFormFields {
		const result = filterPostValues(values);
		if (isCustomerConsumer(result)) {
			delete result?.companyName;
			delete result?.requestedDiscountType;
			delete result?.industry;
		}
		return result;
	},
};
