import { FormikControl } from 'js/components/formik/FormikControl';
import { LicenseOperationEnum, licenseOperationEnumUtils } from 'module/licenses/enums';
import { head } from 'lodash';
import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { ILicenseOperationForm } from 'module/licenses';

export const SelectOperation = () => {
	const [t] = useTranslation(licensesConfig.trNamespace);
	const { licenseProductOperations } = useLicenseOperationContext();
	const { setFieldValue } = useFormikContext<ILicenseOperationForm>();

	return (
		<FormikControl
			groupProps={{ className: 'mb-0' }}
			label={t('licenseOperation.label')}
		>
			<FormikControl.SingleSelect<LicenseOperationEnum>
				name="licenseOperation"
				options={licenseProductOperations.map((operation) => ({
					value: operation.type,
					label: licenseOperationEnumUtils.getText(operation.type),
				}))}
				onChange={(value) => {
					const productLicenseOperation = licenseProductOperations.find((operation) => operation.type === value);
					const productGroup = head(productLicenseOperation?.productGroups || []);

					// Set first product group as selected
					setFieldValue('productGroupCode', productGroup?.code || null);
					setFieldValue('variant', productGroup?.products[0].sku || null);
				}}
				required
				isClearable={false}
			/>
		</FormikControl>
	);
};
