import { Row } from 'js/components/atoms/Row';
import { Col } from 'react-bootstrap';
import { H2 } from '@avast/react-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { guideImage, THelpGuideSlide } from 'js/help/guide/guideContent';

type TGuideSlideProps = {
	slideKey: THelpGuideSlide;
};

export const GuideSlide = (props: TGuideSlideProps) => {
	const { slideKey } = props;
	const [t] = useTranslation('app');
	const text: string[] = t(`help.guide.slides.${slideKey}.text`, { returnObjects: true });

	return (
		<Row
			multi
			md={2}
			align="center"
			className="flex-md-row-reverse h-100"
		>
			<Col
				md={5}
				className="ps-lg-8"
			>
				<H2
					className="mb-3"
					bold
				>
					{t(`help.guide.slides.${slideKey}.title`)}
				</H2>
				<div className="wysiwyg">
					{text.map((part, index) => (
						<p key={index}>{part}</p>
					))}
				</div>
			</Col>
			<Col
				md={7}
				className="text-center"
			>
				<img
					src={guideImage[slideKey]}
					alt={t(`help.guide.slides.${slideKey}.title`)}
					className="img-fluid"
				/>
			</Col>
		</Row>
	);
};
