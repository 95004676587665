import { TApiMessagePlacement, TAppMessageConfig } from 'submodule/AppMessages/types';
import {
	useFlexibleBillingAvailableMessageConfig,
	useTaxExemptionCertificateMessageConfig,
} from 'submodule/AppMessages/messages';
import { useNewTermsAndConditionsMessageConfig } from 'submodule/AppMessages/messages/useNewTermsAndConditionsMessageConfig';

export const useAppMessagesConfig = (placement: TApiMessagePlacement): TAppMessageConfig[] => {
	const list: TAppMessageConfig[] = [
		useNewTermsAndConditionsMessageConfig(),
		useTaxExemptionCertificateMessageConfig(),
		useFlexibleBillingAvailableMessageConfig(),
	];

	return list.filter((message) => message.show && message.placement.includes(placement));
};
