import React, { PropsWithChildren } from 'react';
import type { RowProps } from 'react-bootstrap';
import { Row as BsRow } from 'react-bootstrap';
import classNames from 'classnames';
import { isDefined } from 'js/utils/common';

type TRowProps = RowProps & {
	multi?: boolean;
	size?: 'md' | 'sm';
	justify?: 'start' | 'center' | 'end' | 'between';
	align?: 'start' | 'center' | 'end';
	className?: string;
};

export const Row = (props: PropsWithChildren<TRowProps>) => {
	const { className, multi, size, children, justify, align, ...rest } = props;
	return (
		<BsRow
			{...rest}
			className={classNames(className, {
				[`row-${size}`]: isDefined(size),
				[`justify-content-${justify}`]: isDefined(justify),
				[`align-items-${align}`]: isDefined(align),
				'multi-row': Boolean(multi),
			})}
		>
			{children}
		</BsRow>
	);
};
