import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { IEntityPartnerDetail } from 'module/partners';
import { partnersConfig } from 'module/partners/partnersConfig';
import { getPartnerFullName, getPartnerRenderName } from 'module/partners/utils/partnerSelectors';
import { isDefined, joinValues } from 'js/utils/common';
import { PartnerCurrencyAlert } from 'module/partners/components/PartnerCurrencyAlert';
import { PartnerTermsAlert } from 'module/partners/components/PartnerTermsAlert';

type TPartnerCardProps = {
	partner: IEntityPartnerDetail;
	className?: string;
};

export const PartnerCard = (props: TPartnerCardProps): ReactElement => {
	const { partner } = props;
	const [t] = useTranslation(partnersConfig.trNamespace);

	return (
		<div className={props.className}>
			<PartnerTermsAlert partner={partner} />
			<p className="fw-bold">{getPartnerRenderName(partner)}</p>
			<ul className="mt-3 fs-sm reset">
				<li>
					{t('common.avastId')}: {partner.id}
				</li>
				{partner.salesforceId && (
					<li>
						{t('entity.salesforceId')}: {partner.salesforceId}
					</li>
				)}
				{isDefined(partner.identifiers?.partyTaxId) && (
					<li>
						{t('common:contact.taxId')}: {partner.identifiers?.partyTaxId}
					</li>
				)}
			</ul>
			<ul className="mt-3 fs-sm reset">
				<li>{partner.address1}</li>
				<li>{joinValues([partner.city, partner.postalCode, partner.stateName])}</li>
				<li>{partner.countryName}</li>
			</ul>
			<ul className="mt-3 fs-sm reset">
				<li>{getPartnerFullName(partner)}</li>
				{isDefined(partner.phone) && (
					<li>
						{t('common:contact.phone')}: {partner.phone}
					</li>
				)}
				{isDefined(partner.email) && (
					<li>
						{t('common:contact.email')}: {partner.email}
					</li>
				)}
			</ul>
			<PartnerCurrencyAlert partner={partner} />
		</div>
	);
};
