import React from 'react';
import { H2 } from '@avast/react-ui-components';
import classNames from 'classnames';

export const DividerTitle = ({ title, className }: { title?: string; className?: string }) =>
	title ? (
		<H2
			type="h3"
			className={classNames('text', className)}
			bold
		>
			{title}
		</H2>
	) : null;
