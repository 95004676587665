import React from 'react';
import { Headline } from 'js/components/molecules/Headline';
import { EditView } from 'submodule/widgets/view';
import { H1 } from '@avast/react-ui-components';
import { EditWidgetsActions } from 'module/homepage/components/EditWidgetsActions';
import { homepageConfig } from 'module/homepage/homepageConfig';
import { useTranslation } from 'react-i18next';
import { WidgetEditContextProvider } from 'submodule/widgets/context/WidgetEditContext';
import { useWidgetContext } from 'submodule/widgets/context';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';

export const EditHomepageContent = () => {
	const [t] = useTranslation(homepageConfig.trNamespace);
	const { config, loading } = useWidgetContext();

	if (loading) {
		return <LoadingPlaceholder />;
	}

	return (
		<WidgetEditContextProvider config={config}>
			<Headline
				className="align-items-center"
				rightContent={<EditWidgetsActions />}
			>
				<div className="d-flex align-items-center">
					<H1 type="h2">{t('submodules:widgets.action.editWidgets')}</H1>
				</div>
			</Headline>

			<EditView />
		</WidgetEditContextProvider>
	);
};
