import React, { FunctionComponent } from 'react';
import { ModuleContainer } from 'js/layouts/container/ModuleContainer';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { priceCalculatorConfig } from 'module/priceCalculator/priceCalculatorConfig';
import { PriceCalculatorRoutes } from 'module/priceCalculator/PriceCalculatorRoutes';

export const PriceCalculatorContainer: FunctionComponent = () => (
	<DefaultContainer>
		<ModuleContainer moduleMenuConfig={priceCalculatorConfig} />
		<PriceCalculatorRoutes />
	</DefaultContainer>
);
