import { IFormikContextValidator } from 'types/validator';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';
import * as Yup from 'yup';
import { yupContextValidator } from 'js/utils/validator';
import { IRegistrationDataForm } from 'module/registration';
import { hasCountryStates } from 'js/entities/country/countryUtils';

export const useRegistrationFormValidator = (): IFormikContextValidator<IRegistrationDataForm> => {
	type Values = IRegistrationDataForm;
	const [t] = useTranslation(customersConfig.trNamespace);

	const validationSchema = useMemo(
		() =>
			Yup.object().shape<Values>({
				companyName: Yup.string().ensure().label(t('common:entity.company')).required(),

				address: Yup.string().ensure().label(t('common:address.caption')).required(),

				postalCode: Yup.string().ensure().label(t('common:address.postalCode')).required(),

				city: Yup.string().ensure().label(t('common:address.city')).required(),

				countryCode: Yup.string().ensure().label(t('common:address.country')).required(),

				stateCode: Yup.string().ensure().label(t('common:address.state')).when('country', {
					is: hasCountryStates,
					then: Yup.string().required(),
				}),

				taxId: Yup.string().ensure().label(t('common:contact.taxId')).required(),
			}),
		[t],
	);

	return {
		validationSchema,
		validate: (values) => yupContextValidator<Values>(validationSchema, values),
	};
};
