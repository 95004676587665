import React from 'react';
import { WidgetRowsContainer } from 'submodule/widgets/components/WidgetRowsContainer';
import { FixedWidthWrapper } from 'js/components/molecules/Inputs/FixedWidthWrapper';
import { useWidgetEditContext } from 'submodule/widgets/context';

export const EditView = () => {
	const { onDragEnd, state } = useWidgetEditContext();

	return (
		<div className="component__widget mt-5 d-flex justify-content-center">
			<FixedWidthWrapper
				max
				width={1200}
			>
				<WidgetRowsContainer
					onDragEnd={onDragEnd}
					rows={state}
				/>
			</FixedWidthWrapper>
		</div>
	);
};
