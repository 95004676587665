export default {
	comparativeFeatures: {
		title: 'Neue Avast Business-Lösungen',
		availableAsAddon: 'Als Add-On verfügbar',
		equivalentLabel: 'Mehr Wert. Derselbe Preis.',
		action: 'Pakete anzeigen',
		message:
			'Wir bieten neue Sicherheitspakete an, die Ihren Kunden größeren Wert für den gleichen Preis bieten. Helfen Sie Ihren Kunden, den Schutz ihres Unternehmens richtig zu bemessen.',
		price: {
			from: 'Ab',
			units: '{{unitType}}',
			units_other: '{{count}} {{unitType}}',
		},
		startShopping: 'Einkauf beginnen',
		windowsOnly: 'Nicht verfügbar für macOS',
	},
	refunds: {
		entity: {
			date: 'Datum der Rückerstattung',
			initiatedDate: 'Rückerstattung initiiert am',
			itemId: 'Einzel-ID',
			orderLineItemId: 'Bestellpostennummer',
			type: 'Art der Rückerstattung',
			reason: 'Grund für Rückerstattung',
			reasonNote: 'Kommentar',
			refundableAmount: 'Verfügbar für Rückerstattung',
			refundAmount: 'Erstatteter Betrag',
			totalRefunded: 'Erstattet insgesamt',
		},
		initiate: {
			title: 'Rückerstattung einleiten für Bestellung Nr. {{id}}',
			submit: 'Anfrage senden',
			process: {
				loading: 'Rückerstattung wird gesendet...',
				success: 'Ihr Rückerstattungsantrag wurde übermittelt.',
				error: 'Rückerstattungsantrag konnte nicht übermittelt werden.',
			},
			message: {
				ORDER_REFUND:
					'Erstatten Sie entweder den gesamten Bestellbetrag oder beantragen Sie eine Teilerstattung, indem Sie den gewünschten Betrag (bis zum maximal verfügbaren Betrag) eingeben.',
				LINE_REFUND:
					'Wählen Sie die Produkte für die Rückerstattung aus. Erstatten Sie entweder den gesamten Betrag für den Einzelposten oder beantragen Sie eine Teilerstattung, indem Sie den gewünschten Betrag (bis zum maximal verfügbaren Betrag) eingeben.',
				TAX_REFUND:
					'Wenn Sie diese Option wählen, wird die gesamte Bestellung erstattet und automatisch eine neue Rechnung ohne Steuer erstellt.',
				KEY_REFUND:
					'Wählen Sie den zu erstattenden Schlüssel aus. Erstatten Sie entweder den gesamten Betrag oder beantragen Sie eine Teilerstattung, indem Sie den gewünschten Betrag (bis zum maximal verfügbaren Betrag) eingeben.',
			},
		},
		view: {
			title: 'Erstattungen für Bestellung Nr. {{id}}',
		},
		error: {
			noRefundTypeEnabled: 'Für diese Bestellung ist keine Rückerstattungsart zulässig.',
			refundableAmount: 'Die Informationen zur Rückerstattung der Bestellung können nicht geladen werden.',
			refundedAmount: 'Der erstattete Gesamtbetrag kann nicht geladen werden.',
		},
		license: {
			noSelectedLicenses:
				'Sie haben keine Lizenzen ausgewählt. Bitte verwenden Sie das oben stehende Feld, um nach Lizenzen zu suchen.',
			search: {
				title: 'Geben Sie einen Schlüssel ein, um nach einer Lizenz zu suchen',
				searchFailure: 'Der Schlüssel {{licenseKey}} passt zu keiner Lizenz für diese Bestellung.',
				refundableAmountFailure:
					'Es ist nicht möglich, einen erstattungsfähigen Betrag für den Schlüssel {{licenseKey}} zu laden.',
				success: '{{licenseKey}} wurde in das Rückerstattungsformular eingefügt.',
				loadingLicenses: 'Lizenzen werden geladen...',
				loadingRefundableAmount: 'Erstattungsfähiger Betrag wird geladen...',
			},
			select: {
				title: 'Lizenz für Rückerstattung auswählen',
			},
		},
	},
	appMessages: {
		taxExemptionCertificate: {
			title:
				'Bitte beachten Sie, dass die über OMS hochgeladenen Zertifikate nur für Kreditkartenbestellungen gelten, bei denen der Verkäufer Digital River Inc. ist.',
			contentTop:
				'Ab <strong>1. Februar 2023</strong> wird der Verkäufer für Kreditlinienkäufe (einschließlich der Zahlungsmethode für den Abschlusssaldo, falls zutreffend) von Digital River Inc. auf Gen Digital Inc. umgestellt. Um sicherzustellen, dass der Kreditlinienkauf weiterhin steuerbefreit bleibt, senden Sie bitte <strong>eine für Gen Digital Inc. ausgestellte Steuerbefreiungsbescheinigung</strong> an unsere Steuerabteilung unter <0>{{email}}</0>.',
			contentBottom: 'Vielen Dank für Ihre Mithilfe!',
		},
		flexibleBillingAvailable: {
			title: 'Flexible Abrechnungen sind jetzt im Business Hub verfügbar',
			content:
				'Managed Service Provider (MSPs), die Partnerversion des Business Hub für mehrere Unternehmen nutzen, haben jetzt eine Pay-As-You-Go-Option!<br/>Dies bietet MSPs maximale Flexibilität, Skalierbarkeit und Rentabilität und ermöglicht es ihnen, die Avast Business-Lösungen so zu nutzen, wie es am besten zu ihren Transaktionen mit Endkunden passt.',
			action: 'Weitere Informationen',
		},
		newTermsAndConditions: {
			title: 'Wichtiger Hinweis',
			content:
				'Am 19. März geben wir eine aktualisierte Version unserer Partnervereinbarung heraus, die Partnern angezeigt wird, wenn sie im Partnerportal auf "Jetzt bestellen" klicken.<br/>Um mit einer OMS-Transaktion fortfahren zu können, muss die aktualisierte Vereinbarung akzeptiert werden. Bei Fragen wenden Sie sich bitte an Ihren Account Manager.',
		},
	},
	widgets: {
		action: {
			addWidget: 'Widget hinzufügen',
			editWidget: 'Widget bearbeiten',
			editWidgets: 'Widgets bearbeiten',
		},
		common: {
			columnPlaceholder: 'Neues Widget hinzufügen oder Widget verschieben...',
			savingLoader: 'Speichern von Widget-Änderungen...',
			rowTitle: 'Zeile ({{count}} Spalte)',
			rowTitle_other: 'Zeile ({{count}} Spalten)',
			changesSaved: 'Die Widget-Änderungen wurden gespeichert',
			oneColumnRow: 'Eine Spaltenzeile',
			twoColumnsRow: 'Zwei Spaltenzeilen',
		},
		config: {
			type: 'Typ',
			ownName: 'Eigener Name',
			ownNamePlaceholder: 'Widget-Name',
			columns: 'Sichtbare Spalten',
			columnsPlaceholder: 'Wählen Sie mindestens eine Spalte aus',
		},
		setting: {
			days: 'Tage',
			limit: 'Maximale Zeilen',
		},
	},
	locale: {
		language: {
			saving: 'Ändern der Systemsprache',
			success: 'Die Systemsprache wurde geändert.',
			confirmation: {
				title: 'Systemsprache ändern',
				message:
					'Bei Änderungen an den Spracheinstellungen Ihres Kontos ändert sich auch die Sprache Ihrer Rechnungen und E-Mails von Avast. Möchten Sie diese Einstellung wirklich ändern?',
			},
		},
		format: {
			saving: 'Einstellung für das Datums- und Nummernformat ändern',
			success: 'Das Datums- und Nummernformat wurde geändert.',
		},
	},
};
