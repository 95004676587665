import { IEntityProductGroup } from 'module/purchase';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IComparativeData } from 'submodule/comparativeFeatures';
import { Button } from '@avast/react-ui-components';

type TSelectGroupButtonRowProps = {
	comparativeData: IComparativeData;
	onSelect(group: IEntityProductGroup): void;
};

export const SelectGroupButtonRow = (props: TSelectGroupButtonRowProps) => {
	const {
		comparativeData: { products },
		onSelect,
	} = props;
	const [t] = useTranslation('components');
	const isMulti = products.length !== 1;

	return (
		<tr>
			<td />
			{products.map((list, listIndex) => (
				<React.Fragment key={listIndex}>
					{isMulti && listIndex !== 0 && <td className="is-separator" />}
					{list.map((product) => (
						<td
							key={`${product.productGroup.code}_buy`}
							className="py-3"
						>
							<Button
								variant={product.buttonVariant}
								size="sm"
								onClick={() => onSelect(product.productGroup)}
								testId={product.productGroup.code}
							>
								{t('common:actions.continue')}
							</Button>
						</td>
					))}
				</React.Fragment>
			))}
		</tr>
	);
};
