import { AsyncModal, TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { Modal } from '@avast/react-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { partnersConfig } from 'module/partners/partnersConfig';
import {
	SelectPartnerContainer,
	TSelectPartnerContainerProps,
} from 'module/partners/components/SelectPartnerContainer';
import { IEntityPartner } from 'module/partners';

export type TAsyncSelectPartnerModalProps = TSelectPartnerContainerProps;

export const AsyncSelectPartnerModal = (
	props: TAsyncModalContainerProps<TAsyncSelectPartnerModalProps, IEntityPartner>,
) => {
	const { forwardedRef } = props;
	const [t] = useTranslation(partnersConfig.trNamespace);

	return (
		<AsyncModal<TAsyncSelectPartnerModalProps, IEntityPartner>
			ref={forwardedRef}
			size="lg"
			testId="selectPartner"
		>
			{(props) => (
				<>
					<Modal.Header className="pb-0">{t('select.title')}</Modal.Header>
					<Modal.Body className="mt-4">
						<SelectPartnerContainer
							{...props}
							onSelect={(partner) => forwardedRef.current?.onSuccess(partner)}
						/>
					</Modal.Body>
					<Modal.Footer cancelButton />
				</>
			)}
		</AsyncModal>
	);
};
