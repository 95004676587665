import { Button } from '@avast/react-ui-components';
import React, { useCallback } from 'react';
import type { QueryObserverBaseResult } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { IAxiosApiError } from 'types/api';
import { useTranslation } from 'react-i18next';

type TRefetchButtonProps = {
	refetch: QueryObserverBaseResult<AxiosResponse<{}>, IAxiosApiError>['refetch'];
};

export const RefetchButton = ({ refetch }: TRefetchButtonProps) => {
	const [t] = useTranslation();
	const reFetchCallback = useCallback(() => refetch(), [refetch]);

	return (
		<p className="text-center mt-3">
			<Button
				type="button"
				variant="danger"
				size="sm"
				onClick={reFetchCallback}
			>
				{t('components:table.retry')}
			</Button>
		</p>
	);
};
