import { IHelpVideo } from 'js/help';
import type { TFunction } from 'i18next';
import { HelpVideoEnum } from 'js/enums';

export const getHelpVideos = (t: TFunction): IHelpVideo[] => [
	{
		key: HelpVideoEnum.NAVIGATIONS,
		name: t('help.videos.list.navigations'),
		videoId: 'HGNw_V-XnyU',
	},
	{
		key: HelpVideoEnum.NEW_PURCHASE,
		name: t('help.videos.list.newPurchase'),
		videoId: '0QJrJb_E4Z0',
	},
	{
		key: HelpVideoEnum.RENEWAL,
		name: t('help.videos.list.renewal'),
		videoId: 'n9Hwh8bjr80',
	},
	{
		key: HelpVideoEnum.QUOTES,
		name: t('help.videos.list.quotes'),
		videoId: 'rDknEC3hs1o',
	},
];

export const getVideo = (key: HelpVideoEnum | undefined, t: TFunction): IHelpVideo | null => {
	return getHelpVideos(t).find((video) => video.key === key) || null;
};
