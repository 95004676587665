import { Button } from '@avast/react-ui-components';
import React, { useCallback, useEffect, useState } from 'react';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import {
	ChargeOrderDocumentsModalContent,
	TChargeOrderDocumentsModalContentProps,
} from 'js/components/chargeOrderDocuments/ChargeOrderDocumentsModalContent';
import type { UseQueryResult } from '@tanstack/react-query';

type TChargeOrderDocumentsButtonProps = TChargeOrderDocumentsModalContentProps & {
	openOnInit?: boolean;
	availableDocuments?: boolean;
	query: UseQueryResult;
	isLoading?: boolean;
};

export const ChargeOrderDocumentsButton = (props: TChargeOrderDocumentsButtonProps) => {
	const { query, id, chargeOrderId, availableDocuments, title, isLoading } = props;
	const [openOnInit, setOpenOnInit] = useState(props.openOnInit);
	const documentsRef = useAsyncModalRef<TChargeOrderDocumentsModalContentProps>();

	const show = useCallback(() => {
		return documentsRef.current?.show({ id, chargeOrderId, title });
	}, [documentsRef, id, chargeOrderId, title]);

	useEffect(() => {
		if (query.isSuccess && openOnInit && availableDocuments) {
			show();
			setOpenOnInit(false);
		}
	}, [query.isSuccess, openOnInit, availableDocuments, show]);

	if (!availableDocuments) {
		return null;
	}

	return (
		<>
			<Button
				size="sm"
				variant="outline-primary"
				loading={isLoading || query.isFetching}
				onClick={() => show()}
				testId="orderDocuments"
			>
				{title}
			</Button>
			<AsyncModal<TChargeOrderDocumentsModalContentProps>
				backdrop="static"
				size="md"
				ref={documentsRef}
				testId="orderDocuments"
			>
				{(props) => <ChargeOrderDocumentsModalContent {...props} />}
			</AsyncModal>
		</>
	);
};
