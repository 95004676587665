export default {
	comparativeFeatures: {
		title: '新しいアバスト ビジネス ソリューション',
		availableAsAddon: 'アドオンとして利用可能',
		equivalentLabel: '同じ価格でさらなる価値を提供します。',
		action: 'パッケージを見る',
		message:
			'顧客に同じ価格でさらなる価値を提供する新しいセキュリティ パッケージが用意されています。ビジネスに適したレベルの保護を顧客が選択できます。',
		price: {
			from: '開始日',
			units: '{{unitType}}',
			units_other: '{{count}} {{unitType}}',
		},
		startShopping: '買い物を開始',
		windowsOnly: 'MacOS では利用できません',
	},
	refunds: {
		entity: {
			date: '返金日',
			initiatedDate: '返金開始日',
			itemId: '明細行 ID',
			orderLineItemId: '注文明細行番号',
			type: '返金の種類',
			reason: '返金の理由',
			reasonNote: 'コメント',
			refundableAmount: '返金可能',
			refundAmount: '返金額',
			totalRefunded: '合計返金額',
		},
		initiate: {
			title: '注文 #{{id}} の返金を開始',
			submit: 'リクエストを送信',
			process: {
				loading: '返金を送信中...',
				success: '返金リクエストが送信されました。',
				error: '返金リクエストを送信できません。',
			},
			message: {
				ORDER_REFUND:
					'注文の金額を全額返金するか、目的の金額 (返金可能な最大金額まで) を入力して注文の一部返金をリクエストします。',
				LINE_REFUND:
					'返金する製品を選択します。品目の金額を全額返金するか、目的の金額 (返金可能な最大金額まで) を入力して品目の一部返金をリクエストします。',
				TAX_REFUND:
					'このオプションを選択すると、注文の金額が全額返金され、税抜きの新しい請求書が自動的に生成されます。',
				KEY_REFUND:
					'返金するキーを選択します。全額返金するか、目的の金額 (返金可能な最大金額まで) を入力して一部返金をリクエストします。',
			},
		},
		view: {
			title: '注文 #{{id}} の返金',
		},
		error: {
			noRefundTypeEnabled: 'この注文の返金の種類は許可されていません。',
			refundableAmount: '注文の返金情報を読み込むことができません。',
			refundedAmount: '合計返金額を読み込むことができません。',
		},
		license: {
			noSelectedLicenses: 'ライセンスが選択されていません。上のフィールドを使用してライセンスを検索してください。',
			search: {
				title: 'ライセンスを検索するためのキーを入力',
				searchFailure: 'キー {{licenseKey}} はこの注文のライセンスに一致しません。',
				refundableAmountFailure: 'キー {{licenseKey}} の返金可能な金額を読み込むことができません。',
				success: '{{licenseKey}} を返金申請書に追加しました。',
				loadingLicenses: 'ライセンスを読み込み中...',
				loadingRefundableAmount: '返金可能な金額を読み込み中...',
			},
			select: {
				title: '返金するライセンスを選択します',
			},
		},
	},
	appMessages: {
		taxExemptionCertificate: {
			title:
				'OMS を使用してアップロードした証明書は、ベンダーが Digital River Inc. であるクレジット カード注文にのみ適用されます。',
			contentTop:
				'<strong>2023 年 2 月 1 日</strong>以降、クレジット購入の明細行のベンダー (該当する場合は、決算残高のお支払い方法を含む) は Digital River Inc. から Gen Digital Inc に変更されます。クレジット購入の明細行を引き続き非課税にするには、<strong>Gen Digital Inc. に対して発行された非課税証明書</strong>を当社の税務部門 (<0>{{email}}</0>) に送信してください。',
			contentBottom: 'よろしくお願いいたします。',
		},
		flexibleBillingAvailable: {
			title: 'ビジネス ハブに柔軟性の高い請求オプションを導入',
			content:
				'ビジネス ハブのマルチカンパニーパートナーバージョンを使用するマネージドサービスプロバイダー (MSP) に、従量課金オプションが導入されました。<br/>これにより、MSP の柔軟性、拡張性、収益性が大幅に向上し、エンドカスタマーとの取引に最適な方法で、アバスト ビジネスの各種ソリューションを使用できるようになります。',
			action: '詳細を見る',
		},
	},
	widgets: {
		action: {
			addWidget: 'ウィジェットを追加',
			editWidget: 'ウィジェットを編集',
			editWidgets: 'ウィジェットを編集',
		},
		common: {
			columnPlaceholder: '新しいウィジェットを追加/ウィジェットを移動...',
			savingLoader: 'ウィジェットの変更を保存中...',
			rowTitle: '行 ({{count}} 列)',
			rowTitle_other: '行 ({{count}} 列)',
			changesSaved: 'ウィジェットの変更が保存されました',
			oneColumnRow: '1 列 (行)',
			twoColumnsRow: '2 列 (行)',
		},
		config: {
			type: '種類',
			ownName: '自分の名前',
			ownNamePlaceholder: 'ウィジェット名',
			columns: '表示可能な列',
			columnsPlaceholder: '少なくとも 1 列を選択してください',
		},
		setting: {
			days: '日間',
			limit: '最大行数',
		},
	},
	locale: {
		language: {
			saving: 'システムの言語を変更中',
			success: 'システムの言語が変更されました。',
			confirmation: {
				title: 'システムの言語を変更',
				message:
					'アカウントの言語設定を変更すると、アバストが送信する請求書と電子メールで使用される言語も変更されます。この変更に関する処理ですか？',
			},
		},
		format: {
			saving: '日付と数字の書式設定を変更中',
			success: '日付と数字の書式設定が変更されました。',
		},
	},
};
