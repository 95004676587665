import React, { FunctionComponent, ReactNode } from 'react';
import { createAdvAgent, throughContainer } from 'react-through';
import { omit } from 'lodash';
import type { TBreadcrumbsItemProps, TBreadcrumbsProps } from '@avast/react-ui-components';
import { Breadcrumbs } from '@avast/react-ui-components';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import type { Location } from '@remix-run/router';
import { useAppContext } from 'js/contexts';

const breadcrumbsThroughArea = 'breadcrumbs';
const breadcrumbsBearingKey = 'href';
const withBreadcrumbsContainer = throughContainer(breadcrumbsThroughArea);

export type TDynamicBreadcrumbsItemProps = Omit<TBreadcrumbsItemProps<LinkProps>, 'href'> & {
	priority?: number;
	href: string;
};

type TDynamicBreadcrumbsProps = TBreadcrumbsProps & {
	[breadcrumbsThroughArea]: { [key: string]: TDynamicBreadcrumbsItemProps };
	lastIsActive?: boolean;
};

/**
 * Breadcrumb item component
 */
export const DynamicBreadcrumbsItem: FunctionComponent<TDynamicBreadcrumbsItemProps> = createAdvAgent(
	breadcrumbsThroughArea,
	breadcrumbsBearingKey,
);

/**
 * Sort items by priority and then by href length
 * @param {TDynamicBreadcrumbsItemProps} a
 * @param {TDynamicBreadcrumbsItemProps} b
 * @returns {number}
 */
const sortBreadcrumbsItems = (a: TDynamicBreadcrumbsItemProps, b: TDynamicBreadcrumbsItemProps) => {
	if (a.priority === b.priority) {
		if (a[breadcrumbsBearingKey] === '#') {
			return 1;
		}
		return a[breadcrumbsBearingKey].length - b[breadcrumbsBearingKey].length;
	}
	return (b.priority ?? 0) - (a.priority ?? 0);
};

/**
 * Prepare breadcrumb item to render
 * @param {TDynamicBreadcrumbsItemProps} props
 * @param {Location|null} prevLocation
 * @returns {TBreadcrumbsItemProps<LinkProps>}
 */
const prepareItem = (
	props: TDynamicBreadcrumbsItemProps,
	prevLocation?: Location | null,
): TBreadcrumbsItemProps<LinkProps> => ({
	...omit(props, 'priority'),
	linkAs: Link,
	linkProps: {
		to: prevLocation || props.href,
		state: { back: true },
		...props.linkProps,
		children: props.children,
	},
});

/**
 * Component render breadcrumb item, what were collected
 * @param {TDynamicBreadcrumbsProps} props
 * @returns {React.ReactNode}
 * @private
 */
const _DynamicBreadcrumbs = (props: TDynamicBreadcrumbsProps): ReactNode => {
	const { prevLocationListener } = useAppContext();
	const items = props[breadcrumbsThroughArea];
	let prevLocationIndex = 1;

	const itemsSorted = Object.keys(items)
		.map((k) => items[k])
		.sort(sortBreadcrumbsItems)
		.reverse()
		.map((item) => {
			const prevLocation = prevLocationListener.resolveByPathname(item.href, prevLocationIndex);
			if (prevLocation) {
				prevLocationIndex += 1;
			}

			return prepareItem(item, prevLocation);
		})
		.reverse();

	// Set last item as active
	if (itemsSorted.length && props.lastIsActive !== false) {
		itemsSorted[itemsSorted.length - 1].active = true;
	}

	return (
		<Breadcrumbs light>
			{itemsSorted.map((item, index) => (
				<Breadcrumbs.Item
					key={`dynamic-breadcrumbs-item-${index}`}
					{...item}
				/>
			))}
		</Breadcrumbs>
	);
};

export const DynamicBreadcrumbs = withBreadcrumbsContainer(_DynamicBreadcrumbs);
