import { AsyncModal, TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { Modal } from '@avast/react-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { IEntityClosingBalanceLicenses } from 'module/closingBalance';
import { DetailViewModalContent } from 'module/closingBalance/components/modal/DetailViewModalContent';

export type TAsyncDetailViewModalProps = {
	detailView: IEntityClosingBalanceLicenses;
};

export const AsyncDetailViewModal = (props: TAsyncModalContainerProps<TAsyncDetailViewModalProps>) => {
	const { forwardedRef } = props;

	const [t] = useTranslation(closingBalanceConfig.trNamespace);

	return (
		<AsyncModal
			ref={forwardedRef}
			size="lg"
			testId="detail"
		>
			{({ detailView }) => (
				<>
					<Modal.Header className="pb-0">{t('tab.detail')}</Modal.Header>
					<Modal.Body className="mt-4">
						<DetailViewModalContent detailView={detailView} />
					</Modal.Body>
					<Modal.Footer cancelButton={{ children: t('common:actions.close') }} />
				</>
			)}
		</AsyncModal>
	);
};
