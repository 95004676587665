import { Modal, Toast } from '@avast/react-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';
import { CustomerForm } from 'module/customers/forms/CustomerForm';
import { toast } from 'react-toastify';
import { createCustomerApiError } from 'module/customers/utils/apiError';
import { useApiCreateCustomer } from 'module/customers/hooks/useApiCustomers';
import { TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { IEntityCustomer } from 'module/customers';
import { useApiErrorContext, useAuthContext } from 'js/contexts';

type TAsyncCreateCustomerModalProps = {
	onCreate: (customer: IEntityCustomer) => void;
};

export const AsyncCreateCustomerModal = (props: TAsyncCreateCustomerModalProps & TAsyncModalContainerProps) => {
	const { onCreate, forwardedRef } = props;
	const [t] = useTranslation(customersConfig.trNamespace);
	const { authCompanyId } = useAuthContext();
	const { setError } = useApiErrorContext();
	const { mutate: createCustomer } = useApiCreateCustomer({
		config: { params: { partnerId: authCompanyId! } },
	});

	return (
		<>
			<Modal.Header>{t('page.create.title')}</Modal.Header>
			<Modal.Body>
				<CustomerForm
					onSubmit={(values, { setSubmitting }) => {
						createCustomer(values, {
							onSuccess(response) {
								setSubmitting(false);
								if (Boolean(response?.data)) {
									toast.success(<Toast>{t('page.create.success')}</Toast>);
									onCreate(response.data);
								}
							},
							onError(error) {
								setError({ error, resolve: createCustomerApiError });
								setSubmitting(false);
							},
						});
					}}
					onCancel={() => {
						forwardedRef.current?.onCancel();
					}}
				/>
			</Modal.Body>
		</>
	);
};
