const flexibleBillingPrefix = '/flexible-billing';

export const flexibleBillingRouteName = (options?: { withoutPrefix?: boolean }) => {
	const prefix = options?.withoutPrefix ? '' : flexibleBillingPrefix;
	return {
		DISTRIBUTION_PARTNERS: `${prefix}`,
		REPORTS: `${prefix}/reports`,
		DETAIL: `${prefix}/:id`,
		UPDATE: `${prefix}/:id/update`,
		DETAIL_REPORTS: `${prefix}/reports/:id`,
	};
};
