import { TWidgetGenerator, TWidgetGrid } from 'submodule/widgets';
import { useAllowedWidgets } from 'submodule/widgets/hooks/useAllowedWidgets';
import { widgetGeneratorToWidgetGrid } from 'submodule/widgets/utils';
import { WidgetTypeEnum } from 'submodule/widgets/enums';
import { useAuthContext } from 'js/contexts';
import { logError } from 'js/utils/app';

export const useDefaultConfig = (): TWidgetGrid => {
	const { authRole } = useAuthContext();

	// Widgets
	const widgets = useAllowedWidgets();

	const config: TWidgetGenerator = [];
	switch (authRole) {
		case 'FINANCE':
		case 'SUPPORT':
		case 'SALES_MANAGER':
		case 'SALES_OPERATIONS':
			config.push([[WidgetTypeEnum.ORDERS]]);
			break;
		case 'RESELLER':
			config.push([
				[WidgetTypeEnum.EXPIRED_LICENSES_LAST_N_DAYS, WidgetTypeEnum.EXPIRING_LICENSES_NEXT_N_DAYS],
				[WidgetTypeEnum.QUOTES, WidgetTypeEnum.ORDERS, WidgetTypeEnum.CREDIT_STATUS],
			]);
			break;
		case 'DISTRIBUTOR':
			config.push([
				[
					WidgetTypeEnum.EXPIRED_LICENSES_LAST_N_DAYS,
					WidgetTypeEnum.EXPIRING_LICENSES_NEXT_N_DAYS,
					WidgetTypeEnum.CLOSING_BALANCE,
				],
				[WidgetTypeEnum.QUOTES, WidgetTypeEnum.ORDERS, WidgetTypeEnum.CREDIT_STATUS],
			]);
			break;
		default:
			logError(`Not supported authRole: ${authRole}`);
	}

	return widgetGeneratorToWidgetGrid(config, widgets);
};
