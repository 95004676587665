import { ILocaleConfig, TLocale } from 'types/config';
import { en_US } from 'i18n/locales/en_US';
import { de_DE } from 'i18n/locales/de_DE';
import { fr_FR } from 'i18n/locales/fr_FR';
import { cs_CZ } from 'i18n/locales/cs_CZ';
import { en_AU } from 'i18n/locales/en_AU';
import { en_CA } from 'i18n/locales/en_CA';
import { en_GB } from 'i18n/locales/en_GB';
import { en_IE } from 'i18n/locales/en_IE';
import { en_IN } from 'i18n/locales/en_IN';
import { en_MY } from 'i18n/locales/en_MY';
import { en_ZA } from 'i18n/locales/en_ZA';
import { es_ES } from 'i18n/locales/es_ES';
import { es_CL } from 'i18n/locales/es_CL';
import { es_CO } from 'i18n/locales/es_CO';
import { es_MX } from 'i18n/locales/es_MX';
import { fr_CA } from 'i18n/locales/fr_CA';
import { it_IT } from 'i18n/locales/it_IT';
import { ja_JP } from 'i18n/locales/ja_JP';
import { ko_KR } from 'i18n/locales/ko_KR';
import { nl_NL } from 'i18n/locales/nl_NL';
import { pt_PT } from 'i18n/locales/pt_PT';
import { pt_BR } from 'i18n/locales/pt_BR';
import { sk_SK } from 'i18n/locales/sk_SK';
import { tr_TR } from 'i18n/locales/tr_TR';
import { zh_CN } from 'i18n/locales/zh_CN';
import { zh_TW } from 'i18n/locales/zh_TW';
import { no_NO } from 'i18n/locales/no_NO';

export const localesConfig: Record<TLocale, ILocaleConfig> = {
	'cs-CZ': cs_CZ,
	'de-DE': de_DE,
	'en-AU': en_AU,
	'en-CA': en_CA,
	'en-GB': en_GB,
	'en-IE': en_IE,
	'en-IN': en_IN,
	'en-MY': en_MY,
	'en-US': en_US,
	'en-ZA': en_ZA,
	'es-ES': es_ES,
	'es-CL': es_CL,
	'es-CO': es_CO,
	'es-MX': es_MX,
	'fr-CA': fr_CA,
	'fr-FR': fr_FR,
	'it-IT': it_IT,
	'ja-JP': ja_JP,
	'ko-KR': ko_KR,
	'nl-NL': nl_NL,
	'no-NO': no_NO,
	'pt-PT': pt_PT,
	'pt-BR': pt_BR,
	'sk-SK': sk_SK,
	'tr-TR': tr_TR,
	'zh-CN': zh_CN,
	'zh-TW': zh_TW,
};

export const localesConfigKeys: TLocale[] = Object.keys(localesConfig) as TLocale[];
