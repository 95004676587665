export default {
	table: {
		noData: '尚無可用資料',
		error: '提取資料時發生錯誤',
		noDataFilter: '沒有資料可供篩選',
		retry: '重試要求',
		perPage: '每頁',
		pagination: {
			total: '顯示全部 <0>{{total}}</0> 筆記錄中的<strong>第 {{from}} 筆到第 <0>{{to}}</0> 筆</strong>。',
		},
	},
	datepicker: {
		goToToday: '前往本月',
		rangeDivider: '至',
		startDate: '開始日期',
		endDate: '結束日期',
		rangeSelector: {
			today: '今天',
			thisWeek: '本週',
			thisMonth: '本月',
			lastWeek: '上週',
			lastMonth: '上個月',
			thisYear: '今年',
			lastYear: '去年',
			customRange: '自訂範圍',
		},
		relative: {
			single: {
				today: '今天',
				tomorrow: '明天',
				yesterday: '昨日',
			},
			range: {
				thisWeek: '本週',
				lastWeek: '上週',
				thisMonth: '本月',
				lastMonth: '上個月',
				thisYear: '今年',
				lastYear: '去年',
			},
		},
	},
	searchComboBox: {
		value: {
			placeholder: '輸入值...',
		},
	},
	notes: {
		noContent: '沒有可用的附註',
	},
	hgop: {
		shoppingCart: {
			title: '購物車',
		},
	},
	chargeOrderDocuments: {
		entity: {
			documentType: '文件類型',
		},
		type: {
			payment_instructions: '付款指示',
			invoice: '發票',
			vat_invoice: '發票',
			credit_memo: '貸項通知單',
			payg_price_breakdown_msp: '彈性計費 - 合作夥伴報告',
			payg_product_usage_report: '彈性計費 - 客戶報告',
			payg_reports_all: '彈性計費 - 產品使用情況報告（ 全部）',
		},
	},
	export: {
		caption: '正在進行匯出，這可能需要幾分鐘時間。',
		autoDownload: '檔案將會自動下載。',
		downloadError: '無法下載檔案。',
		error: '匯出失敗。請稍後再試一次。',
	},
	common: {
		more: '檢視更多',
		apply: '套用',
	},
	select: {
		placeholder: '選取選項',
	},
	pagination: {
		prev: '上一',
		next: '下一頁',
		page: '頁',
		of: '/',
		miniText: '第 {{active}}/{{total}} 頁',
	},
	modal: {
		cancel: '取消',
		submit: '提交',
		apply: '套用',
	},
	rangeDatepicker: {
		placeholder: '選取日期',
	},
	singleDatepicker: {
		placeholder: '選取日期',
	},
	toast: {
		title: {
			success: '成功',
			error: '錯誤',
			info: '資訊',
			default: '資訊',
			dark: '資訊',
			warning: '警告',
		},
	},
	fileUpload: {
		placeholder: '在這裡<strong>選取</strong>或拖曳檔案',
	},
};
