import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import Axios from 'axios';

/**
 * @readonly
 * @type {AxiosRequestConfig}
 */
const apiConfig: AxiosRequestConfig = {
	baseURL: process.env.REACT_APP_API_URL,
	headers: { 'content-type': 'application/json' },
};

/**
 * @readonly
 * @type {AxiosInstance}
 */
const api: AxiosInstance = Axios.create(apiConfig);

/**
 * @readonly
 * @type {AxiosRequestConfig}
 */
const apiSmartChannelConfig: AxiosRequestConfig = {
	baseURL: process.env.REACT_APP_API_URL_SCH,
	withCredentials: true,
};

/**
 * @readonly
 * @type {AxiosInstance}
 */
const apiSmartChannel: AxiosInstance = Axios.create(apiSmartChannelConfig);

/**
 * @readonly
 * @type {AxiosRequestConfig}
 */
const apiDigitalRiverConfig: AxiosRequestConfig = {
	baseURL: process.env.REACT_APP_API_URL_DR,
	withCredentials: true,
};

/**
 * @readonly
 * @type {AxiosInstance}
 */
const apiDigitalRiver: AxiosInstance = Axios.create(apiDigitalRiverConfig);

export { api, apiConfig, apiSmartChannel, apiSmartChannelConfig, apiDigitalRiver, apiDigitalRiverConfig };
