import React, { PropsWithChildren, ReactElement, useCallback, useContext, useState } from 'react';
import { HelpStepEnum, HelpVideoEnum } from 'js/enums';

export interface IHelpContext {
	step: HelpStepEnum;
	setStep: (value: HelpStepEnum) => void;
	activeVideoKey?: HelpVideoEnum;
	setVideo: (value: HelpVideoEnum) => void;
}

export type THelpContextProviderProps = {
	initStep?: HelpStepEnum;
};

/**
 * Create context
 * @type {React.Context<IHelpContext>}
 */
const HelpContext = React.createContext<IHelpContext>({} as IHelpContext);
HelpContext.displayName = 'HelpContext';

export const useHelpContext = () => useContext(HelpContext);

export const HelpContextProvider = (props: PropsWithChildren<THelpContextProviderProps>): ReactElement => {
	const [step, setStep] = useState<HelpStepEnum>(props.initStep || HelpStepEnum.HOME);
	const [activeVideoKey, setActiveVideoKey] = useState<HelpVideoEnum | undefined>(undefined);

	const setVideo = useCallback((key: HelpVideoEnum) => {
		setStep(HelpStepEnum.VIDEOS);
		setActiveVideoKey(key);
	}, []);

	const value: IHelpContext = {
		step,
		setStep,
		activeVideoKey,
		setVideo,
	};

	return (
		<HelpContext.Provider value={value}>
			<div
				className="component__help component__help-bg"
				data-step={step}
			>
				{props.children}
			</div>
		</HelpContext.Provider>
	);
};
