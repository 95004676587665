import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@avast/react-ui-components';
import { InitiateRefundContextProvider } from 'submodule/refunds/initiate/InitiateRefundContext';
import { AsyncModal, TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { STATUS_SUCCESS } from 'appConstants';
import { TChargeOrderId, TPeriodActivityUuid, TStandardOrderId } from 'types';

type TAsyncInitiateRefundModalProps = TAsyncModalContainerProps & {
	id: TStandardOrderId;
	chargeOrderId: TChargeOrderId;
	periodActivityUuid?: TPeriodActivityUuid;
	onSettled(): void;
};

export const AsyncInitiateRefundModal = (props: TAsyncInitiateRefundModalProps) => {
	const { forwardedRef } = props;
	const [t] = useTranslation('submodules');

	return (
		<AsyncModal
			ref={forwardedRef}
			size="xl"
			testId="initiateRefund"
			backdrop="static"
		>
			<Modal.Header>{t('refunds.initiate.title', { id: props.id })}</Modal.Header>
			<Modal.Body>
				<InitiateRefundContextProvider
					chargeOrderId={props.chargeOrderId}
					periodActivityUuid={props.periodActivityUuid}
					onError={() => {
						forwardedRef.current?.onCancel();
						props.onSettled();
					}}
					onSuccess={() => {
						forwardedRef.current?.onSuccess(STATUS_SUCCESS);
						props.onSettled();
					}}
					onCancel={() => forwardedRef.current?.onCancel()}
					onChangeView={(step) => {
						forwardedRef.current?.updateModalProps({ size: step === 'PRODUCTS' ? 'xl' : 'md' });
					}}
				/>
			</Modal.Body>
		</AsyncModal>
	);
};
