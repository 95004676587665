import { TUseApiListModule, useApiListQuery } from 'js/queries/useApiListQuery';
import { IEntityCountry, IEntityCountryDetail } from 'js/entities/country';
import { CACHE_TIME } from 'appConstants';
import { TUseApiDetailModule, useApiDetailQuery } from 'js/queries/useApiDetailQuery';

export type TUseApiCountryListModule = TUseApiListModule<IEntityCountry, { enabled?: boolean | null }>;

const API_COUNTRY_KEYS = {
	LIST: 'country',
	DETAIL: 'country/:countryCode',
};

export const useApiCountryList: TUseApiCountryListModule = (props) =>
	useApiListQuery(API_COUNTRY_KEYS.LIST, props, {
		queryConfig: {
			staleTime: CACHE_TIME.EXTRA_HIGH,
		},
	});

export const useApiCountry: TUseApiDetailModule<IEntityCountryDetail, { countryCode: string }> = (props) => {
	return useApiDetailQuery(API_COUNTRY_KEYS.DETAIL, props, {
		queryConfig: {
			staleTime: CACHE_TIME.EXTRA_HIGH,
		},
	});
};
