import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { IEntityDistributionPartner } from 'module/distributionPartners';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { joinValues } from 'js/utils/common';
import {
	getDistributionPartnerFullName,
	getDistributionPartnerRenderName,
} from 'module/distributionPartners/utils/distributionPartnerSelector';

type TDistributionPartnerCardProps = {
	distributionPartner: IEntityDistributionPartner;
	className?: string;
};

export const DistributionPartnerCard = (props: TDistributionPartnerCardProps): ReactElement => {
	const { distributionPartner } = props;
	const [t] = useTranslation(distributionPartnersConfig.trNamespace);
	const { billing, contact, website, taxId, id } = distributionPartner;

	return (
		<div className={props.className}>
			<p className="fw-bold">{getDistributionPartnerRenderName(distributionPartner)}</p>
			<ul className="mt-3 fs-sm reset">
				<li>
					{t('common:entity.partnerId')}: {id}
				</li>
				{Boolean(taxId) && (
					<li>
						{t('common:contact.taxId')}: {taxId}
					</li>
				)}
			</ul>
			<ul className="mt-3 fs-sm reset">
				<li>{billing.street}</li>
				<li>{joinValues([billing.city, billing.postalCode, billing.state])}</li>
				<li>{billing.country}</li>
			</ul>
			<ul className="mt-3 fs-sm reset">
				<li>{getDistributionPartnerFullName(distributionPartner)}</li>
				{Boolean(contact.email) && (
					<li>
						{t('common:contact.email')}: {contact.email}
					</li>
				)}
				{Boolean(website) && (
					<li>
						{t('common:contact.website')}: {website}
					</li>
				)}
			</ul>
		</div>
	);
};
