import { usePricingHeader } from 'module/purchase/hooks/pricing/usePricingHeader';
import { IPricingRequestItem } from 'module/purchase';
import { useMemo } from 'react';
import { resolvePricingCustomerSegment } from 'module/purchase/hooks/pricing/pricingUtils';
import { TUsePricingResponse, usePricing } from 'module/purchase/hooks/pricing/usePricing';
import { IComparativeData } from 'submodule/comparativeFeatures';
import { TransactionTypeEnum } from 'module/licenses/enums';
import { useOrderContext } from 'js/contexts';
import { usePriceListContext } from 'js/priceList/PriceListContext';

export const useComparativePricing = (comparativeData: IComparativeData | null): TUsePricingResponse => {
	const { priceListCode } = usePriceListContext();
	const {
		orderState: { customer },
	} = useOrderContext();

	// Prepare pricing fragments
	const header = usePricingHeader(priceListCode);
	const items: IPricingRequestItem[] = useMemo(() => {
		const customerSegment = resolvePricingCustomerSegment(customer);
		const items: IPricingRequestItem[] = [];
		if (!comparativeData) {
			return items;
		}
		comparativeData.products.forEach((list) => {
			list.forEach((item) => {
				items.push({
					reference_lineitem_id: item.product.sku,
					product: item.product.sku,
					customerSegment,
					lineTransactionType: TransactionTypeEnum.NEW,
					unitsToPrice: 1,
					unitsForTier: 1,
					customPricingInfo: {
						quantity: 1,
						customerId: customer?.id,
					},
				});
			});
		});

		return items;
	}, [comparativeData, customer]);

	return usePricing(header, items, { debounceDelay: 0 });
};
