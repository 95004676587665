import { IGenerateRetailCodesProduct } from 'module/retail';
import { TableColumnsDef } from 'types';
import React, { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { getProductValidityLabels } from 'module/purchase/utils/common';
import { FixedWidthWrapper } from 'js/components/molecules/Inputs/FixedWidthWrapper';
import { FormikControl } from 'js/components/formik/FormikControl';
import { CONFIG } from 'config';
import { IconButton, Tooltip } from '@avast/react-ui-components';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { useTranslation } from 'react-i18next';
import { retailConfig } from 'module/retail/retailConfig';
import type { Row } from '@tanstack/react-table';

type TTableData = IGenerateRetailCodesProduct;

export const useGenerateRetailCodesColumns = (
	onRemove: (row: Row<TTableData>) => void,
): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(retailConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();

		return [
			columnHelper.text('product.sku', { header: t('common:entity.sku') }),
			columnHelper.text('product.group.name', {
				header: t('common:entity.product'),
				meta: {
					formatters: [
						(value, { row }) => (
							<>
								<span className="d-block">
									<strong>{value}</strong>
								</span>
								<span className="fs-sm">{getProductValidityLabels(row.original.product).label}</span>
							</>
						),
					],
				},
			}),
			columnHelper.text('product.bulkQuantity', {
				header: t('common:entity.unit', { count: 0 }),
				meta: {
					formatters: [
						(unit, { row }) => {
							const unitLabel = t(`common:entity.unitType.${row.original.product.group.unitType}`, {
								count: unit,
							});
							return (
								<>
									{unit} {unitLabel.toLowerCase()}
								</>
							);
						},
					],
				},
			}),
			columnHelper.text('quantity', {
				header: t('common:entity.quantity'),
				meta: {
					formatters: [
						(value, { row }) => (
							<FixedWidthWrapper width={160}>
								<FormikControl.Number
									name={`products.${row.index}.quantity`}
									size="sm"
									min={1}
									max={CONFIG.MAX_QUANTITY.RETAIL_PARTNERS}
								/>
							</FixedWidthWrapper>
						),
					],
				},
			}),
			columnHelper.display({
				id: 'remove',
				meta: {
					align: 'end',
					formatters: [
						(_, { row }) => (
							<Tooltip
								content={t('generate.cart.removeTooltip')}
								placement="bottom"
							>
								<IconButton
									onClick={() => {
										onRemove(row);
									}}
									variant="light"
									iconFa={faTimes}
									size="sm"
									className="text-danger"
									testId={`remove_${row.index}`}
								/>
							</Tooltip>
						),
					],
				},
			}),
		];
	}, [t, onRemove]);
};
