import React, { ReactElement, ReactNode } from 'react';
import { FormMessage, H2 } from '@avast/react-ui-components';
import { Col, Row } from 'js/components/atoms/Row';
import { LinkButton } from 'js/components/atoms/Button';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';

type TReviewHeadlineProps = {
	id?: string;
	buttons?: ReactNode;
	linkToDetail: string;
	isQuote?: boolean;
};

export const ReviewHeadline = (props: TReviewHeadlineProps): ReactElement => {
	const { id, buttons, linkToDetail, isQuote } = props;
	const [t] = useTranslation(ordersConfig.trNamespace);

	return (
		<div className="component__detail-headline container-fluid py-2">
			<Row
				justify="between"
				align="center"
			>
				<Col sm="auto">
					<Row
						align="center"
						size="md"
					>
						<Col sm="auto">
							<LinkButton
								to={linkToDetail}
								iconLeftFa={faChevronLeft}
								size="sm"
								variant="outline-primary"
								testId="back"
							>
								{t('common:actions.back')}
							</LinkButton>
						</Col>
						<Col sm="auto">
							<H2
								tag="h2"
								variant="secondary"
								bold
							>
								{t(isQuote ? 'common.quoteNumber' : 'common.orderNumber', { id })}
							</H2>
							<FormMessage
								type="warning"
								className="mt-0"
							>
								{t('error.orderChangesAreNotPersist')}
							</FormMessage>
						</Col>
					</Row>
				</Col>
				<Col sm="auto">{buttons}</Col>
			</Row>
		</div>
	);
};
