import React, { PropsWithChildren, ReactElement } from 'react';
import type { TModalProps } from '@avast/react-ui-components';
import { Modal } from '@avast/react-ui-components';

type TModalWrapperProps = Pick<TModalProps, 'size' | 'testId' | 'show'> & {
	toggle: (show: boolean) => void;
	title: string;
};

export const ModalWrapper = (props: PropsWithChildren<TModalWrapperProps>): ReactElement => {
	const { title, children, toggle, ...rest } = props;
	return (
		<Modal
			onHide={() => toggle(false)}
			{...rest}
		>
			<Modal.Header>{title}</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
			<Modal.Footer cancelButton />
		</Modal>
	);
};
