/* eslint-disable no-template-curly-in-string */
export default {
	maxFileSize: '最大檔案大小：{{maxSize}} MB。',
	acceptFileType: '支援的檔案類型：{{types}}。',
	acceptFileType_other: '支援的檔案類型：{{types}}。',
	actions: {
		reset: '重設表單',
	},
	error: {
		file: {
			type: '選取的檔案不是受支援的類型。',
			maxSize: '選取的檔案太大 - {{size}} MB（上限為 {{maxSize}} MB）。',
		},
	},
	validator: {
		array: {
			max: '${path} 欄位必須少於或等於 ${max} 個項目。',
			min: '${path} 欄位必須有至少 ${min} 個項目。',
		},
		date: {
			max: '${path} 欄位必須早於 ${max}。',
			min: '${path} 欄位必須晚於 ${min}。',
		},
		mixed: {
			default: '${path} 無效。',
			notOneOf: '${path} 不得為下列其中一個值：${values}。',
			oneOf: '${path} 必須是下列其中一個值：${values}。',
			required: '${path} 是必要欄位。',
		},
		number: {
			integer: '${path} 必須是整數。',
			lessThan: '${path} 必須小於 ${less}。',
			max: '${path} 必須小於或等於 ${max}。',
			min: '${path} 必須大於或等於 ${min}。',
			moreThan: '${path} 必須大於 ${more}。',
			negative: '${path} 必須是負數。',
			positive: '${path} 必須是正數。',
		},
		string: {
			email: '${path} 必須是有效的電子郵件地址。',
			length: '${path} 必須正好是 ${length} 個字元。',
			link: '${path} 必須是有效的 URL。',
			lowercase: '${path} 必須是小寫字串。',
			matches: '${path} 必須符合以下項目：「${regex}」。',
			max: '${path} 最多只能有 ${max} 個字元。',
			min: '${path} 最少必須有 ${min} 個字元。',
			trim: '${path} 必須是已去除前後空白的字串。',
			uppercase: '${path} 必須是大寫字串。',
			url: '${path} 必須是有效的 URL。',
			uuid: '${path} 必須是有效的 UUID。',
		},
	},
};
