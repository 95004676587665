import React, { PropsWithChildren, ReactElement } from 'react';
import { Alert, Modal } from '@avast/react-ui-components';
import { ErrorCircleAnimated } from 'assets/image/icon';
import { useTranslation } from 'react-i18next';
import { IApiErrorResult } from 'types/api';
import { isUndefined } from 'lodash';
import { isDefined } from 'js/utils/common';

export type TApiErrorModalContentProps = {
	error: IApiErrorResult;
	onRetry?: (() => void) | null;
};

export const ApiErrorModalContent = (props: PropsWithChildren<TApiErrorModalContentProps>): ReactElement | null => {
	const { error, onRetry } = props;
	const [t] = useTranslation('common');
	let { title } = error;
	if (isUndefined(title)) {
		title = t('error:unspecifiedError');
	}

	return (
		<>
			<Modal.Header className="pb-0">{title}</Modal.Header>
			<Modal.Body className="py-4 py-md-8">
				<p className="text-center mb-4">
					<ErrorCircleAnimated />
				</p>
				{error.messages?.map((message, key) =>
					message ? (
						<Alert
							variant="danger"
							key={key}
							caption={message}
						/>
					) : null,
				)}
				{error.content}
				{(error.errorCode || error.requestId || error.errorData) && (
					<div className="mt-4">
						<p className="fw-bold fs-sm mb-1">{t('error:errorDetail')}:</p>
						<code className="bg-light text-start px-3 py-2 border fs-sm d-block">
							{error.requestId && (
								<>
									<b>{t('error:requestId')}:</b> {error.requestId}
									<br />
								</>
							)}
							{error.errorCode && (
								<>
									<b>{t('error:errorId')}:</b> {error.errorCode}
									<br />
								</>
							)}
							{error.errorData && (
								<>
									<b>{t('error:errorDetails')}:</b> {JSON.stringify(error.errorData)}
									<br />
								</>
							)}
						</code>
					</div>
				)}
			</Modal.Body>
			<Modal.Footer
				cancelButton={{
					children: t('actions.continue'),
					testId: 'continue',
				}}
				additionalButtons={
					isDefined(onRetry)
						? [
								{
									id: 'err-retry',
									children: t('actions.retry'),
									onClick: onRetry,
								},
						  ]
						: undefined
				}
			/>
		</>
	);
};
