import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum CreditTransactionSourceEnum {
	CANCELLED_LICENSE = 'cancelled-license',
	CANCELLED_ORDER = 'cancelled-order',
	MSP = 'msp',
	NEW_ORDER = 'new-order',
	OMS_REFUND = 'oms-refund',
	PAID_ORDER = 'paid-order',
	SAP = 'sap',
	SAP_TAX = 'sap-tax',
	OMS_TAX = 'oms-tax',
	ORACLE_TAX = 'oracle-tax',
	ADMIN_CUSTOM = 'admin-custom',
	PAY_AS_YOU_GO = 'pay-as-you-go',
	TAX_REFUND = 'tax-refund',
	PAY_AS_YOU_GO_CONSOLIDATION = 'pay-as-you-go-consolidation',
	SETTLED_ORDER = 'settled-order',
}

export const creditTransactionSourceEnumUtils = generateEnumUtils<CreditTransactionSourceEnum>(
	CreditTransactionSourceEnum,
	{ translatePath: 'enums:creditStatus.transactionSource' },
);
