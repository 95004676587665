import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TSelectEntityProps, TSelectEntityPropsWithChildren } from 'js/components/molecules/Modal';
import { ModalWrapper } from 'js/components/molecules/Modal/ModalWrapper';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { useApiDistributionPartner } from 'module/distributionPartners/hooks/useApiDistributionPartners';
import { IDistributionPartnerSearchFilter, IEntityDistributionPartner } from 'module/distributionPartners';
import { TPartnerId } from 'types';
import { SelectDistributionPartnerContainer } from 'module/distributionPartners/components/SelectDistributionPartnerContainer';

type TTableData = IEntityDistributionPartner;

export type TSelectDistributionPartnerModalExtraProps = {
	distributorId: TPartnerId | null;
};

export type TSelectDistributionPartnerModalProps = TSelectEntityProps<TTableData> &
	TSelectDistributionPartnerModalExtraProps;

/**
 * Select Distribution Partner
 * @param {TSelectDistributionPartnerModalProps} props
 * @return {ReactElement}
 * @constructor
 */
export const SelectDistributionPartnerModal = (
	props: TSelectEntityPropsWithChildren<TTableData, TSelectDistributionPartnerModalProps>,
): ReactElement => {
	// constants
	const { onChange, distributorId, value: distributionPartnerId } = props;

	// Component hooks
	const [t] = useTranslation(distributionPartnersConfig.trNamespace);
	const [entity, setEntity] = useState<TTableData | null>(null);
	const [modalShow, setModalShow] = useState<boolean>(false);

	// Filter
	const filter: IDistributionPartnerSearchFilter = {
		distributorId,
		id: distributionPartnerId,
	};

	// Get entity detail
	const {
		query: { isFetching },
	} = useApiDistributionPartner({
		filter,
		queryConfig: {
			enabled: Boolean(distributionPartnerId) && distributionPartnerId !== entity?.id,
			onSuccess(response) {
				if (Boolean(response.data)) {
					setEntity(response.data);
				}
			},
		},
	});

	// Reset value - clear
	const resetValue = useCallback(() => {
		onChange(null);
	}, [onChange]);

	// Function for set entity - after select from table
	const setValue = useCallback(
		(row: TTableData) => {
			onChange(row);
			setEntity(row);
			setModalShow(false);
		},
		[onChange],
	);

	return (
		<>
			{props.children({ setModalShow, resetValue, value: props.value, entity, loading: isFetching })}

			<ModalWrapper
				title={t('select.title')}
				toggle={setModalShow}
				show={modalShow}
				size="lg"
				testId="selectDistributionPartner"
			>
				<SelectDistributionPartnerContainer
					distributorId={distributorId}
					selectedDistributionPartnerId={distributionPartnerId}
					onSelect={setValue}
				/>
			</ModalWrapper>
		</>
	);
};
