import { SpendingOverview } from 'module/flexibleBilling/components/SpendingOverview';
import React from 'react';
import { useAuthContext } from 'js/contexts';
import { useApiFlexibleBillingDistributionPartnerSpendingOverview } from 'module/flexibleBilling/hooks';
import { TDistributionPartnerSalesforceId } from 'types';

type TDistributionPartnerSpendingOverviewProps = {
	className?: string;
	distributionPartnerId: TDistributionPartnerSalesforceId;
};

export const DistributionPartnerSpendingOverview = (props: TDistributionPartnerSpendingOverviewProps) => {
	const { authCompanyId, authCompany } = useAuthContext();
	const { data, query } = useApiFlexibleBillingDistributionPartnerSpendingOverview({
		filter: { distributorId: authCompanyId, id: props.distributionPartnerId },
	});

	return (
		<SpendingOverview
			currency={authCompany?.currencyCode}
			className={props.className}
			isLoading={query.isLoading}
			values={data}
			type="partner"
		/>
	);
};
