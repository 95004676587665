/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import { CONFIG } from 'config';
import { DEFAULT_ENTITY_VALUE } from 'appConstants';
import type { Logger } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { getCacheVersion } from 'api/cache';
import semver from 'semver';
import { IStandardOrderInstance } from 'module/purchase';
import { diffInDaysFromNow } from 'js/utils/dateTime';
import { TPartnerId } from 'types';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import { isFunction, noop, omit } from 'lodash';
import type { Location } from '@remix-run/router';
import * as process from 'process';

/**
 * Debug values to console
 * @param {any[]} args
 * @returns {void}
 */
export const logDebug = (...args: any[]): void => {
	if (CONFIG.DEBUG) {
		// eslint-disable-next-line no-console
		console.log('[oms-debug]', ...args);
	} else {
		Sentry.addBreadcrumb({
			type: 'oms-debug',
			level: Severity.Log,
			data: { args },
		});
	}
};

/**
 * Error values to console
 * @param {any[]} args
 * @returns {void}
 */
export const logError = (...args: any[]): void => {
	console.error('[oms-error]', ...args);
};

/**
 * Warning values to console
 * @param {any[]} args
 * @returns {void}
 */
export const logWarn = (...args: any[]): void => {
	console.warn('[oms-warning]', ...args);
};

export const errorIsCatchable = (error: AxiosError | null): boolean => {
	// Uncaught error would occur here, therefore check if exist `config` property
	let catchError = error?.config?.catchError !== false;
	if (isFunction(error?.config?.catchError)) {
		catchError = error?.config?.catchError(error!)!;
	}
	return catchError;
};

/**
 * Logger for Axios
 * @type {Logger}
 */
export const axiosLogger: Logger = {
	log(message) {
		// eslint-disable-next-line no-console
		console.log('[axios]', message);
	},
	error(error: AxiosError | null) {
		if (errorIsCatchable(error)) {
			console.error('[axios]', error, error?.response?.data);
		}
	},
	warn(message) {
		console.warn('[axios]', message);
	},
};

/**
 * Parse integer from env value
 * @param {string | undefined} value
 * @returns {number}
 */
export const parseEnvInt = (value: string | undefined): number => parseInt(value || '0', 10);

/**
 * Parse string from env value
 * @param {string | undefined} value
 * @returns {string}
 */
export const parseEnvString = (value: string | undefined): string => (value ? String(value) : '');

/**
 * Empty value
 * @param {string | ReactNode} text
 * @returns {JSX.Element}
 */
export const noValuePlaceholder = (text: string | ReactNode = DEFAULT_ENTITY_VALUE) => (
	<span className="text-muted">{text}</span>
);

/**
 * Get App versions stored on the server
 * @return {Promise<string | null>}
 */
const getServerAppVersion = async (): Promise<string | null> => {
	const response = await axios
		.get<{ version: string }>(CONFIG.APP_FILE_PATH, {
			params: { time: new Date().getTime() },
			headers: { 'content-type': 'application/json', 'Cache-Control': 'no-cache' },
			catchError: false,
		})
		.catch(noop);

	return response?.data?.version || null;
};

export const isAppVersionUpToDate = async (): Promise<boolean | null> => {
	const cacheVersion = await getCacheVersion();
	const serverAppVersion = await getServerAppVersion();

	if (!cacheVersion || !semver.valid(cacheVersion)) {
		return null;
	}

	if (!serverAppVersion || !semver.valid(serverAppVersion)) {
		return null;
	}

	return semver.gte(cacheVersion, serverAppVersion);
};

export const isEnvProduction = CONFIG.ENV === 'production';

/**
 * Returns 'true' if order expired or current partner is different from the instance
 *
 * @param {IStandardOrderInstance | null} instance
 * @param {TPartnerId | null} authPartnerId
 * @returns {boolean}
 */
export const isOrderInstanceObsolete = (
	instance: IStandardOrderInstance | null,
	authPartnerId?: TPartnerId | null,
): boolean => {
	if (!instance) {
		return true;
	}

	const isOrderExpired = diffInDaysFromNow(instance.timestamp) > 1;
	const isPartnerDifferent = Boolean(authPartnerId) && instance.authPartnerId !== authPartnerId;

	return isOrderExpired || isPartnerDifferent;
};

export const getLocationPathname = () => {
	const { pathname } = window.location;
	const prefix = process.env.PUBLIC_URL;

	return pathname.startsWith(prefix) ? pathname.slice(prefix.length) : pathname;
};

export const getReducedLocation = (location?: Location | null): Omit<Location, 'state'> | null =>
	location ? omit(location, ['state']) : null;

export const _t = (key: string): string => {
	logDebug('Untranslated value', key);
	return key;
};
