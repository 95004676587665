import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import { createAxiosInstance } from 'api/setupInterceptors';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteName } from 'module/RouteName';
import { ILogoutPageState } from 'module/security';
import { useAuthContext } from 'js/contexts';

export type TUseApiProps = {
	config?: AxiosRequestConfig;
};

export const useApi = (props?: TUseApiProps): AxiosInstance => {
	const { params, ...config } = props?.config || {};
	const {
		setAuthToken,
		authState: { refresh_token, token },
	} = useAuthContext();
	const navigate = useNavigate();
	const location = useLocation();

	return useMemo(() => {
		return createAxiosInstance({
			onNonAuthorized() {
				const state: ILogoutPageState = {
					keepState: true,
					sessionExpired: true,
					previousLocation: { to: location, state: location.state },
				};

				navigate(RouteName.SECURITY.LOGOUT, { state });
			},
			onRefreshToken(response) {
				setAuthToken(response);
			},
			authToken: token,
			refreshToken: refresh_token,
			...config,
		});
		// "config" is not memoized, do not put into deps
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, refresh_token, location, navigate, setAuthToken]);
};
