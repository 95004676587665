import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum WidgetTypeEnum {
	ORDERS = 'ORDERS',
	QUOTES = 'QUOTES',
	CREDIT_STATUS = 'CREDIT_STATUS',
	EXPIRING_LICENSES_NEXT_N_DAYS = 'EXPIRING_LICENSES_NEXT_N_DAYS',
	EXPIRED_LICENSES_LAST_N_DAYS = 'EXPIRED_LICENSES_LAST_N_DAYS',
	CLOSING_BALANCE = 'CLOSING_BALANCE',
}

interface IWidgetTypeEnumExtend {
	getCaption(value: WidgetTypeEnum): string;
}

export const widgetTypeEnumUtils = generateEnumUtils<WidgetTypeEnum, IWidgetTypeEnumExtend>(WidgetTypeEnum, {
	translatePath: 'enums:widgets.label',
	getCaption(value: WidgetTypeEnum): string {
		return `enums:widgets.caption.${value}`;
	},
});
