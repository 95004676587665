import type { AxiosResponse } from 'axios';

export const downloadFileFromApiResponse = (response: AxiosResponse, filename: string) => {
	const htmlLink = window.document.createElement('a');
	const blob = new Blob([response.data], { type: response.headers['content-type'] });

	htmlLink.href = URL.createObjectURL(blob);
	htmlLink.download = filename;

	window.document.body.appendChild(htmlLink);
	htmlLink.click();
	htmlLink.remove();
};
