import { useMemo } from 'react';
import type { TSelectOptions } from '@avast/react-ui-components';
import { useCountrySupportedCurrencies } from 'js/entities/country/useCountrySupportedCurrencies';

export const useCountrySupportedCurrenciesOptions = (countryCode?: string | null, enabled?: boolean) => {
	const { supportedCurrencies, query } = useCountrySupportedCurrencies(countryCode, enabled);
	const options: TSelectOptions = useMemo(
		() =>
			supportedCurrencies.map((currency) => ({
				value: currency.code,
				label: `${currency.code} (${currency.name})`,
			})),
		[supportedCurrencies],
	);

	return {
		options,
		query,
	};
};
