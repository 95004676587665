export default {
	actions: {
		create: 'Neue Werbeaktion erstellen',
		edit: 'Werbeaktion bearbeiten',
		disable: {
			action: 'Werbeaktion deaktivieren',
			success: 'Die Werbeaktion wurde deaktiviert',
			loading: 'Werbeaktion wird deaktiviert...',
			confirmation: {
				title: 'Werbeaktion wird deaktiviert',
				message: 'Möchten Sie diese Werbeaktion wirklich entfernen? Diese Aktion kann nicht rückgängig gemacht werden.',
			},
		},
		form: {
			create: 'Werbeaktion einreichen',
			update: 'Werbeaktion aktualisieren',
		},
		extraFilters: 'Weitere Filter',
		export: 'Werbeaktionen exportieren',
	},
	common: {
		effectiveDate: 'Datum des Inkrafttretens',
		filterPromotions: 'Werbeaktionen filtern',
		partyCriteria: 'Kriterien für Parteien',
		partyCriteriaInfo: 'Wählen Sie einen Partner oder mindestens ein Parteikriterium aus, um fortzufahren.',
		productCriteria: 'Produktkriterien',
		creatingPromotion: 'Werbeaktion wird erstellt',
		updatingPromotion: 'Werbeaktion wird aktualisiert',
		invalidPromotionStartDate: {
			title: 'Möchten Sie die Werbeaktion speichern?',
			message:
				'Das Startdatum der Werbeaktion liegt in der Vergangenheit. Sie können diese Werbeaktion nicht mehr bearbeiten.',
		},
	},
	error: {
		promotionNotFound: 'Werbeaktion nicht gefunden!',
		createPromotion: 'Die Werbeaktion kann nicht erstellt werden.',
		updatePromotion: 'Die Werbeaktion kann nicht aktualisiert werden.',
	},
	page: {
		create: {
			title: 'Neue Preisaktion',
			success: 'Sie haben eine neue Preisaktion erstellt.',
		},
		update: {
			title: 'Werbeaktion Nr. {{id}} aktualisieren',
			success: 'Sie haben die Preisaktion aktualisiert.',
			notEditable:
				'Die aktuelle Werbeaktion kann nicht bearbeitet werden, weil sie möglicherweise in einer Bestellung verwendet wird.',
		},
	},
	placeholder: {
		types: 'Alle Werbeaktionstypen',
		partyTiers: 'Alle Stufen',
		categories: 'Alle Kategorien',
		licenseOperations: 'Alle Lizenzvorgänge',
		partyCountries: 'Alle Länder',
		partyTypes: 'Alle Parteitypen',
		products: 'Alle Produkte',
	},
	entity: {
		id: 'Aktion-ID',
		name: 'Name der Werbeaktion',
		date: 'Datumsbereich für Gültigkeit',
		partyType: 'Parteityp',
		partyType_other: 'Parteitypen',
		partyTier: 'Parteistufe',
		partyTier_other: 'Parteistufen',
		partyCountry: 'Land der Partei',
		partyCountry_other: 'Länder der Parteien',
		licenseOperation: 'Lizenzvorgangstyp',
		licenseOperation_other: 'Lizenzvorgangstypen',
		category: 'Produktkategorie',
		product: 'Produkt',
		product_other: 'Produkte',
		quantityMin: 'Mindestanzahl',
		rate: 'Preisnachlass',
		scope: 'Umfang',
		type: 'Art der Werbeaktion',
		unitsFrom: 'Einheiten von',
		unitsTo: 'Einheiten bis',
	},
	tooltip: {
		zeroQuantity:
			'Die Anzahl Null bedeutet, dass die Werbeaktion unabhängig von der Anzahl der bestellten Lizenzen angewendet wird.',
	},
};
