import { useApiAutoBillingSource } from 'module/account/hooks/useApiAutoBilling';
import { autoBillingSourceApiError } from 'module/account/utils/apiError';
import { IApiErrorContextState, useApiErrorContext } from 'js/contexts';

type TUseAutoBillingCurrentCard = {
	isFetching: boolean;
	result: boolean | null;
};

export const useAutoBillingHasSource = (onErrorClose: IApiErrorContextState['onClose']): TUseAutoBillingCurrentCard => {
	const { setError } = useApiErrorContext();
	const {
		data,
		query: { isFetching, isError },
	} = useApiAutoBillingSource({
		queryConfig: {
			onError(error) {
				setError({
					error,
					resolve: autoBillingSourceApiError,
					onClose: onErrorClose,
				});
			},
		},
	});

	return {
		isFetching: isFetching || isError,
		result: Boolean(data?.paymentSourceId) && Boolean(data?.paymentSourceClientSecret),
	};
};
