import { camelCase, chunk, isEmpty, negate, toPairs } from 'lodash';
import { dateFormatter } from 'js/utils/dateTime';
import { DateTime } from 'luxon';
import { numberFormatter } from 'js/utils/number';
import { PromotionMessageType } from 'module/orders/enums/promotionMessageType';
import { CONFIG } from 'config';

type TPromotionMessageAttributes = {
	[x: string]: string;
};

export class PromotionMessageParser {
	private readonly message: string;
	private readonly currency?: string;

	private readonly attributesToLookUp: { percentOffPromo: string[]; qtyBasedPromo: string[] } = {
		percentOffPromo: ['Valid From', 'Valid To', 'Unit Price After Percentage-Off Promotions'],
		qtyBasedPromo: [
			'Valid From',
			'Valid To',
			'Off Qty of',
			'Amount Off',
			'Discount Per Unit',
			'Percentage-Off Promotions',
			'Unit Price After Percentage-Off Promotions',
			'Line Price Unit Price After Quantity-based  Promotions',
		],
	};

	private readonly priceAttributes: string[] = [
		'amountOff',
		'discountPerUnit',
		'linePrice',
		'unitPriceAfterQuantityBasedPromotions',
		'unitPriceAfterPercentageOffPromotions',
	];

	private readonly dateAttributes: string[] = ['validFrom', 'validTo'];

	public constructor(message: string, currency: string | undefined) {
		this.message = message;
		this.currency = currency;
	}

	public format() {
		const percentOffPromo = this.sortPromo(this.getPercentageOffPromotions());
		const qtyBasedPromo = this.sortPromo(this.getQuantityBasedPromotions());

		return [percentOffPromo, qtyBasedPromo];
	}

	private sortPromo(promotionArray: TPromotionMessageAttributes[]) {
		return promotionArray.map(toPairs).map(this.sortAttributes);
	}

	private sortAttributes(item: [string, string][]) {
		return item.sort((a, b) => {
			const aContainsPromotion = a.some((item) => item === 'promotion');
			const bContainsPromotion = b.some((item) => item === 'promotion');

			if (aContainsPromotion) {
				return -1;
			}
			if (bContainsPromotion) {
				return 1;
			}
			return 0;
		});
	}

	private getPercentageOffPromotions() {
		const start = this.message.indexOf(PromotionMessageType.PERCENTAGE_OFF_PROMOTIONS);
		const end = this.message.indexOf(PromotionMessageType.QUANTITY_BASED_PROMOTIONS);

		const promotionMessage = this.message.substring(start, end);
		const headlessPromotionMessage = this.removePromotionHead(
			promotionMessage,
			PromotionMessageType.PERCENTAGE_OFF_PROMOTIONS,
		);

		return this.parsePercentageOffPromotions(headlessPromotionMessage);
	}

	private getQuantityBasedPromotions() {
		const start = this.message.indexOf(PromotionMessageType.QUANTITY_BASED_PROMOTIONS);

		const promotionMessage = this.message.substring(start);
		const headlessPromotionMessage = this.removePromotionHead(
			promotionMessage,
			PromotionMessageType.QUANTITY_BASED_PROMOTIONS,
		);

		return this.parseQuantityBasedPromotions(headlessPromotionMessage);
	}

	private removePromotionHead(promotionMessage: string, head: string) {
		return promotionMessage.replace(`${head}:|`, '');
	}

	private parsePercentageOffPromotions(headlessPromotionMessage: string) {
		const splitMessage = headlessPromotionMessage.split('|').filter(negate(isEmpty));

		const postProcess = (promotionAttributes: TPromotionMessageAttributes) => {
			this.processAttributeValidTo(promotionAttributes);
			this.processDates(promotionAttributes);
			this.processPrice(promotionAttributes);

			return promotionAttributes;
		};

		return this.separatePromotions(splitMessage, this.attributesToLookUp.percentOffPromo, postProcess);
	}

	private parseQuantityBasedPromotions(headlessPromotionMessage: string) {
		const splitMessage = headlessPromotionMessage.split('|').filter(negate(isEmpty));
		const attrPairs = chunk(splitMessage, 2);
		const correctlySplitMessage = attrPairs.map((attrPair) => attrPair.join(' '));

		const postProcess = (promotionAttributes: TPromotionMessageAttributes) => {
			this.processAttributeValidTo(promotionAttributes);
			this.processDates(promotionAttributes);
			this.processPriceDetails(promotionAttributes);
			this.processPrice(promotionAttributes);

			return promotionAttributes;
		};

		return this.separatePromotions(correctlySplitMessage, this.attributesToLookUp.qtyBasedPromo, postProcess);
	}

	private separatePromotions(
		parts: string[],
		patterns: string[],
		postProcess: (value: TPromotionMessageAttributes) => TPromotionMessageAttributes,
	): TPromotionMessageAttributes[] {
		return parts.map((part) => {
			const preparedMessage = this.prepareMessage(part, patterns);
			const parsedMessage = preparedMessage.split('|').filter(negate(isEmpty));
			const promotionAttributes = this.convertToAttributes(parsedMessage);

			return postProcess(promotionAttributes);
		});
	}

	/**
	 * Prepare the message by removing certain characters and replacing specified
	 * patterns to be easier to parse attributes.
	 *
	 * @param {string} message - The original message to be prepared.
	 * @param {string[]} patterns
	 * @returns {string} - The prepared message.
	 */
	private prepareMessage(message: string, patterns: string[]): string {
		let clearMessage = message.replaceAll(/[:|,]/g, '');

		patterns.forEach((pattern) => {
			clearMessage = clearMessage.replace(pattern, `|${pattern}:`);
		});

		return clearMessage;
	}

	private convertToAttributes(messageParts: string[]): TPromotionMessageAttributes {
		const attributes: TPromotionMessageAttributes = {};
		messageParts.forEach((part) => {
			const [key, value] = part.split(':');

			attributes[camelCase(key)] = value.trim();
		});
		return attributes;
	}

	/**
	 * Processes the validTo attribute of a promotion message.
	 * This attribute contains two values: validTo and promotion name.
	 * Returns correct validTo and promotion attribute.
	 *
	 * @param {TPromotionMessageAttributes} attributes - The attributes object containing the validTo attribute.
	 * @return {void} - This method does not return a value.
	 */
	private processAttributeValidTo(attributes: TPromotionMessageAttributes): void {
		const mashedString = attributes.validTo;

		if (!mashedString) {
			return;
		}

		const [date, ...promotionParts] = mashedString.split(' ');

		attributes.validTo = date;
		attributes.promotion = promotionParts.join(' ').replace('  ', ': ').trim();
	}

	/**
	 * Processes the price details of a promotion message that is mixed together.
	 * Get the attribute linePriceUnitPriceAfterQuantityBasedPromotions and split the attributes and it's values.
	 *
	 * @param {TPromotionMessageAttributes} attributes - The attributes of the promotion message.
	 * @return {void}
	 */
	private processPriceDetails(attributes: TPromotionMessageAttributes): void {
		const mashedString = attributes.linePriceUnitPriceAfterQuantityBasedPromotions;

		if (!mashedString) {
			return;
		}

		const [firstPrice, secondPrice] = mashedString.split(' ');

		attributes.linePrice = firstPrice;
		attributes.unitPriceAfterQuantityBasedPromotions = secondPrice;
		delete attributes.linePriceUnitPriceAfterQuantityBasedPromotions;
	}

	/**
	 * Process date attributes and format the values to locale date format.
	 *
	 * @param {TPromotionMessageAttributes} attributes - The promotion message attributes object.
	 * @returns {void}
	 */
	private processDates(attributes: TPromotionMessageAttributes): void {
		const keys = Object.keys(attributes);
		const dateKeys = keys.filter((key) => this.dateAttributes.includes(key));

		dateKeys.forEach((key) => {
			const isoDate = DateTime.fromFormat(attributes[key], 'dd-MMM-yy', { zone: CONFIG.LOCALE.TIMEZONE }).toString();
			attributes[key] = dateFormatter.toDate(isoDate) ?? '';
		});
	}

	/**
	 * Returns the price values formatted with proper currency.
	 *
	 * @param {TPromotionMessageAttributes} attributes - The promotion message attributes object.
	 * @returns {void} - This method does not return a value.
	 */
	private processPrice(attributes: TPromotionMessageAttributes): void {
		const keys = Object.keys(attributes);
		const priceKeys = keys.filter((key) => this.priceAttributes.includes(key));

		priceKeys.forEach((key) => {
			attributes[key] = numberFormatter.currency(Number(attributes[key]), this.currency);
		});
	}
}
