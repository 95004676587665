import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum RefundReasonEnum {
	CUSTOMER_SATISFACTION_ISSUE = 'CUSTOMER_SATISFACTION_ISSUE',
	INCORRECT_PRICE_INVOICED = 'INCORRECT_PRICE_INVOICED',
	NOT_DELIVERED = 'NOT_DELIVERED',
	WRONG_PRODUCT = 'WRONG_PRODUCT',
	PRODUCT_NOT_WORKING = 'PRODUCT_NOT_WORKING',
	TAX_EXEMPT = 'TAX_EXEMPT',
	OTHER_REASON = 'OTHER_REASON',
}

export const refundReasonEnumUtils = generateEnumUtils<RefundReasonEnum>(RefundReasonEnum, {
	translatePath: 'enums:refunds.reason',
});
