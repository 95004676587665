import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

type TStepperVerticalContainerProps = {
	border?: boolean;
	variant?: 'light';
	className?: string;
};

export const StepperVerticalContainer = (props: PropsWithChildren<TStepperVerticalContainerProps>) => {
	const { border, variant, children } = props;
	const className = classNames('container-fluid', props.className, {
		'border-bottom': border,
		[`bg-${variant}`]: Boolean(variant),
	});

	return (
		<div className={className}>
			<div className="section__stepper-vertical">{children}</div>
		</div>
	);
};
