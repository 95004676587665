import React, { ReactElement } from 'react';
import { IEntityCustomer } from 'module/customers';
import { customersConfig } from 'module/customers/customersConfig';
import { useTranslation } from 'react-i18next';
import { getCustomerFullName, getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { joinValues } from 'js/utils/common';

type TCustomerCardProps = {
	customer: IEntityCustomer;
	className?: string;
};

export const CustomerCard = (props: TCustomerCardProps): ReactElement => {
	const { customer } = props;
	const [t] = useTranslation(customersConfig.trNamespace);
	const { billing, phone, email, website, id } = customer;

	return (
		<div className={props.className}>
			<p className="fw-bold">{getCustomerRenderName(customer)}</p>
			<ul className="mt-3 fs-sm reset">
				<li>
					{t('entity.contactId')}: {id}
				</li>
			</ul>
			<ul className="mt-3 fs-sm reset">
				<li>{billing.street}</li>
				<li>{joinValues([billing.city, billing.postalCode, billing.state])}</li>
				<li>{billing.country}</li>
			</ul>
			<ul className="mt-3 fs-sm reset">
				<li>{getCustomerFullName(customer)}</li>
				{Boolean(phone) && (
					<li>
						{t('common:contact.phone')}: {phone}
					</li>
				)}
				{Boolean(email) && (
					<li>
						{t('common:contact.email')}: {email}
					</li>
				)}
				{Boolean(website) && (
					<li>
						{t('common:contact.website')}: {website}
					</li>
				)}
			</ul>
		</div>
	);
};
