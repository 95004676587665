export default {
	accountInformation: 'Dati account',
	_: {
		dateRangePlaceholder: 'Seleziona data',
		NA: 'N/D',
		no: 'No',
		pageDetailTitle: 'Dettaglio n. {{id}}',
		yes: 'Sì',
		loading: 'Caricamento in corso...',
	},
	boolean: {
		true: 'Sì',
		false: 'No',
	},
	entity: {
		active: 'Attiva',
		amount: 'Importo',
		company: 'Società',
		companyId: 'ID azienda',
		companyName: 'Nome azienda',
		created: 'Creato',
		currency: 'Valuta',
		customer: 'Cliente',
		customerTotalPrice: 'Prezzo totale cliente',
		detailId: 'ID dettaglio',
		description: 'Descrizione',
		disabled: 'Disabilitato',
		discountAmount: 'Importo sconto',
		discountAmountUnit: 'Importo sconto per unità',
		discountPercent: 'Percentuale sconto',
		discountType: 'Tipo di sconto',
		discountUnitPrice: 'Prezzo unitario scontato',
		distributionPartner: 'Partner di distribuzione',
		enabled: 'Abilitato',
		id: 'ID',
		licenseKey: 'Chiave di licenza',
		name: 'Nome',
		orderStatus: 'Stato ordine',
		partnerId: 'ID partner',
		partner: 'di dist.',
		paymentAmount: 'Importo pagamento',
		paymentMethod: 'Metodo di pagamento',
		paymentStatus: 'Stato pagamento',
		poNumber: 'Numero ordine di acquisto',
		price: 'Prezzo',
		priceWTax: 'Prezzo incl. tasse',
		priceWOTax: 'Prezzo escl. tasse',
		product: 'Prodotto',
		productName: 'Nome prodotto',
		quantity: 'Quantità',
		requestedDiscountType: 'Tipo di sconto richiesto',
		secondaryOrderId: 'ID ordine secondario',
		sku: 'SKU',
		srpUnitPrice: 'Prezzo unitario SKU',
		state: 'Stato',
		status: 'Stato',
		subTotalWOTax: 'Subtotale escl. tasse',
		tax: 'tasse',
		total: 'Totale',
		totalAmount: 'Importo totale',
		totalAmountWOTax: 'Importo totale escl. tasse',
		totalAmountWTax: 'Importo totale incl. tasse',
		totalPrice: 'cliente',
		totalTax: 'Tasse totali',
		totalWTax: 'Totale incl. tasse',
		transaction: 'Transazione',
		unit: 'Unità',
		unit_other: 'Unità',
		unitPrice: 'Prezzo unitario',
		unitType: {
			SEATS: 'Postazione',
			SEATS_other: 'Postazioni',
			SESSIONS: 'Sessione',
			SESSIONS_other: 'Sessioni',
			STORAGE: 'Spazio di archiviazione',
			STORAGE_other: 'Spazio di archiviazione',
			DEVICE: 'Dispositivo',
			DEVICE_other: 'Dispositivi',
		},
		validity: 'Validità',
		variantCount: '{{count}} variante',
		variantCount_other: '{{count}} varianti',
	},
	contact: {
		firstName: 'Nome',
		lastName: 'Cognome',
		name: 'Nome Contatto',
		email: 'Indirizzo e-mail',
		billingEmail: 'Indirizzo e-mail di fatturazione',
		phone: 'Telefono',
		website: 'Sito Web',
		id: 'ID contatto',
		taxId: 'Partita IVA',
		secondaryTaxId: 'Partita IVA secondaria',
	},
	address: {
		caption: 'Indirizzo',
		city: 'Città',
		country: 'Paese',
		state: 'Provincia',
		postalCode: 'Codice postale',
		street: 'Via',
	},
	dates: {
		billing: 'Data di fatturazione',
		created: 'Data di creazione',
		end: 'Data di fine',
		expire: 'Data di scadenza',
		payment: 'Data di pagamento',
		paymentDue: 'Scadenza pagamento',
		start: 'Data di inizio',
	},
	datePeriod: {
		DAY: '{{count}} giorno',
		DAY_other: '{{count}} giorni',
		MONTH: '{{count}} mese',
		MONTH_other: '{{count}} mesi',
		YEAR: '{{count}} anno',
		YEAR_other: '{{count}} anni',
	},
	actions: {
		back: 'Indietro',
		newPurchase: 'Nuovo acquisto',
		cancel: 'Annulla',
		close: 'Chiudi',
		confirm: 'Conferma',
		continue: 'Continua',
		detail: 'Visualizza dettagli',
		download: 'Scarica',
		emptyCart: 'Svuota il carrello',
		logout: 'Esci',
		next: 'Avanti',
		previous: 'Indietro',
		reset: 'Riavvia',
		retry: 'Riprova',
		removeFile: 'Rimuovi file',
		save: 'salva',
		select: 'Seleziona',
		submitForm: 'Invia',
		submitChanges: 'Invia le modifiche',
		show: 'Mostra',
		showAll: 'Mostra tutto',
		showMore: 'Mostra altro',
		showLess: 'Mostra meno',
		copied: 'Copia avvenuta correttamente',
	},
	validator: {
		email: 'Il valore deve essere un indirizzo e-mail valido.',
	},
	table: {
		useFilter: 'Usa un filtro per avviare la ricerca',
	},
	filter: {
		quickFilters: 'Filtri rapidi',
		partnerIsRequired: 'Seleziona un partner per avviare la ricerca.',
		typeAtLeastNCharacters: 'Digita almeno {{count}} caratteri nel filtro.',
	},
	header: {
		cart: 'Carrello',
		cartIsEmpty: 'Il carrello è vuoto',
	},
	productGroupExceptions: {
		premiumRemoteControlQuantityDisabled:
			'Poiché Premium Remote Control può essere installato su un numero illimitato di dispositivi, non è necessario cambiare sessione per questi elementi: le sessioni simultanee si basano infatti sul numero di dispositivi a cui desideri collegarti contemporaneamente.',
		cloudBackupStorageInfo:
			'Modificando l’attributo Spazio di archiviazione, specifichi quanti bucket di archiviazione da 100 GB vuoi acquistare. Ad esempio, se imposti “Spazio di archiviazione = 5”, lo spazio di archiviazione finale acquistato sarà di 500 GB per una licenza.',
	},
	downloadFile: {
		loading: 'Download del file {{filename}} in corso',
		error: 'Impossibile scaricare il file',
	},
	appVersionGuard: {
		caption: 'È disponibile una nuova versione del sistema OMS!',
		message: 'Ricarica la pagina per ottenere la versione più recente.',
		action: 'Ricarica',
	},
};
