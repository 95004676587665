export default {
	requestId: 'ID richiesta',
	errorId: 'ID errore',
	errorDetails: 'Dettagli errore',
	api: {
		activeEntitlementNotFound:
			'Impossibile completare l’operazione sulla licenza perché l’autorizzazione è già stata sostituita.',
		invalidHash: 'Hash non valido.',
		distributionPartnerSalesforceConflict:
			'Il contatto fornito fa capo a un’altra organizzazione. Contatta il tuo account manager per maggiori dettagli.',
		passwordResetInvalidToken: 'Il token di reimpostazione della password non è valido.',
		passwordResetExpiredToken: 'Il token di reimpostazione della password è scaduto.',
		passwordAlreadyUsed: 'La password è già stata utilizzata in precedenza. Scegli un’altra password.',
		partnerNotRegistered:
			'Solo gli account registrati possono effettuare ordini. Chiedi al partner di completare prima la registrazione all’OMS.',
		nowTermsNotAccepted:
			'Solo gli account che hanno accettato termini e condizioni possono effettuare ordini. Chiedi al partner di accettare termini e condizioni aggiornati.',
		licenseOperationWrongSegment:
			'La parte soggetta a fatturazione selezionata non è idonea all’acquisto di prodotti per aziende/privati.',
		partnerQuoteCurrencyMismatch:
			'Impossibile elaborare l’ordine perché la valuta dell’account è diversa dalla valuta del preventivo. Contatta il tuo account manager e richiedi un nuovo preventivo.',
	},
	page: {
		404: {
			title: 'Messaggio dal reparto IT (pagina 404)',
			message: 'Si è verificato un errore, l’URL richiesto non esiste. Clicca di seguito per continuare.',
			back: 'Torna alla pagina iniziale',
		},
	},
	status: {
		networkError: 'Impossibile effettuare la connessione al server.',
	},
	common: {
		contactSales: 'Contatta il tuo account manager per assistenza.',
		repeatOrContactSales: 'Riprova più tardi o contatta il tuo account manager per assistenza.',
	},
	detailNotFound: 'Dettaglio non caricato o inesistente.',
	permissionDenied: 'Non disponi dell’autorizzazione necessaria per accedere a questa azione/risorsa.',
	sectionDataNotFound: 'Non sono stati trovati dati per questa sezione.',
	beforeUnload: 'Se interrompi l’operazione, i dati potrebbero andare persi.',
	unspecifiedError: 'Impossibile elaborare la richiesta',
	errorDetail: 'Dettagli tecnici (da fornire quando si segnala un errore)',
};
