import React from 'react';
import { useFormikContext } from 'formik';
import { TWidget } from 'submodule/widgets';
import { useAllowedWidgets } from 'submodule/widgets/hooks';
import { FormikControl } from 'js/components/formik/FormikControl';
import { useWidgetContext } from 'submodule/widgets/context';

export const ColumnsControl = () => {
	const { t } = useWidgetContext();
	const { values } = useFormikContext<TWidget>();
	const widgets = useAllowedWidgets();
	const columns = widgets.find((widget) => widget.type === values.type)?.getAvailableColumns?.();

	if (!columns) {
		return null;
	}

	return (
		<FormikControl label={t('widgets.config.columns')}>
			<FormikControl.MultiSelect
				name="columns"
				options={columns}
				closeMenuOnSelect={false}
				placeholder={t('widgets.config.columnsPlaceholder')}
				required
			/>
		</FormikControl>
	);
};
