export default {
	requestId: '要求 ID',
	errorId: '錯誤 ID',
	errorDetails: '錯誤詳細資料',
	api: {
		activeEntitlementNotFound: '由於權利已歸還，因此無法完成此授權操作。',
		invalidHash: '雜湊值無效。',
		distributionPartnerSalesforceConflict: '提供的聯絡人屬於不同的組織。請聯絡您的客戶經理以瞭解更多詳情。',
		passwordResetInvalidToken: '密碼重設 Token 無效。',
		passwordResetExpiredToken: '密碼重設 Token 已過期。',
		passwordAlreadyUsed: '此密碼過去已使用過。請選擇其他密碼。',
		partnerNotRegistered: '僅允許註冊帳戶下訂單。請要求合作夥伴先完成 OMS 註冊。',
		nowTermsNotAccepted: '允許接受條款與條件的帳戶下訂單。請要求合作夥伴接受更新的條款與條件。',
		licenseOperationWrongSegment: '選取的計費方沒有資格購買企業/消費性消費者產品。',
		partnerQuoteCurrencyMismatch: '由於帳戶貨幣與報價貨幣不同，因此無法處理此訂單。請聯絡您的客戶經理，索取新報價。',
	},
	page: {
		404: {
			title: '來自 IT 部門的訊息（404 頁面）',
			message: '發生錯誤，要求的 URL 不存在。按下方按鈕以繼續。',
			back: '返回首頁',
		},
	},
	status: {
		networkError: '無法連線至伺服器。',
	},
	common: {
		contactSales: '請聯絡您的客戶經理以尋求協助。',
		repeatOrContactSales: '請稍後再試一次，或聯絡您的客戶經理以尋求協助。',
	},
	detailNotFound: '無法載入詳細資料，或詳細資料不存在。',
	permissionDenied: '您無權存取此動作/資源。',
	sectionDataNotFound: '找不到此區段的資料。',
	beforeUnload: '如果您現在離開，可能會遺失您的資料。',
	unspecifiedError: '您的要求可能無法處理',
	errorDetail: '技術詳細資料（回報錯誤時提供）',
};
