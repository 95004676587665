import { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/fr-CA');
const locale: TLocale = 'fr-CA';

export const fr_CA: ILocaleConfig = {
	name: 'French (Canada)',
	locale,
	language: 'fr',
	format: {
		numbro,
		datetime: locale,
	},
};
