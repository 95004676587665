export default {
	actions: {
		create: 'Crea nuovo partner di dist.',
		edit: 'Modifica partner di distribuzione',
		exportAll: 'Esporta tutti i partner di dist.',
		form: {
			create: 'Salva come nuovo partner di distribuzione',
			update: 'Salva modifiche',
		},
	},
	error: {
		createDistributionPartner: 'Impossibile creare un partner di distribuzione.',
		updateDistributionPartner: 'Impossibile aggiornare il partner di distribuzione.',
	},
	page: {
		create: {
			title: 'Nuovo partner di distribuzione',
			success: 'Nuovo partner di distribuzione creato.',
		},
		update: {
			success: 'Hai aggiornato il partner di distribuzione.',
		},
	},
	select: {
		title: 'Seleziona partner di distribuzione',
		button: {
			label: 'Partner di distribuzione',
			placeholder: 'Seleziona partner di distribuzione',
		},
		filter: {
			placeholder: 'Ricerca per e-mail, nome o azienda',
		},
	},
};
