import React, { ReactElement } from 'react';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { usePageDetailContext } from 'js/contexts';
import { IEntityOrder } from 'module/orders';
import { CustomerDetail } from 'module/customers/components';

export const CustomerTab = (): ReactElement => {
	const {
		data: { customer },
	} = usePageDetailContext<IEntityOrder>();

	return (
		<DefaultContainer>
			<CustomerDetail
				customer={customer}
				linkable
				hideIncompleteData
			/>
		</DefaultContainer>
	);
};
