import { useCallback } from 'react';
import { logDebug } from 'js/utils/app';
import { useAsyncLoadOrderInstancePartner } from 'module/purchase/hooks/useAsyncLoadOrderInstancePartner';
import { usePartnerPriceListGuard, usePartnerTermsGuard } from 'module/purchase/hooks/guards';
import { TPartnerId } from 'types';
import { useOrderContext } from 'js/contexts';

export const useSetPartnerToOrderInstance = () => {
	const { setPartner } = useOrderContext();
	const asyncLoadOrderInstancePartner = useAsyncLoadOrderInstancePartner();
	const partnerPriceListGuard = usePartnerPriceListGuard();
	const partnerTermsGuard = usePartnerTermsGuard();

	return useCallback(
		async (partnerId: TPartnerId): Promise<boolean> => {
			// Get partner detail & price lists
			const orderPartner = await asyncLoadOrderInstancePartner(partnerId);
			logDebug('order partner and allowed price lists', orderPartner);

			if (!orderPartner) {
				throw new Error('Load partner detail and price lists failed.');
			}

			// Check terms
			const termsGuard = await partnerTermsGuard(orderPartner.partner);
			logDebug('termsGuard', termsGuard);
			if (!termsGuard) {
				return false;
			}

			// Check price lists
			const priceListGuard = await partnerPriceListGuard(orderPartner.priceLists);
			logDebug('priceListGuard', priceListGuard);
			if (!priceListGuard) {
				return false;
			}

			setPartner(orderPartner.partner, orderPartner.priceLists);
			return true;
		},
		[setPartner, asyncLoadOrderInstancePartner, partnerPriceListGuard, partnerTermsGuard],
	);
};
