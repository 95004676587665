export default {
	appName: 'Order Management System',
	menu: {
		closingBalance: 'Abschlusssaldo',
		creditLimit: 'Kreditlimit',
		creditStatus: 'Kreditstatus',
		customers: 'Kunden',
		dashboard: 'Start',
		distributionPartners: 'Vertriebspartner',
		licenses: 'Lizenzen',
		orders: 'Bestellungen',
		quotes: 'Angebote',
		purchase: 'Neuer Kauf',
		promotions: 'Aktionen',
		account: 'Mein Konto',
		help: 'Hilfe und Ressourcen',
		feedback: 'Feedback geben',
		retail: 'Produktcode',
		priceCalculator: 'Preisrechner',
		flexibleBilling: 'Flexible Abrechnungen',
	},
	help: {
		main: {
			title: 'Hilfe und Ressourcen',
			guide: {
				title: 'Das ist neu in OMS!',
				text: 'Hier stellen wir die wichtigsten Änderungen vor.',
				action: 'Rundgang beginnen',
			},
			videos: {
				title: 'Zu unseren Videoanleitungen',
			},
		},
		guide: {
			paginator: '{{current}} von {{total}}',
			slides: {
				welcome: {
					title: 'Willkommen beim neuen OMS',
					text: [
						'OMS hat ein neues Design! Lernen Sie unser neues Design, die benutzerfreundliche Oberfläche, die verbesserte Navigation und die optimale Leistung für die bestmögliche Benutzerfreundlichkeit beim Einkaufen kennen. Sehen Sie sich die Widgets auf der Homepage an, die Ihnen schnellen Zugriff auf Ihre letzten Bestellungen, Angebote, auslaufende Lizenzen und vieles mehr geben!',
					],
				},
				pricing: {
					title: 'Dynamische Preisangaben',
					text: [
						'Sie müssen nicht mehr warten, bis Ihre Bestellung neu berechnet wird, und es ist nicht nötig, Produkte in den Einkaufswagen zu legen, um Preise anzuzeigen. Wir zeigen die Preise jetzt direkt im Produktkatalog an!',
						'Wenn Sie die Anzahl der Lizenzen innerhalb eines Produktes oder die Anzahl an Produkten selbst ändern, werden die Preise automatisch neu berechnet, sodass Sie immer aktuelle Informationen erhalten. Der Bestellwert wird auch automatisch neu berechnet, wenn Sie Produkte in den Warenkorb legen oder daraus entfernen.',
					],
				},
				quickFilter: {
					title: 'Einfaches Filtern und Suchen nach Produkten für Privatanwender und Unternehmen',
					text: [
						'Wir haben einen Schnellfilter hinzugefügt, mit dem Sie mühelos Produkte anzeigen können, die nur für Verbraucher oder nur für Unternehmen bestimmt sind. Wenn Sie ein bestimmtes Produkt suchen, geben Sie einfach den Produktnamen oder Produktcode ein, und die Liste wird automatisch entsprechend Ihrer Eingabe aktualisiert.',
					],
				},
				licenseOperations: {
					title: 'Verlängerungen leicht gemacht',
					text: [
						'Wir haben den Neukauf von Lizenzvorgängen (wie Erneuerung oder Upgrade) getrennt, so dass Sie alle verfügbaren Lizenzvorgänge direkt bei der Anzeige von Lizenzen sehen können. Klicken Sie einfach auf die Schaltfläche für lizenzbedingte Vorgänge, die Sie in der Lizenzliste oder auf der Detailseite der Lizenz finden.',
					],
				},
				shoppingCart: {
					title: 'Einfacher Zugriff auf den Warenkorb',
					text: [
						'Wir haben ein Warenkorb-Symbol hinzugefügt, damit Sie leicht auf Ihren Einkaufswagen zugreifen und mit der Bestellung fortfahren können, wann immer Sie bereit sind.',
						'Sie können Neuanschaffungen und Lizenzvorgänge (z. B. Verlängerungen) problemlos in einer Bestellung kombinieren.',
					],
				},
				quickAccess: {
					title: 'Schneller Zugriff auf alle benötigten Aufzeichnungen',
					text: [
						'Wir haben an verschiedenen Stellen in OMS Schnellstarttasten und Links eingefügt, damit Sie leicht auf Informationen zu dem Datensatz zugreifen können, den Sie gerade betrachten. Verwenden Sie die Schaltflächen “Lizenzen anzeigen” und “Bestellungen anzeigen” auf der Kundendetailseite.',
						'Öffnen Sie auf der Seite mit den Lizenzdetails eine betreffende Bestellung oder zeigen Sie Kundendetails an, indem Sie auf die hervorgehobenen Links klicken.',
					],
				},
				quotes: {
					title: 'Leicht zugängliche Angebote',
					text: [
						'Wir haben einen separaten Menüpunkt für Angebote eingeführt, die Sie von Ihrem Account Manager erhalten, sodass Sie diese leichter finden können. Diese Angebote sind auch unter den Bestellungen zu finden und leicht zugänglich.',
					],
				},
				finalStep: {
					title: 'Geschafft!',
					text: [
						'Wir hoffen, dass die neu gestaltete Benutzeroberfläche mit neuen Funktionen und verbesserter Leistung OMS noch angenehmer für Sie machen wird.',
						'Bitte senden Sie uns Ihr Feedback und Ihre Verbesserungsvorschläge, indem Sie auf den Menüpunkt „Feedback geben" klicken.',
						'Sie können diese einführende Anleitung später jederzeit wieder aufrufen, indem Sie im Menü auf “Hilfe und Ressourcen” klicken.',
					],
				},
			},
		},
		videos: {
			title: 'Videoanleitungen',
			list: {
				navigations: 'Navigieren in OMS',
				newPurchase: 'Erstellen einer neuen Bestellung',
				renewal: 'Erstellen einer Verlängerungsbestellung',
				quotes: 'Erhalten eines Angebots vom Account Manager',
			},
		},
	},
};
