import type { TColorVariant } from '@avast/react-ui-components';
import { generateEnumUtils } from 'js/enums/generator/enumFunctions';
import { logError } from 'js/utils/app';

export enum PromotionStateEnum {
	ENABLED = 'ENABLED',
	DISABLED = 'DISABLED',
}

interface IPromotionStateEnumExtend {
	getColorVariant(key: PromotionStateEnum): TColorVariant;
}

export const promotionStateEnumUtils = generateEnumUtils<PromotionStateEnum, IPromotionStateEnumExtend>(
	PromotionStateEnum,
	{
		translatePath: 'enums:promotion.state',
		getColorVariant(key) {
			switch (key) {
				case PromotionStateEnum.DISABLED:
					return 'danger';
				case PromotionStateEnum.ENABLED:
					return 'success';
				default:
					logError(`Not supported promotion state: ${key}`);
					return 'danger';
			}
		},
	},
);
