import React, { useCallback } from 'react';
import { IEntityLegacyPaymentOption } from 'module/account';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { logError } from 'js/utils/app';
import { useApiDeletePaymentOption } from 'module/account/hooks/useApiPaymentOption';
import { useApiErrorContext, useAppContext, useAuthContext } from 'js/contexts';

export const useDeletePaymentOption = (paymentOption: IEntityLegacyPaymentOption) => {
	const { loadingModalRef, confirmationModalRef } = useAppContext();
	const { setError } = useApiErrorContext();
	const [t] = useTranslation(accountConfig.trNamespace);
	const { authCompany: company } = useAuthContext();
	const { mutateAsync } = useApiDeletePaymentOption({
		config: {
			params: {
				affiliateId: company?.id,
				id: paymentOption.id,
			},
		},
	});

	return useCallback(async (): Promise<boolean> => {
		const confirm = await confirmationModalRef.current?.show({
			title: t('creditCard.delete.title'),
			messages: [t('creditCard.delete.confirmation', { lastFourDigits: paymentOption?.creditCard.lastFourDigits })],
			submitButtonText: t('common:actions.confirm'),
		});

		if (!confirm) {
			return false;
		}

		loadingModalRef.current?.show({
			title: t('creditCard.delete.loading'),
			ellipsis: true,
		});

		const response = await mutateAsync({}).catch((error) => {
			logError('Save source data failed', error);
			setError({ error });
		});

		if (response) {
			toast.success(<Toast>{t('creditCard.message.successDelete')}</Toast>);
		}

		loadingModalRef.current?.hide();

		return Boolean(response);
	}, [paymentOption, mutateAsync, t, confirmationModalRef, loadingModalRef, setError]);
};
