import { TApiListResponse } from 'types/api';
import { IEntityOrderDocument } from 'js/chargeOrder';
import { IVatInvoicesItem, IVatInvoicesResponse } from 'js/components/chargeOrderDocuments';
import { CONFIG } from 'config';
import { faFileCsv, faFilePdf, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import type { TColorVariant } from '@avast/react-ui-components';
import { faFileArchive } from '@fortawesome/free-regular-svg-icons';
import { TEventDetailId } from 'types';
import { OrderDocumentTypeEnum, OrderDocumentUrlType } from 'js/enums';
import { isString } from 'lodash';

const itemToDocument = (item: IVatInvoicesItem, documentType: OrderDocumentTypeEnum): IEntityOrderDocument => ({
	documentType,
	urlType: OrderDocumentUrlType.WEB,
	url: `${CONFIG.APP.GC_VANITY_URL}${item.sapInvoiceUri}`,
	id: String(item.taxReceiptID),
});

export const responseToDocuments = (response: IVatInvoicesResponse): TApiListResponse<IEntityOrderDocument> => {
	const documents: TApiListResponse<IEntityOrderDocument> = [];
	const { VATInvoices, creditMemos } = response;

	VATInvoices?.forEach((item) => {
		documents.push(itemToDocument(item, OrderDocumentTypeEnum.VAT_INVOICE));
	});

	creditMemos?.forEach((item) => {
		documents.push(itemToDocument(item, OrderDocumentTypeEnum.CREDIT_MEMO));
	});

	return documents;
};

export const getDocumentFileIcon = (document: IEntityOrderDocument): IconDefinition => {
	switch (document.documentType) {
		case OrderDocumentTypeEnum.PAYG_PRODUCT_USAGE_REPORT:
			return faFileCsv;
		case OrderDocumentTypeEnum.PAYG_REPORTS_ALL:
			return faFileArchive;
		default:
			return faFilePdf;
	}
};

export const getDocumentFileIconColorVariant = (document: IEntityOrderDocument): TColorVariant => {
	switch (document.documentType) {
		case OrderDocumentTypeEnum.PAYG_PRODUCT_USAGE_REPORT:
			return 'success';
		case OrderDocumentTypeEnum.PAYG_REPORTS_ALL:
			return 'warning';
		default:
			return 'danger';
	}
};

export const getDocumentFileExtension = (document: IEntityOrderDocument): string => {
	switch (document.documentType) {
		case OrderDocumentTypeEnum.PAYG_PRODUCT_USAGE_REPORT:
			return 'csv';
		case OrderDocumentTypeEnum.PAYG_REPORTS_ALL:
			return 'zip';
		default:
			return 'pdf';
	}
};

export const getDocumentFileName = (id: TEventDetailId, document: IEntityOrderDocument): string => {
	if (isString(document.fileName)) {
		return document.fileName;
	}

	const extension = getDocumentFileExtension(document);
	return `${id}-${document.documentType}.${extension}`;
};
