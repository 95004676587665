import React, { createContext, PropsWithChildren, useContext } from 'react';
import { TWidgetGrid } from 'submodule/widgets';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { useUserWidgetConfig } from 'submodule/widgets/hooks';

interface IWidgetContext {
	t: TFunction;
	loading: boolean;
	config: TWidgetGrid;
	isEditable?: boolean;
}

const WidgetContext = createContext<IWidgetContext>({ t: i18n.getFixedT(null, 'submodules') } as IWidgetContext);

WidgetContext.displayName = 'WidgetContext';

export const useWidgetContext = () => useContext(WidgetContext);

export const WidgetContextProvider = (props: PropsWithChildren<{}>) => {
	const [t] = useTranslation('submodules');
	const { config, loading } = useUserWidgetConfig();
	const isEditable = true;

	return <WidgetContext.Provider value={{ t, config, loading, isEditable }}>{props.children}</WidgetContext.Provider>;
};
