import React, { ReactElement } from 'react';
import { ButtonComposition, H1 } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { usePageDetailContext } from 'js/contexts';
import { IEntityOrder } from 'module/orders';
import { Col, Row } from 'js/components/atoms/Row';
import { QuoteStatusOverview } from 'module/orders/components/QuoteStatusOverview';
import { CancelOrderButton, EditOrderButton } from 'module/orders/components/buttons';
import { PriceSummary } from 'module/orders/components/PriceSummary';

export const QuoteStatusTab = (): ReactElement => {
	const [t] = useTranslation(ordersConfig.trNamespace);
	const { data: quote } = usePageDetailContext<IEntityOrder>();

	return (
		<DefaultContainer>
			<Row
				multi
				size="sm"
				justify="between"
			>
				<Col md="auto">
					<H1 type="h2">{t('common.quoteNumber', { id: quote.id })}</H1>
				</Col>
				<Col md="auto">
					<PriceSummary />
				</Col>
			</Row>

			<ButtonComposition marginY>
				<EditOrderButton />
				<CancelOrderButton text={t('actions.cancelQuote')} />
			</ButtonComposition>

			<QuoteStatusOverview />
		</DefaultContainer>
	);
};
