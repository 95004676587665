import React from 'react';
import { useAuthContext, usePageDetailContext } from 'js/contexts';
import { IEntityOrder } from 'module/orders';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { Button } from '@avast/react-ui-components';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { isOrderRefundable } from 'module/orders/utils/common';
import { AsyncInitiateRefundModal } from 'submodule/refunds/initiate/AsyncInitiateRefundModal';

export const InitiateRefundButton = () => {
	const {
		data: order,
		query: { isFetching, refetch },
	} = usePageDetailContext<IEntityOrder>();
	const { isRoleSalesOperations, authPartnerGroups } = useAuthContext();
	const [t] = useTranslation(ordersConfig.trNamespace);
	const modalRef = useAsyncModalRef();

	if (!isRoleSalesOperations || !isOrderRefundable(order, authPartnerGroups)) {
		return null;
	}

	return (
		<>
			<Button
				size="sm"
				loading={isFetching}
				variant="outline-primary"
				onClick={() => modalRef.current?.show()}
				testId="initiateRefund"
			>
				{t('common.initiateRefund')}
			</Button>

			<AsyncInitiateRefundModal
				forwardedRef={modalRef}
				id={order.id}
				chargeOrderId={order.chargeOrderId!}
				onSettled={refetch}
			/>
		</>
	);
};
