import { IPartnerGroups } from 'module/partners';
import { useCallback } from 'react';
import { CONFIG } from 'config';
import { TLocaleLanguage } from 'types/config';
import { useAllowedLanguages } from 'js/hooks/useAllowedLanguages';

export const useValidateLanguage = (
	partnerGroups: IPartnerGroups,
): ((language: TLocaleLanguage) => TLocaleLanguage) => {
	const allowedLanguages = useAllowedLanguages(partnerGroups);
	return useCallback(
		(language) => {
			if (allowedLanguages.includes(language)) {
				return language;
			}

			return CONFIG.LOCALE.DEFAULT_LANGUAGE;
		},
		[allowedLanguages],
	);
};
