import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { numberFormatter } from 'js/utils/number';
import { IComparativeProductData } from 'submodule/comparativeFeatures';
import { getProductValidityLabels } from 'module/purchase/utils/common';
import { ComparativeTableVariantEnum } from 'submodule/comparativeFeatures/enums';

type TPriceCellProps = {
	isLoading: boolean;
	colSpan?: number;
	price?: number;
	currencyCode?: string;
	productData: IComparativeProductData;
	productUnits?: number;
	variant: ComparativeTableVariantEnum;
};

export const PriceCell = (props: TPriceCellProps) => {
	const { colSpan, isLoading, price, currencyCode, productData, productUnits = 1, variant } = props;
	const [t] = useTranslation('submodules');
	const { product, productGroup } = productData;
	const productValidityLabels = getProductValidityLabels(product);

	// Prepare bottom text
	const unitType = t(`common:entity.unitType.${productGroup.unitType}`, { count: productUnits }).toLowerCase();
	const textBottom = [
		productValidityLabels.value,
		t('comparativeFeatures.price.units', { count: productUnits, unitType }),
	].join(' / ');

	return (
		<td
			key={`${productData.productGroup.code}_price`}
			colSpan={colSpan}
		>
			{variant !== 'LICENSE_OPERATION' && (
				<small className="text-muted d-block">{t('comparativeFeatures.price.from')}</small>
			)}
			<div className="fw-bold price text-nowrap">
				{isLoading ? <Skeleton width={100} /> : numberFormatter.currency(price, currencyCode)}
			</div>
			<small className="text-muted d-block">{textBottom}</small>
		</td>
	);
};
