import { IApiError, TApiErrorResolve } from 'types/api';
import i18n from 'i18next';
import { accountConfig } from 'module/account/accountConfig';
import { apiErrorResolve } from 'js/utils/apiError';
import { API_ERR_CODE } from 'constant';

export const autoBillingSourceApiErrorMessage = (error?: IApiError): string | null => {
	if (error?.errorCode === API_ERR_CODE.GC_INVALID_PAYMENT_SOURCE) {
		return i18n.t(accountConfig.trPrefix('error.autoBillingSource'));
	}

	return null;
};

export const autoBillingSourceApiError: TApiErrorResolve = (error) => {
	const errorMessage = autoBillingSourceApiErrorMessage(error);
	if (errorMessage) {
		return {
			title: null,
			messages: [errorMessage],
		};
	}

	return apiErrorResolve(error);
};
