import { dateToApiDate } from 'js/utils/dateTime';
import { ICreditLimitListApiFilter, ICreditLimitListFilter } from 'module/creditLimit';

export const creditLimitFilterNormalizer = {
	denormalize(filter: ICreditLimitListFilter): ICreditLimitListApiFilter {
		const apiFilter: ICreditLimitListApiFilter = {
			partnerId: filter.partnerId,
		};

		if (filter.dateRange) {
			apiFilter.createdDateFrom = dateToApiDate(filter.dateRange[0]);
			apiFilter.createdDateTo = dateToApiDate(filter.dateRange[1], true);
		}

		return apiFilter;
	},
};
