import React, { PropsWithChildren, useRef } from 'react';
import { AuthContextProvider, OrderContextProvider } from 'js/contexts';
import { IAuthContextSubscriber } from 'types';

export const UserProviders = (props: PropsWithChildren<{}>) => {
	const authContextSubscriberRef = useRef<IAuthContextSubscriber>(null);

	return (
		<AuthContextProvider authContextSubscriberRef={authContextSubscriberRef}>
			<OrderContextProvider authContextSubscriberRef={authContextSubscriberRef}>{props.children}</OrderContextProvider>
		</AuthContextProvider>
	);
};
