import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum RefundTypeEnum {
	ORDER = 'ORDER_REFUND',
	LINE = 'LINE_REFUND',
	KEY = 'KEY_REFUND',
	TAX = 'TAX_REFUND',
}

export const refundTypeEnumUtils = generateEnumUtils<RefundTypeEnum>(RefundTypeEnum, {
	translatePath: 'enums:refunds.type',
});
