export default {
	error: {
		creditStatusNotFound: 'Stato del credito non trovato.',
	},
	tabs: {
		detail: 'Riepilogo credito',
		transactions: 'Elenco transazioni',
	},
	entity: {
		companyName: 'Società',
		countryCode: 'Paese',
		creditAvailable: 'Credito disponibile',
		creditLimit: 'Limite di credito',
		percentConsumed: '% utilizzata',
		unbilledBalance: 'Saldo non fatturato',
		unpaidBalance: 'Saldo non pagato',
	},
	entityShort: {
		creditAvailable: 'Disponibile',
		creditLimit: 'Limite',
		percentConsumed: '% utilizzata',
		unbilledBalance: 'Non fatturato',
		unpaidBalance: 'Non pagato',
	},
	entityTransaction: {
		amount: 'Importo',
		createdAt: 'Creato',
		orderId: 'ID riferimento',
		source: 'Origine',
	},
	widget: {
		name: 'Tipo',
		value: 'Credito',
	},
};
