import React, { ReactElement } from 'react';
import { IEntityCreditStatus, IEntityCreditStatusFilter } from 'module/creditStatus';
import { PageDetailComponent } from 'js/layouts/page/PageDetailComponent';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle/DynamicPageTitle';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { RouteName } from 'module/RouteName';
import { useApiCreditStatus } from 'module/creditStatus/hooks/useApiCreditStatus';
import { creditStatusConfig } from 'module/creditStatus/creditStatusConfig';
import { useTranslation } from 'react-i18next';
import { CreditStatusDetail, CreditTransactionTable } from 'module/creditStatus/components';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { Tab, Tabs } from 'react-bootstrap';
import { buildParametrizedRoute } from 'js/utils/common';
import { getCreditStatusRenderName } from 'module/creditStatus/utils/creditStatusSelectors';
import { useTabsTestAttributes } from 'js/hooks/testAttributes/useTabsTestAttributes';
import { Alert } from '@avast/react-ui-components';
import type { TFunction } from 'i18next';

type TData = IEntityCreditStatus;
type TQueryFilter = IEntityCreditStatusFilter;
type TDetailProps = TQueryFilter;
type TErrorContent = (t: TFunction) => ReactElement;

export const Detail = (props: TDetailProps): ReactElement => {
	const [t] = useTranslation(creditStatusConfig.trNamespace);
	const { id } = props;
	const testAttributes = useTabsTestAttributes('creditStatus');

	return (
		<PageDetailComponent<TData, TQueryFilter>
			query={useApiCreditStatus}
			queryProps={{ id }}
			trNamespace={creditStatusConfig.trNamespace}
			titleRender={getCreditStatusRenderName}
			getErrorContent={() => getErrorContent(t)}
		>
			{({ title }) => (
				<>
					<DynamicPageTitleItem text={title} />
					{Boolean(id) && (
						<DynamicBreadcrumbsItem href={buildParametrizedRoute(RouteName.CREDIT_STATUS.DETAIL, id)}>
							{title}
						</DynamicBreadcrumbsItem>
					)}

					<Tabs
						defaultActiveKey="detail"
						{...testAttributes}
					>
						<Tab
							eventKey="detail"
							title={t('tabs.detail')}
						>
							<DefaultContainer>
								<CreditStatusDetail />
							</DefaultContainer>
						</Tab>
						<Tab
							eventKey="transactions"
							title={t('tabs.transactions')}
						>
							<DefaultContainer>
								<CreditTransactionTable />
							</DefaultContainer>
						</Tab>
					</Tabs>
				</>
			)}
		</PageDetailComponent>
	);
};

const getErrorContent: TErrorContent = (t): ReactElement => (
	<Alert
		variant="danger"
		caption={t('error.creditStatusNotFound')}
	/>
);
