export default {
	common: {
		forecastedSpend: '予測される支出',
		currentSpend: '現在の支出',
		maxSpend: '最大支出',
		limit: '限度',
		noLimit: '限度なし',
		searchDistributionPartner: '販売パートナーを名前で検索',
		unlimited: '無制限',
		unlimitedNote:
			'使用制限が設定されていない場合、最大支出額はクレジット限度を超える可能性があります。これが発生した場合、クレジット限度額が増額されるか残高が減額されるまで、パートナーの製品は非アクティブ化されます。',
		valueBefore: '前',
		valueAfter: '変更を保存した後',
	},
	page: {
		title: 'フレキシブル請求',
	},
	actions: {
		edit: '製品を管理する',
		viewOrders: '注文を表示',
		enableProducts: '製品を有効化',
		enableAllProducts: 'すべての製品を有効化',
		disableAllProducts: 'すべての製品を無効化',
	},
	tab: {
		distributionPartners: 'パートナーの概要',
		overview: '概要',
		reports: 'レポート',
	},
	modal: {
		confirmation: 'よろしいですか？',
		message: '保存しようとしている変更はあなたの信用に影響を与える可能性があります。続行しますか？',
		apply: '変更を適用する',
		success: '変更は正常に適用されました',
	},
	entity: {
		usageLimit: '使用制限',
		yourPrice: '価格',
	},
	invite: {
		info: 'このパートナーはまだビジネス ハブ アカウントを作成していません。このパートナーに対してフレキシブル請求を設定できますが、ビジネス ハブ アカウントが確立されるまでは機能しません。',
		action: {
			send: '招待状を送信',
			resend: '招待状を再送信',
		},
		lastSent: '最後の招待状は {{date}} に {{email}} 宛てに送信されました。',
		processing: '当社は販売パートナーに招待状を送信します',
		success: '招待状は正常に送信されました',
		description:
			'ビジネス ハブの登録のための招待メールを送信します。このアカウントはハブにおける最初の管理アカウントになります (その後、追加の管理者を追加できます)。登録が完了すると、新しいビジネス ハブ アカウントがパートナーのレコードにリンクされます。',
		form: {
			create: {
				label: 'このパートナーを保存するときにビジネス ハブを作成する',
				description:
					'このフォームを保存すると、ビジネス ハブの登録のための招待メールが送信されます。このアカウントはハブにおける最初の管理アカウントになります (その後、追加の管理者を追加できます)。登録が完了すると、新しいビジネス ハブ アカウントがパートナーのレコードにリンクされます。',
			},
			differentEmail: {
				partner: 'レコードにある電子メールに招待状を送信',
				other: '別の電子メールに招待状を送信',
			},
		},
		modal: {
			title: '{{name}}さんのビジネス ハブを作成',
		},
	},
	tooltips: {
		unlimitedMaxSpend:
			'使用制限が設定されていない場合、最大支出額はクレジット限度を超える可能性があります。これが発生した場合、クレジット限度額が増額されるか残高が減額されるまで、パートナーの製品は非アクティブ化されます。',
		forecastedSpend: {
			distributor: 'すべてのパートナーの製品の使用量が現在のままである場合に、現在の請求期間中に費やされる金額。',
			partner: 'このパートナーの製品の使用量が現在のままである場合に、現在の請求期間中に費やされる金額。',
		},
		currentSpend: {
			distributor: '現在の請求期間中に、すべてのパートナーの有効な製品に対してすでに費やされた金額。',
			partner: '現在の請求期間中に、このパートナーの有効な製品に対してすでに費やされた金額。',
		},
		maxSpend: {
			distributor: 'すべてのパートナーのすべてのアクティブな製品が使用制限に達した場合に請求できる最大金額。',
			partner: 'このパートナーのすべてのアクティブな製品が使用制限に達した場合に請求できる最大金額。',
		},
	},
	error: {
		usageLimit: {
			title: '現在、この変更を保存できません。',
			message:
				'設定しようとしている製品の使用制限は、パートナーがビジネス ハブで顧客向けに設定した使用制限の合計よりも低くなっています。',
			product: '<strong>{{product}}</strong> の制限の合計は、現在 <strong>{{limit}}</strong> に設定されています。',
			note: '使用制限についてパートナーと相談することをお勧めします。<br />サポートが必要な場合は、当社のサポート チームにお問い合わせください。',
		},
	},
};
