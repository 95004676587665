import type { Resource } from 'i18next';
import i18n from 'i18next';
import { CONFIG } from 'config';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { languages } from 'i18n/languages';
import { mapValues, pickBy } from 'lodash';
import { LOCALE } from 'config/locale';
import { localesConfig } from 'i18n/locales';

// Dictionary of translations
i18n
	.use(LanguageDetector)
	.use(initReactI18next) // passes react-i18n down to i18next
	.init({
		resources: languages as Resource,
		fallbackLng: {
			...mapValues(
				pickBy(localesConfig, (config) => Boolean(config.languageFallback)),
				'fallback',
			),
			default: [LOCALE.DEFAULT_LANGUAGE],
		},
		lng: LOCALE.DEFAULT_LANGUAGE,
		supportedLngs: false,
		nonExplicitSupportedLngs: true,
		detection: { order: [''] }, // Disable detection - we set up it from user config
		defaultNS: 'app',
		debug: CONFIG.DEBUG,
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		react: { useSuspense: false },
		returnNull: false,
	});

export default i18n;
