import { Modal, Toast } from '@avast/react-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { IEntityDistributionPartner } from 'module/distributionPartners';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { useApiUpdateDistributionPartner } from 'module/distributionPartners/hooks/useApiDistributionPartners';
import { DistributionPartnerForm } from 'module/distributionPartners/forms/DistributionPartnerForm';
import { updateDistributionPartnerApiError } from 'module/distributionPartners/utils/apiError';
import { distributionPartnerFormNormalizer } from 'module/distributionPartners/normalizer';
import { useApiErrorContext, useAuthContext } from 'js/contexts';

type TAsyncUpdateDistributionPartnerModalProps = {
	distributionPartner?: IEntityDistributionPartner | null;
	onUpdate: (distributionPartner: IEntityDistributionPartner) => void;
};

export const AsyncUpdateDistributionPartnerModal = (
	props: TAsyncUpdateDistributionPartnerModalProps & TAsyncModalContainerProps,
) => {
	const { onUpdate, distributionPartner, forwardedRef } = props;
	const [t] = useTranslation(distributionPartnersConfig.trNamespace);
	const { authCompanyId } = useAuthContext();
	const { setError } = useApiErrorContext();
	const { mutate: updateDistributionPartner } = useApiUpdateDistributionPartner({
		config: { params: { distributorId: authCompanyId!, salesforceId: distributionPartner?.id! } },
	});

	if (!distributionPartner) {
		forwardedRef.current?.onCancel();
		return null;
	}

	return (
		<>
			<Modal.Header>{distributionPartner?.name}</Modal.Header>
			<Modal.Body>
				<DistributionPartnerForm
					initialValues={distributionPartnerFormNormalizer.normalize(distributionPartner)}
					onSubmit={(values, { setSubmitting }) => {
						updateDistributionPartner(values, {
							onSuccess(response) {
								setSubmitting(false);
								if (Boolean(response?.data)) {
									toast.success(<Toast>{t('page.update.success')}</Toast>);
									onUpdate(response.data);
								}
							},
							onError(error) {
								setError({ error, resolve: updateDistributionPartnerApiError });
								setSubmitting(false);
							},
						});
					}}
					onCancel={() => {
						forwardedRef.current?.onCancel();
					}}
				/>
			</Modal.Body>
		</>
	);
};
