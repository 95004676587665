import { Tooltip } from '@avast/react-ui-components';
import React, { ReactElement } from 'react';

export type TEllipsisTextProps = {
	value?: string;
	length?: number;
	tail?: string;
};

export const EllipsisText = (props: TEllipsisTextProps): ReactElement | null => {
	const { value, length = 40, tail = '...' } = props;
	const valueLength = value?.length || 0;
	const tailLength = tail.length;

	if (!value) {
		return null;
	}

	if (valueLength <= length) {
		return <>{value}</>;
	}

	const valueTruncated = value.slice(0, length - tailLength);

	return (
		<Tooltip
			content={value}
			placement="top"
		>
			<span>
				{valueTruncated.trim()}
				{tail}
			</span>
		</Tooltip>
	);
};
