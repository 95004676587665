import { TPartnerPriceLists } from 'js/priceList';
import { useCallback } from 'react';
import { useApi } from 'js/hooks/api';
import { IEntityPartner } from 'module/partners';
import type { AxiosRequestConfig } from 'axios';
import { apiPartnerPriceListsPromise } from 'js/priceList/apiPartnerPriceLists';

export const useAsyncApiPartnerPriceLists = () => {
	const api = useApi();

	return useCallback(
		(partnerId: IEntityPartner['id'], config?: AxiosRequestConfig): Promise<TPartnerPriceLists | null> => {
			return apiPartnerPriceListsPromise(partnerId, config, api);
		},
		[api],
	);
};
