import { IEntityPartnerDetail } from 'module/partners';
import { useApiPartnerBySalesforceId } from 'module/partners/hooks/useApiPartners';
import { useCustomerDetail } from 'module/customers/hooks/useCustomerDetail';
import { IEntityCustomer } from 'module/customers';
import { buildParametrizedRoute, isDefined } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { IEntityLicense } from 'module/licenses';
import { IDistributionPartnerSearchFilter, IEntityDistributionPartner } from 'module/distributionPartners';
import { useApiDistributionPartner } from 'module/distributionPartners/hooks/useApiDistributionPartners';
import { customersConfig } from 'module/customers/customersConfig';
import { getLicenseExternalIds } from 'module/licenses/utils/common';
import { useAuthContext } from 'js/contexts';

export type TUseLicensePartner = {
	isLoading: boolean;
	isFetching: boolean;
	partner: IEntityPartnerDetail | null;
	hasPartner: boolean;
};

type TUseLicenseCustomer = {
	isLoading?: boolean;
	isFetching?: boolean;
	customer: IEntityCustomer | null;
	customerId?: IEntityCustomer['id'] | null;
	customerLink: string | null;
	hasCustomer: boolean;
};

type TUseLicenseDistributionPartner = {
	isLoading?: boolean;
	isFetching?: boolean;
	distributionPartner: IEntityDistributionPartner | null;
	distributionPartnerId?: IEntityDistributionPartner['id'] | null;
	distributionPartnerLink: string | null;
	hasDistributionPartner: boolean;
};

/**
 * @param {IEntityLicense} license
 * @returns {TUseLicensePartner}
 */
export const useLicensePartner = (license: IEntityLicense): TUseLicensePartner => {
	const { partnerSalesforceId } = getLicenseExternalIds(license);
	const { isGroupPartner, authCompanySalesforceId, authCompany } = useAuthContext();

	// Use AuthPartner data if
	//  | the logged partner is from Partner group - is not allowed to load details of other partners
	//  | license SF id is same with the logged partner
	const isAuthPartner =
		isGroupPartner || (Boolean(partnerSalesforceId) && authCompanySalesforceId === partnerSalesforceId);

	const {
		data: partner,
		query: { isInitialLoading, isFetching },
	} = useApiPartnerBySalesforceId({
		id: partnerSalesforceId,
		queryConfig: { enabled: Boolean(partnerSalesforceId) && !isAuthPartner },
	});

	return isAuthPartner
		? {
				partner: authCompany,
				isLoading: false,
				isFetching: false,
				hasPartner: true,
		  }
		: {
				partner,
				isLoading: isInitialLoading,
				isFetching,
				hasPartner: Boolean(partnerSalesforceId),
		  };
};

export const useLicenseCustomer = (license: IEntityLicense): TUseLicenseCustomer => {
	const partnerQuery = useLicensePartner(license);
	const { customerId } = getLicenseExternalIds(license);
	const { data: customer, query } = useCustomerDetail({
		customerId,
		partnerId: partnerQuery.partner?.id,
		enabled: !partnerQuery.isLoading,
	});

	return {
		customer,
		isLoading: query.isInitialLoading || partnerQuery.isLoading,
		isFetching: query.isFetching || partnerQuery.isFetching,
		hasCustomer: Boolean(customerId),
		customerId,
		customerLink: customer ? customersConfig.detailLink(customer) : null,
	};
};

export const useLicenseDistributionPartner = (license: IEntityLicense): TUseLicenseDistributionPartner => {
	const { isRoleDistributor, isGroupSales, authCompanyId } = useAuthContext();
	const partnerQuery = useLicensePartner(license);
	const { distributionPartnerId } = getLicenseExternalIds(license);
	const { data: distributionPartner, query } = useApiDistributionPartner({
		filter: {
			id: distributionPartnerId,
			distributorId: partnerQuery.partner?.id,
		},
		queryConfig: { enabled: Boolean(distributionPartnerId) && Boolean(partnerQuery.partner?.id) },
	});

	let link: TUseLicenseDistributionPartner['distributionPartnerLink'] = null;
	if (isDefined(distributionPartner)) {
		let linkPartnerId: IDistributionPartnerSearchFilter['distributorId'];
		if (isRoleDistributor) {
			linkPartnerId = authCompanyId;
		} else if (isGroupSales && isDefined(partnerQuery.partner?.id)) {
			linkPartnerId = partnerQuery.partner?.id;
		}

		if (linkPartnerId) {
			link = buildParametrizedRoute<IDistributionPartnerSearchFilter>(RouteName.DISTRIBUTION_PARTNERS.DETAIL, {
				id: distributionPartner.id!,
				distributorId: linkPartnerId,
			});
		}
	}

	return {
		distributionPartner,
		isLoading: query.isInitialLoading || partnerQuery.isLoading,
		isFetching: query.isFetching || partnerQuery.isFetching,
		hasDistributionPartner: Boolean(distributionPartnerId),
		distributionPartnerId,
		distributionPartnerLink: link,
	};
};
