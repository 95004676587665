import React, { memo, ReactElement, useCallback, useState } from 'react';
import { UiTable } from '@avast/react-ui-components';
import { filterData } from 'js/utils/common';
import { IEntityProductGroup } from 'module/purchase';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import {
	AsyncSelectVariantModal,
	TAsyncSelectVariantModalProps,
} from 'module/purchase/components/selectProducts/AsyncSelectVariantModal';
import {
	SelectProductsFilter,
	TSelectProductFilter,
} from 'module/purchase/components/selectProducts/SelectProductsFilter';
import { PriceListMixWarning } from 'module/purchase/components/selectProducts/PriceListMixWarning';
import {
	AsyncComparativeTableModal,
	TAsyncComparativeTableModalProps,
} from 'submodule/comparativeFeatures/AsyncComparativeTableModal';
import { getComparativeData } from 'submodule/comparativeFeatures/comparativeFeaturesUtils';
import { ProductGroupTagsEnum } from 'js/enums';
import {
	IProductGroupWithPrice,
	useSelectProductsGroupsWithPrices,
} from 'module/purchase/hooks/useSelectProductsGroupsWithPrices';
import { extractProductGroupCode, sortProductGroupsByTag } from 'module/purchase/utils/common';
import { useSelectProductsColumns } from 'module/purchase/components/selectProducts/useSelectProductsColumns';
import { useOrderContext } from 'js/contexts';

type TTableData = IProductGroupWithPrice;
type TSelectProductsProps = {
	onAddItem?: () => void;
};

const _SelectProducts = (props: TSelectProductsProps): ReactElement => {
	const { onAddItem } = props;
	const [filter, setFilter] = useState<TSelectProductFilter>({
		searchValue: '',
		entity: { firstPurchaseEnabled: true },
	});
	const { billableParty, orderMarketSegments } = useOrderContext();
	const currencyCode = billableParty?.currencyCode;
	const selectVariantRef = useAsyncModalRef<TAsyncSelectVariantModalProps>();
	const comparativeTableRef = useAsyncModalRef<TAsyncComparativeTableModalProps, IEntityProductGroup>();
	const sortProductGroupsByTagNew = sortProductGroupsByTag(ProductGroupTagsEnum.NEW);

	// Api
	const {
		data: productGroups,
		query: { isFetching, isError },
		isPricingLoading,
	} = useSelectProductsGroupsWithPrices();

	// Select product group
	const selectProductGroup = useCallback(
		async (selectedProductGroup: IEntityProductGroup) => {
			let productGroup = selectedProductGroup;
			const comparativeData = getComparativeData(productGroups, selectedProductGroup.code);
			if (comparativeData) {
				const _productGroup = await comparativeTableRef.current?.show({
					comparativeData,
					currencyCode,
				});
				if (_productGroup) {
					productGroup = _productGroup;
				} else {
					return;
				}
			}

			if (await selectVariantRef.current?.show({ productGroup })) {
				onAddItem?.();
			}
		},
		[selectVariantRef, comparativeTableRef, onAddItem, productGroups, currencyCode],
	);

	// Columns
	const columns = useSelectProductsColumns({
		isLoading: isPricingLoading,
		onClick: selectProductGroup,
	});

	return (
		<>
			<SelectProductsFilter
				filter={filter}
				onChange={setFilter}
				marketSegments={orderMarketSegments}
			/>
			<PriceListMixWarning />
			<UiTable<TTableData>
				testId="products"
				data={filterData<TTableData>(productGroups.sort(sortProductGroupsByTagNew), filter.entity)}
				columns={columns}
				state={{ globalFilter: extractProductGroupCode(filter.searchValue) || filter.searchValue }}
				onGlobalFilterChange={(searchValue: string) => setFilter((values) => ({ ...values, searchValue }))}
				meta={{
					isScrollable: true,
					loading: isFetching,
					loadingType: 'BAR',
					customError: isError,
					onRowClick: selectProductGroup,
				}}
			/>

			<AsyncSelectVariantModal forwardedRef={selectVariantRef} />
			<AsyncComparativeTableModal forwardedRef={comparativeTableRef} />
		</>
	);
};

export const SelectProducts = memo(_SelectProducts);
