export default {
	common: {
		discountWaitingToApprove: 'Lo sconto è in attesa dell’approvazione del responsabile delle vendite.',
		selectCurrencyModalTitle: 'Seleziona la valuta del cliente',
		otherCustomerInformation: 'Altro',
	},
	entity: {
		contactId: 'ID cliente',
		customerType: 'Tipo di cliente',
		discount: 'Sconto',
		industry: 'Settore',
	},
	error: {
		createCustomer: 'Impossibile creare il cliente.',
		updateCustomer: 'Impossibile aggiornare il cliente.',
	},
	select: {
		title: 'Seleziona cliente',
		button: {
			placeholder: 'Seleziona cliente',
			label: 'Cliente',
		},
		filter: {
			placeholder: 'Trova cliente',
			placeholderEmail: 'Trova cliente tramite l’indirizzo e-mail',
			placeholderType: 'Tipo di cliente',
		},
		type: {
			EMAIL: 'Indirizzo e-mail',
			ID: 'ID Salesforce',
		},
	},
	page: {
		create: {
			title: 'Nuovo cliente',
			success: 'Nuovo cliente creato.',
			form: {
				email: 'Usa un indirizzo e-mail autentico a cui il cliente abbia accesso.',
			},
		},
		update: {
			success: 'Hai aggiornato il cliente.',
		},
	},
	actions: {
		edit: 'Modifica cliente',
		exportAll: 'Esporta tutti i clienti',
		newCustomer: 'Crea nuovo cliente',
		relatedLicenses: 'Visualizza le licenze correlate',
		relatedOrders: 'Visualizza gli ordini correlati',
		relatedQuotes: 'Visualizza i preventivi correlati',
		viewLicenses: 'Visualizza licenze',
		viewOrders: 'Visualizza ordini',
		viewQuotes: 'Visualizza preventivi',
		form: {
			create: 'Salva come nuovo cliente',
			update: 'Salva modifiche',
		},
	},
};
