import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum PromotionPartyTierEnum {
	TIER_1 = 'TIER_CERTIFICATION_TIER_1',
	TIER_2 = 'TIER_CERTIFICATION_TIER_2',
	TIER_3 = 'TIER_CERTIFICATION_TIER_3',
	TIER_4 = 'TIER_CERTIFICATION_TIER_4',
	DISTRIBUTORS = 'TIER_CERTIFICATION_DISTRIBUTORS',
	NONE = 'TIER_CERTIFICATION_NONE',
}

export const promotionPartyTierEnumUtils = generateEnumUtils<PromotionPartyTierEnum>(PromotionPartyTierEnum, {
	translatePath: 'enums:promotion.partyTier',
});
