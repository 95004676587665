import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useCallback } from 'react';
import { useAuthContext } from 'js/contexts';

export type TPartyPrefixType = 'srp' | 'customer' | 'quotedCustomer' | 'partner';
export type TUsePartyPrefixTranslation = {
	tPartyPrefix(key: string, type?: TPartyPrefixType): string;
};

export const usePartyPrefixTranslation = (ns?: string): TUsePartyPrefixTranslation => {
	const [t] = useTranslation(ns);
	const { isGroupPartner } = useAuthContext();

	const prefixType = isGroupPartner ? 'your' : 'partner';

	return {
		tPartyPrefix: useCallback(
			(key, type) => {
				const prefix = t(purchaseConfig.trPrefix(`common.partyPrefix.${type || prefixType}`));
				return `${prefix} ${t(key)}`;
			},
			[t, prefixType],
		),
	};
};
