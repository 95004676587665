import { TFlexibleBillingForm } from 'module/flexibleBilling';
import { FLEXIBLE_BILLING_UNLIMITED_VALUE } from 'module/flexibleBilling/constants';

export const getFlexibleBillingFormSum = (values?: TFlexibleBillingForm): number => {
	return (
		values?.reduce((acc, item) => {
			if (acc === FLEXIBLE_BILLING_UNLIMITED_VALUE) {
				return acc;
			}

			if (!item.enabled) {
				return acc;
			}

			if (item.usageLimitEnabled) {
				return acc + item.usageLimit * item.currentPrice;
			}

			return FLEXIBLE_BILLING_UNLIMITED_VALUE;
		}, 0) || 0
	);
};
