import React, { ChangeEvent, ReactElement, useCallback } from 'react';
import { FormMessage } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { IEntityProductGroup } from 'module/purchase';
import { FormikControl } from 'js/components/formik/FormikControl';
import { TSelectVariantForm, TSelectVariantItem } from 'module/purchase/components/selectProducts';
import { numberFormatter } from 'js/utils/number';
import { CustomTable } from 'js/components/molecules/CustomTable';
import Skeleton from 'react-loading-skeleton';
import { isGroupBusiness, isGroupConsumer } from 'module/purchase/utils/selectors';
import { usePartyPrefixTranslation } from 'module/purchase/hooks/usePartyPrefixTranslation';
import { FixedWidthWrapper } from 'js/components/molecules/Inputs/FixedWidthWrapper';
import {
	getOrderItemMaxQuantity,
	getProductValidityLabels,
	isQuantityChangeDisabled,
} from 'module/purchase/utils/common';
import { ProductGroupExceptions } from 'js/components/molecules/Exceptions';
import { useSelectVariantPricing } from 'module/purchase/hooks/pricing/useSelectVariantPricing';
import { PriceListMixWarning } from 'module/purchase/components/selectProducts/PriceListMixWarning';
import { TransactionTypeEnum } from 'module/licenses/enums';
import { useOrderContext } from 'js/contexts';
import { usePriceListContext } from 'js/priceList/PriceListContext';

export type TSelectVariantTableProps = {
	productGroup: IEntityProductGroup;
};

export const SelectVariantTable = (props: TSelectVariantTableProps): ReactElement => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { tPartyPrefix } = usePartyPrefixTranslation('components');
	const { values, setFieldValue } = useFormikContext<TSelectVariantForm>();
	const orderContext = useOrderContext();
	const { billableParty, isEndCustomerBillableParty } = orderContext;
	const { priceListCode } = usePriceListContext();
	const { pricing, isPricingLoading } = useSelectVariantPricing();

	const { productGroup } = props;
	const currencyCode = billableParty?.currencyCode;
	const isBusiness = isGroupBusiness(productGroup);
	const isConsumer = isGroupConsumer(productGroup);

	const onCheckboxChanged = useCallback(
		(checked: boolean, row: TSelectVariantItem, index: number) => {
			const name = isConsumer ? 'quantity' : 'unit';
			const currentValue = get<TSelectVariantItem, keyof TSelectVariantItem, number>(row, name, 0);
			const newValue = checked ? currentValue || 1 : 0;

			setFieldValue(`variant.${index}.${name}`, newValue, true);
		},
		[setFieldValue, isConsumer],
	);

	return (
		<>
			<PriceListMixWarning />
			<ProductGroupExceptions
				priceListCode={priceListCode}
				transactionType={TransactionTypeEnum.NEW}
				productCode={productGroup.code}
			/>
			<CustomTable borderless>
				<thead>
					<tr>
						<CustomTable.Th />
						<CustomTable.Th
							align="center"
							description={t('tableVariant.tooltip.quantity')}
						>
							{t('common:entity.quantity')}
						</CustomTable.Th>
						<CustomTable.Th
							align="center"
							description={t(`tableVariant.tooltip.unitType.${productGroup.unitType}`)}
						>
							{t(`common:entity.unitType.${productGroup.unitType}`, { count: 0 })}
						</CustomTable.Th>
						<CustomTable.Th align="end">{tPartyPrefix('common:entity.totalPrice', 'customer')}</CustomTable.Th>
						{!isEndCustomerBillableParty && (
							<CustomTable.Th align="end">{tPartyPrefix('common:entity.totalPrice')}</CustomTable.Th>
						)}
					</tr>
				</thead>
				<tbody>
					{values.variant.map((row, i) => {
						const isPricingAble = isConsumer ? row.quantity !== 0 : row.unit !== 0;
						const pricingItem = isPricingAble
							? pricing?.lineitems.find((item) => item.reference_lineitem_id === row.product.sku)
							: undefined;
						const labels = getProductValidityLabels(row.product);

						// Prices
						let customerPrice = 0;
						let partnerPrice = 0;
						if (isPricingAble) {
							customerPrice = pricingItem?.customerPrice?.total || 0;
							partnerPrice = pricingItem?.linePriceWOTax || 0;
							if (isBusiness) {
								customerPrice *= row.quantity || 1;
								partnerPrice *= row.quantity || 1;
							}
						}

						return (
							<tr key={i}>
								<CustomTable.Td>
									<FormikControl groupProps={{ className: 'mb-0' }}>
										<FormikControl.Checkbox
											testId={row.product.sku}
											name={`variant.${i}.checked`}
											label={labels.label}
											description={labels.description}
											onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxChanged(e.target.checked, row, i)}
										/>
									</FormikControl>
								</CustomTable.Td>
								<CustomTable.Td align="center">
									<FixedWidthWrapper width={160}>
										<FormikControl groupProps={{ className: 'mb-0' }}>
											<FormikControl.Number
												testId={`${row.product.sku}_quantity`}
												name={`variant.${i}.quantity`}
												min={isConsumer ? 0 : 1}
												max={isConsumer ? getOrderItemMaxQuantity(orderContext, row) : undefined}
												onChange={(value) => {
													if (isConsumer) {
														setFieldValue(`variant.${i}.checked`, value && value > 0, true);
													}
												}}
												disabled={isBusiness && !row.checked}
											/>
										</FormikControl>
									</FixedWidthWrapper>
								</CustomTable.Td>
								<CustomTable.Td align="center">
									<FixedWidthWrapper width={160}>
										<FormikControl groupProps={{ className: 'mb-0' }}>
											<FormikControl.Number
												testId={`${row.product.sku}_unit`}
												name={`variant.${i}.unit`}
												min={isBusiness ? 0 : row.unit}
												max={isBusiness ? getOrderItemMaxQuantity(orderContext, row) : undefined}
												onChange={(value) => {
													if (isBusiness) {
														setFieldValue(`variant.${i}.checked`, value && value > 0, true);
													}
												}}
												disabled={!isBusiness || isQuantityChangeDisabled(productGroup.code)}
											/>
										</FormikControl>
									</FixedWidthWrapper>
								</CustomTable.Td>
								<CustomTable.Td align="right">
									{isPricingLoading && isPricingAble ? (
										<Skeleton width={100} />
									) : (
										`${numberFormatter.currency(customerPrice, currencyCode)}*`
									)}
								</CustomTable.Td>
								{!isEndCustomerBillableParty && (
									<CustomTable.Td align="right">
										{isPricingLoading && isPricingAble ? (
											<Skeleton width={100} />
										) : (
											`${numberFormatter.currency(partnerPrice, currencyCode)}*`
										)}
									</CustomTable.Td>
								)}
							</tr>
						);
					})}
				</tbody>
			</CustomTable>
			<div className="text-end mt-3">
				<FormMessage type="info">* {t('tableVariant.priceNote')}</FormMessage>
			</div>
		</>
	);
};
