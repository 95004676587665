import { TPartnerPriceLists, TPriceListCode } from 'js/priceList';
import { PRICE_LIST_CODE_DEFAULT } from 'js/priceList/priceListConfig';

export const getDefaultPriceListCode = (priceLists: TPartnerPriceLists): TPriceListCode => {
	const defaultList = priceLists.find((list) => list.hasProducts);
	return defaultList?.code || PRICE_LIST_CODE_DEFAULT;
};

export const isPriceListCodeInPriceLists = (
	priceLists: TPartnerPriceLists,
	priceListCode?: TPriceListCode | null,
): boolean => {
	if (!priceListCode) {
		return false;
	}

	return priceLists.some((list) => list.code === priceListCode && list.hasProducts);
};
