export default {
	actions: {
		detail: 'View License Details',
		export: 'Export licenses',
		exportDetailed: 'Detailed Export',
		followingLicense: 'Following License',
		lastNDays: 'Expired last {{count}} days',
		nextNDays: 'Expiring next {{count}} days',
		operations: 'License operations',
		precedingLicense: 'Preceding License',
		registerTo: 'Register a License To...',
		relatedCustomer: 'View Related Customer',
		relatedOrder: 'View Related Order',
	},
	error: {
		disallowed: 'License operations are not allowed for this license.',
		operation: {
			invalidParty: 'License parties are different from the current order parties',
			alreadyInCart: {
				title: 'This license is already in the cart.',
				message:
					'You already have this license in your cart, do you want to replace it with the selected license operation?',
			},
			product: {
				noOperationsAvailable: 'No licensing operations available.',
			},
			partner: {
				unableToLoad: 'The license partner failed to load.',
			},
			customer: {
				unableToLoad: 'The license customer failed to load.',
			},
			distributionPartner: {
				unableToLoad: 'The license Distribution Partner failed to load.',
			},
			actions: {
				rewriteOperation: 'Replace license operation',
			},
			billablePartyRequired:
				'The license has no billable parties. Go to "New Purchase" and select the billable parties.',
		},
		registration: 'Unable to register license.',
		detailNotFound: 'Entitlement detail #{{id}} not found',
	},
	common: {
		operation: {
			addToCart: 'The license operation was added to the cart.',
			goToCart: 'Go to Cart',
		},
	},
	entity: {
		createdAt: 'Created Date',
		customer: {
			contact: 'Customer Contact',
			id: 'Customer ID',
			email: 'Customer E-mail',
			name: 'Customer',
		},
		partner: {
			contact: 'Partner Contact',
			id: 'Partner ID',
			name: 'Partner',
		},
		distributionPartner: {
			contact: 'Dist. Partner Contact',
			id: 'Dist. Partner ID',
			name: 'Dist. Partner',
		},
		date: 'Date',
		entitlementId: 'Entitlement ID',
		licenseId: 'License ID',
		licenseType: 'License Type',
		modifiedAt: 'Modified Date',
		orderId: 'Order ID',
		product: {
			termLength: 'Term Length',
			type: 'Product Type',
		},
		registeredAt: 'Register Date',
		status: 'License Status',
		statusShort: 'Status',
		statusPlaceholder: 'Any status',
		walletKey: 'Wallet Key',
	},
	licenseOperation: {
		label: 'License Operation',
	},
	registration: {
		registering: 'License registering',
		success: 'License successfully registerd to customer: {{customer}}',
		error: 'Error occurred during registering license.',
		confirmation: {
			title: 'License Registration',
			place: 'Are you sure you would like to attach this customer to this license?',
			replace:
				'License #{{licenseId}} is already registered. Do you want to proceed with registration of this license?',
		},
	},
	selectParty: {
		partner: {
			title: 'Select Partner for Order',
			billableParty: 'This Partner will be billable party.',
			requiredBillableParty: 'Is not possible to have a Order without Partner.',
			emptyBillableParty: 'In the next step, the Customer will be selected as the billable party.',
			license: {
				title: 'License Partner',
				button: 'Use License Partner',
				buttonEmpty: 'Remove Partner from Order',
				empty: 'License does not have a Partner.',
			},
			order: {
				title: 'Order Partner',
				button: 'Keep Order Partner',
				empty: 'Order does not have a Partner.',
				notAllowed: 'The Partner cannot perform licensing operations on this product group.',
			},
		},
		customer: {
			title: 'Select Customer for order',
			billableParty: 'This Customer will be billable party.',
			requiredBillableParty: 'Is not possible to have a Order without Customer.',
			emptyBillableParty: 'A Partner will be a billable party.',
			license: {
				title: 'License Customer',
				button: 'Use License Customer',
				buttonEmpty: 'Remove Customer from Order',
				empty: 'License does not have a Customer.',
			},
			order: {
				title: 'Order Customer',
				button: 'Keep Order Customer',
				empty: 'Order does not have a Customer.',
			},
		},
		distributionPartner: {
			title: 'Select distribution partner for order',
			license: {
				title: 'License Distribution Partner',
				button: 'Use License Dist. Partner',
				buttonEmpty: 'Remove Dist. Partner from Order',
				empty: 'License does not have a Distribution Partner.',
				notAllowed: 'It is not possible to assign a distribution partner to the selected partner.',
			},
			order: {
				title: 'Order Distribution Partner',
				button: 'Keep Order Dist. Partner',
				empty: 'Order does not have a Distribution Partner.',
				notAllowed: 'It is not possible to assign a distribution partner to the selected partner.',
			},
		},
	},
};
