import { cacheInstance } from 'api/cache';
import { useCallback, useEffect, useState } from 'react';
import { logError } from 'js/utils/app';
import { isFunction } from 'lodash';

type TSetValueType<D> = D | null | ((value: D | null) => D | null);
type TUseStorage<D> = [D | null, (value: TSetValueType<D>) => void];

/**
 * Save value into storage and load on init
 *
 * @param {string} key
 * @param {{} | boolean | string | number} initialValue
 * @returns {TUseStorage}
 *
 * @see https://usehooks.com/
 */
export const useStorage = <D extends {} | boolean | string | number>(key: string, initialValue: D): TUseStorage<D> => {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState<D | null>(initialValue);

	useEffect(() => {
		storageLoad<D>(key, (value) => {
			setStoredValue(value);
		});
	}, [key]);

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = useCallback(
		(value: TSetValueType<D>) => {
			try {
				// Allow value to be a function so we have same API as useState
				const valueToStore = isFunction(value) ? value(storedValue) : value;
				// Save state
				storageSave<D>(key, valueToStore);
				setStoredValue(valueToStore);
			} catch (err) {
				logError(err);
			}
		},
		[key, storedValue],
	);

	return [storedValue, setValue];
};

/**
 * Save value to storage
 *
 * @param {string} key
 * @param {{}} value
 * @returns {Promise<void>}
 */
export const storageSave = async <P extends unknown>(key: string, value: P | null): Promise<void> => {
	await cacheInstance.setItem(key, value);
};

/**
 * Load data from storage and return in callback
 *
 * @param {string} key
 * @param {Function} cb
 * @returns {void}
 */
export const storageLoad = <P extends {}>(key: string, cb: (value: P | null) => void): void => {
	const value = cacheInstance.getItem<P>(key);
	value.then((value) => cb(value));
};

/**
 * Load data from storage and return promise
 *
 * @param {string} key
 * @returns {Promise}
 */
export const storageLoadPromise = <P extends {}>(key: string): Promise<P | null> => cacheInstance.getItem<P>(key);
