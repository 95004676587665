import React from 'react';
import classNames from 'classnames';
import { DividerIterator, DividerTitle, RowDivider, TRowDividerProps } from 'submodule/RowDivider';

type TStepperVerticalHeadlineProps = TRowDividerProps & {
	step: number;
	title?: string;
	active?: boolean;
	highlighted?: boolean;
};

export const StepperVerticalHeadline = (props: TStepperVerticalHeadlineProps) => {
	const { active, highlighted, step, title, startComponent, ...rest } = props;
	const textClassName = classNames('component__stepperVertical-headline', {
		'is-active': active,
		'is-highlighted': highlighted,
		'me-3': title,
	});

	return (
		<RowDivider
			startComponent={
				<div className={textClassName}>
					<DividerIterator
						className="me-3"
						step={step}
					/>
					<DividerTitle title={title} />
					{startComponent}
				</div>
			}
			{...rest}
		/>
	);
};
