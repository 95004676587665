import React from 'react';
import { Tooltip } from '@avast/react-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as iconTooltip } from '@fortawesome/free-solid-svg-icons';

type TSpendingOverviewHeaderProps = {
	text: string;
	tooltipText: string;
	minWidth?: string;
};

export const SpendingOverviewHeader: React.FC<TSpendingOverviewHeaderProps> = (props) => {
	return (
		<div style={{ minWidth: props.minWidth }}>
			<span className="me-1">{props.text}</span>
			<Tooltip
				placement="top"
				content={props.tooltipText}
			>
				<FontAwesomeIcon
					className="tooltip-icon"
					icon={iconTooltip}
				/>
			</Tooltip>
		</div>
	);
};
