import { IEntityOrder } from 'module/orders';
import { TableColumnsDef } from 'types';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { getPartnerRenderName } from 'module/partners/utils/partnerSelectors';
import { noValuePlaceholder } from 'js/utils/app';
import { getDistributionPartnerRenderName } from 'module/distributionPartners/utils/distributionPartnerSelector';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { orderStatusEnumUtils } from 'module/orders/enums';
import { ordersConfig } from 'module/orders/ordersConfig';
import { DropdownLink } from 'js/components/molecules/Dropdown/DropdownLink';
import React, { useMemo } from 'react';
import { useIsEditable } from 'module/orders/hooks/useIsEditable';
import { useTranslation } from 'react-i18next';
import { hasAvailableDocuments, hasAvailableLicenses } from 'module/orders/utils/common';
import { paymentStatusEnumUtils, paymentTypeEnumUtils } from 'js/enums';
import { useAuthContext } from 'js/contexts';

type TTableData = IEntityOrder;

export const useOrderListColumns = (): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(ordersConfig.trNamespace);
	const isEditable = useIsEditable();
	const { isGroupPartner, isRoleDistributor } = useAuthContext();

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.link(
				'id',
				{
					header: t('entity.orderNumberShort'),
				},
				{
					to: (order) => ordersConfig.detailLink(order),
					copy: true,
				},
			),
			columnHelper.ellipsis((row) => getPartnerRenderName(row.partner), {
				header: t('common:entity.partner'),
				enableSorting: false,
				id: 'partner',
				meta: {
					hidden: isGroupPartner,
					defaultValue: { value: noValuePlaceholder(t('common:_.NA')) },
					formatters: [formatter.bold],
				},
			}),
			columnHelper.ellipsis((row) => getDistributionPartnerRenderName(row.distributionPartner), {
				header: t('common:entity.distributionPartner'),
				enableSorting: false,
				id: 'distributionPartner',
				meta: {
					hidden: !isRoleDistributor,
					defaultValue: { value: noValuePlaceholder(t('common:_.NA')) },
					formatters: [formatter.bold],
				},
			}),
			columnHelper.ellipsis((row) => getCustomerRenderName(row.customer), {
				header: t('common:entity.customer'),
				enableSorting: false,
				meta: {
					defaultValue: { value: noValuePlaceholder(t('common:_.NA')) },
					formatters: [formatter.bold],
				},
			}),
			columnHelper.date('createdAt', { header: t('entity.created') }),
			columnHelper.text('payment.type', {
				header: t('common:entity.paymentMethod'),
				id: 'paymentType',
				meta: {
					formatters: [paymentTypeEnumUtils.getText],
					defaultValue: { value: noValuePlaceholder(t('common:_.NA')) },
				},
			}),
			columnHelper.text('status', {
				header: t('common:entity.orderStatus'),
				meta: { formatters: [orderStatusEnumUtils.getText] },
			}),
			columnHelper.text('payment.status', {
				header: t('common:entity.paymentStatus'),
				id: 'paymentStatus',
				meta: {
					defaultValue: { value: noValuePlaceholder(t('common:_.NA')) },
					formatters: [paymentStatusEnumUtils.getText],
				},
			}),
			columnHelper.date('payment.dueDate', {
				header: t('common:dates.paymentDue'),
				id: 'paymentDueDate',
				meta: { hidden: !isGroupPartner, defaultValue: { value: noValuePlaceholder(t('common:_.NA')) } },
			}),
			columnHelper.money('totalAmountWithoutTax', { header: t('entity.totalAmount') }, { currencyKey: 'currency' }),
			columnHelper.actions({
				dropdown: {
					items(order) {
						const list = [
							<DropdownLink
								key="detail"
								to={ordersConfig.detailLink(order)}
								testId="detail"
							>
								{t('actions.detail')}
							</DropdownLink>,
						];

						if (isEditable(order)) {
							list.push(
								<DropdownLink
									to={ordersConfig.updateLink(order)}
									testId="edit"
								>
									{t('actions.edit')}
								</DropdownLink>,
							);
						}

						if (hasAvailableLicenses(order)) {
							list.push(
								<DropdownLink
									{...ordersConfig.detailLinkLicenses(order)}
									testId="licenses"
								>
									{t('actions.licenses')}
								</DropdownLink>,
							);
						}

						if (hasAvailableDocuments(order)) {
							list.push(
								<DropdownLink
									{...ordersConfig.detailLinkDocuments(order)}
									testId="documents"
								>
									{t('actions.documents')}
								</DropdownLink>,
							);
						}

						return list;
					},
				},
			}),
		];
	}, [t, isEditable, isGroupPartner, isRoleDistributor]);
};
