import * as Yup from 'yup';
import { yupContextValidator } from 'js/utils/validator';
import { IFormikContextValidator } from 'types/validator';
import { INewPasswordData } from 'module/security';
import { useTranslation } from 'react-i18next';
import { securityConfig } from 'module/security/securityConfig';
import { isDefined } from 'js/utils/common';

export const useNewPasswordValidator = (): IFormikContextValidator<INewPasswordData> => {
	type Values = INewPasswordData;
	const [t] = useTranslation(securityConfig.trNamespace);

	const validationSchema = Yup.object().shape<Values>({
		newPassword: Yup.string()
			.label(t('page.newPassword.form.password'))
			.required()
			.matches(/\d/, t('page.newPassword.validation.digit'))
			.matches(/[!";#$%&'()*+,\-./:<=>?@^_`{|}~\\[\] ]/, t('page.newPassword.validation.symbol'))
			.test('isTrimmed', t('page.newPassword.validation.trim'), (text) => text === text?.trim())
			.min(8, t('page.newPassword.validation.length'))
			.when('$newPassword', (newPassword, schema) => {
				if (isWeakPassword(newPassword)) {
					return schema
						.matches(/[A-Z]/, t('page.newPassword.validation.uppercase'))
						.matches(/[a-z]/, t('page.newPassword.validation.lowercase'));
				}
			}),
		confirmPassword: Yup.string()
			.label(t('page.newPassword.form.confirm'))
			.when('newPassword', {
				is: (newPassword) => isDefined(newPassword),
				then: Yup.string()
					.oneOf([Yup.ref('newPassword')], t('page.newPassword.validation.notSame'))
					.ensure()
					.required(),
			}),
	});

	return {
		validationSchema,
		validate(values) {
			return yupContextValidator<Values>(validationSchema, values, {
				newPassword: values.newPassword,
			});
		},
	};
};

const isWeakPassword = (password: string) => password.length >= 8 && password.length < 12;
