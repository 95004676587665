import { generateEnumUtilsWithDescription } from 'js/enums/generator/enumFunctions';

export enum LicenseStatusEnum {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	CANCELLED = 'CANCELLED',
	REPLACED = 'REPLACED',
	EXPIRED = 'EXPIRED',
	USED = 'USED',
	MIGRATED = 'MIGRATED',
	BLACKLISTED = 'BLACKLISTED',
}

export const licenseStatusEnumUtils = generateEnumUtilsWithDescription<LicenseStatusEnum>(LicenseStatusEnum, {
	translatePath: 'enums:licenses.status',
});
