import { CONFIG } from 'config';
import { getFeatureFlag, TFeatureFlagId } from 'config/featureFlag';

interface IUseFeatureFlag {
	isEnabled: boolean;
	isDisabled: boolean;
}

export const useFeatureFlag = (id: TFeatureFlagId): IUseFeatureFlag => {
	const env = CONFIG.ENV;
	const feature = getFeatureFlag(id);
	const isDisabled: boolean = feature.isDisabled() && (feature.env === null || feature.env.includes(env));

	return {
		isEnabled: !isDisabled,
		isDisabled,
	};
};
