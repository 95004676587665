import React from 'react';
import { useTranslation } from 'react-i18next';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { Col, Row } from 'js/components/atoms/Row';
import { DataTable } from 'js/components/molecules/DataTable';
import { numberFormatter } from 'js/utils/number';
import { getYearValidityFromSku } from 'js/utils/common';
import { IStatementDetailItem } from 'module/closingBalance';

type TStatementDetailModalContentProps = {
	statementDetail: IStatementDetailItem;
};

export const StatementDetailModalContent = ({ statementDetail }: TStatementDetailModalContentProps) => {
	const [t] = useTranslation(closingBalanceConfig.trNamespace);
	const { product, price, pricingMessage, pricedQuantity } = statementDetail;
	const validityInYears = getYearValidityFromSku(product?.naturalSku);

	return (
		<Row
			multi
			md={2}
		>
			<Col md={5}>
				<DataTable>
					<DataTable.Tr name={t('common:entity.product')}>{product?.description}</DataTable.Tr>
					<DataTable.Tr name={t('common:entity.sku')}>{product?.naturalSku}</DataTable.Tr>
					<DataTable.Tr name={t('common:entity.validity')}>
						{validityInYears && t('common:datePeriod.YEAR', { count: validityInYears })}
					</DataTable.Tr>
					<DataTable.Tr name={t('common:entity.quantity')}>{pricedQuantity}</DataTable.Tr>
				</DataTable>
			</Col>
			<Col md={7}>
				<DataTable>
					<DataTable.Tr name={t('statement.saleUnitPrice')}>
						{numberFormatter.currency(price?.saleUnit, price?.withoutTaxCurrencyCode)}
					</DataTable.Tr>
					<DataTable.Tr name={t('common:entity.priceWTax')}>
						{numberFormatter.currency(price?.withoutTax, price?.withoutTaxCurrencyCode)}
					</DataTable.Tr>
					<DataTable.Tr name={t('common:entity.priceWOTax')}>
						{numberFormatter.currency(price?.withTax, price?.withoutTaxCurrencyCode)}
					</DataTable.Tr>
					<DataTable.Tr name={t('statement.pricingInfo')}>{pricingMessage}</DataTable.Tr>
				</DataTable>
			</Col>
		</Row>
	);
};
