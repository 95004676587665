import { useCallback, useEffect, useState } from 'react';
import { IDigitalRiverSource } from 'module/account';
import { useAutoBillingContext } from 'module/account/context/AutoBillingContext';
import { useApiAutoBillingSource } from 'module/account/hooks/useApiAutoBilling';
import { autoBillingSourceApiError, autoBillingSourceApiErrorMessage } from 'module/account/utils/apiError';
import { logError } from 'js/utils/app';
import { useApiErrorContext } from 'js/contexts';

export type TUseAutoBillingCurrentSource = {
	isFetching: boolean;
	source: IDigitalRiverSource | null;
	refetch(): void;
	error?: string | null;
};

export const useAutoBillingCurrentSource = (): TUseAutoBillingCurrentSource => {
	const { digitalRiver } = useAutoBillingContext();
	const apiErrorContext = useApiErrorContext();
	const [error, setError] = useState<TUseAutoBillingCurrentSource['error']>(null);
	const {
		data,
		query: { isFetching, refetch },
	} = useApiAutoBillingSource({
		queryConfig: {
			onError(error) {
				const errorMessage = autoBillingSourceApiErrorMessage(error.response?.data);
				if (errorMessage) {
					setError(errorMessage);
				} else {
					apiErrorContext.setError({
						error,
						resolve: autoBillingSourceApiError,
					});
				}
			},
		},
	});

	const [source, setSource] = useState<TUseAutoBillingCurrentSource['source']>(null);
	const [sourceFetching, setSourceFetching] = useState<boolean>(false);

	useEffect(() => {
		if (data?.paymentSourceId && data.paymentSourceClientSecret) {
			setError(null);
			setSourceFetching(true);
			digitalRiver.retrieveSource(data.paymentSourceId, data.paymentSourceClientSecret).then((r) => {
				setSource(r.source);
				setSourceFetching(false);
				if (r.error) {
					logError('Unable to retrieve source', data, r.error);
				}
			});
		}
	}, [digitalRiver, data]);

	const hasBillingSourceInputData = Boolean(data?.paymentSourceClientSecret) && Boolean(data?.paymentSourceId);
	return {
		isFetching: isFetching || sourceFetching,
		source: hasBillingSourceInputData ? source : null,
		refetch: useCallback(() => {
			refetch();
			setSource(null);
		}, [refetch]),
		error,
	};
};
