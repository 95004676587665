import { get, isEmpty, isNumber, isObject, set } from 'lodash';
import { Tooltip } from '@avast/react-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as iconTooltip } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

/**
 * Remove 'empty' values from an object recursively
 *
 * @template T
 * @param {T} values
 * @return {T}
 */
export const filterPostValues = <T extends {} = {}>(values: T): T => {
	const result = {};
	Object.keys(values).forEach((key) => {
		let value = get(values, key, null);
		let empty = !value;

		if (isNumber(value)) {
			empty = isNaN(value);
		} else if (isObject(value)) {
			value = filterPostValues(value);
			empty = isEmpty(value);
		}

		if (!empty) {
			set(result, key, value);
		}
	});
	return result as T;
};

/**
 * Children prop used for FormikControl component in label prop.
 * @param {string} label - Input label
 * @param {string} tooltipText - Text content shown in tooltip
 * @returns {{children: JSX.Element}}
 *
 * @example
 * <FormikControl
 * 		label={
 * 			getLabelPropsForTooltip( t( 'quantityMin' ), t( 'zeroQuantity' ) )
 * 		}
 * >
 */
export const getLabelPropsForTooltip = (label: string, tooltipText: string) => ({
	children: (
		<>
			<span className="me-1">{label}</span>
			<Tooltip
				content={tooltipText}
				placement="top"
			>
				<FontAwesomeIcon
					className="tooltip-icon"
					icon={iconTooltip}
				/>
			</Tooltip>
		</>
	),
});
