const prefix = '/orders';
const quotePrefix = '/quotes';

export const orderRouteName = {
	LIST: `${prefix}`,
	DETAIL: `${prefix}/detail/:id`,
	UPDATE: `${prefix}/detail/:id/update`,
};

export const quoteRouteName = {
	LIST: `${quotePrefix}`,
	DETAIL: `${quotePrefix}/detail/:id`,
	UPDATE: `${quotePrefix}/detail/:id/update`,
};
