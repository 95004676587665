import { TableColumnsDef } from 'types';
import { IEntityLicense } from 'module/licenses';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import {
	getBaseLicensesTableColumns,
	getLicenseExternalIds,
	isAllowedLicenseOperations,
} from 'module/licenses/utils/common';
import { DropdownLink } from 'js/components/molecules/Dropdown/DropdownLink';
import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { RegisterLicenseDropdownItem } from 'module/customers/components/RegisterLicenseDropdownItem';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { TAsyncContainerRef } from 'js/components/molecules/Modal/AsyncContainer';
import { TAsyncLicenseOperationContainerProps } from 'module/licenses/components/licenseOperation/AsyncLicenseOperationContainer';
import { useAuthContext } from 'js/contexts';

type TTableData = IEntityLicense;

export const useLicenseListColumns = (
	licenseOperationRef: TAsyncContainerRef<TAsyncLicenseOperationContainerProps>,
): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(licensesConfig.trNamespace);
	const { isGroupPartner, isAuthorizedBuyer } = useAuthContext();

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const columns = getBaseLicensesTableColumns(t);
		columns.push(
			columnHelper.actions({
				button: {
					children: t('actions.operations'),
					onClick(license) {
						licenseOperationRef.current?.show({ license });
					},
					conditionalProps: (license) => ({
						hidden: !isAllowedLicenseOperations(license, isAuthorizedBuyer, isGroupPartner),
					}),
					testId: 'licenseOperations',
				},
				dropdown: {
					items(license) {
						const { orderId, customerId } = getLicenseExternalIds(license);

						const list = [
							<DropdownLink
								key="detail"
								to={buildParametrizedRoute(RouteName.LICENSE.DETAIL, license.id)}
								testId="detail"
							>
								{t('actions.detail')}
							</DropdownLink>,
						];

						if (orderId) {
							list.push(
								<DropdownLink
									to={buildParametrizedRoute(RouteName.ORDER.DETAIL, orderId)}
									testId="relatedOrder"
								>
									{t('actions.relatedOrder')}
								</DropdownLink>,
							);
						}

						if (customerId && isGroupPartner) {
							list.push(
								<DropdownLink
									to={buildParametrizedRoute(RouteName.CUSTOMERS.DETAIL, customerId)}
									testId="relatedCustomer"
								>
									{t('actions.relatedCustomer')}
								</DropdownLink>,
							);
						}

						list.push(<RegisterLicenseDropdownItem license={license} />);

						return list;
					},
				},
			}),
		);

		return columns;
	}, [t, licenseOperationRef, isAuthorizedBuyer, isGroupPartner]);
};
