import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'js/components/atoms/Row';
import { DataTable } from 'js/components/molecules/DataTable';
import { ordersConfig } from 'module/orders/ordersConfig';
import { usePageDetailContext } from 'js/contexts';
import { IEntityOrder } from 'module/orders';
import { dateFormatter } from 'js/utils/dateTime';
import { billablePartyTypeEnumUtils, creationSourceEnumUtils, orderStatusEnumUtils } from 'module/orders/enums';
import { getBillablePartyRenderName } from 'module/orders/utils/common';

export const QuoteStatusOverview = () => {
	const [t] = useTranslation(ordersConfig.trNamespace);
	const { data: order } = usePageDetailContext<IEntityOrder>();

	return (
		<Row
			multi
			md={2}
		>
			<Col>
				<DataTable>
					<DataTable.Tr
						name={t('entity.quoteStatus')}
						highlighted
					>
						{orderStatusEnumUtils.getText(order.status)}
					</DataTable.Tr>
					<DataTable.Tr name={t('common:dates.created')}>
						{dateFormatter.toTimeZoneDateTime(order.createdAt)}
					</DataTable.Tr>
					<DataTable.Tr name={t('common:dates.expire')}>
						{dateFormatter.toTimeZoneDateTime(order.expirationDate)}
					</DataTable.Tr>
				</DataTable>
			</Col>
			<Col>
				<DataTable>
					<DataTable.Tr name={t('common:entity.poNumber')}>{order.purchaseOrderNumber}</DataTable.Tr>
					<DataTable.Tr
						name={t('entity.opportunityId')}
						noRenderEmpty
					>
						{order.opportunityId}
					</DataTable.Tr>
					<DataTable.Tr name={t('entity.billablePartyType')}>
						{billablePartyTypeEnumUtils.getText(order.billableParty?.type)}
					</DataTable.Tr>
					<DataTable.Tr name={t('entity.billablePartyName')}>{getBillablePartyRenderName(order)}</DataTable.Tr>
					<DataTable.Tr name={t('entity.createdBy')}>
						{order.createdByPartyId || order.transaction?.ownerIndividualId}
					</DataTable.Tr>
					<DataTable.Tr name={t('entity.creationSource')}>
						{creationSourceEnumUtils.getText(order.creationSource)}
					</DataTable.Tr>
				</DataTable>
			</Col>
		</Row>
	);
};
