import React from 'react';
import { DataTable, DataTableCard } from 'js/components/molecules/DataTable';
import { getPartnerRenderName } from 'module/partners/utils/partnerSelectors';
import { useTranslation } from 'react-i18next';
import { partnersConfig } from 'module/partners/partnersConfig';
import { accountConfig } from 'module/account/accountConfig';
import { TDataTableCardProps } from 'js/components/molecules/DataTable/DataTableCard';
import { useAuthContext } from 'js/contexts';

export const AccountInformationCard = (props: Partial<TDataTableCardProps>) => {
	const { authCompany: company } = useAuthContext();
	const [t] = useTranslation(partnersConfig.trNamespace);
	const [tAccount] = useTranslation(accountConfig.trNamespace);

	if (!company) {
		return null;
	}

	return (
		<DataTableCard
			caption={tAccount('cards.accountInformation')}
			variant="simple"
			{...props}
		>
			<DataTable.Tr name={t('common:entity.company')}>{getPartnerRenderName(company)}</DataTable.Tr>
			<DataTable.Tr name={t('entity.salesforceId')}>{company.salesforceId}</DataTable.Tr>
			<DataTable.Tr name={t('common:contact.taxId')}>{company.identifiers?.partyTaxId}</DataTable.Tr>
			<DataTable.Tr name={t('common:contact.secondaryTaxId')}>{company.identifiers?.partyTaxId2}</DataTable.Tr>
			<DataTable.Tr
				name={t('common:entity.currency')}
				noRenderEmpty
			>
				{company.currencyCode}
			</DataTable.Tr>
		</DataTableCard>
	);
};
