export default {
	common: {
		allPartnerType: '所有合作夥伴',
		avastId: 'Avast 合作夥伴 ID',
		loadingDetail: '正在載入合作夥伴詳細資料',
	},
	error: {
		unableToLoadTerms: '無法載入條款與條件。',
		unableToLoadPartnerDetail: '無法載入合作夥伴詳細資料',
	},
	entity: {
		accountType: '類型',
		accountSubType: '子類型',
		company: '公司',
		email: '電子郵件地址',
		id: '合作夥伴 ID',
		salesforceId: '銷售人員 ID',
		name: '全名',
	},
	select: {
		title: '選取合作夥伴',
		button: {
			label: '合作夥伴',
			placeholder: '選取合作夥伴',
		},
		filter: {
			placeholder: '尋找合作夥伴',
		},
	},
};
