import { TLoginResolve } from 'module/security';
import { useCallback } from 'react';
import { useAuthUserData } from 'module/security/hooks/useAuthUserData';
import { getApiSsoPromise } from 'module/security/utils/apiSecurity';
import { getInvalidStateLocation } from 'module/security/utils/common';
import { useLogout } from 'module/security/hooks/useLogout';
import { useAuthContext } from 'js/contexts';

export const useSsoLogin = (): (() => Promise<TLoginResolve>) => {
	const { setAuthData } = useAuthContext();
	const logout = useLogout();
	const authUserData = useAuthUserData();

	return useCallback(
		() =>
			new Promise(async (resolve) => {
				// Do log out first
				await logout();

				const response = await getApiSsoPromise().catch(() => {
					resolve({ success: false, message: 'state.invalidSso' });
				});

				// Err -> handled by hook props
				if (!response) {
					resolve({ success: false, message: 'state.invalidSso' });
					return;
				}

				const data = await authUserData(response.data);
				if (data) {
					setAuthData(data);
					resolve({ success: true, redirectTo: getInvalidStateLocation(data) });
					return;
				}
				resolve({ success: false, message: 'state.invalidSso' });
			}),
		[logout, setAuthData, authUserData],
	);
};
