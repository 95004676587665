import { useEffect } from 'react';
import { orderInstanceItemsToPricingItems } from 'module/purchase/hooks/pricing/pricingUtils';
import { usePricingHeader } from 'module/purchase/hooks/pricing/usePricingHeader';
import { usePricing } from 'module/purchase/hooks/pricing/usePricing';
import { useOrderContext } from 'js/contexts';

/**
 * Pricing request for review and confirm page - result is set to the order context
 *
 * @returns {void}
 */
export const useReviewOrderPricing = (): void => {
	const { orderState, setPricing } = useOrderContext();
	const isDisabled = orderState.isLocked;

	// Prepare pricing fragments
	const header = usePricingHeader(orderState.priceListCode);
	const items = orderInstanceItemsToPricingItems(orderState.items, orderState.customer);

	// Get pricing
	const { pricing } = usePricing(header, items, { isDisabled });

	// On pricing change save to the order context
	useEffect(() => {
		if (!isDisabled) {
			setPricing(pricing);
		}
	}, [isDisabled, pricing, setPricing]);
};
