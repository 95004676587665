import { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/fr-FR');
const locale: TLocale = 'fr-FR';

export const fr_FR: ILocaleConfig = {
	name: 'French (France)',
	locale,
	language: 'fr',
	default: true,
	format: {
		numbro,
		datetime: locale,
	},
};
