import React, { Component, HTMLAttributes, ReactNode, RefObject, useRef } from 'react';
import { STATUS_CANCEL, STATUS_SUCCESS } from 'appConstants';
import classNames from 'classnames';
import { isFunction } from 'lodash';

type TAsyncContainerResolve = (state: number) => void;
type TAsyncContainerReject = (reason?: unknown) => void;
type TAsyncContainerProps<P> = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
	children: ReactNode | ((props: P) => ReactNode);
};
type TAsyncContainerState<P> = {
	show: boolean;
	childrenProps?: P;
};

export type TAsyncContainerRef<P extends {} = {}> = RefObject<AsyncContainer<P>>;

export type TAsyncContainerComponentProps<P extends {} = {}> = {
	forwardedRef: RefObject<AsyncContainer<P>>;
};
export const useAsyncContainerRef = <P extends {} = {}>() => useRef<AsyncContainer<P>>(null);

export class AsyncContainer<P extends {} = {}> extends Component<TAsyncContainerProps<P>, TAsyncContainerState<P>> {
	state: TAsyncContainerState<P> = {
		show: false,
	};

	promiseInfo: {
		resolve?: TAsyncContainerResolve;
		reject?: TAsyncContainerReject;
	} = {};

	async show(props?: P) {
		return new Promise<number>((resolve, reject) => {
			this.promiseInfo = {
				resolve,
				reject,
			};
			this.setState({
				show: true,
				childrenProps: props,
			});
		});
	}

	hide = () => {
		this.setState({
			show: false,
		});
	};

	isOpen(): boolean {
		return this.state.show;
	}

	onSuccess(state = STATUS_SUCCESS): void {
		const { resolve } = this.promiseInfo;

		this.hide();
		resolve?.(state);
	}

	onCancel(): void {
		const { resolve } = this.promiseInfo;

		this.hide();
		resolve?.(STATUS_CANCEL);
	}

	getResolve(): TAsyncContainerResolve {
		const { resolve } = this.promiseInfo;
		return (result) => {
			resolve?.(result);
			this.hide();
		};
	}

	getReject(): TAsyncContainerReject {
		const { reject } = this.promiseInfo;
		return (err) => {
			reject?.(err);
			this.hide();
		};
	}

	render() {
		const { children, className, ...props } = this.props;
		const { show, childrenProps } = this.state;

		return (
			<div
				{...props}
				className={classNames(className, 'component__asyncContainer')}
			>
				{show && (isFunction(children) ? children(childrenProps!) : children)}
			</div>
		);
	}
}
