import React, { ReactElement } from 'react';
import { Alert } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useOrderContext } from 'js/contexts';

/**
 * Order credit status tab for end customer
 * @return {ReactElement|null}
 * @constructor
 */
export const CustomerCreditStatusTab = (): ReactElement | null => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const {
		orderState: { customer },
		isEndCustomerBillableParty,
	} = useOrderContext();

	if (isEndCustomerBillableParty) {
		if (!customer) {
			return <LoadingPlaceholder />;
		}
		if (customer.isCreditEligible) {
			return (
				<Alert
					variant="success"
					caption={t('common.customerCreditStatus.eligible')}
				/>
			);
		}

		return (
			<Alert
				variant="warning"
				caption={t('common.customerCreditStatus.notEligible')}
			/>
		);
	}

	return null;
};
