import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	SELECT_PARTY_LICENSE,
	SELECT_PARTY_ORDER,
	SelectPartyModalContent,
	TSelectPartyModalContentProps,
} from 'module/licenses/components/licenseOperation/SelectPartyModalContent';
import { PartnerCard } from 'module/partners/components';
import { isDifferentLicenseAndOrderParty } from 'module/licenses/utils/licenseOperation';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { IEntityPartnerDetail } from 'module/partners';
import { IEntityCustomer } from 'module/customers';
import { IEntityDistributionPartner } from 'module/distributionPartners';
import { CustomerCard } from 'module/customers/components';
import { DistributionPartnerCard } from 'module/distributionPartners/components';
import { TPartnerPriceLists } from 'js/priceList';
import { isPriceListCodeInPriceLists } from 'js/priceList/priceListUtils';
import { useOrderContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';
import { logError } from 'js/utils/app';

export type TLicenseOperationPartyResolution = {
	partner: IEntityPartnerDetail | null;
	partnerPriceLists?: TPartnerPriceLists | null;
	customer: IEntityCustomer | null;
	distributionPartner: IEntityDistributionPartner | null;
};

export type TSelectPartyModalProps = {
	onCancel(): void;
	onSuccess(resolution: TLicenseOperationPartyResolution): void;
};

type TStep = TSelectPartyModalContentProps['type'];

export const SelectPartyModal = (props: TSelectPartyModalProps) => {
	const { onCancel, onSuccess } = props;
	const licenseOperationContext = useLicenseOperationContext();
	const {
		partner,
		customer,
		distributionPartner,
		hasCustomer,
		priceListCode,
		licenseProductGroupIsBusiness,
		partnerPriceLists,
	} = licenseOperationContext;
	const { orderState, hasBillableParty, partnerGroups } = useOrderContext();
	const [resolution, setResolution] = useState<TLicenseOperationPartyResolution>({
		partner,
		partnerPriceLists,
		customer,
		distributionPartner,
	});

	const differentParty = useMemo(
		() => isDifferentLicenseAndOrderParty(licenseOperationContext, orderState),
		[licenseOperationContext, orderState],
	);

	// If license and order parties are same skip this modal
	useEffect(() => {
		if (!differentParty.isDifferent || !hasBillableParty) {
			onSuccess(resolution);
		}
	}, [differentParty, onSuccess, resolution, hasBillableParty]);

	// Current step of selecting billable parties
	const [step, setStep] = useState<TStep>(
		((): TStep => {
			if (differentParty.partner) {
				return 'partner';
			}
			if (differentParty.distributionPartner) {
				return 'distributionPartner';
			}
			return 'customer';
		})(),
	);

	// Go to next step
	const goNext = useCallback(
		(resolution: TLicenseOperationPartyResolution) => {
			switch (step) {
				case 'partner':
					if (differentParty.distributionPartner) {
						setStep('distributionPartner');
					} else if (differentParty.customer) {
						setStep('customer');
					} else {
						onSuccess(resolution);
					}
					break;
				case 'distributionPartner':
					if (differentParty.customer) {
						setStep('customer');
					} else {
						onSuccess(resolution);
					}
					break;
				case 'customer':
					onSuccess(resolution);
					break;
				default:
					logError(`Not supported step: ${step}`);
			}
		},
		[step, onSuccess, differentParty],
	);

	// Go to next step
	const updateState = useCallback(
		(stepState: Partial<TLicenseOperationPartyResolution>) => {
			const nextResolution = { ...resolution, ...stepState };
			if (!nextResolution.partner) {
				nextResolution.distributionPartner = null;
			}

			setResolution(nextResolution);
			goNext(nextResolution);
		},
		[goNext, resolution],
	);

	// Go to previous step
	const goPrev = useCallback(() => {
		switch (step) {
			case 'partner':
				onCancel();
				break;
			case 'distributionPartner':
				if (differentParty.partner) {
					setStep('partner');
				} else {
					onCancel();
				}
				break;
			case 'customer':
				if (differentParty.distributionPartner) {
					setStep('distributionPartner');
				} else if (differentParty.partner) {
					setStep('partner');
				} else {
					onCancel();
				}
				break;
			default:
				logError(`Not supported step: ${step}`);
		}
	}, [step, onCancel, differentParty]);

	// Partner
	if (step === 'partner' && differentParty.partner) {
		const wouldHaveBillableCustomer = hasCustomer || isDefined(orderState.customer);
		const isNotAllowedPriceList =
			priceListCode !== orderState.priceListCode &&
			!isPriceListCodeInPriceLists(orderState.partnerPriceLists || [], priceListCode);
		const isInconsistentProductSegment =
			(partnerGroups.isBusiness && !partnerGroups.isConsumer && !licenseProductGroupIsBusiness) ||
			(!partnerGroups.isBusiness && partnerGroups.isConsumer && licenseProductGroupIsBusiness);

		return (
			<SelectPartyModalContent
				type="partner"
				entityLicense={partner ? <PartnerCard partner={partner} /> : null}
				entityOrder={orderState.partner ? <PartnerCard partner={orderState.partner} /> : null}
				billableParty={true}
				emptyEntityAllowed={wouldHaveBillableCustomer}
				entityOrderNotAllowed={isNotAllowedPriceList || isInconsistentProductSegment}
				onResolve={(selectedPartner) => {
					switch (selectedPartner) {
						case SELECT_PARTY_LICENSE:
							updateState({
								partner,
								partnerPriceLists,
							});
							break;
						case SELECT_PARTY_ORDER:
							updateState({
								partner: orderState.partner || null,
								partnerPriceLists: orderState.partnerPriceLists,
							});
							break;
						default:
							goPrev();
							break;
					}
				}}
			/>
		);
	}

	// Customer
	if (step === 'customer' && differentParty.customer) {
		return (
			<SelectPartyModalContent
				type="customer"
				entityLicense={customer ? <CustomerCard customer={customer} /> : null}
				entityOrder={orderState.customer ? <CustomerCard customer={orderState.customer} /> : null}
				billableParty={!resolution.partner}
				emptyEntityAllowed={Boolean(resolution.partner)}
				onResolve={(selectedCustomer) => {
					switch (selectedCustomer) {
						case SELECT_PARTY_LICENSE:
							updateState({ customer });
							break;
						case SELECT_PARTY_ORDER:
							updateState({ customer: orderState.customer || null });
							break;
						default:
							goPrev();
							break;
					}
				}}
			/>
		);
	}

	// Distribution Partner
	if (step === 'distributionPartner' && differentParty.distributionPartner) {
		return (
			<SelectPartyModalContent
				type="distributionPartner"
				entityLicense={
					distributionPartner ? <DistributionPartnerCard distributionPartner={distributionPartner} /> : null
				}
				entityOrder={
					orderState.distributionPartner ? (
						<DistributionPartnerCard distributionPartner={orderState.distributionPartner} />
					) : null
				}
				entityLicenseNotAllowed={
					isDefined(distributionPartner) && distributionPartner.distributorId !== resolution.partner?.id
				}
				entityOrderNotAllowed={
					isDefined(orderState.distributionPartner) &&
					orderState.distributionPartner.distributorId !== resolution.partner?.id
				}
				onResolve={(selectedDistributionPartner) => {
					switch (selectedDistributionPartner) {
						case SELECT_PARTY_LICENSE:
							updateState({ distributionPartner });
							break;
						case SELECT_PARTY_ORDER:
							updateState({ distributionPartner: orderState.distributionPartner || null });
							break;
						default:
							goPrev();
							break;
					}
				}}
			/>
		);
	}

	return null;
};
