import { usePageDetailContext } from 'js/contexts';
import { IEntityOrder } from 'module/orders';
import { OrderPriceSummary } from 'js/components/molecules/PriceSummary';
import { isOrderInStatus } from 'module/orders/utils/common';
import React from 'react';
import { TOrderPriceSummaryProps } from 'js/components/molecules/PriceSummary/OrderPriceSummary';
import { OrderStatusEnum } from 'module/orders/enums';

export const PriceSummary = () => {
	const { data: order } = usePageDetailContext<IEntityOrder>();
	const { computedValues } = order;
	const values: TOrderPriceSummaryProps = {
		customerTotal: computedValues.price.customer.total,
		customerPrefixType: computedValues.price.customer.prefixType,
		partnerTotal: computedValues.isEndCustomerOrder ? false : computedValues.price.partner.total,
		currencyCode: order.currency,
	};

	// Complete orders
	if (
		isOrderInStatus(order, [
			OrderStatusEnum.SUBMITTED_TO_COMMERCE,
			OrderStatusEnum.COMPLETE,
			OrderStatusEnum.WAITING_PAYMENT,
		])
	) {
		values.taxTotal = order.totalTax;
		values.totalWithTax = order.totalAmountWithTax;
	}

	if (computedValues.price.quotedCustomer) {
		values.customerTotal = undefined;
		values.quotedCustomerTotal = computedValues.price.quotedCustomer?.total;
	}

	return <OrderPriceSummary {...values} />;
};
