export default {
	comparativeFeatures: {
		title: 'New Avast Business Solutions',
		availableAsAddon: 'Available as add-on',
		equivalentLabel: 'More value. Same price.',
		action: 'See packages',
		message:
			'We have new security packages that provide more value to your customers for the same price. Help your customers choose the right level of protection for their business.',
		price: {
			from: 'From',
			units: '{{unitType}}',
			units_other: '{{count}} {{unitType}}',
		},
		startShopping: 'Start shopping',
		windowsOnly: 'Not available for MacOS',
	},
	refunds: {
		entity: {
			date: 'Refund Date',
			initiatedDate: 'Refund Initiated Date',
			itemId: 'Line ID',
			orderLineItemId: 'Order Line Number',
			type: 'Refund Type',
			reason: 'Refund Reason',
			reasonNote: 'Comment',
			refundableAmount: 'Available for Refund',
			refundAmount: 'Refund Amount',
			totalRefunded: 'Total refunded',
		},
		initiate: {
			title: 'Initiate Refund for Order #{{id}}',
			submit: 'Submit request',
			process: {
				loading: 'Submitting refund...',
				success: 'Your refund request has been submitted.',
				error: 'Unable to submit the refund request.',
			},
			message: {
				ORDER_REFUND:
					'Either refund the whole order amount or request a partial order refund by entering the desired amount (up to the maximum available amount).',
				LINE_REFUND:
					'Select which products you want to refund. Either refund the whole line item amount or request a partial line item refund by entering the desired amount (up to the maximum available amount).',
				TAX_REFUND:
					'By selecting this option, the whole order will be refunded and a new invoice without tax will be generated automatically.',
				KEY_REFUND:
					'Select the key you want to refund. Either refund the full amount or request a partial refund by entering the desired amount (up to the maximum available amount).',
			},
		},
		view: {
			title: 'Refunds for Order #{{id}}',
		},
		error: {
			noRefundTypeEnabled: 'No refund type is allowed for this order.',
			refundableAmount: 'Unable to load order refunds information.',
			refundedAmount: 'Unable to load total refunded amount.',
		},
		license: {
			noSelectedLicenses: 'You do not have any licenses selected. Please use the field above to search for licenses.',
			search: {
				title: 'Enter a key to search for a license',
				searchFailure: 'The key {{licenseKey}} does not match any license for this order.',
				refundableAmountFailure: 'Unable to load a refundable amount for the key {{licenseKey}}.',
				success: '{{licenseKey}} has been added to the refund form.',
				loadingLicenses: 'Loading licenses...',
				loadingRefundableAmount: 'Loading refundable amount...',
			},
			select: {
				title: 'Select a license to refund',
			},
		},
	},
	appMessages: {
		taxExemptionCertificate: {
			title:
				'Please note that the certificates uploaded via OMS are only applicable to credit card orders where the vendor is Digital River Inc.',
			contentTop:
				'As of <strong>February 1, 2023</strong>, the vendor for line of credit purchases (including closing balance payment method, if applicable) will change from Digital River Inc. to Gen Digital Inc. In order to ensure that line of credit purchases continue to be tax exempt, please <strong>send a tax exemption certificate issued for Gen Digital Inc.</strong> to our Tax Department at <0>{{email}}</0>.',
			contentBottom: 'Thank you for your cooperation!',
		},
		flexibleBillingAvailable: {
			title: 'Flexible Billing now available in the Business Hub',
			content:
				'Managed Service Providers (MSPs) using the Multi-company partner version of the Business Hub now have a pay-as-you-go option!<br/>This helps maximize flexibility, scalability, and profitability for MSPs, enabling them to consume Avast Business solutions in the way that best aligns with their transactions with end customers.',
			action: 'Learn More',
		},
		newTermsAndConditions: {
			title: 'Important notice',
			content:
				'On March 19, we will be releasing an updated version of our partner agreement, which partners will see displayed when they click "Order Now" in the Partner Portal.<br/>In order to proceed with an OMS transaction, the updated agreement must be accepted. If you have any questions, please contact your account manager.',
		},
	},
	widgets: {
		action: {
			addWidget: 'Add widget',
			editWidget: 'Edit Widget',
			editWidgets: 'Edit Widgets',
		},
		common: {
			columnPlaceholder: 'Add new or move widget...',
			savingLoader: 'Saving widget changes...',
			rowTitle: 'Row ({{count}} column)',
			rowTitle_other: 'Row ({{count}} columns)',
			changesSaved: 'Widget changes have been saved',
			oneColumnRow: 'One column row',
			twoColumnsRow: 'Two columns row',
		},
		config: {
			type: 'Type',
			ownName: 'Own name',
			ownNamePlaceholder: 'Widget name',
			columns: 'Visible columns',
			columnsPlaceholder: 'Select at least one column',
		},
		setting: {
			days: 'Days',
			limit: 'Max rows',
		},
	},
	locale: {
		language: {
			saving: 'Changing the system language',
			success: 'The system language has been changed.',
			confirmation: {
				title: 'Change the system language',
				message:
					'Changing the language settings of your account will also affect the language used on your invoices and emails from Avast. Are you sure about this change?',
			},
		},
		format: {
			saving: 'Changing the Date and Number formatting setting',
			success: 'The Date and Number formatting has been changed.',
		},
	},
};
