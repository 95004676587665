import React from 'react';
import { DistributorSpendingOverview, FlexibleBillingListMenu, PageListHeader } from '../components';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';

export const PageReports = () => {
	return (
		<>
			<DefaultContainer>
				<PageListHeader />
			</DefaultContainer>
			<FlexibleBillingListMenu />
			<DefaultContainer>
				<DistributorSpendingOverview className="mb-4" />
				<div>...reports...</div>
			</DefaultContainer>
		</>
	);
};
