import type { TButtonProps } from '@avast/react-ui-components';
import { Button } from '@avast/react-ui-components';
import { Col, Row } from 'js/components/atoms/Row';
import { SubmitButton } from 'js/components/atoms/Button';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

type TFormikFooterControlsProps = {
	hr?: boolean;
	submit?: TButtonProps;
	cancel?: TButtonProps;
	className?: string;
};

/**
 * Component with button for formik form - submit, cancel, reset
 * @param {TFormikFooterControlsProps} props
 * @returns {ReactElement}
 * @constructor
 */
export const FormikFooterControls = (props: TFormikFooterControlsProps): ReactElement => {
	const { hr = true, cancel, submit, className } = props;
	const [t] = useTranslation();
	const { isSubmitting } = useFormikContext();

	return (
		<div className={className}>
			{hr && <hr />}
			<Row
				size="md"
				justify="between"
				multi
			>
				<Col sm="auto">
					{cancel && (
						<Button
							testId="cancelForm"
							size="sm"
							variant="light"
							disabled={isSubmitting}
							{...cancel}
						>
							{t('common:actions.cancel')}
						</Button>
					)}
				</Col>
				<Col sm="auto">
					<SubmitButton
						size="sm"
						testId="submitForm"
						{...submit}
					>
						{submit?.children || t('common:actions.submitForm')}
					</SubmitButton>
				</Col>
			</Row>
		</div>
	);
};
