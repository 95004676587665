import React from 'react';
import { Modal } from '@avast/react-ui-components';
import { Beforeunload } from 'react-beforeunload';
import { useTranslation } from 'react-i18next';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';

type TModalLoadingProps = {
	title: string;
	disableBeforeUnload?: boolean;
	onHide?: () => void;
};

export const ModalLoading = (props: TModalLoadingProps) => {
	const [t] = useTranslation('error');

	return (
		<Modal
			show
			size="sm"
			backdrop="static"
			onHide={props.onHide}
			testId="loading"
		>
			<Modal.Body className="text-center py-8">
				<LoadingPlaceholder minHeight={false} />
				<p className="mt-1 h5 fw-bold">{props.title}</p>
			</Modal.Body>
			{props.disableBeforeUnload ? null : <Beforeunload onBeforeunload={() => t('beforeUnload')} />}
		</Modal>
	);
};
