import { useApiProductGroupList } from 'module/purchase/hooks/useApiPurchase';
import { IEntityPartnerDetail } from 'module/partners';
import { isPosaPartner } from 'module/partners/utils/partnerSelectors';
import { filterPosaProductGroups, isRetailProductGroup } from 'module/retail/utils/common';

export const useRetailProductGroupList = (partner: IEntityPartnerDetail | null) => {
	const { data, query } = useApiProductGroupList({
		filter: { priceListCode: 'PREPAID_OMS', partnerId: partner?.id! },
		queryConfig: { enabled: Boolean(partner?.id) },
	});

	let productGroups = data.filter(isRetailProductGroup);
	if (isPosaPartner(partner)) {
		productGroups = filterPosaProductGroups(productGroups);
	}

	return {
		data: productGroups,
		query,
	};
};
