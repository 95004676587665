import React from 'react';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { IEntityCustomer } from 'module/customers';
import { Headline } from 'js/components/molecules/Headline';
import { Can } from 'js/components/molecules/Can';
import { customersConfig } from 'module/customers/customersConfig';
import { LinkButton } from 'js/components/atoms/Button';
import { buildParametrizedRoute, tableFilterCreator } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { ButtonComposition, H1 } from '@avast/react-ui-components';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { ILicenseListFilter } from 'module/licenses';
import { IOrderListFilter } from 'module/orders';
import { CustomerDetail } from 'module/customers/components/CustomerDetail';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'js/contexts';

export const DetailContent = () => {
	const [t] = useTranslation(customersConfig.trNamespace);
	const {
		data: customer,
		query: { isFetching },
	} = usePageDetailContext<IEntityCustomer>();
	const { isGroupPartner } = useAuthContext();

	return (
		<>
			<Headline
				className="align-items-center"
				rightContent={
					<ButtonComposition
						size="sm"
						testId="customerActions"
					>
						<LinkButton
							testId="licenses"
							variant="outline-primary"
							size="sm"
							loading={isFetching}
							to={buildParametrizedRoute(
								RouteName.LICENSE.LIST,
								tableFilterCreator<ILicenseListFilter>({ customerId: customer.id }),
							)}
						>
							{t('actions.viewLicenses')}
						</LinkButton>
						<LinkButton
							testId="orders"
							variant="outline-primary"
							size="sm"
							loading={isFetching}
							to={buildParametrizedRoute(
								RouteName.ORDER.LIST,
								tableFilterCreator<IOrderListFilter>({ customerId: customer.id }),
							)}
						>
							{t('actions.viewOrders')}
						</LinkButton>
						{!isGroupPartner && (
							<LinkButton
								testId="quotes"
								variant="outline-primary"
								size="sm"
								loading={isFetching}
								to={buildParametrizedRoute(
									RouteName.QUOTE.LIST,
									tableFilterCreator<IOrderListFilter>({ customerId: customer.id }),
								)}
							>
								{t('actions.viewQuotes')}
							</LinkButton>
						)}
						<Can
							do={customersConfig.aclModule}
							update
						>
							<LinkButton
								testId="update"
								size="sm"
								variant="primary"
								loading={isFetching}
								to={buildParametrizedRoute(RouteName.CUSTOMERS.UPDATE, customer.id)}
							>
								{t('actions.edit')}
							</LinkButton>
						</Can>
					</ButtonComposition>
				}
			>
				<H1 type="h2">{getCustomerRenderName(customer)}</H1>
			</Headline>

			<CustomerDetail
				customer={customer}
				isLoading={isFetching}
			/>
		</>
	);
};
