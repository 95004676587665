import React, { PropsWithChildren } from 'react';

export const MiddleComponent = ({ children }: PropsWithChildren<{}>) => {
	if (children) {
		return (
			<>
				<div className="ms-3 me-3">{children}</div>
				<div className="component__line flex-grow-1" />
			</>
		);
	}
	return null;
};
