import React, { ReactElement } from 'react';
import { FormikControl, TFormikFieldProps } from 'js/components/formik/FormikControl';
import { createFilter } from 'react-select';
import { useTranslation } from 'react-i18next';
import type { TSingleSelectProps } from '@avast/react-ui-components';
import { TCustomerIndustry } from 'module/customers';
import { customersConfig } from 'module/customers/customersConfig';
import { useIndustrySelectOptions } from 'module/customers/hooks/useIndustrySelectOptions';

export const FormikCustomerIndustrySelect = (
	props: TFormikFieldProps<TSingleSelectProps<TCustomerIndustry>>,
): ReactElement => {
	const { industrySelectOptions, isIndustrySelectOptionsLoading } = useIndustrySelectOptions();
	const [t] = useTranslation(customersConfig.trNamespace);

	return (
		<FormikControl label={t('entity.industry')}>
			<FormikControl.SingleSelect<TCustomerIndustry>
				{...props}
				options={industrySelectOptions}
				isLoading={isIndustrySelectOptionsLoading}
				filterOption={createFilter({ matchFrom: 'start' })}
			/>
		</FormikControl>
	);
};
