import { DataTable, DataTableCard } from 'js/components/molecules/DataTable';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { registrationConfig } from 'module/registration/registrationConfig';
import { useRegistrationContext } from 'module/registration/context/RegistrationContext';

export const RegistrationBaseInfoCard = (props: { className?: string }) => {
	const [t] = useTranslation(registrationConfig.trNamespace);
	const { partner } = useRegistrationContext();

	return (
		<div className={props.className}>
			<DataTableCard
				caption={t('page.registrationInfo.contactInformation')}
				variant="simple"
			>
				<DataTable.Tr name={t('common:contact.firstName')}>{partner.firstName}</DataTable.Tr>
				<DataTable.Tr name={t('common:contact.lastName')}>{partner.lastName}</DataTable.Tr>
				<DataTable.Tr name={t('common:contact.email')}>{partner.email}</DataTable.Tr>
				<DataTable.Tr name={t('common:contact.phone')}>{partner.phone}</DataTable.Tr>
			</DataTableCard>
		</div>
	);
};
