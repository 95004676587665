export default {
	dateRangeType: {
		BILLING: 'Date de facturation',
		CREATED: 'Date de création',
		PAYMENT: 'Date du paiement',
	},
	tierCertification: {
		DISTRIBUTORS: 'Distributeurs',
		TIER_4: 'Niveau 4',
		TIER_3: 'Niveau 3',
		TIER_2: 'Niveau 2',
		TIER_1: 'Niveau 1',
	},
	customer: {
		type: {
			BUSINESS: 'Entreprise',
			CONSUMER: 'Particulier',
		},
		discountType: {
			EDUCATION: {
				name: 'Éducation',
				description:
					'Établissements d’enseignement bénéficiant d’une remise supplémentaire : pour l’appliquer à votre commande, contactez votre responsable des ventes.',
			},
			GOVERNMENT: {
				name: 'Administration publique',
				description:
					'Institutions relevant de l’administration publique et bénéficiant d’une remise supplémentaire : pour l’appliquer à votre commande, contactez votre responsable des ventes.',
			},
			NOT_FOR_PROFIT: {
				name: 'Organismes à but non lucratif',
				description:
					'Organismes à but non lucratif bénéficiant d’une remise supplémentaire : pour l’appliquer à votre commande, contactez votre responsable des ventes.',
			},
		},
		searchKey: {
			EMAIL: 'E-mail',
			SALESFORCE_ID: 'ID Salesforce',
		},
	},
	partner: {
		type: {
			DISTRIBUTOR: 'Distributeur',
			RESELLER: 'Revendeur',
			INTERNAL_COMPANY: 'Entreprise interne',
			USER: 'Utilisateur',
		},
		subType: {
			RETAIL: 'Distribution',
			ISP: 'FAI',
			MSP: 'FSG',
			NONE: 'Aucune',
		},
		terms: {
			DR: 'Contrat d’adhésion des participants au réseau Digital River',
			CLIENT: 'Contrat pour les partenaires',
		},
		payAsYouGoPaymentMethod: {
			LINE_OF_CREDIT: {
				name: 'LOC',
				description:
					'Une facture sera générée peu après la fin du mois. Les conditions de paiement habituelles s’appliquent. Une facture LOC ne peut être payée que par virement bancaire. ',
			},
			AUTO_BILLING: {
				name: 'Facturation automatique',
				description:
					'La carte que vous avez enregistrée sera automatiquement débitée peu après la fin du mois. En cas d’échec, une facture LOC standard sera générée.',
			},
		},
	},
	order: {
		discountCategory: {
			NONE: 'Aucune',
			DISCRETIONARY: 'Facultatif',
		},
		lineItemDiscountType: {
			STANDARD: 'Standard',
			SPECIAL: 'Spécial',
			NONE: 'Aucune',
		},
		approvalFlag: {
			NA: 'NA',
			APPROVAL_NOT_REQUIRED: 'Approbation non requise',
			APPROVAL_REQUIRED: 'Approbation requise',
			PENDING_APPROVAL: 'En attente d’approbation',
			APPROVED: 'Approuvé',
			REJECTED: 'Rejeté',
		},
		billablePartyType: {
			PARTNER: 'Partenaire',
			DISTRIBUTOR: 'Distributeur',
			END_CUSTOMER: 'Client final',
		},
		searchKey: {
			COMMERCE_ORDER_ID: 'N° de commande facturable',
			OPPORTUNITY_ID: 'ID de l’opportunité',
			ORDER_NUMBER: 'Numéro de commande',
			PO_NUMBER: 'Numéro de BC',
			QUOTE_NUMBER: 'Numéro de devis',
		},
		status: {
			SAVED_IN_PROGRESS: {
				name: 'Enregistrée à l’état En cours',
				description:
					'La commande a été enregistrée mais n’a pas été envoyée. Seules les commandes à cet état peuvent être modifiées.',
			},
			ISSUED_AS_CUSTOMER_QUOTE: {
				name: 'Émise sous forme de devis client',
				description:
					'La commande consiste en un devis envoyé à un client. Elle ne peut pas être modifiée par le client, seulement envoyée.',
			},
			ISSUED_AS_QUOTE: {
				name: 'Émise sous forme de devis',
				description: 'La commande est un devis d’Avast. Elle ne peut pas être modifiée, seulement envoyée.',
			},
			POSTED_TO_STATEMENT: {
				name: 'Comptabilisée sur le relevé',
				description: 'La commande a été comptabilisée sur un relevé mensuel.',
			},
			PENDING_SUBMISSION: {
				name: 'En attente d’envoi',
				description: '',
			},
			IN_REVIEW: {
				name: 'En cours de vérification',
				description: 'La commande est en attente de vérification. Elle ne peut pas être modifiée.',
			},
			SUBMITTED_TO_COMMERCE: {
				name: 'Envoyée au service commercial',
				description: 'La commande a été envoyée pour facturation.',
			},
			PAYMENT_ERROR: {
				name: 'Erreur de paiement',
				description:
					'Une erreur de paiement s’est produite. La commande doit être renvoyée après la vérification de son exactitude et la mise à jour des informations relatives au paiement.',
			},
			OTHER_ERROR: {
				name: 'Autre erreur',
				description:
					'Une erreur système s’est produite et a persisté après 3 tentatives. La commande doit être renvoyée pour une nouvelle tentative ou le service de support doit être contacté.',
			},
			WAITING_PAYMENT: {
				name: 'En attente de paiement',
				description: 'La commande a été envoyée pour facturation. Le paiement est dû.',
			},
			COMPLETE: {
				name: 'Terminée',
				description: 'Le paiement a été reçu et le traitement de la commande est terminé.',
			},
			CANCELED_BY_USER: {
				name: 'Annulée par l’utilisateur',
				description: 'Un utilisateur a décidé d’annuler la commande.',
			},
			CANCELED_FOR_FAILURE_TO_PAY: {
				name: 'Annulée pour défaut de paiement',
				description: 'La commande a été annulée car le paiement intégral n’a pas été effectué.',
			},
			EXPIRED: {
				name: 'Expirée',
				description: 'La commande n’a pas été modifiée au cours des 30 jours suivant sa création.',
			},
			NOT_SUBMITTED: {
				name: 'Non envoyée',
				description: 'La commande n’a pas été envoyée pour facturation.',
			},
		},
	},
	payment: {
		status: {
			PAID: {
				name: 'Payée',
				description: 'La commande a été traitée et le paiement a été reçu.',
			},
			UNPAID: {
				name: 'Non payée',
				description: 'La commande est en attente de paiement ou le paiement n’a pas été reçu.',
			},
			PARTIAL_PAID: {
				name: 'Partiellement payée',
				description: 'La commande présente un solde restant à payer.',
			},
			NA: {
				name: 'Non facturée',
				description: 'La commande est en attente de facturation.',
			},
			UNBILLED: {
				name: 'Non facturée',
				description: 'La commande est en attente de facturation.',
			},
			SETTLED: {
				name: 'Réglée',
				description: 'La commande a été traitée et finalisée par l’équipe de recouvrement.',
			},
		},
		type: {
			WIRE_TRANSFER: 'Virement bancaire',
			WTOC: 'WTOC',
			CART_SELECTED: 'Panier sélectionné',
			CLOSING_BALANCE: 'Solde de clôture',
			LOC: 'LOC',
			AUTO_BILLING: 'Facturation automatique',
		},
	},
	licenses: {
		searchKey: {
			LICENSE_KEY: 'Clé de licence',
			ORDER_NUMBER: 'Numéro de commande',
		},
		status: {
			ACTIVE: {
				name: 'Active',
				description: 'La licence est en cours d’utilisation.',
			},
			INACTIVE: {
				name: 'Inactive',
				description: 'Le code d’activation n’a pas été utilisé.',
			},
			CANCELLED: {
				name: 'Annulée',
				description: 'La licence a été annulée ou remboursée.',
			},
			REPLACED: {
				name: 'Remplacée',
				description:
					'Une autorisation active a été remplacée par une autre autorisation active au cours de l’opération.',
			},
			EXPIRED: {
				name: 'Expirée',
				description: 'La date d’expiration d’une autorisation active est révolue.',
			},
			USED: {
				name: 'Utilisée',
				description: 'La licence a été utilisée.',
			},
			MIGRATED: {
				name: 'Migrée',
				description: 'La licence a été migrée vers la licence d’un produit successeur.',
			},
			BLACKLISTED: {
				name: 'Liste noire',
				description: '',
			},
		},
		datepickerDateType: {
			CREATED_AT: 'Date de création',
			EXPIRATION: 'Date d’expiration',
			REGISTERED_AT: 'Date d’enregistrement',
		},
	},
	closingBalance: {
		searchKey: {
			ORDER_NUMBER: 'Numéro de commande',
		},
	},
	creditStatus: {
		transactionSource: {
			CANCELLED_LICENSE: 'Licence annulée',
			CANCELLED_ORDER: 'Commande annulée',
			MSP: 'FSG',
			NEW_ORDER: 'Nouvelle commande',
			OMS_REFUND: 'Remboursement',
			PAID_ORDER: 'Commande payée',
			SAP: 'SAP',
			SAP_TAX: 'Taxe',
			OMS_TAX: 'Taxe',
			ORACLE_TAX: 'Taxe',
			ADMIN_CUSTOM: 'Ajustement personnalisé',
			PAY_AS_YOU_GO: 'Facturation flexible',
			TAX_REFUND: 'Remboursement de la TVA',
			PAY_AS_YOU_GO_CONSOLIDATION: 'Facturation flexible - correction',
			SETTLED_ORDER: 'Réglée',
		},
	},
	purchase: {
		marketSegment: {
			BUSINESS: 'Entreprise',
			CONSUMER: 'Particulier',
		},
		billablePartyType: {
			PARTNER: 'Partenaire',
			CUSTOMER: 'Client',
		},
		type: {
			PREPAID: 'Prépayé',
			FLEXIBLE_BILLING: 'Facturation flexible',
		},
	},
	promotion: {
		scope: {
			PARTY_ONLY: 'Partie uniquement',
			PRODUCT_ONLY: 'Produit uniquement',
			PARTY_AND_PRODUCT: 'Partie et produit',
		},
		type: {
			DISCRETIONARY: 'Facultatif',
			SALES_CAMPAIGN: 'Campagne de vente',
		},
		partyType: {
			DISTRIBUTION: 'Distributeur',
			RESELLER: 'Revendeur',
			END_CUSTOMER: 'Client final',
		},
		partyTier: {
			DISTRIBUTORS: 'Distributeurs',
			NONE: 'Aucune',
			TIER_4: 'Niveau 4',
			TIER_3: 'Niveau 3',
			TIER_2: 'Niveau 2',
			TIER_1: 'Niveau 1',
		},
		state: {
			ENABLED: 'Activée',
			DISABLED: 'Désactivée',
		},
	},
	retail: {
		status: {
			COMPLETE: 'Terminée',
			IN_PROGRESS: 'En cours',
		},
	},
	refunds: {
		status: {
			OPEN: 'Ouverte',
			FULLY_APPLIED: 'Pleinement appliquée',
			VOIDED: 'Annulée',
			PENDING: 'En attente',
		},
		type: {
			ORDER: 'Remboursement de la commande',
			LINE: 'Remboursement d’un article',
			KEY: 'Remboursement d’une clé',
			TAX: 'Remboursement de la TVA',
		},
		reason: {
			CUSTOMER_SATISFACTION_ISSUE: 'Problème de satisfaction client',
			INCORRECT_PRICE_INVOICED: 'Prix facturé incorrect',
			NOT_DELIVERED: 'Non livrée',
			WRONG_PRODUCT: 'Produit incorrect',
			PRODUCT_NOT_WORKING: 'Le produit ne fonctionne pas',
			TAX_EXEMPT: 'Exonération fiscale',
			OTHER_REASON: 'Autre raison',
		},
	},
	comparativeFeature: {
		ONLINE_MANAGEMENT: {
			name: 'Plateforme de gestion en ligne',
			description:
				'Configurez facilement la protection de vos appareils, gérez vos abonnements, surveillez et ajoutez des appareils à partir d’un seul endroit, à tout moment et où que vous soyez.',
		},
		DEVICE_PROTECTION: {
			name: 'Protection des appareils',
			description:
				'Un antivirus primé pour protéger tous vos appareils contre les malwares, le phishing, les ransomwares et autres cyberattaques avancées.',
		},
		DATA_PROTECTION: {
			name: 'Protection des données',
			description: 'Protège vos données personnelles, professionnelles et celles de vos clients contre le vol.',
		},
		IT_SUPPORT: {
			name: 'Support informatique',
			description:
				'Une assistance rapide et conviviale, disponible 24 h/24 et 5 j/7 et assurée par des ingénieurs techniques hautement qualifiés. Ils peuvent vous aider, vous et vos employés, par e-mail, par chat ou par téléphone.',
		},
		IDENTITY_PROTECTION: {
			name: 'Protection de l’identité',
			description:
				'Inclut une protection par mot de passe et une protection des webcams afin de protéger l’identité en ligne de vos employés. ',
		},
		UNLIMITED_VPN: {
			name: 'VPN illimité',
			description:
				'Notre VPN intégré chiffre vos données et sécurise les connexions Internet de vos employés pour les rendre privées lorsqu’ils utilisent des réseaux Wi-Fi publics.',
		},
		USB_PROTECTION: {
			name: 'Protection USB',
			description:
				'Empêche les employés d’utiliser des périphériques de stockage amovibles non autorisés, notamment des lecteurs flash, des lecteurs externes, des cartes mémoire, etc. Bloque, contrôle et surveille les ports USB pour empêcher le vol et la perte de données, ainsi que les infections par des malwares.',
		},
		PATCH_MANAGEMENT: {
			name: 'Gestion des correctifs',
			description:
				'Corrige automatiquement les vulnérabilités de vos logiciels et applications tierces susceptibles de faire l’objet de cyberattaques.',
		},
	},
	productGroupTag: {
		NEW: 'Nouveau',
	},
	widgets: {
		label: {
			QUOTES: 'Devis récents',
			ORDERS: 'Commandes récentes',
			CREDIT_STATUS: 'État du crédit',
			EXPIRING_LICENSES_NEXT_N_DAYS: 'Licences expirant dans les N prochains jours',
			EXPIRED_LICENSES_LAST_N_DAYS: 'Licences expirées au cours des N derniers jours',
			CLOSING_BALANCE: 'Relevé de clôture de solde récents',
		},
		caption: {
			QUOTES: 'Devis récents',
			ORDERS: 'Commandes récentes',
			CREDIT_STATUS: 'État du crédit',
			EXPIRING_LICENSES_NEXT_N_DAYS: 'Licences expirant dans les {{count}} prochains jours',
			EXPIRED_LICENSES_LAST_N_DAYS: 'Licences expirées au cours des {{count}} derniers jours',
			CLOSING_BALANCE: 'Relevé de clôture de solde récents',
		},
	},
};
