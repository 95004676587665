/* eslint-disable no-template-curly-in-string */
export default {
	maxFileSize: 'Max file size: {{maxSize}} MB.',
	acceptFileType: 'Supported file type: {{types}}.',
	acceptFileType_other: 'Supported file types: {{types}}.',
	actions: {
		reset: 'Reset Form',
	},
	error: {
		file: {
			type: 'The selected file has not supported type.',
			maxSize: 'The selected file is too large - {{size}} MB (max {{maxSize}} MB).',
		},
	},
	validator: {
		array: {
			max: '${path} field must have less than or equal to ${max} items.',
			min: '${path} field must have at least ${min} items.',
		},
		date: {
			max: '${path} field must be at earlier than ${max}.',
			min: '${path} field must be later than ${min}.',
		},
		mixed: {
			default: '${path} is invalid.',
			notOneOf: '${path} must not be one of the following values: ${values}.',
			oneOf: '${path} must be one of the following values: ${values}.',
			required: '${path} is a required field.',
		},
		number: {
			integer: '${path} must be an integer.',
			lessThan: '${path} must be less than ${less}.',
			max: '${path} must be less than or equal to ${max}.',
			min: '${path} must be greater than or equal to ${min}.',
			moreThan: '${path} must be greater than ${more}.',
			negative: '${path} must be a negative number.',
			positive: '${path} must be a positive number.',
		},
		string: {
			email: '${path} must be a valid email.',
			length: '${path} must be exactly ${length} characters.',
			link: '${path} must be a valid URL.',
			lowercase: '${path} must be a lowercase string.',
			matches: '${path} must match the following: "${regex}".',
			max: '${path} must be at most ${max} characters.',
			min: '${path} must be at least ${min} characters.',
			trim: '${path} must be a trimmed string.',
			uppercase: '${path} must be a upper case string.',
			url: '${path} must be a valid URL.',
			uuid: '${path} must be a valid UUID.',
		},
	},
};
