import { useCallback } from 'react';
import { logDebug } from 'js/utils/app';
import { useApiGcOrderSubmission } from 'module/purchase/hooks/useApiPurchase';
import { TChargeOrderId } from 'types';
import {
	useApiPartnerSyncTaxExemption,
	useApiPartnerUpdateSapLocId,
	useApiPartnerUpdateTaxId,
} from 'module/partners/hooks/useApiPartners';
import { IStandardOrderGcSubmission } from 'module/purchase';
import { useApiLocReviewRequestNotification } from 'js/notifications/useApiNotifications';
import { CONFIG } from 'config';
import { GcOrderStatusEnum } from 'js/enums';
import { useAuthContext } from 'js/contexts';
import { isString } from 'lodash';

interface IGcMessageEventOrderData {
	gcOrderID: string;
	gcPageName: 'QuickBuyCartPage' | 'ThankYouPage';
	gcOrderStatus: GcOrderStatusEnum;
	gcLineOfCreditID?: string;
	gcOrderTotal?: string;
	gcOrderTax?: string;
}

interface IGcMessageEventLocReview {
	sendLOCReviewRequest: boolean;
}

interface IGcMessageEventUpdateTaxId {
	buyerVATNumber?: string;
}

interface IGcMessageEventUpdateTaxInfo {
	sendTaxInfo: boolean;
}

export type TGcMessageEventData =
	| IGcMessageEventOrderData
	| IGcMessageEventUpdateTaxInfo
	| IGcMessageEventLocReview
	| IGcMessageEventUpdateTaxId;

type TGcMessageEventHandlerProps = {
	chargeOrderId: TChargeOrderId;
	onSuccess: () => void;
	onEvent: (event: MessageEvent<TGcMessageEventData>) => void;
};

export const useGcMessageEventHandler = (props: TGcMessageEventHandlerProps) => {
	const { onSuccess, chargeOrderId, onEvent } = props;
	const { authCompany, isGroupPartner, updateTaxId } = useAuthContext();

	// Prepare queries
	const gcOrderSubmission = useApiGcOrderSubmission({ config: { params: { chargeOrderId } } });
	const updatePartnerTaxId = useApiPartnerUpdateTaxId({ config: { params: { partnerId: authCompany?.id } } });
	const updatePartnerSapLocId = useApiPartnerUpdateSapLocId({ config: { params: { partnerId: authCompany?.id } } });
	const locReviewRequestNotification = useApiLocReviewRequestNotification();
	const syncTaxExemption = useApiPartnerSyncTaxExemption({ config: { params: { partnerId: authCompany?.id } } });

	return useCallback(
		(event: MessageEvent<TGcMessageEventData>) => {
			// Accept messages only from HGOP domain
			if (event.origin !== CONFIG.APP.GC_VANITY_URL) {
				return;
			}

			logDebug('MessageEvent', event.data);

			// Update tax info Event
			if (event.data?.hasOwnProperty('sendTaxInfo') && isGroupPartner) {
				const data = event.data as IGcMessageEventUpdateTaxInfo;
				logDebug('syncTaxExemption', data);
				syncTaxExemption.mutate({});
			}

			// LOC review request
			if (event.data?.hasOwnProperty('sendLOCReviewRequest')) {
				const data = event.data as IGcMessageEventLocReview;
				logDebug('locReviewRequestNotification', data.sendLOCReviewRequest);
				if (data.sendLOCReviewRequest) {
					locReviewRequestNotification.mutate({ chargeOrderId });
				}
			}

			// Update TAX Id
			if (event.data?.hasOwnProperty('buyerVATNumber')) {
				const data = event.data as IGcMessageEventUpdateTaxId;
				const taxId = data.buyerVATNumber;
				if (isString(taxId) && isGroupPartner && authCompany?.identifiers?.partyTaxId !== taxId) {
					logDebug('updatePartnerTaxId', authCompany?.id, taxId);
					updatePartnerTaxId.mutate(
						{ taxId },
						{
							onSuccess() {
								updateTaxId(taxId);
							},
						},
					);
				}
			}

			// Order status Event
			const data = event.data as IGcMessageEventOrderData;
			if (data?.gcOrderStatus) {
				const { gcOrderStatus, gcOrderID, gcPageName, gcLineOfCreditID } = data;
				const successStatuses: GcOrderStatusEnum[] = [
					GcOrderStatusEnum.REQUISITION_SUBMITTED,
					GcOrderStatusEnum.REQUISITION_COMPLETE,
					GcOrderStatusEnum.REQUISITION_PENDING_FUNDS,
				];

				if (successStatuses.includes(gcOrderStatus)) {
					if (gcPageName === 'ThankYouPage') {
						const { gcOrderTotal = '', gcOrderTax = '' } = data;
						logDebug('gcOrderSubmission', gcOrderStatus, gcOrderID, gcOrderTotal, gcOrderTax);

						const payload: IStandardOrderGcSubmission = {
							gcOrderId: gcOrderID,
							totalAmountWithTax: Number(gcOrderTotal) || 0,
							totalTax: Number(gcOrderTax) || 0,
						};

						if (gcOrderStatus === GcOrderStatusEnum.REQUISITION_PENDING_FUNDS) {
							payload.billingStatus = 'Waiting Payment';
						} else {
							// GC CC/LOC payments - prevent race conditions
							onSuccess();
							return;
						}

						// Update order status -> only for OMS usage
						// - an OCN will refresh order in a while,
						// 		but we are not able to wait for the OCN,
						// 		therefore we change status by this call
						gcOrderSubmission.mutate(payload);
						onSuccess();
					}
				}

				if (
					isGroupPartner &&
					isString(gcLineOfCreditID) &&
					gcOrderStatus === GcOrderStatusEnum.REQUISITION_OPEN &&
					gcPageName === 'QuickBuyCartPage'
				) {
					if (authCompany?.identifiers?.sapLineOfCreditId !== gcLineOfCreditID) {
						logDebug('updatePartnerSapLocId', gcOrderStatus, gcLineOfCreditID);
						updatePartnerSapLocId.mutate({ sapLocId: gcLineOfCreditID });
					}
				}
				onEvent(event);
			}
		},
		[
			isGroupPartner,
			onSuccess,
			onEvent,
			authCompany,
			chargeOrderId,
			gcOrderSubmission,
			locReviewRequestNotification,
			updatePartnerTaxId,
			updateTaxId,
			updatePartnerSapLocId,
			syncTaxExemption,
		],
	);
};
