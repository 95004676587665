import { useFormikContext } from 'formik';
import React from 'react';
import { useCompare } from 'js/hooks/useCompare';
import { useDebouncedCallback } from 'use-debounce';

type TAutoSaveProps = {
	wait?: number;
};

/**
 * AutoSave simulation in formik - you can save data to localstorage / pass it to handler / etc.
 *
 * @param {TAutoSaveProps} props
 * @returns {null}
 * @constructor
 */
export const AutoSave = <Values extends object>(props: TAutoSaveProps): null => {
	const { wait = 1000 } = props;
	const { values, submitForm } = useFormikContext<Values>();
	const isChanged = useCompare<Values>(values, true);
	const save = useDebouncedCallback(submitForm, wait);

	React.useEffect(() => {
		if (isChanged) {
			save();
		}
	}, [save, values, isChanged]);

	return null;
};
