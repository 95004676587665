import React from 'react';
import { Button } from '@avast/react-ui-components';
import { RouteName } from 'module/RouteName';
import { useNavigate } from 'react-router-dom';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { homepageConfig } from 'module/homepage/homepageConfig';
import { useTranslation } from 'react-i18next';
import { useWidgetContext } from 'submodule/widgets/context';

export const EditWidgetButton = () => {
	const navigate = useNavigate();
	const [t] = useTranslation(homepageConfig.trNamespace);
	const { isEditable } = useWidgetContext();

	if (!isEditable) {
		return null;
	}

	return (
		<div className="text-warning ms-2">
			<Button
				size="sm"
				variant="link"
				iconLeftFa={faWandMagicSparkles}
				onClick={() => navigate(RouteName.HOMEPAGE.EDIT_WIDGETS)}
			>
				{t('submodules:widgets.action.editWidgets')}
			</Button>
		</div>
	);
};
