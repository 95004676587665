import { ILocaleConfig } from 'types/config';
import { dateFormatter } from 'js/utils/dateTime';
import React, { useMemo } from 'react';
import { faCalendarAlt, faDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFormatCurrencyExample } from 'submodule/localeSwitch/utils/localeUtils';

type TLocaleFormatPreviewProps = {
	config: ILocaleConfig;
};

export const LocaleFormatPreview = (props: TLocaleFormatPreviewProps) => {
	const { config } = props;
	const date = useMemo(() => new Date(), []);

	const dateValue = dateFormatter.default(date, 'DATE', config.locale);
	const numberValue = getFormatCurrencyExample(12540.86, config.locale);

	return (
		<>
			<FontAwesomeIcon icon={faCalendarAlt} /> {dateValue} - <FontAwesomeIcon icon={faDollar} /> {numberValue}
		</>
	);
};
