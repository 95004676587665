import { parseEnvInt } from 'js/utils/app';
import { DateTime } from 'luxon';

export const isScheduledMaintenance = () => Boolean(parseEnvInt(process.env.REACT_APP_MAINTENANCE));

export const scheduledMaintenanceEnd = (): DateTime | null => {
	const endTimestamp = parseEnvInt(process.env.REACT_APP_MAINTENANCE_END);
	if (!endTimestamp) {
		return null;
	}

	const date = DateTime.fromSeconds(endTimestamp);
	return date.isValid ? date : null;
};
