import React from 'react';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { IEntityLicense } from 'module/licenses';
import { useLicensePartner } from 'module/licenses/hooks';
import { DataTable } from 'js/components/molecules/DataTable';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { getPartnerFullName, getPartnerRenderName } from 'module/partners/utils/partnerSelectors';
import { getLicenseExternalIds } from 'module/licenses/utils/common';
import { useAuthContext } from 'js/contexts';

export const PartnerGroup = () => {
	const [t] = useTranslation(licensesConfig.trNamespace);
	const { data: license } = usePageDetailContext<IEntityLicense>();
	const { isGroupPartner, authCompanySalesforceId } = useAuthContext();
	const { partner, isFetching, hasPartner } = useLicensePartner(license);
	const { partnerSalesforceId } = getLicenseExternalIds(license);

	if (!hasPartner || (isGroupPartner && authCompanySalesforceId !== partnerSalesforceId)) {
		return null;
	}

	return (
		<>
			<DataTable.Tr
				name={t('entity.partner.name')}
				highlighted
				isLoading={isFetching}
			>
				{getPartnerRenderName(partner)}
			</DataTable.Tr>
			<DataTable.Tr name={t('entity.partner.id')}>{partnerSalesforceId}</DataTable.Tr>
			<DataTable.Tr
				name={t('entity.partner.contact')}
				isLoading={isFetching}
			>
				{getPartnerFullName(partner)}
			</DataTable.Tr>
		</>
	);
};
