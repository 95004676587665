import React, { ReactElement } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ILoginPageState } from 'module/security';
import { RouteName } from 'module/RouteName';
import { aclCheck, TAclAction, TAclModules } from 'config/acl';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { useAuthGuards } from 'js/hooks/useSecurityGuards';
import { IAuthGuards } from 'types';
import { useAuthContext } from 'js/contexts';

interface IProtectedRoute {
	acl?: [TAclModules, TAclAction];
	authGuards?: IAuthGuards;
}

export const AuthRoute = ({ acl, authGuards }: IProtectedRoute): ReactElement => {
	const authContext = useAuthContext();
	const [t] = useTranslation('error');
	const location = useLocation();
	const isAllowedByAuthGuards = useAuthGuards(authGuards);

	// Not logged
	if (!authContext.isLogged) {
		const state: ILoginPageState = { authRedirectFrom: { to: location, state: location.state } };
		return (
			<Navigate
				to={{ pathname: RouteName.SECURITY.LOGIN }}
				state={state}
				replace
			/>
		);
	}

	// ACL check if set
	if (acl) {
		const [module, action] = acl;
		if (!aclCheck(authContext, module, action)) {
			toast.warning(<Toast>{t('permissionDenied')}</Toast>);
			return <Navigate to={RouteName.HOMEPAGE.DEFAULT} />;
		}
	}

	// Group
	if (!isAllowedByAuthGuards) {
		toast.warning(<Toast>{t('permissionDenied')}</Toast>);
		return <Navigate to={RouteName.HOMEPAGE.DEFAULT} />;
	}

	return <Outlet />;
};
