import {
	IApiCustomerCreateParams,
	IApiCustomerFilter,
	IApiCustomerListFilter,
	IApiCustomerUpdateParams,
	IApiPartnerCustomerCount,
	IApiPartnerCustomerCountResponse,
	IApiPartnerCustomerFilter,
	IApiPartnerCustomerListFilter,
	IAsyncExportCustomersRequest,
	IEntityCustomer,
	TCustomerIndustry,
} from 'module/customers';
import { TUseApiListModule, useApiListQuery } from 'js/queries/useApiListQuery';
import { TUseApiDetailModule, useApiDetailQuery } from 'js/queries/useApiDetailQuery';
import { TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import { CACHE_TIME } from 'appConstants';
import { customerFilterNormalizer } from 'module/customers/normalizer';
import { ApiMutationTypeEnum } from 'js/enums';
import { IAsyncJob } from 'js/asyncJobs';

export const API_CUSTOMERS_KEYS = {
	PARTNER_LIST: 'partner/:partnerId/customer',
	PARTNER_LIST_COUNT: 'partner/:partnerId/customer/count',
	PARTNER_DETAIL: 'partner/:partnerId/customer/:salesforceId',
	LIST: 'customer',
	DETAIL: 'customer/:salesforceId',
	INDUSTRY_OPTIONS: 'customer/industry-options',
	UPDATE: 'partner/:partnerId/customer/:salesforceCustomerId',
	CREATE: 'partner/:partnerId/customer',
	EXPORT: 'export/customers',
};

export const useApiCustomerList: TUseApiListModule<IEntityCustomer, IApiCustomerListFilter> = (props) => {
	return useApiListQuery(API_CUSTOMERS_KEYS.LIST, props);
};

export const useApiCustomer: TUseApiDetailModule<IEntityCustomer, IApiCustomerFilter> = (props) => {
	return useApiDetailQuery(API_CUSTOMERS_KEYS.DETAIL, props);
};

export const useApiPartnerCustomerList: TUseApiListModule<IEntityCustomer, IApiPartnerCustomerListFilter> = (props) => {
	return useApiListQuery(API_CUSTOMERS_KEYS.PARTNER_LIST, props, {
		apiFilterNormalizer: (filter) => customerFilterNormalizer.denormalize(filter),
	});
};

export const useApiPartnerCustomerCount: TUseApiDetailModule<
	IApiPartnerCustomerCountResponse,
	IApiPartnerCustomerCount
> = (props) =>
	useApiDetailQuery(API_CUSTOMERS_KEYS.PARTNER_LIST_COUNT, props, {
		queryConfig: { staleTime: CACHE_TIME.MEDIUM },
	});

export const useApiPartnerCustomer: TUseApiDetailModule<IEntityCustomer, IApiPartnerCustomerFilter> = (props) => {
	return useApiDetailQuery(API_CUSTOMERS_KEYS.PARTNER_DETAIL, props);
};

export const useApiUpdateCustomer: TUseApiMutationModule<
	Partial<IEntityCustomer>,
	IEntityCustomer,
	IApiCustomerUpdateParams
> = (props) =>
	useApiMutationQuery(API_CUSTOMERS_KEYS.UPDATE, ApiMutationTypeEnum.PATCH, props, {
		invalidateQueries: Object.values(API_CUSTOMERS_KEYS),
	});

export const useApiCreateCustomer: TUseApiMutationModule<
	Partial<IEntityCustomer>,
	IEntityCustomer,
	IApiCustomerCreateParams
> = (props) =>
	useApiMutationQuery(API_CUSTOMERS_KEYS.CREATE, ApiMutationTypeEnum.POST, props, {
		invalidateQueries: Object.values(API_CUSTOMERS_KEYS),
	});

export const useApiIndustryOptions: TUseApiListModule<TCustomerIndustry> = (props) => {
	return useApiListQuery(API_CUSTOMERS_KEYS.INDUSTRY_OPTIONS, props);
};

export const useApiPartnerCustomersExport: TUseApiMutationModule<IAsyncExportCustomersRequest, IAsyncJob> = (props) => {
	return useApiMutationQuery(API_CUSTOMERS_KEYS.EXPORT, ApiMutationTypeEnum.POST, props);
};
