export default {
	actions: {
		create: '新しいプロモーションを作成',
		edit: 'プロモーションを編集',
		disable: {
			action: 'プロモーションを無効にする',
			success: 'プロモーションが無効になりました',
			loading: 'プロモーションを無効にしています...',
			confirmation: {
				title: 'プロモーションを無効にしています',
				message: 'このプロモーションを無効にしますか？この処理を元に戻すことはできません。',
			},
		},
		form: {
			create: 'プロモーションを送信',
			update: 'プロモーションを更新',
		},
		extraFilters: 'その他のフィルタ',
		export: 'プロモーションをエクスポート',
	},
	common: {
		effectiveDate: '発効日',
		filterPromotions: 'プロモーションをフィルタ',
		partyCriteria: '当事者の条件',
		partyCriteriaInfo: '続行するには、パートナーまたは少なくとも 1 つの当事者の条件を選択してください。',
		productCriteria: '製品の条件',
		creatingPromotion: 'プロモーションを作成中',
		updatingPromotion: 'プロモーションを更新中',
		invalidPromotionStartDate: {
			title: 'プロモーションを保存しますか？',
			message: 'プロモーションの開始日が過去の日付になっています。プロモーションを編集できなくなります。',
		},
	},
	error: {
		promotionNotFound: 'プロモーションが見つかりません。',
		createPromotion: 'プロモーションを作成できません。',
		updatePromotion: 'プロモーションを更新できません。',
	},
	page: {
		create: {
			title: '新しい価格プロモーション',
			success: '新しい価格プロモーションが正常に作成されました。',
		},
		update: {
			title: 'プロモーション #{{id}} を更新',
			success: '価格プロモーションが正常に更新されました。',
			notEditable: '現在のプロモーションは注文に関係している可能性があるため編集できません。',
		},
	},
	placeholder: {
		types: 'すべてのプロモーションの種類',
		partyTiers: 'すべての層',
		categories: 'すべてのカテゴリ',
		licenseOperations: 'すべてのライセンス処理',
		partyCountries: 'すべての国',
		partyTypes: 'すべての当事者の種類',
		products: 'すべての製品',
	},
	entity: {
		id: 'プロモーション ID',
		name: 'プロモーション名',
		date: '有効な日付の範囲',
		partyType: '当事者の種類',
		partyType_other: '当事者の種類',
		partyTier: '当事者の層',
		partyTier_other: '当事者の層',
		partyCountry: '当事者の国',
		partyCountry_other: '当事者の国',
		licenseOperation: 'ライセンス処理の種類',
		licenseOperation_other: 'ライセンス処理の種類',
		category: '製品カテゴリ',
		product: '製品',
		product_other: '製品',
		quantityMin: '最小数量',
		rate: '割引率',
		scope: '範囲',
		type: 'プロモーションの種類',
		unitsFrom: '単位の先頭',
		unitsTo: '単位の末尾',
	},
	tooltip: {
		zeroQuantity: '数量ゼロは、注文するライセンス数に関係なくプロモーションが適用されることを意味します。',
	},
};
