import React from 'react';
import {
	OrderItemPriceDetail,
	TOrderItemPriceDetailProps,
} from 'module/orders/components/modalContent/OrderItemPriceDetail';
import { useTranslation } from 'react-i18next';
import { AsyncModal, TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { Modal } from '@avast/react-ui-components';

export type TPriceDetailModalProps = TOrderItemPriceDetailProps;

export const AsyncPriceDetailModal = (props: TAsyncModalContainerProps<TPriceDetailModalProps>) => {
	const [t] = useTranslation('moduleOrders');
	const { forwardedRef } = props;

	return (
		<AsyncModal<TPriceDetailModalProps>
			ref={forwardedRef}
			size="lg"
			testId="priceDetails"
		>
			{({ order, orderItem }) => (
				<>
					<Modal.Header>{t('common.priceDetails')}</Modal.Header>
					<Modal.Body>
						<OrderItemPriceDetail
							order={order}
							orderItem={orderItem}
						/>
					</Modal.Body>
					<Modal.Footer cancelButton />
				</>
			)}
		</AsyncModal>
	);
};
