import { useEffect, useReducer } from 'react';
import { AffiliateConfigEnum } from 'module/partners/enums';
import { TUserAppMessageId, TUserAppMessagesConfig } from 'submodule/AppMessages/types';
import { affiliateConfigUiValueToApi, getAffiliateConfig_UserAppMessages } from 'module/partners/utils/affiliateConfig';
import { logError } from 'js/utils/app';
import { useApiAffiliateConfigUpdate } from 'module/partners/hooks/useApiPartners';
import { appMessagesReducer, TAppMessagesReducerState } from 'submodule/AppMessages/reducers';
import { useAuthContext } from 'js/contexts';

type TUseUserAppMessagesConfig = TAppMessagesReducerState & {
	hideMessage(id: TUserAppMessageId): void;
};

export const useUserAppMessagesConfig = (): TUseUserAppMessagesConfig => {
	const {
		authPartnerId,
		authState: { token },
	} = useAuthContext();
	const [state, dispatch] = useReducer(appMessagesReducer, {
		isReady: false,
		loading: true,
		messagesConfig: [],
		hiddenMessageIds: [],
	});

	// Prepare update config call
	const { mutateAsync: affiliateConfigUpdate } = useApiAffiliateConfigUpdate({
		config: { params: { id: authPartnerId, name: AffiliateConfigEnum.USER_APP_MESSAGES } },
	});

	// Load on init
	useEffect(() => {
		getAffiliateConfig_UserAppMessages({
			affiliateId: authPartnerId!,
			apiConfig: { authToken: token },
		})
			.then((payload) => dispatch({ type: 'SET_DATA', payload }))
			.catch(logError); // Pass error and just log to Sentry
	}, [authPartnerId, token]);

	// On hide new message
	useEffect(() => {
		if (!state.isReady || !state.isChanged) {
			return;
		}

		// Update in OMS
		const value = affiliateConfigUiValueToApi<TUserAppMessagesConfig>(
			AffiliateConfigEnum.USER_APP_MESSAGES,
			state.messagesConfig,
		);
		affiliateConfigUpdate({ value }).catch(logError); // Pass error and just log to Sentry
	}, [state.messagesConfig, state.isReady, state.isChanged, affiliateConfigUpdate]);

	return {
		...state,
		hideMessage(id) {
			dispatch({ type: 'HIDE_MESSAGE', payload: id });
		},
	};
};
