import { useParams } from 'react-router-dom';
import { omit } from 'lodash';
import { useMemo } from 'react';

// @see https://github.com/remix-run/react-router/issues/8865

export const useRouteParams = <
	Type extends Record<string, string | undefined> = Record<string, string | undefined>,
>(): Type => {
	const params = useParams<Type & { '*'?: string }>();
	return useMemo(() => omit(params, '*') as Type, [params]);
};
