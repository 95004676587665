import React from 'react';
import { Button } from '@avast/react-ui-components';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { useTranslation } from 'react-i18next';
import { AsyncExportModal } from 'js/components/exports/AsyncExportModal';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { IAsyncExportProps } from 'js/components/exports';
import { IAsyncExportPromotionRequest, IPromotionListFilter } from 'module/promotions';
import { useApiPromotionExport } from 'module/promotions/hooks/useApiPromotion';
import { IListMetaDataValues } from 'types/utils';
import { promotionFilterNormalizer } from 'module/promotions/normalizer';

type TExportPromotionButtonProps = {
	listMetaData: IListMetaDataValues<IPromotionListFilter>;
};

export const ExportPromotionsButton = (props: TExportPromotionButtonProps) => {
	const { listMetaData } = props;
	const { totalCount, filter } = listMetaData;
	const [t] = useTranslation(promotionsConfig.trNamespace);
	const exportRef = useAsyncModalRef<IAsyncExportProps<IAsyncExportPromotionRequest>>();
	const isDisabled = totalCount === 0;
	const exportPromotions = () => {
		exportRef.current?.show({
			request: promotionFilterNormalizer.denormalize(filter),
			useQuery: useApiPromotionExport,
		});
	};

	return (
		<>
			<Button
				size="sm"
				disabled={isDisabled}
				onClick={exportPromotions}
				variant="outline-primary"
			>
				{t('actions.export')}
			</Button>
			<AsyncExportModal
				forwardedRef={exportRef}
				testId="exportPromotion"
			/>
		</>
	);
};
