import { IEntityProductGroup } from 'module/purchase';
import { sortProducts } from 'module/purchase/utils/selectors';
import { toLower } from 'lodash';
import { hasProductGroupProducts } from 'module/purchase/utils/common';

export const productGroupEntityNormalizer = {
	normalizeList(list: IEntityProductGroup[]): IEntityProductGroup[] {
		return list
			.sort((a, b) => toLower(a.name).localeCompare(toLower(b.name)))
			.filter(hasProductGroupProducts)
			.map((productGroup) => ({
				...productGroup,
				products: productGroup.products.sort(sortProducts),
			}));
	},
};
