import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum LicenseDateRangeTypeEnum {
	CREATED_AT = 'CREATED_AT',
	EXPIRATION = 'EXPIRATION',
	REGISTERED_AT = 'REGISTERED_AT',
}

export const licenseDateRangeTypeEnumUtils = generateEnumUtils<LicenseDateRangeTypeEnum>(LicenseDateRangeTypeEnum, {
	translatePath: 'enums:licenses.datepickerDateType',
});
