import { IEntityOrder } from 'module/orders';
import { useOrderParties } from 'module/orders/hooks/orderToInstance/useOrderParties';
import { useOrderPriceList } from 'module/orders/hooks/orderToInstance/useOrderPriceList';
import { useProductGroupFinder } from 'module/orders/hooks/orderToInstance/useProductGroupFinder';
import { useOrderInstanceProducts } from 'module/orders/hooks/orderToInstance/useOrderInstanceProducts';
import { TOrderInstanceData } from 'module/orders/hooks/orderToInstance';

export const useOrderInstanceData = (order: IEntityOrder | null): TOrderInstanceData => {
	// Order parties
	const parties = useOrderParties(order);

	// Price list
	const priceList = useOrderPriceList(order, parties);

	// Products data
	const productGroupFinder = useProductGroupFinder(order, parties, priceList);
	const products = useOrderInstanceProducts(order, productGroupFinder);

	return {
		parties,
		priceList,
		products,
		isFetching: parties.isFetching || priceList.isFetching || productGroupFinder.isFetching || products.isFetching,
	};
};
