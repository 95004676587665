export default {
	requestId: 'Code de demande',
	errorId: 'Code d’erreur',
	errorDetails: 'Détails des erreurs',
	api: {
		activeEntitlementNotFound:
			'Cette opération liée aux licences ne peut pas être effectuée car l’autorisation a déjà été remplacée.',
		invalidHash: 'Le dièse n’est pas valide.',
		distributionPartnerSalesforceConflict:
			'Le contact fourni relève d’une autre organisation. Contactez votre responsable de compte pour en savoir plus.',
		passwordResetInvalidToken: 'Le jeton de réinitialisation du mot de passe n’est pas valide.',
		passwordResetExpiredToken: 'Le jeton de réinitialisation du mot de passe a expiré.',
		passwordAlreadyUsed: 'Ce mot de passe a déjà été utilisé. Choisissez un autre mot de passe.',
		partnerNotRegistered:
			'Les commandes ne sont autorisées que pour les comptes inscrits. Demandez au partenaire d’effectuer tout d’abord son inscription au système de gestion des commandes.',
		nowTermsNotAccepted:
			'Les commandes sont autorisées pour les comptes ayant accepté les conditions générales. Demandez au partenaire d’accepter les conditions générales mises à jour.',
		licenseOperationWrongSegment:
			'La partie facturable sélectionnée n’est pas autorisée à acheter des produits destinés aux entreprises/particuliers.',
		partnerQuoteCurrencyMismatch:
			'Cette commande ne peut pas être traitée car la devise du compte diffère de la devise du devis. Contactez votre responsable de compte et demandez-lui un nouveau devis.',
	},
	page: {
		404: {
			title: 'Message du service informatique (page 404)',
			message: 'Une erreur s’est produite, l’URL demandée n’existe pas. Cliquez ci-dessous pour continuer.',
			back: 'Page principale',
		},
	},
	status: {
		networkError: 'Impossible de se connecter au serveur.',
	},
	common: {
		contactSales: 'Contactez votre responsable de compte pour obtenir de l’aide.',
		repeatOrContactSales: 'Réessayez plus tard ou contactez votre responsable de compte pour obtenir de l’aide.',
	},
	detailNotFound: 'Les informations n’ont pas pu être chargées ou n’existent pas.',
	permissionDenied: 'Vous n’êtes pas autorisé à accéder à cette action/ressource.',
	sectionDataNotFound: 'Les données relatives à cette section n’ont pas été trouvées.',
	beforeUnload: 'Si vous quittez maintenant, vous risquez de perdre vos données.',
	unspecifiedError: 'Votre demande n’a pas pu être traitée.',
	errorDetail: 'Détails techniques (à fournir lors du signalement d’une erreur)',
};
