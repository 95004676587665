import React, { useEffect, useRef, useState } from 'react';
import { useEventListener } from 'js/hooks/useEventListener';
import { Modal } from '@avast/react-ui-components';
import { IHgopResponse } from 'js/hgop/useApiHgop';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import classNames from 'classnames';
import { Beforeunload } from 'react-beforeunload';
import { useTranslation } from 'react-i18next';
import { TGcMessageEventData, useGcMessageEventHandler } from 'js/hgop/useGcMessageEventHandler';
import { TChargeOrderId, TEventDetailId } from 'types';

const HGOP_IFRAME_NAME = 'HGOP_POST_RESULT_IFRAME';

type THgopModalProps = {
	id: TEventDetailId;
	chargeOrderId: TChargeOrderId;
	hgop: IHgopResponse;
	onHide: () => void;
	onSuccess: () => void;
};

export const HgopModal = (props: THgopModalProps) => {
	const { hgop, id, chargeOrderId, onHide, onSuccess } = props;
	const ref = useRef<HTMLFormElement | null>(null);
	const [loading, setLoading] = useState(false);
	const [t] = useTranslation('components');
	const eventHandler = useGcMessageEventHandler({
		chargeOrderId,
		onSuccess,
		onEvent: () => setLoading(false),
	});

	useEventListener<MessageEvent<TGcMessageEventData>>('message', eventHandler);

	// Submit the form on mount
	// - Note: The server is expecting a request from the FORM, so we cannot use AJAX
	useEffect(() => {
		ref.current?.submit();
		setLoading(true);
	}, []);

	return (
		<Modal
			show
			backdrop="static"
			onHide={onHide}
			testId="hgop"
		>
			<Modal.Header>
				{t('hgop.shoppingCart.title')} <small>#{id}</small>
			</Modal.Header>
			<Modal.Body>
				{loading && <LoadingPlaceholder type="BAR" />}
				<iframe
					name={HGOP_IFRAME_NAME}
					title="HGOP IFRAME"
					className={classNames('component__HGOP-iframe', { 'd-none': loading })}
				/>
				<form
					className="d-none"
					method="post"
					action={hgop.url}
					target={HGOP_IFRAME_NAME}
					acceptCharset="utf-8"
					ref={ref}
				>
					<textarea
						name="message"
						className="d-block"
						rows={1}
						value={hgop.payload}
						readOnly
					/>
					<input type="submit" />
				</form>
			</Modal.Body>
			<Beforeunload onBeforeunload={() => t('error:beforeUnload')} />
		</Modal>
	);
};
