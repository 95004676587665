import React from 'react';
import { AuthRoute } from 'js/routes/AuthRoute';
import { ACL_CREATE } from 'config/acl';
import { PageDefault, PageOrder, PageProducts } from 'module/purchase/pages';
import { Error404 } from 'module/error/page/Error404';
import { Route, Routes } from 'react-router-dom';
import { purchaseConfig } from 'module/purchase/purchaseConfig';

export const PurchaseRoutes = () => {
	const { aclModule } = purchaseConfig;

	return (
		<Routes>
			<Route element={<AuthRoute acl={[aclModule, ACL_CREATE]} />}>
				<Route
					index
					element={<PageDefault />}
				/>
				<Route
					path="products"
					element={<PageProducts />}
				/>
				<Route
					path="order"
					element={<PageOrder />}
				/>
			</Route>
			<Route
				path="*"
				element={<Error404 />}
			/>
		</Routes>
	);
};
