export default {
	actions: {
		detail: '檢視授權詳細資料',
		export: '匯出授權',
		exportDetailed: '詳細出口資訊',
		followingLicense: '後續授權',
		lastNDays: '最近 {{count}} 天到期',
		nextNDays: '未來 {{count}} 天到期',
		operations: '授權操作',
		precedingLicense: '前置授權',
		registerTo: '將授權註冊給...',
		relatedCustomer: '檢視相關客戶',
		relatedOrder: '檢視相關訂單',
	},
	error: {
		disallowed: '此授權不允許授權操作。',
		operation: {
			invalidParty: '授權方與目前的訂單方不同',
			alreadyInCart: {
				title: '此授權已在購物車中。',
				message: '您的購物車中已有此授權，是否要將其取代為所選的授權操作？',
			},
			product: {
				noOperationsAvailable: '沒有可用的授權操作。',
			},
			partner: {
				unableToLoad: '無法載入授權合作夥伴。',
			},
			customer: {
				unableToLoad: '無法載入授權客戶。',
			},
			distributionPartner: {
				unableToLoad: '無法載入授權經銷合作夥伴。',
			},
			actions: {
				rewriteOperation: '取代授權操作',
			},
			billablePartyRequired: '授權沒有可計費方。前往「新品全新購買」並選取可計費方。',
		},
		registration: '無法註冊授權。',
		detailNotFound: '找不到權利詳細資料 #{{id}}',
	},
	common: {
		operation: {
			addToCart: '授權操作已新增至購物車。',
			goToCart: '前往購物車',
		},
	},
	entity: {
		createdAt: '建立日期',
		customer: {
			contact: '客戶聯絡人',
			id: '客戶 ID',
			email: '客戶電子郵件地址',
			name: '客戶',
		},
		partner: {
			contact: '合作夥伴聯絡人',
			id: '合作夥伴 ID',
			name: '合作夥伴',
		},
		distributionPartner: {
			contact: '經銷合作夥伴聯絡人',
			id: '經銷合作夥伴 ID',
			name: '經銷合作夥伴',
		},
		date: '日期',
		entitlementId: '權利 ID',
		licenseId: '授權 ID',
		licenseType: '授權類型',
		modifiedAt: '修改日期',
		orderId: '訂單 ID',
		product: {
			termLength: '期限長度',
			type: '產品類型',
		},
		registeredAt: '註冊日期',
		status: '授權狀態',
		statusShort: '狀態',
		statusPlaceholder: '任何狀態',
		walletKey: '電子錢包金鑰',
	},
	licenseOperation: {
		label: '授權操作',
	},
	registration: {
		registering: '授權註冊',
		success: '授權已成功註冊給客戶：{{customer}}',
		error: '註冊授權時發生錯誤。',
		confirmation: {
			title: '授權註冊',
			place: '您確定要將此客戶附加到這個授權嗎？',
			replace: '授權 #{{licenseId}} 已註冊。您要繼續註冊此授權嗎？',
		},
	},
	selectParty: {
		partner: {
			title: '選取合作夥伴進行訂購',
			billableParty: '此合作夥伴將成為計費方。',
			requiredBillableParty: '如果沒有合作夥伴，就無法下單。',
			emptyBillableParty: '在下一個步驟中，將選取客戶作為計費方。',
			license: {
				title: '授權合作夥伴',
				button: '使用授權合作夥伴',
				buttonEmpty: '從訂單中移除合作夥伴',
				empty: '授權沒有合作夥伴。',
			},
			order: {
				title: '訂單合作夥伴',
				button: '保留訂單合作夥伴',
				empty: '訂單沒有合作夥伴。',
				notAllowed: '合作夥伴無法對此產品群組執行授權操作。',
			},
		},
		customer: {
			title: '選取客戶進行訂購',
			billableParty: '此客戶將成為計費方。',
			requiredBillableParty: '如果沒有客戶，就無法下單。',
			emptyBillableParty: '合作夥伴將成為計費方。',
			license: {
				title: '授權客戶',
				button: '使用授權客戶',
				buttonEmpty: '從訂單中移除客戶',
				empty: '授權沒有客戶。',
			},
			order: {
				title: '訂單客戶',
				button: '保留訂單客戶',
				empty: '客戶沒有客戶。',
			},
		},
		distributionPartner: {
			title: '選取經銷合作夥伴進行訂購',
			license: {
				title: '授權經銷合作夥伴',
				button: '使用授權經銷合作夥伴',
				buttonEmpty: '從訂單中移除經銷合作夥伴',
				empty: '授權沒有經銷合作夥伴。',
				notAllowed: '無法將經銷合作夥伴指派給所選合作夥伴。',
			},
			order: {
				title: '訂單經銷合作夥伴',
				button: '保留訂單經銷合作夥伴',
				empty: '訂單沒有經銷合作夥伴。',
				notAllowed: '無法將經銷合作夥伴指派給所選合作夥伴。',
			},
		},
	},
};
