import React, { ChangeEvent, ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import { Switch, TSwitchProps } from '@avast/react-ui-components';
import { formik2Props, TFormikFieldProps } from 'js/components/formik/FormikControl';
import { SubmittingIndicatorEnum } from 'js/enums';

export const FormikSwitch = ({ name, onChange, ...props }: TFormikFieldProps<TSwitchProps>): ReactElement => (
	<Field
		name={name}
		type="checkbox"
		value={props.value}
	>
		{(fieldProps: FieldProps) => (
			<Switch
				{...fieldProps.field}
				{...formik2Props<TSwitchProps>(fieldProps, props, SubmittingIndicatorEnum.DISABLED)}
				onChange={(e: ChangeEvent<HTMLInputElement>) => {
					fieldProps.field.onChange(e);
					onChange?.(e);
				}}
			/>
		)}
	</Field>
);
