import React from 'react';
import { AuthRoute } from 'js/routes/AuthRoute';
import { ACL_READ_DETAIL, ACL_READ_LIST } from 'config/acl';
import { Error404 } from 'module/error/page/Error404';
import { Route, Routes } from 'react-router-dom';
import { PageDetail, PageList } from 'module/licenses/pages';
import { licensesConfig } from 'module/licenses/licensesConfig';

export const LicenseRoutes = () => {
	const { aclModule } = licensesConfig;

	return (
		<Routes>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ_LIST]} />}>
				<Route
					index
					element={<PageList />}
				/>
			</Route>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ_DETAIL]} />}>
				<Route
					path="detail/:id"
					element={<PageDetail />}
				/>
			</Route>
			<Route
				path="*"
				element={<Error404 />}
			/>
		</Routes>
	);
};
