export default {
	actions: {
		addProductsToOrder: '製品を追加',
		cancel: '注文をキャンセル',
		cancelQuote: '見積もりをキャンセル',
		createQuote: '新しい見積もりを作成',
		cancelLicense: 'ライセンスをキャンセル',
		cancelSelectedLicenses: '選択したライセンスをキャンセル',
		cancelSelectedLicenses_zero: '選択したライセンスをキャンセル',
		cancelSelectedLicenses_other: '選択したライセンス ({{count}}) をキャンセル',
		detail: '注文の詳細を表示',
		detailQuote: '見積もりの詳細を表示',
		documents: '注文書類を表示',
		edit: '注文を編集',
		editQuote: '見積もりを編集',
		export: {
			default: '注文をエクスポート',
			detailed: '詳細なエクスポート',
			quotes: '見積もりをエクスポート',
		},
		exportQuotes: '見積もりをエクスポート',
		licenses: 'ライセンスを表示',
		placeQuote: '見積もりを注文として配置',
		priceDetails: '価格の詳細を表示',
		thisMonth: '今月',
		viewClosingBalance: '決算残高の注文を表示',
		pay: '支払い',
		payEdit: '支払い/編集',
		payEditQuote: '見積もりを支払い/編集',
		payQuote: '見積もりを支払い',
	},
	common: {
		flexibleBilling: 'フレキシブル請求',
		documents: '注文書類',
		orderNumber: '注文: #{{id}}',
		quoteNumber: '見積もり: #{{id}}',
		priceDetails: '価格の詳細',
		licenses: 'ライセンス',
		itemLicenses: '品目のライセンス',
		paymentInstructions: '支払い指示',
		pricingMessage: '価格設定のメッセージ (全体)',
		promoMessage: 'プロモーションのメッセージ (全体)',
		refunds: '注文の返金',
		initiateRefund: '返金を開始',
	},
	entity: {
		approvalStatus: '承認のステータス',
		billablePartyName: '請求可能な当事者名',
		billablePartyType: '請求可能な当事者の種類',
		created: '作成日',
		createdBy: '作成者',
		creationSource: '作成元',
		purchaseType: '購入の種類',
		purchaseTypePlaceholder: 'すべての購入タイプ',
		creationSourcePlaceholder: 'すべての作成元',
		date: '日付',
		discountApplied: '適用された割引',
		discountInformation: '割引情報',
		gcOrderNumber: 'Commerce の注文番号',
		paymentStatusPlaceholder: 'すべてのステータス',
		noDiscount: '割引なし',
		opportunityId: '案件番号',
		orderAfterDiscount: '割引後の注文',
		orderNumber: '注文番号',
		orderNumberShort: '注文番号',
		orderStatusPlaceholder: 'すべてのステータス',
		originateAsQuote: '見積もりとして生成',
		quoteNumber: '見積もり番号',
		quoteNumberShort: '見積もり番号',
		quoteStatus: '見積もりのステータス',
		quoteType: '見積もりの種類',
		statementId: ' 明細書 ID',
		totalAmount: '値',
	},
	error: {
		orderNotFound: '注文 #{{id}} が見つかりません',
		orderNotEditable: '注文 #{{id}} は編集できません',
		noProductsInOrder: {
			caption: 'この注文には製品がありません',
			text: '製品を追加するか、注文をキャンセルしてください。注文を送信/保存するまで変更は適用されません。',
		},
		orderChangesAreNotPersist: '注文を完了または保存して変更を保存してください。',
		orderCancellation: '注文をキャンセルできませんでした。',
		orderSubmissionCancellation: '注文の送信をキャンセルできませんでした。',
	},
	discountType: {
		line: '明細行の割引',
		other: '{{type}} {{category}}',
	},
	tab: {
		customer: '顧客',
		discretionaryDiscount: '裁量割引',
		itemsInOrder: '注文の品目',
		itemsInQuote: '見積もりの品目',
		licenses: 'ライセンス',
		notes: 'メモ',
		orderStatus: '注文ステータス',
		partner: 'アバスト パートナー',
		quoteStatus: '見積もりのステータス',
	},
	licenses: {
		inProcess: '注文のライセンスをリクエストしています。しばらくお待ちください',
		error: {
			title: '現時点では、注文のライセンスを取得できませんでした。後でもう一度ご確認ください。',
			detail:
				'ライセンスの取得を自動的に再試行します。この処理には数分かかる場合があります。生成されたライセンスがここに表示されます。また、このライセンスを電子メールで受信することもできます。',
		},
	},
	paymentInstructions: {
		title: '支払い指示',
		description: '次に示す電信送金の手順をお使いの銀行に指示します',
		amount: '金額',
		referenceNumber: {
			label: '支払い参照番号',
			note: 'この番号を必ず追加してください。そうしないと、お支払いが受領されない可能性があります。この番号全体の使用が銀行から許可されない場合は、番号をコピーして受信者フィールドのメッセージに貼り付けてください。',
		},
		accountHolder: '口座名義人',
		bankName: '銀行名',
		accountNumber: '口座番号',
		iban: {
			label: 'IBAN',
			note: 'IBAN (International Bank Account Number) は銀行口座の特定に使用する国際標準です。支払いの経路指定を円滑化したり、経路指定エラーを回避したりするために使用されます。',
		},
		bic: 'BIC',
		aba: 'ABA/ルーティング ナンバー',
		ach: 'ACH',
		error: {
			notAvailable: '支払い指示を利用できません。',
		},
	},
	cancelOrder: {
		title: '注文のキャンセル',
		message:
			'注文のキャンセルを元に戻したり取り消したりすることはできません。引き続き注文を参照することはできますが、注文に対する処理を実行することはできません。',
		success: '注文を正常にキャンセルしました。',
		error: '注文をキャンセル中にエラーが発生しました。',
		loading: '注文 #{{orderNumber}} をキャンセル中',
		wtoc: {
			title: 'WTOC 注文をキャンセル中',
		},
	},
	discountApproval: {
		approved: '裁量割引が承認され、注文を完了できます。',
		rejected: '裁量割引が却下されます。注文を編集して承認を再度リクエストできます。',
		alert: {
			caption: '裁量割引が承認待ちです',
			text: '割引を確認し、裁量割引を承認/却下するかどうかを決定してください。',
			reject: '却下',
			approve: '承認',
		},
		alertApprove: {
			caption: '裁量割引が却下されました',
			text: 'この割引は引き続き承認できます。',
		},
		confirm: {
			approve: {
				title: '割引を承認',
				message: 'この割引の承認を確定してください。',
				button: '確定 - この割引を承認',
			},
			reject: {
				title: '割引を却下',
				message: 'この割引の却下を確定してください。',
				button: '確定 - この割引を却下',
			},
		},
		status: {
			success: {
				approve: 'この割引は正常に承認されました。',
				reject: 'この割引は正常に却下されました。',
			},
			error: '割引ステータスの変更が失敗しました。',
		},
	},
	cancelLicenses: {
		loading: 'ライセンスをキャンセル中',
		loading_other: 'ライセンスをキャンセル中',
		success: 'ライセンスは正常にキャンセルされました',
		success_other: 'ライセンスは正常にキャンセルされました',
		confirm: {
			title: 'ライセンスをキャンセル',
			title_other: 'ライセンスをキャンセル',
			message: 'ライセンスをキャンセルしますか？',
			message_other: 'ライセンスをキャンセルしますか？',
			message_note:
				'残りのライセンスをすべてキャンセルすると、注文全体がキャンセルされます。キャンセル プロセスを元に戻したり取り消したりすることはできません。',
		},
	},
};
