import { TableColumnsDef } from 'types';
import React, { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { IEntityFlexibleBillingDetail } from 'module/flexibleBilling';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { noValuePlaceholder } from 'js/utils/app';
import { TColumnFormatter } from '@tanstack/react-table';

type TTableData = IEntityFlexibleBillingDetail;

export const useFlexibleBillingOverviewColumns = (currency: string): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(flexibleBillingConfig.trNamespace);

	return useMemo<TableColumnsDef<TTableData>>(() => {
		const columnHelper = createColumnHelper<IEntityFlexibleBillingDetail>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.ellipsis('product.label', {
				header: t('common:entity.product'),
				meta: {
					formatters: [formatter.bold],
				},
			}),
			columnHelper.enableStatus(
				'enabled',
				{
					header: t('common:entity.status'),
				},
				{ t, colored: true },
			),
			columnHelper.text('usageLimitEnabled', {
				header: t('entity.usageLimit'),
				meta: {
					formatters: [
						(hasLimit) => {
							return hasLimit ? t('common.limit') : t('common.noLimit');
						},
						disabledColumnFormatter,
					],
				},
			}),
			columnHelper.number(
				'usageLimit',
				{
					header: t('common:entity.unit_other'),
					meta: { defaultValue: { value: noValuePlaceholder() }, formatters: [disabledColumnFormatter] },
				},
				{ optionalMantissa: true },
			),
			columnHelper.money(
				'currentPrice',
				{
					header: t('entity.yourPrice'),
					meta: { formatters: [disabledColumnFormatter] },
				},
				{ currency },
			),
			columnHelper.number(
				'currentUsage',
				{
					header: t('common:entity.active'),
					meta: { formatters: [disabledColumnFormatter] },
				},
				{ optionalMantissa: true },
			),
			columnHelper.money(
				'currentSpend',
				{
					header: t('common.currentSpend'),
					meta: { formatters: [disabledColumnFormatter] },
				},
				{ currency },
			),
			columnHelper.money(
				'maxSpend',
				{
					header: t('common.maxSpend'),
					meta: {
						formatters: [
							(formattedValue, cell) => {
								const row = cell.row.original;
								return row.enabled && !row.usageLimitEnabled ? t('common.unlimited') : formattedValue;
							},
							disabledColumnFormatter,
						],
					},
				},
				{ currency },
			),
		];
	}, [currency, t]);
};

const disabledColumnFormatter: TColumnFormatter<IEntityFlexibleBillingDetail> = (value, cell) => {
	const row = cell.row.original;
	return row.enabled ? value : <span className="text-gray">{value}</span>;
};
