import React, { FunctionComponent } from 'react';
import { ModuleContainer } from 'js/layouts/container/ModuleContainer';
import { creditStatusConfig } from 'module/creditStatus/creditStatusConfig';
import { CreditStatusRoutes } from 'module/creditStatus/CreditStatusRoutes';

export const CreditStatusContainer: FunctionComponent = () => (
	<>
		<ModuleContainer moduleMenuConfig={creditStatusConfig} />
		<CreditStatusRoutes />
	</>
);
