export default {
	actions: {
		create: '建立新經銷合作夥伴',
		edit: '編輯經銷合作夥伴',
		exportAll: '匯出所有經銷合作夥伴',
		form: {
			create: '另存為新經銷合作夥伴',
			update: '儲存變更',
		},
	},
	error: {
		createDistributionPartner: '無法建立經銷合作夥伴。',
		updateDistributionPartner: '無法更新經銷合作夥伴。',
	},
	page: {
		create: {
			title: '新經銷合作夥伴',
			success: '您已成功建立新經銷合作夥伴。',
		},
		update: {
			success: '您已成功更新經銷合作夥伴。',
		},
	},
	select: {
		title: '選取經銷合作夥伴',
		button: {
			label: '經銷合作夥伴',
			placeholder: '選取經銷合作夥伴',
		},
		filter: {
			placeholder: '透過電子郵件地址、姓名或公司尋找',
		},
	},
};
