export default {
	dateRangeType: {
		BILLING: '請求日',
		CREATED: '作成日',
		PAYMENT: 'お支払い日',
	},
	tierCertification: {
		DISTRIBUTORS: '卸売業者',
		TIER_4: '層 4',
		TIER_3: '層 3',
		TIER_2: '層 2',
		TIER_1: '層 1',
	},
	customer: {
		type: {
			BUSINESS: '企業',
			CONSUMER: '消費者',
		},
		discountType: {
			EDUCATION: {
				name: '教育',
				description:
					'追加の割引が適用される教育機関。この割引を注文に適用する場合は、販売マネージャーにお問い合わせください。',
			},
			GOVERNMENT: {
				name: '行政機関',
				description:
					'追加の割引が適用される行政機関。この割引を注文に適用する場合は、販売マネージャーにお問い合わせください。',
			},
			NOT_FOR_PROFIT: {
				name: '非営利団体',
				description:
					'追加の割引が適用される非営利団体。この割引を注文に適用する場合は、販売マネージャーにお問い合わせください。',
			},
		},
		searchKey: {
			EMAIL: 'メール アドレス',
			SALESFORCE_ID: 'Salesforce ID',
		},
	},
	partner: {
		type: {
			DISTRIBUTOR: '卸売業者',
			RESELLER: '再販業者',
			INTERNAL_COMPANY: '内部会社',
			USER: 'ユーザー',
		},
		subType: {
			RETAIL: '小売り',
			ISP: 'ISP',
			MSP: 'MSP',
			NONE: 'なし',
		},
		terms: {
			DR: 'Digital River Channel Participant Membership Agreement',
			CLIENT: 'パートナー条項',
		},
		payAsYouGoPaymentMethod: {
			LINE_OF_CREDIT: {
				name: 'LOC',
				description:
					'請求書は月末のすぐ後に生成されます。標準の支払い条件が適用されます。LOC 請求書の支払いは銀行振込にのみ対応しています。 ',
			},
			AUTO_BILLING: {
				name: '自動請求',
				description:
					'月末のすぐ後に保存されたカードへの自動請求が行われます。請求が失敗すると、標準の LOC 請求書が生成されます。',
			},
		},
	},
	order: {
		discountCategory: {
			NONE: 'なし',
			DISCRETIONARY: '裁量',
		},
		lineItemDiscountType: {
			STANDARD: '標準',
			SPECIAL: '特別',
			NONE: 'なし',
		},
		approvalFlag: {
			NA: '該当なし',
			APPROVAL_NOT_REQUIRED: '承認は不要',
			APPROVAL_REQUIRED: '承認が必要',
			PENDING_APPROVAL: '承認待ち',
			APPROVED: '承認済み',
			REJECTED: '却下',
		},
		billablePartyType: {
			PARTNER: 'パートナー',
			DISTRIBUTOR: '卸売業者',
			END_CUSTOMER: '最終顧客',
		},
		searchKey: {
			COMMERCE_ORDER_ID: '請求可能な注文 ID',
			OPPORTUNITY_ID: '案件 ID',
			ORDER_NUMBER: '注文番号',
			PO_NUMBER: '発注書番号',
			QUOTE_NUMBER: '見積もり番号',
		},
		status: {
			SAVED_IN_PROGRESS: {
				name: '保存処理中',
				description: '注文が送信されましたが未送信です。変更できるのはこのステータスの注文のみです。',
			},
			ISSUED_AS_CUSTOMER_QUOTE: {
				name: '顧客見積もりとして発行済み',
				description: 'この注文は顧客に送信される見積もりです。顧客が編集することはできません。送信のみ可能です。',
			},
			ISSUED_AS_QUOTE: {
				name: '見積もりとして発行済み',
				description: 'この注文はアバストからの見積もりです。編集することはできません。送信のみ可能です。',
			},
			POSTED_TO_STATEMENT: {
				name: '明細書に転記済み',
				description: 'この注文は月次明細書に転記されました。',
			},
			PENDING_SUBMISSION: {
				name: '送信待ち',
				description: '',
			},
			IN_REVIEW: {
				name: '確認中',
				description: 'この注文は確認待ちです。編集できません。',
			},
			SUBMITTED_TO_COMMERCE: {
				name: 'Commerce に送信済み',
				description: 'この注文は請求用に送信されました。',
			},
			PAYMENT_ERROR: {
				name: '支払いエラー',
				description:
					'支払いエラーが発生しました。支払い情報が正確かつ最新であることを確認した後で注文を再送信する必要があります。',
			},
			OTHER_ERROR: {
				name: 'その他のエラー',
				description:
					'システム エラーが発生しました。3 回再試行しましたが、問題が解決されていません。この注文を再送信して再試行するか、サポートへのお問い合わせが必要です。',
			},
			WAITING_PAYMENT: {
				name: 'お支払い待ち',
				description: 'この注文は請求用に送信されました。お支払いの期限です。',
			},
			COMPLETE: {
				name: '完了',
				description: '支払いが徴収されて注文が完了しました。',
			},
			CANCELED_BY_USER: {
				name: 'ユーザーによってキャンセル済み',
				description: 'ユーザーが注文のキャンセルを選択しました。',
			},
			CANCELED_FOR_FAILURE_TO_PAY: {
				name: '支払いに失敗したためキャンセル済み',
				description: 'この注文の支払いが完了しなかったため、注文はキャンセルされました。',
			},
			EXPIRED: {
				name: '期限切れ',
				description: 'この注文は作成後 30 日間変更されていません。',
			},
			NOT_SUBMITTED: {
				name: '未送信',
				description: 'この注文は請求用に送信されませんでした。',
			},
		},
	},
	payment: {
		status: {
			PAID: {
				name: '支払い済み',
				description: 'この注文は正常に処理され、お支払いが完了しました。',
			},
			UNPAID: {
				name: '未払い',
				description: 'この注文はお支払い待ちであるか、お支払いが完了していません。',
			},
			PARTIAL_PAID: {
				name: '一部支払い済み',
				description: 'この注文には未払い残高があります。',
			},
			NA: {
				name: '未請求',
				description: 'この注文は請求待ち、または請求書の発行待ちです。',
			},
			UNBILLED: {
				name: '未請求',
				description: 'この注文は請求待ち、または請求書の発行待ちです。',
			},
			SETTLED: {
				name: '決済済み',
				description: 'この注文は徴収チームによって処理され、完了しました。',
			},
		},
		type: {
			WIRE_TRANSFER: '電信送金',
			WTOC: 'WTOC',
			CART_SELECTED: 'かごを選択済み',
			CLOSING_BALANCE: '決算残高',
			LOC: 'LOC',
			AUTO_BILLING: '自動請求',
		},
	},
	licenses: {
		searchKey: {
			LICENSE_KEY: 'ライセンス キー',
			ORDER_NUMBER: '注文番号',
		},
		status: {
			ACTIVE: {
				name: '有効',
				description: 'このライセンスは使用中です',
			},
			INACTIVE: {
				name: '無効',
				description: 'このアクティベーションコードは使用されていません',
			},
			CANCELLED: {
				name: 'キャンセル済み',
				description: 'このライセンスはキャンセル済みまたは返金済みです',
			},
			REPLACED: {
				name: '置き換え済み',
				description: '有効なエンタイトルメントが処理中に別の有効なエンタイトルメントに置き換えられました',
			},
			EXPIRED: {
				name: '期限切れ',
				description: '有効なエンタイトルメントの有効期限が過ぎました',
			},
			USED: {
				name: '使用済み',
				description: 'このライセンスは使用済みです',
			},
			MIGRATED: {
				name: '移行済み',
				description: 'このライセンスは後継製品のライセンスに移行されました',
			},
			BLACKLISTED: {
				name: '拒否リストに登録済み',
				description: '',
			},
		},
		datepickerDateType: {
			CREATED_AT: '作成日',
			EXPIRATION: '有効期限',
			REGISTERED_AT: '登録日',
		},
	},
	closingBalance: {
		searchKey: {
			ORDER_NUMBER: '注文番号',
		},
	},
	creditStatus: {
		transactionSource: {
			CANCELLED_LICENSE: 'キャンセルしたライセンス',
			CANCELLED_ORDER: 'キャンセルした注文',
			MSP: 'MSP',
			NEW_ORDER: '新しい注文',
			OMS_REFUND: '返金',
			PAID_ORDER: '支払い済みの注文',
			SAP: 'SAP',
			SAP_TAX: '税金',
			OMS_TAX: '税金',
			ORACLE_TAX: '税金',
			ADMIN_CUSTOM: 'カスタム調整',
			PAY_AS_YOU_GO: 'フレキシブル請求',
			TAX_REFUND: '税金の返金',
			PAY_AS_YOU_GO_CONSOLIDATION: 'フレキシブル請求 - 修正',
			SETTLED_ORDER: '決済済み',
		},
	},
	purchase: {
		marketSegment: {
			BUSINESS: '企業',
			CONSUMER: '消費者',
		},
		billablePartyType: {
			PARTNER: 'パートナー',
			CUSTOMER: '顧客',
		},
		type: {
			PREPAID: 'プリペイド',
			FLEXIBLE_BILLING: 'フレキシブル請求',
		},
	},
	promotion: {
		scope: {
			PARTY_ONLY: '当事者のみ',
			PRODUCT_ONLY: '製品のみ',
			PARTY_AND_PRODUCT: '当事者と製品',
		},
		type: {
			DISCRETIONARY: '裁量',
			SALES_CAMPAIGN: '販売キャンペーン',
		},
		partyType: {
			DISTRIBUTION: '卸売業者',
			RESELLER: '再販業者',
			END_CUSTOMER: '最終顧客',
		},
		partyTier: {
			DISTRIBUTORS: '卸売業者',
			NONE: 'なし',
			TIER_4: '層 4',
			TIER_3: '層 3',
			TIER_2: '層 2',
			TIER_1: '層 1',
		},
		state: {
			ENABLED: '有効',
			DISABLED: '無効',
		},
	},
	retail: {
		status: {
			COMPLETE: '完了',
			IN_PROGRESS: '進行中',
		},
	},
	refunds: {
		status: {
			OPEN: '開く',
			FULLY_APPLIED: '完全に適用済み',
			VOIDED: '無効',
			PENDING: '保留中',
		},
		type: {
			ORDER: '注文の返金',
			LINE: '品目の返金',
			KEY: 'キーの返金',
			TAX: '税金の返金',
		},
		reason: {
			CUSTOMER_SATISFACTION_ISSUE: '顧客満足度の問題',
			INCORRECT_PRICE_INVOICED: '請求された価格が正しくありません',
			NOT_DELIVERED: '未配送',
			WRONG_PRODUCT: '製品が間違っている',
			PRODUCT_NOT_WORKING: '製品が動作しない',
			TAX_EXEMPT: '非課税',
			OTHER_REASON: 'その他の理由',
		},
	},
	comparativeFeature: {
		ONLINE_MANAGEMENT: {
			name: 'オンライン管理プラットフォーム',
			description:
				'時間と場所を問わずデバイス保護の設定、サブスクリプションの管理、監視、デバイスの追加を 1 か所で簡単に行うことができます。',
		},
		DEVICE_PROTECTION: {
			name: 'デバイス保護',
			description:
				'受賞歴のあるアンチウイルス製品より、すべてのデバイスがマルウェア、フィッシング、ランサムウェア、その他の高度なサイバー攻撃から保護されます。',
		},
		DATA_PROTECTION: {
			name: 'データ保護',
			description: '個人、ビジネス、顧客のデータの盗難を防ぎます。',
		},
		IT_SUPPORT: {
			name: 'IT サポート',
			description: '熟練の技術エンジニアが週 5 日の 24 時間サポートを電子メール、チャット、電話で提供します。',
		},
		IDENTITY_PROTECTION: {
			name: '個人情報保護',
			description:
				'従業員のオンライン上の個人情報を保護するパスワード プロテクションとウェブカメラ プロテクションが含まれます。',
		},
		UNLIMITED_VPN: {
			name: '無制限の VPN',
			description:
				'フリー Wi-Fi ネットワーク使用時に組み込みの VPN によってデータが暗号化され、従業員のインターネット接続のプライバシーが保護されます。',
		},
		USB_PROTECTION: {
			name: 'USB プロテクション',
			description:
				'従業員が許可されていないリムーバブル ストレージ デバイス (フラッシュドライブ、外付けドライブ、メモリ カードなど) を使用できないようにします。USB ポートを遮断、制御、監視してデータ盗難、データ損失、マルウェア感染を防ぎます。',
		},
		PATCH_MANAGEMENT: {
			name: 'パッチ管理',
			description:
				'サイバー攻撃の影響を受けやすいソフトウェアやサードパーティ アプリケーションの脆弱性を自動的に解決します。',
		},
	},
	productGroupTag: {
		NEW: '新機能',
	},
	widgets: {
		label: {
			QUOTES: '最近の見積もり',
			ORDERS: '最近の注文',
			CREDIT_STATUS: 'クレジット ステータス',
			EXPIRING_LICENSES_NEXT_N_DAYS: '今後 N 日以内に期限が切れるライセンス',
			EXPIRED_LICENSES_LAST_N_DAYS: '過去 N 日以内に期限が切れたライセンス',
			CLOSING_BALANCE: '最近の決算残高明細書',
		},
		caption: {
			QUOTES: '最近の見積もり',
			ORDERS: '最近の注文',
			CREDIT_STATUS: 'クレジット ステータス',
			EXPIRING_LICENSES_NEXT_N_DAYS: '今後 {{count}} 日以内に期限が切れるライセンス',
			EXPIRED_LICENSES_LAST_N_DAYS: '過去 {{count}} 日以内に期限が切れたライセンス',
			CLOSING_BALANCE: '最近の決算残高明細書',
		},
	},
};
