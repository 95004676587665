import React from 'react';
import { useFormikContext } from 'formik';
import { IGenerateRetailCodesForm } from 'module/retail';
import { Button, Modal } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { retailConfig } from 'module/retail/retailConfig';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { SelectProducts } from 'module/retail/components/generate/SelectProducts';
import { GenerateRetailCodesTable } from './GenerateRetailCodesTable';
import { STATUS_SUCCESS } from 'appConstants';
import { StepperVerticalHeadline } from 'submodule/stepperVertical';

export const GenerateRetailCodesCart = () => {
	const { values } = useFormikContext<IGenerateRetailCodesForm>();
	const selectProductsRef = useAsyncModalRef();
	const [t] = useTranslation(retailConfig.trNamespace);
	const isActive = Boolean(values.partner);

	return (
		<>
			<StepperVerticalHeadline
				step={2}
				title={t('generate.cart.headline')}
				className="mb-3"
				active={isActive}
				endComponent={
					<Button
						size="sm"
						disabled={!isActive}
						onClick={() => selectProductsRef.current?.show()}
					>
						{t('generate.cart.add')}
					</Button>
				}
			/>
			<GenerateRetailCodesTable />

			<AsyncModal ref={selectProductsRef}>
				<Modal.Header>{t('generate.products.headline')}</Modal.Header>
				<Modal.Body>
					<SelectProducts
						onAddItem={(close) => {
							if (close) {
								selectProductsRef.current?.onSuccess(STATUS_SUCCESS);
							}
						}}
					/>
				</Modal.Body>
			</AsyncModal>
		</>
	);
};
