import { TUseApiPaginatedListModule, useApiPaginatedListQuery } from 'js/queries/useApiPaginatedListQuery';
import {
	IAsyncRetailRequestLicensesExport,
	IEntityRetailRequest,
	IGenerateRetailCodesRequest,
	IGenerateRetailCodesResponse,
	IRetailRequestListApiFilter,
	IRetailRequestListFilter,
} from 'module/retail';
import { TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import { retailRequestFilterNormalizer } from 'module/retail/normalizer';
import { ApiMutationTypeEnum } from 'js/enums';
import { IAsyncJob } from 'js/asyncJobs';

export const API_RETAIL_KEYS = {
	LIST: 'retail-code-request',
	GENERATE: 'retail-code-request/generate',
	EXPORT: 'export/retail-codes',
};

export const useApiRetailList: TUseApiPaginatedListModule<
	IEntityRetailRequest,
	IRetailRequestListFilter,
	IRetailRequestListApiFilter
> = (props) =>
	useApiPaginatedListQuery(API_RETAIL_KEYS.LIST, props, {
		apiFilterNormalizer: (filter) => retailRequestFilterNormalizer.denormalize(filter),
	});

export const useApiRetailGenerate: TUseApiMutationModule<IGenerateRetailCodesRequest, IGenerateRetailCodesResponse> = (
	props,
) => useApiMutationQuery(API_RETAIL_KEYS.GENERATE, ApiMutationTypeEnum.POST, props);

export const useApiRetailRequestLicensesExport: TUseApiMutationModule<IAsyncRetailRequestLicensesExport, IAsyncJob> = (
	props,
) => useApiMutationQuery(API_RETAIL_KEYS.EXPORT, ApiMutationTypeEnum.POST, props);
