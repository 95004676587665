import React from 'react';
import { TChargeOrderId } from 'types';
import { PriceSummary } from 'js/components/molecules/PriceSummary';
import { useTranslation } from 'react-i18next';
import { Alert } from '@avast/react-ui-components';
import { logError } from 'js/utils/app';
import { useApiRefundableAmount } from 'submodule/refunds/hooks/useApiRefunds';

type TRefundedAmountProps = {
	chargeOrderId?: TChargeOrderId;
	currencyCode: string;
};

export const RefundedAmount = (props: TRefundedAmountProps) => {
	const { chargeOrderId, currencyCode } = props;
	const [t] = useTranslation('submodules');
	const { data: refundableAmount, query } = useApiRefundableAmount({
		id: chargeOrderId,
		queryConfig: {
			onError(error) {
				logError(`Unable to load refundable amount for charge order #${chargeOrderId}`, error);
			},
		},
	});

	if (query.isError) {
		return (
			<Alert
				variant="warning"
				caption={t('refunds.error.refundedAmount')}
			/>
		);
	}

	return (
		<PriceSummary
			headers={[t('refunds.entity.totalRefunded')]}
			currencyCode={currencyCode}
			rows={[
				{
					values: [refundableAmount?.totalAmountRefundedWithTax],
				},
			]}
			isLoading={query.isFetching}
		/>
	);
};
