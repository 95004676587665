import { TChargeOrderId } from 'types';
import { useApiChargeOrderDocumentsList } from 'js/chargeOrder/useApiChargeOrder';
import { useEffect, useState } from 'react';
import { TApiListResponse } from 'types/api';
import { IEntityOrderDocument } from 'js/chargeOrder';
import { fetchVatInvoices, logoutGcStore } from 'js/utils/jsonp';
import { logDebug } from 'js/utils/app';
import { IVatInvoicesResponse } from 'js/components/chargeOrderDocuments';
import { responseToDocuments } from 'js/components/chargeOrderDocuments/utils';
import { OrderDocumentTypeEnum } from 'js/enums';

export const useChargeOrderDocuments = (chargeOrderId: TChargeOrderId) => {
	const [isFetching, setFetching] = useState<boolean>(true);
	const { data, query: documentsQuery } = useApiChargeOrderDocumentsList({
		filter: { chargeOrderId },
	});
	const [documents, setDocuments] = useState<TApiListResponse<IEntityOrderDocument>>([]);

	useEffect(() => {
		let run = true;
		const process = async () => {
			setFetching(true);
			const documents: TApiListResponse<IEntityOrderDocument> = [];
			const promises: Promise<IVatInvoicesResponse | null>[] = [];

			data?.forEach((document) => {
				if (document.documentType === OrderDocumentTypeEnum.GC_SAP_DOCUMENT) {
					logDebug('Fetch VAT invoices', document.url);
					promises.push(fetchVatInvoices(document.url));
					return;
				}

				if (document.documentType === OrderDocumentTypeEnum.GC_DISPLAY_INVOICE_PAGE) {
					logDebug('Logout for Display Invoice', document.url);
					logoutGcStore('VANITY');
					document.documentType = OrderDocumentTypeEnum.INVOICE;
				}

				documents.push(document);
			});

			await Promise.all(promises).then((responses) => {
				responses.forEach((response) => {
					if (response) {
						documents.push(...responseToDocuments(response));
					}
				});
			});

			if (run) {
				setDocuments(documents);
				setFetching(false);
			}
		};

		process();
		return () => {
			run = false;
		};
	}, [data]);

	return {
		isFetching: documentsQuery.isFetching || isFetching,
		documents,
	};
};
