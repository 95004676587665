import { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/ko-KR');
const locale: TLocale = 'ko-KR';

export const ko_KR: ILocaleConfig = {
	name: 'Korean (South Korea)',
	locale,
	language: 'ko',
	default: true,
	format: {
		numbro,
		datetime: locale,
	},
};
