import BootstrapModalManager from 'react-bootstrap/BootstrapModalManager';
import type { ModalInstance } from '@restart/ui/ModalManager';

export class CustomModalManager extends BootstrapModalManager {
	resetModalsAttributes() {
		this.modals.forEach((modal) => {
			if (this.isTopModal(modal)) {
				modal.dialog?.removeAttribute('aria-hidden');
				modal.backdrop?.removeAttribute('aria-hidden');
			} else {
				modal.dialog?.setAttribute('aria-hidden', 'true');
				modal.backdrop?.setAttribute('aria-hidden', 'true');
			}
		});
	}

	setModalAttributes(modal: ModalInstance) {
		super.setModalAttributes(modal);
		this.resetModalsAttributes();
	}

	removeModalAttributes(modal: ModalInstance) {
		super.removeModalAttributes(modal);
		this.resetModalsAttributes();
	}
}
