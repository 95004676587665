export default {
	page: {
		default: {
			title: 'Mein Konto',
		},
		taxExemption: {
			title: 'Bescheinigung über die Steuerbefreiung',
			shortTitle: 'Steuerbefreiung',
			action: 'Neue Steuerbefreiung einreichen',
		},
		flexibleBilling: {
			title: 'Flexible Abrechnungen',
		},
		autoBillingAddCard: {
			title: 'Neue Karte hinzufügen',
		},
	},
	error: {
		autoBillingSource:
			'Ihre Kreditkartendaten konnten nicht geladen werden. Bitte kontaktieren Sie Ihren Account Manager.',
	},
	cards: {
		accountInformation: 'Kontoinformationen',
		contactInformation: 'Kontaktinformationen',
		addressInformation: 'Adressangaben',
		terms: 'Allgemeine Geschäftsbedingungen',
		legacyPaymentOptions: 'Gespeicherte Karten',
		personalization: 'Personalisierung',
	},
	common: {
		locale: {
			language: 'Systemsprache',
			format: 'Datums-/Nummernformatierung',
		},
	},
	form: {
		creditCard: {
			owner: 'Angaben zum Karteninhaber',
			creditCard: 'Kreditkarte',
			number: 'Kartennummer',
			expiration: 'Ablaufdatum (MM/JJ)',
			cvv: 'CVV',
			submit: 'Kreditkarte speichern',
			isPreferredPayAsYouGoPaymentMethod: 'Als bevorzugte Zahlungsmethode für flexible Abrechnung festlegen',
		},
		taxExemption: {
			title: 'Neue Steuerbescheinigung einreichen',
			taxAuthorityPlaceholder: 'Steuerbehörde wählen',
			file: 'Steuerbefreiungsakte',
			declaration:
				'Ich erkläre unter Strafe des Meineids nach bestem Wissen und Gewissen, dass die obigen Angaben und meine Unterlagen vollständig und richtig sind.',
			success: 'Das Formular für die Steuerbefreiung wurde eingereicht.',
		},
	},
	taxExemption: {
		taxAuthority: 'Steuerbehörde',
		startDate: 'Startdatum der Steuerbefreiung',
		endDate: 'Enddatum der Steuerbefreiung',
		fileName: 'Dateiname',
		status: {
			ELIGIBLE: 'Berechtigt',
			NOT_ELIGIBLE: 'Nicht berechtigt',
		},
	},
	flexibleBilling: {
		paymentMethod: {
			title: 'Zahlungsmethode',
			subtitle: 'Wählen Sie Ihre bevorzugte Zahlungsmethode',
			submitButton: 'Zahlungsmethode speichern',
			success: 'Bevorzugte Zahlungsmethode wurde gespeichert',
			addCardFirst:
				'Bitte fügen Sie zunächst eine Kreditkarte hinzu, um die Automatische Rechnungsstellung zu aktivieren.',
		},
	},
	creditCard: {
		entity: {
			number: 'Kartennummer',
			expiration: 'Ablaufdatum',
		},
		currentSource: {
			title: 'Automatische Rechnungsstellung',
			subtitle: 'Ihre gespeicherte Karte',
			loading: 'Kartendaten werden abgerufen',
			notFound: 'Sie haben keine gespeicherten Karten.',
			create: 'Neue Karte hinzufügen',
		},
		message: {
			successCreate: 'Ihre Karte wurde erfolgreich gespeichert.',
			successDelete: 'Ihre Karte wurde erfolgreich gelöscht.',
			saving: 'Ihre Karte wird gespeichert',
		},
		delete: {
			title: 'Karte aus Ihrem Konto entfernen',
			confirmation: 'Möchten Sie die Karte (•••• {{lastFourDigits}}) wirklich entfernen?',
			loading: 'Karte wird entfernt',
		},
		error: {
			bad_request: 'Ungültige Kreditkarte. Bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Account Manager.',
		},
	},
	legacyPaymentOptions: {
		unableToLoad: 'Gespeicherte Karten können nicht geladen werden.',
		noStoredCards: 'Sie haben keine gespeicherten Karten.',
	},
};
