import { FixedWidthWrapper } from 'js/components/molecules/Inputs/FixedWidthWrapper';
import { FormikControl } from 'js/components/formik/FormikControl';
import React from 'react';
import { useInitiateRefundContext } from 'submodule/refunds/initiate/InitiateRefundContext';

type TProductsTableFormikNumberProps = {
	name: string;
	max: number;
};

export const ProductsTableFormikNumber = (props: TProductsTableFormikNumberProps) => {
	const { currency } = useInitiateRefundContext();
	const { name, max } = props;

	return (
		<FixedWidthWrapper width={160}>
			<FormikControl.Number
				name={name}
				size="sm"
				max={max}
				min={0}
				hideControls
				numberFormat={{
					output: 'currency',
					currencySymbol: currency,
					currencyPosition: 'postfix',
					spaceSeparatedCurrency: true,
				}}
			/>
		</FixedWidthWrapper>
	);
};
