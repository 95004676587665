import { isString } from 'lodash';
import { NavigateLink, NavigateObject } from 'types';

export const navigateLinkNormalize = (link: string | NavigateLink): NavigateLink => {
	if (isString(link)) {
		return { to: link };
	}

	return link;
};

export const navigateLinkToNavigateObject = (link: string | NavigateLink): NavigateObject => {
	const { to, state } = navigateLinkNormalize(link);
	return { to, options: { state } };
};
