import { IBaseCustomer, IEntityCustomer } from 'module/customers';
import { joinValues } from 'js/utils/common';
import { CustomerTypeEnum } from 'module/customers/enums';

export const getCustomerFullName = (customer?: IBaseCustomer | null): string => {
	if (!customer) {
		return '';
	}

	return joinValues([customer.firstName, customer.lastName], ' ');
};

export const getCustomerRenderName = (customer?: IEntityCustomer | null, defaultValue?: string): string => {
	if (!customer) {
		return defaultValue ?? '';
	}

	return customer.companyName ?? getCustomerFullName(customer);
};

export const isCustomerBusiness = (customer?: IEntityCustomer | null, defaultValue: boolean = false): boolean => {
	return customer ? customer.contactType === CustomerTypeEnum.BUSINESS : defaultValue;
};

export const isCustomerConsumer = (customer?: IEntityCustomer | null, defaultValue: boolean = false): boolean => {
	return customer ? customer.contactType === CustomerTypeEnum.CONSUMER : defaultValue;
};

export const hasCustomerAnyOtherData = (customer?: IEntityCustomer | null): boolean => {
	return Boolean(customer?.industry);
};
