import { TApiErrorResolve } from 'types/api';
import { apiCriticalErrorResolve } from 'js/utils/apiError';
import { API_ERR_CODE } from 'constant';
import React from 'react';
import i18n from 'i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { UsageLimitApiError } from 'module/flexibleBilling/components';

export const submitFlexibleBillingSettingApiError: TApiErrorResolve = (err) => {
	if (err.errorCode === API_ERR_CODE.USAGE_LIMIT_IS_LOWER_THEN_TOTAL_USAGE_LIMIT) {
		return {
			title: i18n.t(flexibleBillingConfig.trPrefix('error.usageLimit.title')),
			content: <UsageLimitApiError />,
		};
	}

	return apiCriticalErrorResolve(err);
};
