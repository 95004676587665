export default {
	common: {
		continueToProducts: '製品に進む',
		orderValue: '注文金額',
		remaining: '残り',
		partyPrefix: {
			your: 'お客様の',
			partner: 'パートナー',
			srp: 'SRP',
			customer: '顧客',
			quotedCustomer: '見積もりが作成された顧客',
		},
		savingOrder: '注文を保存中...',
		exportingOrder: '注文をエクスポート中...',
		loadingOrder: '注文を読み込み中...',
		customerCreditStatus: {
			eligible: '顧客はクレジットを利用できます。',
			notEligible: '顧客はクレジットを利用できません。',
		},
		previousLicense: '以前のライセンス キー',
		currency: {
			changeTooltip: '通貨を変更',
			selectPrompt: '顧客の通貨を選択',
		},
		paginationText: '{{total}} 個の品目のうち <strong>{{from}} ～ {{to}}</strong> 番目を表示中。',
	},
	discretionaryDiscount: {
		title: '裁量割引',
		placeholder: '割引を入力',
		approvalFlag: '承認のステータス',
		amount: '金額',
		amountUnit: '単位金額',
		amountTotal: '合計金額',
		status: {
			notSet: '注文に裁量割引が設定されていません。',
			lineNotSet: '明細行に裁量割引が設定されていません。',
			orderLevelDiscountIsSet: '注文レベルの割引がこの注文に適用されました。明細行レベルの割引が無効になっています。',
			lineLevelDiscountIsSet: '明細行レベルの割引がこの注文に適用されました。注文レベルの割引が無効になっています。',
		},
		action: {
			resetLineDiscounts: '明細行の割引を削除',
		},
		waitingApprovalMessage: 'この注文には、販売オペレーションからの承認を必要とする裁量割引が入力されています。',
	},
	error: {
		submitOrder: {
			title: '注文を送信できません',
			general: '何らかの問題が発生したため、注文の送信が失敗しました。再送信してください。',
		},
		pricing: {
			title: '価格を計算できません',
		},
		context: {
			setPartnerFailed: '注文へのパートナーの追加に失敗しました。',
		},
		creditExceed: {
			title: 'このトランザクションは、アカウントで利用可能なクレジットの金額を超えます。',
			text: 'このトランザクションではクレジット支払い方法を利用できません。［買い物かごのチェックアウト］オプションを使用してください。',
		},
		requiredCustomer: {
			title: '顧客情報は必須です',
			text: '購入を完了するには、顧客を選択してください。',
			action: '顧客を選択',
			tooltip: {
				question: '注文を実行するために顧客情報が必要なのはなぜですか？',
				answer:
					'最終顧客に関するより多くの分析情報を収集するために、注文の実行時には顧客情報を追加するよう求められます。これらの情報をより詳細に把握し、顧客の傾向、ニーズ、好みを理解することで、より優れたサービスを提供できます。',
			},
		},
		requiredDistributionPartner: {
			title: '販売パートナー情報は必須です',
			text: '購入を完了するには、販売パートナーを選択してください。',
			action: '販売パートナーを選択',
			tooltip: {
				question: '注文を実行するために販売パートナー情報が必要なのはなぜですか？',
				answer:
					'注文の実行時には両方のレベルの顧客情報 (販売パートナーおよびその顧客) を追加するよう求められます。これらの情報をより詳細に把握し、顧客の傾向、ニーズ、好みを理解することで、より優れたサービスを提供できます。',
			},
		},
	},
	components: {
		successOrder: {
			title: '成功しました。',
			action: {
				NEW_ORDER: '新しい注文',
				DETAIL: '注文の詳細',
				QUOTE_DETAIL: '見積もりの詳細',
				LICENSES: 'ライセンスを表示',
				EDIT: '注文を編集',
				QUOTE_EDIT: '見積もりを編集',
				PAYMENT_INSTRUCTIONS: '支払い指示',
			},
		},
		editPrices: {
			title: '単価を編集',
			message:
				'編集できるのは、現在かごに追加されている品目の単価のみです。この画面で保存された注文を変更することはできません。続行する前に、かごの内容を確認してください。',
			button: '価格設定を編集',
			columnLabel: 'カスタム単価',
			submit: '注文を保存',
			confirmation: {
				message: 'これにより、完了可能な注文が作成されます。注文の編集はできなくなります。よろしいですか？',
				success: 'はい、注文を保存します',
			},
		},
		customerQuote: {
			error: {
				invalidItems: 'この注文を保存して電子メールをプレビュー/送信するには、カスタム単価が必要です。',
			},
			fields: {
				customerEmail: '宛先',
				partnerEmail: 'CC',
				message: '個人用のメッセージ',
			},
			submit: '電子メールを送信',
		},
	},
	page: {
		default: {
			title: '注文の詳細',
		},
		products: {
			title: '製品',
		},
		order: {
			title: '確認して確定',
		},
	},
	unit: {
		current: '現在の {{type}}',
		toIncrease: '追加の {{type}}',
		toDecrease: '{{type}} を削除',
		total: '合計 {{type}}',
	},
	entity: {
		privateNotes: '個人用のメモ',
		publicNotes: '公開用のメモ',
		opportunityId: '案件番号',
		marketSegment: '区分',
		validityLabel: '{{value}} の場合',
		validityDescription: '条件の長さ {{value}}',
	},
	pricing: {
		basePrice: '{{price}} から',
		discount: {
			segment: '区分割引',
			renewal: '更新割引',
			partner: 'パートナー割引',
			discretionary: '裁量割引',
			promo: 'プロモーション割引',
		},
		details: {
			show: '価格の詳細を表示',
			hide: '価格の詳細を非表示',
		},
	},
	actions: {
		addNote: 'メモを追加',
		addToCart: 'かごに追加',
		goToCart: 'かごに移動',
		exportCart: 'かごをエクスポート',
	},
	message: {
		addToCart: {
			item: '- <strong>{{name}}</strong> - {{unit}} {{unitLabel}}、{{quantity}}x',
			title: '製品をかごに追加しました。',
		},
		removedFromCart: '製品をかごから削除しました。',
		emptyCart: 'すべての製品をかごから削除しました。',
		emptyCartConfirmation: 'すべての製品をかごから削除しますか？',
	},
	table: {
		action: {
			selectVariant: 'バリエーションを選択',
		},
		filter: {
			search: {
				placeholder: '名前または SKU で製品を検索',
			},
			marketSegment: '{{type}} 製品',
		},
	},
	tableVariant: {
		priceNote: '価格にはすべての顧客の割引が含まれます',
		tooltip: {
			quantity: '数量値は、選択した製品について注文した個人用ライセンスの数と等しくなっています。',
			unitType: {
				SEATS: '単一ライセンスのシート数。',
				SESSIONS: '単一ライセンスのセッション数。',
				STORAGE: '',
				DEVICE: '',
			},
		},
	},
	widget: {
		common: {
			billableParty: '請求可能な当事者',
			nonBillableParty: '請求不可の当事者',
		},
		selectCustomerCard: {
			createNew: '新しい顧客を作成',
			note: 'この注文には顧客が指定されていません。<br/><strong>後でライセンスを登録してください。</strong>',
			select: '顧客を選択',
			title: '顧客',
			error: {
				load: '顧客を読み込むことができませんでした',
			},
			tooltips: {
				change: '顧客を変更',
				update: '顧客を更新',
				cancel: '顧客を注文から削除',
			},
		},
		selectDistributionPartnerCard: {
			createNew: '新しい販売パートナーを作成',
			note: 'この注文には販売パートナーが指定されていません。',
			select: '販売パートナーを選択',
			title: '販売パートナー',
			error: {
				load: '販売パートナーを読み込むことができませんでした',
			},
			tooltips: {
				change: '販売パートナーを変更',
				update: '販売パートナーを更新',
				cancel: '販売パートナーを注文から削除',
			},
		},
		selectPartnerCard: {
			note: '割引後の最終価格を表示するパートナーを選択します。',
			select: 'パートナーを選択',
			title: 'アバスト パートナー',
			error: {
				load: 'パートナーを読み込むことができませんでした',
			},
			tooltips: {
				change: 'アバスト パートナーを変更',
				cancel: 'アバスト パートナーを注文から削除',
			},
		},
	},
	paymentType: {
		SAVE: {
			action: '保存',
			caption: '後で保存',
			button: '後で保存',
			successMessage: '注文が保存されました。',
			confirmationCaption: 'この注文を保存しますか？',
		},
		QUOTE: {
			action: '送信',
			caption: '見積もりとして送信',
			button: '見積もりとして送信',
			successMessage: '見積もりが配信用にキューに登録されました。',
			confirmationCaption: 'この見積もりを送信しますか？',
		},
		END_CUSTOMER_QUOTE: {
			action: '送信',
			caption: '見積もりとして送信',
			button: '見積もりとして送信',
			successMessage: '見積もりが配信用にキューに登録されました。',
			confirmationCaption: 'この注文を実行する準備ができましたか？',
		},
		CUSTOMER_QUOTE: {
			action: '送信',
			caption: '顧客の価格見積もりを送信',
			button: '顧客の価格見積もりを送信',
			successMessage: '見積もりが配信用にキューに登録されました。',
			confirmationCaption: '顧客の価格見積もりメールを送信しますか？',
			confirmationMessage:
				'すべての顧客単価が注文に保存され、顧客に電子メールが送信されます。<br/>この処理を元に戻したり取り消したりすることはできません。',
		},
		GLOBAL_COMMERCE_CART_CHECKOUT: {
			action: '送信',
			caption: 'カード支払い',
			button: 'カード支払い',
			successMessage: '注文が正常に実行されました。',
			confirmationCaption: 'この注文を実行する準備ができましたか？',
		},
		GLOBAL_COMMERCE_CLOSING_BALANCE: {
			action: '送信',
			caption: '決算残高',
			button: '決算残高で送信',
			successMessage: '注文が正常に実行されました。',
			confirmationCaption: 'この注文を実行する準備ができましたか？',
		},
		GLOBAL_COMMERCE_LINE_OF_CREDIT: {
			action: '送信',
			caption: 'クレジットで送信',
			button: 'クレジットで送信',
			successMessage: '注文が正常に実行されました。',
			confirmationCaption: 'この注文を実行する準備ができましたか？',
		},
		ORACLE_CART_CHECKOUT: {
			action: '送信',
			caption: 'クレジット カードで支払う',
			button: 'クレジット カードで支払う',
			successMessage: '注文の支払いが正常に完了しました。',
			confirmationCaption: 'この注文を実行する準備ができましたか？',
		},
		ORACLE_LINE_OF_CREDIT: {
			action: '送信',
			caption: 'クレジットで送信',
			button: 'クレジットで送信',
			successMessage: '注文が正常に実行されました。',
			confirmationCaption: 'この注文を実行する準備ができましたか？',
		},
		ORACLE_CLOSING_BALANCE: {
			action: '送信',
			caption: '決算残高',
			button: '決算残高で送信',
			successMessage: '注文が正常に実行されました。',
			confirmationCaption: 'この注文を実行する準備ができましたか？',
		},
	},
	guards: {
		partnerPriceListGuard: {
			title: 'パートナー変更の確認',
			message:
				'選択したパートナーには、現在の価格リストと同じ許可された価格リストがありません。続行すると、かごが空になります。',
		},
		partnerTermsGuard: {
			title: 'このパートナーが注文を実行することはできません',
			message: '選択したパートナーは利用規約に同意していません。',
		},
		priceListGuard: {
			title: '製品価格の混在',
			messageCaption:
				'CloudCare と OMS の製品を 1 つの注文に混在させることはできません。この製品をかごに追加しますか？',
			message: '以前に追加した製品が自動的に削除され、この品目がかごに追加されます。',
		},
		maxCartItemsGuard: {
			title: '品目の最大数を超えています',
			message:
				'1 つの注文に含めることができる品目数は最大 {{count}} 個です。続行するには、一部の品目を削除してください。',
			message_other:
				'1 つの注文に含めることができる品目数は最大 {{count}} 個です。続行するには、一部の品目を削除してください。',
		},
	},
	priceList: {
		mixWarning:
			'CloudCare と OMS の製品は価格と割引がそれぞれ異なるため、それらを 1 つの注文に混在させることはできません。',
		code: {
			PREPAID_OMS: 'OMS 製品',
			PREPAID_CC_LEGACY: 'CloudCare 製品',
			PREPAID_CC_MSRP: 'CloudCare 製品',
		},
	},
	tooltips: {
		remove: 'この品目をかごから削除',
		emptyCart: 'すべての品目をかごから削除',
	},
};
