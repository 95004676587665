export default {
	tierQualifyingUnits: '層の適格単位',
	tier: '層',
	srpUnitPrice: 'SRP 単価',
	customerSegment: '顧客の区分',
	segmentDiscount: '区分割引',
	srpAfterSegmentDiscount: '区分割引後の SRP',
	quantityPriced: '数量の価格が設定されました',
	totalPrice: '合計価格',
	discretionaryDiscount: '裁量割引',
	unitPriceAfterDiscretionaryDiscount: '裁量割引後の単価',
	partnerDiscount: 'パートナー割引',
	unitPriceAfterDiscount: '割引後の単価',
	finalDiscountedUnitPrice: '最終割引単価',
	upgradeCredit: 'クレジットをアップグレード',
	daysInTerm: '条件の日数',
	daysToProrate: '比例配分する日数',
	prorationRate: '比例配分率',
	proratedUnitPrice: '比例配分した単価',
	proratedSrpUnitPrice: '比例配分した SRP 単価',
	daysInPeriod: '期間の日数',
	dailySaleUnitPrice: '毎日の販売単価',
	customPricing: 'カスタム価格設定',
	quantityBasedPromotions: '数量ベースのプロモーション',
	linePriceUnitPriceAfterQuantityBasedPromotions: '明細行価格、数量ベースのプロモーション後の単価',
	linePrice: '明細行価格',
	unitPriceAfterQuantityBasedPromotions: '数量ベースのプロモーション後の単価',
	percentageOffPromotions: 'パーセント割引プロモーション',
	unitPriceAfterPercentageOffPromotions: 'パーセント割引プロモーション後の単価',
	validFrom: '発効日',
	validTo: '失効日',
	offQtyOf: 'オフ数量:',
	amountOff: '金額オフ',
	discountPerUnit: '単位ごとの割引',
	discount: '割引',
};
