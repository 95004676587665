import { useEventListener } from 'js/hooks/useEventListener';
import { useState } from 'react';

export const useDropzone = (target: HTMLElement | null, onDrop: (files?: FileList) => boolean) => {
	const [active, setActive] = useState<boolean>(false);

	useEventListener('dragenter', () => setActive(true), target);
	useEventListener('dragleave', () => setActive(false), target);
	useEventListener('dragover', (e) => e.preventDefault(), target);
	useEventListener<DragEvent>(
		'drop',
		(e) => {
			setActive(false);
			onDrop(e.dataTransfer?.files);
			e.preventDefault();
		},
		target,
	);

	return { active };
};
