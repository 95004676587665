export default {
	accountInformation: 'アカウント情報',
	_: {
		dateRangePlaceholder: '日付を選択',
		NA: 'N/A',
		no: 'いいえ',
		pageDetailTitle: '詳細 #{{id}}',
		yes: 'はい',
		loading: '読み込み中...',
	},
	boolean: {
		true: 'はい',
		false: 'いいえ',
	},
	entity: {
		active: '有効',
		amount: '金額',
		company: '会社',
		companyId: '会社 ID',
		companyName: '会社名',
		created: '作成済み',
		currency: '通貨',
		customer: '顧客',
		customerTotalPrice: '顧客合計価格',
		detailId: '詳細 ID',
		description: '説明',
		disabled: '無効',
		discountAmount: '割引金額',
		discountAmountUnit: '単位の割引金額',
		discountPercent: '割引率',
		discountType: '割引の種類',
		discountUnitPrice: '割引単価',
		distributionPartner: '販売パートナー',
		enabled: '有効',
		id: 'ID',
		licenseKey: 'ライセンス キー',
		name: '名前',
		orderStatus: '注文ステータス',
		partnerId: 'パートナー ID',
		partner: 'パートナー',
		paymentAmount: 'お支払い金額',
		paymentMethod: 'お支払い方法',
		paymentStatus: 'お支払いステータス',
		poNumber: '発注書番号',
		price: '価格',
		priceWTax: '税込み価格',
		priceWOTax: '税抜き価格',
		product: '製品',
		productName: '製品名',
		quantity: '数量',
		requestedDiscountType: 'リクエストされた割引の種類',
		secondaryOrderId: '注文 ID 2',
		sku: 'SKU',
		srpUnitPrice: 'SRP 単価',
		state: '都道府県',
		status: 'ステータス',
		subTotalWOTax: '税抜き小計',
		tax: '税金',
		total: '合計',
		totalAmount: '合計金額',
		totalAmountWOTax: '税抜き合計金額',
		totalAmountWTax: '税込み合計金額',
		totalPrice: '合計価格',
		totalTax: '合計税額',
		totalWTax: '税込み合計',
		transaction: 'トランザクション',
		unit: '単位',
		unit_other: '単位',
		unitPrice: '単価',
		unitType: {
			SEATS: 'シート',
			SEATS_other: 'シート',
			SESSIONS: 'セッション',
			SESSIONS_other: 'セッション',
			STORAGE: 'ストレージ',
			STORAGE_other: 'ストレージ',
			DEVICE: 'デバイス',
			DEVICE_other: 'デバイス',
		},
		validity: '有効性',
		variantCount: '{{count}} 個のバリエーション',
		variantCount_other: '{{count}} 個のバリエーション',
	},
	contact: {
		firstName: '名',
		lastName: '姓',
		name: '連絡先名',
		email: 'メール アドレス',
		billingEmail: '請求先メール アドレス',
		phone: '電話番号',
		website: 'Web サイト',
		id: '連絡先 ID',
		taxId: '税 ID',
		secondaryTaxId: '税 ID 2',
	},
	address: {
		caption: '住所',
		city: '市区町村',
		country: '国',
		state: '都道府県',
		postalCode: '郵便番号',
		street: '番地',
	},
	dates: {
		billing: '請求日',
		created: '作成日',
		end: '終了日',
		expire: '有効期限',
		payment: 'お支払い日',
		paymentDue: 'お支払い期限',
		start: '開始日',
	},
	datePeriod: {
		DAY: '{{count}} 日',
		DAY_other: '{{count}} 日',
		MONTH: '{{count}} か月',
		MONTH_other: '{{count}} か月',
		YEAR: '{{count}} 年',
		YEAR_other: '{{count}} 年',
	},
	actions: {
		back: '戻る',
		newPurchase: '新しい購入',
		cancel: 'キャンセル',
		close: '閉じる',
		confirm: '確認',
		continue: '続行',
		detail: '詳細を表示',
		download: 'ダウンロード',
		emptyCart: '空のかご',
		logout: 'ログアウト',
		next: '次へ',
		previous: '前へ',
		reset: 'リセット',
		retry: '再試行',
		removeFile: 'ファイルを削除',
		save: '保存',
		select: '選択',
		submitForm: '送信',
		submitChanges: '変更を送信',
		show: '表示',
		showAll: 'すべて表示',
		showMore: '詳細を表示',
		showLess: '簡易表示',
		copied: '正常にコピーされました',
	},
	validator: {
		email: '値は有効なメール アドレスにする必要があります。',
	},
	table: {
		useFilter: 'フィルタを使用して検索を開始する',
	},
	filter: {
		quickFilters: 'クイック フィルタ',
		partnerIsRequired: 'パートナーを選択して検索を開始してください。',
		typeAtLeastNCharacters: 'フィルタには {{count}} 文字以上を入力してください。',
	},
	header: {
		cart: 'かご',
		cartIsEmpty: 'かごが空です',
	},
	productGroupExceptions: {
		premiumRemoteControlQuantityDisabled:
			'プレミアム リモートコントロールをインストールできるデバイス数に制限はないため、これらの品目用のセッションを変更する必要はありません。同時セッション数は、同時に接続する可能性のあるデバイス数に基づいてます。',
		cloudBackupStorageInfo:
			'Storage 属性を変更することで、100GB のストレージ バケットの購入回数を定義します。たとえば、「Storage = 5」は、最終的に購入した 1 ライセンスのストレージが 500GB であることを意味します。',
	},
	downloadFile: {
		loading: 'ファイル {{filename}} をダウンロード中',
		error: 'ファイルをダウンロードできません',
	},
	appVersionGuard: {
		caption: '新しいバージョンの OMS が利用可能です。',
		message: 'ページを再読み込みして最新バージョンを入手してください。',
		action: '再読み込み',
	},
};
