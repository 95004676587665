import React, { useCallback, useRef, useState } from 'react';
import { CONFIG } from 'config';
import { Button, FormMessage, useInputRef } from '@avast/react-ui-components';
import { Trans, useTranslation } from 'react-i18next';
import { fileUploadValidate } from 'js/components/fileUpload/fileUploadValidate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { useDropzone } from 'js/components/fileUpload/useDropzone';
import classNames from 'classnames';
import { useBootstrapFormContext } from 'js/contexts';

export type TFileUploadBaseProps = {
	maxSize?: number;
	acceptExtensions?: string[];
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'>;

type TFileUploadProps = TFileUploadBaseProps & {
	value: File | null;
	onChange: (file: File | null) => void;
	onBlur?: () => void;
	validationOff?: boolean;
};

export const FileUpload = (props: TFileUploadProps) => {
	const {
		maxSize = CONFIG.APP.MAX_FILE_SIZE,
		acceptExtensions,
		onChange,
		onBlur,
		value,
		validationOff,
		...rest
	} = props;
	const [t] = useTranslation('form');
	const refLabel = useRef<HTMLLabelElement>(null);
	const ref = useInputRef();
	const [error, setError] = useState<string | null | undefined>(null);
	const formContext = useBootstrapFormContext();

	// Prepare info text
	const infoTexts: string[] = [];
	infoTexts.push(t('maxFileSize', { maxSize }));
	if (acceptExtensions) {
		infoTexts.push(
			t('acceptFileType', {
				count: acceptExtensions.length,
				types: acceptExtensions.join(', '),
			}),
		);
	}

	// Clear input value
	const clearValue = useCallback(
		(error?: string | null) => {
			setError(error);
			onChange(null);
			ref.current!.value = '';
			ref.current!.files = null;
		},
		[onChange, ref],
	);

	// On change files
	const onFiles = useCallback(
		(files?: FileList | null, isDropAction?: boolean): boolean => {
			setError(null);
			const file = files?.[0];
			if (!files || !file) {
				return true;
			}

			// Validate
			const response = fileUploadValidate(file, { maxSize, acceptMimeType: rest.accept });
			if (response.valid || Boolean(validationOff)) {
				onChange(file);
				if (isDropAction) {
					ref.current!.files = files;
				}
				return true;
			}

			// Reset
			clearValue(response.error);
			return false;
		},
		[clearValue, maxSize, onChange, rest.accept, validationOff, ref],
	);

	const dropzone = useDropzone(refLabel.current, (files) => onFiles(files, true));

	return (
		<>
			<label
				className={classNames('fileDropzone', { 'is-active': dropzone.active })}
				ref={refLabel}
			>
				<FontAwesomeIcon
					icon={faFileAlt}
					className="fileDropzone-icon"
				/>
				<input
					id={formContext.controlId}
					ref={ref}
					className="fileDropzone-input"
					{...rest}
					type="file"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						if (!onFiles(event.currentTarget.files)) {
							event.stopPropagation();
						}
					}}
					onBlur={onBlur}
				/>
				{value ? (
					<>
						<div className="fileDropzone-value">{value.name}</div>
						<div className="mt-2">
							<Button
								size="xs"
								variant="outline-danger"
								onClick={(e) => {
									clearValue();
									e.stopPropagation();
									e.preventDefault();
								}}
							>
								{t('common:actions.removeFile')}
							</Button>
						</div>
					</>
				) : (
					<>
						<div className="fileDropzone-placeholder">
							<Trans t={t}>{'components:fileUpload.placeholder'}</Trans>
						</div>
						<FormMessage type="info">{infoTexts.join(' ')}</FormMessage>
					</>
				)}
			</label>
			{error && !validationOff && <FormMessage type="danger">{error}</FormMessage>}
		</>
	);
};
