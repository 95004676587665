export default {
	steps: {
		billableParty: {
			title: '請求可能な当事者',
			virtualPartnerTitle: '新しい見込み顧客',
		},
		product: {
			title: '製品',
			isRenewal: '更新ですか？',
		},
		discounts: {
			title: '割引',
			discretionary: '裁量割引',
			special: '特別な割引',
			tier: '層の割引',
			segment: '区分割引',
		},
		price: {
			title: '価格',
			appliedPromotions: '適用されているプロモーション',
		},
	},
	message: {
		countryCodeNotSelected: '国コードを最初に選択してください',
		requiredStepsNotFulfilled: '請求可能な当事者と製品を選択して価格を表示します',
	},
	goalSeekedPrice: {
		title: '目標達成のための単価',
		originalPrice: '元の単価',
		finalPrice: '最終単価',
	},
};
