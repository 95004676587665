import React from 'react';
import { IEntityOrder, IEntityOrderItem } from 'module/orders';
import { PricingMessage } from '../PricingMessage';
import { useOrderPricingBreakdown } from 'module/orders/hooks/useOrderPricingBreakdown';
import { Row } from 'js/components/atoms/Row';
import { PricingBreakdownCols } from 'module/purchase/components/order/row/PricingBreakdownCols';
import { PromotionMessage } from 'module/orders/components/PromotionMessage';

export type TOrderItemPriceDetailProps = {
	order: IEntityOrder;
	orderItem: IEntityOrderItem;
};

export const OrderItemPriceDetail = ({ order, orderItem }: TOrderItemPriceDetailProps) => {
	const pricingBreakdown = useOrderPricingBreakdown(order, orderItem);

	if (pricingBreakdown) {
		return (
			<Row multi>
				<PricingBreakdownCols
					pricingBreakdown={pricingBreakdown}
					colSize={6}
				/>
			</Row>
		);
	}

	if (orderItem?.pricingMessage) {
		return (
			<>
				<PricingMessage
					message={orderItem.pricingMessage}
					className="mt-3"
				/>
				<PromotionMessage
					message={orderItem.pricingPromoMessage}
					className="mt-3"
					caption="common.promoMessage"
					currency={order.currency}
				/>
			</>
		);
	}

	return null;
};
