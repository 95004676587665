import React, { ReactElement, useCallback, useState } from 'react';
import { TSelectEntityProps, TSelectEntityPropsWithChildren } from 'js/components/molecules/Modal';
import { IEntityCustomer } from 'module/customers';
import { customersConfig } from 'module/customers/customersConfig';
import { useTranslation } from 'react-i18next';
import { ModalWrapper } from 'js/components/molecules/Modal/ModalWrapper';
import { useCustomerDetail } from 'module/customers/hooks/useCustomerDetail';
import { TPartnerId } from 'types';
import { SelectCustomerContainer } from 'module/customers/components/SelectCustomerContainer';

type TTableData = IEntityCustomer;
export type TSelectCustomerModalExtraProps = {
	partnerId?: TPartnerId | null;
	customer?: IEntityCustomer | null;
	disallowPartnerCustomers?: boolean;
};
export type TSelectCustomerModalProps = TSelectEntityProps<TTableData> & TSelectCustomerModalExtraProps;

/**
 * Select customer filter field
 * @param {TSelectCustomerModalProps} props
 * @return {ReactElement}
 * @constructor
 */
export const SelectCustomerModal = (
	props: TSelectEntityPropsWithChildren<TTableData, TSelectCustomerModalProps>,
): ReactElement => {
	// constants
	const { onChange, customer = null, partnerId, value, disallowPartnerCustomers } = props;

	// Component hooks
	const [t] = useTranslation(customersConfig.trNamespace);
	const [entity, setEntity] = useState<TTableData | null>(customer);
	const [modalShow, setModalShow] = useState<boolean>(false);

	// Get entity detail
	const {
		query: { isFetching },
	} = useCustomerDetail({
		customerId: value,
		partnerId,
		enabled: value !== entity?.id,
		onData: setEntity,
	});

	// Reset value - clear
	const resetValue = useCallback(() => {
		onChange(null);
	}, [onChange]);

	// Function for set entity - after select from table
	const setValue = useCallback(
		(row: TTableData) => {
			onChange(row);
			setEntity(row);
			setModalShow(false);
		},
		[onChange],
	);

	return (
		<>
			{props.children({ setModalShow, resetValue, value, entity, loading: isFetching })}

			<ModalWrapper
				title={t('select.title')}
				toggle={setModalShow}
				show={modalShow}
				size="lg"
				testId="selectCustomer"
			>
				<SelectCustomerContainer
					selectedCustomerId={value}
					partnerId={partnerId}
					disallowPartnerCustomers={disallowPartnerCustomers}
					onSelect={setValue}
				/>
			</ModalWrapper>
		</>
	);
};
