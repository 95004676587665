import { Button } from '@avast/react-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { object2UrlParams, tableFilterCreator } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { useNavigate } from 'react-router-dom';
import { IOrderListFilter } from 'module/orders';
import { PurchaseTypeEnum } from 'module/orders/enums';

export const ViewOrdersButton = () => {
	const [t] = useTranslation(flexibleBillingConfig.trNamespace);
	const navigate = useNavigate();

	return (
		<Button
			testId="viewOrders"
			size="sm"
			variant="outline-primary"
			onClick={() =>
				navigate({
					pathname: RouteName.ORDER.LIST,
					search: object2UrlParams(
						tableFilterCreator<IOrderListFilter>({
							purchaseType: PurchaseTypeEnum.FLEXIBLE_BILLING,
						}),
					),
				})
			}
		>
			{t('actions.viewOrders')}
		</Button>
	);
};
