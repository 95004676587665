import { IEntityOrder } from 'module/orders';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { ordersConfig } from 'module/orders/ordersConfig';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsEditable } from 'module/orders/hooks';
import { TWidgetColumns } from 'submodule/widgets';
import { getPartnerRenderName } from 'module/partners/utils/partnerSelectors';
import { noValuePlaceholder } from 'js/utils/app';
import { useAuthContext } from 'js/contexts';

type TTableData = IEntityOrder;

export const useQuotesWidgetColumns = (): TWidgetColumns<TTableData> => {
	const [t] = useTranslation(ordersConfig.trNamespace);
	const isEditable = useIsEditable();
	const { isGroupInternal } = useAuthContext();

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();

		const columns = [
			columnHelper.link(
				'id',
				{
					header: t('entity.quoteNumberShort'),
				},
				{
					to: (quote) => ordersConfig.detailLink(quote),
					copy: true,
				},
			),
		];

		if (isGroupInternal) {
			columns.push(
				columnHelper.ellipsis((row) => getPartnerRenderName(row.partner), {
					header: t('common:entity.partner'),
					enableSorting: false,
					id: 'partner',
					meta: {
						defaultValue: { value: noValuePlaceholder(t('common:_.NA')) },
					},
				}),
			);
		}

		columns.push(
			columnHelper.date('createdAt', { header: t('entity.created') }),
			columnHelper.money('totalAmountWithoutTax', { header: t('entity.totalAmount') }, { currencyKey: 'currency' }),
			columnHelper.actions({
				link: {
					children: isGroupInternal ? t('actions.payEdit') : t('actions.pay'),
					to: (quote) => ordersConfig.quoteUpdateLink(quote),
					conditionalProps: (quote) => ({
						hidden: !isEditable(quote),
					}),
					testId: 'pay',
				},
			}),
		);

		return { columns };
	}, [t, isEditable, isGroupInternal]);
};
