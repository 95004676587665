export default {
	accountInformation: 'Kontoinformationen',
	_: {
		dateRangePlaceholder: 'Datum wählen',
		NA: 'Nicht zutreffend',
		no: 'Nein',
		pageDetailTitle: 'Detailnr. {{id}}',
		yes: 'Ja',
		loading: 'Wird geladen...',
	},
	boolean: {
		true: 'Ja',
		false: 'Nein',
	},
	entity: {
		active: 'Aktiv',
		amount: 'Betrag',
		company: 'Unternehmen',
		companyId: 'Firmen-ID',
		companyName: 'Firmenname',
		created: 'Erstellt',
		currency: 'Währung',
		customer: 'Kunde',
		customerTotalPrice: 'Kunde Gesamtpreis',
		detailId: 'Detail-ID',
		description: 'Beschreibung',
		disabled: 'Deaktiviert',
		discountAmount: 'Rabattbetrag',
		discountAmountUnit: 'Rabattbetrag (Stück)',
		discountPercent: 'Rabatt in Prozent',
		discountType: 'Art des Rabatts',
		discountUnitPrice: 'Ermäßigter Einzelpreis',
		distributionPartner: 'Vertriebspartner',
		enabled: 'Aktiviert',
		id: 'ID',
		licenseKey: 'Lizenzschlüssel',
		name: 'Name',
		orderStatus: 'Status der Bestellung',
		partnerId: 'Partner-ID',
		partner: 'Partner',
		paymentAmount: 'Zahlungsbetrag',
		paymentMethod: 'Zahlungsmethode',
		paymentStatus: 'Zahlungsstatus',
		poNumber: 'PO Nummer',
		price: 'Preis',
		priceWTax: 'Preis inkl. Steuern',
		priceWOTax: 'Preis exkl. Steuern',
		product: 'Produkt',
		productName: 'Produktname',
		quantity: 'Anzahl',
		requestedDiscountType: 'Gewünschte Rabattart',
		secondaryOrderId: 'Sekundäre Bestell-ID',
		sku: 'SKU',
		srpUnitPrice: 'SRP-Einzelpreis',
		state: 'Status',
		status: 'Status',
		subTotalWOTax: 'Zwischensumme exkl. Steuern',
		tax: 'Steuern',
		total: 'Gesamt',
		totalAmount: 'Gesamtsumme',
		totalAmountWOTax: 'Gesamtsumme exkl. Steuern',
		totalAmountWTax: 'Gesamtsumme inkl. Steuern',
		totalPrice: 'Gesamtpreis',
		totalTax: 'Steuern gesamt',
		totalWTax: 'Gesamt inkl. Steuern',
		transaction: 'Transaktion',
		unit: 'Gerät',
		unit_other: 'Geräte',
		unitPrice: 'Einzelpreis',
		unitType: {
			SEATS: 'Gerät',
			SEATS_other: 'Geräte',
			SESSIONS: 'Sitzung',
			SESSIONS_other: 'Sitzungen',
			STORAGE: 'Speicher',
			STORAGE_other: 'Speicher',
			DEVICE: 'Gerät',
			DEVICE_other: 'Geräte',
		},
		validity: 'Gültigkeit',
		variantCount: '{{count}} Variante',
		variantCount_other: '{{count}} Varianten',
	},
	contact: {
		firstName: 'Vorname',
		lastName: 'Nachname',
		name: 'Ansprechpartner',
		email: 'E-Mail',
		billingEmail: 'E-Mail-Adresse für Rechnungsstellung',
		phone: 'Telefon',
		website: 'Webseite',
		id: 'Kontakt-ID',
		taxId: 'Steuernr.',
		secondaryTaxId: 'Zweite Steuernr.',
	},
	address: {
		caption: 'Adresse',
		city: 'Stadt',
		country: 'Land',
		state: 'Bundesstaat/Provinz',
		postalCode: 'Postleitzahl',
		street: 'Straße',
	},
	dates: {
		billing: 'Abrechnungsdatum',
		created: 'Erstellt am',
		end: 'Enddatum',
		expire: 'Ablaufdatum',
		payment: 'Zahlungstermin',
		paymentDue: 'Zahlungsfrist',
		start: 'Startdatum',
	},
	datePeriod: {
		DAY: '{{count}} Tag',
		DAY_other: '{{count}} Tage',
		MONTH: '{{count}} Monat',
		MONTH_other: '{{count}} Monate',
		YEAR: '{{count}} Jahr',
		YEAR_other: '{{count}} Jahre',
	},
	actions: {
		back: 'Zurück',
		newPurchase: 'Neuer Kauf',
		cancel: 'Abbrechen',
		close: 'Schließen',
		confirm: 'Bestätigen',
		continue: 'Weiter',
		detail: 'Details ansehen',
		download: 'Herunterladen',
		emptyCart: 'Warenkorb löschen',
		logout: 'Abmelden',
		next: 'Weiter',
		previous: 'Zurück',
		reset: 'Zurücksetzen',
		retry: 'Wiederholen',
		removeFile: 'Datei entfernen',
		save: 'Speichern',
		select: 'Auswählen',
		submitForm: 'Absenden',
		submitChanges: 'Änderungen übermitteln',
		show: 'Anzeigen',
		showAll: 'Alles anzeigen',
		showMore: 'Weitere Informationen anzeigen',
		showLess: 'Weniger anzeigen',
		copied: 'Erfolgreich kopiert',
	},
	validator: {
		email: 'Der Wert muss eine gültige E-Mail-Adresse sein.',
	},
	table: {
		useFilter: 'Zum Suchen Filter verwenden',
	},
	filter: {
		quickFilters: 'Schnellfilter',
		partnerIsRequired: 'Bitte wählen Sie einen Partner, um die Suche zu starten.',
		typeAtLeastNCharacters: 'Geben Sie mindestens {{count}} Zeichen in den Filter ein.',
	},
	header: {
		cart: 'Warenkorb',
		cartIsEmpty: 'Warenkorb ist leer',
	},
	productGroupExceptions: {
		premiumRemoteControlQuantityDisabled:
			'Da Premium Remote Control auf einer unbegrenzten Anzahl von Geräten installiert werden kann, müssen Sie die Sitzungen für diese Geräte nicht ändern, da die Zahl der zeitgleichen Sitzungen davon abhängt, mit wie vielen Geräten Sie sich voraussichtlich gleichzeitig verbinden.',
		cloudBackupStorageInfo:
			'Indem Sie das Attribut Storage ändern, legen Sie fest, wie viele 100-GB-Speicher-Buckets Sie kaufen möchten. Beispiel: “Storage = 5” bedeutet, Sie kaufen einen Speicher-Bucket mit 500 GB für eine Lizenz.',
	},
	downloadFile: {
		loading: 'Datei {{filename}} wird heruntergeladen',
		error: 'Datei kann nicht heruntergeladen werden',
	},
	appVersionGuard: {
		caption: 'Eine neue Version von OMS ist verfügbar!',
		message: 'Bitte laden Sie die Seite neu, um die neueste Version zu erhalten.',
		action: 'Erneut laden',
	},
};
