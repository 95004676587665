export default {
	error: {
		closingBalanceNotFound: '決算残高が見つかりません。',
	},
	page: {
		detail: {
			title: '詳細 #{{id}}',
		},
	},
	tab: {
		detail: '詳細ビュー',
		info: '情報',
		statement: '明細書ビュー',
	},
	entity: {
		amountWOTax: '税抜き金額',
		amountWTax: '税込み金額',
		companyTaxId: '会社の税 ID',
		lineDetailId: '明細行の詳細 ID',
		orderStatusPlaceholder: 'すべてのステータス',
		paymentStatusPlaceholder: 'すべてのステータス',
		period: '期間',
		term: '条件',
		partnerDiscount: 'パートナー割引',
		itemPriceWOTax: '品目の税抜き価格',
		fulfillmentCompleteDate: '注文日',
		srpTotalPrice: 'SRP 合計価格',
	},
	actions: {
		downloadInvoice: '請求書をダウンロード',
		export: {
			list: '明細書をエクスポート',
			line: '品目をエクスポート',
			lineDetail: 'ライセンスをエクスポート',
		},
		viewRefunds: '返金を表示',
	},
	common: {
		documents: '注文書類',
		initiateRefund: '返金を開始',
	},
	statement: {
		saleUnitPrice: '販売単価',
		pricingInfo: '価格情報',
	},
};
