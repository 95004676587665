import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import React, { PropsWithChildren, useState } from 'react';
import { FormikForm } from 'js/components/formik/FormikForm';
import { useTranslation } from 'react-i18next';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import { ICreditLimitForm } from 'module/creditLimit';
import { creditLimitConfig } from 'module/creditLimit/creditLimitConfig';
import { Col, Row } from 'js/components/atoms/Row';
import { FormikControl } from 'js/components/formik/FormikControl';
import { IEntityPartner } from 'module/partners';
import { useCreditLimitFormValidator } from 'module/creditLimit/hooks/useCreditLimitFormValidator';

type TCreditLimitFormProps = {
	initialValues: ICreditLimitForm;
	onCancel?: () => void;
	onSubmit: FormikConfig<ICreditLimitForm>['onSubmit'];
};

export const CreditLimitForm = (props: PropsWithChildren<TCreditLimitFormProps>) => {
	const { onCancel, onSubmit, initialValues } = props;
	const [t] = useTranslation(creditLimitConfig.trNamespace);
	const validator = useCreditLimitFormValidator();

	// Partner
	const [partner, setPartner] = useState<IEntityPartner | null>(null);
	const currency = partner?.currencyCode || '';

	return (
		<Formik<ICreditLimitForm>
			initialValues={initialValues}
			validationSchema={validator.validationSchema}
			onSubmit={onSubmit}
		>
			<FormikForm testId="creditLimit">
				<Row size="md">
					<Col sm={12}>
						<FormikControl label={t('common:entity.partner')}>
							<FormikControl.SelectPartner
								required
								name="partnerId"
								onChange={(_, partnerEntity) => setPartner(partnerEntity)}
								staticFilter={{
									creditEligible: true,
								}}
							/>
						</FormikControl>
					</Col>
					<Col sm={8}>
						<FormikControl label={t('common:entity.amount')}>
							<FormikControl.Number
								name="amount"
								min={0}
								step={1}
								required
								numberFormat={{
									output: Boolean(currency) ? 'currency' : 'number',
									currencyPosition: 'postfix',
									currencySymbol: currency,
									spaceSeparatedCurrency: true,
								}}
							/>
						</FormikControl>
					</Col>
				</Row>

				<FormikFooterControls
					submit={{ children: t('common:actions.submitForm') }}
					cancel={{ onClick: onCancel }}
				/>
			</FormikForm>
		</Formik>
	);
};
