import React, { FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { createAgent, throughDirect } from 'react-through';

const filterThroughArea = 'filter';
const withFilterContainer = throughDirect(filterThroughArea);

/**
 * Component render filter, if is set and wrap it to filter container
 * @param {PropsWithChildren} props
 * @returns {FunctionComponent}
 * @private
 */
const _FilterPlaceholder: FunctionComponent = (props: PropsWithChildren<{}>): ReactElement | null =>
	props.children ? <div className="section__filter container-fluid">{props.children}</div> : null;

export const FilterPlaceholder = withFilterContainer(_FilterPlaceholder);

/**
 * Filter container component
 */
export const FilterPlaceholderAgent: FunctionComponent<{ children: ReactNode }> = createAgent(filterThroughArea);
