import React from 'react';
import { DataTable, DataTableCard } from 'js/components/molecules/DataTable';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import { TDataTableCardProps } from 'js/components/molecules/DataTable/DataTableCard';
import { useCountryName } from 'js/entities/country/useCountryName';
import { useCountryStateName } from 'js/entities/country/useCountryStateName';
import { useAuthContext } from 'js/contexts';

export const AddressInformationCard = (props: Partial<TDataTableCardProps>) => {
	const { authCompany: company } = useAuthContext();
	const [t] = useTranslation(accountConfig.trNamespace);
	const countryName = useCountryName(company?.countryCode, company?.countryName);
	const stateName = useCountryStateName(company?.countryCode, company?.stateCode, company?.stateName);

	if (!company) {
		return null;
	}

	return (
		<DataTableCard
			caption={t('cards.addressInformation')}
			variant="simple"
			{...props}
		>
			<DataTable.Tr name={t('common:address.street')}>{company.address1}</DataTable.Tr>
			<DataTable.Tr name={t('common:address.city')}>{company.city}</DataTable.Tr>
			<DataTable.Tr name={t('common:address.postalCode')}>{company.postalCode}</DataTable.Tr>
			<DataTable.Tr
				name={t('common:address.state')}
				noRenderEmpty
			>
				{stateName}
			</DataTable.Tr>
			<DataTable.Tr name={t('common:address.country')}>{countryName}</DataTable.Tr>
		</DataTableCard>
	);
};
