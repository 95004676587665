import { TApiErrorResolve } from 'types/api';
import { customersConfig } from 'module/customers/customersConfig';
import i18n from 'i18next';

export const createCustomerApiError: TApiErrorResolve = () => ({
	title: i18n.t(customersConfig.trPrefix('error.createCustomer')),
});

export const updateCustomerApiError: TApiErrorResolve = () => ({
	title: i18n.t(customersConfig.trPrefix('error.updateCustomer')),
});
