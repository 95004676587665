import React from 'react';
import { AuthRoute } from 'js/routes/AuthRoute';
import { ACL_CREATE, ACL_READ_DETAIL, ACL_READ_LIST, ACL_UPDATE } from 'config/acl';
import { Error404 } from 'module/error/page/Error404';
import { Route, Routes } from 'react-router-dom';
import { PageCreate, PageDetail, PageList, PageUpdate } from 'module/distributionPartners/pages';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';

export const DistributionPartnerRoutes = () => {
	const { aclModule } = distributionPartnersConfig;

	return (
		<Routes>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ_LIST]} />}>
				<Route
					index
					element={<PageList />}
				/>
			</Route>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ_DETAIL]} />}>
				<Route
					path="detail/:id"
					element={<PageDetail />}
				/>
			</Route>
			<Route element={<AuthRoute acl={[aclModule, ACL_UPDATE]} />}>
				<Route
					path="update/:id"
					element={<PageUpdate />}
				/>
			</Route>
			<Route element={<AuthRoute acl={[aclModule, ACL_CREATE]} />}>
				<Route
					path="create"
					element={<PageCreate />}
				/>
			</Route>
			<Route
				path="*"
				element={<Error404 />}
			/>
		</Routes>
	);
};
