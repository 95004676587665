import { generateEnumUtilsWithDescription } from 'js/enums/generator/enumFunctions';

export enum PaymentStatusEnum {
	PAID = 'Paid',
	PARTIAL_PAID = 'Partial Paid',
	UNPAID = 'Unpaid',
	UNBILLED = 'Unbilled',
	SETTLED = 'Settled',
	NA = 'NA',
}

export const paymentStatusEnumUtils = generateEnumUtilsWithDescription<PaymentStatusEnum>(PaymentStatusEnum, {
	translatePath: 'enums:payment.status',
	aliases: { [PaymentStatusEnum.NA]: PaymentStatusEnum.UNBILLED },
});
