import { TableColumnsDef } from 'types';
import { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { useTranslation } from 'react-i18next';
import { retailConfig } from 'module/retail/retailConfig';
import { IEntityLicense } from 'module/licenses';
import { getBaseLicensesTableColumns } from 'module/licenses/utils/common';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';

type TTableData = IEntityLicense;

export const useRetailLicensesListColumns = (): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(retailConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<IEntityLicense>();

		return [
			...getBaseLicensesTableColumns(t),
			columnHelper.actions({
				iconLink: {
					icon: faArrowRight,
					to: (row) => buildParametrizedRoute(RouteName.LICENSE.DETAIL, row.id),
					title: t('common:actions.detail'),
					testId: 'detail',
				},
			}),
		];
	}, [t]);
};
