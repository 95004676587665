import { IPageModuleConfig, IPageModuleLinksConfig, moduleConfigGenerator } from 'module/index';
import { RouteName } from 'module/RouteName';
import { buildParametrizedRoute } from 'js/utils/common';
import { IEntityPromotion, IPromotionForm } from 'module/promotions';
import { PromotionScopeEnum, PromotionTypeEnum } from 'module/promotions/enums';
import { MarketSegmentEnum } from 'module/purchase/enums';

interface IPromotionsConfigProps extends IPageModuleConfig, IPageModuleLinksConfig<IEntityPromotion> {
	updateLink: (promotion: IEntityPromotion) => string;
	defaultFormValues: IPromotionForm;
}

export const promotionsConfig = moduleConfigGenerator<IPromotionsConfigProps>({
	trNamespace: 'modulePromotions',
	menuName: 'app:menu.promotions',
	menuLink: RouteName.PROMOTIONS.LIST,
	aclModule: 'promotions',
	defaultFormValues: {
		name: '',
		scope: PromotionScopeEnum.PARTY_AND_PRODUCT,
		type: PromotionTypeEnum.DISCRETIONARY,
		date: null,
		marketSegment: MarketSegmentEnum.BUSINESS,
	},
	updateLink: (promotion) => buildParametrizedRoute(RouteName.PROMOTIONS.UPDATE, promotion.id),
	detailLink: (promotion) => buildParametrizedRoute(RouteName.PROMOTIONS.DETAIL, promotion.id),
});
