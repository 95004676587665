import { Button } from '@avast/react-ui-components';
import { LinkButton } from 'js/components/atoms/Button';
import React from 'react';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { IEntityPromotion } from 'module/promotions';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { isPromotionEditable } from 'module/promotions/utils/common';

export const EditPromotionButton = () => {
	const [t] = useTranslation(promotionsConfig.trNamespace);
	const {
		data: promotion,
		query: { isFetching },
	} = usePageDetailContext<IEntityPromotion>();

	if (!isPromotionEditable(promotion)) {
		return (
			<Button
				loading={isFetching}
				size="sm"
				disabled
			>
				{t('actions.edit')}
			</Button>
		);
	}

	return (
		<LinkButton
			loading={isFetching}
			to={promotionsConfig.updateLink(promotion)}
			size="sm"
			testId="editPromotion"
		>
			{t('actions.edit')}
		</LinkButton>
	);
};
