import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum PromotionPartyTypeEnum {
	DISTRIBUTION = 'DISTRIBUTION',
	RESELLER = 'RESELLER',
	END_CUSTOMER = 'END_CUSTOMER',
}

export const promotionPartyTypeEnumUtils = generateEnumUtils<PromotionPartyTypeEnum>(PromotionPartyTypeEnum, {
	translatePath: 'enums:promotion.partyType',
});
