import { TWidgetGrid } from 'submodule/widgets';
import { logError } from 'js/utils/app';
import { TWidgetValidatorSchema } from 'submodule/widgets/hooks/useWidgetConfigValidator';

export const widgetConfigNormalizer = {
	normalize(config: TWidgetGrid | null | undefined, validator: TWidgetValidatorSchema): TWidgetGrid | null {
		if (!config) {
			return null;
		}

		try {
			validator.validateSync(config, { strict: true });
		} catch (e) {
			logError('widgetConfigNormalizer', e);
			return null;
		}

		return config;
	},
};
