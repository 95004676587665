import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Button, H3 } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { registrationConfig } from 'module/registration/registrationConfig';
import { PartnerTermsEnum, partnerTermsEnumUtils } from 'module/partners/enums';
import { useBootstrapAccordionContext } from 'js/contexts/useBootstrapAccordionContext';

type TTermsCardProps = {
	eventKey: PartnerTermsEnum;
	content: string;
};

export const TermsCard = (props: TTermsCardProps) => {
	const [t] = useTranslation(registrationConfig.trNamespace);
	const { eventKey, content } = props;
	const { activeEventKey } = useBootstrapAccordionContext();
	const isActive = activeEventKey === eventKey;

	return (
		<Accordion.Item
			eventKey={eventKey}
			className="accordion-item--terms"
		>
			<Accordion.Button className="d-flex justify-content-between align-items-center">
				<H3>{partnerTermsEnumUtils.getText(eventKey)}</H3>
				<Button
					as="span"
					variant="outline-primary"
					size="xs"
					style={{ minWidth: '130px' }}
				>
					{t(`common:actions.${isActive ? 'showLess' : 'showMore'}`)}
				</Button>
			</Accordion.Button>

			<Accordion.Collapse eventKey={eventKey}>
				<Accordion.Body className="p-0">
					<div
						className="terms-content-container p-3 fs-sm"
						dangerouslySetInnerHTML={{ __html: content }}
					/>
				</Accordion.Body>
			</Accordion.Collapse>
		</Accordion.Item>
	);
};
