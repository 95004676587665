import React, { ReactElement } from 'react';
import { BillablePartyStep, DiscountStep, PriceStep, ProductStep } from 'module/priceCalculator/forms';
import { Formik } from 'formik';
import { logDebug } from 'js/utils/app';
import { IPriceCalculatorForm } from 'module/priceCalculator';
import { FormikForm } from 'js/components/formik/FormikForm';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle/DynamicPageTitle';
import { useTranslation } from 'react-i18next';
import { priceCalculatorConfig } from 'module/priceCalculator/priceCalculatorConfig';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { TierCertificationEnum } from 'js/enums';
import { MarketSegmentEnum, PurchaseBillablePartyTypeEnum } from 'module/purchase/enums';
import { useAuthContext } from 'js/contexts';

export const PageCalculator = (): ReactElement => {
	const [t] = useTranslation(priceCalculatorConfig.trNamespace);
	const { authPartnerGroups } = useAuthContext();

	return (
		<Formik<IPriceCalculatorForm>
			initialValues={{
				billablePartyType: authPartnerGroups.isConsumerTeam
					? PurchaseBillablePartyTypeEnum.CUSTOMER
					: PurchaseBillablePartyTypeEnum.PARTNER,
				units: 0,
				isRenewal: false,
				marketSegment: authPartnerGroups.isConsumerTeam ? MarketSegmentEnum.CONSUMER : MarketSegmentEnum.BUSINESS,
				discount: {
					tierCertification: TierCertificationEnum.TIER_4,
					special: 0,
					discretionary: 0,
				},
			}}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(false);
				logDebug('values', values);
			}}
		>
			<FormikForm
				testId="priceCalculator"
				className="d-flex flex-column min-vh-content-100"
			>
				<DynamicPageTitleItem text={t(priceCalculatorConfig.menuName)} />
				<DynamicBreadcrumbsItem href={priceCalculatorConfig.menuLink}>
					{t(priceCalculatorConfig.menuName)}
				</DynamicBreadcrumbsItem>

				<BillablePartyStep />
				<ProductStep />
				<DiscountStep />
				<PriceStep />
			</FormikForm>
		</Formik>
	);
};
