import { FLEXIBLE_BILLING_UNLIMITED_VALUE } from 'module/flexibleBilling/constants';
import { UnlimitedValue } from 'module/flexibleBilling/components/UnlimitedValue';
import { numberFormatter } from 'js/utils/number';
import React from 'react';
import { TCurrency } from 'types';

type TFlexibleBillingFormAfterValueProps = {
	valueBefore: number;
	valueAfter: number;
	currency?: TCurrency;
};

export const FlexibleBillingFormAfterValue = (props: TFlexibleBillingFormAfterValueProps) => {
	const { valueBefore, valueAfter, currency } = props;

	if (valueAfter === FLEXIBLE_BILLING_UNLIMITED_VALUE) {
		return <UnlimitedValue valueClassName="text-primary" />;
	}

	const diff = valueAfter - valueBefore;
	return (
		<div className="text-gray lh-1 mt-2">
			<div className="text-primary">{numberFormatter.currency(valueAfter, currency)}</div>
			{valueBefore !== FLEXIBLE_BILLING_UNLIMITED_VALUE && (
				<div className="fs-sm">
					{diff >= 0 ? '+' : '-'}
					{numberFormatter.currency(Math.abs(diff), currency)}
				</div>
			)}
		</div>
	);
};
