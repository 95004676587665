import { IInitiateRefundForm, IInitiateRefundTableItem } from 'submodule/refunds';
import { TableColumnsDef } from 'types';
import React, { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { RefundTypeEnum, refundTypeEnumUtils } from 'submodule/refunds/enums';
import { FormikControl } from 'js/components/formik/FormikControl';
import { normalizeSku } from 'module/orders/utils/common';
import { sumNumberFooter } from 'js/components/table/common/sumNumberFooter';
import { ProductsTableFormikNumber } from 'submodule/refunds/initiate/form/ProductsTableFormikNumber';
import { noValuePlaceholder } from 'js/utils/app';
import { floor, isNumber } from 'lodash';
import { useInitiateRefundContext } from 'submodule/refunds/initiate/InitiateRefundContext';
import { useFormikContext } from 'formik';

type TTableData = IInitiateRefundTableItem;

export const useRefundProductsColumns = (): TableColumnsDef<TTableData> => {
	const { t, currency } = useInitiateRefundContext();
	const { values } = useFormikContext<IInitiateRefundForm>();
	const { refundType, refundAmount, refundableAmount, taxRefundableAmount } = values;
	const listKey = refundType === RefundTypeEnum.KEY ? 'licenses' : 'items';
	const isSelectable = refundTypeEnumUtils.validateOneOf(refundType, [RefundTypeEnum.KEY, RefundTypeEnum.LINE]);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		const quotient = refundAmount / refundableAmount;
		const isKeyRefund = refundType === RefundTypeEnum.KEY;

		const columns = [columnHelper.hidden('productSku')];

		if (isSelectable) {
			columns.push(
				columnHelper.display({
					id: 'selection',
					meta: {
						clickAble: false,
						formatters: [
							(value, { row }) => (
								<FormikControl groupProps={{ className: 'mb-0' }}>
									<FormikControl.Checkbox
										validation={false}
										name={`${listKey}.${row.index}.isSelected`}
									/>
								</FormikControl>
							),
						],
						className: 'pe-0',
					},
				}),
			);
		}

		columns.push(
			columnHelper.text('productName', {
				header: t('common:entity.product'),
				meta: {
					formatters: [
						formatter.bold,
						(value, { row }) => {
							const description: string[] = [];
							description.push(normalizeSku(row.original.productSku));
							description.push(`${t('common:entity.quantity')}: ${row.original.quantity}`);
							description.push(`${t('common:entity.unit', { count: row.original.unit })}: ${row.original.unit}`);
							return (
								<>
									<div>{value}</div>
									<div className="fs-sm text-gray text-nowrap">{description.join(' / ')}</div>
								</>
							);
						},
					],
				},
			}),
		);

		if (isKeyRefund) {
			columns.push(
				columnHelper.text('licenseKey', {
					header: t('common:entity.licenseKey'),
					meta: {
						className: 'text-nowrap',
					},
				}),
			);
		}

		columns.push(
			columnHelper.money(
				'totalPrice',
				{
					header: t('common:entity.total'),
					footer: sumNumberFooter<IInitiateRefundTableItem>({ currency }),
					meta: {
						className: 'text-nowrap',
						hidden: isNumber(taxRefundableAmount) && isKeyRefund,
					},
				},
				{ currency },
			),
		);

		if (isNumber(taxRefundableAmount)) {
			columns.push(
				columnHelper.money(
					'totalTax',
					{
						header: t('common:entity.tax'),
						footer: sumNumberFooter<IInitiateRefundTableItem>({ currency }),
						meta: {
							className: 'text-nowrap',
							hidden: isKeyRefund,
						},
					},
					{ currency },
				),
				columnHelper.money(
					'totalPriceWithTax',
					{
						header: t('common:entity.totalWTax'),
						footer: sumNumberFooter<IInitiateRefundTableItem>({ currency }),
						meta: { className: 'text-nowrap' },
					},
					{ currency },
				),
			);
		}

		columns.push(
			columnHelper.money(
				'refundableAmount',
				{
					header: t('refunds.entity.refundableAmount'),
					footer: sumNumberFooter<IInitiateRefundTableItem>({ currency }),
					meta: {
						className: 'text-nowrap',
					},
				},
				{ currency },
			),
		);

		if (isSelectable) {
			columns.push(
				columnHelper.number('refundAmountCustom', {
					header: t('refunds.entity.refundAmount'),
					footer: sumNumberFooter<IInitiateRefundTableItem>({
						currency,
						isValidRow: (row) => row.isSelected,
						className: 'fw-bold',
					}),
					meta: {
						clickAble: false,
						formatters: [
							(value, { row }) => {
								if (row.original.isSelected) {
									return (
										<ProductsTableFormikNumber
											name={`${listKey}.${row.index}.refundAmountCustom`}
											max={row.original.refundAmount}
										/>
									);
								}
								return noValuePlaceholder();
							},
						],
					},
				}),
			);
		}

		if (refundType === RefundTypeEnum.ORDER) {
			columns.push(
				columnHelper.text('refundAmount', {
					id: 'refundAmount',
					header: t('refunds.entity.refundAmount'),
					footer: () => (
						<ProductsTableFormikNumber
							name="refundAmount"
							max={refundableAmount}
						/>
					),
					meta: {
						className: 'text-nowrap',
						align: 'end',
						formatters: [
							(value) => floor(value * quotient, 2),
							formatter.money<IInitiateRefundTableItem>({ currency }),
							formatter.bold,
							(value) => (quotient === 1 ? value : <>≈ {value}</>),
						],
					},
				}),
			);
		}

		if (refundType === RefundTypeEnum.TAX) {
			columns.push(
				columnHelper.money(
					'refundAmount',
					{
						header: t('refunds.entity.refundAmount'),
						footer: sumNumberFooter<IInitiateRefundTableItem>({ currency, className: 'fw-bold' }),
					},
					{ currency },
				),
			);
		}

		return columns;
	}, [t, currency, taxRefundableAmount, refundType, refundAmount, refundableAmount, listKey, isSelectable]);
};
