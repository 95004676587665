export default {
	error: {
		closingBalanceNotFound: 'Solde de clôture introuvable',
	},
	page: {
		detail: {
			title: 'N° de détail : {{id}}',
		},
	},
	tab: {
		detail: 'Vue des détails',
		info: 'Informations',
		statement: 'Vue des relevés',
	},
	entity: {
		amountWOTax: 'Montant hors taxe',
		amountWTax: 'Montant TTC',
		companyTaxId: 'N° fiscal de l’entreprise',
		lineDetailId: 'ID de la ligne d’article',
		orderStatusPlaceholder: 'Tout état',
		paymentStatusPlaceholder: 'Tout état',
		period: 'Période',
		term: 'Durée de validité',
		partnerDiscount: 'Remise aux partenaires',
		itemPriceWOTax: 'Prix de l’article HT',
		fulfillmentCompleteDate: 'Date de la commande',
		srpTotalPrice: 'Prix total conseillé',
	},
	actions: {
		downloadInvoice: 'Télécharger la facture',
		export: {
			list: 'Exporter les relevés',
			line: 'Exporter les articles',
			lineDetail: 'Exporter les licences',
		},
		viewRefunds: 'Afficher les remboursements',
	},
	common: {
		documents: 'Documents associés à la commande',
		initiateRefund: 'Initier le remboursement',
	},
	statement: {
		saleUnitPrice: 'Prix unitaire de vente',
		pricingInfo: 'Informations sur le tarif',
	},
};
