import { orderStatusEnumUtils } from 'module/orders/enums';
import { IOrderListApiFilter, IOrderListFilter } from 'module/orders';
import { ordersFilterNormalizer } from 'module/orders/normalizer/ordersFilterNormalizer';

interface IQuotesFilterNormalizer {
	denormalize(filter?: IOrderListFilter): IOrderListApiFilter;
}

export const quotesFilterNormalizer: IQuotesFilterNormalizer = {
	denormalize(filter) {
		return ordersFilterNormalizer.denormalize(filter, orderStatusEnumUtils.getQuoteStatuses());
	},
};
