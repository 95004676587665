import { toast } from 'react-toastify';
import { Card, Toast } from '@avast/react-ui-components';
import React, { useCallback } from 'react';
import { useApiRegisterLicense } from 'module/licenses/hooks';
import { IEntityLicense } from 'module/licenses';
import { IEntityCustomer } from 'module/customers';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { getLicenseExternalIds, showEntitlementId } from 'module/licenses/utils/common';
import { CustomerCard } from 'module/customers/components';
import { registerLicenseApiError } from 'module/licenses/utils/apiError';
import { gtm } from 'js/analytics/gtm';
import { useApiErrorContext, useAppContext } from 'js/contexts';

export const useRegisterCustomerToLicense = (license: IEntityLicense) => {
	const { loadingModalRef, confirmationModalRef } = useAppContext();
	const { setError } = useApiErrorContext();
	const [t] = useTranslation(licensesConfig.trNamespace);
	const licenseId = license.id;

	const { mutate: updateLicense } = useApiRegisterLicense({
		config: {
			params: {
				entitlementId: licenseId.toString(),
			},
		},
	});

	const isConfirmedLicenseRegistration = useCallback(
		async (customer: IEntityCustomer): Promise<boolean> => {
			const { customerId } = getLicenseExternalIds(license);
			const message = customerId
				? t('registration.confirmation.replace', { licenseId: showEntitlementId(license.id) })
				: t('registration.confirmation.place');
			const customerCard = (
				<Card>
					<Card.Body>
						<CustomerCard customer={customer!} />
					</Card.Body>
				</Card>
			);

			return Boolean(
				await confirmationModalRef.current?.show({
					title: t('registration.confirmation.title'),
					messages: [message],
					submitButtonText: t('common:actions.confirm'),
					content: customerCard,
				}),
			);
		},
		[confirmationModalRef, t, license],
	);

	const registerCustomerToLicense = useCallback(
		async (customer?: IEntityCustomer | null) => {
			if (customer?.id) {
				if (!(await isConfirmedLicenseRegistration(customer))) {
					return;
				}

				loadingModalRef.current?.show({ title: t('registration.registering') });
				gtm.registerLicense(licenseId);
				updateLicense(
					{ customerId: customer.id },
					{
						onSuccess() {
							loadingModalRef.current?.hide();
							toast.success(<Toast>{t('registration.success', { customer: getCustomerRenderName(customer) })}</Toast>);
						},
						onError(error) {
							loadingModalRef.current?.hide();
							setError({ error, resolve: registerLicenseApiError });
						},
					},
				);
			}
		},
		[isConfirmedLicenseRegistration, loadingModalRef, setError, t, updateLicense, licenseId],
	);

	return {
		registerCustomerToLicense,
		isEnabled: Boolean(getLicenseExternalIds(license).partnerSalesforceId),
	};
};
