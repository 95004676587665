import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { RouteName } from 'module/RouteName';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';

export const FlexibleBillingListMenu = () => {
	const [t] = useTranslation(flexibleBillingConfig.trNamespace);

	return (
		<Nav
			variant="tabs"
			as="ul"
		>
			<Nav.Item as="li">
				<Nav.Link
					as={NavLink}
					end
					to={RouteName.FLEXIBLE_BILLING.DISTRIBUTION_PARTNERS}
				>
					{t('tab.distributionPartners')}
				</Nav.Link>
			</Nav.Item>
			<Nav.Item as="li">
				<Nav.Link
					as={NavLink}
					end
					to={RouteName.FLEXIBLE_BILLING.REPORTS}
				>
					{t('tab.reports')}
				</Nav.Link>
			</Nav.Item>
		</Nav>
	);
};
