export default {
	actions: {
		create: 'Create new Dist. Partner',
		edit: 'Edit Distribution Partner',
		exportAll: 'Export All Dist. Partners',
		form: {
			create: 'Save as new Distribution Partner',
			update: 'Save Changes',
		},
	},
	error: {
		createDistributionPartner: 'Unable to create Distribution Partner.',
		updateDistributionPartner: 'Unable to update Distribution Partner.',
	},
	page: {
		create: {
			title: 'New Distribution Partner',
			success: 'You have successfully created a new Distribution Partner.',
		},
		update: {
			success: 'You have successfully updated Distribution Partner.',
		},
	},
	select: {
		title: 'Select Distribution Partner',
		button: {
			label: 'Distribution Partner',
			placeholder: 'Select distribution partner',
		},
		filter: {
			placeholder: 'Find by e-mail, name or company',
		},
	},
};
