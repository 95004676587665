export default {
	common: {
		allPartnerType: 'Alle Partner',
		avastId: 'Avast-Partner-ID',
		loadingDetail: 'Partnerdetails werden geladen',
	},
	error: {
		unableToLoadTerms: 'Allgemeine Geschäftsbedingungen können nicht geladen werden.',
		unableToLoadPartnerDetail: 'Partnerdetails können nicht geladen werden',
	},
	entity: {
		accountType: 'Typ',
		accountSubType: 'Untertyp',
		company: 'Unternehmen',
		email: 'E-Mail',
		id: 'Partner-ID',
		salesforceId: 'SalesForce-ID',
		name: 'Vollständiger Name',
	},
	select: {
		title: 'Partner wählen',
		button: {
			label: 'Partner',
			placeholder: 'Partner wählen',
		},
		filter: {
			placeholder: 'Partner suchen',
		},
	},
};
