export default {
	page: {
		default: {
			title: 'マイ アカウント',
		},
		taxExemption: {
			title: '非課税証明書',
			shortTitle: '非課税',
			action: '新しい非課税証明書を送信',
		},
		flexibleBilling: {
			title: 'フレキシブル請求',
		},
		autoBillingAddCard: {
			title: '新しいカードを追加',
		},
	},
	error: {
		autoBillingSource:
			'クレジット カードの詳細を読み込むことができませんでした。アカウント マネージャーにお問い合わせください。',
	},
	cards: {
		accountInformation: 'アカウント情報',
		contactInformation: '連絡先情報',
		addressInformation: '住所情報',
		terms: '利用規約',
		legacyPaymentOptions: '保存されたカード',
		personalization: 'パーソナライズ',
	},
	common: {
		locale: {
			language: 'システムの言語',
			format: '日付/数字の書式設定',
		},
	},
	form: {
		creditCard: {
			owner: 'カード所有者情報',
			creditCard: 'クレジット カード',
			number: 'カード番号',
			expiration: '有効期限 (MM/YY)',
			cvv: 'CVV',
			submit: 'クレジット カードを保存',
			isPreferredPayAsYouGoPaymentMethod: 'ご希望のフレキシブル請求をお支払い方法として設定',
		},
		taxExemption: {
			title: '新しい納税証明書を送信',
			taxAuthorityPlaceholder: '税務署を選択',
			file: '非課税証明書ファイル',
			declaration:
				'私は上記の情報および提出する証明書類が正確なものであることを表明し、違反した場合は罰則および偽証罪が適用されることに同意するものとします。',
			success: '非課税申請書が送信されました。',
		},
	},
	taxExemption: {
		taxAuthority: '税務署',
		startDate: '課税控除の開始日',
		endDate: '課税控除の終了日',
		fileName: 'ファイル名',
		status: {
			ELIGIBLE: '対象',
			NOT_ELIGIBLE: '対象外',
		},
	},
	flexibleBilling: {
		paymentMethod: {
			title: 'お支払い方法',
			subtitle: 'ご希望のお支払いオプションを選択',
			submitButton: 'お支払い方法を保存',
			success: 'ご希望のお支払い方法が正常に保存されました',
			addCardFirst: '自動請求を有効にするには、クレジット カードを最初に追加してください。',
		},
	},
	creditCard: {
		entity: {
			number: 'カード番号',
			expiration: '有効期限',
		},
		currentSource: {
			title: '自動請求',
			subtitle: '保存されたカード',
			loading: 'カード情報を取得中',
			notFound: '保存されたカードはありません。',
			create: '新しいカードを追加',
		},
		message: {
			successCreate: 'カードが正常に保存されました。',
			successDelete: 'カードが正常に削除されました。',
			saving: 'カードを保存中',
		},
		delete: {
			title: 'カードをアカウントから削除',
			confirmation: 'カード (•••• {{lastFourDigits}}) を削除しますか？',
			loading: 'カードを削除中',
		},
		error: {
			bad_request:
				'クレジット カードが無効です。もう一度お試しになるか、アカウント マネージャーにお問い合わせください。',
		},
	},
	legacyPaymentOptions: {
		unableToLoad: '保存されたカードを読み込むことができません。',
		noStoredCards: '保存されたカードはありません。',
	},
};
