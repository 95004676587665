import { IEntityLanguage } from 'submodule/localeSwitch';
import { languages } from 'submodule/localeSwitch/data';
import { useAllowedLanguages } from 'js/hooks/useAllowedLanguages';
import { useAuthContext } from 'js/contexts';
import { sortBy } from 'lodash';

export const useLanguages = (): IEntityLanguage[] => {
	const { authPartnerGroups } = useAuthContext();
	const allowedLanguages = useAllowedLanguages(authPartnerGroups);
	return sortBy(
		languages.filter((item) => allowedLanguages.includes(item.language)),
		'name',
	);
};
