import { IEntityClosingBalance } from 'module/closingBalance';
import { ClosingBalanceOrderStatusEnum, closingBalanceOrderStatusEnumUtils } from 'module/closingBalance/enums';
import { IPartnerGroups } from 'module/partners';
import { isGcOrder } from 'module/orders/utils/common';

export const hasAvailableDocuments = (closingBalance: IEntityClosingBalance): boolean => {
	if (closingBalance.billing?.status === 'Complete') {
		return true;
	}

	if (!isGcOrder(closingBalance)) {
		if (closingBalance.billing?.status === 'Waiting Payment') {
			return true;
		}
	}

	return false;
};

export const isClosingBalanceRefundable = (
	closingBalance: IEntityClosingBalance,
	authPartnerGroups: IPartnerGroups,
): boolean => {
	// Disable GC refunds for non-test accounts
	if (isGcOrder(closingBalance) && !authPartnerGroups.isTestAccount) {
		return false;
	}

	if (!closingBalance.chargeOrderId) {
		return false;
	}

	return closingBalanceOrderStatusEnumUtils.validateOneOf(closingBalance.billing?.status, [
		ClosingBalanceOrderStatusEnum.COMPLETE,
		ClosingBalanceOrderStatusEnum.WAITING_PAYMENT,
	]);
};
