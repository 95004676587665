export default {
	actions: {
		create: 'Create New Promotion',
		edit: 'Edit promotion',
		disable: {
			action: 'Disable promotion',
			success: 'The promotion was disabled',
			loading: 'Disabling promotion...',
			confirmation: {
				title: 'Disabling Promotion',
				message: 'Are you sure you want to disable this Promotion? This action cannot be returned.',
			},
		},
		form: {
			create: 'Submit Promotion',
			update: 'Update Promotion',
		},
		extraFilters: 'More Filters',
		export: 'Export Promotions',
	},
	common: {
		effectiveDate: 'Effective Date',
		filterPromotions: 'Filter Promotions',
		partyCriteria: 'Party Criteria',
		partyCriteriaInfo: 'Select a Partner or at least one Party Criteria to continue.',
		productCriteria: 'Product Criteria',
		creatingPromotion: 'Creating Promotion',
		updatingPromotion: 'Updating Promotion',
		invalidPromotionStartDate: {
			title: 'Do you want to save the promotion?',
			message: 'The start date of the promotion is in the past. You will no longer be able to edit the promotion.',
		},
	},
	error: {
		promotionNotFound: 'Promotion not found!',
		createPromotion: 'Unable to create the promotion.',
		updatePromotion: 'Unable to update the promotion.',
	},
	page: {
		create: {
			title: 'New Pricing Promotion',
			success: 'You have successfully created a new Pricing Promotion.',
		},
		update: {
			title: 'Update Promotion #{{id}}',
			success: 'You have successfully updated the Pricing Promotion.',
			notEditable: 'Current promotion cannot be edited because it is possible that is involved in an order.',
		},
	},
	placeholder: {
		types: 'All promotion types',
		partyTiers: 'All tiers',
		categories: 'All categories',
		licenseOperations: 'All license operations',
		partyCountries: 'All countries',
		partyTypes: 'All party types',
		products: 'All products',
	},
	entity: {
		id: 'Promotion ID',
		name: 'Promotion Name',
		date: 'Validity Date Range',
		partyType: 'Party Type',
		partyType_other: 'Party Types',
		partyTier: 'Party Tier',
		partyTier_other: 'Party Tiers',
		partyCountry: 'Party Country',
		partyCountry_other: 'Party Countries',
		licenseOperation: 'License Operations Type',
		licenseOperation_other: 'License Operations Types',
		category: 'Product Category',
		product: 'Product',
		product_other: 'Products',
		quantityMin: 'Minimum Quantity',
		rate: 'Discount Rate',
		scope: 'Scope',
		type: 'Promotion Type',
		unitsFrom: 'Units From',
		unitsTo: 'Units To',
	},
	tooltip: {
		zeroQuantity:
			'A zero quantity means that the promotion will be applied regardless of how many licenses are ordered.',
	},
};
