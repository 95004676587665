import { IGenerateRetailCodesForm, IGenerateRetailCodesRequest } from 'module/retail';
import { isPosaPartner } from 'module/partners/utils/partnerSelectors';
import { RetailCodeTypeEnum } from 'module/retail/enums';

export const retailFormNormalizer = {
	denormalize(form: IGenerateRetailCodesForm): IGenerateRetailCodesRequest | null {
		const { partner, products } = form;

		if (!partner?.id || products.length === 0) {
			return null;
		}

		return {
			type: isPosaPartner(partner) ? RetailCodeTypeEnum.POSA : RetailCodeTypeEnum.STANDARD,
			partnerId: partner.id,
			lineItems: products.map((item) => ({ sku: item.product.sku, quantity: item.quantity })),
		};
	},
};
