import React from 'react';
import { Table } from 'react-bootstrap';
import { comparativeFeatureEnumUtils } from 'submodule/comparativeFeatures/enums';
import { Alert } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { IEntityProductGroup } from 'module/purchase';
import { IComparativeData } from 'submodule/comparativeFeatures';
import { TCurrency } from 'types';
import {
	FeatureRow,
	HeadRow,
	LicenseOperationPricingRow,
	PricingRow,
	SelectGroupButtonRow,
	StartShoppingButtonRow,
} from './components';
import { Tip } from 'assets/image/icon';

export type TComparativeFeaturesTableBaseProps = {
	comparativeData: IComparativeData;
	currencyCode?: TCurrency;
};

type TComparativeFeaturesTableProps = TComparativeFeaturesTableBaseProps & {
	onSelect(group: IEntityProductGroup | null): void;
};

export const ComparativeFeaturesTable = (props: TComparativeFeaturesTableProps) => {
	const { comparativeData, currencyCode } = props;
	const [t] = useTranslation('components');
	if (!comparativeData) {
		return null;
	}

	const groupCount = comparativeData.products.length;
	const colTotal = comparativeData.count + groupCount;
	return (
		<>
			{comparativeData.message && (
				<Alert
					variant="success"
					icon={<Tip />}
				>
					{t(comparativeData.message)}
				</Alert>
			)}
			<Table
				responsive
				hover
				className="comparative-table"
			>
				<thead>
					<HeadRow comparativeData={comparativeData} />
				</thead>
				<tbody>
					{comparativeFeatureEnumUtils.getValues().map((feature) => (
						<FeatureRow
							key={feature}
							feature={feature}
							comparativeData={comparativeData}
						/>
					))}
				</tbody>
				<tfoot>
					{comparativeData.variant === 'LICENSE_OPERATION' ? (
						<LicenseOperationPricingRow
							comparativeData={comparativeData}
							currencyCode={currencyCode}
							variant={comparativeData.variant}
						/>
					) : (
						<PricingRow
							comparativeData={comparativeData}
							currencyCode={currencyCode}
							variant={comparativeData.variant}
						/>
					)}
					{comparativeData.variant === 'HOMEPAGE' ? (
						<StartShoppingButtonRow
							colSpan={colTotal}
							onClick={() => props.onSelect(null)}
						/>
					) : (
						<SelectGroupButtonRow
							comparativeData={comparativeData}
							onSelect={props.onSelect}
						/>
					)}
				</tfoot>
			</Table>
		</>
	);
};
