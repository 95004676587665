import { TAppMessageConfig } from 'submodule/AppMessages/types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LinkButton } from 'js/components/atoms/Button';
import { Tip } from 'assets/image/icon';

export const useFlexibleBillingAvailableMessageConfig = (): TAppMessageConfig => {
	const [t] = useTranslation('submodules');
	const learnMoreLink =
		'https://businesshelp.avast.com/Content/Products/AfB_Management_Consoles/UserandAccountManagement/FlexibleBilling.htm';

	return {
		id: 'badbf55c-ee40-4840-9cbc-7339dac33784',
		hidable: true,
		show: false, // isRoleReseller && isBusiness
		variant: 'success',
		icon: <Tip />,
		caption() {
			return <Trans t={t}>{'appMessages.flexibleBillingAvailable.title'}</Trans>;
		},
		render() {
			return (
				<>
					<p className="mb-3">
						<Trans
							t={t}
							i18nKey="appMessages.flexibleBillingAvailable.content"
						/>
					</p>
					<p>
						<LinkButton
							to={learnMoreLink}
							size="xs"
							target="_blank"
							rel="noopener noreferrer"
						>
							{t('appMessages.flexibleBillingAvailable.action')}
						</LinkButton>
					</p>
				</>
			);
		},
		placement: ['HOMEPAGE_PARTNER'],
	};
};
