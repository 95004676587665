import { Col } from 'js/components/atoms/Row';
import { FormikControl, TFormikControlProps, TFormikFieldProps } from 'js/components/formik/FormikControl';
import React from 'react';
import type { ColProps } from 'react-bootstrap/Col';
import type { TInputProps } from '@avast/react-ui-components';

export type TColInputProps = {
	label: string;
	colProps?: Omit<ColProps, 'children'>;
	messages?: TFormikControlProps['messages'];
} & TFormikFieldProps<TInputProps>;

/**
 * Generates inputs wrapped in Col component. Component should be used for a Bootstrap grid.
 *
 * @param {TColInputProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export const ColInput = (props: TColInputProps) => {
	const { label, colProps = {}, hidden, messages, ...rest } = props;

	return (
		<Col
			{...colProps}
			hidden={hidden}
		>
			<FormikControl
				label={label}
				messages={messages}
			>
				<FormikControl.Input {...rest} />
			</FormikControl>
		</Col>
	);
};
