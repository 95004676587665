import { isFunction } from 'lodash';
import { useEffect, useRef } from 'react';

export const useEventListener = <E extends Event = Event>(
	eventName: string,
	handler: (e: E) => void,
	element: Window | HTMLElement | null = window,
) => {
	// Create a ref that stores handler
	const savedHandler = useRef<((e: E) => void) | null>();

	// Update ref.current value if handler changes.
	// This allows our effect below to always get latest handler ...
	// ... without us needing to pass it in effect deps array ...
	// ... and potentially cause effect to re-run every render.
	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(
		() => {
			// Make sure element supports addEventListener
			// On
			const isSupported = isFunction(element?.addEventListener);
			if (!isSupported) {
				return;
			}

			// Create event listener that calls handler function stored in ref
			const eventListener: (e: E) => void = (event) => {
				savedHandler.current?.(event);
			};

			// Add event listener
			// @ts-ignore
			element.addEventListener(eventName, eventListener);

			// Remove event listener on cleanup
			return () => {
				// @ts-ignore
				element.removeEventListener(eventName, eventListener);
			};
		},
		[eventName, element], // Re-run if eventName or element changes
	);
};
