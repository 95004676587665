export default {
	comparativeFeatures: {
		title: 'Nuove soluzioni Avast Business',
		availableAsAddon: 'Disponibile come componente aggiuntivo',
		equivalentLabel: 'Più valore. Stesso prezzo.',
		action: 'Vedi i pacchetti',
		message:
			'Abbiamo nuovi pacchetti di sicurezza che offrono più valore ai tuoi clienti a parità di prezzo. Aiuta i tuoi clienti a scegliere il giusto livello di protezione per la loro attività.',
		price: {
			from: 'Da',
			units: '{{unitType}}',
			units_other: '{{count}} {{unitType}}',
		},
		startShopping: 'Inizia a fare acquisti',
		windowsOnly: 'Non disponibile per MacOS',
	},
	refunds: {
		entity: {
			date: 'Data di rimborso',
			initiatedDate: 'Data di inizio rimborso',
			itemId: 'ID linea',
			orderLineItemId: 'Numero linea d’ordine',
			type: 'Tipo di rimborso',
			reason: 'Motivo del rimborso',
			reasonNote: 'Commento',
			refundableAmount: 'Disponibile per il rimborso',
			refundAmount: 'Importo rimborso',
			totalRefunded: 'Totale rimborsato',
		},
		initiate: {
			title: 'Avvia rimborso per l’ordine n. {{id}}',
			submit: 'Invia richiesta...',
			process: {
				loading: 'Invio richiesta di rimborso...',
				success: 'La richiesta di rimborso è stata inviata.',
				error: 'Impossibile inviare la richiesta di rimborso.',
			},
			message: {
				ORDER_REFUND:
					'Rimborsa per intero l’importo dell’ordine o richiedi un rimborso parziale dell’ordine inserendo l’importo desiderato (fino all’importo massimo disponibile).',
				LINE_REFUND:
					'Seleziona i prodotti che vuoi rimborsare. Rimborsa per intero l’importo della linea d’ordine o richiedi un rimborso parziale della linea d’ordine inserendo l’importo desiderato (fino all’importo massimo disponibile).',
				TAX_REFUND:
					'Selezionando questa opzione, l’ordine viene rimborsato per intero e viene generata automaticamente una nuova fattura senza imposte.',
				KEY_REFUND:
					'Seleziona la chiave che vuoi rimborsare. Rimborsa l’intero importo o richiedi un rimborso parziale inserendo l’importo desiderato (fino all’importo massimo disponibile).',
			},
		},
		view: {
			title: 'Rimborsi per l’ordine n. {{id}}',
		},
		error: {
			noRefundTypeEnabled: 'Nessun tipo di rimborso consentito per questo ordine.',
			refundableAmount: 'Impossibile caricare le informazioni sui rimborsi degli ordini.',
			refundedAmount: 'Impossibile caricare l’importo totale rimborsato.',
		},
		license: {
			noSelectedLicenses: 'Nessuna licenza selezionata. Usa il campo qui sopra per cercare le licenze.',
			search: {
				title: 'Inserisci una chiave per cercare una licenza',
				searchFailure:
					'La chiave {{licenseKey}} non corrisponde a nessuna delle licenze disponibili per questo ordine.',
				refundableAmountFailure: 'Impossibile caricare un importo rimborsabile per la chiave {{licenseKey}}.',
				success: '{{licenseKey}} aggiunta al modulo di rimborso.',
				loadingLicenses: 'Caricamento licenze...',
				loadingRefundableAmount: 'Caricamento importo rimborsabile...',
			},
			select: {
				title: 'Seleziona una licenza da rimborsare',
			},
		},
	},
	appMessages: {
		taxExemptionCertificate: {
			title:
				'I certificati caricati tramite OMS sono applicabili sono agli ordini con carta di credito in cui il fornitore è Digital River Inc.',
			contentTop:
				'Dall’<strong>1 febbraio 2023</strong>, il fornitore per gli acquisti basati su linee di credito (incluso il metodo per il pagamento del saldo di chiusura, se applicabile) non sarà più Digital River Inc., ma Gen Digital Inc. Per assicurarti che gli acquisti basati su linee di credito continuino a essere soggetti a esenzione fiscale, <strong>invia un certificato di esenzione fiscale emesso per Gen Digital Inc.</strong> al nostro dipartimento fiscale all’indirizzo <0>{{email}}</0>.',
			contentBottom: 'Grazie per la cooperazione.',
		},
		flexibleBillingAvailable: {
			title: 'La fatturazione flessibile è ora disponibile in Business Hub',
			content:
				'I fornitori di servizi gestiti (MSP) che utilizzano la versione per partner multi-azienda di Business Hub dispongono ora di un’opzione “a consumo”.<br/>Questo contribuisce a ottimizzare flessibilità, scalabilità e redditività per gli MSP, consentendo loro di utilizzare le soluzioni Avast Business nel modo che meglio si allinea con le loro transazioni con i clienti finali.',
			action: 'Scopri di più',
		},
	},
	widgets: {
		action: {
			addWidget: 'Aggiungi widget',
			editWidget: 'Modifica widget',
			editWidgets: 'Modifica widget',
		},
		common: {
			columnPlaceholder: 'Aggiungi nuovo widget o sposta widget...',
			savingLoader: 'Salvataggio delle modifiche apportare al widget...',
			rowTitle: 'Riga ({{count}} colonna)',
			rowTitle_other: 'Riga ({{count}} colonne)',
			changesSaved: 'Le modifiche al widget sono state salvate',
			oneColumnRow: 'Riga a una colonna',
			twoColumnsRow: 'Riga a due colonne',
		},
		config: {
			type: 'Tipo',
			ownName: 'Nome',
			ownNamePlaceholder: 'Nome widget',
			columns: 'Colonne visibili',
			columnsPlaceholder: 'Seleziona almeno una colonna',
		},
		setting: {
			days: 'Giorni',
			limit: 'N. max righe',
		},
	},
	locale: {
		language: {
			saving: 'Modifica della lingua di sistema',
			success: 'La lingua di sistema è stata modificata.',
			confirmation: {
				title: 'Modifica lingua di sistema',
				message:
					'La modifica delle impostazioni della lingua dell’account influirà anche sulla lingua utilizzata nelle fatture e nelle e-mail ricevute da Avast. Applicare la modifica?',
			},
		},
		format: {
			saving: 'Modifica dell’impostazione di formato di data e numeri',
			success: 'Il formato di data e numeri è stato modificato.',
		},
	},
};
