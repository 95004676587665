import { useCallback } from 'react';
import type { Location } from '@remix-run/router';
import { Action } from '@remix-run/router';
import { isEqual, last, nth } from 'lodash';
import { TLocationChange, useLocationChange } from 'js/hooks/useLocationChange';
import { getReducedLocation } from 'js/utils/app';

export type TUsePrevLocationListener = {
	resolveByPathname(pathname: string, index: number): Location | null;
};

let currentLocation: Location | null = null;
const prevLocations: (Location | null)[] = [];

export const usePrevLocationListener = (): TUsePrevLocationListener => {
	const onLocationChange: TLocationChange<{ back?: boolean }> = useCallback((location) => {
		const lastLocation = last(prevLocations);
		let action: Action = isEqual(getReducedLocation(location), getReducedLocation(lastLocation))
			? Action.Pop
			: Action.Push;

		// Programmatically back
		if (location.state?.back === true && lastLocation?.pathname === location.pathname) {
			action = Action.Pop;
		}

		switch (action) {
			case 'POP':
				prevLocations.pop();
				break;
			default:
				if (location.pathname !== currentLocation?.pathname) {
					currentLocation && prevLocations.push(currentLocation);
				}
				break;
		}
		currentLocation = location;
	}, []);
	useLocationChange(onLocationChange);

	return {
		resolveByPathname(pathname, index = 1) {
			const prevLocation = nth(prevLocations, 0 - index);
			if (prevLocation?.pathname === pathname) {
				return prevLocation;
			}
			return null;
		},
	};
};
