import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum MarketSegmentEnum {
	BUSINESS = 'BUSINESS',
	CONSUMER = 'CONSUMER',
}

export const marketSegmentEnumUtils = generateEnumUtils<MarketSegmentEnum>(MarketSegmentEnum, {
	translatePath: 'enums:purchase.marketSegment',
});
