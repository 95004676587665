export default {
	common: {
		forecastedSpend: 'Spesa prevista',
		currentSpend: 'Spesa corrente',
		maxSpend: 'Spesa massima',
		limit: 'Limite',
		noLimit: 'Nessun limite',
		searchDistributionPartner: 'Trova il partner di distribuzione cercando per nome',
		unlimited: 'Illimitato',
		unlimitedNote:
			'La spesa massima può superare il limite di credito se non vengono fissati dei limiti di utilizzo. In tal caso, i prodotti del partner saranno disattivati fino a quando il limite di credito non sarà aumentato o il saldo sarà ridotto.',
		valueBefore: 'Prima',
		valueAfter: 'Dopo aver salvato le modifiche',
	},
	page: {
		title: 'Fatturazione flessibile',
	},
	actions: {
		edit: 'Gestisci prodotti',
		viewOrders: 'Visualizza ordini',
		enableProducts: 'Attiva prodotti',
		enableAllProducts: 'Attiva tutti i prodotti',
		disableAllProducts: 'Disattiva tutti i prodotti',
	},
	tab: {
		distributionPartners: 'Panoramica dei partner',
		overview: 'Panoramica',
		reports: 'Report',
	},
	modal: {
		confirmation: 'Vuoi procedere?',
		message: 'Le modifiche che stai salvando possono influire sul tuo credito. Continuare?',
		apply: 'Applicazione delle modifiche in corso',
		success: 'Le modifiche sono state applicate correttamente',
	},
	entity: {
		usageLimit: 'Limite utilizzo',
		yourPrice: 'Prezzo',
	},
	invite: {
		info: 'Questo partner non ha ancora creato un account Business Hub. Puoi impostare la Fatturazione flessibile per il partner, ma questa non sarà operativa fino a quando il suo account Business Hub non verrà creato.',
		action: {
			send: 'Invia invito',
			resend: 'Invia un altro invito',
		},
		lastSent: 'L’ultimo invito è stato inviato a {{email}} il giorno {{date}}.',
		processing: 'Stiamo inviando l’invito al partner di distribuzione',
		success: 'L’invito è stato inviato correttamente',
		description:
			'Invia un invito via e-mail per la registrazione a Business Hub. Questo account sarà il primo account di amministratore nell’Hub (in seguito potrai aggiungere altri amministratori). Una volta registrato, il nuovo account Business Hub sarà collegato al record del partner.',
		form: {
			create: {
				label: 'Crea un Business Hub quando salvi questo partner',
				description:
					'Invia un invito via e-mail per la registrazione a Business Hub una volta salvato il modulo. Questo account sarà il primo account di amministratore nell’Hub (in seguito potrai aggiungere altri amministratori). Una volta registrato, il nuovo account Business Hub sarà collegato al record del partner.',
			},
			differentEmail: {
				partner: 'Invia l’invito all’e-mail registrata',
				other: 'Invia l’invito a un’altra e-mail',
			},
		},
		modal: {
			title: 'Crea Business Hub per {{name}}',
		},
	},
	tooltips: {
		unlimitedMaxSpend:
			'La spesa massima può superare il limite di credito se non vengono fissati dei limiti di utilizzo. In questi casi, i prodotti del partner saranno disattivati fino a quando il limite di credito non sarà aumentato o il saldo sarà ridotto.',
		forecastedSpend: {
			distributor:
				'L’importo che verrà speso nel periodo di fatturazione corrente se l’uso del prodotto di tutti i partner rimane invariato.',
			partner:
				'L’importo che verrà speso nel periodo di fatturazione corrente se l’uso del prodotto di questo partner rimane invariato.',
		},
		currentSpend: {
			distributor: 'L’importo già speso nel periodo di fatturazione corrente per i prodotti attivi di tutti i partner.',
			partner: 'L’importo già speso nel periodo di fatturazione corrente per i prodotti attivi di questo partner.',
		},
		maxSpend: {
			distributor:
				"L'importo massimo che può essere addebitato se tutti i prodotti attivi di tutti i partner raggiungono i limiti di utilizzo.",
			partner:
				'L’importo massimo che può essere addebitato se tutti i prodotti attivi di questo partner raggiungono i limiti di utilizzo.',
		},
	},
	error: {
		usageLimit: {
			title: 'Le modifiche non possono essere salvate in questo momento.',
			message:
				'I limiti di utilizzo per i prodotti che stai cercando di impostare sono inferiori al totale dei limiti di utilizzo che il tuo partner ha stabilito nel suo Business Hub per i suoi clienti.',
			product:
				'Il totale dei limiti per <strong>{{product}}</strong> è attualmente impostato su <strong>{{limit}}</strong>.',
			note: 'Ti consigliamo di contattare il tuo partner per discutere dei limiti di utilizzo.<br />Se hai bisogno di assistenza, contatta il nostro team di supporto.',
		},
	},
};
