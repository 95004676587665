import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum PartnerTypeEnum {
	DISTRIBUTOR = 'DISTRIBUTOR',
	RESELLER = 'RESELLER',
	USER = 'USER', // Company sub account
	INTERNAL_COMPANY = 'INTERNAL_COMPANY', // Avast
}

export const partnerTypeEnumUtils = generateEnumUtils<PartnerTypeEnum>(PartnerTypeEnum, {
	translatePath: 'enums:partner.type',
});
