import React, { FunctionComponent } from 'react';
import { HeaderLogo } from 'js/layouts/components/HeaderLogo';
import { Col, Row } from 'js/components/atoms/Row';
import { Outlet } from 'react-router-dom';

export const DefaultLayout: FunctionComponent = () => (
	<div className="fullHeight-layout">
		<div className="header-app justify-content-end">
			<HeaderLogo collapsedLogo={false} />
		</div>

		<div className="container">
			<Row justify="center">
				<Col
					xl={6}
					lg={9}
					className="text-center"
				>
					<Outlet />
				</Col>
			</Row>
		</div>
	</div>
);
