import type { TSelectOptions } from '@avast/react-ui-components';
import { FormControl, FormControlRadio } from '@avast/react-ui-components';
import React from 'react';

type TDateRangeTypeFilterProps<T extends string> = {
	values: TSelectOptions<T>;
	value: T;
	onChange: (value: T) => void;
	name: string;
};

export const RangeDatepickerTypeFilter = <T extends string>(props: TDateRangeTypeFilterProps<T>) => {
	const { value, values, onChange, name } = props;
	return (
		<div className="date-type">
			<FormControlRadio inline>
				{values.map((radio) => (
					<FormControl.Radio
						key={radio.value}
						name={name}
						testId={`type-${radio.value}`}
						checked={radio.value === value}
						value={radio.value}
						label={radio.label}
						onChange={(data) => onChange(data as T)}
					/>
				))}
			</FormControlRadio>
		</div>
	);
};
