export default {
	common: {
		partnerPortal: 'Accedi all’OMS tramite il portale per i partner',
		forgetPassword: 'Password dimenticata?',
		returnToPartnerPortal: 'Torna al portale per i partner',
		successNewPassword: 'La modifica della nuova password è andata a buon fine.',
		backToLogin: 'Torna alla pagina di accesso',
		loginLoading: 'Accedi all’OMS',
	},
	commonText: {
		loginForAvastOnly: 'Solo per uso interno di Avast.',
		assistance: 'Per ricevere assistenza, contatta il tuo account manager Avast Business.',
		signature: 'Grazie, <br />Avast Business',
	},
	form: {
		password: 'Password',
		submit: 'Vai all’OMS',
		email: 'Indirizzo e-mail',
	},
	page: {
		login: {
			title: 'Accedi',
		},
		logout: {
			loading: 'Esci dall’OMS...',
		},
		sso: {
			title: 'Accesso SSO',
			loading: 'Accedi all’OMS...',
		},
		loginAsAffiliate: {
			title: 'Accedi come affiliato',
			loading: 'Accedi come affiliato all’OMS...',
		},
		orderHold: {
			title: 'Una o più fatture dell’azienda sono scadute da {{count}} o più giorni.',
			text: 'Per tale motivo, l’account aziendale è stato sospeso e non puoi effettuare nuovi acquisti a credito.',
			textMore:
				'Maggiori dettagli sugli ordini non pagati sono disponibili nella sezione Ordini e sono stati condivisi via e-mail con il contatto principale dell’azienda.',
			action: 'Visualizza ordini non pagati',
		},
		registrationIncomplete: {
			title: 'Impossibile completare l’operazione',
			text: 'Il processo di registrazione all’OMS non è ancora stato completato da un utente primario (Admin) dell’azienda. Una volta completata l’operazione, sarà possibile effettuare l’accesso.',
		},
		terms: {
			title: 'Nuovi termini e condizioni',
			text: 'I nuovi termini e condizioni devono essere accettati da un utente primario (Admin) dell’azienda.',
		},
		inactive: {
			title: 'Impossibile completare l’operazione',
			text: 'L’accesso è stato negato. Contatta l’amministratore dell’account per maggiori dettagli.',
		},
		maintenance: {
			scheduled: {
				title: 'Manutenzione programmata',
				text: "Il sistema di gestione degli ordini è in manutenzione ed è temporaneamente non disponibile. Ci scusiamo per l'imprevisto.",
				end: 'Fine manutenzione programmata',
			},
		},
		passwordReset: {
			title: 'Reimposta la password',
			info: 'Reimposta la password fornendo l’indirizzo e-mail associato al tuo account.',
			success: 'Il link per la reimpostazione della password è stato inviato alla tua casella di posta elettronica.',
		},
		newPassword: {
			title: 'Imposta nuova password',
			form: {
				password: 'Nuova password',
				confirm: 'Conferma password',
			},
			info: 'Immetti una nuova password.',
			validation: {
				digit: 'La password deve contenere almeno un numero',
				symbol: 'La password deve contenere almeno un simbolo',
				length: 'La password deve essere composta da almeno 8 caratteri',
				uppercase: 'La password deve contenere almeno una lettera maiuscola',
				lowercase: 'La password deve contenere almeno una lettera minuscola',
				notSame: 'La password di conferma non corrisponde',
				trim: 'La password non può iniziare o terminare con uno spazio.',
			},
		},
	},
	state: {
		invalidCredentials: 'Nome utente o password errati, riprova.',
		invalidSso: 'Impossibile effettuare l’accesso, la sessione è scaduta. Effettua nuovamente l’accesso.',
		invalidAuthorizationCode: 'Impossibile effettuare l’accesso con questo codice di autorizzazione.',
		sessionExpired: 'La sessione è scaduta, è necessario effettuare l’accesso.',
	},
	error: {
		unableToResetPassword: 'Al momento non è possibile reimpostare la password.',
	},
};
