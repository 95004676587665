import { TApiErrorResolve } from 'types/api';
import { apiErrorResolveError } from 'js/utils/apiError';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { API_ERROR_SUBMIT_ORDER } from 'module/purchase/constants';
import i18n from 'i18next';

export const submitOrderApiError: TApiErrorResolve = (err) => {
	const messages = apiErrorResolveError(err, API_ERROR_SUBMIT_ORDER);
	if (messages.length === 0) {
		messages.push(i18n.t(purchaseConfig.trPrefix('error.submitOrder.general')));
	}

	return {
		title: i18n.t(purchaseConfig.trPrefix('error.submitOrder.title')),
		messages,
	};
};

export const pricingApiError: TApiErrorResolve = () => ({
	title: i18n.t(purchaseConfig.trPrefix('error.pricing.title')),
});
