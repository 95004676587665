import { IEntityOrder } from 'module/orders';
import { IEntityLicense } from 'module/licenses';
import { getLicenseExternalIds, isLicenseCancellable } from 'module/licenses/utils/common';
import { useApiOrder } from 'module/orders/hooks/useApiOrders';
import { useOrderLicensesAreCancellable } from 'module/licenses/hooks/useOrderLicensesAreCancellable';
import { useAuthContext } from 'js/contexts';

type TLicenseIsCancellable = {
	isCancellable: boolean;
	licenseOrder: IEntityOrder | null;
};

export const useLicenseIsCancellable = (license: IEntityLicense): TLicenseIsCancellable => {
	const { isRoleSalesOperations } = useAuthContext();
	const { orderId } = getLicenseExternalIds(license);

	// Get license order
	const isCancellable = isRoleSalesOperations && Boolean(orderId) && isLicenseCancellable(license);
	const { data: licenseOrder } = useApiOrder({ id: orderId, queryConfig: { enabled: isCancellable } });
	const orderLicensesAreCancellable = useOrderLicensesAreCancellable(licenseOrder);

	return {
		isCancellable: isCancellable && orderLicensesAreCancellable,
		licenseOrder,
	};
};
