import { IEntityPartnerDetail, IEntityPartnerProperties, IPartnerGroups } from 'module/partners';
import { isAllowedByGroups } from 'js/utils/security';
import { IAuthGuards } from 'types';
import { get } from 'lodash';
import { useAuthContext } from 'js/contexts';

const arePartnerPropertiesEqual = (partner: IEntityPartnerDetail, properties: Partial<IEntityPartnerProperties>) => {
	return Object.keys(properties).reduce<boolean>((acc, key) => {
		const desiredValue = get(properties, key);
		const partnerValue = get(partner, key);
		return acc && desiredValue === partnerValue;
	}, true);
};

const useAuthAffiliateGroupsGuard = (groups: Partial<IPartnerGroups> | undefined): boolean => {
	const { isGroupSales, authPartnerGroups } = useAuthContext();
	if (!isGroupSales) {
		return true;
	}

	return isAllowedByGroups(authPartnerGroups, groups);
};

const useAuthPartnerGroupsGuard = (
	groups: Partial<IPartnerGroups> | undefined,
	defaultValue: boolean = true,
): boolean => {
	const { isGroupPartner, authCompanyGroups } = useAuthContext();
	return isGroupPartner ? isAllowedByGroups(authCompanyGroups, groups) : defaultValue;
};

const useAuthAffiliatePropertiesGuard = (properties: Partial<IEntityPartnerProperties> | undefined): boolean => {
	const { isGroupPartner, authCompany } = useAuthContext();

	if (isGroupPartner || !authCompany || !properties) {
		return true;
	}

	return arePartnerPropertiesEqual(authCompany, properties);
};

const useAuthPartnerPropertiesGuard = (properties: Partial<IEntityPartnerProperties> | undefined): boolean => {
	const { isGroupPartner, authCompany } = useAuthContext();

	if (!isGroupPartner || !authCompany || !properties) {
		return true;
	}
	return arePartnerPropertiesEqual(authCompany, properties);
};

export const useAuthGuards = (guards?: IAuthGuards, defaultValue: boolean = true): boolean => {
	const isAffiliateAllowedByGroups = useAuthAffiliateGroupsGuard(guards?.affiliateGroups);
	const isPartnerAllowedByGroups = useAuthPartnerGroupsGuard(guards?.partnerGroups, defaultValue);

	const isAffiliateAllowedByProperties = useAuthAffiliatePropertiesGuard(guards?.affiliateProperties);
	const isPartnerAllowedByProperties = useAuthPartnerPropertiesGuard(guards?.partnerProperties);

	return (
		isPartnerAllowedByGroups &&
		isAffiliateAllowedByGroups &&
		isAffiliateAllowedByProperties &&
		isPartnerAllowedByProperties
	);
};
