export default {
	actions: {
		create: '新しい販売パートナーを作成',
		edit: '販売パートナーを編集',
		exportAll: 'すべての販売パートナーをエクスポート',
		form: {
			create: '新しい販売パートナーとして保存',
			update: '変更を保存',
		},
	},
	error: {
		createDistributionPartner: '販売パートナーを作成できません。',
		updateDistributionPartner: '販売パートナーを更新できません。',
	},
	page: {
		create: {
			title: '新しい販売パートナー',
			success: '新しい販売パートナーが正常に作成されました。',
		},
		update: {
			success: '販売パートナーが正常に更新されました。',
		},
	},
	select: {
		title: '販売パートナーを選択',
		button: {
			label: '販売パートナー',
			placeholder: '販売パートナーを選択',
		},
		filter: {
			placeholder: 'メール アドレス、名前、会社で検索',
		},
	},
};
