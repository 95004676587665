export default {
	steps: {
		billableParty: {
			title: 'Parte a la que se puede facturar',
			virtualPartnerTitle: 'Nueva oportunidad',
		},
		product: {
			title: 'Producto',
			isRenewal: '¿Es una renovación?',
		},
		discounts: {
			title: 'Descuentos',
			discretionary: 'Descuento a discreción',
			special: 'Descuento especial',
			tier: 'Descuento por nivel',
			segment: 'Descuento por segmento',
		},
		price: {
			title: 'Precio',
			appliedPromotions: 'Promociones aplicadas',
		},
	},
	message: {
		countryCodeNotSelected: 'Seleccione un código de país primero',
		requiredStepsNotFulfilled: 'Seleccione una parte a la que se pueda facturar y un producto para ver los precios',
	},
	goalSeekedPrice: {
		title: 'Precio objetivo por unidad',
		originalPrice: 'Precio original por unidad',
		finalPrice: 'Precio final por unidad',
	},
};
