import React from 'react';
import { useTranslation } from 'react-i18next';
import { IEntityOrder } from 'module/orders';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { Modal, UiTable } from '@avast/react-ui-components';
import { RefundedAmount } from 'submodule/refunds/view/RefundedAmount';
import { useGtmAction } from 'js/analytics/gtm';
import { useApiRefunds } from 'submodule/refunds/hooks/useApiRefunds';
import { IEntityRefund } from 'submodule/refunds';
import { useRefundListColumns } from 'submodule/refunds/hooks';
import { isString } from 'lodash';

export const RefundsModalContent = () => {
	const [t] = useTranslation('submodules');
	const { data: order } = usePageDetailContext<IEntityOrder>();
	const { currency, chargeOrderId } = order;
	const columns = useRefundListColumns();
	useGtmAction('View Refunds');

	const { data: refunds, query: refundsQuery } = useApiRefunds({
		filter: {
			chargeOrderId: chargeOrderId!,
		},
		queryConfig: {
			enabled: isString(chargeOrderId),
		},
	});

	return (
		<>
			<Modal.Header>{t('refunds.view.title', { id: order.id })}</Modal.Header>
			<Modal.Body>
				<div className="mb-3">
					<RefundedAmount
						currencyCode={currency}
						chargeOrderId={chargeOrderId}
					/>
				</div>
				<UiTable<IEntityRefund>
					columns={columns}
					className="table-data"
					data={refunds || []}
					enablePagination
					enableSorting
					initialState={{ pagination: { pageSize: 8 } }}
					meta={{
						loading: refundsQuery.isFetching,
						isScrollable: true,
						nowrapCell: true,
						pageSizeList: false,
						paginationComponent: 'Full',
					}}
					testId="refunds"
				/>
			</Modal.Body>
			<Modal.Footer cancelButton={{ children: t('common:actions.close') }} />
		</>
	);
};
