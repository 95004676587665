import { useApiCountry } from 'js/entities/country/useApiCountry';
import { useMemo } from 'react';
import { IEntityCurrency } from 'types/entity';

export const useCountrySupportedCurrencies = (countryCode?: string | null, enabled: boolean = true) => {
	const defaultValue: IEntityCurrency[] = useMemo(() => [], []);
	const { data: country, query } = useApiCountry({
		filter: { countryCode: countryCode! },
		queryConfig: { enabled: Boolean(countryCode) && enabled },
	});

	return {
		supportedCurrencies: country?.supportedCurrencies || defaultValue,
		query,
	};
};
