import { DataTable } from 'js/components/molecules/DataTable';
import { DetailList, TDetailItem } from 'module/promotions/components/DetailList';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { useApiPromotionProductGroupList } from 'module/promotions/hooks/useApiPromotion';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { IEntityPromotion } from 'module/promotions';
import { getProductGroupCodeFromSku, normalizeSku } from 'module/orders/utils/common';
import { TProductGroupCode } from 'types';

type TProductDetailItem = {
	name: string;
	groupCode: TProductGroupCode;
	skuList: string[];
};

export const ProductsTableRow = ({ maxShownItems = 4 }: { maxShownItems?: number }) => {
	const { data: promotion } = usePageDetailContext<IEntityPromotion>();
	const [t] = useTranslation(promotionsConfig.trNamespace);
	const {
		data: productGroups,
		query: { isFetching },
	} = useApiPromotionProductGroupList();

	const values = useMemo((): TDetailItem[] => {
		const productValues: TProductDetailItem[] = [];
		promotion.skuList?.forEach((sku) => {
			const groupCode = getProductGroupCodeFromSku(normalizeSku(sku));
			const group = productGroups.find((group) => group.code === groupCode);
			if (!group) {
				return;
			}

			const productValue = productValues.find((value) => value.groupCode === groupCode);
			if (productValue) {
				productValue.skuList.push(sku);
			} else {
				productValues.push({
					groupCode,
					name: group.name,
					skuList: [sku],
				});
			}
		});

		return productValues.map((productValue) => ({
			name: productValue.name,
			description: productValue.skuList.join(', '),
		}));
	}, [productGroups, promotion.skuList]);

	return (
		<DataTable.Tr
			name={t('entity.product', { count: values.length })}
			isLoading={isFetching}
			highlighted
		>
			<DetailList
				title={t('entity.product', { count: values.length })}
				defaultValue={t('placeholder.products')}
				values={values}
				maxShownItems={maxShownItems}
				variant="LINES"
			/>
		</DataTable.Tr>
	);
};
