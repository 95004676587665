import React, { ReactElement } from 'react';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { usePageDetailContext } from 'js/contexts';
import { IEntityOrder } from 'module/orders';
import { PartnerDetail } from 'module/partners/components';

export const PartnerTab = (): ReactElement => {
	const {
		data: { partner },
	} = usePageDetailContext<IEntityOrder>();

	return (
		<DefaultContainer>
			{/* Note: In Webix it is accordion Distributor */}
			<PartnerDetail partner={partner} />
		</DefaultContainer>
	);
};
