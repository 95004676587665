export default {
	appName: '訂單管理系統',
	menu: {
		closingBalance: '期末結餘',
		creditLimit: '信用額度限制',
		creditStatus: '信用狀態',
		customers: '客戶',
		dashboard: '首頁',
		distributionPartners: '經銷合作夥伴',
		licenses: '授權',
		orders: '訂單',
		quotes: '報價',
		purchase: '新品全新購買',
		promotions: '促銷活動',
		account: '我的帳戶',
		help: '說明 & 資源',
		feedback: '分享意見回應',
		retail: '零售代碼',
		priceCalculator: '價格計算器',
		flexibleBilling: '彈性計費',
	},
	help: {
		main: {
			title: '說明 & 資源',
			guide: {
				title: '以下是 OMS 中的新功能！',
				text: '讓我們帶您瞭解最重要的幾項變更。',
				action: '開始導覽',
			},
			videos: {
				title: '觀看使用方法影片',
			},
		},
		guide: {
			paginator: '{{current}}/{{total}}',
			slides: {
				welcome: {
					title: '歡迎使用全新的 OMS',
					text: [
						'OMS 已經換上全新風貌！看看我們的全新設計、方便使用的介面、改進更好的導覽和流暢的效能，全是為了讓您享受最好的購買體驗。看看首頁上的 Widget，它可以讓您快速存取最近的訂單、報價、即將到期的授權等各種內容！',
					],
				},
				pricing: {
					title: '動態定價',
					text: [
						'您不再需要等待訂單重新定價，也無需將產品加入購物車即可查看價格。現在，價格就會直接顯示在產品目錄中！',
						'當您調整產品的套數或數量時，價格會自動重新計算，讓您隨時掌握最新資訊。當您在購物車中新增或刪除產品時，訂單價值價格也會自動重新計算。',
					],
				},
				quickFilter: {
					title: '輕鬆篩選及搜尋消費者性產品和企業產品',
					text: [
						'我們新增了一個快速篩選器，方便您輕鬆顯示僅限消費者性或僅限企業的產品。如果您要尋找特定產品，只需輸入產品名稱或產品代碼，清單就會根據您輸入的內容自動更新。',
					],
				},
				licenseOperations: {
					title: '續購續訂更簡便',
					text: [
						'我們將新品全新購買和授權操作（例如續購續訂或升級）分開，因此您在查看授權時可以直接輕鬆查看所有可用的授權操作。只需要在「授權清單」或「授權詳細資料」頁面中按一下「授權操作」按鈕，就是這麼簡單。',
					],
				},
				shoppingCart: {
					title: '輕鬆存取購物車',
					text: [
						'我們新增了購物車圖示，方便您隨時輕鬆存取購物車並繼續下訂。',
						'您可以輕鬆將新品全新購買與授權操作（例如續購續訂）合併到一張訂單中。',
					],
				},
				quickAccess: {
					title: '快速存取您需要的所有記錄',
					text: [
						'我們在 OMS 中的很多位置增設了快速按鈕和連結，方便您輕鬆存取與您正在查看之記錄相關的資訊。使用「客戶詳細資料」頁面中的「檢視授權」和「檢視訂單」按鈕。',
						'在「授權詳細資料」頁面中，按一下反白顯示的連結，即可開啟相關訂單或查看客戶詳細資料。',
					],
				},
				quotes: {
					title: '輕鬆存取報價',
					text: [
						'針對您從客戶經理那裡收到的報價，我們建立了單獨的功能表項目，方便您更輕鬆地找到它們。您也可以在訂單中找到這些報價，方便查閱。',
					],
				},
				finalStep: {
					title: '一切就是這麼簡單！',
					text: [
						'我們希望重新設計的使用者介面、新功能和效能提升，讓您享受更加愉快的 OMS 使用體驗。',
						'請按一下「分享意見回應」功能表項目，提供您的意見回應和改進建議。',
						'請注意，您可以隨時按一下功能表中的「說明 & 資源」來檢閱本簡介指南。',
					],
				},
			},
		},
		videos: {
			title: '使用方法影片',
			list: {
				navigations: '在 OMS 中導覽',
				newPurchase: '建立新採購單',
				renewal: '建立續購續訂訂單',
				quotes: '接收客戶經理的報價',
			},
		},
	},
};
