export default {
	common: {
		partnerPortal: 'Connexion au système de gestion des commandes via le Portail des partenaires',
		forgetPassword: 'Mot de passe oublié ?',
		returnToPartnerPortal: 'Retour au Portail des partenaires',
		successNewPassword: 'Votre nouveau mot de passe a été enregistré.',
		backToLogin: 'Retour à la connexion',
		loginLoading: 'Connexion au système de gestion des commandes',
	},
	commonText: {
		loginForAvastOnly: 'Réservé à l’usage interne d’Avast.',
		assistance: 'Si vous avez besoin d’aide, contactez votre responsable de compte Avast Business.',
		signature: 'Nous vous remercions, <br />Avast Business',
	},
	form: {
		password: 'Mot de passe',
		submit: 'Continuer vers le système de gestion des commandes',
		email: 'E-mail',
	},
	page: {
		login: {
			title: 'Connexion',
		},
		logout: {
			loading: 'Déconnexion du système de gestion des commandes...',
		},
		sso: {
			title: 'Connexion unique',
			loading: 'Connexion au système de gestion des commandes...',
		},
		loginAsAffiliate: {
			title: 'Connexion en tant qu’affilié',
			loading: 'Connexion au système de gestion des commandes en tant qu’affilié...',
		},
		orderHold: {
			title: 'Votre entreprise est associée à des factures dont la date d’échéance est dépassée de {{count}} ou plus.',
			text: 'Pour cette raison, le compte de votre entreprise a été suspendu. Vous ne pouvez donc pas effectuer de nouveaux achats à crédit.',
			textMore:
				'Vous trouverez davantage d’informations sur les commandes impayées dans la section Commandes. Elles ont également été partagées par e-mail avec le contact principal au sein de votre entreprise.',
			action: 'Afficher les commandes impayées',
		},
		registrationIncomplete: {
			title: 'Impossible d’effectuer l’opération',
			text: 'La procédure d’inscription au système de gestion des commandes n’a pas encore été effectuée par un utilisateur principal (administrateur) de votre organisation. Une fois cette étape franchie, votre accès sera disponible.',
		},
		terms: {
			title: 'Nouvelles conditions générales',
			text: 'Les nouvelles conditions générales doivent être acceptés par un utilisateur principal (administrateur) de votre organisation.',
		},
		inactive: {
			title: 'Impossible d’effectuer l’opération',
			text: 'L’accès vous a été refusé. Contactez l’administrateur de votre compte pour en savoir plus.',
		},
		maintenance: {
			scheduled: {
				title: 'Maintenance programmée',
				text: 'Le système de gestion des commandes fait actuellement l’objet d’une maintenance programmée et est temporairement indisponible. Nous vous prions de nous excuser pour ce désagrément.',
				end: 'Fin programmée de la maintenance',
			},
		},
		passwordReset: {
			title: 'Réinitialiser votre mot de passe',
			info: 'Réinitialisez votre mot de passe en indiquant l’adresse e-mail de votre compte.',
			success: 'Un lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail.',
		},
		newPassword: {
			title: 'Configurer un nouveau mot de passe',
			form: {
				password: 'Nouveau mot de passe',
				confirm: 'Confirmer le mot de passe',
			},
			info: 'Saisissez un nouveau mot de passe.',
			validation: {
				digit: 'Le mot de passe doit contenir au moins un chiffre.',
				symbol: 'Le mot de passe doit contenir au moins un symbole.',
				length: 'Le mot de passe doit comporter au moins 8 caractères.',
				uppercase: 'Le mot de passe doit contenir au moins une lettre majuscule.',
				lowercase: 'Le mot de passe doit contenir au moins une lettre minuscule.',
				notSame: 'Le mot de passe de confirmation ne correspond pas.',
				trim: 'Le mot de passe ne peut pas commencer ou se terminer par une espace.',
			},
		},
	},
	state: {
		invalidCredentials: 'Le nom d’utilisateur ou le mot de passe est incorrect, veuillez réessayer.',
		invalidSso: 'Connexion impossible, votre session a expiré. Veuillez vous connecter à nouveau.',
		invalidAuthorizationCode: 'Impossible de vous connecter avec ce code d’autorisation.',
		sessionExpired: 'Votre session a expiré, vous devez vous connecter.',
	},
	error: {
		unableToResetPassword: 'Nous ne sommes pas en mesure de réinitialiser votre mot de passe pour l’instant.',
	},
};
