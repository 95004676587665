import { Trans, useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { FLEXIBLE_BILLING_UNLIMITED_VALUE } from 'module/flexibleBilling/constants';
import React from 'react';

export const UsageLimitApiError = () => {
	const [t] = useTranslation(flexibleBillingConfig.trNamespace);

	// TODO
	const list: { name: string; limit: number }[] = [
		{ name: 'AVG PRO product', limit: 10 },
		{ name: 'AVG PRO product upgrade limit', limit: -1 },
	];

	return (
		<>
			<p>{t('error.usageLimit.message')}</p>
			<ul>
				{list.map((item, index) => (
					<li key={index}>
						{
							<Trans
								t={t}
								i18nKey="error.usageLimit.product"
								tOptions={{
									product: item.name,
									limit: item.limit === FLEXIBLE_BILLING_UNLIMITED_VALUE ? t('common.unlimited') : item.limit,
								}}
							/>
						}
					</li>
				))}
			</ul>
			<p className="mb-0">
				<Trans
					t={t}
					i18nKey="error.usageLimit.note"
				/>
			</p>
		</>
	);
};
