export default {
	tierQualifyingUnits: 'Stufe Qualifizierte Einheiten',
	tier: 'Stufenrabatt',
	srpUnitPrice: 'SRP Einzelpreis',
	customerSegment: 'Kundensegment',
	segmentDiscount: 'Segment-Rabatt',
	srpAfterSegmentDiscount: 'SRP Nach Segment-Rabatt',
	quantityPriced: 'Menge Preis',
	totalPrice: 'Gesamtpreis',
	discretionaryDiscount: 'Diskretionärer-Rabatt',
	unitPriceAfterDiscretionaryDiscount: 'Stückpreis nach Diskretionärer Rabatt',
	partnerDiscount: 'Partnerrabatt',
	unitPriceAfterDiscount: 'Stückpreis nach Rabatt',
	finalDiscountedUnitPrice: 'Endgültiger ermäßigter Stückpreis',
	upgradeCredit: 'Upgrade-Guthaben',
	daysInTerm: 'Tage im Begriff',
	daysToProrate: 'Tage bis zur Prorata',
	prorationRate: 'Prorata-Rate',
	proratedUnitPrice: 'Anteiliger Einzelpreis',
	proratedSrpUnitPrice: 'Anteiliger SRP-Einheitspreis',
	daysInPeriod: 'Tage in der Periode',
	dailySaleUnitPrice: 'Täglicher Verkaufspreis pro Einheit',
	customPricing: 'Individuelle Preisgestaltung',
	quantityBasedPromotions: 'Mengenabhängige Promotionen',
	linePriceUnitPriceAfterQuantityBasedPromotions: 'Zeilenpreis, Stückpreis nach mengenbezogenen Aktionen',
	linePrice: 'Zeilenpreis',
	unitPriceAfterQuantityBasedPromotions: 'Stückpreis nach mengenbezogenen Aktionen',
	percentageOffPromotions: 'Prozentuale Nachlass-Aktionen',
	unitPriceAfterPercentageOffPromotions: 'Stückpreis nach prozentualen Preisnachlässen',
	validFrom: 'Gültig von',
	validTo: 'Gültig bis',
	offQtyOf: 'Aus Menge von',
	amountOff: 'Betrag Aus',
	discountPerUnit: 'Rabatt pro Stück',
	discount: 'Rabatt',
};
