import React, { ReactElement } from 'react';
import { Alert, Modal } from '@avast/react-ui-components';
import { LicenseOperationEnum } from 'module/licenses/enums';
import { ILicenseOperationForm } from 'module/licenses';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { useLicenseOperationAddToOrder } from 'module/licenses/hooks/licenseOperation';
import { Formik } from 'formik';
import { LICENSE_OPERATION_QUANTITY } from 'module/licenses/contants';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ToastSuccess } from 'module/licenses/components/licenseOperation/toast';
import { FormikForm } from 'js/components/formik/FormikForm';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { ModalContent } from 'module/licenses/components/licenseOperation/ModalContent';
import { getFulfillmentValue } from 'module/licenses/utils/common';
import { CurrentLicenseInfo } from './CurrentLicenseInfo';

export type TLicenseOperationModalProps = {
	show: boolean;
	onHide: () => void;
};

export const LicenseOperationModal = (props: TLicenseOperationModalProps): ReactElement => {
	const { show, onHide } = props;
	const [t] = useTranslation(licensesConfig.trNamespace);
	const {
		license,
		isLoading,
		licenseOperationLoadError,
		billableParty,
		areRequiredFieldsFulfilled,
		isEndCustomerBillableParty,
	} = useLicenseOperationContext();
	const licenseOperationAddToOrder = useLicenseOperationAddToOrder();

	return (
		<Formik<ILicenseOperationForm>
			initialValues={{
				quantity: LICENSE_OPERATION_QUANTITY,
				unit: license.product.dimension,
				licenseBillableParty: billableParty,
				variant: null,
				productGroupCode: null,
				unitToIncrease: 1,
				unitToDecrease: 1,
			}}
			enableReinitialize
			validationSchema={Yup.object().shape<Partial<ILicenseOperationForm>>({
				licenseOperation: Yup.string<LicenseOperationEnum>().label(t('licenseOperation.label')).required(),
				licenseBillableParty: Yup.object().shape({
					currencyCode: Yup.string()
						.label(t('common:entity.currency'))
						.when('billablePartyType', {
							is: () => isEndCustomerBillableParty,
							then: Yup.string().required(),
						}),
				}),
			})}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(false);
				if (await licenseOperationAddToOrder(values)) {
					toast.success(<ToastSuccess />);
					onHide();
				}
			}}
		>
			{({ values, submitForm }) => {
				const requiredFieldsFulfilled = areRequiredFieldsFulfilled(values);

				return (
					<Modal
						show={show}
						size={requiredFieldsFulfilled ? 'lg' : 'sm'}
						onHide={onHide}
						testId="licenseOperation"
					>
						<Modal.Header className="pb-0">
							{t('actions.operations')} - {getFulfillmentValue(license)}
						</Modal.Header>
						<Modal.Body className="mt-4">
							<CurrentLicenseInfo />
							<FormikForm testId="licenseOperationForm">
								{(() => {
									if (isLoading) {
										return <LoadingPlaceholder type="BAR" />;
									}

									if (Boolean(licenseOperationLoadError)) {
										return (
											<Alert
												variant="danger"
												caption={licenseOperationLoadError}
											/>
										);
									}

									return <ModalContent />;
								})()}
							</FormikForm>
						</Modal.Body>
						<Modal.Footer
							cancelButton
							onSubmit={() => submitForm()}
							submitButton={{
								children: t('common:actions.confirm'),
								disabled: !requiredFieldsFulfilled || !values.variant,
							}}
						/>
					</Modal>
				);
			}}
		</Formik>
	);
};
