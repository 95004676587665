import React, { ReactElement } from 'react';
import { DetailListModalContent } from 'module/promotions/components/DetailListModalContent';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { noValuePlaceholder } from 'js/utils/app';
import { isString } from 'lodash';

type TDetailListVariant = 'LINES';

type TDetailListProps = {
	values: string[] | TDetailItem[];
	title: string;
	defaultValue: string;
	maxShownItems?: number;
	variant?: TDetailListVariant;
};

export type TDetailItem = {
	name: string;
	description?: string;
};

const renderListItems = (values: TDetailItem[], variant?: TDetailListVariant) => (
	<ul className="my-0 reset">
		{values.map((item, index) => {
			let description = null;
			if (item.description) {
				if (variant === 'LINES') {
					description = (
						<>
							<br />
							<em className="text-gray">{item.description}</em>
						</>
					);
				} else {
					description = <span className="text-gray"> ({item.description})</span>;
				}
			}

			return (
				<li
					key={index}
					className="mb-1"
				>
					{item.name}
					{description}
				</li>
			);
		})}
	</ul>
);

export const DetailList = (props: TDetailListProps): ReactElement | null => {
	const [t] = useTranslation(promotionsConfig.trNamespace);
	const { title, maxShownItems, defaultValue, variant } = props;
	const detailListModalRef = useAsyncModalRef();
	const values: TDetailItem[] =
		props.values?.map((value) => {
			if (isString(value)) {
				return { name: value };
			}
			return value;
		}) || [];

	if (values.length === 0) {
		return noValuePlaceholder(defaultValue);
	}

	if (maxShownItems && values.length > maxShownItems) {
		return (
			<>
				{renderListItems(values.slice(0, maxShownItems), variant)}
				<span
					className="text-link text-primary"
					onClick={() => detailListModalRef.current?.show()}
				>
					{t('common:actions.showAll')} ({values.length})
				</span>

				<AsyncModal
					ref={detailListModalRef}
					testId="detailListModal"
				>
					<DetailListModalContent
						title={title}
						values={values}
					/>
				</AsyncModal>
			</>
		);
	}

	return renderListItems(values, variant);
};
