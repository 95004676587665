export default {
	actions: {
		create: 'Neuen Vertriebspartner erstellen',
		edit: 'Vertriebspartner bearbeiten',
		exportAll: 'Alle Vertriebspartner exportieren',
		form: {
			create: 'Als neuen Vertriebspartner speichern',
			update: 'Änderungen speichern',
		},
	},
	error: {
		createDistributionPartner: 'Vertriebspartner kann nicht erstellt werden.',
		updateDistributionPartner: 'Vertriebspartner kann nicht aktualisiert werden.',
	},
	page: {
		create: {
			title: 'Neuer Vertriebspartner',
			success: 'Sie haben einen neuen Vertriebspartner erstellt.',
		},
		update: {
			success: 'Sie haben den Vertriebspartner aktualisiert.',
		},
	},
	select: {
		title: 'Vertriebspartner wählen',
		button: {
			label: 'Vertriebspartner',
			placeholder: 'Vertriebspartner wählen',
		},
		filter: {
			placeholder: 'Nach E-Mail, Name oder Unternehmen suchen',
		},
	},
};
