import { flatMap, isEmpty, isNumber, pick, trim, uniq } from 'lodash';
import { dateToApiDate } from 'js/utils/dateTime';
import {
	CreationSourceEnum,
	creationSourceEnumUtils,
	OrderDateRangeTypeEnum,
	OrderSearchKeyEnum,
	OrderStatusEnum,
	PurchaseTypeEnum,
} from 'module/orders/enums';
import {
	IOrderListApiFilter,
	IOrderListApiFilterDateRange,
	IOrderListBaseFilter,
	IOrderListFilter,
} from 'module/orders';
import { paymentStatusEnumUtils } from 'js/enums';
import { logError } from 'js/utils/app';
import { isDefined } from 'js/utils/common';

interface IOrdersFilterNormalizer {
	preservedKeys: (keyof IOrderListBaseFilter)[];
	dateRangeRules: Record<
		OrderDateRangeTypeEnum,
		[keyof IOrderListApiFilterDateRange, keyof IOrderListApiFilterDateRange]
	>;

	denormalize(filter?: IOrderListFilter, defaultStatuses?: OrderStatusEnum[]): IOrderListApiFilter;

	denormalizeDateRange(filter: IOrderListFilter): IOrderListApiFilterDateRange | undefined;

	denormalizeSearchTerm(filter: IOrderListFilter): Partial<IOrderListApiFilter> | undefined;

	denormalizePurchaseType(filter: IOrderListFilter): Pick<IOrderListApiFilter, 'creationSources'> | undefined;

	denormalizePaymentStatus(filter: IOrderListFilter): Pick<IOrderListApiFilter, 'paymentStatuses'> | undefined;

	denormalizeCreationSources(filter: IOrderListFilter): Pick<IOrderListApiFilter, 'creationSources'> | undefined;
}

export const ordersFilterNormalizer: IOrdersFilterNormalizer = {
	preservedKeys: ['customerId', 'distributionPartnerId'],

	dateRangeRules: {
		PAYMENT: ['paymentDateFrom', 'paymentDateTo'],
		BILLING: ['billingDateFrom', 'billingDateTo'],
		CREATED: ['createdDateFrom', 'createdDateTo'],
	},

	denormalize(filter, defaultStatuses) {
		if (!filter) {
			return {};
		}

		const apiFilter: IOrderListApiFilter = {
			...pick(filter, this.preservedKeys),
			...this.denormalizeDateRange(filter),
			...this.denormalizeSearchTerm(filter),
			...this.denormalizePaymentStatus(filter),
			...this.denormalizeCreationSources(filter),
			...this.denormalizePurchaseType(filter),
			partnerIds: isNumber(filter.partnerId) ? [filter.partnerId] : undefined,
			statuses: isDefined(filter.status) ? [filter.status] : defaultStatuses,
		};

		return apiFilter;
	},

	denormalizeDateRange(filter) {
		if (filter.dateRange && filter.dateRangeType && this.dateRangeRules.hasOwnProperty(filter.dateRangeType)) {
			const [from, to] = this.dateRangeRules[filter.dateRangeType];
			return {
				[`${from}`]: dateToApiDate(filter.dateRange[0]),
				[`${to}`]: dateToApiDate(filter.dateRange[1], true),
			};
		}

		return undefined;
	},

	denormalizeSearchTerm(filter) {
		const value = trim(filter.search?.value);
		if (!isEmpty(value)) {
			switch (filter.search?.key) {
				case OrderSearchKeyEnum.ORDER_NUMBER:
					return { id: value };
				case OrderSearchKeyEnum.COMMERCE_ORDER_ID:
					return { gcOrderId: value };
				case OrderSearchKeyEnum.PO_NUMBER:
					return { purchaseOrderNumber: value };
				case OrderSearchKeyEnum.OPPORTUNITY_ID:
					return { opportunityId: value };
				case OrderSearchKeyEnum.QUOTE_NUMBER:
					return { id: value };
				default:
					logError(`Not supported orderSearch key: ${filter.search?.key}`);
					return undefined;
			}
		}
	},

	denormalizePaymentStatus(filter) {
		if (filter.paymentStatus) {
			return {
				paymentStatuses: [filter.paymentStatus, ...paymentStatusEnumUtils.getAliases(filter.paymentStatus)],
			};
		}
		return undefined;
	},

	denormalizeCreationSources(filter) {
		if (filter.creationSources) {
			return {
				creationSources: uniq(
					flatMap(filter.creationSources.map((source) => [source, ...creationSourceEnumUtils.getAliases(source)])),
				),
			};
		}
		return undefined;
	},

	denormalizePurchaseType(filter) {
		switch (filter.purchaseType) {
			case PurchaseTypeEnum.FLEXIBLE_BILLING:
				return {
					creationSources: [CreationSourceEnum.AVAST_BUSINESS_HUB],
				};
			case PurchaseTypeEnum.PREPAID:
				return {
					creationSources: creationSourceEnumUtils.getValues({ omitted: [CreationSourceEnum.AVAST_BUSINESS_HUB] }),
				};
			default:
				return undefined;
		}
	},
};
