import { IEnumFunctions, IEnumFunctionsWithDescription, TEnumOptions, TEnumType, TSelectOptionCriteria } from '.';
import { enumValidators } from 'js/enums/generator/enumValidators';
import i18n from 'i18n';
import type { TSelectOptions } from '@avast/react-ui-components';
import { filterByCriteria, generateAliasFunctions } from 'js/enums/generator/utils';
import { invert } from 'lodash';

const filterSelectOptions = <T extends string>(
	options: TSelectOptions<T>,
	criteria: TSelectOptionCriteria<string>,
): TSelectOptions<T> => {
	return options.filter((option) => filterByCriteria([option.value], criteria).length > 0);
};

export const generateEnumUtils = <T extends string, E extends {} = {}>(
	enumObject: TEnumType<T>,
	options: TEnumOptions<T> & E,
): IEnumFunctions<T> & E => {
	const { translatePath } = options;
	const aliasFunctions = generateAliasFunctions<T>(options);
	const invertedEnum = invert(enumObject);

	return {
		...aliasFunctions,
		getValues(criteria = {}) {
			return filterByCriteria(Object.values(enumObject), criteria);
		},
		getText(name) {
			if (!name) {
				return '';
			}
			if (aliasFunctions.isAlias(name)) {
				name = aliasFunctions.getAliasValue(name);
			}
			if (invertedEnum[name] === undefined || !translatePath) {
				return name;
			}
			return i18n.t(`${translatePath}.${invertedEnum[name]}`);
		},
		getSelectOptions(criteria = {}) {
			const { disabled = [] } = criteria;
			const options = Object.values(enumObject)
				.filter((value) => !aliasFunctions.isAlias(value))
				.map((value) => ({
					value,
					label: this.getText(value),
					description: undefined,
					disabled: disabled.includes(value),
				})) as TSelectOptions<T>;

			return filterSelectOptions<T>(options, criteria);
		},
		...enumValidators<T>(enumObject),
		...options,
	};
};

export const generateEnumUtilsWithDescription = <T extends string, E extends {} = {}>(
	enumObject: TEnumType<T>,
	options: TEnumOptions<T> & E,
): IEnumFunctionsWithDescription<T> & E => {
	const { translatePath } = options;
	const aliasFunctions = generateAliasFunctions<T>(options);
	const invertedEnum = invert(enumObject);

	return {
		...aliasFunctions,
		getValues(criteria = {}) {
			return filterByCriteria(Object.values(enumObject), criteria);
		},
		getText(name) {
			if (!name) {
				return '';
			}
			if (aliasFunctions.isAlias(name)) {
				name = aliasFunctions.getAliasValue(name);
			}
			if (invertedEnum[name] === undefined || !translatePath) {
				return name;
			}
			return i18n.t(`${translatePath}.${invertedEnum[name]}.name`);
		},
		getDescription(name) {
			if (!name) {
				return '';
			}
			if (aliasFunctions.isAlias(name)) {
				return this.getDescription(aliasFunctions.getAliasValue(name));
			}
			if (invertedEnum[name] === undefined || !translatePath) {
				return name;
			}
			return i18n.t(`${translatePath}.${invertedEnum[name]}.description`);
		},
		getSelectOptions(criteria = {}) {
			const { disabled = [] } = criteria;
			const options = Object.values(enumObject)
				.filter((value) => !aliasFunctions.isAlias(value))
				.map((value) => ({
					value,
					label: this.getText(value),
					description: this.getDescription(value),
					disabled: disabled.includes(value),
				})) as TSelectOptions<T>;

			return filterSelectOptions<T>(options, criteria);
		},
		...enumValidators<T>(enumObject),
		...options,
	};
};
