import { IEntityPartner, IEntityPartnerDetail, IPartnerGroups } from 'module/partners';
import { PARTNER_GROUP } from 'module/partners/constants';
import { joinValues } from 'js/utils/common';
import { MarketSegmentEnum, PurchaseBillablePartyTypeEnum } from 'module/purchase/enums';
import { AccountStatementEnum, PartnerUserRoleEnum } from 'module/partners/enums';

export const getPartnerFullName = (partner: IEntityPartner | null): string => {
	if (!partner) {
		return '';
	}

	return joinValues([partner.firstName, partner.lastName], ' ');
};

export const getPartnerRenderName = (partner: IEntityPartner | null): string => {
	if (!partner) {
		return '';
	}

	return partner.companyName || getPartnerFullName(partner);
};

export const hasPartnerGroup = (partner: IEntityPartnerDetail, groupId: number): boolean =>
	partner.groups?.some((group) => group.id === groupId) || false;

export const isPartnerInGroup = (partner: IEntityPartnerDetail | null | undefined, groupId: number): boolean => {
	if (partner?.groups) {
		return hasPartnerGroup(partner, groupId);
	}
	return false;
};

export const getPartnerGroups = (partner?: IEntityPartnerDetail | null): IPartnerGroups => ({
	creditEligible: isPartnerInGroup(partner, PARTNER_GROUP.CREDIT_ELIGIBLE),
	closingBalance: isPartnerInGroup(partner, PARTNER_GROUP.CLOSING_BALANCE),
	isBusiness: isPartnerInGroup(partner, PARTNER_GROUP.BUSINESS_PARTNER),
	isConsumer: isPartnerInGroup(partner, PARTNER_GROUP.CONSUMER_PARTNER),
	isConsumerTeam: isPartnerInGroup(partner, PARTNER_GROUP.CONSUMER_TEAM),
	isRetail: isPartnerInGroup(partner, PARTNER_GROUP.IS_RETAIL),
	isTestAccount: isPartnerInGroup(partner, PARTNER_GROUP.TEST_ACCOUNT),
});

export const isAuthorizedBuyer = (partner?: IEntityPartnerDetail | null): boolean =>
	partner?.attributes?.partnerUserRole === PartnerUserRoleEnum.AUTHORIZED_BUYER;

export const hasPartnerDetailData = (partner?: IEntityPartnerDetail | IEntityPartner | null): boolean =>
	partner?.hasOwnProperty('attributes') || false;

export const getMarketSegments = ({
	partner,
	purchaseBillablePartyType,
}: {
	partner?: IEntityPartnerDetail | null;
	purchaseBillablePartyType?: PurchaseBillablePartyTypeEnum | null;
}) => {
	if (purchaseBillablePartyType === PurchaseBillablePartyTypeEnum.CUSTOMER) {
		return [MarketSegmentEnum.BUSINESS];
	}

	return getPartnerMarketSegments(partner);
};

const getPartnerMarketSegments = (partner?: IEntityPartnerDetail | null) => {
	const partnerGroups = getPartnerGroups(partner);
	const segments: MarketSegmentEnum[] = [];

	if (partnerGroups.isBusiness) {
		segments.push(MarketSegmentEnum.BUSINESS);
	}

	if (partnerGroups.isConsumer) {
		segments.push(MarketSegmentEnum.CONSUMER);
	}

	return segments;
};

export const isPartnerEligibleToBuy = (partner?: IEntityPartnerDetail | null): boolean =>
	partner?.attributes?.accountStanding === AccountStatementEnum.GOOD_STANDING;

export const isPosaPartner = (partner: IEntityPartnerDetail | null): boolean =>
	Boolean(partner?.attributes?.posaDistributionId);
