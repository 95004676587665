import type { TUiTableRow } from '@avast/react-ui-components';
import type { TColumnFormatter } from '@tanstack/react-table';
import React from 'react';
import classNames from 'classnames';
import { TFunction } from 'i18next';

export type TEnableStatusOptions = {
	t: TFunction;
	colored?: boolean;
};

export const enableStatus =
	<Row extends TUiTableRow>(options: TEnableStatusOptions): TColumnFormatter<Row> =>
	// eslint-disable-next-line react/display-name
	(value) => {
		const isEnabled = Boolean(value);
		const className = classNames({
			'text-success': isEnabled && options.colored,
			'text-danger': !isEnabled && options.colored,
		});

		return (
			<span className={className}>{options.t(isEnabled ? 'common:entity.enabled' : 'common:entity.disabled')}</span>
		);
	};
