export default {
	common: {
		continueToProducts: 'Continuar a productos',
		orderValue: 'Valor de pedido',
		remaining: 'Restante',
		partyPrefix: {
			your: 'Su',
			partner: 'Socio',
			srp: 'SRP',
			customer: 'Cliente',
			quotedCustomer: 'Cliente cotizado',
		},
		savingOrder: 'Guardando pedido...',
		exportingOrder: 'Exportando pedido...',
		loadingOrder: 'Cargando un pedido...',
		customerCreditStatus: {
			eligible: 'El cliente cumple los requisitos para obtener un crédito.',
			notEligible: 'El cliente no cumple los requisitos para obtener un crédito.',
		},
		previousLicense: 'Clave de licencia anterior',
		currency: {
			changeTooltip: 'Cambiar moneda',
			selectPrompt: 'Seleccionar moneda de cliente',
		},
		paginationText: 'Mostrando entre <strong>{{from}} y {{to}}</strong> de {{total}} artículos.',
	},
	discretionaryDiscount: {
		title: 'Descuento a discreción',
		placeholder: 'Introduzca un descuento',
		approvalFlag: 'Estado de aprobación',
		amount: 'Importe',
		amountUnit: 'Importe por unidad',
		amountTotal: 'Importe total',
		status: {
			notSet: 'No se estableció ningún descuento a discreción en el pedido.',
			lineNotSet: 'No se estableció ningún descuento a discreción en la línea.',
			orderLevelDiscountIsSet:
				'Se aplicó un descuento de nivel de pedido a este pedido. Se desactivaron los descuentos de nivel de línea.',
			lineLevelDiscountIsSet:
				'Se aplicaron descuentos de nivel de línea a este pedido. Se desactivó el descuento de nivel de pedido.',
		},
		action: {
			resetLineDiscounts: 'Eliminar descuentos de línea',
		},
		waitingApprovalMessage:
			'Hay un descuento a discreción en el pedido que requiere la aprobación del área de Operaciones de venta.',
	},
	error: {
		submitOrder: {
			title: 'No se puede enviar un pedido',
			general: 'Algo no salió bien y el envío falló. Vuelva a realizar el envío.',
		},
		pricing: {
			title: 'No es posible calcular el precio',
		},
		context: {
			setPartnerFailed: 'No fue posible agregar el socio al pedido.',
		},
		creditExceed: {
			title: 'Esta transacción superará el importe de crédito disponible para su cuenta.',
			text: 'No hay ninguna forma de pago de crédito disponible para esta transacción. Use la opción Finalización de carrito de compra.',
		},
		requiredCustomer: {
			title: 'Se necesita información del cliente',
			text: 'Seleccione un cliente para completar la compra.',
			action: 'Seleccionar cliente',
			tooltip: {
				question: '¿Por qué solicitamos información del cliente para realizar un pedido?',
				answer:
					'Solicitamos información del cliente al realizar los pedidos para poder obtener más datos sobre los clientes finales. Al conocerlos mejor y entender sus hábitos, necesidades y preferencias, podemos ofrecer un mejor servicio para usted y sus clientes.',
			},
		},
		requiredDistributionPartner: {
			title: 'Se necesita información del socio de distribución',
			text: 'Seleccione un socio de distribución para completar la compra.',
			action: 'Seleccionar socio de distribución',
			tooltip: {
				question: '¿Por qué solicitamos información del socio de distribución para realizar un pedido?',
				answer:
					'Solicitamos que se incluyan ambos niveles de información del cliente (socio de distribución y cliente de socio de distribución) al realizar los pedidos para poder conocerlos mejor y entender sus hábitos, necesidades y preferencias, de modo que ofrecerles un mejor servicio a usted y ellos.',
			},
		},
	},
	components: {
		successOrder: {
			title: 'Correcto.',
			action: {
				NEW_ORDER: 'Nuevo pedido',
				DETAIL: 'Detalles de pedido',
				QUOTE_DETAIL: 'Detalle de cotización',
				LICENSES: 'Ver licencias',
				EDIT: 'Editar pedido',
				QUOTE_EDIT: 'Editar cotización',
				PAYMENT_INSTRUCTIONS: 'Instrucciones de pago',
			},
		},
		editPrices: {
			title: 'Editar precios por unidad',
			message:
				'Solo es posible editar el precio por unidad de los artículos que están actualmente en el carrito. No se puede realizar cambios en los pedidos guardados desde esta pantalla. Asegúrese de que el carrito esté completo antes de continuar.',
			button: 'Editar precios',
			columnLabel: 'Precio por unidad personalizado',
			submit: 'Guardar pedido',
			confirmation: {
				message:
					'Esto creará un pedido que se puede completar, pero no se puede editar de ninguna manera. ¿Quiere hacerlo?',
				success: 'Sí, quiero guardar el pedido',
			},
		},
		customerQuote: {
			error: {
				invalidItems:
					'Hace falta especificar los precios por unidad personalizados para guardar este pedido y obtener una vista preliminar o enviar el correo electrónico.',
			},
			fields: {
				customerEmail: 'Para',
				partnerEmail: 'CC',
				message: 'Mensaje personal',
			},
			submit: 'Enviar correo electrónico nuevo',
		},
	},
	page: {
		default: {
			title: 'Detalles de pedido',
		},
		products: {
			title: 'Productos',
		},
		order: {
			title: 'Revisar y confirmar',
		},
	},
	unit: {
		current: 'Actual: {{type}}',
		toIncrease: 'Adicional: {{type}}',
		toDecrease: 'Eliminar {{type}}',
		total: 'Total: {{type}}',
	},
	entity: {
		privateNotes: 'Nota privada',
		publicNotes: 'Nota pública',
		opportunityId: 'Número de oportunidad',
		marketSegment: 'Segmento',
		validityLabel: 'Para {{value}}',
		validityDescription: 'Longitud del plazo {{value}}',
	},
	pricing: {
		basePrice: 'Desde {{price}}',
		discount: {
			segment: 'Descuento por segmento',
			renewal: 'Descuento de renovación',
			partner: 'Descuento de socio',
			discretionary: 'Descuento a discreción',
			promo: 'Descuento promocional',
		},
		details: {
			show: 'Mostrar detalles de precios',
			hide: 'Ocultar detalles de precios',
		},
	},
	actions: {
		addNote: 'Agregar una nota',
		addToCart: 'Agregar al carrito',
		goToCart: 'Ir al carrito',
		exportCart: 'Exportar carrito',
	},
	message: {
		addToCart: {
			item: '- <strong>{{name}}</strong> - {{unit}} {{unitLabel}}, {{quantity}}x',
			title: 'Se agregó el producto al carrito.',
		},
		removedFromCart: 'Se eliminó el producto del carrito.',
		emptyCart: 'Se eliminaron todos los productos del carrito.',
		emptyCartConfirmation: '¿Realmente desea eliminar todos los productos del carrito?',
	},
	table: {
		action: {
			selectVariant: 'Seleccionar variante',
		},
		filter: {
			search: {
				placeholder: 'Buscar producto por nombre o SKU',
			},
			marketSegment: 'Productos {{type}}',
		},
	},
	tableVariant: {
		priceNote: 'Los precios incluyen todos los descuentos de cliente',
		tooltip: {
			quantity:
				'El valor de la cantidad equivale a la cantidad de licencias individuales pedidas para el producto seleccionado.',
			unitType: {
				SEATS: 'Cantidad de puestos para una sola licencia.',
				SESSIONS: 'Cantidad de sesiones para una sola licencia.',
				STORAGE: '',
				DEVICE: '',
			},
		},
	},
	widget: {
		common: {
			billableParty: 'Parte a la que se puede facturar',
			nonBillableParty: 'Parte a la que no se puede facturar',
		},
		selectCustomerCard: {
			createNew: 'Crear nuevo cliente',
			note: 'No hay clientes en este pedido.<br/><strong>Registre las licencias más tarde.</strong>',
			select: 'Seleccionar cliente',
			title: 'Cliente',
			error: {
				load: 'No se pudo cargar el cliente',
			},
			tooltips: {
				change: 'Cambiar cliente',
				update: 'Actualizar cliente',
				cancel: 'Eliminar cliente del pedido',
			},
		},
		selectDistributionPartnerCard: {
			createNew: 'Crear nuevo socio de distribución',
			note: 'No hay socio de distribución en este pedido.',
			select: 'Seleccionar socio de distribución',
			title: 'Socio de distribución',
			error: {
				load: 'No se pudo cargar el socio de distribución',
			},
			tooltips: {
				change: 'Cambiar socio de distribución',
				update: 'Actualizar socio de distribución',
				cancel: 'Eliminar socio de distribución del pedido',
			},
		},
		selectPartnerCard: {
			note: 'Selecciona el socio para ver los precios finales después de los descuentos.',
			select: 'Seleccionar socio',
			title: 'Socio de Avast',
			error: {
				load: 'No se pudo cargar el socio',
			},
			tooltips: {
				change: 'Cambiar socio de Avast',
				cancel: 'Eliminar socio de Avast del pedido',
			},
		},
	},
	paymentType: {
		SAVE: {
			action: 'Guardar',
			caption: 'Guardar para más tarde',
			button: 'Guardar para más tarde',
			successMessage: 'Se guardó su pedido.',
			confirmationCaption: '¿Desea guardar este pedido?',
		},
		QUOTE: {
			action: 'Enviar',
			caption: 'Enviar como cotización',
			button: 'Enviar como cotización',
			successMessage: 'La cotización se puso en cola para envío.',
			confirmationCaption: '¿Desea enviar esta cotización?',
		},
		END_CUSTOMER_QUOTE: {
			action: 'Enviar',
			caption: 'Enviar como cotización',
			button: 'Enviar como cotización',
			successMessage: 'La cotización se puso en cola para envío.',
			confirmationCaption: '¿Todo listo para realizar este pedido?',
		},
		CUSTOMER_QUOTE: {
			action: 'Enviar',
			caption: 'Enviar cotización de precio de cliente',
			button: 'Enviar cotización de precio de cliente',
			successMessage: 'La cotización se puso en cola para envío.',
			confirmationCaption: '¿Realmente desea enviar el correo electrónico de cotización de precio de cliente?',
			confirmationMessage:
				'Todos los precios por unidad de cliente se guardarán en el pedido y un correo electrónico se enviará al cliente.<br/>Esta acción no se puede revertir ni deshacer.',
		},
		GLOBAL_COMMERCE_CART_CHECKOUT: {
			action: 'Enviar',
			caption: 'Pago de tarjeta',
			button: 'Pago de tarjeta',
			successMessage: 'El pedido se realizó correctamente.',
			confirmationCaption: '¿Todo listo para realizar este pedido?',
		},
		GLOBAL_COMMERCE_CLOSING_BALANCE: {
			action: 'Enviar',
			caption: 'Saldo final',
			button: 'Enviar en saldo final',
			successMessage: 'El pedido se realizó correctamente.',
			confirmationCaption: '¿Todo listo para realizar este pedido?',
		},
		GLOBAL_COMMERCE_LINE_OF_CREDIT: {
			action: 'Enviar',
			caption: 'Enviar en crédito',
			button: 'Enviar en crédito',
			successMessage: 'El pedido se realizó correctamente.',
			confirmationCaption: '¿Todo listo para realizar este pedido?',
		},
		ORACLE_CART_CHECKOUT: {
			action: 'Enviar',
			caption: 'Pagar con tarjeta de crédito',
			button: 'Pagar con tarjeta de crédito',
			successMessage: 'El pedido se pagó correctamente.',
			confirmationCaption: '¿Todo listo para realizar este pedido?',
		},
		ORACLE_LINE_OF_CREDIT: {
			action: 'Enviar',
			caption: 'Enviar en crédito',
			button: 'Enviar en crédito',
			successMessage: 'El pedido se realizó correctamente.',
			confirmationCaption: '¿Todo listo para realizar este pedido?',
		},
		ORACLE_CLOSING_BALANCE: {
			action: 'Enviar',
			caption: 'Saldo final',
			button: 'Enviar en saldo final',
			successMessage: 'El pedido se realizó correctamente.',
			confirmationCaption: '¿Todo listo para realizar este pedido?',
		},
	},
	guards: {
		partnerPriceListGuard: {
			title: 'Confirmación de cambio de socio',
			message:
				'El socio seleccionado no tiene las mismas listas de precios permitidos que el actual. Si continúa, el carrito se vaciará.',
		},
		partnerTermsGuard: {
			title: 'El socio no puede realizar un pedido',
			message: 'El socio seleccionado no aceptó los términos y condiciones.',
		},
		priceListGuard: {
			title: 'Mezcla de listas de precios de productos',
			messageCaption:
				'Tenga en cuenta que no pudimos combinar los productos CloudCare y OMS en un pedido. ¿Realmente quiere agregar este producto al carrito?',
			message:
				'Los productos que ha agregado anteriormente se eliminarán de forma automática y este artículo se agregará al carrito.',
		},
		maxCartItemsGuard: {
			title: 'Cantidad máxima de artículos de línea superada',
			message:
				'Un pedido puede contener un máximo de {{count}} artículo de línea. Para continuar, elimine algunos de los artículos.',
			message_other:
				'Un pedido puede contener un máximo de {{count}} artículos de línea. Para continuar, elimine algunos de los artículos.',
		},
	},
	priceList: {
		mixWarning:
			'Tenga en cuenta que no podemos combinar los productos CloudCare y OMS en un pedido porque hay diferentes precios y descuentos para cada uno.',
		code: {
			PREPAID_OMS: 'Productos OMS',
			PREPAID_CC_LEGACY: 'Productos CloudCare',
			PREPAID_CC_MSRP: 'Productos CloudCare',
		},
	},
	tooltips: {
		remove: 'Eliminar este artículo del carrito',
		emptyCart: 'Eliminar todos los artículos del carrito',
	},
};
