export default {
	common: {
		forecastedSpend: '預計支出',
		currentSpend: '目前支出',
		maxSpend: '最高支出',
		limit: '限制',
		noLimit: '沒有限制',
		searchDistributionPartner: '按名稱尋找分銷合作夥伴',
		unlimited: '無限制',
		unlimitedNote:
			'如果未設定使用限額，則最大支出可能會超出您的信用額度。發生這種情況時，合作夥伴的產品將停用，直到信用額度增加或餘額減少。',
		valueBefore: '前',
		valueAfter: '儲存變更後',
	},
	page: {
		title: '彈性計費',
	},
	actions: {
		edit: '管理產品',
		viewOrders: '檢視訂單',
		enableProducts: '啟用產品',
		enableAllProducts: '啟用所有產品',
		disableAllProducts: '停用所有產品',
	},
	tab: {
		distributionPartners: '合作夥伴概觀',
		overview: '概觀',
		reports: '報告',
	},
	modal: {
		confirmation: '您確定嗎？',
		message: '您儲存的變更可能會對您的信用產生影響。您要繼續嗎？',
		apply: '正在套用變更',
		success: '變更已成功套用',
	},
	entity: {
		usageLimit: '使用限制',
		yourPrice: '您的價格',
	},
	invite: {
		info: '該合作夥伴尚未建立 Business Hub 帳戶。您可以為他們設定彈性收費，但僅在建立他們的 Business Hub 帳戶後，該功能才會生效。',
		action: {
			send: '傳送邀請',
			resend: '重新傳送邀請',
		},
		lastSent: '最後一次邀請已於 {{date}} 傳送至 {{email}}。',
		processing: '我們正在向分銷合作夥伴傳送邀請',
		success: '邀請已成功傳送',
		description:
			'傳送註冊 Business Hub 的電子郵件邀請。該帳戶將是 Business Hub 中的首個管理員帳戶（隨後可以新增其他管理員）。註冊後，新的 Business Hub 帳戶將連結到合作夥伴的記錄。',
		form: {
			create: {
				label: '儲存此合作夥伴時建立 Business Hub',
				description:
					'儲存此表單後，將發送一封電子郵件邀請註冊 Business Hub。該帳戶將是 Business Hub 中的首個管理員帳戶（隨後可以新增其他管理員）。註冊後，新的 Business Hub 帳戶將連結到合作夥伴的記錄。',
			},
			differentEmail: {
				partner: '傳送邀請至記錄中的電子郵件',
				other: '將邀請傳送到不同的電子郵件',
			},
		},
		modal: {
			title: '為 {{name}} 建立 Business Hub',
		},
	},
	tooltips: {
		unlimitedMaxSpend:
			'如果未設定使用限額，則最大支出可能會超出您的信用額度。發生這種情況時，合作夥伴的產品將停用，直到信用額度增加或餘額減少。',
		forecastedSpend: {
			distributor: '如果所有合作夥伴的產品使用情況保持不變，在目前收費週期內將花費的金額。',
			partner: '如果此合作夥伴的產品使用情況保持不變，在目前收費週期內將花費的金額。',
		},
		currentSpend: {
			distributor: '在目前收費週期內所有合作夥伴已在使用中產品上花費的金額。',
			partner: '在目前收費週期內此合作夥伴已在使用中產品上花費的金額。',
		},
		maxSpend: {
			distributor: '如果所有合作夥伴的所有使用中產品達到其使用限額，可以收取的最高金額。',
			partner: '如果此合作夥伴的所有使用中產品達到其使用限額，可以收取的最高金額。',
		},
	},
	error: {
		usageLimit: {
			title: '目前無法儲存您的變更。',
			message: '您嘗試設定的產品的使用限額低於您的合作夥伴在其 Business Hub 為其客戶建立的總使用限額。',
			product: '<strong>{{product}}</strong> 的總限額目前設定為 <strong>{{limit}}</strong>。',
			note: '我們建議聯絡合作夥伴討論使用限額。<br />如果您需要協助，請聯絡我們的支援團隊。',
		},
	},
};
