export default {
	actions: {
		generate: 'Générer des codes de vente au détail',
		downloadLicenses: 'Télécharger la demande de licences',
		refreshListItems: 'Actualiser les articles',
	},
	page: {
		list: 'Recherche d’identifiants de lot',
		licenses: 'Recherche d’un code de vente au détail',
	},
	entity: {
		bulkId: 'ID de lot',
		transactionDate: 'Date de la demande',
	},
	filter: {
		bulkId: {
			placeholder: 'Saisissez l’ID de lot et rendez-vous sur ...',
		},
	},
	generate: {
		submit: 'Envoyer la demande',
		submitLoading: 'Enregistrement de la demande...',
		success: 'Demande créée',
		partner: {
			headline: 'Partenaire',
			select: 'Sélectionner le partenaire',
			change: 'Modifier le partenaire',
			placeholder: 'Sélectionner un partenaire détaillant',
		},
		cart: {
			headline: 'Panier',
			add: 'Ajouter des produits',
			removeTooltip: 'Supprimer cet article du panier',
			placeholder: 'Ajouter des produits au panier',
		},
		products: {
			headline: 'Sélectionner les produits',
			addAndClose: 'Ajouter et fermer',
			success: 'Les produits ont été ajoutés au panier.',
		},
	},
};
