import React, { FunctionComponent, ReactNode } from 'react';
import { createAdvAgent, throughArea } from 'react-through';
import { Helmet } from 'react-helmet';

const agentName = 'pageTitle';

type TDynamicPageTitleItemProps = {
	text: string;
	clear?: boolean;
};

type TDynamicPageTitleProps = {
	[key: string]: TDynamicPageTitleItemProps;
};

export const DynamicPageTitleItem: FunctionComponent<TDynamicPageTitleItemProps> = createAdvAgent(agentName, 'text');

/**
 * Render page title by Helmet
 * @param {TDynamicPageTitleProps} items Items for meta title
 * @return {React.ReactNode}
 * @private
 */
const _DynamicPageTitleRender = (items: TDynamicPageTitleProps): ReactNode => {
	let values: string[] = [];
	Object.keys(items).forEach((key) => {
		const item = items[key];
		values = [item.text, ...(item.clear ? [] : values)];
	});
	return <Helmet title={values.join(' | ')} />;
};

export default throughArea(agentName)(_DynamicPageTitleRender);
