import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export type TUseRefetchQueriesKeys = string[];

export const useRefetchQueries = () => {
	const queryClient = useQueryClient();
	return useCallback(
		(keys: TUseRefetchQueriesKeys) => {
			keys.forEach((key) => queryClient.refetchQueries({ queryKey: [key] }));
		},
		[queryClient],
	);
};
