export default {
	requestId: 'リクエスト ID',
	errorId: 'エラー ID',
	errorDetails: 'エラーの詳細',
	api: {
		activeEntitlementNotFound: 'このライセンス処理を完了できません。エンタイトルメントがすでに置き換えられています。',
		invalidHash: 'ハッシュが無効です。',
		distributionPartnerSalesforceConflict:
			'指定された連絡先は別の組織に属しています。詳細については、アカウント マネージャーにお問い合わせください。',
		passwordResetInvalidToken: 'このパスワード リセット トークンは無効です。',
		passwordResetExpiredToken: 'このパスワード リセット トークンは期限切れです。',
		passwordAlreadyUsed: 'このパスワードは過去にすでに使用されています。別のパスワードを選択してください。',
		partnerNotRegistered:
			'注文の実行が許可されているのは登録済みのアカウントのみです。最初に OMS の登録を完了するようパートナーに依頼してください。',
		nowTermsNotAccepted:
			'注文の実行が許可されているのは利用規約に同意したアカウントです。更新された利用規約に同意するようパートナーに依頼してください。',
		licenseOperationWrongSegment: '選択した請求可能な当事者には、企業/消費者向けの製品を購入する資格がありません。',
		partnerQuoteCurrencyMismatch:
			'アカウントの通貨と見積もりの通貨が異なるため、この注文を処理できません。アカウント マネージャーに問い合わせて、新しい見積もりを依頼してください。',
	},
	page: {
		404: {
			title: 'IT 部門からのメッセージ (404 ページ)',
			message: 'エラーが発生しました。要求された URL が存在しません。以下をクリックして続行してください。',
			back: 'ホームページに戻る',
		},
	},
	status: {
		networkError: 'サーバーに接続できません。',
	},
	common: {
		contactSales: '詳細については、アカウント マネージャーにお問い合わせください。',
		repeatOrContactSales: '後でもう一度お試しになるか、アカウント マネージャーに詳細をご確認ください。',
	},
	detailNotFound: '詳細を読み込めないか、または詳細が存在しません。',
	permissionDenied: 'この処理/リソースにアクセスする権限がありません。',
	sectionDataNotFound: 'このセクションのデータが見つかりませんでした。',
	beforeUnload: '今すぐここから離れると、データが失われる可能性があります。',
	unspecifiedError: 'リクエストを処理できませんでした',
	errorDetail: '技術的な詳細 (エラーが報告された場合に表示)',
};
