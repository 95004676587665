import React, { useCallback } from 'react';
import { INewPasswordData } from 'module/security';
import { useTranslation } from 'react-i18next';
import { securityConfig } from 'module/security/securityConfig';
import { useApiNewPassword } from 'module/security/hooks/useApiSecurity';
import { H2, Toast } from '@avast/react-ui-components';
import { RouteName } from 'module/RouteName';
import { useLogin } from 'module/security/hooks/useLogin';
import { passwordResetApiError } from 'module/security/utils/apiError';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { navigateLinkToNavigateObject } from 'js/utils/link';
import { useApiErrorContext, useAppContext } from 'js/contexts';

export const useNewPassword = (token: string = '') => {
	const [t] = useTranslation(securityConfig.trNamespace);
	const { loadingModalRef, successModalRef } = useAppContext();
	const { setError } = useApiErrorContext();
	const login = useLogin();
	const navigate = useNavigate();

	const { mutate: sendNewPassword } = useApiNewPassword();

	const onSuccessHandler = useCallback(
		async (email: string, password: string) => {
			await successModalRef.current?.show({
				content: (
					<H2
						className="text-center h3"
						bold
					>
						{t('common.successNewPassword')}
					</H2>
				),
				submitButtonText: t('common:actions.continue'),
			});

			loadingModalRef.current?.show({ title: t('common.loginLoading') });
			const response = await login({
				username: email,
				password,
			});
			loadingModalRef.current?.hide();

			if (response.success) {
				const navigateObject = navigateLinkToNavigateObject(response.redirectTo || RouteName.HOMEPAGE.DEFAULT);
				navigate(navigateObject.to, navigateObject.options);
				return;
			}

			toast.error(<Toast>{response.message}</Toast>);

			navigate(RouteName.HOMEPAGE.DEFAULT);
		},
		[successModalRef, t, loadingModalRef, login, navigate],
	);

	const newPasswordRequest = useCallback(
		(data: INewPasswordData) => {
			const { newPassword: password } = data;
			loadingModalRef.current?.show({ title: t('common:_.loading') });
			sendNewPassword(
				{
					token,
					password,
				},
				{
					onSuccess(response) {
						onSuccessHandler(response.data.email, password);
					},
					onError(error) {
						setError({ error, resolve: passwordResetApiError });
					},
					onSettled() {
						loadingModalRef.current?.hide();
					},
				},
			);
		},
		[loadingModalRef, onSuccessHandler, sendNewPassword, setError, t, token],
	);

	return {
		newPasswordRequest,
	};
};
