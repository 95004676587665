import { useApiExportCart } from 'module/purchase/hooks/useApiPurchase';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { downloadFileFromApiResponse } from 'js/utils/file';
import { logError } from 'js/utils/app';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { orderInstanceNormalizer } from 'module/purchase/normalizer';
import { useAppContext, useOrderContext } from 'js/contexts';

export const useExportCart = () => {
	const apiExportCart = useApiExportCart();
	const { loadingModalRef } = useAppContext();
	const orderContext = useOrderContext();
	const [t] = useTranslation(purchaseConfig.trNamespace);

	return useCallback(async () => {
		const order = orderInstanceNormalizer.denormalizeToSave(orderContext);
		if (!order) {
			return;
		}

		loadingModalRef.current?.show({
			title: t('common.exportingOrder'),
			disableBeforeUnload: true,
		});

		const response = await apiExportCart
			.mutateAsync(order)
			.catch((error) => {
				logError(error);
				toast.error(<Toast>{t('components:export.downloadError')}</Toast>);
			})
			.finally(() => {
				loadingModalRef.current?.hide();
			});

		if (response) {
			downloadFileFromApiResponse(response, 'cart.xlsx');
		}
	}, [apiExportCart, loadingModalRef, t, orderContext]);
};
