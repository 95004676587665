import { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/es-CO');

const locale: TLocale = 'es-CO';

export const es_CO: ILocaleConfig = {
	name: 'Spanish (Colombia)',
	locale,
	language: 'es',
	format: {
		numbro,
		datetime: locale,
	},
};
