import { TApiMessagePlacement } from 'submodule/AppMessages/types';
import React from 'react';
import { Alert } from '@avast/react-ui-components';
import { useAppMessagesConfig } from 'submodule/AppMessages/useAppMessagesConfig';
import { useUserAppMessagesConfig } from 'submodule/AppMessages/hooks';
import Skeleton from 'react-loading-skeleton';

export type TAppMessagesProps = {
	className?: string;
	placement: TApiMessagePlacement;
};

export const AppMessages = (props: TAppMessagesProps) => {
	const { hiddenMessageIds, loading, hideMessage } = useUserAppMessagesConfig();
	const appMessagesConfig = useAppMessagesConfig(props.placement);

	if (loading) {
		return (
			<div className="my-3">
				<Skeleton />
				<Skeleton width="30%" />
			</div>
		);
	}

	return (
		<>
			{appMessagesConfig
				.filter((message) => message.hidable === false || !hiddenMessageIds.includes(message.id))
				.map((message) => (
					<Alert
						key={message.id}
						variant={message.variant}
						caption={message.caption?.()}
						closeable={message.hidable !== false}
						onClose={() => hideMessage(message.id)}
						icon={message.icon}
						{...props}
					>
						{message.render()}
					</Alert>
				))}
		</>
	);
};
