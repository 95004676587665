import React, { ReactElement } from 'react';
import { Formik } from 'formik';
import { IResetPasswordData } from 'module/security';
import { FormikForm } from 'js/components/formik/FormikForm';
import { FormikControl } from 'js/components/formik/FormikControl';
import { SubmitButton } from 'js/components/atoms/Button';
import { useTranslation } from 'react-i18next';
import { securityConfig } from 'module/security/securityConfig';
import { usePasswordReset } from 'module/security/hooks/usePasswordReset';
import * as Yup from 'yup';
import { SecurityFormLayout } from 'module/security/layouts';
import { RouteName } from 'module/RouteName';
import { Link } from 'react-router-dom';

export const PagePasswordReset = (): ReactElement => {
	const [t] = useTranslation(securityConfig.trNamespace);
	const { resetPasswordRequest } = usePasswordReset();

	return (
		<SecurityFormLayout title={t('page.passwordReset.title')}>
			<div className="mb-4 text-muted fs-md text-center">
				<p className="mb-1">{t('page.passwordReset.info')}</p>
				<p>{t('commonText.loginForAvastOnly')}</p>
			</div>
			<Formik<IResetPasswordData>
				initialValues={{ email: '' }}
				onSubmit={(values, { setSubmitting }) => {
					resetPasswordRequest(values);
					setSubmitting(false);
				}}
				validationSchema={Yup.object().shape<IResetPasswordData>({
					email: Yup.string().required().ensure().email().label(t('common:contact.email')),
				})}
			>
				<FormikForm testId="passwordReset">
					<FormikControl label={t('form.email')}>
						<FormikControl.Input
							name="email"
							size="lg"
							autoFocus
						/>
					</FormikControl>

					<div className="mt-6 text-center">
						<SubmitButton style={{ minWidth: 240 }}>{t('common:actions.submitForm')}</SubmitButton>
					</div>

					<div className="text-center mt-3">
						<Link
							to={RouteName.SECURITY.LOGIN}
							className="link"
						>
							{t('common.backToLogin')}
						</Link>
					</div>
				</FormikForm>
			</Formik>
		</SecurityFormLayout>
	);
};
