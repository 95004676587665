import axios from 'axios';
import { logError } from 'js/utils/app';
import { logoutGcStore } from 'js/utils/jsonp';

export const gcPaymentInstructions = async (url: string): Promise<null | string> => {
	await logoutGcStore('STANDARD');
	const response = await axios.get<string>(url).catch(logError);

	return response?.data || null;
};
