/* eslint-disable no-template-curly-in-string */
export default {
	maxFileSize: 'Dimensione max file: {{maxSize}} MB.',
	acceptFileType: 'Tipo di file supportato: {{types}}.',
	acceptFileType_other: 'Tipi di file supportati: {{types}}.',
	actions: {
		reset: 'Ripristina modulo',
	},
	error: {
		file: {
			type: 'Il file selezionato non presenta un tipo supportato.',
			maxSize: 'Il file selezionato è troppo grande: {{size}} MB (dimensioni massime: {{maxSize}} MB).',
		},
	},
	validator: {
		array: {
			max: 'Il campo ${path} non deve contenere più di ${max} elementi.',
			min: 'Il campo ${path} deve contenere almeno ${min} elementi.',
		},
		date: {
			max: 'Il campo ${path} deve essere antecedente a ${max}.',
			min: 'Il campo ${path} deve essere successivo a ${min}.',
		},
		mixed: {
			default: '${path} non valido.',
			notOneOf: '${path} non deve essere uno dei seguenti valori: ${values}.',
			oneOf: '${path} deve essere uno dei seguenti valori: ${values}.',
			required: 'Il campo ${path} è obbligatorio.',
		},
		number: {
			integer: '${path} deve essere un numero intero.',
			lessThan: '${path} deve essere minore di ${less}.',
			max: '${path} deve essere minore o uguale a ${max}.',
			min: '${path} deve essere maggiore o uguale a ${min}.',
			moreThan: '${path} deve essere maggiore di ${more}.',
			negative: '${path} deve essere un numero negativo.',
			positive: '${path} deve essere un numero positivo.',
		},
		string: {
			email: '${path} deve essere un’e-mail valida.',
			length: '${path} deve contenere esattamente ${length} caratteri.',
			link: '${path} deve essere un URL valido.',
			lowercase: '${path} deve essere una stringa in caratteri minuscoli.',
			matches: '${path} deve corrispondere a quanto segue: “${regex}”.',
			max: '${path} deve contenere un massimo di ${max} caratteri.',
			min: '${path} deve contenere almeno ${min} caratteri.',
			trim: '${path} deve essere una stringa tagliata.',
			uppercase: '${path} deve essere una stringa in caratteri maiuscoli.',
			url: '${path} deve essere un URL valido.',
			uuid: '${path} deve essere un UUID valido.',
		},
	},
};
