import { TApiErrorResolve } from 'types/api';
import i18n from 'i18next';
import { securityConfig } from 'module/security/securityConfig';
import { apiErrorResolveError } from 'js/utils/apiError';
import { API_ERROR_PASSWORD_RESET } from 'module/security/constants';

export const passwordResetApiError: TApiErrorResolve = (err) => ({
	title: i18n.t(securityConfig.trPrefix('error.unableToResetPassword')),
	messages: apiErrorResolveError(err, API_ERROR_PASSWORD_RESET),
});
