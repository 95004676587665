/* eslint-disable no-template-curly-in-string */
export default {
	maxFileSize: 'Taille de fichier max. : {{maxSize}} Mo',
	acceptFileType: 'Type de fichiers pris en charge : {{types}}',
	acceptFileType_other: 'Types de fichiers pris en charge : {{types}}',
	actions: {
		reset: 'Réinitialiser le formulaire',
	},
	error: {
		file: {
			type: 'Le type de fichier sélectionné n’est pas pris en charge.',
			maxSize: 'Le fichier sélectionné est trop volumineux : {{size}} Mo (max. {{maxSize}} Mo).',
		},
	},
	validator: {
		array: {
			max: 'Le champ ${path} doit comporter un maximum de ${max} éléments. ',
			min: 'Le champ ${path} doit comporter au moins ${min} éléments.',
		},
		date: {
			max: 'La valeur du champ ${path} doit être antérieure à ${max}.',
			min: 'La valeur du champ ${path} doit être postérieure à ${min}.',
		},
		mixed: {
			default: 'La valeur ${path} n’est pas valide.',
			notOneOf: 'La valeur ${path} ne doit pas être l’une des suivantes : ${values}.',
			oneOf: 'La valeur ${path} doit être l’une des suivantes : ${values}.',
			required: '${path} est un champ obligatoire.',
		},
		number: {
			integer: 'La valeur ${path} doit être un nombre entier.',
			lessThan: 'La valeur ${path} doit être inférieure à ${less}.',
			max: 'La valeur ${path} doit être inférieure ou égale à ${max}.',
			min: 'La valeur ${path} doit être supérieure ou égale à ${min}.',
			moreThan: 'La valeur ${path} doit être supérieure à ${more}.',
			negative: 'La valeur ${path} doit être un nombre négatif.',
			positive: 'La valeur ${path} doit être un nombre positif.',
		},
		string: {
			email: 'La valeur ${path} doit être une adresse e-mail valide.',
			length: 'La valeur ${path} doit comporter exactement ${length} caractères.',
			link: 'La valeur ${path} doit être une adresse URL valide.',
			lowercase: 'La valeur ${path} doit être une chaîne de caractères minuscules.',
			matches: 'La valeur ${path} doit correspondre à la suivante : ${regex}.',
			max: 'La valeur ${path} doit comporter au maximum ${max} caractères.',
			min: 'La valeur ${path} doit comporter au moins ${min} caractères.',
			trim: 'La valeur ${path} doit être une chaîne nettoyée.',
			uppercase: 'La valeur ${path} doit être une chaîne de caractères majuscules.',
			url: 'La valeur ${path} doit être une adresse URL valide.',
			uuid: 'La valeur ${path} doit être un UUID valide.',
		},
	},
};
