import { CONFIG } from 'config';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { securityConfig } from 'module/security/securityConfig';
import { Alert } from '@avast/react-ui-components';
import { isPartnerEligibleToBuy } from 'module/partners/utils/partnerSelectors';
import { RouteName } from 'module/RouteName';
import { object2UrlParams, tableFilterCreator } from 'js/utils/common';
import { LinkButton } from 'js/components/atoms/Button';
import { IOrderListFilter } from 'module/orders';
import { IClosingBalanceListFilter } from 'module/closingBalance';
import { PaymentStatusEnum } from 'js/enums';
import { NavigateLink } from 'types';
import { useAuthContext } from 'js/contexts';

type TOrderHoldAlertProps = {
	className?: string;
	simplified?: boolean;
};

export const OrderHoldAlert = (props: TOrderHoldAlertProps) => {
	const { simplified, className } = props;
	const { authCompany, isGroupPartner, authCompanyGroups } = useAuthContext();
	const [t] = useTranslation(securityConfig.trNamespace);
	const isEligible = isPartnerEligibleToBuy(authCompany);

	if (!isGroupPartner || isEligible) {
		return null;
	}

	// Link to UNPAID orders
	let link: NavigateLink['to'];
	if (authCompanyGroups.closingBalance) {
		link = {
			pathname: RouteName.CLOSING_BALANCE.LIST,
			search: object2UrlParams(
				tableFilterCreator<IClosingBalanceListFilter>({ paymentStatus: PaymentStatusEnum.UNPAID }),
			),
		};
	} else {
		link = {
			pathname: RouteName.ORDER.LIST,
			search: object2UrlParams(tableFilterCreator<IOrderListFilter>({ paymentStatus: PaymentStatusEnum.UNPAID })),
		};
	}

	return (
		<Alert
			variant="warning"
			caption={t('page.orderHold.title', { count: CONFIG.APP.ORDER_HOLD_DAYS })}
			className={className}
		>
			<p>{t('page.orderHold.text')}</p>
			{!simplified && (
				<>
					<p>{t('page.orderHold.textMore')}</p>
					<p className="mt-3">
						<LinkButton
							size="xs"
							variant="warning"
							to={link}
						>
							{t('page.orderHold.action')}
						</LinkButton>
					</p>
				</>
			)}
		</Alert>
	);
};
