import React, { ReactElement } from 'react';
import { TStepperItemProps } from 'js/components/molecules/Stepper/StepperItem';
import { useTestAttributesCallback } from '@avast/react-ui-components';
import classNames from 'classnames';
import { castArray, isFunction, isString } from 'lodash';
import { Link } from 'react-router-dom';
import { isDefined } from 'js/utils/common';

type TStepperProps<T extends string> = {
	className?: string;
	children: ReactElement | ReactElement[];
	value?: T;
	/** If true, items after active one will be disabled - unable to click */
	readonlyFromActive?: boolean;
	readonlyBeforeActive?: boolean;
	onClick?: (value: T) => void;
};

/**
 * Stepper navigation, e.g. for shopping cart
 * @param {TStepperProps} props
 * @return {ReactElement}
 */
export const Stepper = <T extends string = string>(props: TStepperProps<T>): ReactElement => {
	const { children, readonlyFromActive, readonlyBeforeActive, onClick } = props;
	const items: TStepperItemProps<T>[] = castArray(children).map((item) => item.props);
	let isAfterActive = !readonlyFromActive;
	const testAttributesCallback = useTestAttributesCallback();

	return (
		<div className={classNames('component__stepper', props.className)}>
			<ul>
				{items.map((item, index) => {
					const value = item.to || item.value;
					const active = props.value === value;
					const testAttributes = testAttributesCallback(item.testId, { type: 'stepper' });
					let component = (
						<span className="stepper-item">
							<span className="iterator">{index + 1}</span>
							<span>{item.children}</span>
						</span>
					);

					isAfterActive = isAfterActive || active;
					const addLinksBeforeActive = !isAfterActive && !readonlyBeforeActive;
					const addLinksFromActive = isAfterActive && !readonlyFromActive;

					// Modify component for items before or after active one
					if (addLinksBeforeActive || addLinksFromActive || item.isEnabled) {
						// Link
						if (isString(item.to)) {
							component = (
								<Link
									className="stepper-link"
									to={item.to}
									{...testAttributes}
								>
									{component}
								</Link>
							);
						}

						// Button
						else if (isFunction(onClick) && isDefined(item.value)) {
							component = (
								<button
									type="button"
									className="stepper-link"
									onClick={() => onClick(item.value!)}
									{...testAttributes}
								>
									{component}
								</button>
							);
						}
					}

					return (
						<li
							key={index}
							className={classNames({ 'is-active': active })}
						>
							{component}
						</li>
					);
				})}
			</ul>
		</div>
	);
};
