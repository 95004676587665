import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { IUrlFilter } from 'types/filter';
import { useCompare } from 'js/hooks/useCompare';
import { useLocation } from 'react-router-dom';
import { urlParams2object } from 'js/utils/common';
import { QuickFilters, TQuickFiltersProps } from './QuickFilters';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { DataFilterContextProvider } from './DataFilterContext';
import { identity, isFunction, isPlainObject, pickBy } from 'lodash';

export type TDataFilterChildrenProps<T extends object = object> = {
	values: T;
	updateFilter: (values: T, update?: boolean) => void;
	isEnabledField: (name: keyof T) => boolean;
};

export type TDataFilterProps<T extends object = object> = {
	onChange: (data: T | null) => void;
	values: T | null;
	defaultValues?: T;
	useLocation?: boolean;
	children?: ((filter: TDataFilterChildrenProps<T>) => ReactNode) | ReactNode;
	actionsCaption?: string;
	actions?: TQuickFiltersProps<T>['actions'];
	enabledRules?: Partial<Record<keyof T, (values: T) => boolean>>;
};

/**
 * Wrapper for filter, setup Formik form, auto saving, change delegating, ...
 * @param {TDataFilterProps<Filter>} props
 * @returns {ReactElement}
 * @constructor
 */
export const DataFilter = <Filter extends object>(props: TDataFilterProps<Filter>): ReactElement => {
	const { onChange, children, enabledRules, values, defaultValues } = props;

	// Hooks for data
	const [filter, setFilter] = useState<Filter | null>(props.values);
	const [locationLoaded, setLocationLoaded] = useState(!props.useLocation);
	const filterChanged = useCompare(filter, true);
	const activeFilter = useMemo(() => filter || defaultValues || ({} as Filter), [filter, defaultValues]);

	/**
	 * On change filter delegate values
	 */
	useEffect(() => {
		if (filterChanged && locationLoaded) {
			onChange(filter);
		}
	}, [filter, onChange, filterChanged, locationLoaded]);

	/**
	 * Function for detect if field is disabled
	 * @param {string} name
	 * @returns {boolean}
	 */
	const isEnabledField = useCallback<TDataFilterChildrenProps<Filter>['isEnabledField']>(
		(name) => {
			const rule = enabledRules && enabledRules[name];
			return rule ? rule(activeFilter) : true;
		},
		[enabledRules, activeFilter],
	);

	/**
	 * Callback for setting filter, values are filtered
	 * @type {TDataFilterChildrenProps<Filter>['updateFilter']}
	 */
	const updateFilter = useCallback<TDataFilterChildrenProps<Filter>['updateFilter']>(
		(values, update = true) => {
			let _values = update ? { ...filter, ...values } : values;

			// Remove disabled fields
			if (enabledRules) {
				Object.keys(enabledRules).forEach((key) => {
					const rule = enabledRules[key as keyof Filter];
					if (rule && !rule(_values)) {
						_values = { ..._values, [key]: undefined };
					}
				});
			}

			// Update filter
			setFilter(pickBy(_values, identity) as Filter);
		},
		[enabledRules, filter],
	);

	// Location
	const location = useLocation();
	useEffect(() => {
		if (!locationLoaded) {
			const { filter }: IUrlFilter<Filter> = urlParams2object(location.search);
			if (filter && isPlainObject(filter)) {
				updateFilter(filter, false);
			} else {
				setFilter(defaultValues || ({} as Filter));
			}
			setLocationLoaded(true);
		}
	}, [locationLoaded, location.search, updateFilter, values, defaultValues]);

	return (
		<>
			{props.actions && (
				<QuickFilters<Filter>
					actions={props.actions}
					caption={props.actionsCaption}
					setFilter={(values) => updateFilter(values, false)}
					activeFilter={activeFilter}
				/>
			)}
			<DataFilterContextProvider value={{ values: activeFilter, isEnabledField, updateFilter }}>
				{locationLoaded ? (
					children &&
					(isFunction(children)
						? children({
								values: activeFilter,
								updateFilter,
								isEnabledField,
						  })
						: children)
				) : (
					<LoadingPlaceholder minHeight={100} />
				)}
			</DataFilterContextProvider>
		</>
	);
};
