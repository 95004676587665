import { useApiCountry } from 'js/entities/country/useApiCountry';
import { normalizeStateName } from 'js/utils/normalize';

export const useCountryStateName = (
	countryCode?: string | null,
	stateCode?: string | null,
	stateName?: string | null,
) => {
	const _stateName = normalizeStateName(stateName);
	const { data: country } = useApiCountry({
		filter: { countryCode: countryCode! },
		queryConfig: { enabled: !stateName && Boolean(countryCode) && Boolean(stateCode) },
	});

	if (!countryCode || !stateCode) {
		return _stateName;
	}

	return _stateName || country?.states.find((state) => state.code === stateCode)?.name;
};
