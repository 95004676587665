import { numberFormatter, TNumberFormatterOptions } from 'js/utils/number';
import type { TUiTableRow } from '@avast/react-ui-components';
import type { TColumnFormatter } from '@tanstack/react-table';
import { RequireExactlyOne } from 'type-fest';
import { get } from 'lodash';
import { TEntityKey } from 'types';

type TCurrencyTypes<D extends {}> = {
	currencyKey: TEntityKey<D>;
	currency: string;
};
export type TMoneyOptions<D extends {}> = TNumberFormatterOptions &
	RequireExactlyOne<TCurrencyTypes<D>, 'currencyKey' | 'currency'>;

export const money =
	<Row extends TUiTableRow>(options: TMoneyOptions<Row>): TColumnFormatter<Row> =>
	(value, cell) => {
		const { currency, currencyKey, ...rest } = options;
		const currencyValue = currencyKey ? (get(cell.row.original, currencyKey, '') as string) : currency;
		const moneyValue = value && parseFloat(value.toString());

		return numberFormatter.currency(moneyValue, currencyValue, rest);
	};
