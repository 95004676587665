import React, { ReactElement } from 'react';
import { DiscretionaryDiscountForm } from 'module/purchase/forms/DiscretionaryDiscountForm';
import { DiscretionaryDiscountStatus } from 'module/purchase/components/order/DiscretionaryDiscountStatus';
import { Alert, Button } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useOrderContext } from 'js/contexts';

export const DiscretionaryDiscountTab = (): ReactElement => {
	const { hasLineDiscount, resetLineDiscretionaryDiscount, orderState } = useOrderContext();
	const [t] = useTranslation(purchaseConfig.trNamespace);

	if (hasLineDiscount) {
		return (
			<>
				<Alert variant="warning">{t('discretionaryDiscount.status.lineLevelDiscountIsSet')}</Alert>
				{!orderState.isLocked && (
					<p>
						<Button
							variant="danger"
							size="sm"
							onClick={resetLineDiscretionaryDiscount}
						>
							{t('discretionaryDiscount.action.resetLineDiscounts')}
						</Button>
					</p>
				)}
				<DiscretionaryDiscountStatus />
			</>
		);
	}

	return (
		<>
			<DiscretionaryDiscountForm name="discretionaryDiscount" />
			<DiscretionaryDiscountStatus />
		</>
	);
};
