import { IEntityOrderItemBase } from 'module/orders';
import { isOrderItemBusiness } from 'module/orders/utils/common';
import { extractBulkQuantityFromSku } from 'js/utils/common';
import { cond, overEvery, stubTrue } from 'lodash';
import { getPropAsNumber, ifElse, isNot, isPropNonEmptyString, isPropNumber, propIsNot } from 'js/utils/propUtils';

const DEFAULT_ITEM_QUANTITY = 0;
const DEFAULT_ITEM_UNIT = 0;

const pricedQuantityConditions = [propIsNot('transactionType', 'Increase'), isPropNumber('pricedQuantity')];

const getBulkQuantity = (item: IEntityOrderItemBase) => extractBulkQuantityFromSku(item.product.sku);

const getBusinessOrderItemQuantity: (item: IEntityOrderItemBase) => number = ifElse(
	isPropNumber('entitlementQuantity'),
	getPropAsNumber('entitlementQuantity'),
	() => DEFAULT_ITEM_QUANTITY,
);

const getConsumerOrderItemQuantity: (item: IEntityOrderItemBase) => number = cond([
	[isPropNumber('pricedQuantity'), getPropAsNumber('pricedQuantity')],
	[isPropNumber('unitsQuantityTotal'), getPropAsNumber('unitsQuantityTotal')],
	[isPropNumber('entitlementQuantity'), getPropAsNumber('entitlementQuantity')],
	[stubTrue, () => DEFAULT_ITEM_QUANTITY],
]);

const getBusinessOrderItemUnit: (item: IEntityOrderItemBase) => number = cond([
	[overEvery(pricedQuantityConditions), getPropAsNumber('pricedQuantity')],
	[isPropNumber('unitsQuantityTotal'), getPropAsNumber('unitsQuantityTotal')],
	[isPropNonEmptyString('ems.order.dimension'), getPropAsNumber('ems.order.dimension')],
	[stubTrue, () => DEFAULT_ITEM_UNIT],
]);

const getConsumerOrderItemUnit: (item: IEntityOrderItemBase) => number = cond([
	[isPropNonEmptyString('ems.order.dimension'), getPropAsNumber('ems.order.dimension')],
	[isPropNumber('unitsQuantityTotal'), getPropAsNumber('unitsQuantityTotal')],
	[(item) => isNot(0, getBulkQuantity(item)), getBulkQuantity],
	[stubTrue, () => DEFAULT_ITEM_UNIT],
]);

export const getOrderItemQuantity = ifElse(
	isOrderItemBusiness,
	getBusinessOrderItemQuantity,
	getConsumerOrderItemQuantity,
);

export const getOrderItemUnit = ifElse(isOrderItemBusiness, getBusinessOrderItemUnit, getConsumerOrderItemUnit);
