import { IEntityOrder } from 'module/orders';
import { useApiPartner } from 'module/partners/hooks/useApiPartners';
import { useCustomerDetail } from 'module/customers/hooks/useCustomerDetail';
import { useApiDistributionPartner } from 'module/distributionPartners/hooks/useApiDistributionPartners';
import { TOrderParties } from 'module/orders/hooks/orderToInstance';

export const useOrderParties = (order: IEntityOrder | null): TOrderParties => {
	const isEnabled = Boolean(order);

	// Partner
	const partnerId = order?.partner?.id;
	const hasPartner = Boolean(partnerId);
	const { data: partner, query: partnerQuery } = useApiPartner({
		id: partnerId,
		queryConfig: { enabled: hasPartner && isEnabled },
	});

	// Customer
	const customerId = order?.customer?.id;
	const { data: customer, query: customerQuery } = useCustomerDetail({
		partnerId,
		customerId,
		enabled: isEnabled,
	});

	// Distribution partner
	const distributionPartnerId = order?.distributionPartner?.id;
	const hasDistributionPartner = Boolean(distributionPartnerId);
	const { data: distributionPartner, query: distributionPartnerQuery } = useApiDistributionPartner({
		filter: { id: distributionPartnerId, distributorId: partnerId },
		queryConfig: { enabled: hasPartner && hasDistributionPartner && isEnabled },
	});

	return {
		partner,
		customer,
		distributionPartner,
		isFetching: partnerQuery.isFetching || customerQuery.isFetching || distributionPartnerQuery.isFetching,
	};
};
