/* eslint-disable no-template-curly-in-string */
export default {
	maxFileSize: 'Tamaño máximo de archivo: {{maxSize}} MB.',
	acceptFileType: 'Tipo de archivo admitido: {{types}}.',
	acceptFileType_other: 'Tipos de archivos admitidos: {{types}}.',
	actions: {
		reset: 'Restablecer formulario',
	},
	error: {
		file: {
			type: 'El archivo seleccionado no es de un tipo admitido.',
			maxSize: 'El archivo seleccionado es demasiado grande: {{size}} MB ({{maxSize}} MB como máximo).',
		},
	},
	validator: {
		array: {
			max: 'El campo ${path} debe tener ${max} elementos o menos.',
			min: 'El campo ${path} debe tener al menos ${min} elementos.',
		},
		date: {
			max: 'El campo ${path} debe ser una fecha anterior a ${max}.',
			min: 'El campo ${path} debe ser una fecha posterior a ${min}.',
		},
		mixed: {
			default: 'El valor de ${path} no es válido.',
			notOneOf: 'El valor de ${path} no debe ser uno de los siguientes: ${values}.',
			oneOf: 'El valor de ${path} debe ser uno de los siguientes: ${values}.',
			required: '${path} es un campo obligatorio.',
		},
		number: {
			integer: 'El valor de ${path} debe ser un número entero.',
			lessThan: 'El valor de ${path} debe ser inferior a ${less}.',
			max: 'El valor de ${path} debe ser igual o inferior a ${max}.',
			min: 'El valor de ${path} debe ser igual o superior a ${min}.',
			moreThan: 'El valor de ${path} debe ser superior a ${more}.',
			negative: 'El valor de ${path} debe ser un número negativo.',
			positive: 'El valor de ${path} debe ser un número positivo.',
		},
		string: {
			email: 'El valor de ${path} debe ser un correo electrónico válido.',
			length: 'El valor de ${path} debe tener exactamente ${length} caracteres.',
			link: 'El valor de ${path} debe ser una URL válida.',
			lowercase: 'El valor de ${path} debe ser una cadena en minúsculas.',
			matches: 'El valor de ${path} debe coincidir con lo siguiente: "${regex}".',
			max: 'El valor de ${path} debe tener, como máximo, ${max} caracteres.',
			min: 'El valor de ${path} debe tener al menos ${min} caracteres.',
			trim: 'El valor de ${path} debe ser una cadena recortada.',
			uppercase: 'El valor de ${path} debe ser una cadena en mayúsculas.',
			url: 'El valor de ${path} debe ser una URL válida.',
			uuid: 'El valor de ${path} debe ser una UUID válida.',
		},
	},
};
