import React, { PropsWithChildren, ReactElement } from 'react';
import { DataFilter, TDataFilterProps } from 'js/components/molecules/DataFilter/DataFilter';
import { Col, Row } from 'js/components/atoms/Row';
import { SelectPartner } from 'module/partners/components';
import { ICreditLimitListFilter } from 'module/creditLimit';
import { ButtonComposition, FormControl } from '@avast/react-ui-components';
import { FilterRangeDatepicker } from 'js/components/molecules/Datepicker/FilterRangeDatepicker';
import { useTranslation } from 'react-i18next';
import { Can } from 'js/components/molecules/Can';
import { creditLimitConfig } from 'module/creditLimit/creditLimitConfig';
import { CreditLimitCreateButton } from 'module/creditLimit/components/CreditLimitCreateButton';

type TFilter = ICreditLimitListFilter;
type TFilterProps = TDataFilterProps<TFilter>;

/**
 * Filter for credit limit list
 * @param {React.PropsWithChildren<TFilterProps>} props
 * @returns {React.ReactElement}
 * @constructor
 */
export const CreditLimitFilter = (props: PropsWithChildren<TFilterProps>): ReactElement => {
	const [t] = useTranslation();

	return (
		<DataFilter<TFilter> {...props}>
			{({ values, updateFilter }) => (
				<Row
					multi
					size="sm"
					align="end"
				>
					<Col
						xl={3}
						lg={4}
						sm={6}
					>
						<SelectPartner
							size="sm"
							value={values.partnerId}
							onChange={(value) => updateFilter({ partnerId: value })}
							staticFilter={{
								creditEligible: true,
							}}
						/>
					</Col>
					<Col
						lg={3}
						md={4}
						sm={6}
					>
						<FormControl label={t('common:dates.created')}>
							<FilterRangeDatepicker testId="period" />
						</FormControl>
					</Col>
					<Can
						do={creditLimitConfig.aclModule}
						create
					>
						<Col
							xl={6}
							lg={5}
							className="text-end"
						>
							<ButtonComposition size="sm">
								<CreditLimitCreateButton />
							</ButtonComposition>
						</Col>
					</Can>
				</Row>
			)}
		</DataFilter>
	);
};

CreditLimitFilter.defaultProps = {
	useLocation: true,
};
