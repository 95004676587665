import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { useCallback } from 'react';
import { TLicenseOperationPartyResolution } from 'module/licenses/components/licenseOperation/SelectPartyModal';
import { STATUS_SUCCESS } from 'appConstants';

export const useLicenseOperationPartyResolver = () => {
	const licenseOperationContext = useLicenseOperationContext();

	return useCallback(async (): Promise<TLicenseOperationPartyResolution | null> => {
		let resolution: TLicenseOperationPartyResolution | null = null;
		const { selectPartyModalRef } = licenseOperationContext;

		await selectPartyModalRef.current?.show({
			onSelect(response) {
				resolution = response;
				selectPartyModalRef.current?.onSuccess(STATUS_SUCCESS);
			},
		});

		return resolution;
	}, [licenseOperationContext]);
};
