import { TAppMessageConfig } from 'submodule/AppMessages/types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAuthContext } from 'js/contexts';
import { useFeatureFlag } from 'js/hooks/useFeatureFlag';

export const useNewTermsAndConditionsMessageConfig = (): TAppMessageConfig => {
	const [t] = useTranslation('submodules');
	const { isGroupInternal, authCompany } = useAuthContext();
	const featureFlag = useFeatureFlag('NEW_TERMS_AND_CONDITIONS');

	return {
		id: '5e35c79c-ac12-40f6-b5fa-bd5c8dfab284',
		hidable: false,
		show: (isGroupInternal || Boolean(authCompany?.isClientTermsRequired)) && featureFlag.isEnabled,
		variant: 'warning',
		caption() {
			return <Trans t={t}>{'appMessages.newTermsAndConditions.title'}</Trans>;
		},
		render() {
			return (
				<>
					<p className="mb-3">
						<Trans
							t={t}
							i18nKey="appMessages.newTermsAndConditions.content"
						/>
					</p>
				</>
			);
		},
		placement: ['HOMEPAGE_PARTNER', 'HOMEPAGE_INTERNAL'],
	};
};
