import { CONFIG } from 'config';
import { IApiPartnerCustomerCountResponse } from 'module/customers';
import type { TFunction } from 'i18next';
import { isString } from 'lodash';
import { isDefined } from 'js/utils/common';

export const isCustomersApiLimitExceeded = (response: IApiPartnerCustomerCountResponse | null): boolean =>
	isDefined(response) && response.count > CONFIG.APP.CUSTOMERS_API_LIMIT;

export const hasFilterEnoughChars = (value?: string | null): boolean =>
	isString(value) ? value.trim().length >= CONFIG.APP.CUSTOMERS_API_FILTER_MIN_CHAR : false;

export const getFilterCharsMessage = (t: TFunction): string =>
	t('common:filter.typeAtLeastNCharacters', { count: CONFIG.APP.CUSTOMERS_API_FILTER_MIN_CHAR });
