import React, { ReactElement } from 'react';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { EditHomepageContent } from 'module/homepage/components/EditHomepageContent';
import { Navigate } from 'react-router-dom';
import { RouteName } from 'module/RouteName';
import { useWidgetContext } from 'submodule/widgets/context';

export const PageEditWidgets = (): ReactElement => {
	const { isEditable } = useWidgetContext();

	if (!isEditable) {
		return <Navigate to={RouteName.HOMEPAGE.DEFAULT} />;
	}

	return (
		<DefaultContainer>
			<EditHomepageContent />
		</DefaultContainer>
	);
};
