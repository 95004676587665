import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import React, { PropsWithChildren } from 'react';
import { IPromotionForm } from 'module/promotions';
import { FormikForm } from 'js/components/formik/FormikForm';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import {
	PromotionFormMainBlock,
	PromotionFormPartyBlock,
	PromotionFormProductBlock,
} from 'module/promotions/form/blocks';
import { usePromotionFormValidator } from 'module/promotions/utils/validator';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import { RouteName } from 'module/RouteName';
import { DateTime } from 'luxon';
import { CONFIG } from 'config';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';

type TPromotionFormProps = {
	isUpdate: boolean;
	initialValues?: IPromotionForm;
	onCancel?: () => void;
	onSubmit: FormikConfig<IPromotionForm>['onSubmit'];
};

export const PromotionForm = (props: PropsWithChildren<TPromotionFormProps>) => {
	const { onCancel, onSubmit, isUpdate, initialValues = promotionsConfig.defaultFormValues } = props;
	const [t] = useTranslation(promotionsConfig.trNamespace);
	const { confirmationModalRef } = useAppContext();

	const navigate = useNavigate();
	const { validationSchema } = usePromotionFormValidator();

	return (
		<Formik<IPromotionForm>
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={async (values, formikHelpers) => {
				if (isDefined(values.date) && isDefined(values.date?.[0])) {
					const startDate = DateTime.fromJSDate(values.date[0], { zone: CONFIG.LOCALE.TIMEZONE });
					if (startDate.diffNow().toMillis() <= 0) {
						if (
							!(await confirmationModalRef.current?.show({
								title: t('common.invalidPromotionStartDate.title'),
								messages: [t('common.invalidPromotionStartDate.message')],
								submitButtonText: t('common:actions.confirm'),
							}))
						) {
							formikHelpers.setSubmitting(false);
							return;
						}
					}
				}

				onSubmit(values, formikHelpers);
			}}
		>
			<FormikForm>
				<PromotionFormMainBlock isUpdate={isUpdate} />
				<PromotionFormPartyBlock />
				<PromotionFormProductBlock isUpdate={isUpdate} />

				<FormikFooterControls
					submit={{ children: t(`actions.form.${isUpdate ? 'update' : 'create'}`) }}
					cancel={{
						onClick: () => onCancel?.() || navigate(RouteName.PROMOTIONS.LIST),
					}}
				/>
			</FormikForm>
		</Formik>
	);
};
