import React, { ReactElement, useState } from 'react';
import { TSearchFilter } from 'types/filter';
import { IApiCustomerListFilter, IEntityCustomer } from 'module/customers';
import { SearchComboBox } from 'js/components/molecules/SearchBox';
import { useApiCustomerList } from 'module/customers/hooks/useApiCustomers';
import { ApiListTable } from 'js/components/molecules/DataTable';
import { CustomersSearchKeyEnum, customersSearchKeyEnumUtils } from 'module/customers/enums';
import { TableColumnsDef } from 'types';
import { trim } from 'lodash';
import { isValidEmail } from 'js/utils/validator';
import { useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';

type TTableData = IEntityCustomer;
type TTableSearchKeys = CustomersSearchKeyEnum;

type TSelectCustomerInternalCompanyProps = {
	onSelect: (customer: IEntityCustomer) => void;
	columns: TableColumnsDef<IEntityCustomer>;
};

/**
 * Search customer for internal user
 * @param {TSelectCustomerInternalCompanyProps} props
 * @return {ReactElement}
 * @constructor
 */
export const SelectCustomerInternalCompany = (props: TSelectCustomerInternalCompanyProps): ReactElement => {
	const { onSelect, columns } = props;
	const { t } = useTranslation(customersConfig.trNamespace);
	const [searchComboValue, setSearchComboValue] = useState<TSearchFilter<TTableSearchKeys> | undefined>({
		key: CustomersSearchKeyEnum.EMAIL,
		value: '',
	});

	// Filter
	let filter: IApiCustomerListFilter = {};
	if (searchComboValue && searchComboValue.key && Boolean(searchComboValue.value)) {
		filter = { [searchComboValue.key]: searchComboValue.value };
	}

	return (
		<>
			<div className="mb-3">
				<SearchComboBox<TTableSearchKeys>
					autoFocus
					size="sm"
					onSubmit={(value) => setSearchComboValue(value)}
					value={searchComboValue}
					keys={customersSearchKeyEnumUtils.getSelectOptions()}
					isValid={({ value, key }) => {
						const invalid = key === CustomersSearchKeyEnum.EMAIL && trim(value).length !== 0 && !isValidEmail(value);

						return {
							valid: !invalid,
							message: t('common:validator.email'),
						};
					}}
				/>
			</div>

			<ApiListTable<TTableData, IApiCustomerListFilter>
				columns={columns}
				query={useApiCustomerList}
				filter={filter}
				filterRequired
				table={{
					testId: 'customers',
					meta: {
						onRowClick: onSelect,
						loadingType: 'BAR',
						nowrapCell: false,
					},
				}}
			/>
		</>
	);
};
