export default {
	appName: 'Order Management System',
	menu: {
		closingBalance: 'Closing Balance',
		creditLimit: 'Credit Limit',
		creditStatus: 'Credit Status',
		customers: 'Customers',
		dashboard: 'Home',
		distributionPartners: 'Distribution Partners',
		licenses: 'Licenses',
		orders: 'Orders',
		quotes: 'Quotes',
		purchase: 'New Purchase',
		promotions: 'Promotions',
		account: 'My Account',
		help: 'Help & Resources',
		feedback: 'Share Feedback',
		retail: 'Retail Codes',
		priceCalculator: 'Price Calculator',
		flexibleBilling: 'Flexible Billing',
	},
	help: {
		main: {
			title: 'Help & Resources',
			guide: {
				title: 'Here’s what’s new in OMS!',
				text: 'Let us walk you through the most important changes.',
				action: 'Start tour',
			},
			videos: {
				title: 'Check out our how-to videos',
			},
		},
		guide: {
			paginator: '{{current}} of {{total}}',
			slides: {
				welcome: {
					title: 'Welcome to the new OMS',
					text: [
						'OMS has a new look! Check out our new design, user-friendly interface, improved navigation and smooth performance for the best purchase experience. Check out the widgets on the home page that give you quick access to your recent orders, quotes, expiring licenses, and more!',
					],
				},
				pricing: {
					title: 'Dynamic pricing',
					text: [
						'You no longer need to wait for your order to be repriced, and you do not need to add products to the shopping cart to see prices. Now, we display prices directly in the product catalogue!',
						'As you adjust the seat count or quantity of a product, prices are automatically recalculated to always give you up-to-date information. Order value is also recalculated automatically as you add or remove products from the shopping cart.',
					],
				},
				quickFilter: {
					title: 'Easily filter and search for consumer and business products',
					text: [
						'We added a quick filter for you to easily display consumer only or business only products. If you are looking for a specific product, simply start typing a product name or product code, and the list will automatically update to match your input.',
					],
				},
				licenseOperations: {
					title: 'Renewals made easier',
					text: [
						'We separated a new purchase from license operations (such as renewal or upgrade), so you can easily view all available license operations directly when viewing licenses. Simply click on the License Operations button available in the License list or in the License detail page.',
					],
				},
				shoppingCart: {
					title: 'Easy access to the shopping cart',
					text: [
						'We added a shopping cart icon so that you can easily access your shopping cart and proceed with the order whenever you are ready.',
						'You can easily combine new purchases with license operations (such as renewal) in one order.',
					],
				},
				quickAccess: {
					title: 'Quick access to all the records you need',
					text: [
						'We added quick buttons and links in various places in OMS so that you can easily access information related to the record you are viewing. Use the “View Licenses” and “View Orders” buttons in the Customer detail page.',
						'From the License detail page, open a related order or view customer details by clicking on the highlighted links.',
					],
				},
				quotes: {
					title: 'Easily accessible quotes',
					text: [
						'We created a separate menu item for quotes that you receive from your Account Manager so that you can find them more easily. These quotes can be also found among Orders for easy access.',
					],
				},
				finalStep: {
					title: 'That’s it!',
					text: [
						'We hope that the redesigned user interface with new features and improved performance will make your OMS experience more enjoyable.',
						'Please share your feedback and suggestions for improvements by clicking on the "Share Feedback" menu item.',
						'Note that you can review this introduction guide later anytime by clicking on “Help & Resources” in the menu.',
					],
				},
			},
		},
		videos: {
			title: 'How-to videos',
			list: {
				navigations: 'Navigating in OMS',
				newPurchase: 'Creating a new purchase order',
				renewal: 'Creating a renewal order',
				quotes: 'Receiving a quote from Account Manager',
			},
		},
	},
};
