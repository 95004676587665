import React from 'react';
import { TDetailArguments } from 'types';
import { Detail } from 'module/creditStatus/pages/content/Detail';
import { toNumber } from 'lodash';
import { useRouteParams } from 'js/hooks/useRouteParams';

type TArguments = TDetailArguments;

export const PageDetail = () => {
	const { id } = useRouteParams<TArguments>();

	return <Detail id={toNumber(id)} />;
};
