import React, { PropsWithChildren } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';

export const CreditCardPreviewTable = (props: PropsWithChildren<{}>) => {
	const [t] = useTranslation(accountConfig.trNamespace);

	return (
		<Table className="table-creditCardPreview">
			<thead>
				<tr>
					<th colSpan={2}>{t('creditCard.entity.number')}</th>
					<th>{t('creditCard.entity.expiration')}</th>
					<th />
				</tr>
			</thead>
			<tbody>{props.children}</tbody>
		</Table>
	);
};
