import { IEntityLicense, ILicenseListFilter } from 'module/licenses';

type TFilterLicensesByStatus = (
	licenses: IEntityLicense[],
	listFilter: ILicenseListFilter['statuses'],
) => IEntityLicense[];

export const filterLicensesByStatus: TFilterLicensesByStatus = (licenses, statuses = []) => {
	if (statuses.length === 0) {
		return licenses;
	}

	return licenses.filter((license) => statuses.includes(license.status));
};
