import { useEffect } from 'react';
import { logDebug } from 'js/utils/app';
import { useCompare } from 'js/hooks/useCompare';
import { useAppContext, useAuthContext } from 'js/contexts';

export const useUILocaleContextGuard = () => {
	const { refreshUILocaleContext } = useAppContext();
	const {
		authState: { locale },
	} = useAuthContext();
	const changedLocale = useCompare(locale.format);

	// Update date/number formatting
	useEffect(() => {
		if (changedLocale && locale.format) {
			logDebug('useUILocaleContextGuard', locale.format);
			refreshUILocaleContext(locale.format);
		}
	}, [changedLocale, locale.format, refreshUILocaleContext]);
};
