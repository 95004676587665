export default {
	page: {
		default: {
			title: 'Mi cuenta',
		},
		taxExemption: {
			title: 'Certificado de exención de impuestos',
			shortTitle: 'Exención de impuestos',
			action: 'Enviar nueva exención de impuestos',
		},
		flexibleBilling: {
			title: 'Facturación flexible',
		},
		autoBillingAddCard: {
			title: 'Agregar nueva tarjeta',
		},
	},
	error: {
		autoBillingSource:
			'No se pusieron cargar los detalles de la tarjeta de crédito. Contacte con su Administrador de cuentas.',
	},
	cards: {
		accountInformation: 'Información de cuenta',
		contactInformation: 'Información de contacto',
		addressInformation: 'Información de dirección',
		terms: 'Términos y condiciones',
		legacyPaymentOptions: 'Tarjetas guardadas',
		personalization: 'Personalización',
	},
	common: {
		locale: {
			language: 'Idioma del sistema',
			format: 'Formato de fecha/número',
		},
	},
	form: {
		creditCard: {
			owner: 'Información de propietario de tarjeta',
			creditCard: 'Tarjeta de crédito',
			number: 'Número de tarjeta',
			expiration: 'Expiración (MM/AA)',
			cvv: 'CVV',
			submit: 'Guardar tarjeta de crédito',
			isPreferredPayAsYouGoPaymentMethod: 'Establecer como forma de pago preferido para facturación flexible',
		},
		taxExemption: {
			title: 'Enviar nuevo certificado de impuesto',
			taxAuthorityPlaceholder: 'Seleccionar autoridad fiscal',
			file: 'Archivo de exención de impuestos',
			declaration:
				'Bajo juramento, declaro que la información antes mencionada y la documentación complementaria está completa y es correcta.',
			success: 'El formulario de exención de impuestos se envió.',
		},
	},
	taxExemption: {
		taxAuthority: 'Autoridad fiscal',
		startDate: 'Fecha de inicio de exención',
		endDate: 'Fecha de finalización de exención',
		fileName: 'Nombre de archivo',
		status: {
			ELIGIBLE: 'Cumple los requisitos',
			NOT_ELIGIBLE: 'No cumple los requisitos',
		},
	},
	flexibleBilling: {
		paymentMethod: {
			title: 'Forma de pago',
			subtitle: 'Seleccione su opción de pago preferida',
			submitButton: 'Guardar forma de pago',
			success: 'La forma de pago preferida se guardó correctamente',
			addCardFirst: 'Agregue una tarjeta de crédito primero para activar la facturación automática.',
		},
	},
	creditCard: {
		entity: {
			number: 'Número de tarjeta',
			expiration: 'Expiración',
		},
		currentSource: {
			title: 'Facturación automática',
			subtitle: 'Su tarjeta guardada',
			loading: 'Recuperación de información de tarjeta',
			notFound: 'No tiene tarjetas guardadas.',
			create: 'Agregar nueva tarjeta',
		},
		message: {
			successCreate: 'La tarjeta se guardó correctamente.',
			successDelete: 'La tarjeta se eliminó correctamente.',
			saving: 'Guardar la tarjeta',
		},
		delete: {
			title: 'Eliminar la tarjeta de la cuenta',
			confirmation: '¿Realmente desea eliminar la tarjeta (•••• {{lastFourDigits}})?',
			loading: 'Eliminación de la tarjeta',
		},
		error: {
			bad_request: 'Tarjeta de crédito no válida. Vuelva a intentarlo o contacte con el Administrador de cuentas.',
		},
	},
	legacyPaymentOptions: {
		unableToLoad: 'No es posible cargar tarjetas almacenadas.',
		noStoredCards: 'No tiene tarjetas guardadas.',
	},
};
