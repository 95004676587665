import React, { ReactElement } from 'react';
import { Col, Row } from 'js/components/atoms/Row';
import { RouteName } from 'module/RouteName';
import { DataTable } from 'js/components/molecules/DataTable';
import { dateFormatter } from 'js/utils/dateTime';
import { Copy2Clipboard } from 'js/components/molecules/Copy2Clipboard/Copy2Clipboard';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { IEntityLicense } from 'module/licenses';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { buildParametrizedRoute } from 'js/utils/common';
import {
	extractLicenseSku,
	getFulfillmentValue,
	getLicenseContextValue,
	getLicenseExternalIds,
	getLicenseId,
	getLicenseType,
	getVariantColorForLicenseStatus,
	showEntitlementId,
} from 'module/licenses/utils/common';
import { LicenseDetailHeader } from 'module/licenses/components/LicenseDetailHeader';
import { CustomerGroup, DistributionPartnerGroup, PartnerGroup } from 'module/licenses/components/table';
import { LicenseContextIdEnum, licenseStatusEnumUtils } from 'module/licenses/enums';
import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuthContext } from 'js/contexts';

export const LicenseDetail = (): ReactElement => {
	const [t] = useTranslation(licensesConfig.trNamespace);
	const {
		data: license,
		query: { isFetching },
	} = usePageDetailContext<IEntityLicense>();
	const { orderId } = getLicenseExternalIds(license);
	const { isGroupPartner } = useAuthContext();

	return (
		<>
			<LicenseDetailHeader />
			<Row
				multi
				md={2}
			>
				<Col>
					<DataTable isLoading={isFetching}>
						<DataTable.Tr
							name={t('common:entity.productName')}
							highlighted
						>
							{license.product.productGroupName} ({extractLicenseSku(license)})
						</DataTable.Tr>
						{!isGroupPartner && (
							<DataTable.Tr
								name={t('entity.product.type')}
								highlighted
							>
								{license.product.productTypeName}
							</DataTable.Tr>
						)}
						<DataTable.Tr name={t('common:entity.unitType.SEATS', { count: license.product.dimension })}>
							{license.product.dimension}
						</DataTable.Tr>
						<DataTable.Tr name={t('entity.product.termLength')}>
							{t(`common:datePeriod.${license.product.validityUnit}`, { count: license.product.validity })}
						</DataTable.Tr>
						<DataTable.Tr name={t('entity.createdAt')}>
							{dateFormatter.toTimeZoneDateTime(license.createdAt)}
						</DataTable.Tr>
						<DataTable.Tr
							name={t('entity.modifiedAt')}
							defaultValue={t('common:_.NA')}
						>
							{dateFormatter.toTimeZoneDateTime(license.modifiedAt)}
						</DataTable.Tr>
						<DataTable.Tr
							name={t('entity.registeredAt')}
							defaultValue={t('common:_.NA')}
						>
							{dateFormatter.toTimeZoneDateTime(license.registeredAt)}
						</DataTable.Tr>
						<DataTable.Tr
							name={t('common:dates.expire')}
							defaultValue={t('common:_.NA')}
						>
							{dateFormatter.toTimeZoneDateTime(license.expiration)}
						</DataTable.Tr>
						<DataTable.Tr
							name={t('entity.orderId')}
							defaultValue={t('common:_.NA')}
						>
							{orderId ? <Link to={buildParametrizedRoute(RouteName.ORDER.DETAIL, orderId)}>{orderId}</Link> : null}
						</DataTable.Tr>
						<DataTable.Tr name={t('entity.entitlementId')}>{showEntitlementId(license.id)}</DataTable.Tr>
					</DataTable>
				</Col>
				<Col>
					<DataTable isLoading={isFetching}>
						<DataTable.Tr
							name={t('common:entity.licenseKey')}
							highlighted
						>
							<Copy2Clipboard value={getFulfillmentValue(license)} />
						</DataTable.Tr>
						<DataTable.Tr name={t('entity.walletKey')}>
							<Copy2Clipboard
								value={getLicenseContextValue(license, LicenseContextIdEnum.WALLET_KEY)}
								defaultValue={t('common:_.NA')}
							/>
						</DataTable.Tr>
						<DataTable.Tr name={t('entity.status')}>
							<Badge bg={getVariantColorForLicenseStatus(license.status)}>
								{licenseStatusEnumUtils.getText(license.status)}
							</Badge>
						</DataTable.Tr>
						<DataTable.Tr
							name={t('entity.licenseId')}
							defaultValue={t('common:_.NA')}
						>
							{getLicenseId(license)}
						</DataTable.Tr>
						<DataTable.Tr name={t('entity.licenseType')}>{getLicenseType(license)}</DataTable.Tr>

						<PartnerGroup />
						<DistributionPartnerGroup />
						<CustomerGroup />
					</DataTable>
				</Col>
			</Row>
		</>
	);
};
