import React, { ChangeEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { IEntityProductGroup } from 'module/purchase';
import { FormikControl } from 'js/components/formik/FormikControl';
import { TSelectVariantForm } from 'module/purchase/components/selectProducts';
import { CustomTable } from 'js/components/molecules/CustomTable';
import { FixedWidthWrapper } from 'js/components/molecules/Inputs/FixedWidthWrapper';
import { getProductValidityLabels } from 'module/purchase/utils/common';
import { CONFIG } from 'config';

export type TSelectProductVariantTableProps = {
	productGroup: IEntityProductGroup;
};

export const SelectProductVariantTable = (props: TSelectProductVariantTableProps): ReactElement => {
	const { productGroup } = props;
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { values, setFieldValue } = useFormikContext<TSelectVariantForm>();

	return (
		<CustomTable borderless>
			<thead>
				<tr>
					<CustomTable.Th />
					<CustomTable.Th />
					<CustomTable.Th align="center">{t('common:entity.quantity')}</CustomTable.Th>
				</tr>
			</thead>
			<tbody>
				{values.variant.map((row, i) => {
					const labels = getProductValidityLabels(row.product);
					const unitLabel = t(`common:entity.unitType.${productGroup.unitType}`, { count: row.unit });

					return (
						<tr key={i}>
							<CustomTable.Td>
								<FormikControl groupProps={{ className: 'mb-0' }}>
									<FormikControl.Checkbox
										testId={row.product.sku}
										name={`variant.${i}.checked`}
										label={labels.label}
										description={labels.description}
										onChange={(e: ChangeEvent<HTMLInputElement>) =>
											setFieldValue(`variant.${i}.quantity`, e.target.checked ? row.quantity || 1 : 0)
										}
									/>
								</FormikControl>
							</CustomTable.Td>
							<CustomTable.Td className="font-italic text-gray	">
								{row.unit} {unitLabel.toLowerCase()}
							</CustomTable.Td>
							<CustomTable.Td align="center">
								<FixedWidthWrapper width={160}>
									<FormikControl groupProps={{ className: 'mb-0' }}>
										<FormikControl.Number
											testId={`${row.product.sku}_quantity`}
											name={`variant.${i}.quantity`}
											min={0}
											max={CONFIG.MAX_QUANTITY.RETAIL_PARTNERS}
											size="sm"
											onChange={(value) => {
												setFieldValue(`variant.${i}.checked`, value && value > 0, true);
											}}
										/>
									</FormikControl>
								</FixedWidthWrapper>
							</CustomTable.Td>
						</tr>
					);
				})}
			</tbody>
		</CustomTable>
	);
};
