import { useApi } from 'js/hooks/api';
import { useCallback } from 'react';
import { IEntityPartner, IEntityPartnerDetail } from 'module/partners';
import { fillUrlParameters } from 'js/utils/common';
import { API_PARTNERS_KEYS } from 'module/partners/hooks/useApiPartners';
import type { AxiosRequestConfig } from 'axios';

export const useAsyncApiPartnerDetail = () => {
	const api = useApi();

	return useCallback(
		(id: IEntityPartner['id'], config?: AxiosRequestConfig) => {
			const [url] = fillUrlParameters(API_PARTNERS_KEYS.DETAIL, { id });
			return api.get<IEntityPartnerDetail>(`/${url}`, config);
		},
		[api],
	);
};
