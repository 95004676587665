import { FormikControl } from 'js/components/formik/FormikControl';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useFormikContext } from 'formik';
import { getLicenseOperationEntities } from 'module/licenses/utils/licenseOperation';
import { ILicenseOperationForm } from 'module/licenses';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';

export const SelectProduct = () => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { licenseProductOperations } = useLicenseOperationContext();
	const {
		values: { licenseOperation },
		setFieldValue,
	} = useFormikContext<ILicenseOperationForm>();
	const { operation: productLicenseOperation } = getLicenseOperationEntities(
		licenseProductOperations,
		licenseOperation,
	);
	const isSingleProductGroup = (productLicenseOperation?.productGroups || []).length === 1;

	if (!productLicenseOperation || isSingleProductGroup) {
		return null;
	}

	return (
		<FormikControl
			groupProps={{ className: 'mb-0' }}
			label={t('common:entity.product')}
		>
			<FormikControl.SingleSelect
				name="productGroupCode"
				options={productLicenseOperation.productGroups.map((productGroup) => ({
					value: productGroup.code,
					label: `${productGroup.name} [${productGroup.code}]`,
				}))}
				onChange={(value) => {
					const productGroup = productLicenseOperation.productGroups.find(
						(productGroup) => productGroup.code === value,
					);
					// Set first product group as selected
					setFieldValue('variant', productGroup?.products[0].sku || null);
				}}
				required
				isClearable={false}
			/>
		</FormikControl>
	);
};
