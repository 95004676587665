import React, { ReactElement } from 'react';
import { Modal } from '@avast/react-ui-components';
import { Table } from 'react-bootstrap';
import { TDetailItem } from 'module/promotions/components/DetailList';

export type TDetailListModalContentProps = {
	title: string;
	values: TDetailItem[];
};

export const DetailListModalContent = (props: TDetailListModalContentProps): ReactElement => {
	const { title, values } = props;

	return (
		<>
			<Modal.Header className="pb-2">{title}</Modal.Header>
			<Modal.Body className="py-md-4">
				<Table>
					<tbody>
						{values?.map((item, index) => (
							<tr key={index}>
								<td
									className="p-2"
									colSpan={item.description ? 1 : 2}
									style={{ width: '70%' }}
								>
									{item.name}
								</td>
								{item.description && <td className="p-2 fs-md text-gray">{item.description}</td>}
							</tr>
						))}
					</tbody>
				</Table>
			</Modal.Body>
		</>
	);
};
