export default {
	entity: {
		createdBy: 'Creato da',
	},
	actions: {
		create: 'Crea limite di credito',
	},
	error: {
		createCreditLimit: 'Impossibile creare il limite di credito.',
	},
	modal: {
		create: {
			title: 'Crea limite di credito',
			loading: 'Creazione limite di credito',
			success: 'Creazione limite di credito completata',
		},
	},
};
