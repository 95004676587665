import React, { createContext, PropsWithChildren, useContext } from 'react';
import { IAxiosApiError, TApiErrorResolve } from 'types/api';
import { apiErrorResolve, apiErrorWrapper } from 'js/utils/apiError';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { ApiErrorLevelEnum } from 'js/enums';
import { useAppContext } from 'js/contexts/AppContext';

export interface IApiErrorContextState {
	error: IAxiosApiError;
	onClose?: () => void;
	onRetry?: () => void;
	resolve?: TApiErrorResolve;
}

export interface IApiErrorContext {
	setError(state: IApiErrorContextState | null): Promise<void>;
}

const ApiErrorContext = createContext<IApiErrorContext>({} as IApiErrorContext);
ApiErrorContext.displayName = 'ApiErrorContext';

export const useApiErrorContext = () => useContext(ApiErrorContext);

export const ApiErrorContextProvider = ({ children }: PropsWithChildren<{}>) => {
	const { apiErrorModalRef } = useAppContext();

	return (
		<ApiErrorContext.Provider
			value={{
				async setError(errorState) {
					if (!errorState) {
						apiErrorModalRef.current?.hide();
						return;
					}

					const error = apiErrorWrapper(errorState.error, errorState.resolve || apiErrorResolve);
					if (error) {
						switch (error.level) {
							case ApiErrorLevelEnum.INFO:
								toast.error(<Toast caption={error.title}>{error.messages?.join('<br />')}</Toast>);
								errorState.onClose?.();
								break;
							case ApiErrorLevelEnum.CRITICAL:
							default:
								await apiErrorModalRef.current?.show(
									{
										error,
										onRetry: errorState.onRetry
											? () => {
													apiErrorModalRef.current?.onCancel();
													errorState.onRetry?.();
											  }
											: undefined,
									},
									{ onHide: errorState.onClose },
								);
								break;
						}
					}
				},
			}}
		>
			{children}
		</ApiErrorContext.Provider>
	);
};
