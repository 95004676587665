import React, { FunctionComponent } from 'react';
import { ordersConfig } from 'module/orders/ordersConfig';
import { OrderRoutes } from 'module/orders/OrderRoutes';
import { ModuleContainer } from 'js/layouts/container/ModuleContainer';

export const OrdersContainer: FunctionComponent = () => (
	<>
		<ModuleContainer moduleMenuConfig={ordersConfig} />
		<OrderRoutes />
	</>
);
