import React from 'react';
import { logError } from 'js/utils/app';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { IEntityOrder } from 'module/orders';
import { usePageDetailContext } from 'js/contexts';
import { Alert, Modal } from '@avast/react-ui-components';
import { usePaymentInstructions } from 'module/orders/hooks/usePaymentInstructions';

export const PaymentInstructionsModal = () => {
	const [t] = useTranslation(ordersConfig.trNamespace);
	const { data: standardOrder } = usePageDetailContext<IEntityOrder>();
	const { content, error } = usePaymentInstructions(standardOrder);

	return (
		<>
			<Modal.Header>{t('paymentInstructions.title')}</Modal.Header>
			<Modal.Body>
				{(() => {
					if (error) {
						logError(error);
					}

					if (!content) {
						return (
							<Alert
								variant="danger"
								caption={t('paymentInstructions.error.notAvailable')}
							/>
						);
					}

					return content;
				})()}
			</Modal.Body>
			<Modal.Footer cancelButton />
		</>
	);
};
