import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { CONFIG } from 'config';
import { TStandardOrderInstanceAddItem } from 'module/purchase';
import { useAppContext, useOrderContext } from 'js/contexts';

type TUseMaxCartItemsGuardResponse = (items: TStandardOrderInstanceAddItem[]) => Promise<boolean>;

/**
 * Resolve if the cart is possible to add desired amount of products to the cart
 * @returns {TUseMaxCartItemsGuardResponse}
 */
export const useMaxCartItemsGuard = (): TUseMaxCartItemsGuardResponse => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { confirmationModalRef } = useAppContext();
	const { orderState } = useOrderContext();

	return useCallback(
		async (items) => {
			const newQuantity = orderState.items.length + items.length;
			const maxQuantity = CONFIG.PURCHASE.MAX_ITEMS;

			// Current price list is also included in new list
			if (newQuantity <= maxQuantity) {
				return true;
			}

			// Inform the user
			await confirmationModalRef.current?.show({
				title: t('guards.maxCartItemsGuard.title'),
				messages: [t('guards.maxCartItemsGuard.message', { count: maxQuantity })],
				hideSubmitButton: true,
			});

			return false;
		},
		[orderState.items, confirmationModalRef, t],
	);
};
