export default {
	common: {
		allPartnerType: 'All partners',
		avastId: 'Avast Partner ID',
		loadingDetail: 'Loading Partner Detail',
	},
	error: {
		unableToLoadTerms: 'Unable to load Terms and Conditions.',
		unableToLoadPartnerDetail: 'Unable to load Partner detail',
	},
	entity: {
		accountType: 'Type',
		accountSubType: 'Subtype',
		company: 'Company',
		email: 'E-mail',
		id: 'Partner ID',
		salesforceId: 'Salesforce ID',
		name: 'Full Name',
	},
	select: {
		title: 'Select Partner',
		button: {
			label: 'Partner',
			placeholder: 'Select partner',
		},
		filter: {
			placeholder: 'Find partner',
		},
	},
};
