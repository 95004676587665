/* eslint-disable no-template-curly-in-string */
export default {
	maxFileSize: '最大ファイル サイズ: {{maxSize}} MB。',
	acceptFileType: 'サポートされるファイルの種類: {{types}}。',
	acceptFileType_other: 'サポートされるファイルの種類: {{types}}。',
	actions: {
		reset: 'フォームをリセット',
	},
	error: {
		file: {
			type: '選択したファイルの種類はサポートされていません。',
			maxSize: '選択したファイルのサイズ {{size}} MB が大きすぎます (最大サイズは {{maxSize}} MB)。',
		},
	},
	validator: {
		array: {
			max: '${path} フィールドの項目数は ${max} 個以下にする必要があります。',
			min: '${path} フィールドの項目数は ${max} 個以上にする必要があります。',
		},
		date: {
			max: '${path} フィールドは ${max} より前にする必要があります。',
			min: '${path} フィールドは ${min} より後にする必要があります。',
		},
		mixed: {
			default: '${path} は無効です。',
			notOneOf: '${path} は次の値以外にする必要があります: ${values}。',
			oneOf: '${path} は次のいずれかの値にする必要があります: ${values}。',
			required: '${path} は必須フィールドです。',
		},
		number: {
			integer: '${path} は整数にする必要があります。',
			lessThan: '${path} は ${less} より小さくする必要があります。',
			max: '${path} は ${max} 以下にする必要があります。',
			min: '${path} は ${min} 以上にする必要があります。',
			moreThan: '${path} は ${more} より大きくする必要があります。',
			negative: '${path} は負の数にする必要があります。',
			positive: '${path} は正の数にする必要があります。',
		},
		string: {
			email: '${path} は有効なメール アドレスにする必要があります。',
			length: '${path} は ${length} 文字にする必要があります。',
			link: '${path} は有効な URL にする必要があります。',
			lowercase: '${path} は英字の小文字の文字列にする必要があります。',
			matches: '${path} は次に一致にする必要があります:「${regex}」。',
			max: '${path} は ${max} 文字以下にする必要があります。',
			min: '${path} は ${min} 文字以上にする必要があります。',
			trim: '${path} はトリミングした文字列にする必要があります。',
			uppercase: '${path} は英字の大文字の文字列にする必要があります。',
			url: '${path} は有効な URL にする必要があります。',
			uuid: '${path} は有効な UUID にする必要があります。',
		},
	},
};
