import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'js/components/atoms/Row';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { usePageDetailContext } from 'js/contexts';
import { IEntityOrder } from 'module/orders';
import { Note } from 'js/components/molecules/Note';
import { purchaseConfig } from 'module/purchase/purchaseConfig';

export const NotesTab = (): ReactElement => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { data } = usePageDetailContext<IEntityOrder>();

	return (
		<DefaultContainer>
			<Row
				multi
				size="sm"
				justify="between"
				align="center"
			>
				<Col
					md="6"
					className="py-lg-0"
				>
					<Note header={t('entity.publicNotes')}>{data.publicNotesHeader}</Note>
				</Col>
				<Col
					md="6"
					className="py-lg-0 clearfix"
				>
					<Note header={t('entity.privateNotes')}>{data.privateNotesHeader}</Note>
				</Col>
			</Row>
		</DefaultContainer>
	);
};
