import React from 'react';
import { Alert, Button, Modal } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { Tip } from 'assets/image/icon';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { useFormikContext } from 'formik';
import { ILicenseOperationForm } from 'module/licenses';
import { getLicenseOperationEntities } from 'module/licenses/utils/licenseOperation';
import { getLicenseOperationComparativeData } from 'submodule/comparativeFeatures/comparativeFeaturesUtils';
import { ComparativeFeaturesTable } from 'submodule/comparativeFeatures/ComparativeFeaturesTable';

export const ComparativeFeaturesLicenseOperationWidget = () => {
	const { licenseProductOperations } = useLicenseOperationContext();
	const {
		values: { licenseOperation, productGroupCode, licenseBillableParty },
		setFieldValue,
	} = useFormikContext<ILicenseOperationForm>();
	const { operation } = getLicenseOperationEntities(licenseProductOperations, licenseOperation);
	const [t] = useTranslation('submodules');
	const ref = useAsyncModalRef();

	if (!productGroupCode || !operation?.productGroups) {
		return null;
	}

	const comparativeData = getLicenseOperationComparativeData(operation.productGroups, productGroupCode);

	if (!comparativeData) {
		return null;
	}

	return (
		<>
			<Alert
				variant="success"
				caption={t('comparativeFeatures.title')}
				className="mt-3"
				icon={<Tip />}
			>
				<p>{t('comparativeFeatures.message')}</p>
				<p className="mt-2 pt-1">
					<Button
						variant="primary"
						onClick={() => ref.current?.show()}
						size="xs"
						testId="comparativeFeatures"
					>
						{t('comparativeFeatures.action')}
					</Button>
				</p>
			</Alert>
			<AsyncModal
				size="lg"
				ref={ref}
				testId="comparativeFeatures"
			>
				<Modal.Header>{t('comparativeFeatures.title')}</Modal.Header>
				<Modal.Body>
					<ComparativeFeaturesTable
						comparativeData={comparativeData}
						currencyCode={licenseBillableParty?.currencyCode}
						onSelect={(group) => {
							if (group) {
								setFieldValue('productGroupCode', group.code);
								setFieldValue('variant', group.products[0].sku || null);
							}
							ref.current?.hide();
						}}
					/>
				</Modal.Body>
			</AsyncModal>
		</>
	);
};
