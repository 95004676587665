import { Formik } from 'formik';
import React from 'react';
import { FormikForm } from 'js/components/formik/FormikForm';
import { FormikControl, FormikControlRadio } from 'js/components/formik/FormikControl';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import { FormikHelpers } from 'formik/dist/types';
import { Card, FormMessage } from '@avast/react-ui-components';
import { SubmitButton } from 'js/components/atoms/Button';
import { PayAsYouGoPaymentMethodEnum, PayAsYouGoPaymentMethodEnumUtils } from 'module/partners/enums';

interface IFlexibleBillingForm {
	paymentMethod?: PayAsYouGoPaymentMethodEnum;
}

type TFlexibleBillingFormProps = {
	autoBillingDisabled?: boolean;
	initialValues: IFlexibleBillingForm;
	onSubmit(values: IFlexibleBillingForm, formikHelpers: FormikHelpers<IFlexibleBillingForm>): void;
};

export const FlexibleBillingForm = (props: TFlexibleBillingFormProps) => {
	const { onSubmit, autoBillingDisabled, initialValues } = props;
	const [t] = useTranslation(accountConfig.trNamespace);

	return (
		<Formik<IFlexibleBillingForm>
			initialValues={initialValues}
			onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers)}
		>
			<FormikForm testId="creditCard">
				<Card.Body>
					<FormikControlRadio
						name="paymentMethod"
						groupProps={{ className: 'mb-3' }}
						required
					>
						{PayAsYouGoPaymentMethodEnumUtils.getSelectOptions().map((option) => (
							<FormikControl.Radio
								key={option.value}
								name="paymentMethod"
								value={option.value}
								label={option.label}
								description={option.description as string}
								disabled={option.value === PayAsYouGoPaymentMethodEnum.AUTO_BILLING && autoBillingDisabled}
							/>
						))}
					</FormikControlRadio>
					{autoBillingDisabled && (
						<FormMessage type="warning">{t('flexibleBilling.paymentMethod.addCardFirst')}</FormMessage>
					)}
				</Card.Body>

				<Card.Footer className="text-center">
					<SubmitButton
						size="sm"
						testId="submitForm"
						disabled={autoBillingDisabled && initialValues.paymentMethod === PayAsYouGoPaymentMethodEnum.LINE_OF_CREDIT}
					>
						{t('flexibleBilling.paymentMethod.submitButton')}
					</SubmitButton>
				</Card.Footer>
			</FormikForm>
		</Formik>
	);
};
