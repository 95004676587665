import { TransactionTypeEnum, transactionTypeEnumUtils } from 'module/licenses/enums';
import { get, invert } from 'lodash';
import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum LicenseOperationEnum {
	NEW = 'NEW',
	RENEWAL = 'RENEWAL',
	INCREASE = 'INCREASE',
	INCREASE_UPGRADE = 'INCREASE_UPGRADE',
	UPGRADE = 'UPGRADE',
	RENEWAL_INCREASE = 'RENEWAL_INCREASE',
	RENEWAL_DECREASE = 'RENEWAL_DECREASE',
	RENEWAL_UPGRADE = 'RENEWAL_UPGRADE',
	RENEWAL_DOWNGRADE = 'RENEWAL_DOWNGRADE',
}

const operationToTransaction: Record<LicenseOperationEnum, TransactionTypeEnum> = {
	[LicenseOperationEnum.NEW]: TransactionTypeEnum.NEW,
	[LicenseOperationEnum.RENEWAL]: TransactionTypeEnum.RENEW,
	[LicenseOperationEnum.INCREASE]: TransactionTypeEnum.INCREASE,
	[LicenseOperationEnum.INCREASE_UPGRADE]: TransactionTypeEnum.INCREASE_AND_UPGRADE,
	[LicenseOperationEnum.UPGRADE]: TransactionTypeEnum.UPGRADE,
	[LicenseOperationEnum.RENEWAL_INCREASE]: TransactionTypeEnum.RENEW_AND_INCREASE,
	[LicenseOperationEnum.RENEWAL_DECREASE]: TransactionTypeEnum.RENEW_AND_DECREASE,
	[LicenseOperationEnum.RENEWAL_UPGRADE]: TransactionTypeEnum.RENEW_AND_UPGRADE,
	[LicenseOperationEnum.RENEWAL_DOWNGRADE]: TransactionTypeEnum.RENEW_AND_DOWNGRADE,
};
const transactionToOperation = invert(operationToTransaction) as Record<TransactionTypeEnum, LicenseOperationEnum>;

interface ILicenseOperationKeyEnumExtend {
	getTransactionType(operation?: LicenseOperationEnum): TransactionTypeEnum;

	getFromTransactionType(operation: TransactionTypeEnum): LicenseOperationEnum;
}

export const licenseOperationEnumUtils = generateEnumUtils<LicenseOperationEnum, ILicenseOperationKeyEnumExtend>(
	LicenseOperationEnum,
	{
		getFromTransactionType(operation) {
			return get(transactionToOperation, operation, LicenseOperationEnum.NEW);
		},
		getTransactionType(operation) {
			if (!operation) {
				return TransactionTypeEnum.NEW;
			}
			return operationToTransaction[operation];
		},
		getText(operation) {
			return transactionTypeEnumUtils.getText(this.getTransactionType(operation));
		},
	},
);
