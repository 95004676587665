import { apiConfig, apiDigitalRiverConfig, apiSmartChannelConfig } from 'api';
import { CONFIG } from 'config';
import type { AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import axios, { AxiosError } from 'axios';
import { object2UrlParams } from 'js/utils/common';
import { axiosLogger, errorIsCatchable } from 'js/utils/app';
import { isExpiredJwtToken, isUnauthorized } from 'js/utils/response';
import { refreshTokenService } from 'api/refreshTokenService';
import { noop } from 'lodash';

export const setupInterceptors = (api: AxiosInstance) => {
	api.interceptors.request.use(
		(config: InternalAxiosRequestConfig) => {
			const { apiVersion } = config;
			if (apiVersion !== null) {
				config.url = `/v${config.apiVersion || CONFIG.API_VERSION}${config.url}`;
			}

			config.paramsSerializer = {
				serialize(params) {
					return object2UrlParams(params);
				},
			};

			// JWT token
			if (config.authToken) {
				const token = refreshTokenService.resolveCurrentToken(config.authToken);
				config.headers.Authorization = `Bearer ${token}`;
			}

			return config;
		},
		(error) => Promise.reject(error),
	);

	api.interceptors.response.use(
		(response) => response,
		async (error: AxiosError) => {
			const { config, response } = error;

			// Canceled request by script
			if (axios.isCancel(error)) {
				axiosLogger.log('Request canceled', error);
				return;
			}

			// Action based on response status
			if (response?.status) {
				// Refresh token
				if (isExpiredJwtToken(error) && !config?.isRetry) {
					const refetchConfig: AxiosRequestConfig = { ...config, isRetry: true, apiVersion: null };
					const refreshTokenResponse = await refreshTokenService
						.subscribe(api, config?.refreshToken, config?.authToken)
						.catch(noop);

					if (refreshTokenResponse) {
						// Update auth token
						refetchConfig.onRefreshToken?.(refreshTokenResponse);
						refetchConfig.authToken = refreshTokenResponse.token;

						return api(refetchConfig);
					}
				}

				if (isUnauthorized(error) && config?.onNonAuthorized) {
					await config.onNonAuthorized();
					axiosLogger.log('Unauthorized user', error);
					return;
				}
			}

			axiosLogger.error(error);
			return Promise.reject(error);
		},
	);
};

export const setupDigitalRiverInterceptors = (api: AxiosInstance) => {
	api.interceptors.response.use(undefined, (error: AxiosError) => {
		if (errorIsCatchable(error)) {
			console.error('[digital-river]', error, error?.response?.data);
		}
		return Promise.reject(error);
	});
};

export const createAxiosInstance = (config: AxiosRequestConfig = {}): AxiosInstance => {
	// Get config
	let defaultConfig: AxiosRequestConfig;
	switch (config.apiType) {
		case 'DR':
			defaultConfig = apiDigitalRiverConfig;
			break;
		case 'SCH':
			defaultConfig = apiSmartChannelConfig;
			break;
		case 'OMS':
		default:
			defaultConfig = apiConfig;
			break;
	}

	// Create instance
	const api = axios.create({
		...defaultConfig,
		...config,
	});

	// Add interceptors
	switch (config.apiType) {
		case 'DR':
			setupDigitalRiverInterceptors(api);
			break;
		case 'SCH':
			break;
		case 'OMS':
		default:
			setupInterceptors(api);
			break;
	}

	return api;
};
