export default {
	actions: {
		create: 'Crea nuova promozione',
		edit: 'Modifica promozione',
		disable: {
			action: 'Disattiva promozione',
			success: 'La promozione è stata disattivata',
			loading: 'Disattivazione promozione...',
			confirmation: {
				title: 'Disattivazione promozione',
				message: 'Disattivare la promozione? L’azione è irreversibile.',
			},
		},
		form: {
			create: 'Invia promozione',
			update: 'Aggiorna promozione',
		},
		extraFilters: 'Più filtri',
		export: 'Esporta promozioni',
	},
	common: {
		effectiveDate: 'Data di entrata in vigore',
		filterPromotions: 'Filtra promozioni',
		partyCriteria: 'Criteri delle parti',
		partyCriteriaInfo: 'Seleziona un partner o almeno un criterio delle parti per continuare.',
		productCriteria: 'Criteri di prodotto',
		creatingPromotion: 'Creazione promozione',
		updatingPromotion: 'Aggiornamento promozione',
		invalidPromotionStartDate: {
			title: 'Salvare la promozione?',
			message: 'La data di inizio della promozione è già trascorsa. Non è più possibile modificare la promozione.',
		},
	},
	error: {
		promotionNotFound: 'Promozione non trovata.',
		createPromotion: 'Impossibile creare la promozione.',
		updatePromotion: 'Impossibile aggiornare la promozione.',
	},
	page: {
		create: {
			title: 'Nuova promozione sui prezzi',
			success: 'Nuova promozione sui prezzi creata.',
		},
		update: {
			title: 'Aggiorna promozione n. {{id}}',
			success: 'Promozione suo prezzi aggiornata.',
			notEditable: 'Impossibile modificare la promozione corrente perché potrebbe essere associata a un ordine.',
		},
	},
	placeholder: {
		types: 'Tutti i tipi di promozione',
		partyTiers: 'Tutti i livelli',
		categories: 'Tutte le categorie',
		licenseOperations: 'Tutte le operazioni sulle licenze',
		partyCountries: 'Tutti i paesi',
		partyTypes: 'Tutti i tipi di parti',
		products: 'Tutti i prodotti',
	},
	entity: {
		id: 'ID promozione',
		name: 'Nome promozione',
		date: 'Intervallo date di validità',
		partyType: 'Tipo di parte',
		partyType_other: 'Tipi di parti',
		partyTier: 'Livello parte',
		partyTier_other: 'Livelli parti',
		partyCountry: 'Paese parte',
		partyCountry_other: 'Paesi parti',
		licenseOperation: 'Tipo di operazioni sulle licenze',
		licenseOperation_other: 'Tipi di operazioni sulle licenze',
		category: 'Categoria prodotto',
		product: 'Prodotto',
		product_other: 'Prodotti',
		quantityMin: 'Quantità minima',
		rate: 'Percentuale di sconto',
		scope: 'Ambito',
		type: 'Tipo di promozione',
		unitsFrom: 'Unità a partire da',
		unitsTo: 'Unità fino a',
	},
	tooltip: {
		zeroQuantity:
			'Se la quantità è pari a zero, la promozione verrà applicata indipendentemente dal numero di licenze ordinate.',
	},
};
