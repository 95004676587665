export default {
	common: {
		continue: 'Poursuivre l’inscription',
		skip: 'Ignorer pour l’instant',
		submitting: 'Envoi de vos données...',
		finishing: 'Nous finalisons votre inscription...',
		successRegistration: 'Vous êtes maintenant inscrit(e).',
		successAcceptTerms: 'Les conditions générales ont été acceptées. Merci.',
	},
	steps: {
		init: 'Bienvenue',
		terms: 'Conditions générales',
		registrationInfo: 'Informations essentielles',
		taxExemption: 'Exonération fiscale',
	},
	page: {
		welcome: {
			title: 'Bienvenue dans le processus d’inscription e-commerce',
			content: 'Vous serez guidé tout au long des étapes nécessaires à l’inscription et il vous sera demandé :',
			conditionList: [
				'd’accepter les conditions générales',
				'de mettre à jour et de confirmer les informations essentielles de votre profil',
			],
		},
		terms: {
			title: 'Veuillez consulter nos conditions générales et les accepter :',
			button: 'J’ai lu le(s) contrat(s) et j’en accepte les conditions',
		},
		registrationInfo: {
			contactInformation: 'Coordonnées',
			accountInformation: 'Informations du compte',
		},
		taxExemption: {
			title: 'Envoyez les détails de votre exonération fiscale.',
			subtitle: 'Remplissez les informations demandées concernant votre exonération fiscale.',
		},
	},
	error: {
		unableToInit: 'Impossible d’initier l’inscription.',
		unableToRegistrationToken: 'Impossible d’obtenir le jeton d’inscription.',
		unableToStart: 'Impossible de commencer l’inscription.',
		unableToLoadTerms:
			'Impossible de charger les Conditions générales. Contactez votre responsable de compte pour obtenir de l’aide.',
		unableToAcceptTerms: 'Impossible d’accepter les conditions du partenaire actuel.',
		unableToProcess:
			'Nous sommes actuellement dans l’impossibilité d’envoyer vos données pour qu’elles soient traitées.',
	},
	alert: {
		checkCurrentAccountInfo:
			'Nous avons complété les informations actuellement enregistrées pour votre compte dans nos systèmes. Vérifiez ces informations et envoyez des mises à jour si nécessaire. Toute modification envoyée sera enregistrée en vue d’une utilisation ultérieure.',
		changeAccountDataInPartnerPortal:
			'Si vous avez besoin de modifier vos coordonnées, terminez le processus d’inscription et rendez-vous dans la section Mon profil du Portail des partenaires Avast Business pour modifier les informations de votre profil.',
	},
};
