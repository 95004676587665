import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { IEntityProduct, IEntityProductGroup } from 'module/purchase';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { TableColumnsDef } from 'types';
import { constant } from 'lodash';

type TTableData = IEntityProductGroup;

export const useSelectProductsColumns = (onClick: (row: TTableData) => void): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(purchaseConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.hidden('code'),
			columnHelper.text('name', {
				header: t('common:entity.productName'),
				meta: { formatters: [formatter.bold] },
			}),
			columnHelper.text('products', {
				id: 'products',
				header: constant(null),
				enableSorting: false,
				meta: {
					formatters: [
						(products: IEntityProduct[]) => (
							<em className="text-gray">{t('common:entity.variantCount', { count: products.length })}</em>
						),
					],
				},
			}),
			columnHelper.actions({
				button: {
					children: t('table.action.selectVariant'),
					onClick,
					testId: 'selectVariant',
				},
			}),
		];
	}, [t, onClick]);
};
