import React, { ReactElement } from 'react';
import type { TButtonProps } from '@avast/react-ui-components';
import { Button } from '@avast/react-ui-components';
import { useFormikContext } from 'formik';

/**
 * Submit button for Formik forms
 * @param {TButtonProps} props
 * @returns {ReactElement}
 * @constructor
 */
export const SubmitButton = (props: TButtonProps): ReactElement => {
	const { isSubmitting } = useFormikContext();
	const { loading, ...rest } = props;
	return (
		<Button
			testId="submit"
			type="submit"
			variant="primary"
			loading={loading || isSubmitting}
			{...rest}
		/>
	);
};
