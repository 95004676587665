export default {
	common: {
		continueToProducts: 'Vai ai prodotti',
		orderValue: 'Valore ordine',
		remaining: 'Rimanente',
		partyPrefix: {
			your: 'Il tuo',
			partner: 'di dist.',
			srp: 'SRP',
			customer: 'Cliente',
			quotedCustomer: 'Cliente con preventivo',
		},
		savingOrder: 'Salvataggio ordine in corso...',
		exportingOrder: 'Esportazione ordine in corso...',
		loadingOrder: 'Caricamento di un ordine in corso...',
		customerCreditStatus: {
			eligible: 'Il cliente ha diritto al credito.',
			notEligible: 'Il cliente non ha diritto al credito.',
		},
		previousLicense: 'Chiave di licenza precedente',
		currency: {
			changeTooltip: 'Modifica valuta',
			selectPrompt: 'Seleziona la valuta del cliente',
		},
		paginationText: 'Da <strong>{{from}} a {{to}}</strong> voci su {{total}} visualizzate.',
	},
	discretionaryDiscount: {
		title: 'Sconto discrezionale',
		placeholder: 'Inserisci uno sconto',
		approvalFlag: 'Stato approvazione',
		amount: 'Importo',
		amountUnit: 'Importo unitario',
		amountTotal: 'Importo totale',
		status: {
			notSet: 'Nessuno sconto discrezionale impostato per l’ordine.',
			lineNotSet: 'Nessuno sconto discrezionale impostato per la linea.',
			orderLevelDiscountIsSet:
				'A questo ordine è stato applicato uno sconto a livello di ordine. Sconti a livello di linea disabilitati.',
			lineLevelDiscountIsSet:
				'A questo ordine sono stati applicati sconti a livello di linea. Sconto a livello di ordine disabilitato.',
		},
		action: {
			resetLineDiscounts: 'Rimuovi sconti a livello di linea',
		},
		waitingApprovalMessage:
			'All’ordine è stato applicato uno sconto discrezionale che richiede l’approvazione della divisione vendite.',
	},
	error: {
		submitOrder: {
			title: 'Impossibile inviare un ordine',
			general: 'Si è verificato un errore e non è stato possibile inviare l’ordine. Riprova.',
		},
		pricing: {
			title: 'Impossibile calcolare il prezzo',
		},
		context: {
			setPartnerFailed: 'Non è stato possibile aggiungere il partner all’ordine.',
		},
		creditExceed: {
			title: 'Questa transazione supererà la quantità di credito disponibile per il conto.',
			text: 'Per questa transazione non è disponibile un metodo di pagamento a credito. Usa l’opzione di pagamento del carrello.',
		},
		requiredCustomer: {
			title: 'Dati del cliente richiesti',
			text: 'Seleziona un cliente per completare l’acquisto.',
			action: 'Seleziona cliente',
			tooltip: {
				question: 'Perché è necessario fornire i dati del cliente quando si effettua un ordine?',
				answer:
					'Ti chiediamo di inserire i dati del cliente quando effettui un ordine per raccogliere maggiori informazioni sui clienti finali. Il fatto di conoscerli meglio, e di comprenderne abitudini, esigenze e preferenze, ci consente di offrire sia a te che a loro un servizio migliore.',
			},
		},
		requiredDistributionPartner: {
			title: 'Dati del partner di distribuzione richiesti',
			text: 'Seleziona un partner di distribuzione per completare l’acquisto.',
			action: 'Seleziona partner di dist.',
			tooltip: {
				question: 'Perché è necessario fornire i dati del partner di distribuzione quando si effettua un ordine?',
				answer:
					'Quando effettui un ordine, ti chiediamo di fornire i dati del partner di distribuzione e del cliente del partner di distribuzione per aiutarci a conoscerli meglio e a comprenderne abitudini, esigenze e preferenze, in modo da offrire un servizio migliore sia a te che a loro.',
			},
		},
	},
	components: {
		successOrder: {
			title: 'Operazione completata!',
			action: {
				NEW_ORDER: 'Nuovo ordine',
				DETAIL: 'Dettagli ordine',
				QUOTE_DETAIL: 'Dettaglio preventivo',
				LICENSES: 'Visualizza licenze',
				EDIT: 'Modifica ordine',
				QUOTE_EDIT: 'Modifica preventivo',
				PAYMENT_INSTRUCTIONS: 'Istruzioni di pagamento',
			},
		},
		editPrices: {
			title: 'Modifica prezzi unitari',
			message:
				'È possibile modificare solo il prezzo unitario degli articoli attualmente presenti nel carrello. Gli ordini salvati da questa schermata non possono essere modificati. Assicurati di non volere più apportare modifiche al carrello prima di procedere.',
			button: 'Modifica prezzi',
			columnLabel: 'Prezzo unitario personalizzato',
			submit: 'Salva ordine',
			confirmation: {
				message: 'Verrà creato un ordine che può essere completato, ma non modificato. Vuoi procedere?',
				success: 'Sì, salva l’ordine',
			},
		},
		customerQuote: {
			error: {
				invalidItems:
					'Per salvare l’ordine e visualizzare un’anteprima o inviare l’e-mail sono necessari i prezzi unitari personalizzati.',
			},
			fields: {
				customerEmail: 'A',
				partnerEmail: 'CC',
				message: 'Messaggio personale',
			},
			submit: 'Invia e-mail',
		},
	},
	page: {
		default: {
			title: 'Dettagli ordine',
		},
		products: {
			title: 'Prodotti',
		},
		order: {
			title: 'Rivedi e conferma',
		},
	},
	unit: {
		current: '{{type}} correnti',
		toIncrease: '{{type}} aggiuntivi',
		toDecrease: 'Rimuovi {{type}}',
		total: 'Totale {{type}}',
	},
	entity: {
		privateNotes: 'Nota privata',
		publicNotes: 'Nota pubblica',
		opportunityId: 'Numero opportunità',
		marketSegment: 'Segmento',
		validityLabel: 'Per {{value}}',
		validityDescription: 'Durata {{value}}',
	},
	pricing: {
		basePrice: 'Da {{price}}',
		discount: {
			segment: 'Sconto segmento',
			renewal: 'Sconto rinnovo',
			partner: 'Sconto partner',
			discretionary: 'Sconto discrezionale',
			promo: 'Sconto promozionale',
		},
		details: {
			show: 'Mostra dettagli prezzo',
			hide: 'Nascondi dettagli prezzo',
		},
	},
	actions: {
		addNote: 'Aggiungi una nota',
		addToCart: 'Aggiungi al carrello',
		goToCart: 'Vai al carrello',
		exportCart: 'Esporta carrello',
	},
	message: {
		addToCart: {
			item: '- <strong>{{name}}</strong> - {{unit}} {{unitLabel}}, {{quantity}}x',
			title: 'Prodotto aggiunto al carrello.',
		},
		removedFromCart: 'Il prodotto è stato rimosso dal carrello.',
		emptyCart: 'Tutti i prodotti sono stati rimossi dal carrello.',
		emptyCartConfirmation: 'Rimuovere tutti i prodotti dal carrello?',
	},
	table: {
		action: {
			selectVariant: 'Seleziona variante',
		},
		filter: {
			search: {
				placeholder: 'Trova il prodotto per nome o SKU',
			},
			marketSegment: 'Prodotti {{type}}',
		},
	},
	tableVariant: {
		priceNote: 'I prezzi includono tutti gli sconti per i clienti',
		tooltip: {
			quantity: 'Il valore di quantità è pari al numero di licenze individuali ordinate per il prodotto selezionato.',
			unitType: {
				SEATS: 'Numero di postazioni per una singola licenza.',
				SESSIONS: 'Numero di sessioni per una singola licenza.',
				STORAGE: '',
				DEVICE: '',
			},
		},
	},
	widget: {
		common: {
			billableParty: 'Parte soggetta a fatturazione',
			nonBillableParty: 'Parte non soggetta a fatturazione',
		},
		selectCustomerCard: {
			createNew: 'Crea nuovo cliente',
			note: 'Nessun cliente per questo ordine.<br/><strong>Registra le licenze in un secondo momento.</strong>',
			select: 'Seleziona cliente',
			title: 'Cliente',
			error: {
				load: 'Impossibile caricare il cliente',
			},
			tooltips: {
				change: 'Modifica cliente',
				update: 'Aggiorna cliente',
				cancel: 'Rimuovi il cliente dall’ordine',
			},
		},
		selectDistributionPartnerCard: {
			createNew: 'Crea nuovo partner di dist.',
			note: 'Nessun partner di distribuzione per questo ordine.',
			select: 'Seleziona partner di dist.',
			title: 'Partner di distribuzione',
			error: {
				load: 'Impossibile caricare il partner di distribuzione',
			},
			tooltips: {
				change: 'Modifica partner di distribuzione',
				update: 'Aggiorna partner di distribuzione',
				cancel: 'Rimuovi il partner di distribuzione dall’ordine',
			},
		},
		selectPartnerCard: {
			note: 'Seleziona il partner per visualizzare i prezzi finali al netto degli sconti.',
			select: 'Seleziona partner',
			title: 'Partner Avast',
			error: {
				load: 'Impossibile caricare il partner',
			},
			tooltips: {
				change: 'Modifica partner Avast',
				cancel: 'Rimuovi il partner Avast dall’ordine',
			},
		},
	},
	paymentType: {
		SAVE: {
			action: 'Salva',
			caption: 'Salva per dopo',
			button: 'Salva per dopo',
			successMessage: 'L’ordine è stato salvato.',
			confirmationCaption: 'Salvare l’ordine?',
		},
		QUOTE: {
			action: 'Invia',
			caption: 'Invia come preventivo',
			button: 'Invia come preventivo',
			successMessage: 'Il preventivo è stato messo in coda per la consegna.',
			confirmationCaption: 'Inviare il preventivo?',
		},
		END_CUSTOMER_QUOTE: {
			action: 'Invia',
			caption: 'Invia come preventivo',
			button: 'Invia come preventivo',
			successMessage: 'Il preventivo è stato messo in coda per la consegna.',
			confirmationCaption: 'È tutto pronto per l’ordine?',
		},
		CUSTOMER_QUOTE: {
			action: 'Invia',
			caption: 'Invia preventivo al cliente',
			button: 'Invia preventivo al cliente',
			successMessage: 'Il preventivo è stato messo in coda per la consegna.',
			confirmationCaption: 'Vuoi davvero inviare l’e-mail con il preventivo al cliente?',
			confirmationMessage:
				'Tutti i prezzi unitari di vendita al cliente verranno salvati nell’ordine e verrà inviata un’e-mail al cliente.<br/>Questa azione non può essere annullata.',
		},
		GLOBAL_COMMERCE_CART_CHECKOUT: {
			action: 'Invia',
			caption: 'Pagamento con carta',
			button: 'Pagamento con carta',
			successMessage: 'L’ordine è stato effettuato.',
			confirmationCaption: 'È tutto pronto per l’ordine?',
		},
		GLOBAL_COMMERCE_CLOSING_BALANCE: {
			action: 'Invia',
			caption: 'Saldo di chiusura',
			button: 'Includi nel saldo di chiusura',
			successMessage: 'L’ordine è stato effettuato.',
			confirmationCaption: 'È tutto pronto per l’ordine?',
		},
		GLOBAL_COMMERCE_LINE_OF_CREDIT: {
			action: 'Invia',
			caption: 'Includi nel credito',
			button: 'Includi nel credito',
			successMessage: 'L’ordine è stato effettuato.',
			confirmationCaption: 'È tutto pronto per l’ordine?',
		},
		ORACLE_CART_CHECKOUT: {
			action: 'Invia',
			caption: 'Paga tramite carta di credito',
			button: 'Paga tramite carta di credito',
			successMessage: 'Il pagamento dell’ordine è andato a buon fine.',
			confirmationCaption: 'È tutto pronto per l’ordine?',
		},
		ORACLE_LINE_OF_CREDIT: {
			action: 'Invia',
			caption: 'Includi nel credito',
			button: 'Includi nel credito',
			successMessage: 'L’ordine è stato effettuato.',
			confirmationCaption: 'È tutto pronto per l’ordine?',
		},
		ORACLE_CLOSING_BALANCE: {
			action: 'Invia',
			caption: 'Saldo di chiusura',
			button: 'Includi nel saldo di chiusura',
			successMessage: 'L’ordine è stato effettuato.',
			confirmationCaption: 'È tutto pronto per l’ordine?',
		},
	},
	guards: {
		partnerPriceListGuard: {
			title: 'Conferma la modifica del partner',
			message:
				'Il partner selezionato non ha gli stessi listini prezzi consentiti di quello attuale. Se procedi, il carrello verrà svuotato.',
		},
		partnerTermsGuard: {
			title: 'Il partner non può effettuare un ordine',
			message: 'Il partner selezionato non ha accettato Termini e Condizioni.',
		},
		priceListGuard: {
			title: 'Mix di listini prezzi di prodotti',
			messageCaption:
				'Non è possibile inserire nello stesso ordine prodotti CloudCare e prodotti OMS. Aggiungere questo prodotto al carrello?',
			message:
				'Il prodotto o i prodotti che hai aggiunto in precedenza verranno rimossi automaticamente e questo articolo verrà aggiunto al carrello.',
		},
		maxCartItemsGuard: {
			title: 'Numero massimo di articoli superato',
			message: 'Un ordine può contenere al massimo {{count}} articolo. Rimuovi alcuni degli articoli per continuare.',
			message_other:
				'Un ordine può contenere al massimo {{count}} articoli. Rimuovi alcuni degli articoli per continuare.',
		},
	},
	priceList: {
		mixWarning:
			'Non è possibile inserire nello stesso ordine prodotti CloudCare e prodotti OMS a causa dei diversi prezzi e sconti applicati a ciascun prodotto. ',
		code: {
			PREPAID_OMS: 'Prodotti OMS',
			PREPAID_CC_LEGACY: 'Prodotti CloudCare',
			PREPAID_CC_MSRP: 'Prodotti CloudCare',
		},
	},
	tooltips: {
		remove: 'Rimuovi questo articolo dal carrello',
		emptyCart: 'Rimuovi tutti gli articoli dal carrello',
	},
};
