export default {
	tierQualifyingUnits: 'Unidades calificadas de nivel',
	tier: 'Nivel',
	srpUnitPrice: 'Precio por unidad (SRP)',
	customerSegment: 'Segmento de cliente',
	segmentDiscount: 'Descuento por segmento',
	srpAfterSegmentDiscount: 'SRP después de descuento por segmento',
	quantityPriced: 'Precio establecido por cantidad',
	totalPrice: 'Precio total',
	discretionaryDiscount: 'Descuento a discreción',
	unitPriceAfterDiscretionaryDiscount: 'Precio por unidad después de descuento a discreción',
	partnerDiscount: 'Descuento de socio',
	unitPriceAfterDiscount: 'Precio por unidad después de descuento',
	finalDiscountedUnitPrice: 'Precio final por unidad con descuento',
	upgradeCredit: 'Actualizar crédito',
	daysInTerm: 'Días de plazo',
	daysToProrate: 'Días para prorratear',
	prorationRate: 'Índice de prorrateo',
	proratedUnitPrice: 'Precio por unidad prorrateado',
	proratedSrpUnitPrice: 'Precio por unidad prorrateado (SRP)',
	daysInPeriod: 'Días de periodo',
	dailySaleUnitPrice: 'Precio por unidad de venta diaria',
	customPricing: 'Precios personalizados',
	quantityBasedPromotions: 'Promociones por cantidad',
	linePriceUnitPriceAfterQuantityBasedPromotions:
		'Precio de línea, Precio por unidad después de promociones por cantidad',
	linePrice: 'Precio de línea',
	unitPriceAfterQuantityBasedPromotions: 'Precio por unidad después de promociones por cantidad',
	percentageOffPromotions: 'Promociones con porcentaje de descuento',
	unitPriceAfterPercentageOffPromotions: 'Precio por unidad después de promociones con porcentaje de descuento',
	validFrom: 'Fecha de inicio de validez',
	validTo: 'Fecha de finalización de validez',
	offQtyOf: 'Cantidad de descuento de',
	amountOff: 'Importe de descuento',
	discountPerUnit: 'Descuento por unidad',
	discount: 'Descuento',
};
