import { FormikControl } from 'js/components/formik/FormikControl';
import React from 'react';
import { useFormikContext } from 'formik';
import { IPriceCalculatorForm } from 'module/priceCalculator';
import { useTranslation } from 'react-i18next';
import { createFilter } from 'react-select';
import { useCountrySelectOptions } from 'js/entities/country/useCountrySelectOptions';
import { useBillablePartyGuard } from 'module/priceCalculator/guards';

export const CountrySelect = () => {
	const [t] = useTranslation('common');
	const { setFieldValue } = useFormikContext<IPriceCalculatorForm>();
	const { countrySelectOptions, isLoading } = useCountrySelectOptions();
	const billablePartyGuard = useBillablePartyGuard();

	return (
		<FormikControl label={t('address.country')}>
			<FormikControl.SingleSelect
				name="countryCode"
				options={countrySelectOptions}
				isLoading={isLoading}
				disabled={billablePartyGuard.isExistingPartner}
				onChange={() => {
					setFieldValue('currencyCode', null, true);
				}}
				filterOption={createFilter({ matchFrom: 'start' })}
				size="sm"
				required={!billablePartyGuard.isExistingPartner}
			/>
		</FormikControl>
	);
};
