import { Dispatch, SetStateAction, useState } from 'react';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { IApiPaginatedListResponse, IApiSortBy } from 'types/api';
import type { AxiosResponse } from 'axios';
import { AxiosError } from 'axios';
import { merge } from 'lodash';
import { useApiPaginatedList } from 'js/hooks/api';
import { TQueryConfig } from 'js/queries';
import { TUseApiPaginatedListQueryKey } from 'js/hooks/api/useApiPaginatedList';
import { TUseApiListProps } from 'js/hooks/api/useApiList';
import { PaginationState } from '@tanstack/table-core/src/features/Pagination';
import { useUILocaleContext } from 'js/contexts';

/**
 * Paginated query config
 */
type TQueryPaginatedListConfig = TQueryConfig & {
	limit?: number;
};

/**
 * Response from paginated api call
 */
type TUseApiPaginatedListResponse<Data extends {}, Error = AxiosError<IApiPaginatedListResponse<Data>>> = {
	data: IApiPaginatedListResponse<Data> | null;
	pagination: PaginationState;
	setPagination: Dispatch<SetStateAction<PaginationState>>;
	query: UseQueryResult<AxiosResponse<IApiPaginatedListResponse<Data>>, Error>;
	sort?: IApiSortBy<Data>;
	setSort: Dispatch<SetStateAction<IApiSortBy<Data> | undefined>>;
};

/**
 * Props of module api query function
 */
type TUseApiPaginatedListQueryProps<
	Data extends {},
	Filter extends {} = {},
	ApiFilter = Filter,
	Error = AxiosError<IApiPaginatedListResponse<Data>>,
> = {
	filter?: Filter;
	sortBy?: IApiSortBy<Data>;
	config?: TQueryPaginatedListConfig;
	queryConfig?: UseQueryOptions<
		AxiosResponse<IApiPaginatedListResponse<Data>>,
		Error,
		AxiosResponse<IApiPaginatedListResponse<Data>>,
		TUseApiPaginatedListQueryKey<Data, Filter>
	>;
} & Pick<TUseApiListProps<Filter, ApiFilter>, 'apiFilterNormalizer'>;

/**
 * Module api query function type
 */
export type TUseApiPaginatedListModule<
	Data extends {},
	Filter extends {} = {},
	ApiFilter = Filter,
	Error = AxiosError<IApiPaginatedListResponse<Data>>,
> = (
	props?: TUseApiPaginatedListQueryProps<Data, Filter, ApiFilter, Error>,
) => TUseApiPaginatedListResponse<Data, Error>;

/**
 * Query hook for get paginated data
 * @param {string} key
 * @param {TUseApiPaginatedListQueryProps} props
 * @param {TUseApiPaginatedListQueryProps} defaults
 * @param {string|undefined} extraQueryKey
 * @returns {TUseApiPaginatedListResponse}
 */
export const useApiPaginatedListQuery = <
	Data extends {},
	Filter extends {} = {},
	ApiFilter extends {} = Filter,
	Error = AxiosError<IApiPaginatedListResponse<Data>>,
>(
	key: string,
	props: TUseApiPaginatedListQueryProps<Data, Filter, ApiFilter, Error> = {},
	defaults?: TUseApiPaginatedListQueryProps<Data, Filter, ApiFilter, Error>,
	extraQueryKey?: string,
): TUseApiPaginatedListResponse<Data, Error> => {
	const { filter, sortBy, config, queryConfig, apiFilterNormalizer } = merge(defaults, props);
	const { table: context } = useUILocaleContext();
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: config?.limit ?? context.pageSizeDefault,
	});
	const [sort, setSort] = useState<IApiSortBy<Data> | undefined>(sortBy);

	const queryFn = useApiPaginatedList<Data, Filter, ApiFilter>({
		config: {
			...config,
		},
		apiFilterNormalizer,
	});

	const query = useQuery(
		[key, pagination.pageIndex, pagination.pageSize, filter as Filter, sort, extraQueryKey],
		queryFn,
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			...queryConfig,
		},
	);

	return { query, setPagination, pagination, data: query.data?.data ?? null, sort, setSort };
};
