import { API_ERR_CODE } from 'constant';
import { IPaymentTypeDetail, IStandardOrderItemPricing, TPaymentTypeAction } from 'module/purchase';
import { TApiErrorList } from 'types/api';
import { BillingSystemPaymentTypeEnum, PaymentTypeActionEnum, PostSubmitActionEnum } from 'module/purchase/enums';

export const CUSTOM_PRICING_MESSAGE: IStandardOrderItemPricing['pricingMessageLine'] = 'Custom Pricing';

/**
 * Define payment types detail data
 * @type {Record<BillingSystemPaymentTypeEnum, IPaymentTypeDetail>}
 */
export const PAYMENT_TYPES_DETAIL: Record<BillingSystemPaymentTypeEnum, IPaymentTypeDetail> = {
	GLOBAL_COMMERCE_CART_CHECKOUT: { priority: 1 },
	GLOBAL_COMMERCE_CLOSING_BALANCE: { priority: 2 },
	GLOBAL_COMMERCE_LINE_OF_CREDIT: { priority: 3 },
	ORACLE_CART_CHECKOUT: { priority: 1 },
	ORACLE_CLOSING_BALANCE: { priority: 2 },
	ORACLE_LINE_OF_CREDIT: { priority: 3 },
};

export const PAYMENT_TYPES_SAVE: TPaymentTypeAction[] = [
	PaymentTypeActionEnum.SAVE,
	PaymentTypeActionEnum.QUOTE,
	PaymentTypeActionEnum.CUSTOMER_QUOTE,
];
export const PAYMENT_TYPES_CONFIRMATION_TEXT: TPaymentTypeAction[] = [PaymentTypeActionEnum.CUSTOMER_QUOTE];

export const API_ERROR_SUBMIT_ORDER: TApiErrorList = {
	[API_ERR_CODE.INVALID_HASH]: 'error:api.invalidHash',
	[API_ERR_CODE.ACTIVE_ENTITLEMENT_NOT_FOUND]: 'error:api.activeEntitlementNotFound',
	[API_ERR_CODE.PARTNER_NOT_REGISTERED]: 'error:api.partnerNotRegistered',
	[API_ERR_CODE.NEW_TERMS_NOT_ACCEPTED]: 'error:api.nowTermsNotAccepted',
	[API_ERR_CODE.LICENSE_OPERATION_WRONG_SEGMENT]: 'error:api.licenseOperationWrongSegment',
	[API_ERR_CODE.PARTNER_QUOTE_CURRENCY_MISMATCH]: 'error:api.partnerQuoteCurrencyMismatch',
};

export const POST_SUBMIT_ACTIONS: Record<TPaymentTypeAction, PostSubmitActionEnum[]> = {
	GLOBAL_COMMERCE_CART_CHECKOUT: [PostSubmitActionEnum.LICENSES, PostSubmitActionEnum.DETAIL],
	GLOBAL_COMMERCE_CLOSING_BALANCE: [PostSubmitActionEnum.LICENSES, PostSubmitActionEnum.DETAIL],
	GLOBAL_COMMERCE_LINE_OF_CREDIT: [PostSubmitActionEnum.LICENSES, PostSubmitActionEnum.DETAIL],
	ORACLE_CART_CHECKOUT: [PostSubmitActionEnum.LICENSES, PostSubmitActionEnum.DETAIL],
	ORACLE_LINE_OF_CREDIT: [PostSubmitActionEnum.LICENSES, PostSubmitActionEnum.DETAIL],
	ORACLE_CLOSING_BALANCE: [PostSubmitActionEnum.LICENSES, PostSubmitActionEnum.DETAIL],
	SAVE: [PostSubmitActionEnum.DETAIL, PostSubmitActionEnum.EDIT],
	QUOTE: [PostSubmitActionEnum.DETAIL],
	END_CUSTOMER_QUOTE: [PostSubmitActionEnum.DETAIL],
	CUSTOMER_QUOTE: [PostSubmitActionEnum.DETAIL],
};
