export default {
	accountInformation: 'Información de cuenta',
	_: {
		dateRangePlaceholder: 'Seleccionar fecha',
		NA: 'N/D',
		no: 'No',
		pageDetailTitle: 'Detalle #{{id}}',
		yes: 'Sí',
		loading: 'Cargando...',
	},
	boolean: {
		true: 'Sí',
		false: 'No',
	},
	entity: {
		active: 'Activa',
		amount: 'Importe',
		company: 'Empresa',
		companyId: 'Id. de la empresa',
		companyName: 'Nombre de la empresa',
		created: 'Creado',
		currency: 'Moneda',
		customer: 'Cliente',
		customerTotalPrice: 'Cli. Precio total',
		detailId: 'Id. de detalle',
		description: 'Descripción',
		disabled: 'Desactivada',
		discountAmount: 'Importe con descuento',
		discountAmountUnit: 'Importe con descuento por unidad',
		discountPercent: 'Porcentaje de descuento',
		discountType: 'Tipo de descuento',
		discountUnitPrice: 'Precio por unidad con descuento',
		distributionPartner: 'Socio de distribución',
		enabled: 'Activada',
		id: 'Id.',
		licenseKey: 'Clave de licencia',
		name: 'Nombre',
		orderStatus: 'Estado de pedido',
		partnerId: 'Id. de socio',
		partner: 'Socio',
		paymentAmount: 'Importe de pago',
		paymentMethod: 'Forma de pago',
		paymentStatus: 'Estado de pago',
		poNumber: 'Número de PO',
		price: 'Precio',
		priceWTax: 'Precio con impuestos',
		priceWOTax: 'Precio sin impuestos',
		product: 'Producto',
		productName: 'Nombre de producto',
		quantity: 'Cantidad',
		requestedDiscountType: 'Tipo de descuento solicitado',
		secondaryOrderId: 'Id. de pedido secundario',
		sku: 'SKU',
		srpUnitPrice: 'Precio por unidad (SRP)',
		state: 'Estado',
		status: 'Estado',
		subTotalWOTax: 'Subtotal sin impuestos',
		tax: 'Impuesto',
		total: 'Total',
		totalAmount: 'Importe total',
		totalAmountWOTax: 'Importe total sin impuestos',
		totalAmountWTax: 'Importe total con impuestos',
		totalPrice: 'Precio total',
		totalTax: 'Total de impuestos',
		totalWTax: 'Total con impuestos',
		transaction: 'Transacción',
		unit: 'Unidad',
		unit_other: 'Unidades',
		unitPrice: 'Precio por unidad',
		unitType: {
			SEATS: 'Puesto',
			SEATS_other: 'Puestos',
			SESSIONS: 'Sesión',
			SESSIONS_other: 'Sesiones',
			STORAGE: 'Almacenamiento',
			STORAGE_other: 'Almacenamiento',
			DEVICE: 'Dispositivo',
			DEVICE_other: 'Dispositivos',
		},
		validity: 'Validez',
		variantCount: '{{count}} variante',
		variantCount_other: '{{count}} variantes',
	},
	contact: {
		firstName: 'Nombre',
		lastName: 'Apellidos',
		name: 'Nombre de contacto',
		email: 'Correo electrónico',
		billingEmail: 'Correo electrónico de facturación',
		phone: 'Teléfono',
		website: 'Sitio web',
		id: 'Id. de contacto',
		taxId: 'Id. de impuesto',
		secondaryTaxId: 'Id. de impuesto secundario',
	},
	address: {
		caption: 'Dirección',
		city: 'Ciudad',
		country: 'País',
		state: 'Estado/provincia',
		postalCode: 'Código postal',
		street: 'Calle',
	},
	dates: {
		billing: 'Fecha de facturación',
		created: 'Fecha de creación',
		end: 'Fecha de finalización',
		expire: 'Fecha de expiración',
		payment: 'Fecha de pago',
		paymentDue: 'Fecha de vencimiento de pago',
		start: 'Fecha de inicio',
	},
	datePeriod: {
		DAY: '{{count}} día',
		DAY_other: '{{count}} días',
		MONTH: '{{count}} mes',
		MONTH_other: '{{count}} meses',
		YEAR: '{{count}} año',
		YEAR_other: '{{count}} años',
	},
	actions: {
		back: 'Atrás',
		newPurchase: 'Nueva compra',
		cancel: 'Cancelar',
		close: 'Cerrar',
		confirm: 'Confirmar',
		continue: 'Continuar',
		detail: 'Ver detalles',
		download: 'Descargar',
		emptyCart: 'Carrito vacío',
		logout: 'Cerrar sesión',
		next: 'Siguiente',
		previous: 'Anterior',
		reset: 'Restablecer',
		retry: 'Reintentar',
		removeFile: 'Eliminar archivo',
		save: 'guardar',
		select: 'Seleccionar',
		submitForm: 'Enviar',
		submitChanges: 'Enviar cambios',
		show: 'Mostrar',
		showAll: 'Mostrar todo',
		showMore: 'Mostrar más',
		showLess: 'Mostrar menos',
		copied: 'Correctamente copiado',
	},
	validator: {
		email: 'El valor debe ser un correo electrónico válido.',
	},
	table: {
		useFilter: 'Usar un filtro para comenzar a buscar',
	},
	filter: {
		quickFilters: 'Filtros rápidos',
		partnerIsRequired: 'Seleccione un socio para comenzar una búsqueda.',
		typeAtLeastNCharacters: 'Escriba al menos {{count}} caracteres en el filtro.',
	},
	header: {
		cart: 'Carrito',
		cartIsEmpty: 'El carrito está vacío',
	},
	productGroupExceptions: {
		premiumRemoteControlQuantityDisabled:
			'Mientras que Premium Remote Control se puede instalar en una cantidad ilimitada de dispositivos, no es necesario que cambie las sesiones para estos elementos, ya que las sesiones concurrentes se basan en la cantidad de dispositivos que cree que se pueden conectar al mismo tiempo.',
		cloudBackupStorageInfo:
			'Al cambiar el atributo de almacenamiento, se define la cantidad de veces que se quiere comprar un paquete de almacenamiento de 100&nbsp;GB. Por ejemplo, “Storage = 5” significa que el almacenamiento comprado final será de 500&nbsp;GB para una licencia.',
	},
	downloadFile: {
		loading: 'Descargando archivo {{filename}}',
		error: 'No es posible descargar el archivo',
	},
	appVersionGuard: {
		caption: '¡Hay una nueva versión de OMS disponible!',
		message: 'Vuelva a cargar la página para obtener la versión más reciente.',
		action: 'Volver a cargar',
	},
};
