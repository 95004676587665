import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import type { Location } from '@remix-run/router';
import { useCompare } from 'js/hooks/useCompare';
import { getReducedLocation } from 'js/utils/app';

export type TLocationChange<State = {}> = (location: Location<State>) => void;

export const useLocationChange = <State = {},>(cb: TLocationChange<State>) => {
	const location = useLocation();
	const hasChanged = useCompare<ReturnType<typeof getReducedLocation>>(getReducedLocation(location), true);

	useEffect(() => {
		if (hasChanged) {
			cb(location);
		}
	}, [location, cb, hasChanged]);
};
