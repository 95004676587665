export default {
	page: {
		default: {
			title: '我的帳戶',
		},
		taxExemption: {
			title: '免稅證明',
			shortTitle: '免稅',
			action: '提交新的免稅文件',
		},
		flexibleBilling: {
			title: '彈性計費',
		},
		autoBillingAddCard: {
			title: '新增卡片',
		},
	},
	error: {
		autoBillingSource: '無法載入您的信用卡詳細資料，請聯絡您的客戶經理。',
	},
	cards: {
		accountInformation: '帳戶資訊',
		contactInformation: '聯絡資訊',
		addressInformation: '地址資訊',
		terms: '條款與條件',
		legacyPaymentOptions: '儲存的卡片',
		personalization: '個人化',
	},
	common: {
		locale: {
			language: '系統語言',
			format: '日期/數字格式',
		},
	},
	form: {
		creditCard: {
			owner: '持卡人資訊',
			creditCard: '信用卡',
			number: '卡號',
			expiration: '到期日 (MM/YY)',
			cvv: 'CVV',
			submit: '儲存信用卡',
			isPreferredPayAsYouGoPaymentMethod: '設定為偏好的彈性計費付款方式',
		},
		taxExemption: {
			title: '提交新的納稅證明',
			taxAuthorityPlaceholder: '選取稅務機構',
			file: '免稅檔案',
			declaration: '本人聲明，據我所知，上述資訊和證明文件完整且準確，如有偽證，願受處罰。',
			success: '免稅表單已提交。',
		},
	},
	taxExemption: {
		taxAuthority: '稅務機構',
		startDate: '豁免開始日期',
		endDate: '豁免結束日期',
		fileName: '檔案名稱',
		status: {
			ELIGIBLE: '合格',
			NOT_ELIGIBLE: '不合格',
		},
	},
	flexibleBilling: {
		paymentMethod: {
			title: '付款方式',
			subtitle: '選取您偏好的付款選項',
			submitButton: '儲存付款方式',
			success: '已成功儲存偏好的付款方式',
			addCardFirst: '請先新增信用卡以啟用自動收費。',
		},
	},
	creditCard: {
		entity: {
			number: '卡號',
			expiration: '到期日',
		},
		currentSource: {
			title: '自動收費',
			subtitle: '您儲存的卡片',
			loading: '正在擷取卡片資訊',
			notFound: '您沒有儲存的卡片。',
			create: '新增卡片',
		},
		message: {
			successCreate: '您的卡片已成功儲存。',
			successDelete: '您的卡片已成功刪除。',
			saving: '正在儲存您的卡片',
		},
		delete: {
			title: '從您的帳戶中移除卡片',
			confirmation: '您確定要移除卡片 (•••• {{lastFourDigits}}) 嗎？',
			loading: '正在刪除卡片',
		},
		error: {
			bad_request: '信用卡無效。請再試一次，或聯絡您的客戶經理。',
		},
	},
	legacyPaymentOptions: {
		unableToLoad: '無法載入儲存的卡片。',
		noStoredCards: '您沒有儲存的卡片。',
	},
};
