import { Button } from '@avast/react-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

type TStartShoppingButtonRowProps = {
	colSpan: number;
	onClick(): void;
};

export const StartShoppingButtonRow = (props: TStartShoppingButtonRowProps) => {
	const [t] = useTranslation('submodules');
	return (
		<tr>
			<td
				colSpan={props.colSpan}
				className="py-4"
			>
				<Button
					variant="primary"
					size="sm"
					onClick={props.onClick}
					style={{ minWidth: '240px' }}
					testId="startShopping"
				>
					{t('comparativeFeatures.startShopping')}
				</Button>
			</td>
		</tr>
	);
};
