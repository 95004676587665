import { TableColumnsDef } from 'types';
import { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { IEntityOrder, IEntityOrderItem } from 'module/orders';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';

type TTableData = IEntityOrderItem;

export const useOrderDiscountsColumns = (order: IEntityOrder): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(ordersConfig.trNamespace);
	const { currency } = order;

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.text('discount.category', {
				header: t('common:entity.discountType'),
				meta: { formatters: [formatter.bold] },
			}),
			columnHelper.text('ems.order.sku', { header: t('common:entity.sku') }),
			columnHelper.text('pricedQuantity', { header: t('common:entity.quantity') }),
			columnHelper.money('price.saleUnit', { header: t('common:entity.srpUnitPrice') }, { currency: currency || '' }),
			columnHelper.money(
				'price.discountedUnit',
				{ header: t('common:entity.discountUnitPrice') },
				{ currency: currency || '' },
			),
			columnHelper.percentage('discount.percent', { header: t('common:entity.discountPercent') }, {}),
			columnHelper.money(
				'discount.flat',
				{ header: t('common:entity.discountAmountUnit') },
				{ currency: currency || '' },
			),
		];
	}, [t, currency]);
};
