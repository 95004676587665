import React, { FunctionComponent } from 'react';
import { AuthPartnerDropdownCol, HeaderLogo } from 'js/layouts/components';
import { Col, Row } from 'js/components/atoms/Row';
import { Outlet } from 'react-router-dom';

export const TermsLayout: FunctionComponent = () => (
	<>
		<div className="header-app justify-content-end">
			<HeaderLogo collapsedLogo={false} />
			<AuthPartnerDropdownCol />
		</div>

		<div className="container">
			<Row justify="center">
				<Col
					xl={6}
					lg={8}
					md={10}
				>
					<Outlet />
				</Col>
			</Row>
		</div>
	</>
);
