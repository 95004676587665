import React, { KeyboardEvent, ReactElement, useEffect, useState } from 'react';
import type { TSelectOptions } from '@avast/react-ui-components';
import { Alert, FormControl, IconButton, SingleSelect, useInputRef } from '@avast/react-ui-components';
import { trim } from 'lodash';
import { TSearchFilter } from 'types/filter';
import { InputGroup } from 'react-bootstrap';
import { useCompare } from 'js/hooks/useCompare';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { getSelectComponentCSSWidth } from 'js/components/molecules/SearchBox/searchBoxUtils';
import classNames from 'classnames';

type TSearchComboBoxValidationResult = { valid: true } | { valid: false; message: string };

type TSearchComboBoxProps<KeyType extends string> = {
	keys?: TSelectOptions<KeyType>;
	value?: TSearchFilter<KeyType>;
	name: string;
	placeholder?: string;
	size?: 'sm' | 'lg';
	disabled?: boolean;
	autoFocus?: boolean;
	onSubmit: (value: TSearchFilter<KeyType> | undefined) => void;
	isValid?: (value: TSearchFilter<KeyType>) => TSearchComboBoxValidationResult;
};

export const SearchComboBox = <KeyType extends string>(props: TSearchComboBoxProps<KeyType>): ReactElement => {
	const ref = useInputRef();
	const [t] = useTranslation();

	// Props
	const {
		autoFocus,
		size,
		onSubmit,
		name,
		placeholder = t('components:searchComboBox.value.placeholder'),
		disabled,
		keys,
		isValid,
	} = props;

	// States
	const updatedValue = useCompare<TSearchFilter<KeyType> | undefined>(props.value, true);
	const [searchValue, setSearchValue] = useState<string>('');
	const [searchKey, setSearchKey] = useState<KeyType | undefined>(keys?.[0]?.value);

	// Validations
	const [validate, setValidate] = useState(false);
	const [validateState, setValidateState] = useState<TSearchComboBoxValidationResult>({ valid: true });

	useEffect(() => {
		if (autoFocus) {
			ref.current?.focus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Update values from outside
	 */
	useEffect(() => {
		if (updatedValue) {
			setSearchValue(props.value?.value || '');
			setSearchKey((searchKey) => props.value?.key || searchKey || keys?.[0]?.value);
		}
	}, [updatedValue, props.value, keys]);

	/**
	 * Validation
	 */
	useEffect(() => {
		if (isValid && validate) {
			setValidateState(isValid({ value: searchValue, key: searchKey }));
		}
	}, [isValid, validate, searchValue, searchKey]);

	const submitHandler = () => {
		// Clear timer
		blurTimer.cancel();

		// Validation
		if (isValid) {
			const state = isValid({ value: searchValue, key: searchKey });
			setValidateState(state);
			setValidate(true);

			// Do not submit, when the value is not valid
			if (!state.valid) {
				return;
			}
		}

		// Pass to HOC
		const value = trim(searchValue);
		onSubmit(Boolean(value) ? { key: searchKey, value } : undefined);
	};

	const blurTimer = useDebouncedCallback(submitHandler, 500);

	/**
	 * Component for selector part in searcher.
	 * @returns {ReactElement | null}
	 */
	const SearchSelector = (): ReactElement | null => {
		if (!keys) {
			return null;
		}

		const oneKey = keys.length === 1;
		return (
			<SingleSelect<KeyType>
				options={keys}
				name={`${name}-key`}
				size={size}
				value={searchKey}
				disabled={disabled}
				styles={{
					container: () => ({
						maxWidth: '100%',
						width: getSelectComponentCSSWidth(keys, oneKey ? 2 : undefined),
					}),
				}}
				onChange={(value) => {
					setSearchKey(value || undefined);
				}}
				className={classNames({ 'select__single-option': oneKey })}
				onFocus={blurTimer.cancel}
				isClearable={false}
			/>
		);
	};

	return (
		<>
			{!validateState.valid && <Alert variant="danger">{validateState.message}</Alert>}
			<InputGroup
				size={size}
				className="component__search-combo-box"
			>
				<SearchSelector />
				<FormControl.Input
					placeholder={placeholder}
					name={`${name}-value`}
					type="text"
					value={searchValue}
					size={size}
					disabled={disabled}
					onChange={setSearchValue}
					onFocus={blurTimer.cancel}
					onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
						if (e.key.toLocaleLowerCase() === 'enter') {
							submitHandler();
						}
					}}
					onBlur={() => {
						blurTimer.cancel();
						// Setup new one
						if (!searchValue) {
							blurTimer();
						}
					}}
					forwardedRef={ref}
					isInvalid={!validateState.valid}
				/>
				<IconButton
					onClick={submitHandler}
					disabled={disabled || !validateState.valid}
					iconFa={faSearch}
					size={size}
				/>
				{searchValue && (
					<IconButton
						onClick={() => {
							setSearchValue('');
							onSubmit(undefined);
							setValidate(false);
							setValidateState({ valid: true });
						}}
						disabled={disabled || !searchValue}
						variant="reset"
						iconFa={faTimes}
						size="sm"
					/>
				)}
			</InputGroup>
		</>
	);
};

SearchComboBox.defaultProps = {
	name: 'search',
};
