export default {
	error: {
		creditStatusNotFound: 'No se encontró el estado del crédito.',
	},
	tabs: {
		detail: 'Resumen de crédito',
		transactions: 'Lista de transacciones',
	},
	entity: {
		companyName: 'Empresa',
		countryCode: 'País',
		creditAvailable: 'Crédito disponible',
		creditLimit: 'Límite crediticio',
		percentConsumed: 'Porcentaje consumido',
		unbilledBalance: 'Saldo no facturado',
		unpaidBalance: 'Saldo no pagado',
	},
	entityShort: {
		creditAvailable: 'Disponible',
		creditLimit: 'Límite',
		percentConsumed: 'Porcentaje consumido',
		unbilledBalance: 'No facturado',
		unpaidBalance: 'No pagado',
	},
	entityTransaction: {
		amount: 'Importe',
		createdAt: 'Hora de creación',
		orderId: 'Id. de referencia',
		source: 'Fuente',
	},
	widget: {
		name: 'Tipo',
		value: 'Crédito',
	},
};
