import { useApiClosingBalanceList } from 'module/closingBalance/hooks/useApiClosingBalance';
import { IClosingBalanceListFilter, IEntityClosingBalance } from 'module/closingBalance';
import { TUseApiPaginatedListModule } from 'js/queries/useApiPaginatedListQuery';
import { get, merge } from 'lodash';

export const useClosingBalanceList: TUseApiPaginatedListModule<IEntityClosingBalance, IClosingBalanceListFilter> = (
	props,
) => {
	const value = get(props, 'filter.search.value');
	const key = get(props, 'filter.search.key');
	const queryEnabled = get(props, 'queryConfig.enabled');
	const isOrderNumberSearch = key === 'ORDER_NUMBER';

	// If is in filter ORDER_NUMBER, first try search by charge order ID
	const ordersByClosingBalanceId = useApiClosingBalanceList({
		...props,
		filter: { chargeOrderId: value },
		queryConfig: {
			...props?.queryConfig,
			enabled: queryEnabled && isOrderNumberSearch,
		},
	});

	const hasOrdersByClosingBalanceId =
		// Search by ORDER_NUMBER
		isOrderNumberSearch &&
		// Query state
		// Query is in progress
		(ordersByClosingBalanceId.query.isFetching ||
			// Query is finished and success with data
			(ordersByClosingBalanceId.query.isSuccess && Boolean(ordersByClosingBalanceId.data?.items.length)));

	// Otherwise search by standard filter
	const ordersByFilter = useApiClosingBalanceList(
		merge({}, props, {
			queryConfig: { enabled: queryEnabled && !hasOrdersByClosingBalanceId },
		}),
	);

	return hasOrdersByClosingBalanceId ? ordersByClosingBalanceId : ordersByFilter;
};
