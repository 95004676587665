import React from 'react';
import type { ITestId } from '@avast/react-ui-components';
import classNames from 'classnames';

type TActionLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & ITestId;

export const ActionLink = (props: TActionLinkProps) => {
	const { className, onClick, children, ...rest } = props;

	return (
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		<a
			href="#"
			className={classNames('action-link', className)}
			onClick={(e) => {
				e.preventDefault();
				e.currentTarget.blur();
				onClick?.(e);
				return false;
			}}
			{...rest}
		>
			{children}
		</a>
	);
};
