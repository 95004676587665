import { generateEnumUtils } from 'js/enums/generator/enumFunctions';
import { UserOrderActionEnum } from 'module/orders/enums';

export enum BillingSystemEnum {
	NETSUITE = 'NETSUITE',
	GLOBAL_COMMERCE = 'GLOBAL_COMMERCE',
	ORACLE = 'ORACLE',
}

interface IBillingSystemEnumExtend {
	fromOrderAction(value?: UserOrderActionEnum): BillingSystemEnum;
}

export const billingSystemEnumUtils = generateEnumUtils<BillingSystemEnum, IBillingSystemEnumExtend>(
	BillingSystemEnum,
	{
		fromOrderAction(value) {
			switch (value) {
				case UserOrderActionEnum.NET_SUITE:
					return BillingSystemEnum.NETSUITE;
				case UserOrderActionEnum.ORACLE:
					return BillingSystemEnum.ORACLE;
				default:
					return BillingSystemEnum.GLOBAL_COMMERCE;
			}
		},
	},
);
