import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { LinkButton } from 'js/components/atoms/Button';
import React from 'react';
import { isNil } from 'lodash';
import type { ITestId } from '@avast/react-ui-components';
import { TEntityLicenseId } from 'module/licenses';

type TLinkToLicenseDetailProps = {
	licenseId?: TEntityLicenseId;
	text?: string;
} & ITestId;

export const LinkToLicenseDetail = ({ licenseId, text, testId }: TLinkToLicenseDetailProps) => {
	if (isNil(licenseId)) {
		return null;
	}

	return (
		<LinkButton
			testId={testId}
			to={buildParametrizedRoute(RouteName.LICENSE.DETAIL, licenseId)}
			variant="light"
			size="sm"
		>
			{text}
		</LinkButton>
	);
};
