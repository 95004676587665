import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@avast/react-ui-components';
import { AsyncExportModal } from 'js/components/exports/AsyncExportModal';
import { IAsyncExportProps } from 'js/components/exports';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { IAsyncExportCustomersRequest } from 'module/customers';
import { customersConfig } from 'module/customers/customersConfig';
import { TPartnerId } from 'types';
import { useApiPartnerCustomersExport } from 'module/customers/hooks/useApiCustomers';
import { isNumber } from 'lodash';

type TExportAllCustomersButtonProps = {
	partnerId?: TPartnerId | null;
	isLoading?: boolean;
	isDisabled?: boolean;
};

export const ExportAllCustomersButton = (props: TExportAllCustomersButtonProps) => {
	const { partnerId, isLoading } = props;
	const [t] = useTranslation(customersConfig.trNamespace);
	const exportRef = useAsyncModalRef<IAsyncExportProps<IAsyncExportCustomersRequest>>();
	const isDisabled = props.isDisabled || !partnerId;

	return (
		<>
			<Button
				size="sm"
				variant="outline-primary"
				disabled={isDisabled}
				loading={isLoading}
				onClick={() =>
					isNumber(partnerId) &&
					exportRef.current?.show({
						request: { partnerId },
						useQuery: useApiPartnerCustomersExport,
					})
				}
				testId="exportAllCustomers"
			>
				{t('actions.exportAll')}
			</Button>
			<AsyncExportModal
				forwardedRef={exportRef}
				testId="exportAllCustomers"
			/>
		</>
	);
};
