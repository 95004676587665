export default {
	common: {
		partnerPortal: 'Anmelden über Partnerportal',
		forgetPassword: 'Sie haben Ihr Passwort vergessen?',
		returnToPartnerPortal: 'Zurück zum Partnerportal',
		successNewPassword: 'Ihr neues Kennwort wurde erfolgreich geändert.',
		backToLogin: 'Zurück zur Anmeldung',
		loginLoading: 'Beim OMS anmelden',
	},
	commonText: {
		loginForAvastOnly: 'Nur für interne Verwendung bei Avast.',
		assistance: 'Wenden Sie sich für weitere Unterstützung an Ihren Avast Business Account Manager.',
		signature: 'Vielen Dank, <br />Avast Business',
	},
	form: {
		password: 'Passwort',
		submit: 'Weiter zu OMS',
		email: 'E-Mail',
	},
	page: {
		login: {
			title: 'Anmelden',
		},
		logout: {
			loading: 'Abmelden aus OMS...',
		},
		sso: {
			title: 'SSO-Anmeldung',
			loading: 'Anmelden bei OMS...',
		},
		loginAsAffiliate: {
			title: 'Anmelden als Partner',
			loading: 'Anmelden als Partner bei OMS...',
		},
		orderHold: {
			title:
				'Für Ihr Unternehmen sind eine oder mehrere Rechnungen vorhanden, deren Fälligkeitsdatum um {{count}} oder mehr Tage überschritten ist.',
			text: 'Aus diesem Grund wurde das Konto Ihres Unternehmens vorübergehend gesperrt, sodass Sie vorerst keine neuen Käufe auf Kredit tätigen können.',
			textMore:
				'Weitere Einzelheiten zu unbezahlten Bestellungen finden Sie im Abschnitt "Bestellungen", und sie wurden auch per E-Mail an den Hauptansprechpartner Ihres Unternehmens weitergeleitet.',
			action: 'Unbezahlte Bestellungen anzeigen',
		},
		registrationIncomplete: {
			title: 'Vorgang konnte nicht abgeschlossen werden',
			text: 'Der OMS-Registrierungsprozess wurde noch nicht von einem Hauptbenutzer (Admin) in Ihrem Unternehmen abgeschlossen. Sobald dies geschehen ist, wird Ihr Zugang freigeschaltet.',
		},
		terms: {
			title: 'Neue Allgemeine Geschäftsbedingungen',
			text: 'Neue Allgemeine Geschäftsbedingungen müssen von einem Hauptbenutzer (Admin) in Ihrem Unternehmen akzeptiert werden.',
		},
		inactive: {
			title: 'Vorgang konnte nicht abgeschlossen werden',
			text: 'Ihr Zugriff wurde verweigert. Wenden Sie sich an Ihren Kontoadministrator, um weitere Informationen zu erhalten.',
		},
		maintenance: {
			scheduled: {
				title: 'Geplante Wartung',
				text: 'Das Order Management System wird derzeit planmäßig gewartet und ist vorübergehend nicht verfügbar. Bitte entschuldigen Sie eventuelle Unannehmlichkeiten.',
				end: 'Planmäßiges Ende der Wartung',
			},
		},
		passwordReset: {
			title: 'Ihr Passwort zurücksetzen',
			info: 'Setzen Sie Ihr Passwort zurück, indem Sie Ihre Konto-E-Mail-Adresse angeben.',
			success: 'Ihr Link zum Zurücksetzen des Passworts wurde an Ihr E-Mail-Postfach gesendet.',
		},
		newPassword: {
			title: 'Neues Passwort konfigurieren',
			form: {
				password: 'Neues Passwort',
				confirm: 'Passwort bestätigen',
			},
			info: 'Geben Sie ein neues Passwort ein.',
			validation: {
				digit: 'Das Passwort muss mindestens eine Ziffer enthalten',
				symbol: 'Das Passwort muss mindestens ein Symbol enthalten',
				length: 'Das Passwort muss mindestens 8 Zeichen lang sein',
				uppercase: 'Das Passwort muss mindestens einen Großbuchstaben enthalten',
				lowercase: 'Das Passwort muss mindestens einen Kleinbuchstaben enthalten',
				notSame: 'Passwortbestätigung stimmt nicht mit Passwort überein',
				trim: 'Das Passwort darf nicht mit einem Leerzeichen beginnen oder enden.',
			},
		},
	},
	state: {
		invalidCredentials: 'Der Benutzername oder das Passwort ist falsch, bitte versuchen Sie es erneut.',
		invalidSso: 'Sie können sich nicht anmelden, Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
		invalidAuthorizationCode: 'Anmelden nicht möglich mit diesem Autorisierungscode.',
		sessionExpired: 'Ihre Sitzung ist abgelaufen, Sie müssen sich anmelden.',
	},
	error: {
		unableToResetPassword: 'Wir können Ihr Passwort derzeit nicht zurücksetzen.',
	},
};
