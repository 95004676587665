export default {
	page: {
		default: {
			title: 'My Account',
		},
		taxExemption: {
			title: 'Tax Exemption Certificate',
			shortTitle: 'Tax Exemption',
			action: 'Submit New Tax Exemption',
		},
		flexibleBilling: {
			title: 'Flexible Billing',
		},
		autoBillingAddCard: {
			title: 'Add New Card',
		},
	},
	error: {
		autoBillingSource: 'Your credit card details could not be loaded, please contact your Account Manager.',
	},
	cards: {
		accountInformation: 'Account Information',
		contactInformation: 'Contact Information',
		addressInformation: 'Address Information',
		terms: 'Terms and Conditions',
		legacyPaymentOptions: 'Saved Cards',
		personalization: 'Personalization',
	},
	common: {
		locale: {
			language: 'System Language',
			format: 'Date / Number Formatting',
		},
	},
	form: {
		creditCard: {
			owner: 'Card owner information',
			creditCard: 'Credit Card',
			number: 'Card Number',
			expiration: 'Expiration (MM/YY)',
			cvv: 'CVV',
			submit: 'Save Credit Card',
			isPreferredPayAsYouGoPaymentMethod: 'Set as preferred Flexible Billing payment method',
		},
		taxExemption: {
			title: 'Submit New Tax Certificate',
			taxAuthorityPlaceholder: 'Select tax authority',
			file: 'Tax Exemption file',
			declaration:
				'Under penalties and perjury, I declare that to the best of my knowledge the above information and my supporting documentation is complete and accurate.',
			success: 'Tax Exemption form was submitted.',
		},
	},
	taxExemption: {
		taxAuthority: 'Tax Authority',
		startDate: 'Exemption Start Date',
		endDate: 'Exemption End Date',
		fileName: 'File Name',
		status: {
			ELIGIBLE: 'Eligible',
			NOT_ELIGIBLE: 'Not Eligible',
		},
	},
	flexibleBilling: {
		paymentMethod: {
			title: 'Payment Method',
			subtitle: 'Select your preferred payment option',
			submitButton: 'Save payment method',
			success: 'Preferred payment method was successfully saved',
			addCardFirst: 'Please add a credit card first to enable Auto-Billing.',
		},
	},
	creditCard: {
		entity: {
			number: 'Card Number',
			expiration: 'Expiration',
		},
		currentSource: {
			title: 'Auto-Billing',
			subtitle: 'Your saved card',
			loading: 'Retrieving card information',
			notFound: 'You have no saved cards.',
			create: 'Add New Card',
		},
		message: {
			successCreate: 'Your card was successfully saved.',
			successDelete: 'Your card was successfully deleted.',
			saving: 'Saving your card',
		},
		delete: {
			title: 'Remove the Card from your account',
			confirmation: 'Are you sure you want to remove the card (•••• {{lastFourDigits}})?',
			loading: 'Deleting the card',
		},
		error: {
			bad_request: 'Invalid credit card. Please try again or contact your Account Manager.',
		},
	},
	legacyPaymentOptions: {
		unableToLoad: 'Unable to load stored cards.',
		noStoredCards: 'You have no saved cards.',
	},
};
