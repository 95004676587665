import { IEntityOrder } from 'module/orders';
import { TOrderInstanceProducts, TProductGroupFinder } from 'module/orders/hooks/orderToInstance';
import { isIspOrder, normalizeBusinessSku, normalizeSku } from 'module/orders/utils/common';
import { IStandardOrderInstanceItem, IStandardOrderInstanceProduct, IStandardOrderItemPricing } from 'module/purchase';
import { orderInstanceNormalizer } from 'module/purchase/normalizer';

export const useOrderInstanceProducts = (
	order: IEntityOrder | null,
	productGroupFinder: TProductGroupFinder,
): TOrderInstanceProducts => {
	const { getProductBySku, getProductGroupByCode } = productGroupFinder;
	let seqId = 0;
	const items: IStandardOrderInstanceItem[] = [];
	const pricingItems: IStandardOrderItemPricing[] = [];

	order?.lineItems.forEach((item) => {
		const groupCode = item.ems?.order?.productGroup;
		const group = getProductGroupByCode(groupCode);
		if (!group) {
			return null;
		}

		const sku =
			group.marketSegment === 'BUSINESS' ? normalizeBusinessSku(item.product?.sku) : normalizeSku(item.product?.sku);
		const product = getProductBySku(sku, groupCode);
		let instanceItemProduct: IStandardOrderInstanceProduct | null = null;
		if (product) {
			instanceItemProduct = orderInstanceNormalizer.normalizeProduct(group, product);
		} else if (isIspOrder(order)) {
			instanceItemProduct = orderInstanceNormalizer.normalizeIspItemToProduct(group, item);
		}

		if (!instanceItemProduct) {
			return null;
		}

		const id = seqId++;
		items.push(orderInstanceNormalizer.normalizeItem(id, item, instanceItemProduct));
		pricingItems.push(orderInstanceNormalizer.normalizeItemPricing(id, item, order));
	});

	return {
		isFetching: productGroupFinder.isFetching,
		items,
		pricing: orderInstanceNormalizer.normalizePricing(order, pricingItems),
		seqId,
	};
};
