import React, { PropsWithChildren, ReactElement, ReactNode, useCallback } from 'react';
import { DataFilter, TDataFilterProps } from 'js/components/molecules/DataFilter/DataFilter';
import { IClosingBalanceListFilter } from 'module/closingBalance';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'js/components/atoms/Row';
import { SearchComboBox } from 'js/components/molecules/SearchBox';
import {
	ClosingBalanceOrderStatusEnum,
	closingBalanceOrderStatusEnumUtils,
	ClosingBalanceSearchKeyEnum,
	closingBalanceSearchKeyEnumUtils,
} from 'module/closingBalance/enums';
import { FormControl } from '@avast/react-ui-components';
import { SelectPartner } from 'module/partners/components';
import { FilterRangeDatepicker } from 'js/components/molecules/Datepicker/FilterRangeDatepicker';
import { PaymentStatusEnum, paymentStatusEnumUtils } from 'js/enums';
import { useAuthContext } from 'js/contexts';

type TFilter = IClosingBalanceListFilter;
type TFilterProps = TDataFilterProps<TFilter> & {
	controls?: ReactNode;
};

/**
 * Filter for Closing Balance list
 * @param {React.PropsWithChildren<TFilterProps>} props
 * @returns {React.ReactElement}
 * @constructor
 */
export const ClosingBalanceFilter = (props: PropsWithChildren<TFilterProps>): ReactElement => {
	const [t] = useTranslation('moduleClosingBalance');
	const { isGroupInternal } = useAuthContext();
	const { controls, ...rest } = props;

	const closingBalanceSearchKeys = closingBalanceSearchKeyEnumUtils.getSelectOptions();

	// Function for resolve if filter fields should be disabled
	const isEnabled = useCallback((values: TFilter) => {
		return !(values.search?.key && values.search?.value);
	}, []);

	return (
		<DataFilter<TFilter>
			{...rest}
			enabledRules={{
				partnerId: isEnabled,
				paymentStatus: isEnabled,
				billingStatus: isEnabled,
				dateRange: isEnabled,
			}}
		>
			{({ values, isEnabledField, updateFilter }) => (
				<Row
					multi
					size="sm"
					align="center"
				>
					<Col
						xl={4}
						lg={5}
					>
						<SearchComboBox<ClosingBalanceSearchKeyEnum>
							size="sm"
							keys={closingBalanceSearchKeys}
							value={values.search}
							onSubmit={(value) => updateFilter({ search: value })}
						/>
					</Col>
					<Col
						xl={8}
						lg={7}
						className="text-end"
					>
						{controls}
					</Col>
					<Col
						xs={12}
						className="col-hr"
					/>
					{isGroupInternal && (
						<Col
							lg={3}
							md={4}
							sm={6}
						>
							<SelectPartner
								size="sm"
								value={values.partnerId}
								disabled={!isEnabledField('partnerId')}
								onChange={(value) => updateFilter({ partnerId: value })}
							/>
						</Col>
					)}
					<Col
						lg={3}
						md={4}
						sm={6}
					>
						<FormControl label={t('common:entity.orderStatus')}>
							<FormControl.SingleSelect<ClosingBalanceOrderStatusEnum>
								name="billingStatus"
								disabled={!isEnabledField('billingStatus')}
								value={values.billingStatus}
								onChange={(value) => updateFilter({ billingStatus: value || undefined })}
								size="sm"
								placeholder={t('entity.orderStatusPlaceholder')}
								options={closingBalanceOrderStatusEnumUtils.getSelectOptions()}
							/>
						</FormControl>
					</Col>
					<Col
						lg={3}
						md={4}
						sm={6}
					>
						<FormControl label={t('common:entity.paymentStatus')}>
							<FormControl.SingleSelect<PaymentStatusEnum>
								name="paymentStatus"
								disabled={!isEnabledField('paymentStatus')}
								value={values.paymentStatus}
								onChange={(value) => updateFilter({ paymentStatus: value || undefined })}
								size="sm"
								placeholder={t('entity.paymentStatusPlaceholder')}
								options={paymentStatusEnumUtils.getSelectOptions()}
							/>
						</FormControl>
					</Col>
					<Col
						lg={3}
						md={4}
						sm={6}
					>
						<FormControl label={t('entity.period')}>
							<FilterRangeDatepicker testId="period" />
						</FormControl>
					</Col>
				</Row>
			)}
		</DataFilter>
	);
};

ClosingBalanceFilter.defaultProps = {
	useLocation: true,
};
