export default {
	actions: {
		create: 'Créer un partenaire de distribution',
		edit: 'Modifier le partenaire de distribution',
		exportAll: 'Exporter toutes les partenaires de distribution',
		form: {
			create: 'Enregistrer en tant que nouveau partenaire de distribution',
			update: 'Enregistrer les modifications',
		},
	},
	error: {
		createDistributionPartner: 'Impossible de créer le partenaire de distribution.',
		updateDistributionPartner: 'Impossible de mettre à jour le partenaire de distribution.',
	},
	page: {
		create: {
			title: 'Nouveau partenaire de distribution',
			success: 'Vous avez créé un nouveau partenaire de distribution.',
		},
		update: {
			success: 'Vous avez mis à jour le partenaire de distribution.',
		},
	},
	select: {
		title: 'Sélectionner un partenaire de distribution',
		button: {
			label: 'Partenaire de distribution',
			placeholder: 'Sélectionner le partenaire de distribution',
		},
		filter: {
			placeholder: 'Recherche par e-mail, nom ou entreprise',
		},
	},
};
