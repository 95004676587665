export default {
	requestId: 'Request ID',
	errorId: 'Error ID',
	errorDetails: 'Error Details',
	api: {
		activeEntitlementNotFound:
			'This license operation cannot be completed because the entitlement has already been replaced.',
		invalidHash: 'Hash is invalid.',
		distributionPartnerSalesforceConflict:
			'The supplied contact is under a different organization. Please contact your Account Manager for more details.',
		passwordResetInvalidToken: 'The password reset token is invalid.',
		passwordResetExpiredToken: 'The password reset token has expired.',
		passwordAlreadyUsed: 'This password has already been used in the past. Please choose a different password.',
		partnerNotRegistered:
			'Placing orders is allowed for registered accounts only. Please ask the partner to complete OMS registration first.',
		nowTermsNotAccepted:
			'Placing orders is allowed for accounts with accepted terms and conditions. Please ask the partner to accept the updated terms and conditions.',
		licenseOperationWrongSegment:
			'The selected billable party is not eligible to purchase Business / Consumer products.',
		partnerQuoteCurrencyMismatch:
			'This order cannot be processed because the account currency differs from the quote currency. Please contact your account manager and ask for a new quote.',
	},
	page: {
		404: {
			title: 'Message from IT department (page 404)',
			message: 'Error occurred, requested URL does not exist. Click below to continue.',
			back: 'Back to Homepage',
		},
	},
	status: {
		networkError: 'Unable to connect to server.',
	},
	common: {
		contactSales: 'Please contact your Account Manager for assistance.',
		repeatOrContactSales: 'Please try again later or contact your Account Manager for assistance.',
	},
	detailNotFound: 'Detail failed to load or does not exist.',
	permissionDenied: 'You do not have permission to access this action/resource.',
	sectionDataNotFound: 'Data for this section has not been found.',
	beforeUnload: 'If you leave now, you may lose your data.',
	unspecifiedError: 'Your request could not be processed',
	errorDetail: 'Technical details (provide when reporting an error)',
};
