import { filterPostValues } from 'js/utils/form';
import { IEntityDistributionPartner, IFormDistributionPartner } from 'module/distributionPartners';
import { YesNoEnum } from 'js/enums';

export const distributionPartnerFormNormalizer = {
	normalize(values: IEntityDistributionPartner): IFormDistributionPartner {
		return {
			name: values.name,
			website: values.website,
			taxId: values.taxId,
			contact: {
				firstName: values.contact.firstName,
				lastName: values.contact.lastName,
				email: values.contact.email,
			},
			billing: {
				street: values.billing.street,
				city: values.billing.city,
				postalCode: values.billing.postalCode,
				countryCode: values.billing.countryCode,
				stateCode: values.billing.stateCode,
			},
		};
	},
	denormalize(values: IFormDistributionPartner, isUpdate: boolean = false): IFormDistributionPartner {
		const result = filterPostValues(values);

		if (isUpdate) {
			delete result?.billing.countryCode;
			delete result?.billing.stateCode;
		}

		if (result?.flexibleBilling?.create && result.flexibleBilling.differentEmail !== YesNoEnum.YES) {
			delete result.flexibleBilling?.differentEmailValue;
		}

		return result;
	},
};
