// import * as serviceWorker from './serviceWorker';
import React, { Suspense } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import WebFont from 'webfontloader';
import './i18n';
import { setupInterceptors } from 'api/setupInterceptors';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as yupSetup from 'js/yup';
import { numberFormatter } from 'js/utils/number';
import { ApiErrorContextProvider, AppContextProvider } from 'js/contexts';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { api } from 'api';
import { LoadingFullScreenPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { SkeletonTheme } from 'react-loading-skeleton';
import { parseEnvInt } from 'js/utils/app';
import { minToMs } from 'js/utils/dateTime';
import * as Sentry from '@sentry/react';
import { CaptureConsole, Dedupe, ExtraErrorData } from '@sentry/integrations';
import { AppContainer } from 'AppContainer';
import { CONFIG } from 'config';
import { gtm } from 'js/analytics/gtm';
import { UserProviders } from 'UserProviders';
import { BrowserRouter } from 'react-router-dom';
import { userLocaleInstance } from 'i18n/userLocaleInstance';
import i18n from 'i18n';
import { isNumber, isObject } from 'lodash';

WebFont.load({
	typekit: {
		id: 'bgh6vec',
	},
});

if (process.env.REACT_APP_SENTRY_DNS) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DNS,
		integrations: [
			...Sentry.defaultIntegrations,
			new Sentry.Integrations.FunctionToString(),
			new Dedupe(),
			new CaptureConsole({
				levels: process.env.REACT_APP_SENTRY_LEVEL?.split(','),
			}),
			new ExtraErrorData(),
		],
		environment: CONFIG.ENV,
		attachStacktrace: false,
		maxBreadcrumbs: 6,
		release: CONFIG.VERSION,
		autoSessionTracking: false,
		ignoreErrors: [/^TypeError: cyclic object value$/],
	});
}

// Init interceptors for axios
setupInterceptors(api);
yupSetup.addValidators();
yupSetup.setLocale();
numberFormatter.init();
gtm.init();

userLocaleInstance.init(({ language, format }) => {
	if (i18n.language !== language) {
		i18n.changeLanguage(language);
	}

	numberFormatter.setLocale(format);
});

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: minToMs(parseEnvInt(process.env.REACT_APP_REACT_QUERY_STALE_TIME)),
			retry(failureCount, error: unknown) {
				// Prevent to retry calls when status code is below 500
				// @ts-ignore
				if (isObject(error) && isNumber(error?.response?.status) && error.response.status < 500) {
					return false;
				}

				return failureCount < 3;
			},
		},
	},
});

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container!);

root.render(
	<QueryClientProvider client={queryClient}>
		<SkeletonTheme
			baseColor="#dfddeb"
			highlightColor="#d4d2e6"
		>
			<BrowserRouter basename={process.env.PUBLIC_URL}>
				<AppContextProvider>
					<ApiErrorContextProvider>
						<Suspense fallback={<LoadingFullScreenPlaceholder />}>
							<UserProviders>
								<AppContainer />
							</UserProviders>
						</Suspense>
					</ApiErrorContextProvider>
				</AppContextProvider>
			</BrowserRouter>
		</SkeletonTheme>
		<ReactQueryDevtools
			initialIsOpen={false}
			toggleButtonProps={{ style: { transform: 'translate(-50%, 50%)', left: 0, bottom: 0 } }}
		/>
	</QueryClientProvider>,
);
