import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { RouteName } from 'module/RouteName';
import { aclCheck, TAclAction, TAclModules } from 'config/acl';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { useAuthGuards } from 'js/hooks/useSecurityGuards';
import { IAuthGuards } from 'types';
import { useAuthContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';

interface INoAuthRoute {
	acl?: [TAclModules, TAclAction];
	authGuards?: IAuthGuards;
}

export const NoAuthRoute = ({ acl, authGuards }: INoAuthRoute): ReactElement => {
	const authContext = useAuthContext();
	const isAllowedByAuthGuards = useAuthGuards(authGuards);
	const [t] = useTranslation('error');

	// Logged
	if (authContext.isLogged) {
		return (
			<Navigate
				to={RouteName.HOMEPAGE.DEFAULT}
				replace
			/>
		);
	}

	// ACL check if set and has user data
	if (acl && isDefined(authContext.authRole)) {
		const [module, action] = acl;
		if (!aclCheck(authContext, module, action)) {
			toast.warning(<Toast>{t('permissionDenied')}</Toast>);
			return <Navigate to={RouteName.HOMEPAGE.DEFAULT} />;
		}
	}

	// Group
	if (!isAllowedByAuthGuards) {
		toast.warning(<Toast>{t('permissionDenied')}</Toast>);
		return <Navigate to={RouteName.HOMEPAGE.DEFAULT} />;
	}

	return <Outlet />;
};
