import { useApiPlaceOrder, useApiSaveOrder } from 'module/purchase/hooks/useApiPurchase';
import { useCallback } from 'react';
import { IAxiosApiError } from 'types/api';
import { IEntityOrder } from 'module/orders';
import { PAYMENT_TYPES_SAVE } from 'module/purchase/constants';
import { IStandardOrder } from 'module/purchase';
import { logDebug } from 'js/utils/app';
import { orderInstanceNormalizer } from 'module/purchase/normalizer';
import { BillingSystemPaymentTypeEnum, PaymentTypeActionEnum } from 'module/purchase/enums';
import { useOrderContext } from 'js/contexts';

export type TUsePlaceOrder = (
	onSuccess: (data: IEntityOrder) => void,
	onError: (error: IAxiosApiError | null) => void,
) => void;

export const usePlaceOrder = () => {
	const placeOrder = useApiPlaceOrder();
	const saveOrder = useApiSaveOrder();
	const orderContext = useOrderContext();
	const {
		orderState: { action },
	} = orderContext;

	return useCallback<TUsePlaceOrder>(
		(onSuccess, onError) => {
			if (!action) {
				onError(null);
				return;
			}

			// Save
			if (PAYMENT_TYPES_SAVE.includes(action)) {
				const order = orderInstanceNormalizer.denormalizeToSave(orderContext);
				if (!order) {
					onError(null);
					return;
				}

				logDebug('Order payload', order);
				saveOrder.mutate(order, {
					onSuccess({ data }) {
						onSuccess(data);
					},
					onError(error) {
						onError(error);
					},
				});
				return;
			}

			// Checkout
			let order: IStandardOrder | null;
			if (action === PaymentTypeActionEnum.END_CUSTOMER_QUOTE) {
				order = orderInstanceNormalizer.denormalize(
					BillingSystemPaymentTypeEnum.GLOBAL_COMMERCE_CART_CHECKOUT,
					orderContext,
				);
			} else {
				order = orderInstanceNormalizer.denormalize(action as BillingSystemPaymentTypeEnum, orderContext);
			}
			if (!order) {
				onError(null);
				return;
			}

			logDebug('Order payload', order);
			placeOrder.mutate(order, {
				onSuccess({ data }) {
					onSuccess(data);
				},
				onError(error) {
					onError(error);
				},
			});
		},
		[action, orderContext, placeOrder, saveOrder],
	);
};
