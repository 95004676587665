import { ICreditCardInfo } from 'module/account';
import { get } from 'lodash';
import React, { ReactElement } from 'react';
import { CreditCardBrandEnum } from 'module/account/enums';

const creditCardBrandImage: Record<CreditCardBrandEnum, string> = {
	[CreditCardBrandEnum.VISA]: 'visa',
	[CreditCardBrandEnum.MASTER_CARD]: 'mastercard',
	[CreditCardBrandEnum.AMERICAN_EXPRESS]: 'amex',
	[CreditCardBrandEnum.DISCOVER]: 'discover',
	[CreditCardBrandEnum.JCB]: 'jcb',
	[CreditCardBrandEnum.MAESTRO]: 'maestro',
	[CreditCardBrandEnum.UNION_PAY]: 'unionpay',
};

export const getCreditCardImageUrl = (brand: CreditCardBrandEnum): string | null => {
	const imageName = get(creditCardBrandImage, brand, null);

	return imageName
		? `https://ui1.img.digitalrivercontent.net/Storefront/images/creditCardLogos/${imageName}.png`
		: null;
};

export const getCreditCardNumber = (creditCard: ICreditCardInfo): ReactElement => {
	const hiddenNumbers: string[] = [];
	for (let i = 1; i <= 3; i += 1) {
		hiddenNumbers.push('••••');
	}

	return (
		<>
			<span className="text-muted">{hiddenNumbers.join(' ')} </span>
			<span>{creditCard.lastFourDigits}</span>
		</>
	);
};

export const getCreditCardExpiration = (creditCard: ICreditCardInfo): string => {
	const { expirationYear, expirationMonth } = creditCard;

	return [expirationMonth, expirationYear].join('/');
};
