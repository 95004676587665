import { IEntityOrder } from 'module/orders';
import { useCallback } from 'react';
import { isOrderPaymentInStatus, isOrderUnfinished } from 'module/orders/utils/common';
import { PaymentStatusEnum } from 'js/enums';
import { useAuthContext } from 'js/contexts';

export const useIsEditable = () => {
	const { isAuthorizedBuyer } = useAuthContext();
	return useCallback(
		(order: IEntityOrder | null) =>
			isAuthorizedBuyer && isOrderUnfinished(order) && !isOrderPaymentInStatus(order, PaymentStatusEnum.SETTLED),
		[isAuthorizedBuyer],
	);
};
