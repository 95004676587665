import React from 'react';
import { TWidgetHeader } from 'submodule/widgets/hooks/useWidgetHeader';
import { isString } from 'lodash';

export const WidgetItemCell = ({ title, meta, description }: TWidgetHeader) => (
	<>
		<div>{title}</div>
		{isString(meta) && <div className="fs-md">{meta}</div>}
		{isString(description) && <div className="fs-md text-gray">{description}</div>}
	</>
);
