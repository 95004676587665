import React, { ReactElement } from 'react';
import { PromotionForm } from '../components';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle/DynamicPageTitle';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { RouteName } from 'module/RouteName';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { createPromotionApiError } from 'module/promotions/utils/apiError';
import { useApiCreatePromotion } from 'module/promotions/hooks/useApiPromotion';
import { promotionFormNormalizer } from 'module/promotions/normalizer';
import { useNavigate } from 'react-router-dom';
import { useApiErrorContext, useAppContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';

export const PageCreate = (): ReactElement => {
	const [t] = useTranslation(promotionsConfig.trNamespace);
	const { loadingModalRef } = useAppContext();
	const navigate = useNavigate();
	const { setError } = useApiErrorContext();
	const { mutate: createPromotion } = useApiCreatePromotion();

	return (
		<div className="section__content mt-3">
			<DynamicPageTitleItem text={t('page.create.title')} />
			<DynamicBreadcrumbsItem href={RouteName.PROMOTIONS.CREATE}>{t('page.create.title')}</DynamicBreadcrumbsItem>
			<PromotionForm
				isUpdate={false}
				onSubmit={(values, { setSubmitting }) => {
					loadingModalRef.current?.show({ title: t('common.creatingPromotion') });
					createPromotion(promotionFormNormalizer.denormalize(values), {
						onSuccess(response) {
							setSubmitting(false);
							loadingModalRef.current?.hide();
							if (isDefined(response?.data)) {
								toast.success(<Toast>{t('page.create.success')}</Toast>);
								navigate(promotionsConfig.detailLink(response.data));
							}
						},
						onError(error) {
							loadingModalRef.current?.hide();
							setError({ error, resolve: createPromotionApiError });
							setSubmitting(false);
						},
					});
				}}
			/>
		</div>
	);
};
