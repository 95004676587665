import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { IInitiateRefundForm } from 'submodule/refunds';
import { FormikForm } from 'js/components/formik/FormikForm';
import React, { useEffect, useState } from 'react';
import { useInitiateRefundContext } from './InitiateRefundContext';
import { StepProducts, StepReview } from 'submodule/refunds/initiate/form';
import { useInitiateRefundFormValidator } from 'submodule/refunds/hooks/useInitiateRefundFormValidator';
import { InitiateRefundFormEnum } from 'submodule/refunds/enums';

type TInitiateRefundFormProps = {
	initialValues: IInitiateRefundForm;
	onSubmit: FormikConfig<IInitiateRefundForm>['onSubmit'];
	onCancel: () => void;
};

export const InitiateRefundForm = (props: TInitiateRefundFormProps) => {
	const { onSubmit, onCancel, initialValues } = props;
	const [step, setStep] = useState<InitiateRefundFormEnum>(InitiateRefundFormEnum.PRODUCTS);
	const { onChangeView } = useInitiateRefundContext();
	const { validationSchema } = useInitiateRefundFormValidator();

	useEffect(() => {
		onChangeView(step);
	}, [step, onChangeView]);

	return (
		<Formik<IInitiateRefundForm>
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
		>
			{({ submitForm }) => (
				<FormikForm testId="initiateRefund">
					{step === InitiateRefundFormEnum.PRODUCTS ? (
						<StepProducts
							onSubmit={() => {
								setStep(InitiateRefundFormEnum.REVIEW);
							}}
							onCancel={onCancel}
						/>
					) : (
						<StepReview
							onCancel={() => {
								setStep(InitiateRefundFormEnum.PRODUCTS);
							}}
						/>
					)}
				</FormikForm>
			)}
		</Formik>
	);
};
