export default {
	common: {
		discountWaitingToApprove: 'The discount is waiting for your Sales Manager approve.',
		selectCurrencyModalTitle: 'Select customer currency',
		otherCustomerInformation: 'Other',
	},
	entity: {
		contactId: 'Customer ID',
		customerType: 'Customer Type',
		discount: 'Discount',
		industry: 'Industry',
	},
	error: {
		createCustomer: 'Unable to create customer.',
		updateCustomer: 'Unable to update customer.',
	},
	select: {
		title: 'Select Customer',
		button: {
			placeholder: 'Select customer',
			label: 'Customer',
		},
		filter: {
			placeholder: 'Find customer',
			placeholderEmail: 'Find customer by E-mail',
			placeholderType: 'Customer type',
		},
		type: {
			EMAIL: 'E-mail',
			ID: 'Salesforce ID',
		},
	},
	page: {
		create: {
			title: 'New Customer',
			success: 'You have successfully created a new Customer.',
			form: {
				email: 'Please use a genuine email address that your customer has access to.',
			},
		},
		update: {
			success: 'You have successfully updated Customer.',
		},
	},
	actions: {
		edit: 'Edit Customer',
		exportAll: 'Export All Customers',
		newCustomer: 'Create new Customer',
		relatedLicenses: 'View Related Licenses',
		relatedOrders: 'View Related Orders',
		relatedQuotes: 'View Related Quotes',
		viewLicenses: 'View Licenses',
		viewOrders: 'View Orders',
		viewQuotes: 'View Quotes',
		form: {
			create: 'Save as new Customer',
			update: 'Save Changes',
		},
	},
};
