import React from 'react';
import { FormikControl } from 'js/components/formik/FormikControl';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { FixedWidthWrapper } from 'js/components/molecules/Inputs/FixedWidthWrapper';
import { useOrderContext } from 'js/contexts';

type TDiscretionaryDiscountFormProps = {
	name: string;
};

export const DiscretionaryDiscountForm = (props: TDiscretionaryDiscountFormProps) => {
	const { name } = props;
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { orderState } = useOrderContext();

	return (
		<FixedWidthWrapper
			width={300}
			max
		>
			<FormikControl label={t('common:entity.discountPercent')}>
				<FormikControl.Number
					numberFormat={{
						output: 'percent',
						spaceSeparated: true,
						trimMantissa: true,
					}}
					size="sm"
					min={0}
					max={0.99}
					step={0.01}
					mantissa={4}
					name={name}
					placeholder={t('discretionaryDiscount.placeholder')}
					disabled={orderState.isLocked}
					hideControls={orderState.isLocked}
				/>
			</FormikControl>
		</FixedWidthWrapper>
	);
};
