import React from 'react';
import { useFormikContext } from 'formik';
import { IGenerateRetailCodesForm } from 'module/retail';
import { useTranslation } from 'react-i18next';
import { retailConfig } from 'module/retail/retailConfig';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import { RouteName } from 'module/RouteName';
import { useNavigate } from 'react-router-dom';

export const GenerateRetailCodesControls = () => {
	const { values } = useFormikContext<IGenerateRetailCodesForm>();
	const [t] = useTranslation(retailConfig.trNamespace);
	const navigate = useNavigate();

	return (
		<FormikFooterControls
			hr={false}
			cancel={{
				children: t('common:actions.back'),
				onClick() {
					navigate(RouteName.RETAIL.LIST);
				},
			}}
			submit={{
				children: t('generate.submit'),
				disabled: values.products.length === 0,
			}}
		/>
	);
};
