import React from 'react';
import { useHelpContext } from 'js/help/HelpContext';
import { HelpHome } from 'js/help/home/HelpHome';
import { HelpGuide } from 'js/help/guide/HelpGuide';
import { HelpVideos } from 'js/help/videos/HelpVideos';
import { HelpStepEnum } from 'js/enums';
import { logError } from 'js/utils/app';

export const HelpContentSwitch = () => {
	const { step } = useHelpContext();

	switch (step) {
		case HelpStepEnum.HOME:
			return <HelpHome />;
		case HelpStepEnum.GUIDE:
			return <HelpGuide />;
		case HelpStepEnum.VIDEOS:
			return <HelpVideos />;
		default:
			logError(`Not supported help step: ${step}`);
			return null;
	}
};
