import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import {
	Checkbox,
	FormControl,
	Input,
	InputNumber,
	MessageType,
	MultiSelect,
	Radio,
	SingleSelect,
	TCheckboxProps,
	Textarea,
	TFormControlProps,
	TFormFieldProps,
	TInputNumberProps,
	TInputProps,
	TMultiSelectProps,
	TRadioProps,
	TSingleSelectProps,
	TTextareaProps,
} from '@avast/react-ui-components';
import type { FieldProps } from 'formik';
import { Field, FormikContext } from 'formik';
import { get, isString } from 'lodash';
import { FormikSelectPartner } from 'js/components/formik/controls/FormikSelectPartner';
import { FormikRangeDatepicker, FormikSingleDatepicker, FormikSwitch } from 'js/components/formik/controls';
import classNames from 'classnames';
import { useFormikSetFieldValue } from 'js/hooks/useFormikSetFieldValue';
import { useFormikOnBlur } from 'js/hooks/useFormikOnBlur';
import { SubmittingIndicatorEnum } from 'js/enums';
import { logError } from 'js/utils/app';
import { isDefined } from 'js/utils/common';

type TFormikValidationProps = {
	validation?: boolean;
};

export type TFormikFieldProps<T extends TFormFieldProps> = T & TFormikValidationProps;

type TFormikFieldValidationProps<T extends TFormFieldProps = TFormFieldProps> = {
	isValid?: boolean;
	isInvalid?: boolean;
} & Omit<TFormikFieldProps<T>, 'name'>;

type TFormik2Props = <T extends TFormFieldProps>(
	fieldProps: FieldProps,
	props: Omit<TFormikFieldProps<T>, 'name'>,
	submittingIndicator: SubmittingIndicatorEnum,
) => TFormikFieldValidationProps<T>;

export const formik2Props: TFormik2Props = (fieldProps, props, submittingIndicator) => {
	const {
		meta,
		form: { isSubmitting },
	} = fieldProps;
	const { validation: shouldValidate = true, disabled, readOnly } = props;
	const additionalParams: Partial<TFormikFieldValidationProps> = {};

	switch (submittingIndicator) {
		case SubmittingIndicatorEnum.DISABLED:
			additionalParams.disabled = disabled || isSubmitting;
			break;
		case SubmittingIndicatorEnum.READ_ONLY:
			additionalParams.readOnly = readOnly || isSubmitting;
			additionalParams.disabled = disabled;
			break;
		default:
			logError(`Not supported submitting indicator: ${submittingIndicator}`);
	}

	// Validation
	const touched = meta.touched || fieldProps.form.submitCount > 0;
	if (shouldValidate && touched) {
		if (isString(meta.error)) {
			additionalParams.isInvalid = true;
		}
		// Do not add valid state to disabled field
		else if (!disabled) {
			additionalParams.isValid = true;
		}
	}

	return {
		...props,
		...additionalParams,
	};
};

/**
 * Input for Formik
 * @returns {ReactElement}
 * @private
 */
const _FormikInput = ({ name, ...props }: TFormikFieldProps<TInputProps>): ReactElement => (
	<Field
		name={name}
		value={props.value}
	>
		{(fieldProps: FieldProps) => {
			fieldProps.field.value = fieldProps.field.value ?? '';
			return (
				<Input
					{...fieldProps.field}
					{...formik2Props<TInputProps>(fieldProps, props, SubmittingIndicatorEnum.READ_ONLY)}
				/>
			);
		}}
	</Field>
);

/**
 * Input for Formik
 * @param {TFormikFieldProps} componentProps
 * @returns {ReactElement}
 * @private
 */
const _FormikNumber = (
	componentProps: TFormikFieldProps<Partial<TInputNumberProps> & TFormFieldProps>,
): ReactElement => {
	const { name, onChange, onBlur, ...props } = componentProps;
	const formikSetFieldValue = useFormikSetFieldValue(name);
	const formikOnBlur = useFormikOnBlur(name);
	return (
		<Field name={name}>
			{(fieldProps: FieldProps) => (
				<InputNumber
					{...fieldProps.field}
					{...formik2Props<TInputNumberProps>(
						fieldProps,
						props as TInputNumberProps,
						SubmittingIndicatorEnum.READ_ONLY,
					)}
					onChange={(value?: number) => {
						formikSetFieldValue(value);
						onChange?.(value);
					}}
					onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
						formikOnBlur();
						onBlur?.(e);
					}}
				/>
			)}
		</Field>
	);
};

/**
 * Textarea for Formik
 * @returns {ReactElement}
 * @private
 */
const _FormikTextarea = ({ name, ...props }: TFormikFieldProps<TTextareaProps>): ReactElement => (
	<Field name={name}>
		{(fieldProps: FieldProps) => (
			<Textarea
				{...fieldProps.field}
				{...formik2Props<TTextareaProps>(fieldProps, props, SubmittingIndicatorEnum.READ_ONLY)}
			/>
		)}
	</Field>
);

/**
 * Radio for Formik
 * @returns {ReactElement}
 * @private
 */
const _FormikRadio = ({ name, onChange, ...props }: TFormikFieldProps<TRadioProps>): ReactElement => (
	<Field
		name={name}
		value={props.value}
	>
		{(fieldProps: FieldProps) => (
			<Radio
				{...fieldProps.field}
				checked={props.value === fieldProps.field.value}
				{...formik2Props<TRadioProps>(fieldProps, props, SubmittingIndicatorEnum.DISABLED)}
				onChange={(e: ChangeEvent<HTMLInputElement>) => {
					fieldProps.field.onChange(e);
					onChange?.(e);
				}}
			/>
		)}
	</Field>
);

/**
 * Checkbox for Formik
 * @returns {ReactElement}
 * @private
 */
const _FormikCheckbox = ({ name, onChange, ...props }: TFormikFieldProps<TCheckboxProps>): ReactElement => (
	<Field
		name={name}
		type="checkbox"
		value={props.value}
	>
		{(fieldProps: FieldProps) => (
			<Checkbox
				{...fieldProps.field}
				{...formik2Props<TCheckboxProps>(fieldProps, props, SubmittingIndicatorEnum.DISABLED)}
				onChange={(e: ChangeEvent<HTMLInputElement>) => {
					fieldProps.field.onChange(e);
					onChange?.(e);
				}}
			/>
		)}
	</Field>
);

/**
 * Select for Formik
 * @param {TFormikFieldProps} componentProps
 * @returns {ReactElement}
 * @private
 */
const _FormikSingleSelect = <Keys extends string = string>(
	componentProps: TFormikFieldProps<TSingleSelectProps<Keys>>,
): ReactElement => {
	const { name, onChange, onBlur, ...props } = componentProps;
	const formikSetFieldValue = useFormikSetFieldValue(name);
	const formikOnBlur = useFormikOnBlur(name);
	return (
		<Field name={name}>
			{(fieldProps: FieldProps) => (
				<SingleSelect<Keys>
					{...fieldProps.field}
					onChange={(value, action) => {
						formikSetFieldValue(value);
						onChange?.(value, action);
					}}
					onBlur={(event) => {
						formikOnBlur();
						onBlur?.(event);
					}}
					{...formik2Props<TSingleSelectProps<Keys>>(fieldProps, props, SubmittingIndicatorEnum.DISABLED)}
				/>
			)}
		</Field>
	);
};

/**
 * Multi select for Formik
 * @param {TFormikFieldProps} componentProps
 * @returns {ReactElement}
 * @private
 */
const _FormikMultiSelect = <Keys extends string = string>(
	componentProps: TFormikFieldProps<TMultiSelectProps<Keys>>,
): ReactElement => {
	const { name, onChange, onBlur, ...props } = componentProps;
	const formikSetFieldValue = useFormikSetFieldValue(name);
	const formikOnBlur = useFormikOnBlur(name);
	return (
		<Field name={name}>
			{(fieldProps: FieldProps) => (
				<MultiSelect<Keys>
					{...fieldProps.field}
					onChange={(value, action) => {
						formikSetFieldValue(value);
						onChange?.(value, action);
					}}
					onBlur={(event) => {
						formikOnBlur();
						onBlur?.(event);
					}}
					{...formik2Props<TMultiSelectProps<Keys>>(fieldProps, props, SubmittingIndicatorEnum.DISABLED)}
				/>
			)}
		</Field>
	);
};

export type TFormikControlProps = TFormControlProps & {
	validMessage?: string;
	validation?: boolean;
	name?: string;
};

class FormikControl extends PureComponent<TFormikControlProps> {
	static Input = _FormikInput;
	static Number = _FormikNumber;
	static Textarea = _FormikTextarea;
	static SingleSelect = _FormikSingleSelect;
	static MultiSelect = _FormikMultiSelect;
	static Radio = _FormikRadio;
	static Checkbox = _FormikCheckbox;
	static Switch = FormikSwitch;
	static SingleDatepicker = FormikSingleDatepicker;
	static RangeDatepicker = FormikRangeDatepicker;
	static SelectPartner = FormikSelectPartner;

	static defaultProps = {
		messages: [],
		messagesGap: false,
	};

	render(): React.ReactNode {
		const { children, messages, validation, validMessage, name, ...props } = this.props;
		const controlProps: TFormFieldProps = children?.props || {};
		const controlName = name ?? controlProps?.name;

		return (
			<FormikContext.Consumer>
				{({ errors, touched, submitCount }) => {
					const error = get(errors, controlName, null);
					const hasError = isDefined(error);
					const touch = get(touched, controlName, null);
					const isTouched = Boolean(touch) || submitCount > 0;

					// Messages
					const _messages = messages ? [...messages] : [];
					if (validation !== false && isTouched) {
						// Error message
						if (hasError) {
							_messages.push([error as string, MessageType.DANGER]);
						}
						// Valid
						else if (validMessage) {
							_messages.push([validMessage, MessageType.SUCCESS]);
						}
					}

					return (
						<FormControl
							{...props}
							messages={_messages}
						>
							{children}
						</FormControl>
					);
				}}
			</FormikContext.Consumer>
		);
	}
}

type TFormikControlRadioProps = Omit<TFormikControlProps, 'children'> & {
	children: ReactElement | ReactElement[];
};

class FormikControlRadio extends PureComponent<TFormikControlRadioProps & { name: string; inline?: boolean }> {
	static defaultProps = {
		isWrapper: true,
	};

	render(): React.ReactNode {
		const { children, inline, groupProps, ...props } = this.props;
		return (
			<FormikControl
				{...props}
				validation
				groupProps={{
					...groupProps,
					inline,
					className: classNames('form-check-group', groupProps?.className),
				}}
			>
				<>
					{React.Children.map(children, (child: ReactElement) => (
						<FormikControl
							messagesGap={false}
							validation={false}
							idSuffix={child.props.value.toString()}
							groupProps={{ inline }}
						>
							{child}
						</FormikControl>
					))}
				</>
			</FormikControl>
		);
	}
}

class FormikControlCheckbox extends PureComponent<TFormikControlRadioProps> {
	static defaultProps = {
		isWrapper: true,
	};

	render(): React.ReactNode {
		const { children, ...props } = this.props;
		return (
			<FormikControl
				{...props}
				groupProps={{ className: 'form-check-group' }}
			>
				<>{children}</>
			</FormikControl>
		);
	}
}

export { FormikControl, FormikControlRadio, FormikControlCheckbox };
