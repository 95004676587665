import React, { PropsWithChildren, ReactElement } from 'react';
import { CardTable } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ordersConfig } from 'module/orders/ordersConfig';
import { useApiQuoteList } from 'module/orders/hooks/useApiOrders';
import { RouteName } from 'module/RouteName';
import { IEntityOrder } from 'module/orders';
import { useQuotesWidgetColumns } from 'submodule/widgets/tableColumns';
import { VisibilityState } from '@tanstack/table-core/src/features/Visibility';
import { WidgetTypeEnum, widgetTypeEnumUtils } from 'submodule/widgets/enums';
import { TPartnerId } from 'types';
import { useAuthContext } from 'js/contexts';

type TQuotesWidgetProps = React.HTMLAttributes<HTMLDivElement> & {
	limit?: number;
	header?: string;
	columnVisibility?: VisibilityState;
	partnerId?: TPartnerId;
};

export const QuotesWidget = (props: PropsWithChildren<TQuotesWidgetProps>): ReactElement => {
	const { columns, columnVisibility: defaultColumnVisibility } = useQuotesWidgetColumns();
	const { limit = 5, header, columnVisibility = defaultColumnVisibility, partnerId, ...rest } = props;

	const [t] = useTranslation(ordersConfig.trNamespace);
	const { authCompanyId, isGroupInternal } = useAuthContext();
	const navigate = useNavigate();

	const {
		data,
		query: { isFetching },
	} = useApiQuoteList({
		filter: isGroupInternal ? { partnerId } : { partnerId: authCompanyId },
		config: { limit },
	});

	return (
		<CardTable<IEntityOrder>
			testId="quotes widget"
			{...rest}
			columns={columns}
			header={{ text: header || t(widgetTypeEnumUtils.getCaption(WidgetTypeEnum.QUOTES)) }}
			data={data?.items ?? []}
			onViewMore={() => navigate(RouteName.QUOTE.LIST)}
			table={{
				state: { columnVisibility },
				meta: {
					rowCount: limit,
					loading: isFetching,
					onRowClick(order) {
						navigate(ordersConfig.quoteDetailLink(order));
					},
				},
			}}
		/>
	);
};
