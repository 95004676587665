import React, { useState } from 'react';
import { DistributorSpendingOverview, FlexibleBillingListMenu, PageListHeader } from '../components';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { ApiListTable } from 'js/components/molecules/DataTable';
import { useApiFlexibleBillingDistributionPartnerList } from '../hooks';
import {
	IApiFlexibleBillingDistributionPartnerFilter,
	IFlexibleBillingDistributionPartner,
} from 'module/flexibleBilling';
import { useFlexibleBillingDistributorsColumns } from 'module/flexibleBilling/hooks';
import { useAuthContext } from 'js/contexts';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'js/components/atoms/Row';
import { SearchBox } from 'js/components/molecules/SearchBox';
import { useTranslation } from 'react-i18next';
import { SortDirectionEnum } from 'js/enums';

export const PageDistributionPartners = () => {
	const [t] = useTranslation(flexibleBillingConfig.trNamespace);
	const { authCompanyId, authCompany } = useAuthContext();
	const navigate = useNavigate();
	const columns = useFlexibleBillingDistributorsColumns(authCompany?.currencyCode || '');
	const [search, setSearch] = useState<string | undefined>();

	return (
		<>
			<DefaultContainer>
				<PageListHeader />
			</DefaultContainer>
			<FlexibleBillingListMenu />
			<DefaultContainer>
				<Row
					justify="between"
					multi
					size="sm"
					className="flex-row-reverse mb-3"
				>
					<Col xs="auto">
						<DistributorSpendingOverview />
					</Col>
					<Col xs="auto">
						<SearchBox
							size="sm"
							placeholder={t('common.searchDistributionPartner')}
							value={search}
							onChange={setSearch}
							inline
						/>
					</Col>
				</Row>
				<ApiListTable<IFlexibleBillingDistributionPartner, IApiFlexibleBillingDistributionPartnerFilter>
					query={useApiFlexibleBillingDistributionPartnerList}
					filterStatic={{ distributorId: authCompanyId }}
					columns={columns}
					sort={{ direction: SortDirectionEnum.ASC, key: 'distributionPartner' }}
					useLocation
					table={{
						testId: 'distributionPartners',
						state: { globalFilter: search },
						onGlobalFilterChange: setSearch,
						sortDescFirst: false,
						meta: {
							onRowClick: (row) => navigate(flexibleBillingConfig.detailLink(row)),
							paginationComponent: 'Full',
						},
					}}
				/>
			</DefaultContainer>
		</>
	);
};
