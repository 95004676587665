import { IApiPartnerCustomerListFilter } from 'module/customers/index';
import { isValidEmail } from 'js/utils/validator';

export const customerFilterNormalizer = {
	denormalize(filter: IApiPartnerCustomerListFilter): IApiPartnerCustomerListFilter {
		const apiFilter: IApiPartnerCustomerListFilter = { ...filter };
		if (isValidEmail(apiFilter.filter)) {
			apiFilter.email = apiFilter.filter;
			delete apiFilter.filter;
		}

		return apiFilter;
	},
};
