import { Button } from '@avast/react-ui-components';
import React from 'react';
import { IEntityLicense } from 'module/licenses';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { isAllowedLicenseOperations } from 'module/licenses/utils/common';
import { useAuthContext } from 'js/contexts';

type TLicenseOperationButtonProps = {
	license: IEntityLicense;
	onClick: () => Promise<number> | undefined;
};

export const LicenseOperationButton = ({ license, onClick }: TLicenseOperationButtonProps) => {
	const [t] = useTranslation(licensesConfig.trNamespace);

	const { isAuthorizedBuyer, isGroupPartner } = useAuthContext();

	if (!isAllowedLicenseOperations(license, isAuthorizedBuyer, isGroupPartner)) {
		return null;
	}

	return (
		<Button
			size="sm"
			onClick={onClick}
			testId="licenseOperations"
		>
			{t('actions.operations')}
		</Button>
	);
};
