import React, { PropsWithChildren, ReactElement, ReactNode, useCallback } from 'react';
import { Col, Row } from 'js/components/atoms/Row';
import { SelectPartner } from 'module/partners/components';
import { DataFilter } from 'js/components/molecules/DataFilter';
import { TDataFilterProps } from 'js/components/molecules/DataFilter/DataFilter';
import { useTranslation } from 'react-i18next';
import { retailConfig } from 'module/retail/retailConfig';
import { FormControl } from '@avast/react-ui-components';
import { SearchComboBox } from 'js/components/molecules/SearchBox';
import { FilterRangeDatepicker } from 'js/components/molecules/Datepicker/FilterRangeDatepicker';
import { RetailStatusEnum, retailStatusEnumUtils } from 'module/retail/enums';
import { IRetailRequestListFilter } from 'module/retail';
import { PartnerSubTypeEnum, PartnerTypeEnum } from 'module/partners/enums';

type TFilter = IRetailRequestListFilter;
type TFilterProps = TDataFilterProps<TFilter> & {
	controls?: ReactNode;
};

export const RetailFilter = (props: PropsWithChildren<TFilterProps>): ReactElement => {
	const [t] = useTranslation(retailConfig.trNamespace);
	const { controls, ...rest } = props;

	// Function for resolve if filter fields should be disabled
	const isEnabled = useCallback((values: TFilter) => !values.bulkRequestId, []);

	return (
		<DataFilter<TFilter>
			{...rest}
			enabledRules={{
				partnerId: isEnabled,
				status: isEnabled,
				dateRange: isEnabled,
			}}
		>
			{({ values, updateFilter, isEnabledField }) => (
				<>
					<Row
						multi
						size="sm"
					>
						<Col
							xl={4}
							lg={5}
							md={6}
						>
							<SearchComboBox
								name="bulkRequestId"
								size="sm"
								value={{ value: values.bulkRequestId }}
								placeholder={t('filter.bulkId.placeholder')}
								onSubmit={(result) => updateFilter({ bulkRequestId: result?.value })}
							/>
						</Col>
						<Col
							xl={8}
							lg={7}
							md={6}
							className="text-end"
						>
							{controls}
						</Col>
					</Row>
					<Row
						multi
						size="sm"
						align="center"
					>
						<Col
							sm={6}
							md={4}
							xl={3}
						>
							<SelectPartner
								size="sm"
								value={values.partnerId}
								disabled={!isEnabledField('partnerId')}
								onChange={(value) => updateFilter({ partnerId: value })}
								accountSubTypesWhiteList={[PartnerSubTypeEnum.RETAIL]}
								accountTypesWhiteList={[PartnerTypeEnum.RESELLER]}
							/>
						</Col>
						<Col
							sm={6}
							md={4}
							xl={3}
						>
							<FormControl label={t('entity.transactionDate')}>
								<FilterRangeDatepicker
									disabled={!isEnabledField('dateRange')}
									testId="transactionDate"
								/>
							</FormControl>
						</Col>
						<Col
							sm={6}
							md={4}
							xl={3}
						>
							<FormControl label={t('common:entity.status')}>
								<FormControl.SingleSelect<RetailStatusEnum>
									name="status"
									value={values.status}
									onChange={(value) => updateFilter({ status: value || undefined })}
									size="sm"
									disabled={!isEnabledField('status')}
									options={retailStatusEnumUtils.getSelectOptions()}
								/>
							</FormControl>
						</Col>
					</Row>
				</>
			)}
		</DataFilter>
	);
};

RetailFilter.defaultProps = {
	useLocation: true,
};
