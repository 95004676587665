export default {
	common: {
		partnerPortal: 'パートナー ポータル経由で OMS にログイン',
		forgetPassword: 'パスワードをお忘れですか？',
		returnToPartnerPortal: 'パートナー ポータルに戻る',
		successNewPassword: '新しいパスワードが正常に変更されました。',
		backToLogin: 'ログインに戻る',
		loginLoading: 'OMS にログイン',
	},
	commonText: {
		loginForAvastOnly: 'アバスト社内でのみ使用。',
		assistance: 'サポートが必要な場合は、アバスト ビジネス アカウント マネージャーにお問い合わせください。',
		signature: 'ありがとうございました。<br />アバスト ビジネス',
	},
	form: {
		password: 'パスワード',
		submit: 'OMS に進む',
		email: 'メール アドレス',
	},
	page: {
		login: {
			title: 'ログイン',
		},
		logout: {
			loading: 'OMS からログアウト...',
		},
		sso: {
			title: 'SSO ログイン',
			loading: 'OMS にログイン...',
		},
		loginAsAffiliate: {
			title: '関連会社としてログイン',
			loading: '関連会社として OMS にログイン...',
		},
		orderHold: {
			title: 'お客様の会社には期限を {{count}} 日以上過ぎた請求書があります。',
			text: 'そのため、お客様の会社のアカウントは保留状態になっており、クレジットで新規購入することができません。',
			textMore:
				'未払いの注文の詳細については、［注文］セクションを参照してください。この詳細は、お客様の会社の基本連絡先にも電子メールで送信されます。',
			action: '未払いの注文を表示',
		},
		registrationIncomplete: {
			title: '処理を完了できませんでした',
			text: 'お客様の組織のプライマリ (Admin) ユーザーが OMS 登録プロセスをまだ完了していません。このプロセスが完了すると、アクセスが可能になります。',
		},
		terms: {
			title: '新しい利用規約',
			text: 'お客様の組織のプライマリ (Admin) ユーザーが新しい利用規約に同意する必要があります。',
		},
		inactive: {
			title: '処理を完了できませんでした',
			text: 'アクセスが拒否されました。詳細については、アカウント管理者にお問い合わせください。',
		},
		maintenance: {
			scheduled: {
				title: '定期メンテナンス',
				text: '現在、注文管理システムが定期メンテナンス中であるため、一時的に利用できません。ご迷惑をおかけして申し訳ございません。',
				end: 'メンテナンスの終了予定',
			},
		},
		passwordReset: {
			title: 'パスワードをリセット',
			info: 'アカウントのメール アドレスを入力してパスワードをリセットします。',
			success: '電子メールの受信ボックスにパスワード リセットのリンクが送信されました。',
		},
		newPassword: {
			title: '新しいパスワードを設定',
			form: {
				password: '新しいパスワード',
				confirm: 'パスワードを確認',
			},
			info: '新しいパスワードを入力してください。',
			validation: {
				digit: 'パスワードには数字を 1 つ以上含めてください',
				symbol: 'パスワードには記号を 1 つ以上含めてください',
				length: 'パスワードは 8 文字以上にしてください',
				uppercase: 'パスワードには英字の大文字を 1 つ以上含めてください',
				lowercase: 'パスワードには英字の小文字を 1 つ以上含めてください',
				notSame: '確認用パスワードが一致しません',
				trim: 'パスワードの先頭と末尾にスペースを追加することはできません。にスペースを',
			},
		},
	},
	state: {
		invalidCredentials: 'ユーザー名またはパスワードが正しくありません。もう一度お試しください。',
		invalidSso: 'ログインできません。セッションの期限が切れています。もう一度ログインしてください。',
		invalidAuthorizationCode: 'この承認コードでログインできません。',
		sessionExpired: 'セッションの期限が切れています。ログインしてください。',
	},
	error: {
		unableToResetPassword: '現在、パスワードをリセットできません。',
	},
};
