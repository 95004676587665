import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum LicenseSearchKeyEnum {
	LICENSE_KEY = 'LICENSE_KEY',
	ORDER_NUMBER = 'ORDER_NUMBER',
}

export const licenseSearchKeyEnumUtils = generateEnumUtils<LicenseSearchKeyEnum>(LicenseSearchKeyEnum, {
	translatePath: 'enums:licenses.searchKey',
});
