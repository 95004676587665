export default {
	common: {
		discountWaitingToApprove: 'この割引は販売マネージャーの承認待ちです。',
		selectCurrencyModalTitle: '顧客の通貨を選択',
		otherCustomerInformation: 'その他',
	},
	entity: {
		contactId: '顧客 ID',
		customerType: '顧客の種類',
		discount: '割引',
		industry: '業種',
	},
	error: {
		createCustomer: '顧客を作成できません。',
		updateCustomer: '顧客を更新できません。',
	},
	select: {
		title: '顧客を選択',
		button: {
			placeholder: '顧客を選択',
			label: '顧客',
		},
		filter: {
			placeholder: '顧客を検索',
			placeholderEmail: '顧客をメール アドレスで検索',
			placeholderType: '顧客の種類',
		},
		type: {
			EMAIL: 'メール アドレス',
			ID: 'Salesforce ID',
		},
	},
	page: {
		create: {
			title: '新しい顧客',
			success: '新しい顧客が正常に作成されました。',
			form: {
				email: '顧客がアクセス可能な正規のメール アドレスを使用してください。',
			},
		},
		update: {
			success: '顧客が正常に更新されました。',
		},
	},
	actions: {
		edit: '顧客を編集',
		exportAll: 'すべての顧客をエクスポート',
		newCustomer: '新しい顧客を作成',
		relatedLicenses: '関連するライセンスを表示',
		relatedOrders: '関連する注文を表示',
		relatedQuotes: '関連する見積もりを表示',
		viewLicenses: 'ライセンスを表示',
		viewOrders: '注文を表示',
		viewQuotes: '見積もりを表示',
		form: {
			create: '新しい顧客として保存',
			update: '変更を保存',
		},
	},
};
