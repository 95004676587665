import { IConfigLocale } from 'types/config';

/** Config object */
export const LOCALE: IConfigLocale = {
	DEFAULT_LOCALE: 'en-US',
	DEFAULT_LANGUAGE: 'en',
	LANGUAGES: ['en', 'de', 'it', 'es', 'pt', 'fr', 'ja', 'zh'], // List of available languages
	TIMEZONE: 'America/New_York',
	// @see https://date-fns.org/v2.7.0/docs/format
	DATETIME: {
		DATE: 'DD',
		DATE_TZ: 'DD ZZZZ',
		MONTH_YEAR: 'LLLL yyyy',
		DATETIME: 'DD tt',
		DATETIME_TZ: 'DD ttt',
		TIME: 'tt',
		TIME_TZ: 'ttt',
		MONTH: 'LLLL',
		YEAR: 'yyyy',
		INPUT: 'D',
		WEEK_DAY: 'ccc',
		DAY_LABEL: 'd',
	},
};
