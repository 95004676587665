import { solveWidgets } from 'submodule/widgets/solveWidgetRows';
import { Reducer } from 'react';
import { TWidgetContextState, TWidgetContextStateAction } from 'submodule/widgets';
import { isEqual } from 'lodash';

export const reducer: Reducer<TWidgetContextState, TWidgetContextStateAction> = (state, action) => {
	let nextState;

	switch (action.type) {
		case 'REORDER_ITEMS_IN_COLUMN':
			return solveWidgets.reorderColumnItems(state, action.payload.source, action.payload.destination);
		case 'MOVE_ITEM_TO_ANOTHER_COLUMN':
			return solveWidgets.appendLastEmptyRow(
				solveWidgets.moveItemToAnotherColumn(state, action.payload.source, action.payload.destination),
			);
		case 'SET_ONE_COLUMN':
			return solveWidgets.setOneColumn(state, action.payload);
		case 'SET_TWO_COLUMNS':
			return solveWidgets.setTwoColumns(state, action.payload);
		case 'ADD_WIDGET':
			return solveWidgets.appendLastEmptyRow(
				solveWidgets.addWidget(state, action.payload.values, action.payload.coords),
			);
		case 'REMOVE_WIDGET':
			return solveWidgets.appendLastEmptyRow(
				solveWidgets.removeWidget(state, action.payload.coords, action.payload.index),
			);
		case 'EDIT_WIDGET':
			nextState = solveWidgets.editWidget(state, action.payload.values, action.payload.coords, action.payload.index);
			if (isEqual(state, nextState)) {
				return state;
			}
			return nextState;
		default:
			throw new Error('Used undefined reducer action type');
	}
};
