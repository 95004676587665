import React from 'react';
import { STATUS_SUCCESS } from 'appConstants';
import { AsyncModal, TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { Modal } from '@avast/react-ui-components';
import { Formik } from 'formik';
import { TWidget, TWidgetActionPayload, TWidgetModalProps } from 'submodule/widgets';
import { preSubmitWidget } from 'submodule/widgets/utils';
import { useAllowedWidgets, useDefaultWidgetValues } from 'submodule/widgets/hooks';
import { WidgetForm } from 'submodule/widgets/forms';

type TAsyncWidgetModalProps = TAsyncModalContainerProps<TWidgetModalProps> & {
	onSubmit: (data: TWidgetActionPayload) => void;
};

export const AsyncWidgetModal = (props: TAsyncWidgetModalProps) => {
	const { forwardedRef, onSubmit } = props;
	const widgets = useAllowedWidgets();
	const defaultWidgetValues = useDefaultWidgetValues();

	return (
		<AsyncModal<TWidgetModalProps>
			ref={forwardedRef}
			show
			size="sm"
			backdrop="static"
			testId="widgetModal"
		>
			{({ title, index, initialValues = defaultWidgetValues, action, coords }) => (
				<>
					<Modal.Header className="pb-0">{title}</Modal.Header>
					<Modal.Body className="py-4">
						<Formik<TWidget>
							/* @ts-ignore */
							initialValues={initialValues}
							onSubmit={(values: TWidget) => {
								values = preSubmitWidget(widgets, values);
								if (action === 'ADD_WIDGET') {
									onSubmit({ values, coords });
								} else {
									onSubmit({ values, coords, index });
								}
								forwardedRef.current?.onSuccess(STATUS_SUCCESS);
							}}
						>
							<WidgetForm isUpdate={action === 'EDIT_WIDGET'} />
						</Formik>
					</Modal.Body>
				</>
			)}
		</AsyncModal>
	);
};
