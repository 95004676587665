import { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { useTranslation } from 'react-i18next';
import { creditStatusConfig } from 'module/creditStatus/creditStatusConfig';
import { IEntityCreditStatus } from 'module/creditStatus';
import { TableColumnsDef } from 'types';
import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';

type TTableData = IEntityCreditStatus;
export const useCreditStatusListColumns = (): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(creditStatusConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.link(
				'partnerId',
				{
					header: t('common:entity.partnerId'),
				},
				{
					to: (row) => buildParametrizedRoute(RouteName.CREDIT_STATUS.DETAIL, row.partnerId),
				},
			),
			columnHelper.text('companyName', {
				header: t('entity.companyName'),
				meta: { formatters: [formatter.bold] },
			}),
			columnHelper.text('countryCode', { header: t('entity.countryCode') }),
			columnHelper.money('creditLimit', { header: t('entity.creditLimit') }, { currencyKey: 'currency' }),
			columnHelper.money('creditAvailable', { header: t('entity.creditAvailable') }, { currencyKey: 'currency' }),
			columnHelper.percentage('percentConsumed', { header: t('entity.percentConsumed') }, { isNumber: false }),
			columnHelper.money('unbilledBalance', { header: t('entity.unbilledBalance') }, { currencyKey: 'currency' }),
			columnHelper.money('unpaidBalance', { header: t('entity.unpaidBalance') }, { currencyKey: 'currency' }),
			columnHelper.actions({
				link: {
					children: t('common:actions.detail'),
					to: (row) => buildParametrizedRoute(RouteName.CREDIT_STATUS.DETAIL, row.partnerId),
					testId: 'detail',
				},
			}),
		];
	}, [t]);
};
