import { IEntityProduct, IEntityProductGroup } from 'module/purchase';
import { useApiPromotionProductGroupList } from 'module/promotions/hooks/useApiPromotion';
import type { TSelectOptions } from '@avast/react-ui-components';
import { filterData } from 'js/utils/common';
import { sortBy, toLower } from 'lodash';

type TUsePromotionProductSelectOptions = {
	options: TSelectOptions;
	isLoading: boolean;
	isFetching: boolean;
};

export const usePromotionProductSelectOptions = (
	filter?: Partial<IEntityProductGroup>,
	forceEnabled?: boolean,
): TUsePromotionProductSelectOptions => {
	const {
		data: productGroupList,
		query: { isFetching, isInitialLoading },
	} = useApiPromotionProductGroupList();

	const groupListToSelectOptions = (group: IEntityProductGroup) =>
		group.products.map((product: IEntityProduct) => ({
			value: product.sku,
			label: group.name,
			description: product.sku,
			disabled: !group.firstPurchaseEnabled && !forceEnabled,
		}));

	const sortedProductGroupList = sortBy(productGroupList, [(item) => toLower(item.name)]);

	const options = filterData(sortedProductGroupList, filter).map(groupListToSelectOptions).flat();

	return {
		options,
		isLoading: isInitialLoading,
		isFetching,
	};
};
