import { useCallback } from 'react';
import { useFormikContext } from 'formik';

export const useFormikOnBlur = (name: string) => {
	const { setFieldTouched } = useFormikContext();
	return useCallback(() => {
		setTimeout(() => {
			setFieldTouched(name, true, true);
		}, 50);
	}, [name, setFieldTouched]);
};
