import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { Alert } from '@avast/react-ui-components';
import { IEntityGcPaymentInstructions } from 'js/chargeOrder';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { gcPaymentInstructions } from 'module/orders/utils/gcPaymentInstructions';

type TGcPaymentInstructionsProps = {
	paymentInstructions: IEntityGcPaymentInstructions;
};

export const GcPaymentInstructions = (props: TGcPaymentInstructionsProps) => {
	const [t] = useTranslation(ordersConfig.trNamespace);
	const [response, setResponse] = useState<false | null | string>(null);
	const {
		paymentInstructions: { url },
	} = props;

	useEffect(() => {
		let run = true;
		setResponse(false);
		const fetch = async () => {
			const response = await gcPaymentInstructions(url);
			if (run) {
				setResponse(response);
			}
		};

		fetch();
		return () => {
			run = false;
		};
	}, [url]);

	if (response === false) {
		return <LoadingPlaceholder />;
	}

	if (response === null) {
		return (
			<Alert
				variant="danger"
				caption={t('paymentInstructions.error.notAvailable')}
			/>
		);
	}

	return (
		<div
			className="fs-md"
			dangerouslySetInnerHTML={{ __html: response }}
		/>
	);
};
