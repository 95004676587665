import React, { ReactElement } from 'react';
import { H3 } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { IEntityPartner } from 'module/partners';
import { getPartnerFullName, getPartnerRenderName } from 'module/partners/utils/partnerSelectors';
import { isDefined, joinValues } from 'js/utils/common';
import { partnersConfig } from 'module/partners/partnersConfig';
import { Col, Row } from 'js/components/atoms/Row';
import { retailConfig } from 'module/retail/retailConfig';

type TGenerateRetailCodesPartnerDetailProps = {
	partner: IEntityPartner;
};

export const GenerateRetailCodesPartnerDetail = (props: TGenerateRetailCodesPartnerDetailProps): ReactElement => {
	const { partner } = props;
	const [t] = useTranslation(retailConfig.trNamespace);

	return (
		<>
			<H3
				bold
				className="mb-1"
			>
				{getPartnerRenderName(partner)}
			</H3>
			<Row
				multi
				size="sm"
			>
				<Col
					lg={5}
					sm={6}
				>
					<ul className="fs-sm reset">
						<li>{getPartnerFullName(partner)}</li>
						<li>
							{t(partnersConfig.trPrefix('common.avastId'))}: {partner.id}
						</li>
						{partner.salesforceId && (
							<li>
								{t(partnersConfig.trPrefix('entity.salesforceId'))}: {partner.salesforceId}
							</li>
						)}
						{isDefined(partner.identifiers?.partyTaxId) && (
							<li>
								{t('common:contact.taxId')}: {partner.identifiers?.partyTaxId}
							</li>
						)}
					</ul>
				</Col>
				<Col
					lg={5}
					sm={6}
				>
					<ul className="fs-sm reset">
						<li>{partner.address1}</li>
						<li>{joinValues([partner.postalCode, partner.stateName, partner.countryName])}</li>
						{isDefined(partner.phone) && (
							<li>
								{t('common:contact.phone')}: {partner.phone}
							</li>
						)}
						{isDefined(partner.email) && <li>{partner.email}</li>}
					</ul>
				</Col>
			</Row>
		</>
	);
};
