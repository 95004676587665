export default {
	actions: {
		detail: 'Ver detalles de licencia',
		export: 'Exportar licencias',
		exportDetailed: 'Exportación detallada',
		followingLicense: 'Siguiente licencia',
		lastNDays: 'Expirada en los últimos {{count}} días',
		nextNDays: 'Con fecha de expiración en los próximos {{count}} días',
		operations: 'Operaciones de licencia',
		precedingLicense: 'Licencia anterior',
		registerTo: 'Registre una licencia para ...',
		relatedCustomer: 'Ver cliente relacionado',
		relatedOrder: 'Ver pedido relacionado',
	},
	error: {
		disallowed: 'No se admiten operaciones de licencia para esta licencia.',
		operation: {
			invalidParty: 'Las partes de la licencia son diferentes a las partes del pedido actual',
			alreadyInCart: {
				title: 'Esta licencia ya está en el carrito.',
				message: 'Ya tiene esta licencia en el carrito. ¿Desea reemplazarla con la operación de licencia seleccionada?',
			},
			product: {
				noOperationsAvailable: 'No hay operaciones de licencia disponibles.',
			},
			partner: {
				unableToLoad: 'No se pudo cargar el socio de la licencia.',
			},
			customer: {
				unableToLoad: 'No se pudo cargar el cliente de la licencia.',
			},
			distributionPartner: {
				unableToLoad: 'No se pudo cargar el socio de distribución de la licencia.',
			},
			actions: {
				rewriteOperation: 'Reemplazar operación de licencia',
			},
			billablePartyRequired:
				'La licencia no tiene partes a las que se pueda facturar. Vaya a "Nueva compra" y seleccione las partes a las que se pueda facturar.',
		},
		registration: 'No se puede registrar la licencia.',
		detailNotFound: 'No se encontró el detalle del derecho #{{id}}',
	},
	common: {
		operation: {
			addToCart: 'Se agregó la operación de licencia al carrito.',
			goToCart: 'Ir al carrito',
		},
	},
	entity: {
		createdAt: 'Fecha de creación',
		customer: {
			contact: 'Contacto del cliente',
			id: 'Id. del cliente',
			email: 'Correo electrónico del cliente',
			name: 'Cliente',
		},
		partner: {
			contact: 'Contacto del socio',
			id: 'Id. de socio',
			name: 'Socio',
		},
		distributionPartner: {
			contact: 'Contacto del socio de distribución',
			id: 'Id. del socio de distribución',
			name: 'Socio de distribución',
		},
		date: 'Fecha',
		entitlementId: 'Id. del derecho',
		licenseId: 'Id. de licencia',
		licenseType: 'Tipo de licencia',
		modifiedAt: 'Fecha de modificación',
		orderId: 'Id. del pedido',
		product: {
			termLength: 'Longitud del plazo',
			type: 'Tipo de producto',
		},
		registeredAt: 'Fecha de registro',
		status: 'Estado de licencia',
		statusShort: 'Estado',
		statusPlaceholder: 'Cualquier estado',
		walletKey: 'Código de activación',
	},
	licenseOperation: {
		label: 'Operación de licencia',
	},
	registration: {
		registering: 'Registro de licencia',
		success: 'La licencia se registró correctamente para el cliente: {{customer}}',
		error: 'Se produjo error durante el registro de la licencia.',
		confirmation: {
			title: 'Registro de licencia',
			place: '¿Realmente quiere asociar este cliente a esta licencia?',
			replace: 'La licencia #{{licenseId}} ya está registrada. ¿Quiere continuar con el registro de esta licencia?',
		},
	},
	selectParty: {
		partner: {
			title: 'Seleccionar socio para el pedido',
			billableParty: 'Este socio será la parte a la que se podrá facturar.',
			requiredBillableParty: 'No es posible tener un pedido sin socio.',
			emptyBillableParty: 'En el siguiente paso, se seleccionará al cliente como parte a la que se puede facturar.',
			license: {
				title: 'Socio de licencia',
				button: 'Usar socio de licencia',
				buttonEmpty: 'Eliminar socio del pedido',
				empty: 'La licencia no tiene un socio.',
			},
			order: {
				title: 'Socio del pedido',
				button: 'Conservar socio del pedido',
				empty: 'El pedido no tiene un socio.',
				notAllowed: 'El socio no puede realizar operaciones de licencia en este grupo de productos.',
			},
		},
		customer: {
			title: 'Seleccionar cliente para el pedido',
			billableParty: 'Este cliente será la parte a la que se podrá facturar.',
			requiredBillableParty: 'No es posible tener un pedido sin cliente.',
			emptyBillableParty: 'Un socio será la parte a la que se podrá facturar.',
			license: {
				title: 'Cliente de licencia',
				button: 'Usar cliente de licencia',
				buttonEmpty: 'Eliminar cliente del pedido',
				empty: 'La licencia no tiene un cliente.',
			},
			order: {
				title: 'Cliente del pedido',
				button: 'Conservar cliente del pedido',
				empty: 'El pedido no tiene un cliente.',
			},
		},
		distributionPartner: {
			title: 'Seleccionar socio de distribución para el pedido',
			license: {
				title: 'Socio de distribución de licencia',
				button: 'Usar socio de distribución de licencia',
				buttonEmpty: 'Eliminar socio de distribución del pedido',
				empty: 'La licencia no tiene un socio de distribución.',
				notAllowed: 'No es posible asignar un socio de distribución al socio seleccionado.',
			},
			order: {
				title: 'Socio de distribución de pedido',
				button: 'Conservar socio de distribución de pedido',
				empty: 'El pedido no tiene un socio de distribución.',
				notAllowed: 'No es posible asignar un socio de distribución al socio seleccionado.',
			},
		},
	},
};
