import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { AsyncModal, TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { Modal, Toast } from '@avast/react-ui-components';
import { getPaymentTypeTranslationKey } from 'module/purchase/utils/common';
import { IStandardOrderCustomerQuote, IStandardOrderInstanceCustomPrice } from 'module/purchase';
import { Formik } from 'formik';
import { FormikForm } from 'js/components/formik/FormikForm';
import { useCustomerQuotePrices } from 'module/purchase/components/customerQuote/useCustomerQuotePrices';
import { toast } from 'react-toastify';
import { CustomerQuoteForm } from 'module/purchase/components/customerQuote/CustomerQuoteForm';
import { STATUS_SUCCESS } from 'appConstants';
import { PaymentTypeActionEnum, PaymentTypeTranslationKeyEnum } from 'module/purchase/enums';
import { useOrderContext } from 'js/contexts';

export type TAsyncCustomerQuoteModalProps = {};

export type TCustomerQuoteForm = IStandardOrderCustomerQuote & {
	items: IStandardOrderInstanceCustomPrice[];
};

export const AsyncCustomerQuoteModal = (
	props: TAsyncModalContainerProps<TAsyncCustomerQuoteModalProps>,
): ReactElement | null => {
	const { forwardedRef } = props;
	const { orderState, updateAdditionalData, updateCustomerPrices, getItemPricing } = useOrderContext();
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { hasInvalidItems } = useCustomerQuotePrices();

	return (
		<AsyncModal<TAsyncCustomerQuoteModalProps>
			ref={forwardedRef}
			size="lg"
			testId="customerQuote"
		>
			{() => (
				<>
					<Modal.Header>
						{t(
							getPaymentTypeTranslationKey(PaymentTypeActionEnum.CUSTOMER_QUOTE, PaymentTypeTranslationKeyEnum.CAPTION),
						)}
					</Modal.Header>
					<Formik<TCustomerQuoteForm>
						initialValues={{
							items: orderState.items.map(({ id, savedCustomerPrice }) => ({
								id,
								unitPrice: savedCustomerPrice ?? (getItemPricing(id)?.customerPrice?.unit || 0),
							})),
							customerQuoteMessage: orderState.additionalInfo.customerQuoteMessage,
						}}
						enableReinitialize
						onSubmit={(values, { setSubmitting }) => {
							updateAdditionalData({ customerQuoteMessage: values.customerQuoteMessage });

							if (hasInvalidItems(values)) {
								toast.error(<Toast>{t('components.customerQuote.error.invalidItems')}</Toast>);
								setSubmitting(false);
								return;
							}

							updateCustomerPrices(values.items);
							setSubmitting(false);
							forwardedRef.current?.onSuccess(STATUS_SUCCESS);
						}}
					>
						<FormikForm testId="customerQuote">
							<Modal.Body>
								<CustomerQuoteForm forwardedRef={forwardedRef} />
							</Modal.Body>
						</FormikForm>
					</Formik>
				</>
			)}
		</AsyncModal>
	);
};
