import React, { FunctionComponent } from 'react';
import { ModuleContainer } from 'js/layouts/container/ModuleContainer';
import { ordersConfig } from 'module/orders/ordersConfig';
import { QuoteRoutes } from 'module/orders/QuoteRoutes';

export const QuotesContainer: FunctionComponent = () => (
	<>
		<ModuleContainer moduleMenuConfig={ordersConfig.quotes} />
		<QuoteRoutes />
	</>
);
