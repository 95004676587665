import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { PageDetailComponent } from 'js/layouts/page/PageDetailComponent';
import { useAuthContext } from 'js/contexts';
import { useRouteParams } from 'js/hooks/useRouteParams';
import React, { useMemo } from 'react';
import { TDetailArguments } from 'types';
import { TUseApiDetailProps } from 'js/queries/useApiDetailQuery';
import { Outlet } from 'react-router-dom';
import { getDistributionPartnerRenderName } from 'module/distributionPartners/utils/distributionPartnerSelector';
import { useApiFlexibleBillingDistributionPartner } from 'module/flexibleBilling/hooks';
import { IApiFlexibleBillingDetailFilter, IFlexibleBillingDistributionPartner } from 'module/flexibleBilling';

type TArguments = TDetailArguments;
type TData = IFlexibleBillingDistributionPartner;
type TQueryFilter = IApiFlexibleBillingDetailFilter;
type TQueryProps = TUseApiDetailProps<TData, TQueryFilter>;

export const FlexibleBillingDetailLayout = () => {
	const { authCompanyId } = useAuthContext();
	const params = useRouteParams<TArguments>();

	// Setup query props/filter for the distribution partner detail
	const queryProps: TQueryProps = useMemo(
		() => ({
			filter: {
				id: params.id,
				distributorId: authCompanyId,
			},
		}),
		[params, authCompanyId],
	);

	return (
		<PageDetailComponent<TData, TQueryFilter>
			query={useApiFlexibleBillingDistributionPartner}
			queryProps={queryProps}
			titleRender={(data) => getDistributionPartnerRenderName(data?.distributionPartner)}
			trNamespace={flexibleBillingConfig.trNamespace}
			detailLink={flexibleBillingConfig.detailLinkById(params.id)}
		>
			<Outlet />
		</PageDetailComponent>
	);
};
