export default {
	requestId: 'Anfrage-ID',
	errorId: 'Fehler-ID',
	errorDetails: 'Fehlerdetails',
	api: {
		activeEntitlementNotFound:
			'Dieser Lizenzvorgang kann nicht abgeschlossen werden, da die Berechtigung bereits ersetzt wurde.',
		invalidHash: 'Hash ist ungültig.',
		distributionPartnerSalesforceConflict:
			'Der angegebene Kontakt gehört zu einem anderen Unternehmen. Wenden Sie sich an Ihren Account Manager, um weitere Informationen zu erhalten.',
		passwordResetInvalidToken: 'Das Token zum Zurücksetzen des Passworts ist ungültig.',
		passwordResetExpiredToken: 'Das Token zum Zurücksetzen des Passworts ist abgelaufen.',
		passwordAlreadyUsed:
			'Dieses Passwort wurde bereits in der Vergangenheit verwendet. Wählen Sie ein anderes Passwort.',
		partnerNotRegistered:
			'Bestellungen aufzugeben ist nur mit einem registrierten Konto zulässig. Fordern Sie den Partner auf, zuerst die OMS-Registrierung abzuschließen.',
		nowTermsNotAccepted:
			'Das Aufgeben von Bestellungen ist bei Konten zulässig, für die die Allgemeinen Geschäftsbedingungen akzeptiert wurden. Fordern Sie den Partner auf, die aktualisierten Allgemeinen Geschäftsbedingungen zu akzeptieren.',
		licenseOperationWrongSegment:
			'Die ausgewählte fakturierbare Partei ist nicht zum Kauf von Produkten für Unternehmen/Privatanwender berechtigt.',
		partnerQuoteCurrencyMismatch:
			'Diese Bestellung kann nicht verarbeitet werden, da die Währung des Kontos von der Währung des Angebot abweicht. Bitte wenden Sie sich an Ihren Account Manager und fordern Sie ein neues Angebot an.',
	},
	page: {
		404: {
			title: 'Nachricht der IT-Abteilung (Seite 404)',
			message:
				'Es ist ein Fehler aufgetreten, die angeforderte URL existiert nicht. Klicken Sie auf "Weiter", um fortzufahren.',
			back: 'Zurück zur Homepage',
		},
	},
	status: {
		networkError: 'Verbindung zum Server konnte nicht hergestellt werden.',
	},
	common: {
		contactSales: 'Wenden Sie sich zur Unterstützung an Ihren Account Manager.',
		repeatOrContactSales: 'Versuchen Sie es später erneut oder wenden Sie sich an Ihren Account Manager.',
	},
	detailNotFound: 'Detail konnte nicht geladen werden oder ist nicht vorhanden.',
	permissionDenied: 'Sie haben keine Berechtigung zum Zugriff auf diese Aktion/Ressource.',
	sectionDataNotFound: 'Zu diesem Abschnitt wurden keine Daten gefunden.',
	beforeUnload: 'Wenn Sie dies jetzt verlassen, verlieren Sie eventuell Ihre Daten.',
	unspecifiedError: 'Ihre Anfrage konnte nicht verarbeitet werden.',
	errorDetail: 'Technische Details (beim Melden eines Fehlers angeben)',
};
