export default {
	entity: {
		createdBy: 'Créateur',
	},
	actions: {
		create: 'Créer une limite de crédit',
	},
	error: {
		createCreditLimit: 'Impossible de créer la limite de crédit.',
	},
	modal: {
		create: {
			title: 'Créer une limite de crédit',
			loading: 'Création de la limite de crédit',
			success: 'La limite de crédit a été créée.',
		},
	},
};
