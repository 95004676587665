import { useAllowedWidgets } from 'submodule/widgets/hooks/useAllowedWidgets';
import * as Yup from 'yup';
import { TWidget, TWidgetColumn } from 'submodule/widgets';
import { WidgetTypeEnum } from 'submodule/widgets/enums';
import { logError } from 'js/utils/app';
import { difference } from 'lodash';
import { getAvailableColumnsKeys, isValidWidgetSettingsValues } from 'submodule/widgets/utils';

export const useWidgetValidator = () => {
	const widgets = useAllowedWidgets();

	return Yup.object().shape<TWidget>({
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		type: Yup.string<WidgetTypeEnum | any>().required(),
		label: Yup.string().notRequired(),
		settings: Yup.object()
			.required()
			.test({
				message: 'Invalid settings value',
				test(settings) {
					const { type } = this.parent;
					const widgetConfig = widgets.find((item) => item.type === type);

					if (!widgetConfig) {
						logError(`Invalid widget type "${type}"`, this.parent);
						return false;
					}

					const invalidSettings = difference(Object.keys(settings), Object.keys(widgetConfig.getSettingsFields()));

					if (invalidSettings.length) {
						logError('Invalid widget settings', invalidSettings);
						return false;
					}

					if (!isValidWidgetSettingsValues(this.parent, widgetConfig)) {
						logError('Invalid widget settings values', this.parent, widgetConfig.getSettingsFields());
						return false;
					}

					return true;
				},
			}),
		columns: Yup.array<TWidgetColumn>()
			.notRequired()
			.test({
				message: 'Invalid columns value',
				test(columns) {
					const { type } = this.parent;
					const widgetConfig = widgets.find((item) => item.type === type);

					if (!widgetConfig) {
						logError(`Invalid widget type "${type}"`, this.parent);
						return false;
					}

					// Validate columns
					if (columns) {
						const invalidColumns = difference(columns as TWidgetColumn[], getAvailableColumnsKeys(widgetConfig));
						if (invalidColumns.length) {
							logError('Invalid widget columns', invalidColumns, this.parent);
							return false;
						}
					}

					return true;
				},
			}),
	});
};
