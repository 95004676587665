import React, { useEffect, useState } from 'react';
import { FormControl, IconButton, useInputRef } from '@avast/react-ui-components';
import { InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import { useCompare } from 'js/hooks/useCompare';
import { toString, trim, trimEnd } from 'lodash';

type TSearchBoxProps = {
	onChange: (value: string) => void;
	placeholder?: string;
	delay?: number;
	inline?: boolean;
	disabled?: boolean;
	value?: string;
	size?: 'sm' | 'lg';
	autoFocus?: boolean;
};

const SearchBox = (props: TSearchBoxProps) => {
	const { delay = 350, value, size, disabled, autoFocus } = props;
	// Component states
	const updatedValue = useCompare(props.value);
	const [searchValue, setSearchValue] = useState<string>(toString(value));
	const ref = useInputRef();

	// Pass filter value to table with delay
	const filterOnChange = useDebouncedCallback((value) => props.onChange(trim(value)), delay);

	useEffect(() => {
		if (autoFocus) {
			ref.current?.focus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Update values from outside
	 */
	useEffect(() => {
		const value = toString(props.value);
		if (updatedValue && trimEnd(searchValue) !== value) {
			setSearchValue(value);
		}
	}, [props.value, updatedValue, searchValue]);

	return (
		<InputGroup
			size="sm"
			className={classNames('component__search-box', { 'is-inline': Boolean(props.inline) })}
		>
			<InputGroup.Text>
				<FontAwesomeIcon icon={faSearch} />
			</InputGroup.Text>
			<FormControl.Input
				name="search"
				size={size}
				value={searchValue}
				forwardedRef={ref}
				onChange={(value) => {
					setSearchValue(value);
					filterOnChange(value);
				}}
				placeholder={props.placeholder}
				className={classNames({ 'is-empty': !searchValue })}
				autoComplete="off"
				disabled={disabled}
			/>
			{searchValue && (
				<IconButton
					testId="searchReset"
					size={size}
					className="reset"
					iconFa={faTimes}
					variant="reset"
					disabled={disabled}
					onClick={() => {
						setSearchValue('');
						filterOnChange('');
					}}
				/>
			)}
		</InputGroup>
	);
};

export { SearchBox };
