import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useEditPrices } from 'module/purchase/components/editPrices/useEditPrices';
import { Alert, Modal } from '@avast/react-ui-components';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import { TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { EditPricesTable } from 'module/purchase/components/editPrices/EditPricesTable';
import { useFormikContext } from 'formik';
import { TEditPricesForm } from 'module/purchase/components/editPrices/AsyncEditPricesModal';

export const EditPricesModal = (props: TAsyncModalContainerProps): ReactElement | null => {
	const { forwardedRef } = props;
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { hasEditedPrices } = useEditPrices();
	const { values } = useFormikContext<TEditPricesForm>();

	return (
		<Modal.Body>
			<Alert variant="warning">{t('components.editPrices.message')}</Alert>
			<EditPricesTable />
			<FormikFooterControls
				className="mt-4"
				hr={false}
				cancel={{
					onClick() {
						forwardedRef.current?.onCancel();
					},
				}}
				submit={{
					children: t('components.editPrices.submit'),
					disabled: !hasEditedPrices(values),
				}}
			/>
		</Modal.Body>
	);
};
