import { UiTable } from '@avast/react-ui-components';
import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { IEntityLicense, ILicenseListApiFilter, ILicenseListFilter } from 'module/licenses';
import { useApiLicenseListByLicenseString, useFilterLicenses } from 'module/licenses/hooks';
import { buildParametrizedRoute, orderObject, sortByToTableSortingState } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { TApiPaginatedListTableProps } from 'js/components/molecules/DataTable/ApiPaginatedListTable';
import { IListMetaDataValues } from 'types/utils';
import { useTableStateHelper } from 'js/components/molecules/DataTable';
import { useNavigate } from 'react-router-dom';

type TTableData = IEntityLicense;
type TTableDataFilter = ILicenseListFilter;
type TTableDataApiFilter = ILicenseListApiFilter;
type TApiPaginatedList = TApiPaginatedListTableProps<TTableData, TTableDataFilter, TTableDataApiFilter>;

type TSearchByLicenseStringTableProps = Pick<TApiPaginatedList, 'columns' | 'sort'> & {
	onMetaDataChange: Dispatch<SetStateAction<IListMetaDataValues<TTableDataFilter>>>;
	filter: TTableDataFilter;
};

export const SearchByLicenseStringTable = (props: TSearchByLicenseStringTableProps) => {
	const { columns, onMetaDataChange, sort, filter } = props;
	const navigate = useNavigate();
	const searchValue = filter.search?.value;

	const { setPagination, pagination, sorting, setSorting } = useTableStateHelper<TTableData, TTableDataFilter>({
		initialState: { sorting: sortByToTableSortingState<TTableData>(sort) },
		loadFromUrl: true,
		tableFilter: filter,
	});

	// Get license by license string
	const {
		data,
		query: { isInitialLoading },
	} = useApiLicenseListByLicenseString({
		filter: { licenseString: searchValue },
	});
	const licenses = useFilterLicenses(data, filter);

	const _filter = JSON.stringify(orderObject(filter));
	useEffect(() => {
		onMetaDataChange({
			totalCount: licenses?.length,
			filter: JSON.parse(_filter),
		});
	}, [licenses, _filter, searchValue, onMetaDataChange]);

	// On row selected -> license detail
	const onRowClick = useCallback<(license: TTableData) => void>(
		(license) => {
			navigate(buildParametrizedRoute(RouteName.LICENSE.DETAIL, license.id));
		},
		[navigate],
	);

	return (
		<UiTable<TTableData>
			testId="licenses"
			data={licenses}
			columns={columns}
			state={{ pagination, sorting }}
			onPaginationChange={setPagination}
			onSortingChange={setSorting}
			enableSorting
			enablePagination
			meta={{
				isScrollable: true,
				nowrapCell: true,
				loading: isInitialLoading,
				onRowClick,
				scrollTopOnPageChange: true,
				paginationComponent: 'Full',
			}}
		/>
	);
};
