import React from 'react';
import { Col } from 'js/components/atoms/Row';
import { Dropdown } from '@avast/react-ui-components';
import { getPartnerFullName } from 'module/partners/utils/partnerSelectors';
import { Can } from 'js/components/molecules/Can';
import { accountConfig } from 'module/account/accountConfig';
import { DropdownLink } from 'js/components/molecules/Dropdown/DropdownLink';
import { RouteName } from 'module/RouteName';
import { CONFIG } from 'config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useIsPayAsYouGoEligible } from 'module/account/hooks/useIsPayAsYouGoEligible';
import { useAuthContext, useOrderContext } from 'js/contexts';

export const AuthPartnerDropdownCol = () => {
	const { authPartner, authCompany } = useAuthContext();
	const { resetOrder } = useOrderContext();
	const [t] = useTranslation();
	const isPayAsYouGoEligible = useIsPayAsYouGoEligible();

	if (!authPartner) {
		return null;
	}

	return (
		<Col
			xs="auto"
			className="h-100 pe-0"
		>
			<Dropdown
				variant="secondary"
				text={
					<>
						<span className="d-block">{getPartnerFullName(authPartner) || authPartner.companyName}</span>
						{authCompany?.companyName && <small className="d-block pb-1">{authCompany.companyName}</small>}
					</>
				}
				toggleClassName="rounded-0 h-100 text-start btn-block"
				className="header-dropdown h-100"
				align="end"
				testId="userMenu"
			>
				<Can
					do={accountConfig.aclModule}
					read
					authGuards={accountConfig.authGuards}
				>
					<>
						<DropdownLink
							to={RouteName.ACCOUNT.DEFAULT}
							testId="account"
						>
							{t(accountConfig.trPrefix('page.default.title'))}
						</DropdownLink>
						{authCompany?.isTaxExemptEligible && (
							<DropdownLink
								to={RouteName.ACCOUNT.TAX_EXEMPTION}
								testId="taxExemption"
							>
								{t(accountConfig.trPrefix('page.taxExemption.shortTitle'))}
							</DropdownLink>
						)}
						{isPayAsYouGoEligible && (
							<DropdownLink to={RouteName.ACCOUNT.FLEXIBLE_BILLING}>
								{t(accountConfig.trPrefix('page.flexibleBilling.title'))}
							</DropdownLink>
						)}
						<Dropdown.Divider />
					</>
				</Can>
				{CONFIG.DEBUG && (
					<>
						<Dropdown.Item
							danger
							onClick={resetOrder}
						>
							{t('common:actions.emptyCart')}
						</Dropdown.Item>
						<Dropdown.Divider />
					</>
				)}
				<DropdownLink
					to={RouteName.SECURITY.LOGOUT}
					danger
					testId="logout"
				>
					<span className="d-flex align-items-center">
						<FontAwesomeIcon
							icon={faSignOutAlt}
							className="me-2"
						/>
						{t('common:actions.logout')}
					</span>
				</DropdownLink>
			</Dropdown>
		</Col>
	);
};
