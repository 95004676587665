import { WidgetTypeEnum, widgetTypeEnumUtils } from 'submodule/widgets/enums';
import { getWidgetSettingsValues } from 'submodule/widgets/utils';
import { useWidgets } from './useWidgets';
import { TWidget } from 'submodule/widgets';

export type TWidgetHeader = {
	title: string;
	meta?: string;
	description?: string | null;
};

export const useWidgetHeader = (item: TWidget): TWidgetHeader => {
	const widgets = useWidgets();
	const widgetConfig = widgets.find((widget) => widget.type === item.type);
	const description = getWidgetSettingsValues(item, widgetConfig);
	const typeName = widgetTypeEnumUtils.getText(item.type as WidgetTypeEnum);
	let title = typeName;
	const meta = [];

	if (item.label) {
		title = item.label;
		meta.push(typeName);
	}

	return {
		title,
		meta: meta.join(', '),
		description,
	};
};
