import { IStandardOrderItemPricing } from 'module/purchase';
import React, { ReactNode } from 'react';
import { numberFormatter } from 'js/utils/number';
import { TUsePartyPrefixTranslation } from 'module/purchase/hooks/usePartyPrefixTranslation';
import { IEntityOrderItemComputedValues } from 'module/orders';
import type { TFunction } from 'i18next';

export type TPricingBreakdownLine = [string, ReactNode];

interface IGetPricingBreakdownProps {
	pricing: IStandardOrderItemPricing | null | undefined;
	t: TFunction;
	currencyCode: string | undefined;
	tPartyPrefix: TUsePartyPrefixTranslation['tPartyPrefix'];
}

interface IGetCustomerPricingBreakdownProps extends IGetPricingBreakdownProps {
	quotedCustomerPricing?: IEntityOrderItemComputedValues['price']['quotedCustomer'];
}

export const getPartnerPricingBreakdown = (props: IGetPricingBreakdownProps): TPricingBreakdownLine[] => {
	const { pricing, t, tPartyPrefix, currencyCode } = props;
	const data: TPricingBreakdownLine[] = [];

	// Partner discount
	if (pricing?.discount?.partner?.percent) {
		data.push([
			t('pricing.discount.partner'),
			numberFormatter.percentage(pricing.discount.partner.percent, { optionalMantissa: true, isNumber: false }),
		]);
	}

	// Discretionary discount
	if (pricing?.discount?.discretionary?.percent) {
		data.push([
			t('pricing.discount.discretionary'),
			numberFormatter.percentage(pricing.discount.discretionary.percent, {
				optionalMantissa: true,
				isNumber: false,
			}),
		]);
	}

	// Promo discount
	if (pricing?.discount?.promo?.percent) {
		data.push([
			t('pricing.discount.promo'),
			numberFormatter.percentage(pricing.discount.promo.percent, { optionalMantissa: true, isNumber: false }),
		]);
	}

	// Add unit price after all discounts
	data.push([
		tPartyPrefix('common:entity.unitPrice', 'partner'),
		numberFormatter.currency(pricing?.unitPrice, currencyCode),
	]);

	// Total customer price
	data.push([
		tPartyPrefix('common:entity.totalPrice', 'partner'),
		numberFormatter.currency(pricing?.linePriceWOTax, currencyCode),
	]);

	return data;
};

export const getCustomerPricingBreakdown = (props: IGetCustomerPricingBreakdownProps): TPricingBreakdownLine[] => {
	const { pricing, t, tPartyPrefix, currencyCode, quotedCustomerPricing } = props;
	const data: TPricingBreakdownLine[] = [];

	// Add SRP / base unit price
	data.push([
		tPartyPrefix('common:entity.unitPrice', 'srp'),
		numberFormatter.currency(pricing?.srpPrice?.unit, currencyCode),
	]);

	// Add segment discount
	if (pricing?.discount?.segment?.percent) {
		data.push([
			t('pricing.discount.segment'),
			<>
				{numberFormatter.percentage(pricing.discount.segment.percent, {
					optionalMantissa: true,
					isNumber: false,
				})}{' '}
				({pricing?.customerSegment})
			</>,
		]);
	}

	// Renewal discount
	if (pricing?.discount?.renewal?.percent) {
		data.push([
			t('pricing.discount.renewal'),
			numberFormatter.percentage(pricing.discount.renewal.percent, { optionalMantissa: true, isNumber: false }),
		]);
	}

	// Add unit price after all discounts
	data.push([
		tPartyPrefix('common:entity.unitPrice', 'customer'),
		numberFormatter.currency(pricing?.customerPrice?.unit, currencyCode),
	]);

	// Total customer price
	data.push([
		tPartyPrefix('common:entity.totalPrice', 'customer'),
		numberFormatter.currency(pricing?.customerPrice?.total, currencyCode),
	]);

	// Quoted / Custom price
	if (quotedCustomerPricing) {
		data.push([
			tPartyPrefix('common:entity.unitPrice', 'quotedCustomer'),
			numberFormatter.currency(quotedCustomerPricing.unit, currencyCode),
		]);
		data.push([
			tPartyPrefix('common:entity.totalPrice', 'quotedCustomer'),
			numberFormatter.currency(quotedCustomerPricing.total, currencyCode),
		]);
	}

	return data;
};
