import { useFormikContext } from 'formik';
import { IPriceCalculatorForm } from 'module/priceCalculator';
import { PurchaseBillablePartyTypeEnum } from 'module/purchase/enums';

export const useBillablePartyGuard = () => {
	const { values } = useFormikContext<IPriceCalculatorForm>();
	const isPartner = values.billablePartyType === PurchaseBillablePartyTypeEnum.PARTNER;
	const isCustomer = values.billablePartyType === PurchaseBillablePartyTypeEnum.CUSTOMER;

	return {
		isPartner,
		isCustomer,
		isExistingPartner: isPartner && Boolean(values.partner),
	};
};
