import { useFormikContext } from 'formik';
import { IPromotionForm } from 'module/promotions';
import { PromotionScopeEnum, promotionScopeEnumUtils } from 'module/promotions/enums';
import { Card } from '@avast/react-ui-components';
import { Col, Row } from 'js/components/atoms/Row';
import { FormikControl } from 'js/components/formik/FormikControl';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import classNames from 'classnames';
import { MarketSegmentEnum, marketSegmentEnumUtils } from 'module/purchase/enums';
import { TProductSku } from 'types';
import { usePromotionProductSelectOptions } from 'module/promotions/hooks/usePromotionProductSelectOptions';
import { LicenseOperationEnum, licenseOperationEnumUtils } from 'module/licenses/enums';
import { getLabelPropsForTooltip } from 'js/utils/form';

export const PromotionFormProductBlock = ({ isUpdate }: { isUpdate: boolean }) => {
	const [t] = useTranslation(promotionsConfig.trNamespace);
	const { values, setFieldValue } = useFormikContext<IPromotionForm>();

	const isEnabled = promotionScopeEnumUtils.validateOneOf(values.scope, [
		PromotionScopeEnum.PARTY_AND_PRODUCT,
		PromotionScopeEnum.PRODUCT_ONLY,
	]);
	const isDisabled = !isEnabled;

	const isBusiness = values.marketSegment === MarketSegmentEnum.BUSINESS;
	const { options: productSelectOptions, isLoading: isProductSelectOptionsLoading } = usePromotionProductSelectOptions({
		firstPurchaseEnabled: isUpdate ? undefined : true,
		marketSegment: values.marketSegment,
	});

	const marketSegmentHasChanged = (newValue: MarketSegmentEnum) => values.marketSegment !== newValue;

	return (
		<Card className={classNames('mt-3', { 'd-none': isDisabled })}>
			<Card.Header>{t('common.productCriteria')}</Card.Header>
			<Card.Body>
				<Row size="md">
					<Col sm={4}>
						<FormikControl label={t('entity.category')}>
							<FormikControl.SingleSelect<MarketSegmentEnum>
								name="marketSegment"
								options={marketSegmentEnumUtils.getSelectOptions()}
								isClearable={false}
								required={isEnabled}
								disabled={isDisabled}
								placeholder={t('placeholder.categories')}
								onChange={(value) => {
									if (marketSegmentHasChanged(value as MarketSegmentEnum)) {
										setFieldValue('skuList', []);
									}
								}}
							/>
						</FormikControl>
					</Col>
					<Col sm={4}>
						<FormikControl
							label={
								isBusiness
									? t('entity.unitsFrom')
									: getLabelPropsForTooltip(t('entity.quantityMin'), t('tooltip.zeroQuantity'))
							}
						>
							<FormikControl.Number
								name="quantity"
								disabled={isDisabled}
								required
							/>
						</FormikControl>
					</Col>
					{isBusiness && (
						<Col sm={4}>
							<FormikControl label={t('entity.unitsTo')}>
								<FormikControl.Number
									name="quantityMax"
									disabled={isDisabled}
									required
								/>
							</FormikControl>
						</Col>
					)}
					<Col sm={12}>
						<FormikControl label={t('entity.product', { count: 0 })}>
							<FormikControl.MultiSelect<TProductSku>
								name="skuList"
								options={productSelectOptions}
								placeholder={t('placeholder.products')}
								isLoading={isProductSelectOptionsLoading}
								disabled={isDisabled}
								formatOptionLabel={(option) => option.value}
								closeMenuOnSelect={false}
							/>
						</FormikControl>
					</Col>
					<Col sm={12}>
						<FormikControl label={t('entity.licenseOperation')}>
							<FormikControl.MultiSelect<LicenseOperationEnum>
								name="licenseTypeList"
								options={licenseOperationEnumUtils.getSelectOptions()}
								placeholder={t('placeholder.licenseOperations')}
								disabled={isDisabled}
								closeMenuOnSelect={false}
							/>
						</FormikControl>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};
