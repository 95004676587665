import numbro from 'numbro';
import type { TUILocaleNumber, TUILocaleNumberOptions, TUILocaleNumberValue } from '@avast/react-ui-components';
import { TLocale } from 'types/config';
import { isFinite, isNumber, isString } from 'lodash';
import { localesConfig } from 'i18n/locales';
import { LOCALE } from 'config/locale';

export type TNumberFormatterOptions = TUILocaleNumberOptions;
export type TNumberFormatterPercentageOptions = TNumberFormatterOptions & { isNumber?: boolean };

type TNumberFormatter = {
	_defaultOptions: TNumberFormatterOptions;

	init(): void;
	format: TUILocaleNumber;
	currency(value: TUILocaleNumberValue, symbol?: string | null, options?: TNumberFormatterOptions): string;
	percentage(value: TUILocaleNumberValue, options?: TNumberFormatterPercentageOptions): string;
	setLocale(locale: TLocale): void;
};

export const numberFormatter: TNumberFormatter = {
	/** @private */
	_defaultOptions: {
		mantissa: 2,
		optionalMantissa: false,
		thousandSeparated: true,
	},

	init() {
		this.setLocale(LOCALE.DEFAULT_LOCALE);
	},

	/**
	 * Update instance locale
	 * @param {TLocale} locale
	 * @returns {void}
	 */
	setLocale(locale: TLocale): void {
		const language = localesConfig[locale].format.numbro;

		if (isString(language)) {
			numbro.setLanguage(language);
		} else {
			numbro.registerLanguage(language, true);
		}
	},

	/**
	 * Format a value
	 * @param {TUILocaleNumberValue} value
	 * @param {TNumberFormatterOptions} options
	 * @return {string}
	 */
	format(value, options = {}): string {
		// No value
		if (!isFinite(value)) {
			return '';
		}

		return numbro(value).format({ ...this._defaultOptions, ...options });
	},

	/**
	 * Format a number with currency symbol, what is taken from arguments
	 *
	 * @param {TUILocaleNumberValue} value
	 * @param {string} symbol
	 * @param {TNumberFormatterOptions} options
	 * @return {string}
	 */
	currency(value, symbol, options = {}): string {
		if (isString(symbol)) {
			return this.format(value, {
				output: 'currency',
				spaceSeparatedCurrency: true,
				currencySymbol: symbol,
				currencyPosition: 'postfix',
				...options,
			});
		}

		return this.format(value, options);
	},

	/**
	 * Format a number as percentage value
	 * @param {number} value
	 * @param {{}} options
	 * @returns {string}
	 */
	percentage(value, options = {}): string {
		const { isNumber: isNumberProp, ...rest } = options;
		if (isNumberProp === false && isNumber(value)) {
			value /= 100;
		}

		return this.format(value, {
			output: 'percent',
			optionalMantissa: true,
			mantissa: 2,
			spaceSeparated: true,
			...rest,
		});
	},
};
