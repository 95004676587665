import { useCallback } from 'react';
import { TPriceListCode } from 'js/priceList';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useAppContext, useOrderContext } from 'js/contexts';

type TUsePriceListGuardResponse = (priceListCode: TPriceListCode) => Promise<boolean>;

/**
 * Resolve if the cart should be cleared and the user agree with it
 * @returns {TUsePriceListGuardResponse}
 */
export const usePriceListGuard = (): TUsePriceListGuardResponse => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { confirmationModalRef } = useAppContext();
	const { orderState } = useOrderContext();
	const currentPriceList = orderState.priceListCode;
	const itemsCount = orderState.items.length;

	return useCallback(
		async (priceListCode) => {
			// No products in the cart OR no current price list
			if (itemsCount === 0 || !currentPriceList) {
				return true;
			}

			// Current price list is also included in new list
			if (priceListCode === currentPriceList) {
				return true;
			}

			// Resolution by user
			return Boolean(
				await confirmationModalRef.current?.show({
					title: t('guards.priceListGuard.title'),
					messages: [
						{
							caption: t('guards.priceListGuard.messageCaption'),
							children: t('guards.priceListGuard.message'),
						},
					],
					submitButtonText: t('common:actions.continue'),
				}),
			);
		},
		[currentPriceList, t, confirmationModalRef, itemsCount],
	);
};
