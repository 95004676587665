import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Alert, Button } from '@avast/react-ui-components';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useRequiredOrderParties } from 'module/purchase/hooks/useRequiredOrderParties';
import { ActionLink } from 'js/components/atoms/Button';
import { useOrderContext } from 'js/contexts';

type TRequiredCustomerAlertProps = {
	className?: string;
};

export const RequiredCustomerAlert = (props: TRequiredCustomerAlertProps) => {
	const { className } = props;
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { customer } = useRequiredOrderParties();
	const { orderState, setCustomer, selectCustomerRef } = useOrderContext();
	const partnerId = orderState?.partner?.id;
	const [show, toggle] = useState(false);

	const selectCustomer = async () => {
		const customer = await selectCustomerRef.current?.show({
			partnerId,
			disallowPartnerCustomers: true,
		});

		if (customer) {
			setCustomer(customer);
		}
	};

	if (customer.valid) {
		return null;
	}

	return (
		<Alert
			variant="warning"
			caption={t('error.requiredCustomer.title')}
			className={className}
		>
			<div className="d-flex">
				<div className="flex-grow-1 align-self-center">
					<p>{t('error.requiredCustomer.text')}</p>
					<p className="fw-bold">
						<ActionLink
							onClick={() => toggle((state) => !state)}
							className="fw-bold"
						>
							{t('error.requiredCustomer.tooltip.question')}
						</ActionLink>
					</p>
					{show && <p className="font-italic">{t('error.requiredCustomer.tooltip.answer')}</p>}
				</div>
				<div className="flex-shrink-0 ps-3">
					<Button
						size="xs"
						variant="primary"
						onClick={selectCustomer}
					>
						{t('error.requiredCustomer.action')}
					</Button>
				</div>
			</div>
		</Alert>
	);
};
