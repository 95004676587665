export default {
	dateRangeType: {
		BILLING: 'Data di fatturazione',
		CREATED: 'Data di creazione',
		PAYMENT: 'Data di pagamento',
	},
	tierCertification: {
		DISTRIBUTORS: 'Distributori',
		TIER_4: 'Livello 4',
		TIER_3: 'Livello 3',
		TIER_2: 'Livello 2',
		TIER_1: 'Livello 1',
	},
	customer: {
		type: {
			BUSINESS: 'Aziende',
			CONSUMER: 'Privati',
		},
		discountType: {
			EDUCATION: {
				name: 'Educazione',
				description:
					'Sconto aggiuntivo per gli istituti scolastici. Per l’applicazione dello sconto all’ordine, contattare il responsabile delle vendite.',
			},
			GOVERNMENT: {
				name: 'Enti pubblici',
				description:
					'Sconto aggiuntivo per gli enti pubblici. Per l’applicazione dello sconto all’ordine, contattare il responsabile delle vendite.',
			},
			NOT_FOR_PROFIT: {
				name: 'Organizzazioni no profit',
				description:
					'Sconto aggiuntivo per le organizzazioni no profit. Per l’applicazione dello sconto all’ordine, contattare il responsabile delle vendite.',
			},
		},
		searchKey: {
			EMAIL: 'Indirizzo e-mail',
			SALESFORCE_ID: 'Id Salesforce',
		},
	},
	partner: {
		type: {
			DISTRIBUTOR: 'Distributore',
			RESELLER: 'Rivenditore',
			INTERNAL_COMPANY: 'Utente',
			USER: 'interno all’azienda',
		},
		subType: {
			RETAIL: 'Vendita al dettaglio',
			ISP: 'ISP',
			MSP: 'MSP',
			NONE: 'Nessuna',
		},
		terms: {
			DR: 'Contratto di adesione dei partecipanti al canale Digital River',
			CLIENT: 'Contratto di partnership',
		},
		payAsYouGoPaymentMethod: {
			LINE_OF_CREDIT: {
				name: 'LOC',
				description:
					'La fattura verrà generata poco dopo la fine del mese. Verranno applicate le condizioni di pagamento standard. Le fatture LOC possono essere pagate solo tramite bonifico bancario. ',
			},
			AUTO_BILLING: {
				name: 'Fatturazione automatica',
				description:
					'L’importo verrà addebitato automaticamente sulla carta salvata poco dopo la fine del mese. Se il tentativo non va a buon fine, verrà generata automaticamente una fattura LOC.',
			},
		},
	},
	order: {
		discountCategory: {
			NONE: 'Nessuna',
			DISCRETIONARY: 'Discrezionale',
		},
		lineItemDiscountType: {
			STANDARD: 'Standard',
			SPECIAL: 'Speciale',
			NONE: 'Nessuna',
		},
		approvalFlag: {
			NA: 'N/D',
			APPROVAL_NOT_REQUIRED: 'Non è richiesta l’approvazione',
			APPROVAL_REQUIRED: 'È richiesta l’approvazione',
			PENDING_APPROVAL: 'In attesa di approvazione',
			APPROVED: 'Approvato',
			REJECTED: 'Rifiutato',
		},
		billablePartyType: {
			PARTNER: 'di dist.',
			DISTRIBUTOR: 'Distributore',
			END_CUSTOMER: 'Cliente finale',
		},
		searchKey: {
			COMMERCE_ORDER_ID: 'ID ordine fatturabile',
			OPPORTUNITY_ID: 'ID opportunità',
			ORDER_NUMBER: 'Numero ordine',
			PO_NUMBER: 'Numero ordine di acquisto',
			QUOTE_NUMBER: 'Numero preventivo',
		},
		status: {
			SAVED_IN_PROGRESS: {
				name: 'Salvato e in corso di elaborazione',
				description:
					'L’ordine è stato salvato ma non inviato. Solo gli ordini in questo stato possono essere modificati.',
			},
			ISSUED_AS_CUSTOMER_QUOTE: {
				name: 'Inviato come preventivo per il cliente',
				description:
					'L’ordine è un preventivo inviato a un cliente. Non può essere modificato dal cliente, può solo essere inviato.',
			},
			ISSUED_AS_QUOTE: {
				name: 'Inviato come preventivo',
				description: "L'ordine è un preventivo di Avast. Non può essere modificato, può solo essere inviato.",
			},
			POSTED_TO_STATEMENT: {
				name: 'Registrato nel rendiconto',
				description: 'L’ordine è stato registrato in un rendiconto mensile.',
			},
			PENDING_SUBMISSION: {
				name: 'In attesa di essere inviato',
				description: '',
			},
			IN_REVIEW: {
				name: 'In corso di revisione',
				description: 'L’ordine è in attesa di revisione. Non può essere modificato.',
			},
			SUBMITTED_TO_COMMERCE: {
				name: 'Inviato come ordine commerciale',
				description: 'L’ordine è stato inviato per la fatturazione.',
			},
			PAYMENT_ERROR: {
				name: 'Errore di pagamento',
				description:
					'Si è verificato un errore con il pagamento. L’ordine deve essere inviato nuovamente una volta verificato che i dati di pagamento siano accurati e aggiornati.',
			},
			OTHER_ERROR: {
				name: 'Altro errore',
				description:
					'Si è verificato un errore di sistema che non è stato risolto dopo 3 tentativi. Provare a inviare di nuovo l’ordine o contattare l’assistenza.',
			},
			WAITING_PAYMENT: {
				name: 'In attesa del pagamento',
				description: 'L’ordine è stato inviato per la fatturazione. Il pagamento è esigibile.',
			},
			COMPLETE: {
				name: 'Completato',
				description: 'Il pagamento è stato riscosso e l’ordine è stato completato.',
			},
			CANCELED_BY_USER: {
				name: 'Annullato dall’utente',
				description: 'Un utente ha deciso di annullare l’ordine.',
			},
			CANCELED_FOR_FAILURE_TO_PAY: {
				name: 'Annullato per mancato pagamento',
				description: 'L’ordine è stato annullato in quanto non è stato pagato per intero.',
			},
			EXPIRED: {
				name: 'Scaduto',
				description: 'L’ordine non è stato modificato per 30 giorni in seguito alla data in cui è stato creato.',
			},
			NOT_SUBMITTED: {
				name: 'Non inviato',
				description: 'L’ordine non è stato inviato per la fatturazione.',
			},
		},
	},
	payment: {
		status: {
			PAID: {
				name: 'Pagato',
				description: 'L’ordine è stato elaborato con successo e il pagamento è stato ricevuto.',
			},
			UNPAID: {
				name: 'Non pagato',
				description: 'L’ordine è in attesa di pagamento o il pagamento non è stato ricevuto.',
			},
			PARTIAL_PAID: {
				name: 'Parzialmente pagato',
				description: 'L’ordine presenta un saldo non ancora corrisposto.',
			},
			NA: {
				name: 'Non fatturato',
				description: 'L’ordine è in attesa di essere fatturato.',
			},
			UNBILLED: {
				name: 'Non fatturato',
				description: 'L’ordine è in attesa di essere fatturato.',
			},
			SETTLED: {
				name: 'Liquidato',
				description: 'L’ordine è stato elaborato e completato dal team addetto agli incassi.',
			},
		},
		type: {
			WIRE_TRANSFER: 'Bonifico bancario',
			WTOC: 'WTOC',
			CART_SELECTED: 'Carrello selezionato',
			CLOSING_BALANCE: 'Saldo di chiusura',
			LOC: 'LOC',
			AUTO_BILLING: 'Fatturazione automatica',
		},
	},
	licenses: {
		searchKey: {
			LICENSE_KEY: 'Chiave di licenza',
			ORDER_NUMBER: 'Numero ordine',
		},
		status: {
			ACTIVE: {
				name: 'Attiva',
				description: 'La licenza è in uso',
			},
			INACTIVE: {
				name: 'Inattiva',
				description: 'Il codice di attivazione non è stato utilizzato',
			},
			CANCELLED: {
				name: 'Annullata',
				description: 'La licenza è stata annullata o rimborsata',
			},
			REPLACED: {
				name: 'Sostituita',
				description:
					'Un’autorizzazione attiva è stata sostituita da un’altra autorizzazione attiva durante l’operazione',
			},
			EXPIRED: {
				name: 'Scaduto',
				description: 'La data di scadenza di un’autorizzazione attiva è passata',
			},
			USED: {
				name: 'Utilizzata',
				description: 'La licenza è stata utilizzata',
			},
			MIGRATED: {
				name: 'Migrata',
				description: 'La licenza è stata migrata a una licenza per la versione aggiornata del prodotto',
			},
			BLACKLISTED: {
				name: 'Inserita nella blacklist',
				description: '',
			},
		},
		datepickerDateType: {
			CREATED_AT: 'Data di creazione',
			EXPIRATION: 'Data di scadenza',
			REGISTERED_AT: 'Data di registrazione',
		},
	},
	closingBalance: {
		searchKey: {
			ORDER_NUMBER: 'Numero ordine',
		},
	},
	creditStatus: {
		transactionSource: {
			CANCELLED_LICENSE: 'Licenza annullata',
			CANCELLED_ORDER: 'Ordine annullato',
			MSP: 'MSP',
			NEW_ORDER: 'Nuovo ordine',
			OMS_REFUND: 'Rimborso',
			PAID_ORDER: 'Ordine pagato',
			SAP: 'SAP',
			SAP_TAX: 'tasse',
			OMS_TAX: 'tasse',
			ORACLE_TAX: 'tasse',
			ADMIN_CUSTOM: 'Regolazione personalizzata',
			PAY_AS_YOU_GO: 'Fatturazione flessibile',
			TAX_REFUND: 'Rimborso fiscale',
			PAY_AS_YOU_GO_CONSOLIDATION: 'Fatturazione flessibile - rettifica',
			SETTLED_ORDER: 'Liquidato',
		},
	},
	purchase: {
		marketSegment: {
			BUSINESS: 'Aziende',
			CONSUMER: 'Privati',
		},
		billablePartyType: {
			PARTNER: 'di dist.',
			CUSTOMER: 'Cliente',
		},
		type: {
			PREPAID: 'Prepagato',
			FLEXIBLE_BILLING: 'Fatturazione flessibile',
		},
	},
	promotion: {
		scope: {
			PARTY_ONLY: 'Solo parte',
			PRODUCT_ONLY: 'Solo prodotto',
			PARTY_AND_PRODUCT: 'Parte e prodotto',
		},
		type: {
			DISCRETIONARY: 'Discrezionale',
			SALES_CAMPAIGN: 'Campagna di vendita',
		},
		partyType: {
			DISTRIBUTION: 'Distributore',
			RESELLER: 'Rivenditore',
			END_CUSTOMER: 'Cliente finale',
		},
		partyTier: {
			DISTRIBUTORS: 'Distributori',
			NONE: 'Nessuna',
			TIER_4: 'Livello 4',
			TIER_3: 'Livello 3',
			TIER_2: 'Livello 2',
			TIER_1: 'Livello 1',
		},
		state: {
			ENABLED: 'Abilitato',
			DISABLED: 'Disabilitato',
		},
	},
	retail: {
		status: {
			COMPLETE: 'Completato',
			IN_PROGRESS: 'In corso',
		},
	},
	refunds: {
		status: {
			OPEN: 'Aperto',
			FULLY_APPLIED: 'Pienamente applicato',
			VOIDED: 'Annullato',
			PENDING: 'In sospeso',
		},
		type: {
			ORDER: 'Rimborso ordine',
			LINE: 'Rimborso articolo',
			KEY: 'Rimborso chiave',
			TAX: 'Rimborso fiscale',
		},
		reason: {
			CUSTOMER_SATISFACTION_ISSUE: 'Problema relativo alla soddisfazione del cliente',
			INCORRECT_PRICE_INVOICED: 'Prezzo fatturato errato',
			NOT_DELIVERED: 'Mancata consegna',
			WRONG_PRODUCT: 'Prodotto sbagliato',
			PRODUCT_NOT_WORKING: 'Prodotto non funzionante',
			TAX_EXEMPT: 'Esenzione fiscale',
			OTHER_REASON: 'Altro motivo',
		},
	},
	comparativeFeature: {
		ONLINE_MANAGEMENT: {
			name: 'Piattaforma di gestione online',
			description:
				'Configura la protezione dei dispositivi, gestisci gli abbonamenti, monitora e aggiungi dispositivi in tutta semplicità da un unico posto, dove e quando vuoi.',
		},
		DEVICE_PROTECTION: {
			name: 'Protezione dei dispositivi',
			description:
				'Un antivirus pluripremiato in grado di proteggere tutti i tuoi dispositivi da malware, phishing, ransomware e altri attacchi informatici avanzati.',
		},
		DATA_PROTECTION: {
			name: 'Protezione dei dati',
			description: 'Protegge dati personali, dati aziendali e dati dei clienti dai furti.',
		},
		IT_SUPPORT: {
			name: 'Assistenza IT',
			description:
				'Tu e i tuoi dipendenti potete contare su un’assistenza rapida e cordiale 24/5 via e-mail, chat o telefono da parte del nostro personale tecnico altamente qualificato.',
		},
		IDENTITY_PROTECTION: {
			name: 'Protezione Identità',
			description:
				'Include la protezione per password e webcam per garantire la sicurezza dell’identità online dei tuoi dipendenti.',
		},
		UNLIMITED_VPN: {
			name: 'VPN illimitata',
			description:
				'La VPN integrata esegue la crittografia dei dati e rende private le connessioni a Internet dei tuoi dipendenti, consentendo loro di navigare in sicurezza sulle reti Wi-Fi pubbliche.',
		},
		USB_PROTECTION: {
			name: 'Protezione USB',
			description:
				'Impedisce l’uso di dispositivi di archiviazione rimovibili non autorizzati da parte dei dipendenti, quali unità flash, unità esterne, schede di memoria, ecc. Blocca, controlla e monitora le porte USB per prevenire il furto e le perdite di dati e le infezioni da malware.',
		},
		PATCH_MANAGEMENT: {
			name: 'Gestione patch',
			description:
				'Corregge automaticamente le vulnerabilità del software e delle applicazioni di terzi suscettibili agli attacchi informatici.',
		},
	},
	productGroupTag: {
		NEW: 'Novità',
	},
	widgets: {
		label: {
			QUOTES: 'Preventivi recenti',
			ORDERS: 'Ordini recenti',
			CREDIT_STATUS: 'Stato del credito',
			EXPIRING_LICENSES_NEXT_N_DAYS: 'Licenze in scadenza nei prossimi N giorni',
			EXPIRED_LICENSES_LAST_N_DAYS: 'Licenze scadute negli ultimi N giorni',
			CLOSING_BALANCE: 'Bilanci di chiusura recenti',
		},
		caption: {
			QUOTES: 'Preventivi recenti',
			ORDERS: 'Ordini recenti',
			CREDIT_STATUS: 'Stato del credito',
			EXPIRING_LICENSES_NEXT_N_DAYS: 'Licenze in scadenza nei prossimi {{count}} giorni',
			EXPIRED_LICENSES_LAST_N_DAYS: 'Licenze scadute negli ultimi {{count}} giorni',
			CLOSING_BALANCE: 'Bilanci di chiusura recenti',
		},
	},
};
