import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { hasLicenseOperations } from 'module/licenses/utils/licenseOperation';
import { ILicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { useAuthContext } from 'js/contexts';

export const useLicenseOperationErrorResolver = () => {
	const { isAuthorizedBuyer } = useAuthContext();
	const [t] = useTranslation(licensesConfig.trNamespace);

	return useCallback(
		(context: ILicenseOperationContext): ILicenseOperationContext['licenseOperationLoadError'] => {
			const {
				license,
				customer,
				partner,
				distributionPartner,
				hasCustomer,
				hasPartner,
				hasDistributionPartner,
				billableParty,
				licenseProductOperations,
				priceListCode,
			} = context;

			if (!isAuthorizedBuyer || !priceListCode) {
				return t('error:permissionDenied');
			}

			if (!hasLicenseOperations(license)) {
				return t('error.disallowed');
			}

			if (licenseProductOperations.length === 0) {
				return t('error.operation.product.noOperationsAvailable');
			}

			if (!partner && hasPartner) {
				return t('error.operation.partner.unableToLoad');
			}

			if (!customer && hasCustomer) {
				return t('error.operation.customer.unableToLoad');
			}

			if (!distributionPartner && hasDistributionPartner) {
				return t('error.operation.distributionPartner.unableToLoad');
			}

			if (!hasPartner && !hasCustomer && !billableParty) {
				return t('error.operation.billablePartyRequired');
			}

			return context.licenseOperationLoadError;
		},
		[isAuthorizedBuyer, t],
	);
};
