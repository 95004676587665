import { useReducer } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { reducer } from 'submodule/widgets/widgetReducer';
import { TWidgetActionPayload, TWidgetGrid } from 'submodule/widgets';
import { isEditWidgetAction } from 'submodule/widgets/utils';

type TUseWidgetReducerProps = {
	config: TWidgetGrid;
};

export const useWidgetReducer = (props: TUseWidgetReducerProps) => {
	const { config } = props;
	const [state, dispatch] = useReducer(reducer, config);

	const onDragEnd = (dropResult: DropResult) => {
		const { source, destination, type } = dropResult;

		// dropped outside the list
		if (!destination) {
			return;
		}

		const isInSameColumn = source.droppableId === destination.droppableId;

		if (type === 'COLUMN') {
			return isInSameColumn
				? dispatch({ type: 'REORDER_ITEMS_IN_COLUMN', payload: { source, destination } })
				: dispatch({ type: 'MOVE_ITEM_TO_ANOTHER_COLUMN', payload: { source, destination } });
		}

		throw new Error('Unknown droppable type');
	};

	const onWidgetModalSubmit = (widgetActionData: TWidgetActionPayload) => {
		if (isEditWidgetAction(widgetActionData)) {
			return dispatch({ type: 'EDIT_WIDGET', payload: widgetActionData });
		}
		return dispatch({ type: 'ADD_WIDGET', payload: widgetActionData });
	};

	return { state, dispatch, onDragEnd, onWidgetModalSubmit };
};
