/* eslint-disable react-hooks/exhaustive-deps */
import type { InvalidateQueryFilters } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export type TUseInvalidateQueriesKeys = string[] | true;

export const useInvalidateQueries = (keys?: TUseInvalidateQueriesKeys) => {
	const queryClient = useQueryClient();
	return useCallback(() => {
		if (keys === true) {
			queryClient.invalidateQueries();
			return;
		}

		keys?.forEach((key) => queryClient.invalidateQueries({ queryKey: [key] }));
	}, [queryClient]);
};

export const useAsyncInvalidateQueries = (keys?: TUseInvalidateQueriesKeys) => {
	const queryClient = useQueryClient();
	return useCallback(
		async (filters?: InvalidateQueryFilters) => {
			if (keys === true) {
				await queryClient.invalidateQueries(filters);
				return;
			}

			const promises: Promise<void>[] = [];
			keys?.forEach((key) => {
				promises.push(
					queryClient.invalidateQueries({
						queryKey: [key],
						...filters,
					}),
				);
			});
			await Promise.all(promises);
		},
		[queryClient],
	);
};
