import { ChangeEvent } from 'react';
import { IDigitalRiverElement, IDigitalRiverElementOptions } from 'types/digitalRiver';

type TDRElementChangeEvent = ChangeEvent & { error?: { message?: string } };

export const mountDRElement = (element: IDigitalRiverElement, targetId: string, messageId?: string): void => {
	// If element has no parent node -> it is not mounted
	if (!element.parentNode) {
		element.mount(targetId);
		if (messageId) {
			onChangeEvent(element, messageId);
		}
	}
};

export const unmountDRElement = (element: IDigitalRiverElement): void => {
	// If element has no parent node -> it is not mounted
	if (!element.parentNode) {
		element.unmount();
	}
};

const onChangeEvent = (element: IDigitalRiverElement, id: string): void => {
	element.on('change', (e: TDRElementChangeEvent) => {
		const container = document.getElementById(id);
		if (container) {
			const text = container.getElementsByClassName('message-content')[0];
			if (e.error?.message) {
				container.classList.remove('d-none');
				text!.textContent = e.error?.message;
			} else {
				container.classList.add('d-none');
				text!.textContent = '';
			}
		}
	});
};

export const drElementOptions: IDigitalRiverElementOptions = {
	classes: {
		base: 'form-control',
		complete: 'is-valid',
		invalid: 'is-invalid',
	},
	style: {
		base: {
			color: '#0c053e',
			fontSize: '15px',
			fontFamily: 'Helvetica Neue, Arial, sans-serif',
		},
		empty: {
			color: '#9e9bb2',
		},
		invalid: {
			color: '#cf3968',
		},
	},
};
