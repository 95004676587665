import React from 'react';
import { IApiFlexibleBillingDetailFilter, TFlexibleBillingForm } from 'module/flexibleBilling';
import { logError } from 'js/utils/app';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { useApiErrorContext, useAppContext } from 'js/contexts';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { API_FLEXIBLE_BILLING_KEYS, useApiFlexibleBillingUpdate } from 'module/flexibleBilling/hooks';
import { flexibleBillingFormNormalizer } from 'module/flexibleBilling/normalizer';
import { useInvalidateQueries } from 'js/hooks/useInvalidateQueries';
import { submitFlexibleBillingSettingApiError } from 'module/flexibleBilling/utils';

export const useFlexibleBillingSubmitHandler = (filter: IApiFlexibleBillingDetailFilter) => {
	const [t] = useTranslation(flexibleBillingConfig.trNamespace);
	const { setError } = useApiErrorContext();
	const { confirmationModalRef, loadingModalRef } = useAppContext();
	const { mutateAsync: sendEditedValues } = useApiFlexibleBillingUpdate({
		config: {
			params: filter,
		},
	});
	const invalidateQueries = useInvalidateQueries(Object.values(API_FLEXIBLE_BILLING_KEYS));

	return async (values: TFlexibleBillingForm) => {
		const confirm = await confirmationModalRef.current?.show({
			title: t('modal.confirmation'),
			messages: [t('modal.message')],
			submitButtonText: t('common:actions.confirm'),
		});

		if (!confirm) {
			return false;
		}

		loadingModalRef.current?.show({
			title: t('modal.apply'),
			ellipsis: true,
		});

		const response = await sendEditedValues(flexibleBillingFormNormalizer.denormalize(values)).catch((error) => {
			logError('Applying changes failed', error);
			setError({ error, resolve: submitFlexibleBillingSettingApiError });
		});

		if (response) {
			invalidateQueries();
			toast.success(<Toast>{t('modal.success')}</Toast>);
		}

		loadingModalRef.current?.hide();
		return Boolean(response);
	};
};
