export default {
	appName: 'Sistema di gestione degli ordini',
	menu: {
		closingBalance: 'Saldo di chiusura',
		creditLimit: 'Limite di credito',
		creditStatus: 'Stato del credito',
		customers: 'Clienti',
		dashboard: 'Home',
		distributionPartners: 'Partner di distribuzione',
		licenses: 'Licenze',
		orders: 'Ordini',
		quotes: 'Preventivi',
		purchase: 'Nuovo acquisto',
		promotions: 'Promozioni',
		account: 'Il mio account',
		help: 'Guida e risorse',
		feedback: 'Condividi feedback',
		retail: 'Codici di vendita al dettaglio',
		priceCalculator: 'Calcolatore prezzi',
		flexibleBilling: 'Fatturazione flessibile',
	},
	help: {
		main: {
			title: 'Guida e risorse',
			guide: {
				title: 'Ecco le novità del nostro OMS.',
				text: 'Scopri le novità più importanti.',
				action: 'Inizia il tour',
			},
			videos: {
				title: 'Dai un’occhiata ai video esplicativi',
			},
		},
		guide: {
			paginator: '{{current}} di {{total}}',
			slides: {
				welcome: {
					title: 'Ti diamo il benvenuto nel nuovo OMS',
					text: [
						'Il sistema di gestione degli ordini ha un nuovo look! Scopri il nuovo design, l’interfaccia intuitiva, la navigazione migliorata e le prestazioni ottimizzate per l’esperienza d’acquisto migliore di sempre. Dai un’occhiata ai widget della home page, che consentono di accedere rapidamente a ordini recenti, preventivi, licenze in scadenza e tanto altro.',
					],
				},
				pricing: {
					title: 'Prezzi dinamici',
					text: [
						'Non dovrai più attendere che il prezzo dell’ordine venga aggiornato, né aggiungere i prodotti al carrello per visualizzarne i prezzi. Ora i prezzi vengono mostrati direttamente nel catalogo dei prodotti.',
						'Quando modifichi il conteggio delle postazioni o la quantità di un prodotto, i prezzi vengono ricalcolati automaticamente, in modo che siano sempre aggiornati. Anche il valore dell’ordine viene ricalcolato automaticamente quando aggiungi o rimuovi prodotti dal carrello.',
					],
				},
				quickFilter: {
					title: 'Filtra e cerca in tutta semplicità prodotti per privati e per aziende',
					text: [
						'Abbiamo aggiunto un filtro rapido per consentirti di visualizzare solo i prodotti per i privati o solo i prodotti per le aziende. Per cercare un prodotto specifico, inizia a digitare il nome del prodotto o il codice del prodotto e l’elenco si aggiornerà automaticamente.',
					],
				},
				licenseOperations: {
					title: 'Rinnovi più facili',
					text: [
						'Abbiamo separato i nuovi acquisti dalle operazioni relative alle licenze (quali rinnovo o aggiornamento) per consentirti di vedere facilmente le operazioni sulle licenze quando visualizzi le licenze. Clicca sul pulsante Operazioni sulle licenze disponibile nell’elenco delle licenze o nella pagina dei dettagli delle licenze.',
					],
				},
				shoppingCart: {
					title: 'Accesso semplificato al carrello',
					text: [
						'Abbiamo aggiunto l’icona del carrello degli acquisti per consentirti di accedere facilmente al carrello e procedere all’ordine quando vuoi.',
						'Puoi combinare i nuovi acquisti con le operazioni sulle licenze (come il rinnovo) in un unico ordine in tutta semplicità.',
					],
				},
				quickAccess: {
					title: 'Accesso rapido a tutti i record di cui hai bisogno',
					text: [
						'Abbiamo aggiunto pulsanti e collegamenti di accesso rapido in diversi punti dell’OMS per semplificare l’accesso alle informazioni correlate al record visualizzato. Utilizza i pulsanti “Visualizza licenze” e “Visualizza ordini” nella pagina dei dettagli del cliente.',
						'Nella pagina dei dettagli delle licenze, apri un ordine correlato o visualizza i dettagli del cliente cliccando sui collegamenti in evidenza.',
					],
				},
				quotes: {
					title: 'Preventivi facilmente accessibili',
					text: [
						'Abbiamo creato una voce di menu separata per i preventivi che ricevi dall’account manager, in modo da semplificarne l’individuazione. Per un facile accesso, tali preventivi sono disponibili anche tra gli Ordini.',
					],
				},
				finalStep: {
					title: 'Questo è tutto!',
					text: [
						'Ci auguriamo che la nuova interfaccia utente, aggiornata con nuove funzionalità e prestazioni ottimizzate, possa rendere più piacevole la tua esperienza d’uso dell’OMS.',
						'Condividi i tuoi feedback e le migliorie suggerite cliccando sulla voce di menu “Condividi feedback”.',
						'Puoi consultare questa guida introduttiva in qualsiasi momento cliccando su “Guida e risorse” nel menu.',
					],
				},
			},
		},
		videos: {
			title: 'Video esplicativi',
			list: {
				navigations: 'Utilizzo dell’OMS',
				newPurchase: 'Creazione di un nuovo ordine d’acquisto',
				renewal: 'Creazione di un ordine di rinnovo',
				quotes: 'Ricezione di un preventivo dall’account manager',
			},
		},
	},
};
