export default {
	tierQualifyingUnits: 'Unités éligibles au niveau',
	tier: 'Niveau',
	srpUnitPrice: 'Prix unitaire conseillé',
	customerSegment: 'Segment de clientèle',
	segmentDiscount: 'Remise sectorielle',
	srpAfterSegmentDiscount: 'Prix conseillé après remise sectorielle',
	quantityPriced: 'Quantité',
	totalPrice: 'Prix total',
	discretionaryDiscount: 'Remise discrétionnaire',
	unitPriceAfterDiscretionaryDiscount: 'Prix unitaire après remise discrétionnaire',
	partnerDiscount: 'Remise aux partenaires',
	unitPriceAfterDiscount: 'Prix unitaire après remise',
	finalDiscountedUnitPrice: 'Prix total après remise',
	upgradeCredit: 'Mettre à niveau le crédit',
	daysInTerm: 'Jours du trimestre',
	daysToProrate: 'Jours à calculer au prorata',
	prorationRate: 'Taux de prorata',
	proratedUnitPrice: 'Prix unitaire calculé au prorata',
	proratedSrpUnitPrice: 'Prix unitaire conseillé calculé au prorata',
	daysInPeriod: 'Nombre de jours de la période',
	dailySaleUnitPrice: 'Prix de vente unitaire quotidien',
	customPricing: 'Tarification personnalisée',
	quantityBasedPromotions: 'Promotions basées sur la quantité',
	linePriceUnitPriceAfterQuantityBasedPromotions:
		'Prix d’un article, prix unitaire après les promotions basées sur la quantité',
	linePrice: 'Prix d’un article',
	unitPriceAfterQuantityBasedPromotions: 'Prix unitaire après les promotions basées sur la quantité',
	percentageOffPromotions: 'Promotions avec pourcentage de remise',
	unitPriceAfterPercentageOffPromotions: 'Prix unitaire après les promotions avec pourcentage de remise',
	validFrom: 'Valable à partir du',
	validTo: 'Valable jusqu’au',
	offQtyOf: 'Remise sur une quantité de',
	amountOff: 'Montant de la remise',
	discountPerUnit: 'Remise par unité',
	discount: 'Remise',
};
