import { generateEnumUtils } from 'js/enums/generator/enumFunctions';
import { logDebug } from 'js/utils/app';

export enum CreationSourceEnum {
	AVAST_BUSINESS_HUB = 'AvastBusinessHub',
	DISTRIBUTOR_API = 'DistributorAPI',
	ISPAPI = 'ISPAPI',
	OMS = 'OMS',
	SALESFORCE = 'Salesforce',
	SMART_CHANNEL = 'SmartChannel',
}

export const creationSourceEnumUtils = generateEnumUtils<CreationSourceEnum>(CreationSourceEnum, {
	aliases: { [CreationSourceEnum.SMART_CHANNEL]: CreationSourceEnum.OMS },
	getText(value): string {
		switch (value) {
			case CreationSourceEnum.AVAST_BUSINESS_HUB:
				return 'Avast Business Hub';
			case CreationSourceEnum.DISTRIBUTOR_API:
				return 'Distributor API';
			case CreationSourceEnum.ISPAPI:
				return 'ISP API';
			case CreationSourceEnum.OMS:
			case CreationSourceEnum.SMART_CHANNEL:
				return 'OMS';
			case CreationSourceEnum.SALESFORCE:
				return value;
			default:
				logDebug('Enum has unexpected value', value);
				return value || '';
		}
	},
});
