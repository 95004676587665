import type { AxiosResponse } from 'axios';
import { AxiosError } from 'axios';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { merge } from 'lodash';
import { IApiSortBy, TApiListResponse } from 'types/api';
import { useApiList } from 'js/hooks/api';
import { TQueryConfig } from 'js/queries';
import { TUseApiListProps, TUseApiListQueryKey } from 'js/hooks/api/useApiList';
import { useMemo } from 'react';

/**
 * Response from api call
 */
type TUseApiListResponse<Data extends {}, Error = AxiosError<TApiListResponse<Data>>> = {
	data: TApiListResponse<Data>;
	query: UseQueryResult<AxiosResponse<TApiListResponse<Data>>, Error>;
};

export type TUseApiListQueryOptions<
	Data extends {},
	Filter extends {} = {},
	Error = AxiosError<TApiListResponse<Data>>,
> = UseQueryOptions<
	AxiosResponse<TApiListResponse<Data>>,
	Error,
	AxiosResponse<TApiListResponse<Data>>,
	TUseApiListQueryKey<Data, Filter>
>;

/**
 * Props of module api query function
 */
type TUseApiListQueryProps<
	Data extends {},
	Filter extends {} = {},
	ApiFilter = Filter,
	Error = AxiosError<TApiListResponse<Data>>,
> = {
	filter?: Filter;
	sortBy?: IApiSortBy<Data>;
	config?: TQueryConfig;
	queryConfig?: TUseApiListQueryOptions<Data, Filter, Error>;
} & Pick<TUseApiListProps<Filter, ApiFilter>, 'apiFilterNormalizer'>;

/**
 * Module api query function type
 */
export type TUseApiListModule<
	Data extends {},
	Filter extends {} = {},
	ApiFilter = Filter,
	Error = AxiosError<TApiListResponse<Data>>,
> = (props?: TUseApiListQueryProps<Data, Filter, ApiFilter, Error>) => TUseApiListResponse<Data, Error>;

/**
 * Query hook for get non-paginated data
 * @param {string} key
 * @param {TUseApiListQueryProps} props
 * @param {TUseApiListQueryProps} defaults
 * @returns {TUseApiListResponse}
 */
export const useApiListQuery = <
	Data extends {},
	Filter extends {} = {},
	ApiFilter extends {} = Filter,
	Error = AxiosError<TApiListResponse<Data>>,
>(
	key: string,
	props: TUseApiListQueryProps<Data, Filter, ApiFilter, Error> = {},
	defaults?: TUseApiListQueryProps<Data, Filter, ApiFilter, Error>,
): TUseApiListResponse<Data, Error> => {
	const { filter, sortBy, config, queryConfig, apiFilterNormalizer } = merge(defaults, props);
	const defaultValue = useMemo(() => [], []);

	const queryFn = useApiList<Data, Filter, ApiFilter>({
		config: {
			...config,
		},
		apiFilterNormalizer,
	});

	const response = useQuery([key, filter as Filter, sortBy], queryFn, {
		refetchOnWindowFocus: false,
		...queryConfig,
	});

	return { query: response, data: response.data?.data ?? defaultValue };
};
