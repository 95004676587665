import React, { FunctionComponent, PropsWithChildren } from 'react';
import { retailConfig } from 'module/retail/retailConfig';
import { useTranslation } from 'react-i18next';
import { Nav } from 'react-bootstrap';
import { NavLink, Outlet } from 'react-router-dom';
import { RouteName } from 'module/RouteName';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';

export const RetailListLayout: FunctionComponent = (props: PropsWithChildren<{}>) => {
	const [t] = useTranslation(retailConfig.trNamespace);
	return (
		<>
			<Nav
				variant="tabs"
				as="ul"
			>
				<Nav.Item as="li">
					<Nav.Link
						as={NavLink}
						end
						to={RouteName.RETAIL.LIST}
					>
						{t('page.list')}
					</Nav.Link>
				</Nav.Item>
				<Nav.Item as="li">
					<Nav.Link
						as={NavLink}
						end
						to={RouteName.RETAIL.LICENSES}
					>
						{t('page.licenses')}
					</Nav.Link>
				</Nav.Item>
			</Nav>

			<DefaultContainer>
				<Outlet />
			</DefaultContainer>
		</>
	);
};
