import { TableColumnsDef } from 'types';
import { IEntityClosingBalance } from 'module/closingBalance';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { noValuePlaceholder } from 'js/utils/app';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { paymentStatusEnumUtils } from 'js/enums';

type TTableData = IEntityClosingBalance;
export const useClosingBalanceListColumns = (): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(closingBalanceConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();

		return [
			columnHelper.link(
				'id',
				{
					header: t('common:entity.detailId'),
				},
				{
					to: (closingBalance) => closingBalanceConfig.detailLink(closingBalance),
					copy: true,
				},
			),
			columnHelper.ellipsis('billableParty.name', { header: t('common:entity.company') }),
			columnHelper.date('periodEndDate', { header: t('entity.period') }, 'MONTH_YEAR'),
			columnHelper.money(
				'totalAmountWithoutTax',
				{
					header: t('entity.amountWOTax'),
					meta: { defaultValue: { value: noValuePlaceholder(t('common:_.NA')) } },
				},
				{ currencyKey: 'currency' },
			),
			columnHelper.money(
				'totalAmountWithTax',
				{
					header: t('entity.amountWTax'),
					meta: { defaultValue: { value: noValuePlaceholder(t('common:_.NA')) } },
				},
				{ currencyKey: 'currency' },
			),
			columnHelper.date('payment.dueDate', {
				header: t('common:dates.paymentDue'),
				meta: { defaultValue: { value: noValuePlaceholder(t('common:_.NA')) } },
			}),
			columnHelper.text('payment.status', {
				header: t('common:entity.paymentStatus'),
				meta: { formatters: [paymentStatusEnumUtils.getText] },
			}),
			columnHelper.actions({
				link: {
					children: t('common:actions.detail'),
					to: closingBalanceConfig.detailLink,
					testId: 'detail',
				},
			}),
		];
	}, [t]);
};
