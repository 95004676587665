import React, { FunctionComponent } from 'react';
import { ModuleContainer } from 'js/layouts/container/ModuleContainer';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { LicenseRoutes } from 'module/licenses/LicenseRoutes';

export const LicensesContainer: FunctionComponent = () => (
	<DefaultContainer>
		<ModuleContainer moduleMenuConfig={licensesConfig} />
		<LicenseRoutes />
	</DefaultContainer>
);
