import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum LineItemDiscountTypeEnum {
	STANDARD = 'Standard',
	SPECIAL = 'Special',
	NONE = 'None',
}

export const lineItemDiscountTypeEnumUtils = generateEnumUtils<LineItemDiscountTypeEnum>(LineItemDiscountTypeEnum, {
	translatePath: 'enums:order.lineItemDiscountType',
});
