export default {
	error: {
		creditStatusNotFound: 'Credit status not found!',
	},
	tabs: {
		detail: 'Credit Summary',
		transactions: 'List of Transactions',
	},
	entity: {
		companyName: 'Company',
		countryCode: 'Country',
		creditAvailable: 'Credit Available',
		creditLimit: 'Credit Limit',
		percentConsumed: '% Consumed',
		unbilledBalance: 'Unbilled Balance',
		unpaidBalance: 'Unpaid Balance',
	},
	entityShort: {
		creditAvailable: 'Available',
		creditLimit: 'Limit',
		percentConsumed: '% Consumed',
		unbilledBalance: 'Unbilled',
		unpaidBalance: 'Unpaid',
	},
	entityTransaction: {
		amount: 'Amount',
		createdAt: 'Created at',
		orderId: 'Reference ID',
		source: 'Source',
	},
	widget: {
		name: 'Type',
		value: 'Credit',
	},
};
