import { generateEnumUtilsWithDescription } from 'js/enums/generator/enumFunctions';

export enum ComparativeFeatureEnum {
	ONLINE_MANAGEMENT = 'ONLINE_MANAGEMENT',
	DEVICE_PROTECTION = 'DEVICE_PROTECTION',
	DATA_PROTECTION = 'DATA_PROTECTION',
	IT_SUPPORT = 'IT_SUPPORT',
	IDENTITY_PROTECTION = 'IDENTITY_PROTECTION',
	UNLIMITED_VPN = 'UNLIMITED_VPN',
	USB_PROTECTION = 'USB_PROTECTION',
	PATCH_MANAGEMENT = 'PATCH_MANAGEMENT',
}

export const comparativeFeatureEnumUtils = generateEnumUtilsWithDescription<ComparativeFeatureEnum>(
	ComparativeFeatureEnum,
	{ translatePath: 'enums:comparativeFeature' },
);
