import React from 'react';
import { ButtonComposition } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { LinkButton } from 'js/components/atoms/Button';
import { Can } from 'js/components/molecules/Can';
import { TDistributionPartnerSalesforceId } from 'types';

type TFlexibleBillingDetailActionsProps = {
	partnerId: TDistributionPartnerSalesforceId;
};

export const FlexibleBillingDetailActions: React.FC<TFlexibleBillingDetailActionsProps> = (props) => {
	const [t] = useTranslation(flexibleBillingConfig.trNamespace);

	return (
		<ButtonComposition testId="flexibleBillingActions">
			<Can
				do={flexibleBillingConfig.aclModule}
				update
			>
				<LinkButton
					to={flexibleBillingConfig.updateLinkById(props.partnerId)}
					testId="edit"
					size="sm"
				>
					{t('actions.edit')}
				</LinkButton>
			</Can>
		</ButtonComposition>
	);
};
