import { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/sk-SK');
const locale: TLocale = 'sk-SK';

export const sk_SK: ILocaleConfig = {
	name: 'Slovak (Slovakia)',
	locale,
	language: 'sk',
	languageFallback: ['cs'],
	default: true,
	format: {
		numbro,
		datetime: locale,
	},
};
