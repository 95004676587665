/* eslint-disable no-template-curly-in-string */
export default {
	maxFileSize: 'Max. Dateigröße: {{maxSize}} MB.',
	acceptFileType: 'Unterstützter Dateityp: {{types}}.',
	acceptFileType_other: 'Unterstützte Dateitypen: {{types}}.',
	actions: {
		reset: 'Formular zurücksetzen',
	},
	error: {
		file: {
			type: 'Die ausgewählte Datei hat einen nicht unterstützten Typ.',
			maxSize: 'Die ausgewählte Datei ist zu groß – {{size}} MB (max. {{maxSize}} MB).',
		},
	},
	validator: {
		array: {
			max: 'Feld ${path} muss kleiner als oder gleich ${max} Elemente enthalten.',
			min: 'Feld ${path} muss mindesten ${min} Elemente enthalten.',
		},
		date: {
			max: 'Feld ${path} muss früher sein als ${max}.',
			min: 'Feld ${path} muss später sein als ${min}.',
		},
		mixed: {
			default: '${path} ist ungültig.',
			notOneOf: '${path} darf keiner der folgenden Werte sein: ${values}.',
			oneOf: '${path} muss einer der folgenden Werte sein: ${values}.',
			required: '${path} ist ein Pflichtfeld.',
		},
		number: {
			integer: '${path} muss eine Ganzzahl sein.',
			lessThan: '${path} muss kleiner als ${less} sein.',
			max: '${path} muss kleiner oder gleich ${max} sein.',
			min: '${path} muss größer oder gleich ${min} sein.',
			moreThan: '${path} muss größer als ${more} sein.',
			negative: '${path} muss eine negative Zahl sein.',
			positive: '${path} muss eine positive Zahl sein.',
		},
		string: {
			email: '${path} muss eine gültige E-Mail-Adresse sein.',
			length: '${path} muss genau ${length} Zeichen lang sein.',
			link: '${path} muss eine gültige URL sein.',
			lowercase: '${path} muss eine Zeichenkette aus Kleinbuchstaben sein.',
			matches: '${path} muss übereinstimmen mit: "${regex}".',
			max: '${path} darf höchstens ${max} Zeichen lang sein.',
			min: '${path} muss mindestens ${min} Zeichen lang sein.',
			trim: '${path} muss eine gekürzte Zeichenkette sein.',
			uppercase: '${path} muss eine Zeichenkette aus Großbuchstaben sein.',
			url: '${path} muss eine gültige URL sein.',
			uuid: '${path} muss eine gültige UUID sein.',
		},
	},
};
