export default {
	table: {
		noData: 'Ancora nessun dato disponibile',
		error: 'Si è verificato un errore durante il recupero dei dati',
		noDataFilter: 'Nessun dato per il filtro',
		retry: 'Invia nuovamente la richiesta',
		perPage: 'per pagina',
		pagination: {
			total: 'Da <strong>{{from}} a <0>{{to}}</0></strong> record su <0>{{total}}</0> visualizzati.',
		},
	},
	datepicker: {
		goToToday: 'Vai a Questo mese',
		rangeDivider: 'a',
		startDate: 'Data iniziale',
		endDate: 'Data finale',
		rangeSelector: {
			today: 'Oggi',
			thisWeek: 'Questa settimana',
			thisMonth: 'Questo mese',
			lastWeek: 'Ultima settimana',
			lastMonth: 'Ultimo mese',
			thisYear: 'Questo mese',
			lastYear: 'Ultimo anno',
			customRange: 'Intervallo personalizzato',
		},
		relative: {
			single: {
				today: 'Oggi',
				tomorrow: 'Domani',
				yesterday: 'Ieri',
			},
			range: {
				thisWeek: 'Questa settimana',
				lastWeek: 'Ultima settimana',
				thisMonth: 'Questo mese',
				lastMonth: 'Ultimo mese',
				thisYear: 'Questo mese',
				lastYear: 'Ultimo anno',
			},
		},
	},
	searchComboBox: {
		value: {
			placeholder: 'Inserisci un valore...',
		},
	},
	notes: {
		noContent: 'Nessuna nota disponibile',
	},
	hgop: {
		shoppingCart: {
			title: 'Carrello',
		},
	},
	chargeOrderDocuments: {
		entity: {
			documentType: 'Tipo di documento',
		},
		type: {
			payment_instructions: 'Istruzioni di pagamento',
			invoice: 'Fattura',
			vat_invoice: 'Fattura',
			credit_memo: 'Promemoria sul credito',
			payg_price_breakdown_msp: 'Fatturazione flessibile - Report partner',
			payg_product_usage_report: 'Fatturazione flessibile - Report cliente',
			payg_reports_all: 'Fatturazione flessibile - Report sull’utilizzo dei prodotti (tutti)',
		},
	},
	export: {
		caption: 'Esportazione in corso, l’operazione potrebbe richiedere alcuni minuti.',
		autoDownload: 'Il file verrà scaricato automaticamente.',
		downloadError: 'Impossibile scaricare il file.',
		error: 'Esportazione non riuscita. Riprova più tardi.',
	},
	common: {
		more: 'Altro',
		apply: 'Applica',
	},
	select: {
		placeholder: 'Seleziona opzione',
	},
	pagination: {
		prev: 'Precedente',
		next: 'Avanti',
		page: 'Pagina',
		of: 'di',
		miniText: 'Pagina {{active}} di {{total}}',
	},
	modal: {
		cancel: 'Annulla',
		submit: 'Invia',
		apply: 'Applica',
	},
	rangeDatepicker: {
		placeholder: 'Seleziona data',
	},
	singleDatepicker: {
		placeholder: 'Seleziona data',
	},
	toast: {
		title: {
			success: 'Operazione riuscita',
			error: 'Errore',
			info: 'Informazioni',
			default: 'Informazioni',
			dark: 'Informazioni',
			warning: 'Avviso',
		},
	},
	fileUpload: {
		placeholder: '<strong>Seleziona</strong> o trascina un file qui',
	},
};
