import { IStandardOrderInstanceCustomPrice } from 'module/purchase';
import { TEditPricesForm } from 'module/purchase/components/editPrices/AsyncEditPricesModal';
import { getOrderInstanceItemQuantity } from 'module/purchase/utils/common';
import { useOrderContext } from 'js/contexts';
import { isNumber } from 'lodash';

type TUseEditPrices = {
	getItemUnitPrice(item: IStandardOrderInstanceCustomPrice): number | null;
	getItemPrice(item: IStandardOrderInstanceCustomPrice): number | null;
	getTotalPrice(values: TEditPricesForm): number;
	hasEditedPrices(values: TEditPricesForm): boolean;
};

export const useEditPrices = (): TUseEditPrices => {
	const { getItemPricing, getOrderItem } = useOrderContext();

	/** @inheritDoc */
	const getItemUnitPrice: TUseEditPrices['getItemUnitPrice'] = (item) => {
		if (isNumber(item.unitPrice)) {
			return item.unitPrice;
		}

		return getItemPricing(item.id)?.unitPrice || null;
	};

	/** @inheritDoc */
	const getItemPrice: TUseEditPrices['getItemPrice'] = (item) => {
		const unitPrice = getItemUnitPrice(item);
		const orderItem = getOrderItem(item.id);

		if (unitPrice && orderItem) {
			const qty = getOrderInstanceItemQuantity(orderItem);
			return unitPrice * qty;
		}

		return null;
	};

	/** @inheritDoc */
	const getTotalPrice: TUseEditPrices['getTotalPrice'] = (values) =>
		values.items.reduce<number>((acc, item) => acc + (getItemPrice(item) || 0), 0);

	/** @inheritDoc */
	const hasEditedPrices: TUseEditPrices['hasEditedPrices'] = (values) =>
		values.items.some((item) => isNumber(item.unitPrice));

	return {
		getItemUnitPrice,
		getItemPrice,
		getTotalPrice,
		hasEditedPrices,
	};
};
