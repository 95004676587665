import React from 'react';
import { Alert, H2 } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { registrationConfig } from 'module/registration/registrationConfig';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { Accordion } from 'react-bootstrap';
import { TermsCard } from 'module/registration/components/TermsCard';
import { logError } from 'js/utils/app';
import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { FormikForm } from 'js/components/formik/FormikForm';
import { SubmitButton } from 'js/components/atoms/Button';
import { useApiPartnerTermsList } from 'module/partners/hooks/useApiPartners';
import { TPartnerId } from 'types';

type TValues = {};
type TTermsFormProps = {
	onSubmit: FormikConfig<TValues>['onSubmit'];
	partnerId: TPartnerId | null;
};

export const TermsForm = (props: TTermsFormProps) => {
	const { onSubmit, partnerId } = props;
	const [t] = useTranslation(registrationConfig.trNamespace);
	const {
		data,
		query: { isLoading, isError },
	} = useApiPartnerTermsList({
		filter: { partnerId },
		queryConfig: {
			onError(error) {
				logError(`No terms could be loaded. Partner id is ${partnerId}`, error);
			},
		},
	});

	if (isError || (!isLoading && !data)) {
		return (
			<Alert
				variant="warning"
				caption={t('error.unableToLoadTerms')}
			/>
		);
	}

	return (
		<Formik<TValues>
			initialValues={{}}
			onSubmit={onSubmit}
		>
			<FormikForm testId="partnerTermsForm">
				<H2
					className="text-center"
					bold
				>
					{t('page.terms.title')}
				</H2>
				{isLoading && <LoadingPlaceholder minHeight={300} />}
				{Boolean(data?.length) && (
					<>
						<Accordion
							className="my-8"
							defaultActiveKey={data[0].name}
						>
							{data.map((terms) => (
								<TermsCard
									key={terms.name}
									eventKey={terms.name}
									content={terms.content}
								/>
							))}
						</Accordion>
						<p className="text-center">
							<SubmitButton>{t('page.terms.button')}</SubmitButton>
						</p>
					</>
				)}
			</FormikForm>
		</Formik>
	);
};
