import React from 'react';
import { Table } from 'react-bootstrap';
import { IPriceCalculatorForm } from 'module/priceCalculator';
import Skeleton from 'react-loading-skeleton';
import { getProductValidityLabels } from 'module/purchase/utils/common';
import { useTranslation } from 'react-i18next';
import { priceCalculatorConfig } from 'module/priceCalculator/priceCalculatorConfig';
import { IconButton } from '@avast/react-ui-components';
import { useCalculatorPricing } from 'module/priceCalculator/hooks/useCalculatorPricing';
import { numberFormatter } from 'js/utils/number';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import {
	AsyncGoalSeekedPriceModal,
	TAsyncGoalSeekedPriceModalProps,
} from 'module/priceCalculator/components/AsyncGoalSeekedPriceModal';
import { IGoalSeekedPriceForm } from 'module/priceCalculator/forms/GoalSeekedPriceForm';
import { useFormikContext } from 'formik';
import { IGoalSeekedPrice } from 'module/purchase';
import { AppliedPromotionsText } from 'module/priceCalculator/components/AppliedPromotionsText';
import { useBillablePartyGuard } from 'module/priceCalculator/guards';
import { usePartyPrefixTranslation } from 'module/purchase/hooks/usePartyPrefixTranslation';

export const PriceCalculatorVariantTable = () => {
	const { values, setFieldValue } = useFormikContext<IPriceCalculatorForm>();
	const { productGroup, currencyCode } = values;
	const [t] = useTranslation(priceCalculatorConfig.trNamespace);
	const goalSeekedPriceRef = useAsyncModalRef<TAsyncGoalSeekedPriceModalProps, IGoalSeekedPriceForm>();
	const billablePartyGuard = useBillablePartyGuard();
	const { tPartyPrefix } = usePartyPrefixTranslation('common');

	// Pricing
	const pricing = useCalculatorPricing();

	if (!productGroup) {
		return null;
	}

	return (
		<>
			<Table
				responsive
				hover
				className="component__priceCalculator-table"
			>
				<thead className="thead-dark">
					<tr>
						<th className="ps-3">{productGroup.name}</th>
						{values.productGroup?.marketSegment === 'CONSUMER' && (
							<th className="text-end">{t('common:entity.quantity')}</th>
						)}
						{billablePartyGuard.isPartner && (
							<>
								<th className="text-end">{tPartyPrefix('common:entity.unitPrice', 'customer')}</th>
								<th className="text-end">{tPartyPrefix('common:entity.totalPrice', 'customer')}</th>
							</>
						)}
						<th className="text-end">{t('common:entity.unitPrice')}</th>
						<th className="text-end">{t('common:entity.totalPrice')}</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{productGroup.products.map((product) => {
						const validityLabels = getProductValidityLabels(product);
						const pricingItem = pricing.findByReferenceId(product.sku);
						const promotions = pricing.findItemPromotions(pricingItem);

						return (
							<tr key={product.sku}>
								<td className="ps-3">
									<strong>{validityLabels.label}</strong> - <em className="text-gray">{product.sku}</em>
									<AppliedPromotionsText promotions={promotions} />
								</td>
								{values.productGroup?.marketSegment === 'CONSUMER' && (
									<td
										valign="middle"
										className="text-end text-gray"
									>
										{values.units}
									</td>
								)}
								{billablePartyGuard.isPartner && (
									<>
										<td
											valign="middle"
											className="text-end text-gray"
										>
											{pricing.loading ? (
												<Skeleton width={80} />
											) : (
												numberFormatter.currency(pricingItem?.customerPrice?.unit, currencyCode)
											)}
										</td>
										<td
											valign="middle"
											className="text-end text-gray"
										>
											{pricing.loading ? (
												<Skeleton width={80} />
											) : (
												numberFormatter.currency(pricingItem?.customerPrice?.total, currencyCode)
											)}
										</td>
									</>
								)}
								<td
									valign="middle"
									className="text-end"
								>
									{pricing.loading ? (
										<Skeleton width={80} />
									) : (
										numberFormatter.currency(pricingItem?.unitPrice, currencyCode)
									)}
								</td>
								<td
									valign="middle"
									className="text-end fw-bold"
								>
									{pricing.loading ? (
										<Skeleton width={80} />
									) : (
										numberFormatter.currency(pricingItem?.linePriceWOTax, currencyCode)
									)}
								</td>
								<td
									valign="middle"
									className="text-end pe-3 ps-0"
									width="5%"
								>
									<IconButton
										size="xs"
										variant="outline-primary"
										iconFa={faPencilAlt}
										onClick={async () => {
											const result = await goalSeekedPriceRef.current?.show({
												productGroup,
												product,
												currencyCode,
												initialPrice: pricingItem?.unitPrice || 0,
											});

											if (result) {
												setFieldValue('goalSeekedPrice', {
													price: result.price,
													lineItemReferenceId: product.sku,
												} as IGoalSeekedPrice);
											}
										}}
									/>
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
			<AsyncGoalSeekedPriceModal forwardedRef={goalSeekedPriceRef} />
		</>
	);
};
