import { IEntityProductGroup, IPricingRequestItem, IStandardOrderInstanceItem } from 'module/purchase';
import { IEntityCustomer } from 'module/customers';
import { TSelectVariantForm } from 'module/purchase/components/selectProducts';
import { dateToPricingDate } from 'module/licenses/utils/common';
import {
	isDecreaseOperation,
	isIncreaseOperation,
	isRenewOperation,
	isUpgradeOperation,
} from 'module/licenses/utils/licenseOperation';
import { getOrderInstanceItemQuantity, hasProductGroupProducts } from 'module/purchase/utils/common';
import { LicenseOperationEnum, licenseOperationEnumUtils, TransactionTypeEnum } from 'module/licenses/enums';
import { StandardOrderDiscountCategoryEnum } from 'module/orders/enums';
import { CustomerSegmentEnum } from 'module/purchase/enums';
import { discountTypeEnumUtils } from 'module/customers/enums';

/**
 * Create pricing items list from list of products group - "from" prices
 *
 * @param {IEntityProductGroup[]} productGroups
 * @param {IEntityCustomer | null} customer
 * @returns {IPricingRequestItem[]}
 */
export const productGroupsToPricingItems = (
	productGroups: IEntityProductGroup[],
	customer?: IEntityCustomer | null,
): IPricingRequestItem[] => {
	const customerSegment = resolvePricingCustomerSegment(customer);
	const items: IPricingRequestItem[] = [];
	productGroups.forEach((group) => {
		if (!hasProductGroupProducts(group)) {
			return;
		}

		const product = group.products[0];
		items.push({
			reference_lineitem_id: group.code,
			product: product.sku,
			customerSegment,
			lineTransactionType: TransactionTypeEnum.NEW,
			unitsToPrice: 1,
			unitsForTier: 1,
			customPricingInfo: {
				quantity: 1,
				customerId: customer?.id,
			},
		});
	});
	return items;
};

/**
 * Map form values to pricing items
 *
 * @param {TSelectVariantForm} values
 * @param {IEntityCustomer | null} customer
 * @returns {IPricingRequestItem[]}
 */
export const selectVariantFormToPricingItems = (
	values: TSelectVariantForm,
	customer?: IEntityCustomer | null,
): IPricingRequestItem[] => {
	const customerSegment = resolvePricingCustomerSegment(customer);
	const items: IPricingRequestItem[] = [];
	values.variant.forEach((item) => {
		if (!item.checked) {
			return;
		}

		const quantity = getOrderInstanceItemQuantity(item);
		items.push({
			reference_lineitem_id: item.product.sku,
			product: item.product.sku,
			customerSegment,
			lineTransactionType: TransactionTypeEnum.NEW,
			unitsToPrice: quantity,
			unitsForTier: quantity,
			customPricingInfo: {
				quantity,
				customerId: customer?.id,
			},
		});
	});

	return items;
};

/**
 * Map order context items to pricing items
 *
 * @param {IStandardOrderInstanceItem[]} items
 * @param {IEntityCustomer | null} customer
 * @return {IPricingRequestItem[]}
 */
export const orderInstanceItemsToPricingItems = (
	items: IStandardOrderInstanceItem[],
	customer?: IEntityCustomer | null,
): IPricingRequestItem[] => {
	const customerSegment = resolvePricingCustomerSegment(customer);
	return items.map((item) => {
		const quantity = getOrderInstanceItemQuantity(item);
		const pricingItem: IPricingRequestItem = {
			reference_lineitem_id: String(item.id),
			product: item.product.sku,
			customerSegment,
			lineTransactionType: licenseOperationEnumUtils.getTransactionType(item.licenseOperation),
			unitsToPrice: quantity,
			unitsForTier: quantity,
			discountLinePercent: 0,
			customPricingInfo: {
				quantity,
				customerId: customer?.id,
			},
		};

		if (item.discretionaryDiscount) {
			pricingItem.discountLinePercent = item.discretionaryDiscount;
			pricingItem.discountLineCategory = StandardOrderDiscountCategoryEnum.DISCRETIONARY;
		}

		// Is new return data
		if (item.licenseOperation === LicenseOperationEnum.NEW) {
			return pricingItem;
		}

		// Add base license operation data
		pricingItem.customPricingInfo.entitlementId = item.license?.id;
		pricingItem.licenseExpireDate = dateToPricingDate(item.license?.expireDate);
		pricingItem.licenseCreateDate = dateToPricingDate(item.license?.createDate);
		pricingItem.unitsToIncrease = 0;
		pricingItem.unitsQuantityTotal = quantity;
		if (item.licenseUnit) {
			pricingItem.unitSeats = item.licenseUnit;
		}

		if (isUpgradeOperation(item.licenseOperation)) {
			pricingItem.upgradeFromProductID = item.licenseSku;
		}

		if (isRenewOperation(item.licenseOperation)) {
			pricingItem.renewTo = item.product.sku;
			pricingItem.renewalDate = dateToPricingDate(new Date());
		}

		if (item.product.isBusiness) {
			if (isIncreaseOperation(item.licenseOperation) || isDecreaseOperation(item.licenseOperation)) {
				pricingItem.unitsToIncrease = item.unitToIncrease;
			}

			if (item.licenseOperation === LicenseOperationEnum.INCREASE) {
				const totalUnit = (item.licenseUnit || 0) + (item.unitToIncrease || 0);
				pricingItem.unitsToPrice = totalUnit;
				pricingItem.unitsForTier = totalUnit;
				pricingItem.unitsQuantityTotal = totalUnit;
			}
		}

		return pricingItem;
	});
};

/**
 * Get and convert customer discount type
 *
 * @param {IEntityCustomer | null} customer
 * @returns {CustomerSegmentEnum}
 */
export const resolvePricingCustomerSegment = (customer?: IEntityCustomer | null): CustomerSegmentEnum =>
	discountTypeEnumUtils.toCustomerSegment(customer?.billing?.discountType) || CustomerSegmentEnum.COMMERCIAL;
