import { round } from 'lodash';

/**
 * Converting the file size to MB
 * @param {File} file Size to be converted
 * @returns {number}
 */
export const getFileSizeMB = (file: File): number => {
	return round(file.size / (1024 * 1024), 2);
};

/**
 * Check if the file uploaded is in the accepted mime types
 * @param {File} file The File uploaded
 * @param {string} acceptMimeType Accepted MIME types
 * @returns {boolean}
 */
export const checkMimeType = (file: File, acceptMimeType: string): boolean => {
	const types = acceptMimeType.replaceAll(/\s/g, '').split(',');
	return types.map((type) => type.toLowerCase()).includes(file.type);
};
