import React, { PropsWithChildren } from 'react';
import { useWidgetContext } from 'submodule/widgets/context';
import { TWidgetCoords, TWidgetGridColumn, TWidgetGridItem } from 'submodule/widgets/index';
import { WidgetItemContainer } from 'submodule/widgets/components/WidgetItemContainer';
import { getColKey } from '../utils';

type TWidgetColumn = {
	column: TWidgetGridColumn;
	coords: TWidgetCoords;
};

type TColumnWithItems = {
	column: TWidgetGridColumn;
	coords: TWidgetCoords;
};

const EmptyColumn = ({ children }: PropsWithChildren<{}>) => (
	<div className="pt-1 pb-2 text-center fs-md text-muted">{children}</div>
);

const ColumnWithItems = ({ column, coords }: TColumnWithItems) => (
	<>
		{column.map((item: TWidgetGridItem, itemIndex: number) => (
			<WidgetItemContainer
				key={`${getColKey(coords)}-${itemIndex}`}
				item={item}
				coords={coords}
				itemIndex={itemIndex}
			/>
		))}
	</>
);

export const WidgetColumn = ({ column, coords }: TWidgetColumn) => {
	const { t } = useWidgetContext();

	if (column.length === 0) {
		return <EmptyColumn>{t('widgets.common.columnPlaceholder')}</EmptyColumn>;
	}

	return (
		<ColumnWithItems
			column={column}
			coords={coords}
		/>
	);
};
