export default {
	actions: {
		generate: 'Generate Retail Codes',
		downloadLicenses: 'Download request licenses',
		refreshListItems: 'Refresh items',
	},
	page: {
		list: 'Bulk ID Lookup',
		licenses: 'Retail Code Lookup',
	},
	entity: {
		bulkId: 'Bulk ID',
		transactionDate: 'Requested Date',
	},
	filter: {
		bulkId: {
			placeholder: 'Enter bulk ID and go ...',
		},
	},
	generate: {
		submit: 'Submit Request',
		submitLoading: 'Saving request...',
		success: 'Request successfully created',
		partner: {
			headline: 'Partner',
			select: 'Select partner',
			change: 'Change partner',
			placeholder: 'Select a retail partner',
		},
		cart: {
			headline: 'Cart',
			add: 'Add products',
			removeTooltip: 'Remove this item from the cart',
			placeholder: 'Add products to cart',
		},
		products: {
			headline: 'Select products',
			addAndClose: 'Add and close',
			success: 'Products has been added to the cart.',
		},
	},
};
