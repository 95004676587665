import { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/es-MX');

const locale: TLocale = 'es-MX';

export const es_MX: ILocaleConfig = {
	name: 'Spanish (Mexico)',
	locale,
	language: 'es',
	format: {
		numbro,
		datetime: locale,
	},
};
