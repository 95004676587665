import React, { ReactElement, useMemo } from 'react';
import type { FormikFormProps } from 'formik';
import { Form } from 'formik';
import type { ITestId } from '@avast/react-ui-components';
import { FormContext, generateId, TestIdContextProvider } from '@avast/react-ui-components';

type TFormProps = {
	formId?: string;
} & FormikFormProps &
	ITestId;

/**
 * Form component what wrap children into FormContext
 * @param {string | undefined} formId
 * @param {ReactNode} children
 * @param {string | undefined} testId
 * @param {FormikFormProps} rest
 * @returns {ReactElement}
 */
export const FormikForm = ({ formId, children, testId, ...rest }: TFormProps): ReactElement => {
	const context = useMemo(
		() => ({
			formId: formId || generateId(),
		}),
		[formId],
	);

	return (
		<TestIdContextProvider prefix={testId}>
			<Form {...rest}>
				<FormContext.Provider value={context}>{children}</FormContext.Provider>
			</Form>
		</TestIdContextProvider>
	);
};
