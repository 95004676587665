import { generateEnumUtilsWithDescription } from 'js/enums/generator/enumFunctions';

export enum PayAsYouGoPaymentMethodEnum {
	LINE_OF_CREDIT = 'LINE_OF_CREDIT',
	AUTO_BILLING = 'AUTO_BILLING',
}

export const PayAsYouGoPaymentMethodEnumUtils = generateEnumUtilsWithDescription<PayAsYouGoPaymentMethodEnum>(
	PayAsYouGoPaymentMethodEnum,
	{ translatePath: 'enums:partner.payAsYouGoPaymentMethod' },
);
