import { noValuePlaceholder } from 'js/utils/app';
import Skeleton from 'react-loading-skeleton';
import React from 'react';
import { useLicenseCustomer } from 'module/licenses/hooks';
import { IEntityLicense } from 'module/licenses';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { EllipsisText } from 'js/components/atoms/EllipsisText';
import { isNumber } from 'lodash';

type TPartnerNameProps = {
	license: IEntityLicense;
	ellipsis?: boolean | number;
};

export const LicenseCustomerName = (props: TPartnerNameProps) => {
	const { hasCustomer, isFetching, customer } = useLicenseCustomer(props.license);

	if (hasCustomer && isFetching) {
		return <Skeleton width={120} />;
	}

	if (!hasCustomer || !customer) {
		return noValuePlaceholder();
	}

	const value = getCustomerRenderName(customer);
	return props.ellipsis ? (
		<EllipsisText
			value={value}
			length={isNumber(props.ellipsis) ? props.ellipsis : undefined}
		/>
	) : (
		<>{value}</>
	);
};
