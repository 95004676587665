import { useCallback } from 'react';
import { useApiList } from 'js/hooks/api';
import { IEntityLicense, TEntityLicenseId } from 'module/licenses';
import { IEntityRefundableItem } from 'submodule/refunds';

export const useAsyncApiLicenseRefundableItem = () => {
	const api = useApiList<IEntityRefundableItem, { entitlementIds: TEntityLicenseId[] }>({});

	return useCallback(
		(license: IEntityLicense) =>
			new Promise<IEntityRefundableItem>(async (resolve, reject) => {
				const response = await api({
					queryKey: ['refund/refundableAmountForEntitlements', { entitlementIds: [license.id] }],
					meta: undefined,
				}).catch((error) => reject(error));

				if (response) {
					const refundableAmount = response.data.find((item) => item.entitlementId === license.id);
					if (refundableAmount) {
						resolve(refundableAmount);
					}
				}

				reject(new Error(`No refundable amount found for entitlement #${license.id}`));
			}),
		[api],
	);
};
