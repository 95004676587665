export default {
	error: {
		creditStatusNotFound: 'État du crédit introuvable',
	},
	tabs: {
		detail: 'Récapitulatif du crédit',
		transactions: 'Liste des transactions',
	},
	entity: {
		companyName: 'Entreprise',
		countryCode: 'Pays',
		creditAvailable: 'Crédit disponible',
		creditLimit: 'Limite de crédit',
		percentConsumed: '% utilisé(s)',
		unbilledBalance: 'Solde non facturé',
		unpaidBalance: 'Solde impayé',
	},
	entityShort: {
		creditAvailable: 'Disponible',
		creditLimit: 'Limite',
		percentConsumed: '% utilisé(s)',
		unbilledBalance: 'Non facturée',
		unpaidBalance: 'Non payée',
	},
	entityTransaction: {
		amount: 'Montant',
		createdAt: 'Créée à',
		orderId: 'ID de référence',
		source: 'Source',
	},
	widget: {
		name: 'Type',
		value: 'Crédit',
	},
};
