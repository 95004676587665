import React, { ReactElement, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { TDetailArguments } from 'types';
import { RouteName } from 'module/RouteName';
import { PageDetailComponent } from 'js/layouts/page/PageDetailComponent';
import { useApiOrder } from 'module/orders/hooks/useApiOrders';
import { IEntityOmsOrder, IEntityOrder } from 'module/orders';
import { buildParametrizedRoute } from 'js/utils/common';
import { PageQuoteDetailTabs } from 'module/orders/components/PageQuoteDetailTabs';
import { TUseApiDetailProps } from 'js/queries/useApiDetailQuery';
import { logDebug } from 'js/utils/app';
import { isOrderInQuoteStatus } from 'module/orders/utils/common';
import { useRouteParams } from 'js/hooks/useRouteParams';

type TArguments = TDetailArguments;
type TData = IEntityOrder;
type TQueryProps = TUseApiDetailProps<TData, TArguments, IEntityOmsOrder>;

export const PageQuoteDetail = (): ReactElement => {
	const params = useRouteParams<TArguments>();
	const queryProps: TQueryProps = useMemo(() => ({ filter: params }), [params]);

	return (
		<PageDetailComponent<TData, TArguments, IEntityOmsOrder>
			query={useApiOrder}
			queryProps={queryProps}
			detailLink={buildParametrizedRoute(RouteName.QUOTE.DETAIL, params.id)}
		>
			{({ data: order }) => {
				if (!isOrderInQuoteStatus(order)) {
					logDebug('Quote detail redirected to order details');
					return (
						<Navigate
							to={buildParametrizedRoute(RouteName.ORDER.DETAIL, order.id)}
							replace
						/>
					);
				}

				return <PageQuoteDetailTabs />;
			}}
		</PageDetailComponent>
	);
};
