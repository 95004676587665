import { IPartnerGroups } from 'module/partners';
import { useMemo } from 'react';
import { CONFIG } from 'config';
import { parseEnvString } from 'js/utils/app';
import * as process from 'process';
import { TLocaleLanguage } from 'types/config';

export const useAllowedLanguages = (partnerGroups: IPartnerGroups): TLocaleLanguage[] => {
	const { isTestAccount } = partnerGroups;
	return useMemo(() => {
		if (isTestAccount) {
			return CONFIG.LOCALE.LANGUAGES;
		}

		const envLanguages: TLocaleLanguage[] = parseEnvString(process.env.REACT_APP_LANGUAGES)
			.split(',')
			.filter((language) => CONFIG.LOCALE.LANGUAGES.includes(language as TLocaleLanguage)) as TLocaleLanguage[];

		return envLanguages.length === 0 ? [CONFIG.LOCALE.DEFAULT_LANGUAGE] : envLanguages;
	}, [isTestAccount]);
};
