import { useCallback } from 'react';
import { TPartnerPriceLists } from 'js/priceList';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { isPriceListCodeInPriceLists } from 'js/priceList/priceListUtils';
import { useAppContext, useOrderContext } from 'js/contexts';

type TUsePartnerPriceListGuardResponse = (priceLists: TPartnerPriceLists) => Promise<boolean>;

/**
 * Resolve if the cart should be cleared and the user agree with it
 * @returns {TUsePartnerPriceListGuardResponse}
 */
export const usePartnerPriceListGuard = (): TUsePartnerPriceListGuardResponse => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { confirmationModalRef } = useAppContext();
	const { orderState } = useOrderContext();
	const currentPriceList = orderState.priceListCode;
	const itemsCount = orderState.items.length;

	return useCallback(
		async (priceLists: TPartnerPriceLists) => {
			// No products in the cart OR no current price list
			if (itemsCount === 0 || !currentPriceList) {
				return true;
			}

			// Current price list is also included in new list
			if (isPriceListCodeInPriceLists(priceLists, currentPriceList)) {
				return true;
			}

			// Resolution by user
			return Boolean(
				await confirmationModalRef.current?.show({
					title: t('guards.partnerPriceListGuard.title'),
					messages: [t('guards.partnerPriceListGuard.message')],
					submitButtonText: t('common:actions.continue'),
				}),
			);
		},
		[currentPriceList, t, confirmationModalRef, itemsCount],
	);
};
