import React, { ReactElement } from 'react';
import { FormikControl } from 'js/components/formik/FormikControl';
import { useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';
import type { TFormControlProps } from '@avast/react-ui-components';
import { TCustomerFormFields } from 'module/customers/forms/CustomerForm';
import { useFormikContext } from 'formik';
import { discountTypeEnumUtils } from 'module/customers/enums';
import { isCustomerBusiness } from 'module/customers/utils/customerSelectors';

export const FormikDiscountTypeControl = (): ReactElement | null => {
	const [t] = useTranslation(customersConfig.trNamespace);
	const { values, initialValues } = useFormikContext<TCustomerFormFields>();
	const { billing } = values;

	if (!isCustomerBusiness(values)) {
		return null;
	}

	const hasWaitingDiscount = Boolean(initialValues.requestedDiscountType);
	const isBillingDiscountType = Boolean(billing.discountType);
	const isDisabled = isBillingDiscountType || hasWaitingDiscount;

	const name = isBillingDiscountType ? 'billing.discountType' : 'requestedDiscountType';
	const labelText = isBillingDiscountType ? 'common:entity.discountType' : 'common:entity.requestedDiscountType';
	const controlMessages: TFormControlProps['messages'] = [];

	if (hasWaitingDiscount) {
		controlMessages.push([t('common.discountWaitingToApprove'), 'warning']);
	}

	return (
		<FormikControl
			label={t(labelText)}
			messages={controlMessages}
		>
			<FormikControl.SingleSelect
				name={name}
				options={discountTypeEnumUtils.getSelectOptions()}
				disabled={isDisabled}
			/>
		</FormikControl>
	);
};
