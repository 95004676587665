import React, { PropsWithChildren, ReactElement, ReactNode, useMemo } from 'react';
import type { ITestId } from '@avast/react-ui-components';
import { CardTable } from '@avast/react-ui-components';
import { IApiSortBy } from 'types/api';
import { useTranslation } from 'react-i18next';
import { useApiLicenseList } from 'module/licenses/hooks';
import { IEntityLicense, ILicenseListFilter, ILicenseListFilterBase } from 'module/licenses';
import { RouteName } from 'module/RouteName';
import { dateTimeToFilterDate } from 'js/utils/dateTime';
import { buildParametrizedRoute, object2UrlParams, tableFilterCreator } from 'js/utils/common';
import { DateTime } from 'luxon';
import { useLicenseWidgetColumns } from 'submodule/widgets/tableColumns';
import { VisibilityState } from '@tanstack/table-core/src/features/Visibility';
import { WidgetTypeEnum, widgetTypeEnumUtils } from 'submodule/widgets/enums';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { LicenseDateRangeTypeEnum, LicenseStatusEnum } from 'module/licenses/enums';
import { SortDirectionEnum } from 'js/enums';
import { useNavigate } from 'react-router-dom';

type TLicenseWidgetProps = React.HTMLAttributes<HTMLDivElement> & {
	limit?: number;
	filter: ILicenseListFilter;
	sortBy: IApiSortBy<IEntityLicense>;
	header: ReactNode;
	columnVisibility?: VisibilityState;
} & ITestId;

type TLicenseWidgetForNDaysProps = Pick<TLicenseWidgetProps, 'limit' | 'header' | 'columnVisibility'> & {
	days: number;
	partnerId?: ILicenseListFilterBase['partnerId'];
};

/**
 * Widget with list of licenses
 * @param {PropsWithChildren<TLicenseWidgetProps>} props
 * @returns {ReactElement}
 * @constructor
 */
const LicenseWidget = (props: PropsWithChildren<TLicenseWidgetProps>): ReactElement => {
	const { columns, columnVisibility: defaultColumnVisibility } = useLicenseWidgetColumns();
	const { limit = 5, header, filter, sortBy, columnVisibility = defaultColumnVisibility, ...rest } = props;
	const navigate = useNavigate();

	// Api call for data
	const {
		data,
		query: { isFetching },
	} = useApiLicenseList({ filter, sortBy, config: { limit } });

	return (
		<CardTable<IEntityLicense>
			{...rest}
			columns={columns}
			header={{ text: header }}
			data={data?.items ?? []}
			onViewMore={() =>
				navigate({
					pathname: RouteName.LICENSE.LIST,
					search: object2UrlParams(tableFilterCreator<ILicenseListFilter>(filter)),
				})
			}
			table={{
				state: { columnVisibility },
				meta: {
					rowCount: limit,
					loading: isFetching,
					onRowClick(row) {
						navigate(buildParametrizedRoute(RouteName.LICENSE.DETAIL, row.id));
					},
				},
			}}
		/>
	);
};

/**
 * License widget for last N days (expiration)
 * @param {Object} props
 * @returns {ReactElement}
 * @constructor
 */
const LicenseWidgetLastNDays = (props: TLicenseWidgetForNDaysProps): ReactElement => {
	const { days, header, partnerId, ...rest } = props;
	const [t] = useTranslation(licensesConfig.trNamespace);
	const filter: ILicenseListFilter = useMemo(
		() => ({
			statuses: [LicenseStatusEnum.EXPIRED],
			dateRange: [dateTimeToFilterDate(DateTime.now().minus({ days })), dateTimeToFilterDate(DateTime.now())],
			dateRangeType: LicenseDateRangeTypeEnum.EXPIRATION,
			partnerId,
		}),
		[days, partnerId],
	);

	return (
		<LicenseWidget
			header={header || t(widgetTypeEnumUtils.getCaption(WidgetTypeEnum.EXPIRED_LICENSES_LAST_N_DAYS), { count: days })}
			filter={filter}
			sortBy={{ key: 'expiration', direction: SortDirectionEnum.ASC }}
			testId={`license widget last ${days} days`}
			{...rest}
		/>
	);
};

/**
 * License widget for next N days (expiration)
 * @param {Object} props
 * @returns {ReactElement}
 * @constructor
 */
const LicenseWidgetNextNDays = (props: TLicenseWidgetForNDaysProps): ReactElement => {
	const { days, partnerId, header, ...rest } = props;
	const [t] = useTranslation(licensesConfig.trNamespace);

	const filter: ILicenseListFilter = useMemo(
		() => ({
			statuses: [LicenseStatusEnum.ACTIVE],
			dateRange: [dateTimeToFilterDate(DateTime.now()), dateTimeToFilterDate(DateTime.now().plus({ days }))],
			dateRangeType: LicenseDateRangeTypeEnum.EXPIRATION,
			partnerId,
		}),
		[days, partnerId],
	);

	return (
		<LicenseWidget
			header={
				header || t(widgetTypeEnumUtils.getCaption(WidgetTypeEnum.EXPIRING_LICENSES_NEXT_N_DAYS), { count: days })
			}
			filter={filter}
			sortBy={{ key: 'expiration', direction: SortDirectionEnum.ASC }}
			testId={`license widget next ${days} days`}
			{...rest}
		/>
	);
};

export { LicenseWidget, LicenseWidgetLastNDays, LicenseWidgetNextNDays };
