import React from 'react';
import { Modal } from '@avast/react-ui-components';
import { AsyncModal, TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { useTranslation } from 'react-i18next';
import { IEntityLicense } from 'module/licenses';
import { SelectRefundLicenseTable } from 'submodule/refunds/initiate/search/SelectRefundLicenseTable';

export type TAsyncSelectRefundLicenseModalProps = {
	licenses: IEntityLicense[];
};

export const AsyncSelectRefundLicenseModal = (
	props: TAsyncModalContainerProps<TAsyncSelectRefundLicenseModalProps, IEntityLicense>,
) => {
	const { forwardedRef } = props;
	const [t] = useTranslation('submodules');

	return (
		<AsyncModal<TAsyncSelectRefundLicenseModalProps, IEntityLicense>
			ref={forwardedRef}
			size="lg"
		>
			{({ licenses }) => (
				<>
					<Modal.Header>{t('refunds.license.select.title')}</Modal.Header>
					<Modal.Body>
						<SelectRefundLicenseTable
							licenses={licenses}
							onSelect={(license) => forwardedRef.current?.onSuccess(license)}
						/>
					</Modal.Body>
					<Modal.Footer cancelButton />
				</>
			)}
		</AsyncModal>
	);
};
