import { curry, curryRight, flow, get, isEqual, negate } from 'lodash';

export const getProp = curryRight(get, 2);

export const getPropAsNumber =
	<T extends object>(path: string) =>
	(obj: T) =>
		Number(getProp(path)(obj));

export const isPropType = (type: string) => (propName: string) =>
	flow([getProp(propName), (prop) => type === typeof prop]);

export const isPropNumber: <T extends object>(propName: string) => (obj: T) => boolean = isPropType('number');

export const isPropString: <T extends object>(propName: string) => (obj: T) => boolean = isPropType('string');

export const isPropNonEmptyString: <T extends object>(propName: string) => (obj: T) => boolean = flow([
	isPropString,
	(prop) => prop.length > 1,
]);

export const isNot = curry(negate(isEqual), 2);

export const propIsNot = (prop: string, value: unknown): ((obj: unknown) => boolean) =>
	flow([getProp(prop), isNot(value)]);

export const ifElse =
	<T, R>(condition: (obj: T) => boolean, trueBranch: (obj: T) => R, falseBranch: (obj: T) => R) =>
	(obj: T): R => {
		if (condition(obj)) {
			return trueBranch(obj);
		}

		return falseBranch(obj);
	};
