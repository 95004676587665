import React, { MouseEventHandler, PropsWithChildren } from 'react';
import type { DraggableProvided } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import { useWidgetContext, useWidgetEditContext } from 'submodule/widgets/context';
import { TWidgetCoords, TWidgetGridItem } from 'submodule/widgets';
import { ButtonComposition, Card, IconButton } from '@avast/react-ui-components';
import { faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useWidgetHeader } from 'submodule/widgets/hooks';
import { WidgetItemCell } from 'submodule/widgets/components/WidgetItemCell';

type TWidgetPlaceholderItemProps = {
	item: TWidgetGridItem;
	itemIndex: number;
	coords: TWidgetCoords;
};

type TWidgetActions = {
	onEdit: MouseEventHandler<HTMLButtonElement>;
	onRemove: MouseEventHandler<HTMLButtonElement>;
};

type TWidgetWrapper = PropsWithChildren<{
	provided: DraggableProvided;
	isDragging: boolean;
}>;

const WidgetWrapper = ({ provided, isDragging, children }: TWidgetWrapper) => {
	const className = classNames('widget-draggable', 'widget-item', { 'is-dragging': isDragging });

	return (
		<div
			ref={provided.innerRef}
			{...provided.draggableProps}
			{...provided.dragHandleProps}
			className={className}
		>
			{children}
		</div>
	);
};

const WidgetActions = ({ onEdit, onRemove }: TWidgetActions) => (
	<ButtonComposition
		size="xs"
		marginY={false}
	>
		<IconButton
			size="xs"
			iconFa={faPencilAlt}
			variant="outline-primary"
			onClick={onEdit}
		/>
		<IconButton
			size="xs"
			iconFa={faTimes}
			variant="outline-danger"
			onClick={onRemove}
		/>
	</ButtonComposition>
);

export const WidgetItemContainer = ({ item, itemIndex, coords }: TWidgetPlaceholderItemProps) => {
	const { dispatch, widgetModal } = useWidgetEditContext();
	const { t } = useWidgetContext();
	const draggableKey = `column${itemIndex}-${item?.id}`;

	const widgetHeader = useWidgetHeader(item.widget);

	const editWidget = () => {
		widgetModal.current?.show({
			action: 'EDIT_WIDGET',
			title: t('widgets.action.editWidget'),
			coords,
			index: itemIndex,
			initialValues: item.widget,
		});
	};

	const removeWidget = () => dispatch({ type: 'REMOVE_WIDGET', payload: { index: itemIndex, coords } });

	return (
		<Draggable
			key={draggableKey}
			draggableId={draggableKey}
			index={itemIndex}
		>
			{(provided, snapshot) => (
				<WidgetWrapper
					provided={provided}
					isDragging={snapshot.isDragging}
				>
					<Card>
						<Card.Header
							bottomBorder={false}
							rightPlaceholder={
								<WidgetActions
									onEdit={editWidget}
									onRemove={removeWidget}
								/>
							}
						>
							<WidgetItemCell {...widgetHeader} />
						</Card.Header>
					</Card>
				</WidgetWrapper>
			)}
		</Draggable>
	);
};
