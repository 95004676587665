import { IPageModuleConfig, IPageModuleLinksConfig, moduleConfigGenerator } from 'module/index';
import { RouteName } from 'module/RouteName';
import { buildParametrizedRoute } from 'js/utils/common';
import { IFlexibleBillingDistributionPartner } from 'module/flexibleBilling';

interface IFlexibleBillingModuleConfig
	extends IPageModuleConfig,
		IPageModuleLinksConfig<IFlexibleBillingDistributionPartner> {
	detailLinkById: (entity: IFlexibleBillingDistributionPartner['distributionPartner']['id']) => string;
	updateLinkById: (entity: IFlexibleBillingDistributionPartner['distributionPartner']['id']) => string;
}

export const flexibleBillingConfig = moduleConfigGenerator<IFlexibleBillingModuleConfig>({
	trNamespace: 'moduleFlexibleBilling',
	menuName: 'app:menu.flexibleBilling',
	menuLink: RouteName.FLEXIBLE_BILLING.DISTRIBUTION_PARTNERS,
	aclModule: 'flexibleBilling',
	detailLink: (row) => buildParametrizedRoute(RouteName.FLEXIBLE_BILLING.DETAIL, row.distributionPartner.id),
	detailLinkById: (id) => buildParametrizedRoute(RouteName.FLEXIBLE_BILLING.DETAIL, id),
	updateLinkById: (id) => buildParametrizedRoute(RouteName.FLEXIBLE_BILLING.UPDATE, id),
});
