import React, { ReactElement } from 'react';
import { Modal } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import {
	SelectVariantTable,
	TSelectVariantTableProps,
} from 'module/purchase/components/selectProducts/SelectVariantTable';
import { SubmitButton } from 'js/components/atoms/Button';
import { FormikForm } from 'js/components/formik/FormikForm';
import { isGroupConsumer } from 'module/purchase/utils/selectors';
import { selectVariantItemsToInstanceAddItems } from 'module/purchase/utils/common';
import { TSelectVariantForm, TSelectVariantItem } from 'module/purchase/components/selectProducts';
import { useAddItemsToCart } from 'module/purchase/hooks/order';
import { ToastSuccess } from 'module/purchase/components/selectProducts/ToastSuccess';
import { orderInstanceNormalizer } from 'module/purchase/normalizer';
import { useOrderContext } from 'js/contexts';
import { usePriceListContext } from 'js/priceList/PriceListContext';

type TSelectVariantContentProps = TSelectVariantTableProps & {
	onSelect: () => void;
};

const isSubmitDisabled = (values: TSelectVariantForm): boolean => !values.variant.some((variant) => variant.checked);

export const SelectVariantContent = (props: TSelectVariantContentProps): ReactElement | null => {
	const { onSelect, productGroup } = props;
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { priceListCode } = usePriceListContext();
	const { updateMode } = useOrderContext();
	const addItemsToCart = useAddItemsToCart();

	if (productGroup === null) {
		return null;
	}

	const initValues: TSelectVariantItem[] =
		productGroup.products.map((product, i) => ({
			id: i,
			checked: false,
			quantity: isGroupConsumer(productGroup) ? 0 : 1,
			unit: isGroupConsumer(productGroup) ? product.bulkQuantity : 0,
			product: orderInstanceNormalizer.normalizeProduct(productGroup, product),
		})) || [];

	return (
		<Formik<TSelectVariantForm>
			initialValues={{
				variant: initValues,
			}}
			onSubmit={async (values, { setSubmitting }) => {
				const items = values.variant.filter(
					({ checked, quantity = 0, unit = 0 }) => checked && quantity !== 0 && unit !== 0,
				);
				if (items.length === 0) {
					return;
				}

				setSubmitting(true);

				if (!(await addItemsToCart(priceListCode, selectVariantItemsToInstanceAddItems(items)))) {
					return;
				}

				toast.success(
					<ToastSuccess
						productGroup={productGroup}
						items={items}
						isUpdateOrderMode={updateMode}
					/>,
				);
				setSubmitting(false);
				onSelect();
			}}
		>
			{({ values }) => (
				<FormikForm testId="selectVariantForm">
					<Modal.Header>{productGroup.name}</Modal.Header>
					<Modal.Body>
						<SelectVariantTable productGroup={productGroup} />
					</Modal.Body>
					<Modal.Footer
						cancelButton
						submitButton={
							<SubmitButton
								disabled={isSubmitDisabled(values)}
								size="sm"
								testId="addToCart"
							>
								{t('actions.addToCart')}
							</SubmitButton>
						}
					/>
				</FormikForm>
			)}
		</Formik>
	);
};
