export default {
	table: {
		noData: '利用可能なデータがまだありません',
		error: 'データを取得中にエラーが発生しました',
		noDataFilter: 'フィルタ用のデータがありません',
		retry: 'リクエストを再試行',
		perPage: '1 ページあたり',
		pagination: {
			total: '<0>{{total}}</0> 個のレコードのうち <strong>{{from}} ～ <0>{{to}}</0></strong> 番目を表示中。',
		},
	},
	datepicker: {
		goToToday: '今月に移動',
		rangeDivider: '～',
		startDate: '開始日',
		endDate: '終了日',
		rangeSelector: {
			today: '本日',
			thisWeek: '今週',
			thisMonth: '今月',
			lastWeek: '先週',
			lastMonth: '先月',
			thisYear: '今年',
			lastYear: '昨年',
			customRange: 'カスタム範囲',
		},
		relative: {
			single: {
				today: '本日',
				tomorrow: '明日',
				yesterday: '昨日',
			},
			range: {
				thisWeek: '今週',
				lastWeek: '先週',
				thisMonth: '今月',
				lastMonth: '先月',
				thisYear: '今年',
				lastYear: '昨年',
			},
		},
	},
	searchComboBox: {
		value: {
			placeholder: '値を入力...',
		},
	},
	notes: {
		noContent: '利用可能なメモがありません',
	},
	hgop: {
		shoppingCart: {
			title: '買い物かご',
		},
	},
	chargeOrderDocuments: {
		entity: {
			documentType: '書類の種類',
		},
		type: {
			payment_instructions: '支払い指示',
			invoice: '請求書',
			vat_invoice: '請求書',
			credit_memo: 'クレジット メモ',
			payg_price_breakdown_msp: 'フレキシブル請求 - パートナー レポート',
			payg_product_usage_report: 'フレキシブル請求 - 顧客レポート',
			payg_reports_all: 'フレキシブル請求 - 製品使用状況レポート (すべて)',
		},
	},
	export: {
		caption: 'エクスポート中です。この処理には数分かかる場合があります。',
		autoDownload: 'このファイルは自動的にダウンロードされます。',
		downloadError: 'ファイルをダウンロードできません。',
		error: 'エクスポートが失敗しました。後でもう一度お試しください。',
	},
	common: {
		more: '詳細を表示',
		apply: '適用',
	},
	select: {
		placeholder: 'オプションを選択',
	},
	pagination: {
		prev: '前へ',
		next: '次へ',
		page: 'ページ',
		of: '/',
		miniText: '{{active}} / {{total}} ページ',
	},
	modal: {
		cancel: 'キャンセル',
		submit: '送信',
		apply: '適用',
	},
	rangeDatepicker: {
		placeholder: '日付を選択',
	},
	singleDatepicker: {
		placeholder: '日付を選択',
	},
	toast: {
		title: {
			success: '成功',
			error: 'エラー',
			info: '情報',
			default: '情報',
			dark: '情報',
			warning: '警告',
		},
	},
	fileUpload: {
		placeholder: 'ファイルを<strong>選択</strong>またはここにドラッグします',
	},
};
