import { TApiErrorResolve } from 'types/api';
import i18n from 'i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';

export const createPromotionApiError: TApiErrorResolve = () => ({
	title: i18n.t(promotionsConfig.trPrefix('error.createPromotion')),
});

export const updatePromotionApiError: TApiErrorResolve = () => ({
	title: i18n.t(promotionsConfig.trPrefix('error.updatePromotion')),
});
