import React, { ReactElement } from 'react';
import { AsyncModal, TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { ApiErrorModalContent, TApiErrorModalContentProps } from 'js/components/molecules/Modal/ApiErrorModalContent';

export type TAsyncApiErrorModalProps = TApiErrorModalContentProps;

export const AsyncApiErrorModal = (props: TAsyncModalContainerProps<TAsyncApiErrorModalProps>): ReactElement => {
	const { forwardedRef } = props;

	return (
		<AsyncModal<TAsyncApiErrorModalProps>
			ref={forwardedRef}
			backdrop="static"
			size="sm"
			testId="apiError"
		>
			{(props) => <ApiErrorModalContent {...props} />}
		</AsyncModal>
	);
};
