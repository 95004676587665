import type { ArraySchema } from 'yup';
import * as Yup from 'yup';
import { TUserAppMessageConfig } from 'submodule/AppMessages/types';

const itemValidator = Yup.object().shape<TUserAppMessageConfig>({
	id: Yup.string().required(),
	hidden: Yup.boolean(),
});

// @ts-ignore
export const userAppMessagesConfigValidator: ArraySchema<TUserAppMessageConfig> = Yup.array<TUserAppMessageConfig>()
	.required()
	.of(itemValidator);
