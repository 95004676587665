export default {
	common: {
		continue: 'Continua la registrazione',
		skip: 'Ignora',
		submitting: 'Invio dei dati...',
		finishing: 'Stiamo completando la registrazione...',
		successRegistration: 'Registrazione completata',
		successAcceptTerms: 'Termini e condizioni sono stati accettati. Grazie.',
	},
	steps: {
		init: 'Ti diamo il benvenuto',
		terms: 'Termini e Condizioni',
		registrationInfo: 'Informazioni essenziali',
		taxExemption: 'Esenzione fiscale',
	},
	page: {
		welcome: {
			title: 'Ti diamo il benvenuto nel processo di registrazione dell’e-commerce',
			content: 'Ti guideremo passo passo nel processo di registrazione e ti chiederemo di:',
			conditionList: [
				'accettare termini e condizioni',
				'aggiornare e confermare le tue informazioni profilo essenziali',
			],
		},
		terms: {
			title: 'Leggi e accetta i nostri termini e condizioni:',
			button: 'Ho letto l’Accordo e accetto i termini',
		},
		registrationInfo: {
			contactInformation: 'Informazioni di contatto',
			accountInformation: 'Dati account',
		},
		taxExemption: {
			title: 'Invia i dettagli dell’esenzione fiscale',
			subtitle: 'Immetti le informazioni richieste sull’esenzione fiscale.',
		},
	},
	error: {
		unableToInit: 'Impossibile inizializzare la registrazione.',
		unableToRegistrationToken: 'Impossibile ottenere il token di registrazione.',
		unableToStart: 'Impossibile avviare la registrazione.',
		unableToLoadTerms: 'Impossibile caricare Termini e Condizioni. Contatta il tuo account manager per assistenza.',
		unableToAcceptTerms: 'Impossibile accettare le condizioni per il partner attuale.',
		unableToProcess: 'Al momento non siamo in grado di inviare i tuoi dati per l’elaborazione.',
	},
	alert: {
		checkCurrentAccountInfo:
			'Abbiamo inserito le informazioni attualmente memorizzate per il tuo account nei nostri sistemi. Verifica le informazioni e aggiornale, se necessario. Le modifiche apportate verranno salvate per uso futuro.',
		changeAccountDataInPartnerPortal:
			'Per modificare le informazioni di contatto, completa il processo di registrazione, visita la sezione Profilo nel portale per i partner di Avast Business e modifica le informazioni del profilo.',
	},
};
