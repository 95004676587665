import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum PurchaseTypeEnum {
	PREPAID = 'PREPAID',
	FLEXIBLE_BILLING = 'FLEXIBLE_BILLING',
}

export const purchaseTypeEnumUtils = generateEnumUtils<PurchaseTypeEnum>(PurchaseTypeEnum, {
	translatePath: 'enums:purchase.type',
});
