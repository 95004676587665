import { AsyncModal, TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import React from 'react';
import {
	SelectPartyModal,
	TLicenseOperationPartyResolution,
} from 'module/licenses/components/licenseOperation/SelectPartyModal';

export type TAsyncSelectPartyModalProps = {
	onSelect(resolution: TLicenseOperationPartyResolution): void;
};

export const AsyncSelectPartyModal = (props: TAsyncModalContainerProps<TAsyncSelectPartyModalProps>) => {
	const { forwardedRef } = props;

	return (
		<AsyncModal<TAsyncSelectPartyModalProps>
			ref={forwardedRef}
			testId="selectParty"
		>
			{({ onSelect }) => (
				<SelectPartyModal
					onCancel={() => forwardedRef.current?.onCancel()}
					onSuccess={onSelect}
				/>
			)}
		</AsyncModal>
	);
};
