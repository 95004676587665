import React, { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { Alert } from '@avast/react-ui-components';
import { RequireExactlyOne } from 'type-fest';

type TRetryWrapper = {
	maxAttempts?: number;
	infinite: true;
	delay?: number; // In seconds
	retry(): Promise<unknown>;
	errorCaption: string;
	errorDetail?: ReactNode;
	growingSequence?: boolean;
};

type TRetryWrapperProps = RequireExactlyOne<TRetryWrapper, 'infinite' | 'errorCaption'>;

/**
 * Wrapper repeatedly call retry function until the response is satisfactory,
 * time of the request is not included in delay
 *
 * @param {React.PropsWithChildren<TRetryWrapperProps>} props
 * @returns {JSX.Element}
 * @constructor
 *
 * @example (maxAttempts = 3, delay = 5, growingSequence = false) => 5+5+5 => error is shown after 15s + time of requests
 * @example (maxAttempts = 3, delay = 5, growingSequence = true) => 5+10+15 => error is shown after 30s + time of requests
 */
export const RetryWrapper = (props: PropsWithChildren<TRetryWrapperProps>) => {
	const { maxAttempts = 10, retry, children, delay = 3, errorCaption, errorDetail, growingSequence, infinite } = props;
	const [attempts, setAttempts] = useState(0);
	const _delay = delay * 1000;

	useEffect(() => {
		let running = true;
		const run = async () => {
			await retry();
			if (running) {
				setAttempts((attempts) => attempts + 1);
			}
		};

		if (infinite || attempts < maxAttempts) {
			setTimeout(run, growingSequence ? attempts * _delay : _delay);
		}

		return () => {
			running = false;
		};
	}, [attempts, maxAttempts, infinite, retry, _delay, growingSequence]);

	if (!infinite && attempts >= maxAttempts) {
		return (
			<Alert
				variant="danger"
				caption={errorCaption!}
			>
				{errorDetail}
			</Alert>
		);
	}

	return <>{children}</>;
};
