import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum PartnerTermsEnum {
	DR = 'DR',
	CLIENT = 'Client',
}

export const partnerTermsEnumUtils = generateEnumUtils<PartnerTermsEnum>(PartnerTermsEnum, {
	translatePath: 'enums:partner.terms',
});
