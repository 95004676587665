import { useApiAsyncJob } from 'js/asyncJobs/useApiAsyncJobs';
import { logError } from 'js/utils/app';
import { isAsyncJobDone, isAsyncJobInError } from 'js/asyncJobs/asyncJobsUtils';
import { IAsyncJob } from 'js/asyncJobs';
import { isNumber } from 'lodash';

export const useFetchAsyncJobRecursively = (
	asyncJob: IAsyncJob | null,
	onSuccess: (asyncJob: IAsyncJob) => void,
	onError: () => void,
	delay: number = 500,
) => {
	const {
		query: { refetch },
	} = useApiAsyncJob({
		id: asyncJob?.id,
		queryConfig: {
			enabled: isNumber(asyncJob?.id),
			onSuccess({ data: asyncJob }) {
				if (isAsyncJobDone(asyncJob)) {
					onSuccess(asyncJob);
					return;
				}

				if (isAsyncJobInError(asyncJob)) {
					logError(asyncJob);
					onError();
					return;
				}

				setTimeout(refetch, delay);
			},
			onError() {
				logError(asyncJob);
				onError();
			},
		},
	});
};
