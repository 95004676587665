export default {
	table: {
		noData: 'Noch keine Daten verfügbar',
		noDataFilter: 'Keine Daten für den Filter',
		error: 'Fehler beim Abrufen von Daten aufgetreten',
		retry: 'Anfrage wiederholen',
		perPage: 'pro Seite',
		pagination: {
			total: 'Zeigt <strong>{{from}} bis <0>{{to}}</0></strong> von <0>{{total}}</0> Einträgen an.',
		},
	},
	datepicker: {
		goToToday: 'Gehe zu Dieser Monat',
		rangeDivider: 'zu',
		startDate: 'Startdatum',
		endDate: 'Enddatum',
		rangeSelector: {
			today: 'Heute',
			thisWeek: 'Diese Woche',
			thisMonth: 'Diesen Monat',
			lastWeek: 'Letzte Woche',
			lastMonth: 'Letzter Monat',
			thisYear: 'Dieses Jahr',
			lastYear: 'Letztes Jahr',
			customRange: 'Benutzerdefinierter Bereich',
		},
		relative: {
			single: {
				today: 'Heute',
				tomorrow: 'Morgen',
				yesterday: 'Gestern',
			},
			range: {
				thisWeek: 'Diese Woche',
				lastWeek: 'Letzte Woche',
				thisMonth: 'Diesen Monat',
				lastMonth: 'Letzter Monat',
				thisYear: 'Dieses Jahr',
				lastYear: 'Letztes Jahr',
			},
		},
	},
	searchComboBox: {
		value: {
			placeholder: 'Einen Wert eingeben...',
		},
	},
	notes: {
		noContent: 'Kein Hinweis verfügbar',
	},
	hgop: {
		shoppingCart: {
			title: 'Einkaufswagen',
		},
	},
	chargeOrderDocuments: {
		entity: {
			documentType: 'Dokumenttyp',
		},
		type: {
			payment_instructions: 'Zahlungsanweisungen',
			invoice: 'Rechnung',
			vat_invoice: 'Rechnung',
			credit_memo: 'Gutschrift',
			payg_price_breakdown_msp: 'Flexible Abrechnung – Partnerbericht',
			payg_product_usage_report: 'Flexible Abrechnung – Kundenbericht',
			payg_reports_all: 'Flexible Abrechnung – Produktnutzungsberichte (alle)',
		},
	},
	export: {
		caption: 'Export wird durchgeführt, dies kann einige Minuten dauern.',
		autoDownload: 'Die Datei wird automatisch heruntergeladen.',
		downloadError: 'Datei kann nicht heruntergeladen werden.',
		error: 'Export fehlgeschlagen. Bitte versuchen Sie es später erneut.',
	},
	common: {
		more: 'Mehr anzeigen',
		apply: 'Anwenden',
	},
	select: {
		placeholder: 'Option wählen',
	},
	pagination: {
		prev: 'Zurück',
		next: 'Weiter',
		page: 'Seite',
		of: 'von',
		miniText: 'Seite {{active}} von {{total}}',
	},
	modal: {
		cancel: 'Abbrechen',
		submit: 'Absenden',
		apply: 'Anwenden',
	},
	rangeDatepicker: {
		placeholder: 'Datum wählen',
	},
	singleDatepicker: {
		placeholder: 'Datum wählen',
	},
	toast: {
		title: {
			success: 'Erfolgreich',
			error: 'Fehler',
			info: 'Informationen',
			default: 'Informationen',
			dark: 'Informationen',
			warning: 'Warnung',
		},
	},
	fileUpload: {
		placeholder: '<strong>Wählen</strong> Sie eine Datei aus oder ziehen Sie sie hierher',
	},
};
