import { usePartyPrefixTranslation } from 'module/purchase/hooks/usePartyPrefixTranslation';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useTranslation } from 'react-i18next';
import {
	getCustomerPricingBreakdown,
	getPartnerPricingBreakdown,
	TPricingBreakdownLine,
} from 'module/purchase/utils/pricingBreakdown';
import { IEntityOrder, IEntityOrderItem } from 'module/orders';
import { hasOrderSavedPricingResponse } from 'module/orders/utils/common';

const usePricingBreakdownCustomer = (order: IEntityOrder, item: IEntityOrderItem): TPricingBreakdownLine[] => {
	const { tPartyPrefix } = usePartyPrefixTranslation(purchaseConfig.trNamespace);
	const [t] = useTranslation(purchaseConfig.trNamespace);

	return getCustomerPricingBreakdown({
		pricing: item.pricing,
		currencyCode: order.currency,
		t,
		tPartyPrefix,
		quotedCustomerPricing: item.computedValues.price.quotedCustomer,
	});
};

const usePricingBreakdownPartner = (order: IEntityOrder, item: IEntityOrderItem): TPricingBreakdownLine[] | null => {
	const { tPartyPrefix } = usePartyPrefixTranslation(purchaseConfig.trNamespace);
	const [t] = useTranslation(purchaseConfig.trNamespace);

	if (order.computedValues.isEndCustomerOrder) {
		return null;
	}

	return getPartnerPricingBreakdown({
		pricing: item.pricing,
		currencyCode: order.currency,
		t,
		tPartyPrefix,
	});
};

export const useOrderPricingBreakdown = (
	order: IEntityOrder,
	item: IEntityOrderItem,
): TPricingBreakdownLine[][] | null => {
	const pricingBreakdownCustomer = usePricingBreakdownCustomer(order, item);
	const pricingBreakdownPartner = usePricingBreakdownPartner(order, item);
	const hasSavedPricingResponse = hasOrderSavedPricingResponse(order);

	if (!hasSavedPricingResponse) {
		return null;
	}

	const cols: TPricingBreakdownLine[][] = [];
	cols.push(pricingBreakdownCustomer);
	if (pricingBreakdownPartner) {
		cols.push(pricingBreakdownPartner);
	}

	return cols;
};
