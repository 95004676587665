import React from 'react';
import { useFormikContext } from 'formik';
import { TWidget } from 'submodule/widgets';
import { Col, Row } from 'js/components/atoms/Row';
import { FormikControl } from 'js/components/formik/FormikControl';
import { map } from 'lodash';
import { useAllowedWidgets } from 'submodule/widgets/hooks';
import { logError } from 'js/utils/app';

export const WidgetSettingsControls = () => {
	const { values } = useFormikContext<TWidget>();
	const widgets = useAllowedWidgets();
	const config = widgets.find((widget) => widget.type === values.type);

	if (!config) {
		return null;
	}

	const fields = config.getSettingsFields();

	return (
		<Row size="sm">
			{map(fields, (field, name) => {
				const { type, label, ...rest } = field;

				return (
					<Col
						sm={6}
						key={name}
					>
						{(() => {
							switch (type) {
								case 'number':
									return (
										<FormikControl label={label}>
											<FormikControl.Number
												name={`settings.${name}`}
												size="sm"
												{...rest}
											/>
										</FormikControl>
									);

								case 'partnerId':
									return (
										<FormikControl label={label}>
											<FormikControl.SelectPartner
												name={`settings.${name}`}
												size="sm"
												{...rest}
											/>
										</FormikControl>
									);

								default:
									logError(`Not supported type: ${type}`);
									return null;
							}
						})()}
					</Col>
				);
			})}
		</Row>
	);
};
