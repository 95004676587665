import { TableColumnsDef } from 'types';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TCreditStatusWidgetData } from 'submodule/widgets/view/widget/CreditStatusWidget';
import Skeleton from 'react-loading-skeleton';
import { creditStatusConfig } from 'module/creditStatus/creditStatusConfig';

type TTableData = TCreditStatusWidgetData;

export const useCreditStatusWidgetColumns = (isFetching: boolean): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(creditStatusConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();

		return [
			columnHelper.text('name', { header: t('widget.name') }),
			columnHelper.text('value', {
				header: t('widget.value'),
				meta: { align: 'end', defaultValue: { value: <Skeleton width={100} />, condition: () => isFetching } },
			}),
		];
	}, [t, isFetching]);
};
