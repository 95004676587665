import React, { ReactElement } from 'react';
import { List } from 'module/creditStatus/pages/content/List';
import { Detail } from 'module/creditStatus/pages/content/Detail';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { useAuthContext } from 'js/contexts';

export const PageDefault = (): ReactElement => {
	const { isGroupPartner, authCompanyId } = useAuthContext();

	return isGroupPartner ? (
		<Detail id={authCompanyId} />
	) : (
		<DefaultContainer>
			<List />
		</DefaultContainer>
	);
};
