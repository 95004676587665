import { useApi } from 'js/hooks/api';
import type { AxiosInstance, AxiosResponse } from 'axios';
import axios from 'axios';
import { TUseApiProps } from 'js/hooks/api/useApi';
import { fillUrlParameters } from 'js/utils/common';
import { ApiMutationTypeEnum } from 'js/enums';

type TUseApiMutationProps = TUseApiProps & {
	key: string;
	type?: ApiMutationTypeEnum;
};

type TUseApiMutation<Data extends {}, Response> = (data: Data) => Promise<AxiosResponse<Response>>;

export const useApiMutation = <Data extends {}, Response = Data, Params extends {} = {}>(
	props: TUseApiMutationProps,
): TUseApiMutation<Data, Response> => {
	const { key, type = ApiMutationTypeEnum.POST, config } = props;
	const { params = {} } = config || {};
	const api = useApi({ config });

	return (data) => {
		const [route, _params] = fillUrlParameters<Partial<Params>>(key, params);
		const source = axios.CancelToken.source();

		let promise: Promise<AxiosResponse<Response>>;

		// DELETE action do not need data
		if (type === ApiMutationTypeEnum.DELETE) {
			promise = api.delete<Data, AxiosResponse<Response>>(`/${route}`, {
				cancelToken: source.token,
				...config,
				params: _params,
			});
		} else {
			let instance: AxiosInstance['post'] | AxiosInstance['put'] | AxiosInstance['patch'];
			switch (type) {
				case ApiMutationTypeEnum.PUT:
					instance = api.put;
					break;
				case ApiMutationTypeEnum.PATCH:
					instance = api.patch;
					break;
				case ApiMutationTypeEnum.POST:
					instance = api.post;
					break;
				default:
					throw new TypeError(`Not supported api mutation type: ${type}`);
			}

			promise = instance<Data, AxiosResponse<Response>>(`/${route}`, data, {
				cancelToken: source.token,
				...config,
				params: _params,
			});
		}

		// Cancel the request if React Query calls the `promise.cancel` method
		// @ts-ignore
		promise.cancel = () => {
			source.cancel('Query was canceled by React Query');
		};

		return promise;
	};
};
