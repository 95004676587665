import { usePrevious } from './usePrevious';
import { isEqual } from 'lodash';

/**
 * Compares new value with previous value.
 *
 * @template P
 * @param {P} val - Comparable value.
 * @param {boolean} deep - Deep comparison
 * @param {boolean} disabled - Disable comparison
 * @returns {boolean} - Same or different.
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const useCompare = <P extends any>(val: P, deep = false, disabled = false) => {
	const prevVal = usePrevious<P | null>(disabled ? null : val);

	if (disabled) {
		return false;
	}

	return deep ? !isEqual(prevVal, val) : prevVal !== val;
};
