import { useDefaultConfig } from 'submodule/widgets/hooks/useDefaultConfig';
import { TWidgetGrid } from 'submodule/widgets';
import { AffiliateConfigEnum } from 'module/partners/enums';
import { useAffiliateConfig } from 'module/partners/hooks/useAffiliateConfig';
import { widgetConfigNormalizer } from 'submodule/widgets/normalizer';
import { useWidgetConfigValidator } from 'submodule/widgets/hooks/useWidgetConfigValidator';
import { solveWidgets } from 'submodule/widgets/solveWidgetRows';
import { useAuthContext } from 'js/contexts';

type TUseLoadUserWidgetConfig = {
	config: TWidgetGrid;
	loading: boolean;
};

export const useUserWidgetConfig = (): TUseLoadUserWidgetConfig => {
	const defaultConfig = useDefaultConfig();
	const { authPartnerId } = useAuthContext();
	const validator = useWidgetConfigValidator();

	const { data, loading } = useAffiliateConfig<string, TWidgetGrid>({
		affiliateId: authPartnerId!,
		name: AffiliateConfigEnum.HOME_WIDGET,
		defaultValue: defaultConfig,
	});

	const normalized = widgetConfigNormalizer.normalize(data?.value, validator);
	return {
		config: solveWidgets.appendLastEmptyRow(normalized || defaultConfig),
		loading,
	};
};
