import { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/zh-CN');
const locale: TLocale = 'zh-CN';

export const zh_CN: ILocaleConfig = {
	name: 'Chinese (China)',
	locale,
	language: 'zh',
	format: {
		numbro,
		datetime: locale,
	},
};
