export default {
	common: {
		allPartnerType: 'Tous les partenaires',
		avastId: 'ID de partenaire Avast',
		loadingDetail: 'Chargement des détails du partenaire',
	},
	error: {
		unableToLoadTerms: 'Impossible de charger les Conditions générales.',
		unableToLoadPartnerDetail: 'Impossible de charger les détails du partenaire',
	},
	entity: {
		accountType: 'Type',
		accountSubType: 'Sous-type',
		company: 'Entreprise',
		email: 'E-mail',
		id: 'ID de partenaire',
		salesforceId: 'ID Salesforce',
		name: 'Nom complet',
	},
	select: {
		title: 'Sélectionner un partenaire',
		button: {
			label: 'Partenaire',
			placeholder: 'Sélectionner le partenaire',
		},
		filter: {
			placeholder: 'Rechercher un partenaire',
		},
	},
};
