import React, { PropsWithChildren, ReactElement, useContext, useState } from 'react';
import { RouteName } from 'module/RouteName';
import { IRegistrationData } from 'module/registration';
import { getRegistrationData } from 'module/registration/utils/common';
import { Navigate } from 'react-router-dom';
import { RegistrationStepEnum } from 'module/registration/enums';
import { useAuthContext } from 'js/contexts';

export interface IRegistrationContext {
	partnerId: number;
	partner: IRegistrationData;
	step: RegistrationStepEnum;
	setStep: (step: RegistrationStepEnum) => void;
	isTaxExemptEligible?: boolean;
	gcShopperId?: string;
}

/**
 * Create context
 * @type {React.Context<IRegistrationContext>}
 */
const RegistrationContext = React.createContext<IRegistrationContext>({} as IRegistrationContext);
RegistrationContext.displayName = 'RegistrationContext';

export const useRegistrationContext = () => useContext(RegistrationContext);

export const RegistrationContextProvider = ({ children }: PropsWithChildren<{}>): ReactElement => {
	const { authPartner } = useAuthContext();
	const [step, setStep] = useState<RegistrationStepEnum>(RegistrationStepEnum.INIT);

	if (!authPartner?.id) {
		return (
			<Navigate
				to={RouteName.SECURITY.LOGOUT}
				replace
			/>
		);
	}

	if (!authPartner.canRegister || authPartner.isRegistered) {
		return (
			<Navigate
				to={RouteName.HOMEPAGE.DEFAULT}
				replace
			/>
		);
	}

	const value: IRegistrationContext = {
		partnerId: authPartner.id,
		partner: getRegistrationData(authPartner),
		isTaxExemptEligible: authPartner.isTaxExemptEligible,
		gcShopperId: authPartner.identifiers?.gcShopperId,
		step,
		setStep,
	};

	return <RegistrationContext.Provider value={value}>{children}</RegistrationContext.Provider>;
};
