import localForage from 'localforage';
import Observable from 'zen-observable';
import { extendPrototype } from 'localforage-observable';
import { CONFIG } from 'config';
import { logDebug, logError } from 'js/utils/app';
import semver from 'semver/preload';

const localforage = extendPrototype(localForage);

// Set observable function
// @ts-ignore
localforage.newObservable.factory = (subscribeFn) => new Observable(subscribeFn);

/**
 * Create instance of localForage for app cache
 */
export const cacheInstance = localforage.createInstance({ name: 'oms' });

export const getCacheVersion = () => cacheInstance.getItem<string>('version');

// Allow cross tab notification for cache instance
cacheInstance
	.ready()
	.then(async () => {
		cacheInstance.configObservables({
			crossTabNotification: true,
			crossTabChangeDetection: true,
		});

		// Update app version
		const version = await getCacheVersion();
		const versionsDiff = version ? semver.diff(version, CONFIG.VERSION) : null;
		if (versionsDiff) {
			logDebug('change cache version', version, 'to', CONFIG.VERSION);

			if (versionsDiff === 'major') {
				await cacheInstance.clear();
				await cacheInstance.setItem('version', CONFIG.VERSION);
				window.location.reload();
				return;
			}
		}

		await cacheInstance.setItem('version', CONFIG.VERSION);
	})
	.catch((e) => logError(e)); // to avoid using logError before initialization, use anonymous function
