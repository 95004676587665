import React from 'react';
import type { TButtonProps } from '@avast/react-ui-components';
import { Button } from '@avast/react-ui-components';
import { Link } from 'react-router-dom';
import { NavigateLink } from 'types';

export type TLinkButtonProps<State = object> = Omit<TButtonProps, 'to' | 'state' | 'disabled'> & NavigateLink<State>;

/**
 * Button what act as a link
 * @template State
 * @param {TLinkButtonProps<State>} props
 * @returns {any}
 */
export const LinkButton = <State extends object = {}>(props: TLinkButtonProps<State>) => (
	<Button
		// @ts-ignore
		as={Link}
		{...props}
	/>
);
