import type { TUiTableRow } from '@avast/react-ui-components';
import { get } from 'lodash';
import { numberFormatter } from 'js/utils/number';
import classNames from 'classnames';
import React from 'react';
import type { ColumnDefBase } from '@tanstack/react-table';

type TSumNumberFooterOptions<Row extends TUiTableRow> = {
	currency?: string;
	key?: string;
	isValidRow?: (row: Row) => boolean;
	className?: string;
};

export const sumNumberFooter = <Row extends TUiTableRow>(
	options: TSumNumberFooterOptions<Row>,
): ColumnDefBase<Row>['footer'] => {
	const fn: ColumnDefBase<Row>['footer'] = (props) => {
		const { table, column } = props;
		const { rows } = table.getCoreRowModel();
		const columnKey = options.key || column.id;

		const total = rows.reduce((sum, row) => {
			if (options.isValidRow?.(row.original) === false) {
				return sum;
			}

			return (get(row.original, columnKey, 0) as number) + sum;
		}, 0);
		const value = options.currency ? numberFormatter.currency(total, options.currency) : numberFormatter.format(total);

		return <span className={classNames('text-nowrap', options.className)}>{value}</span>;
	};

	return fn;
};
