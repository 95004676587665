export default {
	actions: {
		generate: '產生零售代碼',
		downloadLicenses: '下載要求授權',
		refreshListItems: '重新整理項目',
	},
	page: {
		list: '大量 ID 查詢',
		licenses: '零售代碼查詢',
	},
	entity: {
		bulkId: '大量 ID',
		transactionDate: '要求的日期',
	},
	filter: {
		bulkId: {
			placeholder: '輸入大量 ID 並前往...',
		},
	},
	generate: {
		submit: '提交要求',
		submitLoading: '正在儲存要求...',
		success: '已成功建立要求',
		partner: {
			headline: '合作夥伴',
			select: '選取合作夥伴',
			change: '變更合作夥伴',
			placeholder: '選取零售合作夥伴',
		},
		cart: {
			headline: '購物車',
			add: '新增產品',
			removeTooltip: '從購物車中移除此商品項目',
			placeholder: '新增產品到購物車',
		},
		products: {
			headline: '選取產品',
			addAndClose: '新增並關閉',
			success: '產品已新增到購物車。',
		},
	},
};
