import { LicenseOperationEnum } from 'module/licenses/enums';

// Quantity for license operation is always 1, for both Business and Consumer products
export const LICENSE_OPERATION_QUANTITY = 1;

export const licenseOperationKeyOrder: Record<LicenseOperationEnum, number> = {
	[LicenseOperationEnum.NEW]: 0,
	[LicenseOperationEnum.RENEWAL]: 1,
	[LicenseOperationEnum.INCREASE]: 2,
	[LicenseOperationEnum.INCREASE_UPGRADE]: 3,
	[LicenseOperationEnum.RENEWAL_UPGRADE]: 4,
	[LicenseOperationEnum.RENEWAL_INCREASE]: 5,
	[LicenseOperationEnum.RENEWAL_DECREASE]: 6,
	[LicenseOperationEnum.RENEWAL_DOWNGRADE]: 7,
	[LicenseOperationEnum.UPGRADE]: 8,
};
