import { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/en-AU');
const locale: TLocale = 'en-AU';

export const en_AU: ILocaleConfig = {
	name: 'English (Australia)',
	locale,
	language: 'en',
	format: {
		numbro,
		datetime: locale,
	},
};
