import React from 'react';
import { Modal, Toast } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import { ITaxExemption, ITaxExemptionFormFields } from 'module/account';
import { Formik } from 'formik';
import { useTaxExemptionFormValidator } from 'module/account/utils/validator';
import { TaxExemptionForm } from 'module/account/forms/TaxExemptionForm';
import { SubmitButton } from 'js/components/atoms/Button';
import { FormikForm } from 'js/components/formik/FormikForm';
import { sendTaxExemptionFormApiError } from 'module/registration/utils/apiError';
import { registrationConfig } from 'module/registration/registrationConfig';
import { TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { toast } from 'react-toastify';
import { useApiUploadTaxExemption } from 'module/account/hooks/useApiAccount';
import { STATUS_SUCCESS } from 'appConstants';
import { taxExemptionFormNormalizer } from 'module/account/normalizer';
import { useApiErrorContext, useAppContext, useAuthContext } from 'js/contexts';

export type TAsyncTaxExemptionModalProps = {
	currentTaxExemption?: ITaxExemption | null;
};

export const AsyncTaxExemptionModal = (props: TAsyncTaxExemptionModalProps & TAsyncModalContainerProps) => {
	const { currentTaxExemption, forwardedRef } = props;
	const { authCompany } = useAuthContext();
	const [t] = useTranslation(accountConfig.trNamespace);
	const { validate } = useTaxExemptionFormValidator();
	const { mutateAsync: uploadTaxExemption } = useApiUploadTaxExemption({
		config: { params: { partnerId: authCompany?.id } },
	});
	const { setError } = useApiErrorContext();
	const { loadingModalRef } = useAppContext();

	return (
		<>
			<Modal.Header>{t('form.taxExemption.title')}</Modal.Header>
			<Formik<ITaxExemptionFormFields>
				enableReinitialize
				initialValues={{
					companyName: currentTaxExemption?.companyName || authCompany?.companyName || '',
					taxAuthority: currentTaxExemption?.taxAuthority || '',
					startDate: null,
					endDate: null,
					file: null,
				}}
				onSubmit={async (values, { setSubmitting }) => {
					loadingModalRef.current?.show({ title: t(registrationConfig.trPrefix('common.submitting')) });
					const formData = taxExemptionFormNormalizer.denormalize(values);
					const result = await uploadTaxExemption(formData).catch((error) => {
						setError({
							error,
							resolve: sendTaxExemptionFormApiError,
						});
					});

					loadingModalRef.current?.hide();
					setSubmitting(false);

					if (result) {
						toast.success(<Toast>{t('form.taxExemption.success')}</Toast>);
						forwardedRef.current?.onSuccess(STATUS_SUCCESS);
					}
				}}
				validate={validate}
			>
				<FormikForm encType="multipart/form-data">
					<Modal.Body className="pb-2">
						<TaxExemptionForm countryCode={authCompany?.countryCode} />
						<hr />
					</Modal.Body>
					<Modal.Footer className="justify-content-between">
						<SubmitButton testId="submit">{t('common:actions.submitForm')}</SubmitButton>
					</Modal.Footer>
				</FormikForm>
			</Formik>
		</>
	);
};
