import React from 'react';
import { IPricingPromotionDetail } from 'module/purchase';
import { numberFormatter } from 'js/utils/number';
import { useTranslation } from 'react-i18next';
import { priceCalculatorConfig } from 'module/priceCalculator/priceCalculatorConfig';

type TAppliedPromotionsTextProps = {
	promotions: IPricingPromotionDetail[];
};

export const AppliedPromotionsText = (props: TAppliedPromotionsTextProps) => {
	const { promotions } = props;
	const [t] = useTranslation(priceCalculatorConfig.trNamespace);

	if (promotions.length === 0) {
		return null;
	}

	const promotionsText = promotions.map(
		(promotion) => `${promotion.name} (${numberFormatter.percentage(promotion.rate)})`,
	);
	return (
		<div className="mt-2 fs-md">
			<strong>{t('steps.price.appliedPromotions')}:</strong> {promotionsText.join(', ')}
		</div>
	);
};
