export default {
	actions: {
		detail: 'Visualizza i dettagli delle licenze',
		export: 'Esporta licenze',
		exportDetailed: 'Esportazione dettagliata',
		followingLicense: 'Licenza successiva',
		lastNDays: 'Scadute negli ultimi {{count}} giorni',
		nextNDays: 'In scadenza nei prossimi {{count}} giorni',
		operations: 'Operazioni sulle licenze',
		precedingLicense: 'Licenza precedente',
		registerTo: 'Registra una licenza in...',
		relatedCustomer: 'Visualizza cliente correlato',
		relatedOrder: 'Visualizza ordine correlato',
	},
	error: {
		disallowed: 'Operazioni sulle licenze non consentite per questa licenza.',
		operation: {
			invalidParty: 'Le parti interessate alle licenze sono diverse dalle attuali parti interessate agli ordini',
			alreadyInCart: {
				title: 'La licenza è già stata aggiunta al carrello.',
				message:
					'Hai già aggiunto questa licenza al carrello. Desideri sostituirla con l’operazione sulle licenze selezionata?',
			},
			product: {
				noOperationsAvailable: 'Nessuna operazione sulle licenze disponibile.',
			},
			partner: {
				unableToLoad: 'Impossibile caricare il partner licenziatario.',
			},
			customer: {
				unableToLoad: 'Impossibile caricare il cliente licenziatario.',
			},
			distributionPartner: {
				unableToLoad: 'Impossibile caricare il partner di distribuzione licenziatario.',
			},
			actions: {
				rewriteOperation: 'Operazione di sostituzione della licenza',
			},
			billablePartyRequired:
				'La licenza non presenta parti soggette a fatturazione. Vai a “Nuovo acquisto” e seleziona le parti soggette a fatturazione.',
		},
		registration: 'Impossibile registrare la licenza.',
		detailNotFound: 'Dettagli autorizzazione n. {{id}} non trovato',
	},
	common: {
		operation: {
			addToCart: 'L’operazione sulla licenza è stata aggiunta al carrello.',
			goToCart: 'Vai al carrello',
		},
	},
	entity: {
		createdAt: 'Data di creazione',
		customer: {
			contact: 'Contatto cliente',
			id: 'ID cliente',
			email: 'Indirizzo e-mail cliente',
			name: 'Cliente',
		},
		partner: {
			contact: 'Contatto partner',
			id: 'ID partner',
			name: 'di dist.',
		},
		distributionPartner: {
			contact: 'Contatto partner di dist.',
			id: 'ID partner ID partner',
			name: 'Partner di dist.',
		},
		date: 'Data',
		entitlementId: 'ID autorizzazione',
		licenseId: 'ID licenza',
		licenseType: 'Tipo di licenza',
		modifiedAt: 'Data modificata',
		orderId: 'ID ordine',
		product: {
			termLength: 'Durata',
			type: 'Tipo di prodotto',
		},
		registeredAt: 'Data di registrazione',
		status: 'Stato della licenza',
		statusShort: 'Stato',
		statusPlaceholder: 'Qualsiasi stato',
		walletKey: 'Codice di attivazione',
	},
	licenseOperation: {
		label: 'Operazione sulla licenza',
	},
	registration: {
		registering: 'Registrazione licenza',
		success: 'Licenza registrata con successo a nome del cliente: {{customer}}',
		error: 'Si è verificato un errore durante la registrazione della licenza.',
		confirmation: {
			title: 'Registrazione licenza',
			place: 'Associare questo cliente a questa licenza?',
			replace: 'La licenza n. {{licenseId}} è già registrata. Procedere con la registrazione della licenza?',
		},
	},
	selectParty: {
		partner: {
			title: 'Seleziona il partner per l’ordine',
			billableParty: 'Il partner sarà la parte soggetta a fatturazione.',
			requiredBillableParty: 'Non può esistere un ordine senza un partner.',
			emptyBillableParty: 'Nel prossimo passaggio, il cliente verrà selezionato come parte soggetta a fatturazione.',
			license: {
				title: 'Partner licenziatario',
				button: 'Usa partner licenziatario',
				buttonEmpty: 'Rimuovi il partner dall’ordine',
				empty: 'Nessun partner associato alla licenza.',
			},
			order: {
				title: 'Partner ordine',
				button: 'Mantieni partner ordine',
				empty: 'Nessun partner associato all’ordine.',
				notAllowed: 'Il partner non può eseguire operazioni relative alle licenze per questo gruppo di prodotti.',
			},
		},
		customer: {
			title: 'Seleziona il cliente per l’ordine',
			billableParty: 'Il cliente sarà la parte soggetta a fatturazione.',
			requiredBillableParty: 'Non può esistere un ordine senza un cliente.',
			emptyBillableParty: 'La parte soggetta a fatturazione sarà un partner.',
			license: {
				title: 'Cliente licenziatario',
				button: 'Usa cliente licenziatario',
				buttonEmpty: 'Rimuovi il cliente dall’ordine',
				empty: 'Nessun cliente associato alla licenza.',
			},
			order: {
				title: 'Cliente ordine',
				button: 'Mantieni cliente ordine',
				empty: 'Nessun cliente associato all’ordine.',
			},
		},
		distributionPartner: {
			title: 'Seleziona il partner di distribuzione per l’ordine',
			license: {
				title: 'Partner di distribuzione licenziatario',
				button: 'Usa partner di dist. licenziatario',
				buttonEmpty: 'Rimuovi partner di dist. dall’ordine',
				empty: 'Nessun partner di distribuzione associato alla licenza.',
				notAllowed: 'Impossibile assegnare un partner di distribuzione al partner selezionato.',
			},
			order: {
				title: 'Partner di distribuzione ordine',
				button: 'Mantieni partner di dist. ordine',
				empty: 'Nessun partner di distribuzione associato all’ordine.',
				notAllowed: 'Impossibile assegnare un partner di distribuzione al partner selezionato.',
			},
		},
	},
};
