export default {
	common: {
		continueToProducts: 'Weiter zu den Produkten',
		orderValue: 'Bestellwert',
		remaining: 'Verbleibend',
		partyPrefix: {
			your: 'Ihr',
			partner: 'Partner',
			srp: 'SRP',
			customer: 'Kunde',
			quotedCustomer: 'Angebotskunde',
		},
		savingOrder: 'Bestellung wird gespeichert...',
		exportingOrder: 'Bestellung wird exportiert...',
		loadingOrder: 'Bestellung wird geladen...',
		customerCreditStatus: {
			eligible: 'Der Kunde ist kreditwürdig.',
			notEligible: 'Der Kunde ist nicht kreditwürdig.',
		},
		previousLicense: 'Vorheriger Lizenzschlüssel',
		currency: {
			changeTooltip: 'Währung ändern',
			selectPrompt: 'Kundenwährung auswählen',
		},
		paginationText: 'Zeigt <strong>{{from}} bis {{to}}</strong> von {{total}} Elementen an.',
	},
	discretionaryDiscount: {
		title: 'Ermessensrabatt',
		placeholder: 'Rabatt ein',
		approvalFlag: 'Genehmigungsstatus',
		amount: 'Betrag',
		amountUnit: 'Einheitsbetrag',
		amountTotal: 'Gesamtsumme',
		status: {
			notSet: 'Kein Ermessensrabatt in Angebot festgelegt.',
			lineNotSet: 'Kein Ermessensrabatt in Einzelposten festgelegt.',
			orderLevelDiscountIsSet:
				'Für diese Bestellung wurde ein Rabatt auf Bestellungsebene angewendet. Rabatte auf Zeilenebene wurden deaktiviert.',
			lineLevelDiscountIsSet:
				'Für diese Bestellung wurden Rabatte auf Zeilenebene angewendet. Rabatt auf die gesamte Bestellung wurde deaktiviert.',
		},
		action: {
			resetLineDiscounts: 'Zeilenrabatte entfernen',
		},
		waitingApprovalMessage:
			'In der Bestellung ist ein Ermessensrabatt eingetragen, der von der Vertriebsabteilung genehmigt werden muss.',
	},
	error: {
		submitOrder: {
			title: 'Bestellung kann nicht eingereicht werden',
			general:
				'Ein Fehler ist aufgetreten und die Bestellung konnte nicht eingereicht werden, bitte erneut einreichen.',
		},
		pricing: {
			title: 'Preis kann nicht berechnet werden',
		},
		context: {
			setPartnerFailed: 'Produkt konnte der Bestellung nicht hinzugefügt werden.',
		},
		creditExceed: {
			title: 'Diese Transaktion wird das verfügbare Guthaben Ihres Kontos übersteigen.',
			text: 'Für diese Transaktion ist keine Kredit-Zahlungsmethode verfügbar. Bitte verwenden Sie die Option für das Warenkorb-Checkout.',
		},
		requiredCustomer: {
			title: 'Kundeninformation erforderlich',
			text: 'Bitte wählen Sie einen Kunden aus, um Ihren Einkauf abzuschließen.',
			action: 'Kunde auswählen',
			tooltip: {
				question: 'Warum benötigen wir Kundeninformationen für Bestellungen?',
				answer:
					'Wir bitten Sie, bei Bestellungen Kundeninformationen anzugeben, damit wir mehr über die Endkunden erfahren können. Wenn wir diese besser kennen und ihre Gewohnheiten, Bedürfnisse und Vorlieben verstehen, können wir Sie und Ihre Kunden besser bedienen.',
			},
		},
		requiredDistributionPartner: {
			title: 'Angaben zum Vertriebspartner erforderlich',
			text: 'Bitte wählen Sie einen Vertriebspartner, um Ihren Einkauf abzuschließen.',
			action: 'Vertriebspartner wählen',
			tooltip: {
				question: 'Warum benötigen wir für Bestellungen Angaben zu Vertriebspartnern?',
				answer:
					'Wir bitten Sie, beim Aufgeben von Bestellungen beide Ebenen von Kundeninformationen (Vertriebspartner und Kunde des Vertriebspartners) anzugeben, damit wir diese besser kennenlernen und ihre Gewohnheiten, Bedürfnisse und Vorlieben verstehen können, was es uns ermöglicht, Sie und die Kunden besser zu bedienen.',
			},
		},
	},
	components: {
		successOrder: {
			title: 'Erfolg!',
			action: {
				NEW_ORDER: 'Neue Bestellung',
				DETAIL: 'Einzelheiten zur Bestellung',
				QUOTE_DETAIL: 'Einzelheiten zum Angebot',
				LICENSES: 'Lizenzen anzeigen',
				EDIT: 'Bestellung bearbeiten',
				QUOTE_EDIT: 'Angebot bearbeiten',
				PAYMENT_INSTRUCTIONS: 'Zahlungsanweisungen',
			},
		},
		editPrices: {
			title: 'Einzelpreise bearbeiten',
			message:
				'Sie können nur den Einzelpreis für die Artikel bearbeiten, die sich derzeit im Warenkorb befinden. Von diesem Bildschirm aus gespeicherte Bestellungen können nicht geändert werden. Bitte vergewissern Sie sich, dass Ihr Warenkorb vollständig ist, bevor Sie fortfahren.',
			button: 'Preisgestaltung bearbeiten',
			columnLabel: 'Benutzerdefinierter Einzelpreis',
			submit: 'Bestellung speichern',
			confirmation: {
				message:
					'Dadurch erstellen Sie eine Bestellung, die zwar abgeschlossen, aber nicht mehr bearbeitet werden kann. Sind Sie sich da wirklich sicher?',
				success: 'Ja, Bestellung speichern',
			},
		},
		customerQuote: {
			error: {
				invalidItems:
					'Benutzerdefinierte Einzelpreise sind erforderlich zum Speichern dieser Bestellung sowie zur Vorschau und zum Versenden per E-Mail.',
			},
			fields: {
				customerEmail: 'Bis',
				partnerEmail: 'CC',
				message: 'Persönliche Nachricht',
			},
			submit: 'E-Mail senden',
		},
	},
	page: {
		default: {
			title: 'Einzelheiten zur Bestellung',
		},
		products: {
			title: 'Produkte',
		},
		order: {
			title: 'Überprüfen und bestätigen',
		},
	},
	unit: {
		current: 'Aktuell: {{type}} ',
		toIncrease: '{{type}} zusätzlich',
		toDecrease: '{{type}} entfernen',
		total: '{{type}} gesamt',
	},
	entity: {
		privateNotes: 'Privater Hinweis',
		publicNotes: 'Öffentlicher Hinweis',
		opportunityId: 'Geschäftsmöglichkeitsnummer',
		marketSegment: 'Segment',
		validityLabel: 'Für {{value}}',
		validityDescription: 'Dauer der Laufzeit: {{value}}',
	},
	pricing: {
		basePrice: 'Ab {{price}}',
		discount: {
			segment: 'Segmentrabatt',
			renewal: 'Verlängerungsrabatt',
			partner: 'Partnerrabatt',
			discretionary: 'Ermessensrabatt',
			promo: 'Aktionsrabatt',
		},
		details: {
			show: 'Preisdetails anzeigen',
			hide: 'Preisdetails verbergen',
		},
	},
	actions: {
		addNote: 'Hinweis hinzufügen',
		addToCart: 'In den Warenkorb',
		goToCart: 'Zum Warenkorb',
		exportCart: 'Warenkorb exportieren',
	},
	message: {
		addToCart: {
			item: '- <strong>{{name}}</strong> - {{unit}} {{unitLabel}}, {{quantity}}x',
			title: 'Produkt wurde in den Einkaufswagen gelegt',
		},
		removedFromCart: 'Das Produkt wurde aus dem Einkaufswagen entfernt.',
		emptyCart: 'Es wurden alle Produkte aus dem Einkaufswagen entfernt.',
		emptyCartConfirmation: 'Möchten Sie wirklich alle Produkte aus dem Einkaufswagen entfernen?',
	},
	table: {
		action: {
			selectVariant: 'Variante wählen',
		},
		filter: {
			search: {
				placeholder: 'Produkt nach Namen oder SKU suchen',
			},
			marketSegment: '{{type}}-Produkte',
		},
	},
	tableVariant: {
		priceNote: 'Preise beinhalten alle Kundenrabatte',
		tooltip: {
			quantity: 'Menge entspricht der Anzahl der Produktlizenzschlüssel.',
			unitType: {
				SEATS: 'Anzahl der Geräte für eine Lizenz.',
				SESSIONS: 'Anzahl der Sitzungen für eine Lizenz.',
				STORAGE: '',
				DEVICE: '',
			},
		},
	},
	widget: {
		common: {
			billableParty: 'Fakturierbare Partei',
			nonBillableParty: 'Nicht fakturierbare Partei',
		},
		selectCustomerCard: {
			createNew: 'Neuen Kunden erstellen',
			note: 'Kein Kunde in dieser Bestellung.<br/><strong>Lizenzen später registrieren.</strong>',
			select: 'Kunde auswählen',
			title: 'Kunde',
			error: {
				load: 'Kunde konnte nicht geladen werden',
			},
			tooltips: {
				change: 'Kunden ändern',
				update: 'Kunden aktualisieren',
				cancel: 'Kunden aus Bestellung entfernen',
			},
		},
		selectDistributionPartnerCard: {
			createNew: 'Neuen Vertriebspartner erstellen',
			note: 'Kein Vertriebspartner in dieser Bestellung.',
			select: 'Vertriebspartner wählen',
			title: 'Vertriebspartner',
			error: {
				load: 'Vertriebspartner konnte nicht geladen werden',
			},
			tooltips: {
				change: 'Vertriebspartner ändern',
				update: 'Vertriebspartner aktualisieren',
				cancel: 'Vertriebspartner aus der Bestellung entfernen',
			},
		},
		selectPartnerCard: {
			note: 'Partner wählen, um Endpreise nach Rabatt anzuzeigen.',
			select: 'Partner wählen',
			title: 'Avast-Partner',
			error: {
				load: 'Partner konnte nicht geladen werden',
			},
			tooltips: {
				change: 'Avast-Partner ändern',
				cancel: 'Avast-Partner aus der Bestellung entfernen',
			},
		},
	},
	paymentType: {
		SAVE: {
			action: 'Speichern',
			caption: 'Für später speichern',
			button: 'Für später speichern',
			successMessage: 'Ihre Bestellung wurde gespeichert.',
			confirmationCaption: 'Diese Bestellung speichern?',
		},
		QUOTE: {
			action: 'Absenden',
			caption: 'Als Angebot senden',
			button: 'Als Angebot senden',
			successMessage: 'Ihr Angebot befindet sich in der Warteschlange für die Übermittlung.',
			confirmationCaption: 'Dieses Angebot senden?',
		},
		END_CUSTOMER_QUOTE: {
			action: 'Absenden',
			caption: 'Als Angebot senden',
			button: 'Als Angebot senden',
			successMessage: 'Ihr Angebot befindet sich in der Warteschlange für die Übermittlung.',
			confirmationCaption: 'Bestellung bereit zum Aufgeben?',
		},
		CUSTOMER_QUOTE: {
			action: 'Absenden',
			caption: 'Preisangebot an Kunden senden.',
			button: 'Preisangebot an Kunden senden.',
			successMessage: 'Ihr Angebot befindet sich in der Warteschlange für die Übermittlung.',
			confirmationCaption: 'Möchten Sie wirklich die E-Mail mit dem Angebot für den Kundenpreis senden?',
			confirmationMessage:
				'Alle Einzelpreise für Kunden werden in der Bestellung gespeichert und eine E-Mail wird an den Kunden gesendet.<br/>Diese Aktion kann nicht rückgängig gemacht werden.',
		},
		GLOBAL_COMMERCE_CART_CHECKOUT: {
			action: 'Absenden',
			caption: 'Kartenzahlung',
			button: 'Kartenzahlung',
			successMessage: 'Ihre Bestellung wurde erfolgreich aufgegeben.',
			confirmationCaption: 'Bestellung bereit zum Aufgeben?',
		},
		GLOBAL_COMMERCE_CLOSING_BALANCE: {
			action: 'Absenden',
			caption: 'Abschlusssaldo',
			button: 'Abschlusssaldo einreichen',
			successMessage: 'Ihre Bestellung wurde erfolgreich aufgegeben.',
			confirmationCaption: 'Bestellung bereit zum Aufgeben?',
		},
		GLOBAL_COMMERCE_LINE_OF_CREDIT: {
			action: 'Absenden',
			caption: 'Zahlung auf Rechnung',
			button: 'Zahlung auf Rechnung',
			successMessage: 'Ihre Bestellung wurde erfolgreich aufgegeben.',
			confirmationCaption: 'Bestellung bereit zum Aufgeben?',
		},
		ORACLE_CART_CHECKOUT: {
			action: 'Absenden',
			caption: 'Zahlung mit Kreditkarte',
			button: 'Zahlung mit Kreditkarte',
			successMessage: 'Ihre Bestellung wurde bezahlt.',
			confirmationCaption: 'Bestellung bereit zum Aufgeben?',
		},
		ORACLE_LINE_OF_CREDIT: {
			action: 'Absenden',
			caption: 'Zahlung auf Rechnung',
			button: 'Zahlung auf Rechnung',
			successMessage: 'Ihre Bestellung wurde erfolgreich aufgegeben.',
			confirmationCaption: 'Bestellung bereit zum Aufgeben?',
		},
		ORACLE_CLOSING_BALANCE: {
			action: 'Absenden',
			caption: 'Abschlusssaldo',
			button: 'Abschlusssaldo einreichen',
			successMessage: 'Ihre Bestellung wurde erfolgreich aufgegeben.',
			confirmationCaption: 'Bestellung bereit zum Aufgeben?',
		},
	},
	guards: {
		partnerPriceListGuard: {
			title: 'Partnerbestätigung ändern',
			message:
				'Der ausgewählte Partner verfügt nicht über dieselben zulässigen Preislisten wie der aktuelle Partner. Wenn Sie fortfahren, wird der Einkaufswagen geleert.',
		},
		partnerTermsGuard: {
			title: 'Der Partner kann keine Bestellung aufgeben',
			message: 'Der ausgewählte Partner hat die Allgemeinen Geschäftsbedingungen nicht akzeptiert.',
		},
		priceListGuard: {
			title: 'Produktpreislisten vermischt',
			messageCaption:
				'Bitte beachten Sie, dass wir CloudCare- und OMS-Produkte nicht in derselben Bestellung kombinieren können. Sind Sie sicher, dass Sie dieses Produkt in den Einkaufswagen legen möchten?',
			message:
				'Das/die Produkt(e), das/die Sie zuvor hinzugefügt haben, wird/werden automatisch entfernt und dieser Artikel wird stattdessen in den Einkaufswagen gelegt.',
		},
		maxCartItemsGuard: {
			title: 'Maximale Anzahl von Einzelposten überschritten',
			message:
				'Eine Bestellung kann maximal {{count}} Einzelposten enthalten. Bitte entfernen Sie einige der Posten, um fortzufahren.',
			message_other:
				'Eine Bestellung kann maximal {{count}} Einzelposten enthalten. Bitte entfernen Sie einige der Posten, um fortzufahren.',
		},
	},
	priceList: {
		mixWarning:
			'Bitte beachten Sie, dass wir CloudCare- und OMS-Produkte aufgrund unterschiedlicher Preise und Rabatte für die einzelnen Produkte nicht in derselben Bestellung kombinieren können.',
		code: {
			PREPAID_OMS: 'OMS-Produkte',
			PREPAID_CC_LEGACY: 'CloudCare-Produkte',
			PREPAID_CC_MSRP: 'CloudCare-Produkte',
		},
	},
	tooltips: {
		remove: 'Dieses Element aus dem Einkaufswagen entfernen',
		emptyCart: 'Alle Elemente aus dem Einkaufswagen entfernen',
	},
};
