export default {
	actions: {
		detail: 'ライセンスの詳細を表示',
		export: 'ライセンスをエクスポート',
		exportDetailed: '詳細なエクスポート',
		followingLicense: '次のライセンス',
		lastNDays: '過去 {{count}} 日以内に期限切れ',
		nextNDays: '今後 {{count}} 日以内に期限切れ',
		operations: 'ライセンス処理',
		precedingLicense: '前のライセンス',
		registerTo: 'ライセンス登録先...',
		relatedCustomer: '関連する顧客を表示',
		relatedOrder: '関連する注文を表示',
	},
	error: {
		disallowed: 'このライセンスの処理は許可されていません。',
		operation: {
			invalidParty: 'ライセンスの当事者が現在の注文の当事者と異なります',
			alreadyInCart: {
				title: 'このライセンスはすでにかごに追加されています。',
				message: 'このライセンスはすでにかごに追加されています。現在の処理を選択したライセンス処理に置き換えますか？',
			},
			product: {
				noOperationsAvailable: '利用可能なライセンス処理がありません。',
			},
			partner: {
				unableToLoad: 'このライセンスの当事者の読み込みに失敗しました。',
			},
			customer: {
				unableToLoad: 'このライセンスの顧客の読み込みに失敗しました。',
			},
			distributionPartner: {
				unableToLoad: 'このライセンスの販売パートナーの読み込みに失敗しました。',
			},
			actions: {
				rewriteOperation: 'ライセンス処理を置き換え',
			},
			billablePartyRequired:
				'このライセンスには請求可能な当事者が指定されていません。［新しい購入］に移動して請求可能な当事者を選択してください。',
		},
		registration: 'ライセンスを登録できません。',
		detailNotFound: 'エンタイトルメントの詳細 #{{id}} が見つかりません',
	},
	common: {
		operation: {
			addToCart: 'このライセンス処理をかごに追加しました。',
			goToCart: 'かごに移動',
		},
	},
	entity: {
		createdAt: '作成日',
		customer: {
			contact: '顧客の連絡先',
			id: '顧客 ID',
			email: '顧客のメール アドレス',
			name: '顧客',
		},
		partner: {
			contact: 'パートナーの連絡先',
			id: 'パートナー ID',
			name: 'パートナー',
		},
		distributionPartner: {
			contact: '販売パートナーの連絡先',
			id: '販売パートナー ID',
			name: '販売パートナー',
		},
		date: '日付',
		entitlementId: 'エンタイトルメント ID',
		licenseId: 'ライセンス ID',
		licenseType: 'ライセンスの種類',
		modifiedAt: '変更日',
		orderId: '注文 ID',
		product: {
			termLength: '条件の長さ',
			type: '製品の種類',
		},
		registeredAt: '登録日',
		status: 'ライセンスのステータス',
		statusShort: 'ステータス',
		statusPlaceholder: 'すべてのステータス',
		walletKey: 'ウォレット キー',
	},
	licenseOperation: {
		label: 'ライセンス処理',
	},
	registration: {
		registering: 'ライセンスを登録中',
		success: 'ライセンスは正常に顧客 {{customer}} に登録されました',
		error: 'ライセンスの登録中にエラーが発生しました。',
		confirmation: {
			title: 'ライセンスの登録',
			place: 'この顧客をこのライセンスに関連付けますか？',
			replace: 'ライセンス #{{licenseId}} はすでに登録されています。ライセンスの登録を続行しますか？',
		},
	},
	selectParty: {
		partner: {
			title: '注文のパートナーを選択',
			billableParty: 'このパートナーが請求可能な当事者になります。',
			requiredBillableParty: 'パートナーを指定せずに注文を使用することはできません。',
			emptyBillableParty: '次のステップでは、顧客が請求可能な当事者として選択されます。',
			license: {
				title: 'ライセンス パートナー',
				button: 'ライセンス パートナーを使用',
				buttonEmpty: 'パートナーを注文から削除',
				empty: 'ライセンスにパートナーが指定されていません。',
			},
			order: {
				title: '注文パートナー',
				button: '注文パートナーを維持',
				empty: '注文にパートナーが指定されていません。',
				notAllowed: 'このパートナーはこの製品グループに対するライセンス処理を実行できません。',
			},
		},
		customer: {
			title: '注文の顧客を選択',
			billableParty: 'この顧客が請求可能な当事者になります。',
			requiredBillableParty: '顧客を指定せずに注文を使用することはできません。',
			emptyBillableParty: 'パートナーが請求可能な当事者になります。',
			license: {
				title: 'ライセンスの顧客',
				button: 'ライセンスの顧客を使用',
				buttonEmpty: '顧客を注文から削除',
				empty: 'ライセンスに顧客が指定されていません。',
			},
			order: {
				title: '注文の顧客',
				button: '注文の顧客を維持',
				empty: '注文に顧客が指定されていません。',
			},
		},
		distributionPartner: {
			title: '注文の販売パートナーを選択',
			license: {
				title: 'ライセンスの販売パートナー',
				button: 'ライセンスの販売パートナーを使用',
				buttonEmpty: '販売パートナーを注文から削除',
				empty: 'ライセンスに販売パートナーが指定されていません。',
				notAllowed: '選択したパートナーに販売パートナーを割り当てることはできません。',
			},
			order: {
				title: '注文の販売パートナー',
				button: '注文の販売パートナーを維持',
				empty: '注文に販売パートナーが指定されていません。',
				notAllowed: '選択したパートナーに販売パートナーを割り当てることはできません。',
			},
		},
	},
};
