import { TableColumnsDef } from 'types';
import { IEntityLicense } from 'module/licenses';
import { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { getBaseLicensesTableColumns } from 'module/licenses/utils/common';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';

type TOrderLicenseColumnsProps = {
	isRowSelectable?: boolean;
};

type TTableData = IEntityLicense;

export const useOrderLicensesColumns = (props: TOrderLicenseColumnsProps): TableColumnsDef<TTableData> => {
	const { isRowSelectable } = props;
	const [t] = useTranslation(licensesConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const columns: TableColumnsDef<TTableData> = [];
		if (isRowSelectable) {
			columns.push(columnHelper.select());
		}

		columns.push(
			...getBaseLicensesTableColumns(t, true),
			columnHelper.actions({
				iconLink: {
					icon: faArrowRight,
					to: (row) => buildParametrizedRoute(RouteName.LICENSE.DETAIL, row.id),
					title: t('common:actions.detail'),
					testId: 'detail',
				},
			}),
		);

		return columns;
	}, [t, isRowSelectable]);
};
