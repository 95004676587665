export default {
	dateRangeType: {
		BILLING: 'Abrechnungsdatum',
		CREATED: 'Erstellt am',
		PAYMENT: 'Zahlungstermin',
	},
	tierCertification: {
		DISTRIBUTORS: 'Vertriebshändler',
		TIER_4: 'Stufe 4',
		TIER_3: 'Stufe 3',
		TIER_2: 'Stufe 2',
		TIER_1: 'Stufe 1',
	},
	customer: {
		type: {
			BUSINESS: 'Unternehmen',
			CONSUMER: 'Privatanwender',
		},
		discountType: {
			EDUCATION: {
				name: 'Bildung',
				description:
					'Bildungseinrichtung mit zusätzlichem Rabatt; zur Anwendung dieses Rabatts auf Ihre Bestellung wenden Sie sich bitte an Ihren Vertriebsmanager.',
			},
			GOVERNMENT: {
				name: 'Behörde',
				description:
					'Staatliche Einrichtung mit zusätzlichem Rabatt; zur Anwendung dieses Rabatts auf Ihre Bestellung wenden Sie sich bitte an Ihren Vertriebsmanager.',
			},
			NOT_FOR_PROFIT: {
				name: 'Für nicht-kommerzielle Zwecke',
				description:
					'Nicht-kommerzielle Einrichtung mit zusätzlichem Rabatt; zur Anwendung dieses Rabatts auf Ihre Bestellung wenden Sie sich bitte an Ihren Vertriebsmanager.',
			},
		},
		searchKey: {
			EMAIL: 'E-Mail',
			SALESFORCE_ID: 'SalesForce-ID',
		},
	},
	partner: {
		type: {
			DISTRIBUTOR: 'Vertriebshändler',
			RESELLER: 'Händler',
			INTERNAL_COMPANY: 'Internes Unternehmen',
			USER: 'Benutzer',
		},
		subType: {
			RETAIL: 'Einzelhandel',
			ISP: 'ISP',
			MSP: 'MSP',
			NONE: 'Keine',
		},
		terms: {
			DR: 'Mitgliedschaftsvereinbarung für Digital River Absatzpartner',
			CLIENT: 'Partnervereinbarung:',
		},
		payAsYouGoPaymentMethod: {
			LINE_OF_CREDIT: {
				name: 'LOC',
				description:
					'Eine Rechnung wird kurz nach Monatsende erstellt. Es gelten Ihre üblichen Zahlungsbedingungen. Eine LOC-Rechnung kann nur mit einer Banküberweisung bezahlt werden. ',
			},
			AUTO_BILLING: {
				name: 'Automatische Rechnungsstellung',
				description:
					'Ihre gespeicherte Karte wird kurz nach Ende des jeweiligen Monats automatisch belastet. Sollte dieser Vorgang fehlschlagen, wird eine normale LOC-Rechnung generiert.',
			},
		},
	},
	order: {
		discountCategory: {
			NONE: 'Keine',
			DISCRETIONARY: 'Ermessen',
		},
		lineItemDiscountType: {
			STANDARD: 'Standard',
			SPECIAL: 'Spezial',
			NONE: 'Keine',
		},
		approvalFlag: {
			NA: 'NA',
			APPROVAL_NOT_REQUIRED: 'Keine Genehmigung erforderlich',
			APPROVAL_REQUIRED: 'Genehmigung erforderlich',
			PENDING_APPROVAL: 'Genehmigung ausstehend',
			APPROVED: 'Genehmigt',
			REJECTED: 'Abgelehnt',
		},
		billablePartyType: {
			PARTNER: 'Partner',
			DISTRIBUTOR: 'Vertriebshändler',
			END_CUSTOMER: 'Endkunde',
		},
		searchKey: {
			COMMERCE_ORDER_ID: 'Fakturierbare Bestell-ID',
			OPPORTUNITY_ID: 'Geschäftsmöglichkeits-ID',
			ORDER_NUMBER: 'Bestellnummer',
			PO_NUMBER: 'PO-Nummer',
			QUOTE_NUMBER: 'Angebotsnummer',
		},
		status: {
			SAVED_IN_PROGRESS: {
				name: 'Gespeichert in Bearbeitung',
				description:
					'Die Bestellung wurde gespeichert, aber nicht gesendet. Nur Aufträge mit diesem Status können geändert werden.',
			},
			ISSUED_AS_CUSTOMER_QUOTE: {
				name: 'Ausgestellt als Kundenangebot',
				description:
					'Die Bestellung ist ein an den Kunden gesendetes Angebot. Sie kann vom Kunden nicht bearbeitet, sondern nur übermittelt werden.',
			},
			ISSUED_AS_QUOTE: {
				name: 'Ausgestellt als Angebot',
				description:
					'Die Bestellung ist ein Angebot von Avast. Sie kann nicht bearbeitet, sondern nur übermittelt werden.',
			},
			POSTED_TO_STATEMENT: {
				name: 'Gebucht in Abrechnung',
				description: 'Die Bestellung wurde in einer monatlichen Abrechnung gebucht.',
			},
			PENDING_SUBMISSION: {
				name: 'Übermittlung ausstehend',
				description: '',
			},
			IN_REVIEW: {
				name: 'In Prüfung',
				description: 'Die Bestellung steht zur Überprüfung an. Sie kann nicht bearbeitet werden.',
			},
			SUBMITTED_TO_COMMERCE: {
				name: 'An Commerce gesendet',
				description: 'Die Bestellung wurde zur Abrechnung gesendet.',
			},
			PAYMENT_ERROR: {
				name: 'Zahlungsfehler',
				description:
					'Ein Zahlungsfehler ist aufgetreten. Die Bestellung muss nach Verifizierung der korrekten und aktuellen Zahlungsinformationen erneut eingereicht werden.',
			},
			OTHER_ERROR: {
				name: 'Anderer Fehler',
				description:
					'Es ist ein Systemfehler aufgetreten, der auch nach 3 Wiederholungsversuchen anhält. Sie müssen die Bestellung für einen weiteren Versuch erneut einreichen oder den Support kontaktieren.',
			},
			WAITING_PAYMENT: {
				name: 'Zahlung ausstehend',
				description: 'Die Bestellung wurde zur Abrechnung eingereicht. Die Zahlung ist fällig.',
			},
			COMPLETE: {
				name: 'Umfassend',
				description: 'Die Zahlung wurde eingezogen und die Bestellung ist abgeschlossen.',
			},
			CANCELED_BY_USER: {
				name: 'Vom Benutzer storniert',
				description: 'Ein Benutzer hat sich entschieden, die Bestellung zu stornieren.',
			},
			CANCELED_FOR_FAILURE_TO_PAY: {
				name: 'Storniert wegen Nichtbezahlung',
				description: 'Die Bestellung wurde storniert, weil sie nicht vollständig bezahlt wurde.',
			},
			EXPIRED: {
				name: 'Abgelaufen',
				description: 'Die Bestellung wurde für 30 Tage nach Ihrer Erstellung nicht geändert.',
			},
			NOT_SUBMITTED: {
				name: 'Nicht gesendet',
				description: 'Die Bestellung wurde nicht zur Abrechnung eingereicht.',
			},
		},
	},
	payment: {
		status: {
			PAID: {
				name: 'Bezahlt',
				description: 'Die Bestellung wurde erfolgreich verarbeitet und die Zahlung ist eingegangen.',
			},
			UNPAID: {
				name: 'Unbezahlt',
				description: 'Die Bezahlung für die Bestellung steht noch aus oder ist nicht eingegangen.',
			},
			PARTIAL_PAID: {
				name: 'Teilweise bezahlt',
				description: 'Für die Bestellung verbleibt ein Restsaldo.',
			},
			NA: {
				name: 'Nicht abgerechnet',
				description: 'Die Bestellung wurde noch nicht fakturiert bzw. in Rechnung gestellt.',
			},
			UNBILLED: {
				name: 'Nicht abgerechnet',
				description: 'Die Bestellung wurde noch nicht fakturiert bzw. in Rechnung gestellt.',
			},
			SETTLED: {
				name: 'Abgewickelt',
				description: 'Der Auftrag wurde vom Zahlungseinzugsteam bearbeitet und abgeschlossen.',
			},
		},
		type: {
			WIRE_TRANSFER: 'Banküberweisung',
			WTOC: 'WTOC',
			CART_SELECTED: 'Warenkorb ausgewählt',
			CLOSING_BALANCE: 'Abschlusssaldo',
			LOC: 'LOC',
			AUTO_BILLING: 'Automatische Rechnungsstellung',
		},
	},
	licenses: {
		searchKey: {
			LICENSE_KEY: 'Lizenzschlüssel',
			ORDER_NUMBER: 'Bestellnummer',
		},
		status: {
			ACTIVE: {
				name: 'Aktiv',
				description: 'Lizenz wird verwendet',
			},
			INACTIVE: {
				name: 'Inaktiv',
				description: 'Aktivierungscode wurde nicht verwendet',
			},
			CANCELLED: {
				name: 'Storniert',
				description: 'Lizenz wurde gekündigt oder zurückerstattet',
			},
			REPLACED: {
				name: 'Erstattet',
				description:
					'Eine aktive Berechtigung wurde während des Vorgangs durch eine andere aktive Berechtigung ersetzt',
			},
			EXPIRED: {
				name: 'Abgelaufen',
				description: 'Das Ablaufdatum einer aktiven Berechtigung ist verstrichen',
			},
			USED: {
				name: 'Verwendet',
				description: 'Die Lizenz wurde verwendet',
			},
			MIGRATED: {
				name: 'Migriert',
				description: 'Die Lizenz wurde in eine Lizenz für ein Nachfolgeprodukt migriert',
			},
			BLACKLISTED: {
				name: 'Gesperrt',
				description: '',
			},
		},
		datepickerDateType: {
			CREATED_AT: 'Erstellt am',
			EXPIRATION: 'Ablaufdatum',
			REGISTERED_AT: 'Registrierungsdatum',
		},
	},
	closingBalance: {
		searchKey: {
			ORDER_NUMBER: 'Bestellnummer',
		},
	},
	creditStatus: {
		transactionSource: {
			CANCELLED_LICENSE: 'Stornierte Lizenz',
			CANCELLED_ORDER: 'Stornierte Bestellung',
			MSP: 'MSP',
			NEW_ORDER: 'Neue Bestellung',
			OMS_REFUND: 'Rückerstattung',
			PAID_ORDER: 'Bezahlte Bestellung',
			SAP: 'SAP',
			SAP_TAX: 'Steuern',
			OMS_TAX: 'Steuern',
			ORACLE_TAX: 'Steuern',
			ADMIN_CUSTOM: 'Benutzerdefinierte Anpassung',
			PAY_AS_YOU_GO: 'Flexible Abrechnungen',
			TAX_REFUND: 'Steuerrückzahlung',
			PAY_AS_YOU_GO_CONSOLIDATION: 'Flexible Abrechnung – Korrektur',
			SETTLED_ORDER: 'Abgewickelt',
		},
	},
	purchase: {
		marketSegment: {
			BUSINESS: 'Unternehmen',
			CONSUMER: 'Privatanwender',
		},
		billablePartyType: {
			PARTNER: 'Partner',
			CUSTOMER: 'Kunde',
		},
		type: {
			PREPAID: 'Vorauszahlung',
			FLEXIBLE_BILLING: 'Flexible Abrechnungen',
		},
	},
	promotion: {
		scope: {
			PARTY_ONLY: 'Nur Partei',
			PRODUCT_ONLY: 'Nur Produkt',
			PARTY_AND_PRODUCT: 'Partei und Produkt',
		},
		type: {
			DISCRETIONARY: 'Ermessen',
			SALES_CAMPAIGN: 'Verkaufskampagne',
		},
		partyType: {
			DISTRIBUTION: 'Vertriebshändler',
			RESELLER: 'Wiederverkäufer',
			END_CUSTOMER: 'Endkunde',
		},
		partyTier: {
			DISTRIBUTORS: 'Vertriebshändler',
			NONE: 'Keine',
			TIER_4: 'Stufe 4',
			TIER_3: 'Stufe 3',
			TIER_2: 'Stufe 2',
			TIER_1: 'Stufe 1',
		},
		state: {
			ENABLED: 'Aktiviert',
			DISABLED: 'Deaktiviert',
		},
	},
	retail: {
		status: {
			COMPLETE: 'Umfassend',
			IN_PROGRESS: 'Vorgang läuft.',
		},
	},
	refunds: {
		status: {
			OPEN: 'Öffnen',
			FULLY_APPLIED: 'Vollständig angewandt',
			VOIDED: 'Ungültig',
			PENDING: 'Ausstehend',
		},
		type: {
			ORDER: 'Rückerstattung für Bestellung',
			LINE: 'Rückerstattung für Einzelposten',
			KEY: 'Rückerstattung für Schlüssel',
			TAX: 'Steuerrückzahlung',
		},
		reason: {
			CUSTOMER_SATISFACTION_ISSUE: 'Problem mit Kundenzufriedenheit',
			INCORRECT_PRICE_INVOICED: 'Falscher Preis abgerechnet',
			NOT_DELIVERED: 'Nicht geliefert',
			WRONG_PRODUCT: 'Falsches Produkt',
			PRODUCT_NOT_WORKING: 'Produkt funktioniert nicht',
			TAX_EXEMPT: 'Steuerbefreit',
			OTHER_REASON: 'Sonstige Gründe',
		},
	},
	comparativeFeature: {
		ONLINE_MANAGEMENT: {
			name: 'Online-Verwaltungsplattform',
			description:
				'Kinderleichte Einrichtung des Geräteschutzes, Verwaltung Ihrer Abonnements, Überwachung und Hinzufügen von Geräten an zentraler Stelle – jederzeit und überall.',
		},
		DEVICE_PROTECTION: {
			name: 'Geräteschutz',
			description:
				'Preisgekrönte Antivirus-Software, die alle Ihre Geräte vor Malware, Phishing, Ransomware und anderen fortschrittlichen Cyberangriffen schützt.',
		},
		DATA_PROTECTION: {
			name: 'Datenschutz',
			description: 'Schützt Ihre persönlichen, geschäftlichen und kundenbezogenen Daten vor Diebstahl.',
		},
		IT_SUPPORT: {
			name: 'IT-Support',
			description:
				'Schneller und freundlicher Support durch unsere hochqualifizierten technischen Experten, die Ihnen und Ihren Mitarbeitern 24 Stunden am Tag, 5 Tage die Woche per E-Mail, Chat und Telefon mit Rat und Tat zur Seite stehen.',
		},
		IDENTITY_PROTECTION: {
			name: 'Identitätsschutz',
			description: 'Beinhaltet einen Passwort- und Webcam-Schutz, um die Online-Identität Ihrer Mitarbeiter zu wahren.',
		},
		UNLIMITED_VPN: {
			name: 'Unbegrenztes VPN',
			description:
				'Unser integriertes VPN verschlüsselt Ihre Daten und macht die Internetverbindung Ihrer Mitarbeiter in öffentlichen WLAN-Netzwerken privat und damit sicher.',
		},
		USB_PROTECTION: {
			name: 'USB-Schutz',
			description:
				'Verhindern Sie, dass Mitarbeiter nicht zugelassene Wechselspeichermedien wie Flash-Laufwerke, externe Laufwerke, Speicherkarten usw. verwenden. Blockieren, kontrollieren und überwachen Sie USB-Anschlüsse, um Datendiebstahl, Datenverlust und Malware-Infektionen zu verhindern.',
		},
		PATCH_MANAGEMENT: {
			name: 'Patch Management',
			description:
				'Behebt automatisch Sicherheitslücken in Ihrer Software und in Anwendungen von Drittanbietern, die anfällig für Cyberangriffe sind.',
		},
	},
	productGroupTag: {
		NEW: 'Neu',
	},
	widgets: {
		label: {
			QUOTES: 'Letzte Angebote',
			ORDERS: 'Letzte Bestellungen',
			CREDIT_STATUS: 'Aktueller Status',
			EXPIRING_LICENSES_NEXT_N_DAYS: 'Ablaufende Lizenzen nächste N Tage',
			EXPIRED_LICENSES_LAST_N_DAYS: 'Abgelaufene Lizenzen letzte N Tage',
			CLOSING_BALANCE: 'Neueste Abschlusssaldo-Abrechnungen',
		},
		caption: {
			QUOTES: 'Letzte Angebote',
			ORDERS: 'Letzte Bestellungen',
			CREDIT_STATUS: 'Aktueller Status',
			EXPIRING_LICENSES_NEXT_N_DAYS: 'Ablaufende Lizenzen nächste {{count}} Tage',
			EXPIRED_LICENSES_LAST_N_DAYS: 'Abgelaufene Lizenzen letzte {{count}} Tage',
			CLOSING_BALANCE: 'Neueste Abschlusssaldo-Abrechnungen',
		},
	},
};
