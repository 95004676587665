import React, { ReactElement } from 'react';
import { Modal } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { TSelectVariantTableProps } from 'module/purchase/components/selectProducts/SelectVariantTable';
import { FormikForm } from 'js/components/formik/FormikForm';
import { TSelectVariantForm, TSelectVariantItem } from 'module/purchase/components/selectProducts';
import { SelectProductVariantTable } from 'module/retail/components/generate/SelectProductVariantTable';
import { retailConfig } from 'module/retail/retailConfig';
import { SubmitButton } from 'js/components/atoms/Button';
import { orderInstanceNormalizer } from 'module/purchase/normalizer';

type TSelectProductVariantModalProps = TSelectVariantTableProps & {
	onSelect(variants: TSelectVariantItem[], close: boolean): void;
};

const isSubmitDisabled = (values: TSelectVariantForm): boolean => !values.variant.some((variant) => variant.checked);

const normalizeVariants = (values: TSelectVariantForm): TSelectVariantItem[] =>
	values.variant.filter(({ checked, quantity = 0, unit = 0 }) => checked && quantity !== 0 && unit !== 0);

export const SelectProductVariantModal = (props: TSelectProductVariantModalProps): ReactElement | null => {
	const { onSelect, productGroup } = props;
	const [t] = useTranslation(purchaseConfig.trNamespace);

	if (productGroup === null) {
		return null;
	}

	const initValues: TSelectVariantItem[] =
		productGroup.products.map((product, i) => ({
			id: i,
			checked: false,
			quantity: 0,
			unit: product.bulkQuantity,
			product: orderInstanceNormalizer.normalizeProduct(productGroup, product),
		})) || [];

	return (
		<Formik<TSelectVariantForm>
			initialValues={{ variant: initValues }}
			onSubmit={(values, { setSubmitting }) => {
				const items = normalizeVariants(values);
				setSubmitting(false);

				if (items.length !== 0) {
					onSelect(items, false);
				}
			}}
		>
			{({ values }) => (
				<FormikForm testId="selectVariantForm">
					<Modal.Header>{productGroup.name}</Modal.Header>
					<Modal.Body>
						<SelectProductVariantTable productGroup={productGroup} />
					</Modal.Body>
					<Modal.Footer
						cancelButton
						submitButton={
							<SubmitButton
								disabled={isSubmitDisabled(values)}
								size="sm"
								testId="addToCart"
							>
								{t('actions.addToCart')}
							</SubmitButton>
						}
						additionalButtons={[
							{
								id: 'addToCartAndClose',
								disabled: isSubmitDisabled(values),
								children: t(retailConfig.trPrefix('generate.products.addAndClose')),
								size: 'sm',
								testId: 'addToCartAndClose',
								variant: 'outline-primary',
								onClick() {
									onSelect(normalizeVariants(values), true);
								},
							},
						]}
					/>
				</FormikForm>
			)}
		</Formik>
	);
};
