import { ILocaleConfig, TLocale } from 'types/config';

const numbro = require('numbro/languages/it-IT');
const locale: TLocale = 'it-IT';

export const it_IT: ILocaleConfig = {
	name: 'Italian (Italia)',
	locale,
	language: 'it',
	default: true,
	format: {
		numbro,
		datetime: locale,
	},
};
