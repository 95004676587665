export default {
	common: {
		forecastedSpend: 'Prognostizierte Ausgaben',
		currentSpend: 'Aktuelle Ausgaben',
		maxSpend: 'Maximale Ausgaben',
		limit: 'Grenzwert',
		noLimit: 'Keine Grenze',
		searchDistributionPartner: 'Vertriebspartner nach Name suchen',
		unlimited: 'Unbegrenzt',
		unlimitedNote:
			'Die maximalen Ausgaben können Ihr Kreditlimit überschreiten, wenn keine Nutzungsgrenzen festgelegt sind. In diesem Fall sind die Produkte Ihres Partners so lange deaktiviert, bis das Kreditlimit erhöht oder der Saldo reduziert wird.',
		valueBefore: 'Vorher',
		valueAfter: 'Nach dem Speichern der Änderungen',
	},
	page: {
		title: 'Flexible Abrechnungen',
	},
	actions: {
		edit: 'Produkte verwalten',
		viewOrders: 'Bestellungen anzeigen',
		enableProducts: 'Produkte aktivieren',
		enableAllProducts: 'Alle Produkte aktivieren',
		disableAllProducts: 'Alle Produkte deaktivieren',
	},
	tab: {
		distributionPartners: 'Partnerübersicht',
		overview: 'Übersicht',
		reports: 'Berichte',
	},
	modal: {
		confirmation: 'Sind Sie sicher?',
		message: 'Gespeicherte Änderungen können Auswirkungen auf Ihren Kredit haben. Möchten Sie fortfahren?',
		apply: 'Änderungen werden angewendet',
		success: 'Änderungen angewendet',
	},
	entity: {
		usageLimit: 'Nutzungsgrenze',
		yourPrice: 'Ihr Preis',
	},
	invite: {
		info: 'Dieser Partner hat noch kein Business Hub-Konto erstellt. Sie können eine flexible Abrechnung für ihn einrichten, die jedoch erst dann wirksam wird, wenn sein Business Hub-Konto erstellt wurde.',
		action: {
			send: 'Einladung senden',
			resend: 'Einladung erneut senden',
		},
		lastSent: 'Die letzte Einladung wurde am {{date}} an {{email}} gesendet.',
		processing: 'Wir senden eine Einladung an den Vertriebspartner',
		success: 'Einladung gesendet',
		description:
			'Sendet eine E-Mail-Einladung zur Business Hub-Registrierung. Dieses Konto wird das erste Administratorkonto im Hub sein (weitere Administratoren können später hinzugefügt werden). Nach der Registrierung wird das neue Business Hub-Konto mit dem Datensatz des Partners verknüpft.',
		form: {
			create: {
				label: 'Business Hub beim Speichern dieses Partners erstellen',
				description:
					'Sendet nach Speicherung dieses Formulars eine E-Mail-Einladung zur Business Hub-Registrierung. Dieses Konto wird das erste Administratorkonto im Hub sein (weitere Administratoren können später hinzugefügt werden). Nach der Registrierung wird das neue Business Hub-Konto mit dem Datensatz des Partners verknüpft.',
			},
			differentEmail: {
				partner: 'Einladung an gespeicherte E-Mail-Adresse senden',
				other: 'Einladung an eine andere E-Mail-Adresse senden',
			},
		},
		modal: {
			title: 'Business Hub für {{name}} erstellen',
		},
	},
	tooltips: {
		unlimitedMaxSpend:
			'Die maximalen Ausgaben können Ihr Kreditlimit überschreiten, wenn keine Nutzungsgrenzen festgelegt sind. In diesem Fall sind die Produkte Ihres Partners so lange deaktiviert, bis das Kreditlimit erhöht oder der Saldo reduziert wird.',
		forecastedSpend: {
			distributor:
				'Der Betrag, der während des aktuellen Abrechnungszeitraums ausgegeben wird, wenn die Produktnutzung bei allen Partnern auf dem heutigen Stand bleibt.',
			partner:
				'Der Betrag, der während des aktuellen Abrechnungszeitraums ausgegeben wird, wenn die Produktnutzung bei diesem Partner auf dem heutigen Stand bleibt.',
		},
		currentSpend: {
			distributor:
				'Der Betrag, der im aktuellen Abrechnungszeitraum bereits für aktive Produkte bei allen Partnern ausgegeben wurde.',
			partner:
				'Der Betrag, der im aktuellen Abrechnungszeitraum bereits für aktive Produkte bei diesem Partner ausgegeben wurde.',
		},
		maxSpend: {
			distributor:
				'Der Höchstbetrag, der in Rechnung gestellt werden kann, wenn alle aktiven Produkte bei allen Partnern ihre Nutzungsgrenzen erreichen.',
			partner:
				'Der Höchstbetrag, der in Rechnung gestellt werden kann, wenn alle aktiven Produkte bei diesem Partner ihre Nutzungsgrenzen erreichen.',
		},
	},
	error: {
		usageLimit: {
			title: 'Ihre Änderungen können derzeit nicht gespeichert werden.',
			message:
				'Die Nutzungsgrenze(n) für das/die Produkt(e), das/die Sie festzulegen versuchen, ist/sind niedriger als die Summe der Nutzungsgrenzen, die Ihr Partner in seinem Business Hub für seine Kunden festgelegt hat.',
			product:
				'Die Summe der Limits für <strong>{{product}}</strong> ist derzeit auf <strong>{{limit}}</strong> festgelegt.',
			note: 'Kontaktieren Sie Ihren Partner, um die Nutzungsgrenzen zu besprechen.<br />Wenn Sie Hilfe benötigen, wenden Sie sich bitte an unser Support-Team.',
		},
	},
};
