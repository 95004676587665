import { useApiPartnerPriceLists } from 'js/priceList/useApiPartnerPriceLists';
import { IEntityPartnerPriceList, TPriceListCode } from 'js/priceList';
import { TPartnerId } from 'types';
import { IEntityLicense } from 'module/licenses';
import { resolveLicenseOperationPriceListCode } from 'module/licenses/utils/licenseOperation';
import { TUseLicensePartner } from 'module/licenses/hooks/useLicenseParty';
import { useOrderContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';

type TUseLicenseOperationPriceListCodes = {
	partnerPriceLists: IEntityPartnerPriceList[] | null;
	licensePriceListCode: TPriceListCode | null;
	isLoading: boolean;
};

export const useLicenseOperationPriceListCodes = (
	license: IEntityLicense,
	licensePartner: TUseLicensePartner,
): TUseLicenseOperationPriceListCodes => {
	const { orderState } = useOrderContext();
	const { partner } = licensePartner;
	const hasPartner = licensePartner.hasPartner && (Boolean(partner) || licensePartner.isFetching);
	const isPartnerQueryEnabled = Boolean(partner?.id);

	// Default return values
	let priceLists: IEntityPartnerPriceList[] = [];

	// License price list code
	let licensePriceListCode: TPriceListCode | null = license.computedValues.priceListCode;

	// Get license partner price list codes
	const {
		data: partnerPriceLists,
		query: { isInitialLoading },
	} = useApiPartnerPriceLists({
		filter: { partnerId: partner?.id as TPartnerId },
		queryConfig: { enabled: isPartnerQueryEnabled },
	});

	// License partner price list codes
	if (hasPartner) {
		if (!isInitialLoading) {
			priceLists = partnerPriceLists;
			licensePriceListCode = resolveLicenseOperationPriceListCode(license, priceLists, licensePriceListCode);
		}
	}

	// Order partner price list codes
	else if (isDefined(orderState.partner) && isDefined(orderState.partnerPriceLists)) {
		priceLists = orderState.partnerPriceLists;
		licensePriceListCode = resolveLicenseOperationPriceListCode(license, priceLists, licensePriceListCode);
	}

	return {
		licensePriceListCode,
		partnerPriceLists: priceLists,
		isLoading: isInitialLoading,
	};
};
