import { IPartnerGroups } from 'module/partners';
import { forEach } from 'lodash';

/**
 * @param {IPartnerGroups} groups
 * @param {Partial<IPartnerGroups>} groupsConfig
 * @return {boolean}
 */
export const isAllowedByGroups = (groups: IPartnerGroups, groupsConfig?: Partial<IPartnerGroups>): boolean => {
	let isValid = true;

	if (groupsConfig) {
		forEach(groupsConfig, (value, key) => {
			// @ts-ignore
			const authValue = groups[key] ?? false;
			if (authValue !== value) {
				isValid = false;
			}
		});
	}

	return isValid;
};
