import React from 'react';
import { IRegistrationDataForm } from 'module/registration';
import { ColInput } from 'js/components/molecules/Inputs/ColInput';
import { FormikCountrySelect } from 'js/components/formik/FormikCountrySelect';
import { Row } from 'js/components/atoms/Row';
import { useTranslation } from 'react-i18next';
import { registrationConfig } from 'module/registration/registrationConfig';

export const PartnerRegistrationForm = () => {
	const [t] = useTranslation(registrationConfig.trNamespace);

	return (
		<>
			<Row size="sm">
				<ColInput
					label={t('common:entity.company')}
					name="companyName"
					required
					disabled
					colProps={{ sm: 12 }}
				/>
				<ColInput
					label={t('common:address.street')}
					name="address"
					required
					disabled
					colProps={{ sm: 6 }}
				/>
				<ColInput
					label={t('common:address.city')}
					name="city"
					required
					disabled
					colProps={{ sm: 6 }}
				/>
				<ColInput
					label={t('common:address.postalCode')}
					name="postalCode"
					required
					disabled
					colProps={{ sm: 6 }}
				/>
				<ColInput
					label={t('common:contact.taxId')}
					name="taxId"
					required
					colProps={{ sm: 6 }}
				/>
			</Row>

			<FormikCountrySelect<IRegistrationDataForm>
				countryField="countryCode"
				countryStateField="stateCode"
				required
				disabled
			/>
		</>
	);
};
