import React, { ReactElement } from 'react';
import { RouteName } from 'module/RouteName';
import { useTranslation } from 'react-i18next';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle/DynamicPageTitle';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { Toast } from '@avast/react-ui-components';
import { toast } from 'react-toastify';
import { customersConfig } from 'module/customers/customersConfig';
import { CustomerForm } from 'module/customers/forms/CustomerForm';
import { useApiCreateCustomer } from 'module/customers/hooks/useApiCustomers';
import { createCustomerApiError } from 'module/customers/utils/apiError';
import { useNavigate } from 'react-router-dom';
import { useApiErrorContext, useAuthContext } from 'js/contexts';

export const PageCreate = (): ReactElement => {
	const [t] = useTranslation(customersConfig.trNamespace);
	const navigate = useNavigate();
	const { authCompanyId } = useAuthContext();
	const { setError } = useApiErrorContext();
	const { mutate: createCustomer } = useApiCreateCustomer({
		config: { params: { partnerId: authCompanyId! } },
	});

	return (
		<div className="section__content mt-3">
			<DynamicPageTitleItem text={t('page.create.title')} />
			<DynamicBreadcrumbsItem href={RouteName.CUSTOMERS.CREATE}>{t('page.create.title')}</DynamicBreadcrumbsItem>

			<CustomerForm
				onSubmit={(values, { setSubmitting }) => {
					createCustomer(values, {
						onSuccess(response) {
							setSubmitting(false);
							// Redirect
							if (Boolean(response?.data)) {
								toast.success(<Toast>{t('page.create.success')}</Toast>);
								navigate(customersConfig.detailLink(response.data));
							}
						},
						onError(error) {
							setError({ error, resolve: createCustomerApiError });
							setSubmitting(false);
						},
					});
				}}
			/>
		</div>
	);
};
