export default {
	actions: {
		create: '建立新促銷活動',
		edit: '編輯促銷活動',
		disable: {
			action: '停用促銷活動',
			success: '促銷活動已停用',
			loading: '正在停用促銷活動...',
			confirmation: {
				title: '正在停用促銷活動',
				message: '您確定要停用這項促銷活動嗎？此操作無法回復。',
			},
		},
		form: {
			create: '提交促銷活動',
			update: '更新促銷活動',
		},
		extraFilters: '更多篩選條件',
		export: '匯出促銷活動',
	},
	common: {
		effectiveDate: '生效日期',
		filterPromotions: '篩選促銷活動',
		partyCriteria: '當事人標準',
		partyCriteriaInfo: '選取一位合作夥伴或至少一個當事人標準以繼續。',
		productCriteria: '產品標準',
		creatingPromotion: '正在建立促銷活動',
		updatingPromotion: '正在更新促銷活動',
		invalidPromotionStartDate: {
			title: '要儲存促銷活動嗎？',
			message: '促銷活動的開始日期已過。您無法再編輯促銷活動。',
		},
	},
	error: {
		promotionNotFound: '找不到促銷活動！',
		createPromotion: '無法建立促銷活動。',
		updatePromotion: '無法更新促銷活動。',
	},
	page: {
		create: {
			title: '新定價促銷活動',
			success: '您已成功建立新定價促銷活動。',
		},
		update: {
			title: '更新促銷活動 #{{id}}',
			success: '您已成功更新定價促銷活動。',
			notEditable: '無法編輯目前的促銷活動，因為有可能涉及訂單。',
		},
	},
	placeholder: {
		types: '所有促銷活動類型',
		partyTiers: '所有層級',
		categories: '所有類別',
		licenseOperations: '所有授權操作',
		partyCountries: '所有國家/地區',
		partyTypes: '所有當事人類型',
		products: '所有產品',
	},
	entity: {
		id: '促銷活動 ID',
		name: '促銷活動名稱',
		date: '有效日期範圍',
		partyType: '當事人類型',
		partyType_other: '當事人類型',
		partyTier: '當事人層級',
		partyTier_other: '當事人層級',
		partyCountry: '當事人國家/地區',
		partyCountry_other: '當事人國家/地區',
		licenseOperation: '授權操作類型',
		licenseOperation_other: '授權操作類型',
		category: '產品類別',
		product: '產品',
		product_other: '產品',
		quantityMin: '最低數量',
		rate: '折扣率',
		scope: '範圍',
		type: '促銷活動類型',
		unitsFrom: '單位數量從',
		unitsTo: '單位數量到',
	},
	tooltip: {
		zeroQuantity: '數量為零表示無論訂購多少授權，都將適用促銷活動。',
	},
};
