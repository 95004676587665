import React, { ReactElement } from 'react';
import { TDetailArguments } from 'types';
import { PageUpdateContent } from 'module/orders/components/PageUpdateContent';
import { useRouteParams } from 'js/hooks/useRouteParams';

export const PageQuoteUpdate = (): ReactElement => {
	const { id } = useRouteParams<TDetailArguments>();
	return (
		<PageUpdateContent
			id={id}
			isQuote={true}
		/>
	);
};
