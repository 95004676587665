import { getNextId, getPositionCoords } from 'submodule/widgets/utils';
import * as R from 'ramda';
import {
	TAddRow,
	TAddWidget,
	TEditWidget,
	TMoveItem,
	TMoveItemToAnotherColumn,
	TRemoveWidget,
	TReorderColumnItems,
	TWidget,
	TWidgetCoords,
	TWidgetGrid,
	TWidgetGridItem,
} from 'submodule/widgets';

const moveItemBetweenColumns: TMoveItem = (sourceColItems, destinationColItems, sourceIndex, destinationIndex) => {
	const itemToMove = R.nth(sourceIndex, sourceColItems);

	if (R.isNil(itemToMove)) {
		return [sourceColItems, destinationColItems];
	}

	const restOfSourceItems = R.remove(sourceIndex, 1, sourceColItems);

	const updatedDestinationItems = R.insert(destinationIndex, itemToMove, destinationColItems);
	return [restOfSourceItems, updatedDestinationItems];
};

const moveItemToAnotherColumn: TMoveItemToAnotherColumn = (rows, source, destination) => {
	const sourceLens = createPositionLens(getPositionCoords(source.droppableId));
	const destinationLens = createPositionLens(getPositionCoords(destination.droppableId));

	const sourceColItems = R.view(sourceLens, rows);
	const destinationColItems = R.view(destinationLens, rows);

	const [newSourceItems, newDestinationItems] = moveItemBetweenColumns(
		sourceColItems,
		destinationColItems,
		source.index,
		destination.index,
	);

	return R.compose(R.set(destinationLens, newDestinationItems), R.set(sourceLens, newSourceItems))(rows);
};

/**
 * Change the position of a widget in the widget grid.
 * @returns {TWidgetGrid}
 */
const reorderColumnItems: TReorderColumnItems = (rows, source, destination): TWidgetGrid => {
	const widgetPosition = createPositionLens(getPositionCoords(source.droppableId));

	return R.over(widgetPosition, R.move(source.index, destination.index), rows);
};

/**
 * Create and append new row with one or two empty columns.
 * @returns {TWidgetGrid}
 */
const addRow: TAddRow = (rows, numOfColumns): TWidgetGrid => R.append(R.repeat([], numOfColumns), rows);

/**
 * Append a new widget into position specified by coords.
 * @returns {TWidgetGrid}
 */
const addWidget: TAddWidget = (rows, values, coords): TWidgetGrid => {
	const widgetPosition = createPositionLens(coords);
	const newItem: TWidgetGridItem = {
		id: getNextId(rows),
		widget: values,
	};

	return R.over(widgetPosition, R.append(newItem), rows);
};

/**
 * Localize widget by coords and edit it with changed values.
 * @returns {TWidgetGrid}
 */
const editWidget: TEditWidget = (rows, values, coords, index): TWidgetGrid => {
	const { rowIndex, colIndex } = coords;
	const widgetPosition = R.lensPath<TWidgetGrid, TWidget>([rowIndex, colIndex, index, 'widget']);

	return R.set(widgetPosition, values, rows);
};

/**
 * Localize widget by coords and removes it from widget grid.
 * @returns {TWidgetGrid}
 */
const removeWidget: TRemoveWidget = (rows, coords, index): TWidgetGrid => {
	const widgetPosition = createPositionLens(coords);

	return R.over(widgetPosition, R.remove(index, 1), rows);
};

/**
 * Creates a lens for widget grid using widget coordination.
 * @returns {R.Lens<TWidgetGrid, TWidgetGridItem[]>}
 */
const createPositionLens = ({ rowIndex, colIndex }: TWidgetCoords): R.Lens<TWidgetGrid, TWidgetGridItem[]> =>
	R.lensPath([rowIndex, colIndex]);

/**
 * Filter out rows that has all of it\'s columns empty.
 * @param {TWidgetGrid} state
 * @returns {TWidgetGrid}
 */
const removeEmptyRows = (state: TWidgetGrid): TWidgetGrid =>
	state.filter((row) => row.some((column) => column.length !== 0));

/**
 * Add an empty row at the end of the grid, if there is not any yet.
 * @param {TWidgetGrid} state
 * @returns {TWidgetGrid}
 */
const appendLastEmptyRow = (state: TWidgetGrid): TWidgetGrid => {
	const rows = removeEmptyRows(state);
	return addRow(rows, 2);
};

/**
 * Change selected widget row from two column row into one column row by moving all columns items into one column.
 * @param {TWidgetGrid} state
 * @param {number} rowIndex
 * @returns {TWidgetGrid}
 */
const setOneColumn = (state: TWidgetGrid, rowIndex: number): TWidgetGrid => {
	const rowPosition = R.lensIndex<TWidgetGrid, number>(rowIndex);

	return R.over(rowPosition, (row) => [R.unnest(row)], state);
};

/**
 * Change selected widget row from one column row into two column row by adding an empty column.
 * @param {TWidgetGrid} state
 * @param {number} rowIndex
 * @returns {TWidgetGrid}
 */
const setTwoColumns = (state: TWidgetGrid, rowIndex: number): TWidgetGrid => {
	const rowPosition = R.lensIndex<TWidgetGrid, number>(rowIndex);

	return R.over(rowPosition, (row) => [...row, []], state);
};

export const solveWidgets = {
	moveItemToAnotherColumn,
	reorderColumnItems,
	addRow,
	addWidget,
	removeWidget,
	editWidget,
	removeEmptyRows,
	setOneColumn,
	setTwoColumns,
	appendLastEmptyRow,
};
