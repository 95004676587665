import React, { ReactElement, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { TDetailArguments } from 'types';
import { RouteName } from 'module/RouteName';
import { PageDetailComponent } from 'js/layouts/page/PageDetailComponent';
import { useApiOrder } from 'module/orders/hooks/useApiOrders';
import { IEntityOmsOrder, IEntityOrder } from 'module/orders';
import { buildParametrizedRoute } from 'js/utils/common';
import { PageDetailTabs } from 'module/orders/components/PageDetailTabs';
import { logDebug } from 'js/utils/app';
import { isOrderInQuoteStatus } from 'module/orders/utils/common';
import { useRouteParams } from 'js/hooks/useRouteParams';

type TArguments = TDetailArguments;
type TData = IEntityOrder;

export const PageDetail = (): ReactElement => {
	const params = useRouteParams<TArguments>();
	const queryProps = useMemo(() => ({ filter: params }), [params]);

	return (
		<PageDetailComponent<TData, TArguments, IEntityOmsOrder>
			query={useApiOrder}
			queryProps={queryProps}
			detailLink={buildParametrizedRoute(RouteName.ORDER.DETAIL, params.id)}
		>
			{({ data: order }) => {
				if (isOrderInQuoteStatus(order)) {
					logDebug('Order details redirected to quote detail');
					return (
						<Navigate
							to={buildParametrizedRoute(RouteName.QUOTE.DETAIL, order.id)}
							replace
						/>
					);
				}

				return <PageDetailTabs />;
			}}
		</PageDetailComponent>
	);
};
