import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { numberFormatter } from 'js/utils/number';
import { CustomTable } from 'js/components/molecules/CustomTable';
import { useFormikContext } from 'formik';
import { FixedWidthWrapper } from 'js/components/molecules/Inputs/FixedWidthWrapper';
import { FormikControl } from 'js/components/formik/FormikControl';
import { TCustomerQuoteForm } from 'module/purchase/components/customerQuote/AsyncCustomerQuoteModal';
import { useCustomerQuotePrices } from 'module/purchase/components/customerQuote/useCustomerQuotePrices';
import { usePartyPrefixTranslation } from 'module/purchase/hooks/usePartyPrefixTranslation';
import { useOrderContext } from 'js/contexts';

export const CustomerQuoteTable = (): ReactElement | null => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const {
		orderState: { pricing },
		getOrderItem,
		getItemPricing,
		billableParty,
	} = useOrderContext();
	const { getItemPrice, getTotalPrice } = useCustomerQuotePrices();
	const currency = billableParty?.currencyCode!;
	const { values } = useFormikContext<TCustomerQuoteForm>();
	const { tPartyPrefix } = usePartyPrefixTranslation('common');

	return (
		<CustomTable
			className="table-data"
			hover
			responsive
		>
			<thead>
				<tr>
					<CustomTable.Th>{t('common:entity.productName')}</CustomTable.Th>
					<CustomTable.Th>{t('common:entity.quantity')}</CustomTable.Th>
					<CustomTable.Th>{t('common:entity.unit', { count: 0 })}</CustomTable.Th>
					<CustomTable.Th align="end">{t('common:entity.unitPrice')}</CustomTable.Th>
					<CustomTable.Th align="end">{tPartyPrefix('common:entity.price', 'quotedCustomer')}</CustomTable.Th>
					<CustomTable.Th align="end">{t('common:entity.priceWOTax')}</CustomTable.Th>
				</tr>
			</thead>
			<tbody>
				{values.items.map((item, i) => {
					const { id } = item;
					const orderItem = getOrderItem(id);
					const pricingItem = getItemPricing(id);
					if (!orderItem) {
						return null;
					}
					const { quantity, unit, product } = orderItem;

					return (
						<tr key={id}>
							<CustomTable.Td>
								<span className="fw-bold">{product.group.name}</span>
								<br />
								{product.sku}
							</CustomTable.Td>
							<CustomTable.Td>{quantity}</CustomTable.Td>
							<CustomTable.Td>{unit}</CustomTable.Td>
							<CustomTable.Td align="right">
								{numberFormatter.currency(pricingItem?.customerPrice?.unit, currency)}
							</CustomTable.Td>
							<CustomTable.Td align="right">
								<FixedWidthWrapper width={160}>
									<FormikControl.Number
										name={`items.${i}.unitPrice`}
										size="sm"
										step={0.01}
										min={0}
										id={`customerPrice-${id}`}
										numberFormat={{
											output: 'currency',
											currencySymbol: currency,
											currencyPosition: 'postfix',
											spaceSeparatedCurrency: true,
										}}
									/>
								</FixedWidthWrapper>
							</CustomTable.Td>
							<CustomTable.Td align="right">{numberFormatter.currency(getItemPrice(item), currency)}</CustomTable.Td>
						</tr>
					);
				})}
			</tbody>
			<tfoot>
				<tr>
					<CustomTable.Td
						foot
						className="cell cell-foot"
						colSpan={3}
					>
						{t('common:entity.totalPrice')}
					</CustomTable.Td>
					<CustomTable.Td
						foot
						align="right"
						className="text-gray"
					>
						{numberFormatter.currency(pricing?.headers.customerTotalPrice, currency)}
					</CustomTable.Td>
					<CustomTable.Td
						foot
						align="right"
						className="text-success"
						colSpan={2}
					>
						{numberFormatter.currency(getTotalPrice(values), currency)}
					</CustomTable.Td>
				</tr>
			</tfoot>
		</CustomTable>
	);
};
