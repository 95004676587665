import { camelCase, flatten, isNumber, transform, zipObject } from 'lodash';
import { CUSTOM_PRICING_MESSAGE } from 'module/purchase/constants';
import type { TFunction } from 'i18next';

type TPricingMessageAttributes = {
	[x: string]: string;
};

export class PricingMessageParser {
	private readonly message: string;
	private readonly t: TFunction;
	private lineSeparator = '|';
	private valueSeparator = ':';
	private multiValueSeparators = [' and ', ', '];

	public constructor(message: string, t: TFunction) {
		this.message = message;
		this.t = t;
	}

	public isCustomPricingMessage(): boolean {
		return this.message === CUSTOM_PRICING_MESSAGE;
	}

	public getLines() {
		if (this.isCustomPricingMessage()) {
			return [this.message];
		}

		return this.message.split(this.lineSeparator).map((line) =>
			transform<object, string[]>(
				this.parseLine(line),
				(acc, value, key) => {
					acc.push(`${key}: ${value}`);
					return acc;
				},
				[],
			).join(', '),
		);
	}

	private translate(keys: string[]) {
		const prepareKey = (key: string) => camelCase(key.replaceAll(',', ''));

		return keys.map((key) => this.t('pricingMessage:' + prepareKey(key)));
	}

	private parseMultiValue(value: string, maxLength?: number): string[] {
		let values = this.multiValueSeparators
			.reduce(
				(values, separator) =>
					flatten(values.map((value) => value && value.split(separator))).map((value) => value && value.trim()),
				[value],
			)
			.filter((value) => value !== '');

		if (isNumber(maxLength) && maxLength > 0 && values.length !== 0) {
			const a = values.slice(0, maxLength - 1);
			const b = values.slice(maxLength - 1);
			values = [...a, b.join(', ')];
		}

		return values;
	}

	private parseLine(line: string): TPricingMessageAttributes {
		const [key, ...rest] = line.split(this.valueSeparator).map((value) => value.trim());
		const keys = this.parseMultiValue(key);
		const values = this.parseMultiValue(rest.join(`${this.valueSeparator} `), keys.length);

		const translatedKey = this.translate(keys);

		if (translatedKey.length === values.length) {
			return zipObject(translatedKey, values);
		}
		return {};
	}
}
