import fetch_jsonp from 'fetch-jsonp';
import { logError } from 'js/utils/app';
import { CONFIG } from 'config';
import { IVatInvoicesResponse } from 'js/components/chargeOrderDocuments';

export const logoutGcStore = (type: 'STANDARD' | 'VANITY') => {
	let storeUrl: string;
	switch (type) {
		case 'VANITY':
			storeUrl = CONFIG.APP.GC_VANITY_URL;
			break;
		case 'STANDARD':
			storeUrl = CONFIG.APP.GC_URL;
			break;
		default:
			throw Error(`Not supported type: ${type}`);
	}
	const url = `${storeUrl}/store/avgb2b/remoteLogout&format=json`;

	return fetch_jsonp(url).catch((error) => {
		logError('Unable to logout from GC store', url, error);
		return new Promise((resolve) => {
			resolve(null);
		});
	});
};

export const fetchVatInvoices = async (url: string): Promise<IVatInvoicesResponse | null> => {
	await logoutGcStore('VANITY');
	return fetch_jsonp(url)
		.then((response) => response.json<IVatInvoicesResponse>())
		.catch((error) => {
			logError('Unable to download VAT invoices', url, error);
			return new Promise((resolve) => {
				resolve(null);
			});
		});
};
