export default {
	common: {
		continueToProducts: 'Continue to products',
		orderValue: 'Order Value',
		remaining: 'Remaining',
		partyPrefix: {
			your: 'Your',
			partner: 'Partner',
			srp: 'SRP',
			customer: 'Customer',
			quotedCustomer: 'Quoted Cust.',
		},
		savingOrder: 'Saving order...',
		exportingOrder: 'Exporting order...',
		loadingOrder: 'Loading an order...',
		customerCreditStatus: {
			eligible: 'Customer is credit eligible.',
			notEligible: 'Customer is not credit eligible.',
		},
		previousLicense: 'Previous license key',
		currency: {
			changeTooltip: 'Change currency',
			selectPrompt: 'Select customer currency',
		},
		paginationText: 'Showing <strong>{{from}} to {{to}}</strong> from {{total}} items.',
	},
	discretionaryDiscount: {
		title: 'Discretionary Discount',
		placeholder: 'Enter a discount',
		approvalFlag: 'Approval Status',
		amount: 'Amount',
		amountUnit: 'Unit Amount',
		amountTotal: 'Total Amount',
		status: {
			notSet: 'No Discretionary Discount set on the order.',
			lineNotSet: 'No Discretionary Discount set on the line.',
			orderLevelDiscountIsSet:
				'An order-level discount has been applied to this order. Line-level discounts have been disabled.',
			lineLevelDiscountIsSet:
				'Line-Level Discounts have been applied to this order. Order-Level Discount has been disabled.',
		},
		action: {
			resetLineDiscounts: 'Remove line discounts',
		},
		waitingApprovalMessage:
			'There is a discretionary discount entered on the order that needs approval from Sales Ops.',
	},
	error: {
		submitOrder: {
			title: 'Unable to submit an order',
			general: 'Something went wrong and order submitting failed, please re-submit it again.',
		},
		pricing: {
			title: 'Unable to calculate the price',
		},
		context: {
			setPartnerFailed: 'Failed to add partner to order.',
		},
		creditExceed: {
			title: 'This transaction will exceed the amount of available credit for your account.',
			text: 'A credit payment method is not available for this transaction. Please use the Shopping Cart Checkout option.',
		},
		requiredCustomer: {
			title: 'Customer info required',
			text: 'Please select a Customer to complete your purchase.',
			action: 'Select customer',
			tooltip: {
				question: 'Why do we require Customer information to place an order?',
				answer:
					'We ask you to include customer information when placing orders to help us gather more insights about end customers. Knowing them better, and understanding their habits, needs, and preferences, enables us to better serve you and your customers.',
			},
		},
		requiredDistributionPartner: {
			title: 'Distribution Partner info required',
			text: 'Please select a Distribution Partner to complete your purchase.',
			action: 'Select Dist. Partner',
			tooltip: {
				question: 'Why do we require Distribution Partner information to place an order?',
				answer:
					'We ask you to include both levels of customer information (distribution partner and customer of distribution partner) when placing orders to help us know them better, and understand their habits, needs, and preferences, which enables us to better serve you and them.',
			},
		},
	},
	components: {
		successOrder: {
			title: 'Success!',
			action: {
				NEW_ORDER: 'New Order',
				DETAIL: 'Order Details',
				QUOTE_DETAIL: 'Quote Detail',
				LICENSES: 'View Licenses',
				EDIT: 'Edit Order',
				QUOTE_EDIT: 'Edit Quote',
				PAYMENT_INSTRUCTIONS: 'Payment Instructions',
			},
		},
		editPrices: {
			title: 'Edit Unit Prices',
			message:
				'You may edit only the unit price for the items currently in the cart. Orders saved from this screen cannot be changed. Please be sure your cart is final before proceeding.',
			button: 'Edit Pricing',
			columnLabel: 'Custom Unit Price',
			submit: 'Save order',
			confirmation: {
				message: 'This will create an order that can be completed, but it cannot be edited in any way. Are you sure?',
				success: 'Yes, Save Order',
			},
		},
		customerQuote: {
			error: {
				invalidItems: 'Custom Unit Prices are required for saving this order and previewing or sending the email.',
			},
			fields: {
				customerEmail: 'To',
				partnerEmail: 'CC',
				message: 'Personal Message',
			},
			submit: 'Send email',
		},
	},
	page: {
		default: {
			title: 'Order Details',
		},
		products: {
			title: 'Products',
		},
		order: {
			title: 'Review and Confirm',
		},
	},
	unit: {
		current: 'Current {{type}}',
		toIncrease: 'Additional {{type}}',
		toDecrease: 'Remove {{type}}',
		total: 'Total {{type}}',
	},
	entity: {
		privateNotes: 'Private Note',
		publicNotes: 'Public Note',
		opportunityId: 'Opportunity Number',
		marketSegment: 'Segment',
		validityLabel: 'For {{value}}',
		validityDescription: 'Term Length {{value}}',
	},
	pricing: {
		basePrice: 'From {{price}}',
		discount: {
			segment: 'Segment Discount',
			renewal: 'Renewal Discount',
			partner: 'Partner Discount',
			discretionary: 'Discretionary Discount',
			promo: 'Promo Discount',
		},
		details: {
			show: 'Show pricing details',
			hide: 'Hide pricing details',
		},
	},
	actions: {
		addNote: 'Add a Note',
		addToCart: 'Add To Cart',
		goToCart: 'Go To Cart',
		exportCart: 'Export cart',
	},
	message: {
		addToCart: {
			item: '- <strong>{{name}}</strong> - {{unit}} {{unitLabel}}, {{quantity}}x',
			title: 'Product has been added to the cart.',
		},
		removedFromCart: 'The product has been removed from the cart.',
		emptyCart: 'All products has been removed from the cart.',
		emptyCartConfirmation: 'Are you sure you want to remove all products from the cart?',
	},
	table: {
		action: {
			selectVariant: 'Select variant',
		},
		filter: {
			search: {
				placeholder: 'Find product by name or SKU',
			},
			marketSegment: '{{type}} Products',
		},
	},
	tableVariant: {
		priceNote: 'Prices include all customer discounts',
		tooltip: {
			quantity: 'Quantity value equals to the number of individual licenses ordered for the selected product.',
			unitType: {
				SEATS: 'Number of seats for a single license.',
				SESSIONS: 'Number of sessions for a single license.',
				STORAGE: '',
				DEVICE: '',
			},
		},
	},
	widget: {
		common: {
			billableParty: 'Billable Party',
			nonBillableParty: 'Non-Billable Party',
		},
		selectCustomerCard: {
			createNew: 'Create new Customer',
			note: 'No Customer on this order.<br/><strong>Register licenses later.</strong>',
			select: 'Select Customer',
			title: 'Customer',
			error: {
				load: 'Customer couldn’t be loaded',
			},
			tooltips: {
				change: 'Change Customer',
				update: 'Update Customer',
				cancel: 'Remove Customer from the order',
			},
		},
		selectDistributionPartnerCard: {
			createNew: 'Create new Dist. Partner',
			note: 'No Distribution Partner on this order.',
			select: 'Select Dist. Partner',
			title: 'Distribution Partner',
			error: {
				load: 'Distribution Partner couldn’t be loaded',
			},
			tooltips: {
				change: 'Change Distribution Partner',
				update: 'Update Distribution Partner',
				cancel: 'Remove Distribution Partner from the order',
			},
		},
		selectPartnerCard: {
			note: 'Select partner to see final prices after discounts.',
			select: 'Select partner',
			title: 'Avast Partner',
			error: {
				load: 'Partner couldn’t be loaded',
			},
			tooltips: {
				change: 'Change Avast Partner',
				cancel: 'Remove Avast Partner from the order',
			},
		},
	},
	paymentType: {
		SAVE: {
			action: 'Save',
			caption: 'Save for Later',
			button: 'Save for Later',
			successMessage: 'Your order has been saved.',
			confirmationCaption: 'Save this order?',
		},
		QUOTE: {
			action: 'Send',
			caption: 'Send as Quote',
			button: 'Send as Quote',
			successMessage: 'Your quote has been queued for delivery.',
			confirmationCaption: 'Send this quote?',
		},
		END_CUSTOMER_QUOTE: {
			action: 'Send',
			caption: 'Send as Quote',
			button: 'Send as Quote',
			successMessage: 'Your quote has been queued for delivery.',
			confirmationCaption: 'Ready to place this order?',
		},
		CUSTOMER_QUOTE: {
			action: 'Send',
			caption: 'Send Customer Price Quote',
			button: 'Send Customer Price Quote',
			successMessage: 'Your quote has been queued for delivery.',
			confirmationCaption: 'Are you sure you wish to send the Customer Price Quote email?',
			confirmationMessage:
				'All Customer Unit Prices will be saved to the order and an email will be sent to the customer.<br/>This action cannot be reversed or undone.',
		},
		GLOBAL_COMMERCE_CART_CHECKOUT: {
			action: 'Send',
			caption: 'Card Payment',
			button: 'Card Payment',
			successMessage: 'Your order has been successfully placed.',
			confirmationCaption: 'Ready to place this order?',
		},
		GLOBAL_COMMERCE_CLOSING_BALANCE: {
			action: 'Send',
			caption: 'Closing Balance',
			button: 'Submit on Closing Balance',
			successMessage: 'Your order has been successfully placed.',
			confirmationCaption: 'Ready to place this order?',
		},
		GLOBAL_COMMERCE_LINE_OF_CREDIT: {
			action: 'Send',
			caption: 'Submit on Credit',
			button: 'Submit on Credit',
			successMessage: 'Your order has been successfully placed.',
			confirmationCaption: 'Ready to place this order?',
		},
		ORACLE_CART_CHECKOUT: {
			action: 'Send',
			caption: 'Pay via Credit Card',
			button: 'Pay via Credit Card',
			successMessage: 'Your order has been successfully paid.',
			confirmationCaption: 'Ready to place this order?',
		},
		ORACLE_LINE_OF_CREDIT: {
			action: 'Send',
			caption: 'Submit on Credit',
			button: 'Submit on Credit',
			successMessage: 'Your order has been successfully placed.',
			confirmationCaption: 'Ready to place this order?',
		},
		ORACLE_CLOSING_BALANCE: {
			action: 'Send',
			caption: 'Closing Balance',
			button: 'Submit on Closing Balance',
			successMessage: 'Your order has been successfully placed.',
			confirmationCaption: 'Ready to place this order?',
		},
	},
	guards: {
		partnerPriceListGuard: {
			title: 'Change partner confirmation',
			message:
				'The selected partner does not have the same allowed price lists as the current one. If you continue, the cart will be emptied.',
		},
		partnerTermsGuard: {
			title: 'The partner cannot make an order',
			message: 'The selected partner has not accepted Terms and Conditions.',
		},
		priceListGuard: {
			title: 'Mix of products price lists',
			messageCaption:
				'Please note that we are unable to combine CloudCare and OMS products in one order. Are you sure you would like to add this product to the cart?',
			message:
				'The product(s) that you have added previously will be removed automatically and this item will be added to the cart.',
		},
		maxCartItemsGuard: {
			title: 'Maximum number of line items exceeded',
			message: 'An order can contain a maximum of {{count}} line item. Please remove some of the items to continue.',
			message_other:
				'An order can contain a maximum of {{count}} line items. Please remove some of the items to continue.',
		},
	},
	priceList: {
		mixWarning:
			'Please note that we are unable to combine CloudCare and OMS products in one order due to different pricing and discounts for each product.',
		code: {
			PREPAID_OMS: 'OMS products',
			PREPAID_CC_LEGACY: 'CloudCare products',
			PREPAID_CC_MSRP: 'CloudCare products',
		},
	},
	tooltips: {
		remove: 'Remove this item from the cart',
		emptyCart: 'Remove all items from the cart',
	},
};
