export default {
	comparativeFeatures: {
		title: '新的 Avast Business 解決方案',
		availableAsAddon: '做為附加元件提供',
		equivalentLabel: '更多價值。相同價格。',
		action: '查看套件',
		message:
			'我們推出了全新安全性套件，可以讓您用相同的價格為客戶提供更多價值。協助您的客戶為其業務選擇合適的防護等級。',
		price: {
			from: '從',
			units: '{{unitType}}',
			units_other: '{{count}} {{unitType}}',
		},
		startShopping: '開始購物',
		windowsOnly: '不適用於 MacOS',
	},
	refunds: {
		entity: {
			date: '退款日期',
			initiatedDate: '退款啟動日期',
			itemId: '單項 ID',
			orderLineItemId: '訂單單項編號',
			type: '退款類型',
			reason: '退款原因',
			reasonNote: '註解',
			refundableAmount: '可退款',
			refundAmount: '退款金額',
			totalRefunded: '退款總額',
		},
		initiate: {
			title: '啟動訂單 #{{id}} 退款',
			submit: '提交要求',
			process: {
				loading: '正在提交退款...',
				success: '已提交您的退款要求。',
				error: '無法提交退款要求。',
			},
			message: {
				ORDER_REFUND: '您可以選擇退還全部訂單金額，也可以輸入所需金額（不超過可用金額上限）來要求部分訂單退款。',
				LINE_REFUND:
					'選取您想要退款的產品。您可以選擇退還全部細目金額，也可以輸入所需金額（不超過可用金額上限）來要求部分細目退款。',
				TAX_REFUND: '選取此選項後，將退還全部訂單金額，並自動產生新的不含稅發票。',
				KEY_REFUND:
					'選取您想要退款的金鑰。您可以選擇退還全部金額，也可以輸入所需金額（不超過可用金額上限）來要求部分退款。',
			},
		},
		view: {
			title: '訂單 #{{id}} 退款',
		},
		error: {
			noRefundTypeEnabled: '此訂單不允許任何退款類型。',
			refundableAmount: '無法載入訂單退款資訊。',
			refundedAmount: '無法載入退款總額。',
		},
		license: {
			noSelectedLicenses: '您未選取任何授權。請使用上方的欄位來搜尋授權。',
			search: {
				title: '輸入金鑰來搜尋授權',
				searchFailure: '金鑰 {{licenseKey}} 與此訂單的任何授權都不相符。',
				refundableAmountFailure: '無法載入金鑰 {{licenseKey}} 的可退款金額。',
				success: '{{licenseKey}} 已新增至退款表單。',
				loadingLicenses: '正在載入授權...',
				loadingRefundableAmount: '正在載入可退款金額...',
			},
			select: {
				title: '選取要退款的授權',
			},
		},
	},
	appMessages: {
		taxExemptionCertificate: {
			title: '請注意，透過 OMS 上傳的憑證僅適用於廠商為 Digital River Inc. 的信用卡訂單。',
			contentTop:
				'自 <strong>2023 年 2 月 1 日</strong>起，信用額度購買（包括期末結餘付款方式，若適用）的廠商已從 Digital River Inc. 變更為 Gen Digital Inc.。為了確保信用額度購買繼續免稅，請將 <strong>Gen Digital Inc. 核發的免稅證明傳送</strong>至我們的稅務部門，電子郵件地址為 <0>{{email}}</0>。',
			contentBottom: '感謝您的合作！',
		},
		flexibleBillingAvailable: {
			title: 'Avast Business Hub 現已提供彈性計費功能',
			content:
				'使用 Business Hub 多公司合作夥伴版本的託管服務供應商 (MSP) 現在提供即付即用選項！<br/>此功能有助於 MSP 完全發揮其靈活性、可擴展性和盈利能力，方便他們以最適合自身與終端客戶的交易方式運用 Avast Business 解決方案。',
			action: '深入瞭解',
		},
	},
	widgets: {
		action: {
			addWidget: '新增 Widget',
			editWidget: '編輯 Widget',
			editWidgets: '編輯 Widget',
		},
		common: {
			columnPlaceholder: '新增或移動 Widget...',
			savingLoader: '正在儲存 Widget 變更...',
			rowTitle: '列（{{count}} 欄）',
			rowTitle_other: '列（{{count}} 欄）',
			changesSaved: '已儲存 Widget 變更',
			oneColumnRow: '一欄的列',
			twoColumnsRow: '兩欄的列',
		},
		config: {
			type: '類型',
			ownName: '自己的名稱',
			ownNamePlaceholder: 'Widget 名稱',
			columns: '可見欄',
			columnsPlaceholder: '選取至少一欄',
		},
		setting: {
			days: '天',
			limit: '最大列數',
		},
	},
	locale: {
		language: {
			saving: '正在變更系統語言',
			success: '系統語言已變更。',
			confirmation: {
				title: '變更系統語言',
				message: '變更帳戶的語言設定，也會影響 Avast 的發票和電子郵件所使用的語言。您確定要進行這項變更嗎？',
			},
		},
		format: {
			saving: '變更日期和數字格式設定',
			success: '日期和數字格式已變更。',
		},
	},
};
