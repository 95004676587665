export default {
	appName: 'Système de gestion des commandes',
	menu: {
		closingBalance: 'Solde de clôture',
		creditLimit: 'Limite de crédit',
		creditStatus: 'État du crédit',
		customers: 'Clients',
		dashboard: 'Accueil',
		distributionPartners: 'Partenaires de distribution',
		licenses: 'Licences',
		orders: 'Commandes',
		quotes: 'Devis',
		purchase: 'Nouvel achat',
		promotions: 'Promotions',
		account: 'Mon compte',
		help: 'Aide et ressources',
		feedback: 'Partager un commentaire',
		retail: 'Codes de vente au détail',
		priceCalculator: 'Calculer le prix',
		flexibleBilling: 'Facturation flexible',
	},
	help: {
		main: {
			title: 'Aide et ressources',
			guide: {
				title: 'Découvrez les nouveautés du système de gestion des commandes !',
				text: 'Nous allons vous présenter les changements les plus importants.',
				action: 'Commencer la présentation',
			},
			videos: {
				title: 'Découvrez nos guides vidéo',
			},
		},
		guide: {
			paginator: '{{current}} sur {{total}}',
			slides: {
				welcome: {
					title: 'Bienvenue dans le nouveau système de gestion des commandes',
					text: [
						'Notre système de gestion des commandes a fait peau neuve ! Découvrez son nouveau design, son interface conviviale, sa navigation améliorée et ses performances fluides, qui vous offrent une expérience d’achat optimale. Jetez un coup d’œil aux widgets de la page d’accueil, qui vous permettent d’accéder rapidement aux commandes récentes, aux devis, aux licences arrivant à expiration, et bien plus encore.',
					],
				},
				pricing: {
					title: 'Tarification dynamique',
					text: [
						'Vous n’avez plus besoin d’attendre que votre commande soit réévaluée, ni d’ajouter des produits au panier pour en voir les prix. Nous affichons désormais les prix directement dans le catalogue des produits.',
						'Lorsque vous modifiez le nombre d’utilisateurs ou la quantité d’un produit, les prix sont automatiquement recalculés afin de vous fournir en permanence des informations actualisées. La valeur de la commande est également recalculée automatiquement lorsque vous ajoutez ou supprimez des produits du panier.',
					],
				},
				quickFilter: {
					title: 'Filtrage et recherche faciles des produits destinés aux particuliers ou aux entreprises',
					text: [
						'Nous avons ajouté un filtre rapide pour vous permettre d’afficher facilement les produits destinés uniquement aux particuliers ou aux entreprises. Si vous recherchez un produit spécifique, il vous suffit de saisir le nom ou le code d’un produit pour que la liste se mette automatiquement à jour.',
					],
				},
				licenseOperations: {
					title: 'Renouvellements plus faciles',
					text: [
						'Nous avons séparé les nouveaux achats des opérations liées aux licences (renouvellement, mise à niveau), de sorte que vous pouvez facilement consulter toutes les opérations associées aux licences disponibles directement lorsque vous affichez les licences. Il vous suffit de cliquer sur le bouton Opérations liées aux licences disponible dans la liste des licences ou sur la page des détails de la licence.',
					],
				},
				shoppingCart: {
					title: 'Accès facile au panier',
					text: [
						'Nous avons ajouté une icône de panier pour vous permettre d’accéder facilement à votre panier et de passer une commande dès que vous le souhaitez.',
						'Vous pouvez facilement combiner les nouveaux achats et les opérations liées aux licences (telles que le renouvellement) en une seule commande.',
					],
				},
				quickAccess: {
					title: 'Accès rapide à tous les dossiers nécessaires',
					text: [
						'Nous avons ajouté des boutons et des liens rapides à divers endroits du système de gestion des commandes, pour vous permettre d’accéder facilement aux informations relatives au dossier que vous êtes en train de consulter. Utilisez les boutons “Afficher les licences” et “Afficher les commandes” sur la page des détails du client.',
						'À partir de la page des détails d’une licence, vous pouvez ouvrir une commande connexe ou consulter les détails du client en cliquant sur les liens mis en évidence.',
					],
				},
				quotes: {
					title: 'Devis facilement accessibles',
					text: [
						'Nous avons créé un élément de menu distinct pour les devis que vous recevez de la part de votre responsable de compte, afin que vous puissiez les retrouver plus facilement. Vous trouverez également ces devis dans la section Commandes pour un accès facile.',
					],
				},
				finalStep: {
					title: 'C’est tout !',
					text: [
						'Nous espérons que la nouvelle interface, les nouvelles fonctionnalités et l’amélioration des performances rendront votre expérience du système de gestion des commandes plus agréable.',
						'N’hésitez pas à nous faire part de vos commentaires et de vos suggestions d’amélioration en cliquant sur l’élément de menu « Partager un commentaire ».',
						'Vous pouvez à nouveau consulter ce guide d’introduction à tout moment en cliquant sur “Aide et ressources” dans le menu.',
					],
				},
			},
		},
		videos: {
			title: 'Guides vidéo',
			list: {
				navigations: 'Navigation dans le système de gestion des commandes',
				newPurchase: 'Création d’une nouvelle commande',
				renewal: 'Création d’une commande de renouvellement',
				quotes: 'Réception d’un devis de la part du responsable de compte',
			},
		},
	},
};
