export default {
	actions: {
		create: 'Crear nueva promoción',
		edit: 'Editar promoción',
		disable: {
			action: 'Desactivar promoción',
			success: 'La promoción se desactivó',
			loading: 'Desactivando promoción...',
			confirmation: {
				title: 'Desactivación de promoción',
				message: '¿Realmente desea desactivar esta promoción? Esta acción no se puede devolver.',
			},
		},
		form: {
			create: 'Enviar promoción',
			update: 'Actualizar promoción',
		},
		extraFilters: 'Más filtros',
		export: 'Exportar promociones',
	},
	common: {
		effectiveDate: 'Fecha de entrada en vigor',
		filterPromotions: 'Filtrar promociones',
		partyCriteria: 'Criterios de parte',
		partyCriteriaInfo: 'Para continuar, seleccione un socio o al menos un criterio de parte.',
		productCriteria: 'Criterio de producto',
		creatingPromotion: 'Creación de promoción',
		updatingPromotion: 'Actualizando promoción',
		invalidPromotionStartDate: {
			title: '¿Desea guardar la promoción?',
			message: 'La fecha de inicio de la promoción es pasada. Ya no podrá editar la promoción.',
		},
	},
	error: {
		promotionNotFound: 'No se encontró la promoción.',
		createPromotion: 'No es posible crear la promoción.',
		updatePromotion: 'No es posible actualizar la promoción.',
	},
	page: {
		create: {
			title: 'Nueva promoción de precios',
			success: 'Creó correctamente una nueva promoción de precios.',
		},
		update: {
			title: 'Actualizar promoción #{{id}}',
			success: 'Actualizó correctamente la promoción de precios.',
			notEditable: 'No se puede editar la promoción actual porque es posible que esté incluida en un pedido.',
		},
	},
	placeholder: {
		types: 'Todos los tipos de promoción',
		partyTiers: 'Todos los niveles',
		categories: 'Todas las categorías',
		licenseOperations: 'Todas las operaciones de licencia',
		partyCountries: 'Todos los países',
		partyTypes: 'Todos los tipos de partes',
		products: 'Todos los productos',
	},
	entity: {
		id: 'Id. de promoción',
		name: 'Nombre de promoción',
		date: 'Rango de fechas de validez',
		partyType: 'Tipo de parte',
		partyType_other: 'Tipos de parte',
		partyTier: 'Nivel de parte',
		partyTier_other: 'Niveles de parte',
		partyCountry: 'País de parte',
		partyCountry_other: 'Países de parte',
		licenseOperation: 'Tipo de operaciones de licencia',
		licenseOperation_other: 'Tipos de operaciones de licencia',
		category: 'Categoría de producto',
		product: 'Producto',
		product_other: 'Productos',
		quantityMin: 'Cantidad mínima',
		rate: 'Tarifa de descuento',
		scope: 'Alcance',
		type: 'Tipo de promoción',
		unitsFrom: 'Unidades de',
		unitsTo: 'Unidades a',
	},
	tooltip: {
		zeroQuantity:
			'Una cantidad de cero significa que la promoción se aplicará sin importar cuántas licencias se pidan.',
	},
};
