import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum PurchaseBillablePartyTypeEnum {
	PARTNER = 'PARTNER',
	CUSTOMER = 'CUSTOMER',
}

export const purchaseBillablePartyTypeEnumUtils = generateEnumUtils<PurchaseBillablePartyTypeEnum>(
	PurchaseBillablePartyTypeEnum,
	{ translatePath: 'enums:purchase.billablePartyType' },
);
