export default {
	actions: {
		detail: 'Lizenzdetails ansehen',
		export: 'Lizenzen exportieren',
		exportDetailed: 'Detaillierter Export',
		followingLicense: 'Folgelizenz',
		lastNDays: 'Abgelaufen letzte {{count}} Tage',
		nextNDays: 'Ablaufend nächste {{count}} Tage',
		operations: 'Lizenzvorgänge',
		precedingLicense: 'Vorherige Lizenz',
		registerTo: 'Eine Lizenz registrieren für...',
		relatedCustomer: 'Verbundenen Kunden anzeigen',
		relatedOrder: 'Verbundene Bestellung anzeigen',
	},
	error: {
		disallowed: 'Lizenzvorgänge sind für diese Lizenz nicht zulässig.',
		operation: {
			invalidParty: 'Die Lizenzparteien stimmen nicht mit den aktuellen Bestellparteien überein',
			alreadyInCart: {
				title: 'Diese Lizenz ist bereits im Warenkorb.',
				message:
					'Sie haben bereits eine Lizenz in Ihrem Warenkorb. Möchten Sie diese durch den ausgewählten Lizenzvorgang ersetzen?',
			},
			product: {
				noOperationsAvailable: 'Keine Lizenzierungsvorgänge verfügbar.',
			},
			partner: {
				unableToLoad: 'Der Lizenzpartner konnte nicht geladen werden.',
			},
			customer: {
				unableToLoad: 'Der Lizenzkunde konnte nicht geladen werden.',
			},
			distributionPartner: {
				unableToLoad: 'Der Lizenz-Vertriebspartner konnte nicht geladen werden.',
			},
			actions: {
				rewriteOperation: 'Lizenzvorgang ersetzen',
			},
			billablePartyRequired:
				'Für diese Lizenz sind keine fakturierbaren Parteien vorhanden. Gehen Sie zu "Neuer Kauf“ und wählen sie die fakturierbaren Parteien.',
		},
		registration: 'Die Lizenz konnte nicht registriert werden.',
		detailNotFound: 'Berechtigungsdetail-Nr. {{id}} nicht gefunden',
	},
	common: {
		operation: {
			addToCart: 'Der Lizenzvorgang wurde in den Warenkorb gelegt.',
			goToCart: 'Zum Warenkorb',
		},
	},
	entity: {
		createdAt: 'Erstellt am',
		customer: {
			contact: 'Kundenkontakt',
			id: 'Kunden-ID',
			email: 'Kunden-E-Mail',
			name: 'Kunden',
		},
		partner: {
			contact: 'Partnerkontakt',
			id: 'Partner-ID',
			name: 'Partner',
		},
		distributionPartner: {
			contact: 'Vertriebspartner-Kontakt',
			id: 'Vertriebspartner-ID',
			name: 'Vertriebspartner',
		},
		date: 'Datum',
		entitlementId: 'Berechtigungs-ID',
		licenseId: 'Lizenz-ID',
		licenseType: 'Lizenztyp',
		modifiedAt: 'Änderungsdatum',
		orderId: 'Bestell-ID',
		product: {
			termLength: 'Dauer der Laufzeit',
			type: 'Produkttyp',
		},
		registeredAt: 'Registrierungsdatum',
		status: 'Lizenzstatus',
		statusShort: 'Status',
		statusPlaceholder: 'Beliebiger Status',
		walletKey: 'Wallet-Code',
	},
	licenseOperation: {
		label: 'Lizenzvorgang',
	},
	registration: {
		registering: 'Lizenzregistrierung',
		success: 'Die Lizenz wurde für den Kunden: {{customer}} registriert.',
		error: 'Fehler bei Registrierung der Lizenz.',
		confirmation: {
			title: 'Lizenzregistrierung',
			place: 'Sind Sie sicher, dass Sie diesen Kunden mit dieser Lizenz verknüpfen wollen?',
			replace:
				'Lizenznummer {{licenseId}} ist bereits registriert. Möchten Sie mit der Registrierung dieser Lizenz fortfahren?',
		},
	},
	selectParty: {
		partner: {
			title: 'Partner für Bestellung auswählen',
			billableParty: 'Dieser Partner wird eine fakturierbare Partei sein.',
			requiredBillableParty: 'Bestellungen ohne Partner sind nicht möglich.',
			emptyBillableParty: 'Im nächsten Schritt wird der Kunde als fakturierbare Partei ausgewählt.',
			license: {
				title: 'Lizenzpartner',
				button: 'Lizenzpartner verwenden',
				buttonEmpty: 'Partner aus Bestellung entfernen',
				empty: 'Kein Partner für Lizenz vorhanden.',
			},
			order: {
				title: 'Bestellungspartner',
				button: 'Bestellungspartner beibehalten',
				empty: 'Kein Partner für Bestellung vorhanden.',
				notAllowed: 'Der Partner kann keine Lizenzvorgänge für diese Produktgruppe durchführen.',
			},
		},
		customer: {
			title: 'Kunden für Bestellung auswählen',
			billableParty: 'Dieser Kunde wird eine fakturierbare Partei sein.',
			requiredBillableParty: 'Eine Bestellung ohne Kunden ist nicht möglich.',
			emptyBillableParty: 'Ein Partner wird eine fakturierbare Partei sein.',
			license: {
				title: 'Lizenzkunde',
				button: 'Lizenzkunden verwenden',
				buttonEmpty: 'Kunden aus Bestellung entfernen',
				empty: 'Kein Kunde für Lizenz vorhanden.',
			},
			order: {
				title: 'Bestellungskunde',
				button: 'Bestellungskunden beibehalten',
				empty: 'Kein Kunde für Bestellung vorhanden.',
			},
		},
		distributionPartner: {
			title: 'Vertriebspartner für Bestellung auswählen',
			license: {
				title: 'Lizenz-Vertriebspartner',
				button: 'Lizenz-Vertriebspartner verwenden',
				buttonEmpty: 'Vertriebspartner aus Bestellung entfernen',
				empty: 'Kein Vertriebspartner für Lizenz vorhanden',
				notAllowed: 'Dem ausgewählten Partner kann kein Vertriebspartner zugewiesen werden.',
			},
			order: {
				title: 'Vertriebspartner für Bestellung',
				button: 'Vertr.- Partner',
				empty: 'Es ist kein Vertriebspartner für die Bestellung vorhanden.',
				notAllowed: 'Dem ausgewählten Partner kann kein Vertriebspartner zugewiesen werden.',
			},
		},
	},
};
