import React from 'react';
import { useTranslation } from 'react-i18next';
import { IComparativeData } from 'submodule/comparativeFeatures';
import { Badge } from 'react-bootstrap';
import { isString } from 'lodash';

type THeadRowProps = {
	comparativeData: IComparativeData;
};

export const HeadRow = (props: THeadRowProps) => {
	const {
		comparativeData: { products },
	} = props;
	const [t] = useTranslation('submodules');
	const isMulti = products.length !== 1;

	return (
		<tr>
			<th />
			{products.map((list, listIndex) => (
				<React.Fragment key={listIndex}>
					{isMulti && listIndex !== 0 && <th className="is-separator" />}
					{list.map((product) => {
						let label: string | null = null;
						if (product.isEquivalent) {
							label = t('comparativeFeatures.equivalentLabel');
						} else if (product.label) {
							label = product.label;
						}

						return (
							<th key={product.productGroup.code}>
								<div className="th-container">
									<div dangerouslySetInnerHTML={{ __html: product.comparativeProduct.name }} />
									{isString(label) && (
										<Badge
											pill
											bg="success"
										>
											{label}
										</Badge>
									)}
								</div>
							</th>
						);
					})}
				</React.Fragment>
			))}
		</tr>
	);
};
