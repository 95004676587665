export default {
	error: {
		creditStatusNotFound: '找不到信用狀態！',
	},
	tabs: {
		detail: '信用摘要',
		transactions: '交易清單',
	},
	entity: {
		companyName: '公司',
		countryCode: '國家/地區',
		creditAvailable: '可用信用',
		creditLimit: '信用額度限制',
		percentConsumed: '消耗百分比',
		unbilledBalance: '未開票餘額',
		unpaidBalance: '未付餘額',
	},
	entityShort: {
		creditAvailable: '可供使用',
		creditLimit: '限制',
		percentConsumed: '消耗百分比',
		unbilledBalance: '未計費',
		unpaidBalance: '未付款',
	},
	entityTransaction: {
		amount: '金額',
		createdAt: '建立時間',
		orderId: '參考 ID',
		source: '來源',
	},
	widget: {
		name: '類型',
		value: '信用',
	},
};
