import { IEntityPartner } from 'module/partners';
import { createAxiosInstance } from 'api/setupInterceptors';
import { fillUrlParameters } from 'js/utils/common';
import { IPartnerRequestParams } from 'module/purchase';
import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import { TPartnerPriceLists } from 'js/priceList';
import { API_PRICE_LIST_KEYS } from 'js/priceList/priceListConfig';
import { logError } from 'js/utils/app';
import { priceListEntityNormalizer } from 'js/priceList/normalizer';

export const apiPartnerPriceListsPromise = (
	partnerId: IEntityPartner['id'],
	config?: AxiosRequestConfig,
	axiosInstance?: AxiosInstance,
) => {
	const api = axiosInstance || createAxiosInstance();
	const [url] = fillUrlParameters<IPartnerRequestParams>(API_PRICE_LIST_KEYS.PARTNER_PRICE_LISTS, { partnerId });

	return new Promise<TPartnerPriceLists>(async (resolve, reject) => {
		const response = await api
			.get<TPartnerPriceLists>(`/${url}`, {
				...config,
			})
			.catch((err) => {
				logError(err);
				reject(err);
			});

		if (response) {
			resolve(priceListEntityNormalizer.normalizeList(response.data));
		}
		reject(new Error('No response for partner price list.'));
	});
};
