import React, { PropsWithChildren, useContext } from 'react';
import { InitiateRefundForm } from 'submodule/refunds/initiate/InitiateRefundForm';
import { TChargeOrderId, TCurrency, TPeriodActivityUuid } from 'types';
import { IEntityRefundableAmount } from 'submodule/refunds';
import { useTranslation } from 'react-i18next';
import { useApiChargeOrder } from 'js/chargeOrder/useApiChargeOrder';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { Alert, Toast } from '@avast/react-ui-components';
import { createRefundRequest, getInitialFormData } from 'submodule/refunds/refundUtils';
import { logDebug } from 'js/utils/app';
import { API_REFUNDS_KEYS, useApiRefundableAmount, useApiRefundProcess } from 'submodule/refunds/hooks/useApiRefunds';
import { toast } from 'react-toastify';
import { resolveRefundProcessApiError } from 'submodule/refunds/apiError';
import { useAsyncInvalidateQueries } from 'js/hooks/useInvalidateQueries';
import type { TFunction } from 'i18next';
import { InitiateRefundFormEnum } from 'submodule/refunds/enums';
import { useApiErrorContext, useAppContext } from 'js/contexts';

interface IInitiateRefundContextProps {
	chargeOrderId: TChargeOrderId;
	onSuccess: () => void;
	onError: () => void;
	onCancel: () => void;
	onChangeView: (step: InitiateRefundFormEnum) => void;
	periodActivityUuid?: TPeriodActivityUuid;
}

export interface IInitiateRefundContext {
	currency: TCurrency;
	t: TFunction<'submodules'>;
	onChangeView: (step: InitiateRefundFormEnum) => void;
	refundTypes: IEntityRefundableAmount['refundTypes'];
	periodActivityUuid?: TPeriodActivityUuid;
}

const InitiateRefundContext = React.createContext<IInitiateRefundContext>({} as IInitiateRefundContext);
InitiateRefundContext.displayName = 'InitiateRefundContext';

export const useInitiateRefundContext = () => useContext(InitiateRefundContext);

export const InitiateRefundContextProvider = (props: PropsWithChildren<IInitiateRefundContextProps>) => {
	const { chargeOrderId, onCancel, onChangeView, onSuccess, onError, periodActivityUuid } = props;
	const [t] = useTranslation('submodules');
	const { loadingModalRef } = useAppContext();
	const { setError } = useApiErrorContext();
	const { data: chargeOrder, query: chargeOrderQuery } = useApiChargeOrder({ id: chargeOrderId });
	const { data: refundableAmount, query: refundableAmountQuery } = useApiRefundableAmount({ id: chargeOrderId });
	const refundProcess = useApiRefundProcess();
	const invalidateQueries = useAsyncInvalidateQueries(Object.values(API_REFUNDS_KEYS));

	if (chargeOrderQuery.isFetching || refundableAmountQuery.isFetching) {
		return <LoadingPlaceholder type="BAR" />;
	}

	if (!chargeOrder || !refundableAmount || !chargeOrderId || !chargeOrder.currency) {
		return (
			<Alert
				variant="danger"
				caption={t('refunds.error.refundableAmount')}
			/>
		);
	}

	if (!refundableAmount.refundTypes.some((type) => type.isEnabled)) {
		return (
			<Alert
				variant="danger"
				caption={t('refunds.error.noRefundTypeEnabled')}
			/>
		);
	}

	const value: IInitiateRefundContext = {
		t,
		onChangeView,
		currency: chargeOrder.currency,
		refundTypes: refundableAmount.refundTypes,
		periodActivityUuid,
	};

	return (
		<InitiateRefundContext.Provider value={value}>
			<InitiateRefundForm
				initialValues={getInitialFormData(chargeOrder, refundableAmount)}
				onCancel={onCancel}
				onSubmit={async (values, formikHelpers) => {
					logDebug('submit', createRefundRequest(values));
					loadingModalRef.current?.show({
						title: t('refunds.initiate.process.loading'),
					});
					const response = await refundProcess.mutateAsync(createRefundRequest(values)).catch((error) => {
						setError({ error, resolve: resolveRefundProcessApiError, onClose: onError });
					});
					await invalidateQueries();

					loadingModalRef.current?.hide();
					formikHelpers.setSubmitting(false);
					refundableAmountQuery.remove();

					if (response) {
						// Success
						if (response.data) {
							toast.success(<Toast caption={t('refunds.initiate.process.success')} />);
							onSuccess();
						}

						// Not processed
						else {
							toast.error(<Toast caption={t('refunds.initiate.process.error')} />);
							onError();
						}
					}
				}}
			/>
		</InitiateRefundContext.Provider>
	);
};
