import React, { FunctionComponent } from 'react';
import { RouteName } from 'module/RouteName';
import { ModuleContainer } from 'js/layouts/container/ModuleContainer';
import { PurchaseStepper } from 'module/purchase/components/PurchaseStepper';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { PurchaseRoutes } from 'module/purchase/PurchaseRoutes';
import { useAuthContext } from 'js/contexts';

export const PurchaseContainer: FunctionComponent = () => {
	const [t] = useTranslation();
	const { isAuthorizedBuyer } = useAuthContext();

	if (!isAuthorizedBuyer) {
		toast.error(<Toast>{t('error:permissionDenied')}</Toast>);
		return (
			<Navigate
				to={RouteName.HOMEPAGE.DEFAULT}
				replace
			/>
		);
	}

	return (
		<>
			<PurchaseStepper />
			<ModuleContainer moduleMenuConfig={purchaseConfig} />

			<PurchaseRoutes />
		</>
	);
};
