export default {
	accountInformation: '帳戶資訊',
	_: {
		dateRangePlaceholder: '選取日期',
		NA: '不適用',
		no: '否',
		pageDetailTitle: '詳細資料 #{{id}}',
		yes: '是',
		loading: '正在載入...',
	},
	boolean: {
		true: '是',
		false: '否',
	},
	entity: {
		active: '使用中',
		amount: '金額',
		company: '公司',
		companyId: '公司 ID',
		companyName: '公司名稱',
		created: '已建立',
		currency: '貨幣',
		customer: '客戶',
		customerTotalPrice: '客戶總價',
		detailId: '詳細資料 ID',
		description: '描述',
		disabled: '已停用',
		discountAmount: '折扣金額',
		discountAmountUnit: '單位折扣金額',
		discountPercent: '折扣百分比',
		discountType: '折扣類型',
		discountUnitPrice: '折扣單價',
		distributionPartner: '經銷合作夥伴',
		enabled: '已啟用',
		id: 'ID',
		licenseKey: '授權碼',
		name: '名稱',
		orderStatus: '訂單狀態',
		partnerId: '合作夥伴 ID',
		partner: '合作夥伴',
		paymentAmount: '付款金額',
		paymentMethod: '付款方式',
		paymentStatus: '付款狀態',
		poNumber: 'PO 編號',
		price: '價格',
		priceWTax: '價格（含稅）',
		priceWOTax: '價格（不含稅）',
		product: '產品',
		productName: '產品名稱',
		quantity: '數量',
		requestedDiscountType: '要求的折扣類型',
		secondaryOrderId: '次要訂單 ID',
		sku: 'SKU',
		srpUnitPrice: 'SRP 單價',
		state: '州',
		status: '狀態',
		subTotalWOTax: '小計（不含稅）',
		tax: '稅',
		total: '總計',
		totalAmount: '總金額',
		totalAmountWOTax: '總金額（不含稅）',
		totalAmountWTax: '總金額（含稅）',
		totalPrice: '總價',
		totalTax: '總稅金',
		totalWTax: '總計（含稅）',
		transaction: '交易',
		unit: '單位',
		unit_other: '單位',
		unitPrice: '單價',
		unitType: {
			SEATS: '套數',
			SEATS_other: '套數',
			SESSIONS: '工作階段',
			SESSIONS_other: '工作階段',
			STORAGE: '儲存裝置',
			STORAGE_other: '儲存裝置',
			DEVICE: '裝置',
			DEVICE_other: '裝置',
		},
		validity: '有效性',
		variantCount: '{{count}} 個變體',
		variantCount_other: '{{count}} 個變體',
	},
	contact: {
		firstName: '名字',
		lastName: '姓氏',
		name: '聯絡人名稱',
		email: '電子郵件地址',
		billingEmail: '帳單電子郵件地址',
		phone: '手機',
		website: '網站',
		id: '聯絡人 ID',
		taxId: '稅號 ID',
		secondaryTaxId: '次要稅號 ID',
	},
	address: {
		caption: '地址',
		city: '城市',
		country: '國家/地區',
		state: '州/省',
		postalCode: '郵遞區號',
		street: '街道',
	},
	dates: {
		billing: '帳單日期',
		created: '建立日期',
		end: '結束日期',
		expire: '到期日期',
		payment: '付款日期',
		paymentDue: '付款截止日期',
		start: '開始日期',
	},
	datePeriod: {
		DAY: '{{count}} 天',
		DAY_other: '{{count}} 天',
		MONTH: '{{count}} 個月',
		MONTH_other: '{{count}} 個月',
		YEAR: '{{count}} 年',
		YEAR_other: '{{count}} 年',
	},
	actions: {
		back: '上一步',
		newPurchase: '新品全新購買',
		cancel: '取消',
		close: '關閉',
		confirm: '確認',
		continue: '繼續',
		detail: '檢視詳細資料',
		download: '下載',
		emptyCart: '清空購物車',
		logout: '登出',
		next: '下一頁',
		previous: '上一個',
		reset: '重設',
		retry: '重試',
		removeFile: '移除檔案',
		save: '儲存',
		select: '選取',
		submitForm: '提交',
		submitChanges: '送出變更',
		show: '顯示',
		showAll: '全部顯示',
		showMore: '顯示更多',
		showLess: '縮略顯示',
		copied: '複製成功',
	},
	validator: {
		email: '值必須是有效的電子郵件地址。',
	},
	table: {
		useFilter: '使用篩選器或啟動搜尋',
	},
	filter: {
		quickFilters: '快速篩選器',
		partnerIsRequired: '請選取一個合作夥伴或啟動搜尋。',
		typeAtLeastNCharacters: '在篩選器中輸入至少 {{count}} 個字元。',
	},
	header: {
		cart: '購物車',
		cartIsEmpty: '購物車是空的',
	},
	productGroupExceptions: {
		premiumRemoteControlQuantityDisabled:
			'由於 Premium Remote Control 可以安裝在無限數量的裝置上，因此您無需變更這些項目的工作階段，因為並行工作階段取決於您認為可以同時連線的裝置數量。',
		cloudBackupStorageInfo:
			'透過變更 Storage 屬性，您可以定義要購買 100GB 儲存桶的次數。例如「Storage = 5」表示一份授權的最終購買儲存容量為 500GB。',
	},
	downloadFile: {
		loading: '下載檔案 {{filename}}',
		error: '無法下載檔案',
	},
	appVersionGuard: {
		caption: 'OMS 已推出新版本！',
		message: '請重新載入頁面以取得新版本。',
		action: '重新載入',
	},
};
