import { ILocaleConfig } from 'types/config';

const numbro = require('numbro/languages/cs-CZ');

export const cs_CZ: ILocaleConfig = {
	name: 'Czech (Czech Republic)',
	locale: 'cs-CZ',
	language: 'cs',
	default: true,
	format: {
		numbro,
		datetime: 'cs-CZ',
	},
};
