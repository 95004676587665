import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@avast/react-ui-components';
import { DataTable } from 'js/components/molecules/DataTable';
import { numberFormatter } from 'js/utils/number';
import { ordersConfig } from 'module/orders/ordersConfig';
import { IEntityOrder } from 'module/orders';
import { standardOrderApprovalFlagEnumUtils } from 'module/purchase/enums';
import { lineItemDiscountTypeEnumUtils, standardOrderDiscountCategoryEnumUtils } from 'module/orders/enums';

type TDiscountCardProps = {
	order: IEntityOrder;
};

export const DiscountCard = ({ order }: TDiscountCardProps): ReactElement => {
	const [t] = useTranslation(ordersConfig.trNamespace);
	let discountText = t('entity.noDiscount');

	const hasOrderDiscount = order.customerSegment !== 'Commercial' || order.discount?.category !== 'None';

	if (order.hasLineDiscount) {
		discountText = t('discountType.line');
	} else if (hasOrderDiscount) {
		discountText = t('discountType.other', {
			type: lineItemDiscountTypeEnumUtils.getText(order.discount?.type),
			category: standardOrderDiscountCategoryEnumUtils.getText(order.discount?.category),
		});
	}

	return (
		<Card>
			<Card.Header>{t('entity.discountInformation')}</Card.Header>
			<Card.Body className="p-0">
				<DataTable>
					<DataTable.Tr name={t('entity.discountApplied')}>{discountText}</DataTable.Tr>
					<DataTable.Tr name={t('entity.approvalStatus')}>
						{standardOrderApprovalFlagEnumUtils.getText(order.discount?.approvalFlag)}
					</DataTable.Tr>
					{!order.hasLineDiscount && (
						<DataTable.Tr name={t('common:entity.discountPercent')}>
							{numberFormatter.percentage(order.discount?.percent)}
						</DataTable.Tr>
					)}
					<DataTable.Tr name={t('common:entity.discountAmount')}>
						{numberFormatter.currency(order.discount?.flat, order.currency)}
					</DataTable.Tr>
					<DataTable.Tr name={t('entity.orderAfterDiscount')}>
						{numberFormatter.currency(order.totalAmountWithoutTax, order.currency)}
					</DataTable.Tr>
				</DataTable>
			</Card.Body>
		</Card>
	);
};
