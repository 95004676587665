import { IPromotionListFilter } from 'module/promotions';
import { dateToApiDate } from 'js/utils/dateTime';
import { isEmpty, trim } from 'lodash';

export const promotionFilterNormalizer = {
	denormalize(filter: IPromotionListFilter | undefined): IPromotionListFilter {
		const _filter = { ...filter };
		if (_filter.effectiveDate) {
			_filter.effectiveDate = dateToApiDate(_filter.effectiveDate, true);
		}

		if (!isEmpty(_filter.name)) {
			_filter.name = `%${trim(_filter.name, '%')}%`;
		}

		return _filter;
	},
};
