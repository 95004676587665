import { useCallback } from 'react';
import { IAuthContextState } from 'js/reducers/authContextReducer';
import { useAuthUserData } from 'module/security/hooks/useAuthUserData';
import { useIsRefreshRestricted } from 'js/hooks/useIsRefreshRestricted';

export const useRefreshAuthState = () => {
	const authUserData = useAuthUserData();
	const isRefreshRestricted = useIsRefreshRestricted();

	return useCallback(
		(state: IAuthContextState | null): Promise<IAuthContextState | null> =>
			new Promise(async (resolve) => {
				if (!state?.token) {
					resolve(null);
					return;
				}

				// Do not run when is restricted
				if (isRefreshRestricted) {
					resolve(state);
					return;
				}

				// Update partner data
				const data = await authUserData({ token: state.token, refresh_token: state.refresh_token });
				if (data) {
					resolve({
						...state,
						...data,
					});
					return;
				}

				resolve(null);
			}),
		[authUserData, isRefreshRestricted],
	);
};
