export default {
	common: {
		allPartnerType: 'すべてのパートナー',
		avastId: 'アバスト パートナー ID',
		loadingDetail: 'パートナーの詳細を読み込み中',
	},
	error: {
		unableToLoadTerms: '利用規約を読み込むことができません。',
		unableToLoadPartnerDetail: 'パートナーの詳細を読み込むことができません',
	},
	entity: {
		accountType: '種類',
		accountSubType: 'サブタイプ',
		company: '会社',
		email: 'メール アドレス',
		id: 'パートナー ID',
		salesforceId: 'Salesforce ID',
		name: '氏名',
	},
	select: {
		title: 'パートナーを選択',
		button: {
			label: 'パートナー',
			placeholder: 'パートナーを選択',
		},
		filter: {
			placeholder: 'パートナーを検索',
		},
	},
};
