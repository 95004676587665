export default {
	appName: 'Sistema de administración de pedidos',
	menu: {
		closingBalance: 'Saldo final',
		creditLimit: 'Límite crediticio',
		creditStatus: 'Estado crediticio',
		customers: 'Clientes',
		dashboard: 'Inicio',
		distributionPartners: 'Socios de distribución',
		licenses: 'Licencias',
		orders: 'Pedidos',
		quotes: 'Cotizaciones',
		purchase: 'Nueva compra',
		promotions: 'Promociones',
		account: 'Mi cuenta',
		help: 'Ayuda y recursos',
		feedback: 'Compartir comentarios',
		retail: 'Códigos de tienda minorista',
		priceCalculator: 'Calculadora de precios',
		flexibleBilling: 'Facturación flexible',
	},
	help: {
		main: {
			title: 'Ayuda y recursos',
			guide: {
				title: 'Novedades en OMS',
				text: 'Le mostraremos algunos de los cambios más importantes.',
				action: 'Comenzar recorrido',
			},
			videos: {
				title: 'Ver videos instructivos',
			},
		},
		guide: {
			paginator: '{{current}} de {{total}}',
			slides: {
				welcome: {
					title: 'Le damos la bienvenida al nuevo OMS',
					text: [
						'OMS tiene nueva apariencia. Descubra el nuevo diseño, la interfaz de usuario fácil de usar, la navegación mejorada y el rendimiento optimizado para obtener la mejor experiencia de compra. Conozca los widgets de la página principal que brindan acceso a pedidos recientes, cotizaciones, licencias por caducar y mucho más.',
					],
				},
				pricing: {
					title: 'Precios dinámicos',
					text: [
						'Ya no necesita esperar a que el precio del pedido cambie ni falta que agregue productos al carrito de compras para ver los precios. Ahora, mostramos los precios directamente en el catálogo de productos.',
						'A medida que se ajusta la cantidad de puestos o de un producto, los precios se recalculan automáticamente para siempre mostrar la información más actualizada. El valor del pedido también se recalcula automáticamente a medida que se agregan o se eliminan productos del carrito de compras.',
					],
				},
				quickFilter: {
					title: 'Filtrar y buscar fácilmente productos para clientes y empresas',
					text: [
						'Agregamos un filtro rápido para que sea más fácil mostrar solo los productos para clientes o solo los que son para empresas. Si busca un producto específico, simplemente empiece a escribir un nombre o código de producto y la lista se actualizará automáticamente para coincidir con los datos ingresados.',
					],
				},
				licenseOperations: {
					title: 'Las renovaciones son más fáciles',
					text: [
						'Separamos las compras nuevas de las operaciones relativas a licencias (como renovaciones o actualizaciones), de modo que sea más fácil ver todas las operaciones relativas a las licencias disponibles directamente cuando se ven las licencias. Simplemente haga clic en el botón Operaciones de licencias disponible en la lista de licencias o en la página de detalles de la licencia.',
					],
				},
				shoppingCart: {
					title: 'Acceso fácil al carrito de compras',
					text: [
						'Agregamos un ícono de carrito de compras para que sea más fácil acceder a él y proceder con el pedido cuando sea posible.',
						'Se pueden combinar fácilmente nuevas compras con operaciones relativas a las licencias (como renovaciones) en un pedido.',
					],
				},
				quickAccess: {
					title: 'Acceso rápido a todos los registros necesarios',
					text: [
						'Agregamos botones rápidos y vínculos en varios lugares de OMS para facilitar el acceso a la información relacionada con el registro que se está viendo. Use los botones “Ver licencias” y “Ver pedidos” de la página de detalles del cliente.',
						'Desde la página de detalles de la licencia, abra un pedido relacionado o vea los detalles del cliente haciendo clic en los vínculos resaltados.',
					],
				},
				quotes: {
					title: 'Cotizaciones fácilmente accesibles',
					text: [
						'Creamos un elemento de menú aparte para las cotizaciones que recibe del Administrador de cuentas para que le resulte más fácil encontrarlas. Estas cotizaciones también se encuentran entre los Pedidos para acceder fácilmente.',
					],
				},
				finalStep: {
					title: '¡Eso es todo!',
					text: [
						'Esperamos que la nueva interfaz de usuario con las nuevas funciones y el rendimiento mejorado le permitan disfrutar de una mejor experiencia en OMS.',
						'Comparta sus comentarios y sugerencias sobre mejoras haciendo clic en el elemento de menú "Compartir comentarios".',
						'Tenga en cuenta que puede revisar esta guía introductoria en cualquier momento haciendo clic en la opción “Ayuda y recursos” del menú.',
					],
				},
			},
		},
		videos: {
			title: 'Videos instructivos',
			list: {
				navigations: 'Navegación en OMS',
				newPurchase: 'Creación de pedido de compra nuevo',
				renewal: 'Creación de pedido de renovación',
				quotes: 'Recepción de una cotización del Administrador de cuentas',
			},
		},
	},
};
