import React, { ReactElement } from 'react';
import { customersConfig } from 'module/customers/customersConfig';
import { SelectCustomerModal } from 'module/customers/components';
import {
	TSelectCustomerModalExtraProps,
	TSelectCustomerModalProps,
} from 'module/customers/components/SelectCustomerModal';
import { IEntityCustomer } from 'module/customers';
import { SelectModal } from 'js/components/molecules/Modal/SelectModal';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';

export type TSelectCustomerProps = Omit<TSelectCustomerModalProps, 'onChange'> & {
	disabled?: boolean;
	onChange: (value: string | null) => void;
};

/**
 * Select customer filter field
 * @param {TSelectCustomerProps} props
 * @return {ReactElement}
 * @constructor
 */
export const SelectCustomer = (props: TSelectCustomerProps): ReactElement => {
	const { onChange, ...rest } = props;

	return (
		<SelectModal<IEntityCustomer, TSelectCustomerModalExtraProps>
			testId="selectCustomer"
			component={SelectCustomerModal}
			trNamespace={customersConfig.trNamespace}
			renderValue={getCustomerRenderName}
			onChange={(value) => onChange(value?.id || null)}
			{...rest}
		/>
	);
};
