export default {
	table: {
		noData: 'No data available yet',
		error: 'Error occurred while fetching data',
		noDataFilter: 'No data for the filter',
		retry: 'Retry request',
		perPage: 'per page',
		pagination: {
			total: 'Showing <strong>{{from}} to <0>{{to}}</0></strong> from <0>{{total}}</0> records.',
		},
	},
	datepicker: {
		goToToday: 'Go to This Month',
		rangeDivider: 'to',
		startDate: 'Start date',
		endDate: 'End date',
		rangeSelector: {
			today: 'Today',
			thisWeek: 'This Week',
			thisMonth: 'This Month',
			lastWeek: 'Last Week',
			lastMonth: 'Last Month',
			thisYear: 'This Year',
			lastYear: 'Last Year',
			customRange: 'Custom Range',
		},
		relative: {
			single: {
				today: 'Today',
				tomorrow: 'Tomorrow',
				yesterday: 'Yesterday',
			},
			range: {
				thisWeek: 'This Week',
				lastWeek: 'Last Week',
				thisMonth: 'This Month',
				lastMonth: 'Last Month',
				thisYear: 'This Year',
				lastYear: 'Last Year',
			},
		},
	},
	searchComboBox: {
		value: {
			placeholder: 'Enter a value...',
		},
	},
	notes: {
		noContent: 'No note available',
	},
	hgop: {
		shoppingCart: {
			title: 'Shopping Cart',
		},
	},
	chargeOrderDocuments: {
		entity: {
			documentType: 'Type of Document',
		},
		type: {
			payment_instructions: 'Payment Instructions',
			invoice: 'Invoice',
			vat_invoice: 'Invoice',
			credit_memo: 'Credit Memo',
			payg_price_breakdown_msp: 'Flexible Billing - Partner Report',
			payg_product_usage_report: 'Flexible Billing - Customer Report',
			payg_reports_all: 'Flexible Billing - Product Usage Reports (All)',
		},
	},
	export: {
		caption: 'Export in progress, this may take a few minutes.',
		autoDownload: 'The file will be downloaded automatically.',
		downloadError: 'Unable to download the file.',
		error: 'Export failed. Please try again later.',
	},
	common: {
		more: 'View more',
		apply: 'Apply',
	},
	select: {
		placeholder: 'Select option',
	},
	pagination: {
		prev: 'Prev',
		next: 'Next',
		page: 'Page',
		of: 'of',
		miniText: 'Page {{active}} of {{total}}',
	},
	modal: {
		cancel: 'Cancel',
		submit: 'Submit',
		apply: 'Apply',
	},
	rangeDatepicker: {
		placeholder: 'Select date',
	},
	singleDatepicker: {
		placeholder: 'Select date',
	},
	toast: {
		title: {
			success: 'Success',
			error: 'Error',
			info: 'Info',
			default: 'Info',
			dark: 'Info',
			warning: 'Warning',
		},
	},
	fileUpload: {
		placeholder: '<strong>Select</strong> or drag a file right here',
	},
};
