import React from 'react';
import { useCountrySelectOptions } from 'js/entities/country/useCountrySelectOptions';
import { FormikControl } from 'js/components/formik/FormikControl';
import type { TMultiSelectProps } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';

export const FormikLocationSelector = (props: TMultiSelectProps) => {
	const { countrySelectOptions, isLoading } = useCountrySelectOptions();
	const [t] = useTranslation(promotionsConfig.trNamespace);

	return (
		<FormikControl label={t('entity.partyCountry', { count: 0 })}>
			<FormikControl.MultiSelect
				placeholder={t('placeholder.partyCountries')}
				options={countrySelectOptions}
				isLoading={isLoading}
				closeMenuOnSelect={false}
				{...props}
			/>
		</FormikControl>
	);
};
