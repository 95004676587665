export default {
	page: {
		default: {
			title: 'Mon compte',
		},
		taxExemption: {
			title: 'Certificat d’exonération fiscale',
			shortTitle: 'Exonération fiscale',
			action: 'Envoyer une nouvelle exonération fiscale',
		},
		flexibleBilling: {
			title: 'Facturation flexible',
		},
		autoBillingAddCard: {
			title: 'Ajouter une nouvelle carte',
		},
	},
	error: {
		autoBillingSource: 'Vos informations de carte n’ont pas pu être chargées. Contactez votre responsable de compte.',
	},
	cards: {
		accountInformation: 'Informations du compte',
		contactInformation: 'Coordonnées',
		addressInformation: 'Adresse',
		terms: 'Conditions générales',
		legacyPaymentOptions: 'Cartes sauvegardées',
		personalization: 'Personnalisation',
	},
	common: {
		locale: {
			language: 'Langue du système',
			format: 'Formatage des dates et des nombres',
		},
	},
	form: {
		creditCard: {
			owner: 'Informations sur le propriétaire de la carte',
			creditCard: 'Carte bancaire',
			number: 'Numéro de carte',
			expiration: 'Date d’expiration (MM/AA)',
			cvv: 'CVV',
			submit: 'Modifier la carte',
			isPreferredPayAsYouGoPaymentMethod: 'Définir comme mode de paiement préféré pour la facturation flexible',
		},
		taxExemption: {
			title: 'Envoyer un nouveau certificat fiscal',
			taxAuthorityPlaceholder: 'Sélectionner l’autorité fiscale',
			file: 'Dossier d’exonération fiscale',
			declaration:
				'Sous peine de parjure, je déclare qu’à ma connaissance, les informations ci-dessus et les documents fournis sont complets et exacts.',
			success: 'Le formulaire d’exonération fiscale a été envoyé.',
		},
	},
	taxExemption: {
		taxAuthority: 'Autorité fiscale',
		startDate: 'Date de début de l’exonération',
		endDate: 'Date de fin de l’exonération',
		fileName: 'Nom du fichier',
		status: {
			ELIGIBLE: 'Éligible',
			NOT_ELIGIBLE: 'Non éligible',
		},
	},
	flexibleBilling: {
		paymentMethod: {
			title: 'Mode de paiement',
			subtitle: 'Sélectionnez votre mode de paiement préféré.',
			submitButton: 'Enregistrer le mode de paiement',
			success: 'Le mode de paiement préféré a été enregistré.',
			addCardFirst: 'Ajoutez d’abord une carte bancaire pour activer la facturation automatique.',
		},
	},
	creditCard: {
		entity: {
			number: 'Numéro de carte',
			expiration: 'Date d’expiration',
		},
		currentSource: {
			title: 'Facturation automatique',
			subtitle: 'Votre carte enregistrée',
			loading: 'Récupération des informations relatives à la carte',
			notFound: 'Vous n’avez pas enregistré de carte.',
			create: 'Ajouter une nouvelle carte',
		},
		message: {
			successCreate: 'Votre carte a été enregistrée.',
			successDelete: 'Votre carte a été supprimée.',
			saving: 'Enregistrement de votre carte',
		},
		delete: {
			title: 'Supprimer la carte de votre compte',
			confirmation: 'Voulez-vous vraiment supprimer la carte (•••• {{lastFourDigits}}) ?',
			loading: 'Suppression de la carte',
		},
		error: {
			bad_request: 'Carte bancaire non valide. Réessayez ou contactez votre responsable de compte.',
		},
	},
	legacyPaymentOptions: {
		unableToLoad: 'Impossible de charger les cartes enregistrées.',
		noStoredCards: 'Vous n’avez pas enregistré de carte.',
	},
};
