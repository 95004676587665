import { useApiList } from 'js/hooks/api';
import { IEntityLicense, ILicenseListByLicenseStringFilter } from 'module/licenses';
import { useCallback } from 'react';
import { API_LICENSES_KEYS } from 'module/licenses/hooks/useApiLicenses';

export const useAsyncApiLicenseListByLicenseString = () => {
	const api = useApiList<IEntityLicense, ILicenseListByLicenseStringFilter>({});

	return useCallback(
		(licenseString: string) =>
			api({
				queryKey: [API_LICENSES_KEYS.SEARCH, { licenseString }],
				meta: undefined,
			}),
		[api],
	);
};
