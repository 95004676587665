import React, { ReactElement } from 'react';
import { Formik } from 'formik';
import { INewPasswordData } from 'module/security';
import { FormikForm } from 'js/components/formik/FormikForm';
import { FormikControl } from 'js/components/formik/FormikControl';
import { SubmitButton } from 'js/components/atoms/Button';
import { useTranslation } from 'react-i18next';
import { securityConfig } from 'module/security/securityConfig';
import { useNewPasswordValidator } from 'module/security/hooks/useNewPasswordValidator';
import { useNewPassword } from 'module/security/hooks/useNewPassword';
import { SecurityFormLayout } from 'module/security/layouts';
import { RouteName } from 'module/RouteName';
import { Link } from 'react-router-dom';
import { useRouteParams } from 'js/hooks/useRouteParams';

type TArguments = {
	token: string;
};

export const PageNewPassword = (): ReactElement => {
	type Values = INewPasswordData;
	const [t] = useTranslation(securityConfig.trNamespace);
	const { token } = useRouteParams<TArguments>();

	const { validate } = useNewPasswordValidator();
	const { newPasswordRequest } = useNewPassword(token);

	return (
		<SecurityFormLayout title={t('page.newPassword.title')}>
			<p className="mb-4 text-muted fs-md text-center">{t('page.newPassword.info')}</p>
			<Formik<Values>
				initialValues={{ newPassword: '', confirmPassword: '' }}
				validate={validate}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(false);
					newPasswordRequest(values);
				}}
			>
				<FormikForm
					testId="newPassword"
					autoComplete="off"
				>
					<FormikControl label={t('page.newPassword.form.password')}>
						<FormikControl.Input
							name="newPassword"
							type="password"
							autoComplete="off"
							size="lg"
							autoFocus
						/>
					</FormikControl>
					<FormikControl label={t('page.newPassword.form.confirm')}>
						<FormikControl.Input
							name="confirmPassword"
							type="password"
							autoComplete="off"
							size="lg"
						/>
					</FormikControl>
					<div className="mt-6 text-center">
						<SubmitButton style={{ minWidth: 240 }}>{t('common:actions.submitForm')}</SubmitButton>
					</div>

					<div className="text-center mt-3">
						<Link
							to={RouteName.SECURITY.LOGIN}
							className="link"
						>
							{t('common.backToLogin')}
						</Link>
					</div>
				</FormikForm>
			</Formik>
		</SecurityFormLayout>
	);
};
