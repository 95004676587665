import React from 'react';
import { Headline, THeadlineProps } from 'js/components/molecules/Headline';
import { H1 } from '@avast/react-ui-components';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { usePageDetailContext } from 'js/contexts';
import { getDistributionPartnerRenderName } from 'module/distributionPartners/utils/distributionPartnerSelector';
import { IFlexibleBillingDistributionPartner } from 'module/flexibleBilling';

type TDetailHeadlineProps = Pick<THeadlineProps, 'rightContent'>;

export const DetailHeadline = (props: TDetailHeadlineProps) => {
	const {
		data: { distributionPartner },
	} = usePageDetailContext<IFlexibleBillingDistributionPartner>();

	return (
		<DefaultContainer>
			<Headline rightContent={props.rightContent}>
				<H1 type="h2">{getDistributionPartnerRenderName(distributionPartner)}</H1>
			</Headline>
		</DefaultContainer>
	);
};
