import { useApiCountry } from 'js/entities/country/useApiCountry';

export const useCountryName = (countryCode?: string | null, countryName?: string): string => {
	const { data: country } = useApiCountry({
		filter: { countryCode: countryCode! },
		queryConfig: { enabled: Boolean(countryCode) && !countryName },
	});

	return countryName || country?.name || '';
};
