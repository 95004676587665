import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum RefundStatusEnum {
	OPEN = 'open',
	FULLY_APPLIED = 'fully_applied',
	VOIDED = 'voided',
	PENDING = 'pending',
}

export const refundStatusEnumUtils = generateEnumUtils<RefundStatusEnum>(RefundStatusEnum, {
	translatePath: 'enums:refunds.status',
});
