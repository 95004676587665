import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { RouteName } from 'module/RouteName';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { buildParametrizedRoute } from 'js/utils/common';
import { usePageDetailContext } from 'js/contexts';
import { IFlexibleBillingDistributionPartner } from 'module/flexibleBilling';

export const FlexibleBillingDetailMenu: React.FC = () => {
	const [t] = useTranslation(flexibleBillingConfig.trNamespace);
	const {
		data: { distributionPartner },
	} = usePageDetailContext<IFlexibleBillingDistributionPartner>();

	return (
		<Nav
			variant="tabs"
			as="ul"
		>
			<Nav.Item as="li">
				<Nav.Link
					as={NavLink}
					to={flexibleBillingConfig.detailLinkById(distributionPartner.id)}
				>
					{t('tab.overview')}
				</Nav.Link>
			</Nav.Item>
			<Nav.Item as="li">
				<Nav.Link
					as={NavLink}
					end
					to={buildParametrizedRoute(RouteName.FLEXIBLE_BILLING.DETAIL_REPORTS, { id: distributionPartner.id })}
				>
					{t('tab.reports')}
				</Nav.Link>
			</Nav.Item>
		</Nav>
	);
};
