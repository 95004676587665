export default {
	actions: {
		addProductsToOrder: 'Produkte hinzufügen',
		cancel: 'Bestellung abbrechen',
		cancelQuote: 'Angebot abbrechen',
		createQuote: 'Neues Angebot erstellen',
		cancelLicense: 'Lizenz stornieren',
		cancelSelectedLicenses: 'Ausgewählte Lizenz stornieren',
		cancelSelectedLicenses_zero: 'Ausgewählte Lizenzen stornieren',
		cancelSelectedLicenses_other: 'Ausgewählte Lizenzen stornieren ({{count}})',
		detail: 'Einzelheiten zur Bestellung',
		detailQuote: 'Einzelheiten zum Angebot anzeigen',
		documents: 'Dokumente zur Bestellung anzeigen',
		edit: 'Bestellung bearbeiten',
		editQuote: 'Angebot bearbeiten',
		export: {
			default: 'Bestellungen exportieren',
			detailed: 'Detaillierter Export',
			quotes: 'Angebote exportieren',
		},
		exportQuotes: 'Angebote exportieren',
		licenses: 'Lizenzen anzeigen',
		placeQuote: 'Angebot als Bestellung aufgeben',
		priceDetails: 'Preisangaben anzeigen',
		thisMonth: 'Diesen Monat',
		viewClosingBalance: 'Abschlusssaldo für Bestellung anzeigen',
		pay: 'Zahlen',
		payEdit: 'Zahlen/Bearbeiten',
		payEditQuote: 'Zahlen/Angebot bearbeiten',
		payQuote: 'Angebot bezahlen',
	},
	common: {
		flexibleBilling: 'Flexible Abrechnungen',
		documents: 'Bestelldokumente',
		orderNumber: 'Bestellnummer {{id}}',
		quoteNumber: 'Angebotsnummer {{id}}',
		priceDetails: 'Preisdetails',
		licenses: 'Lizenzen',
		itemLicenses: 'Einzelposten-Lizenzen',
		paymentInstructions: 'Zahlungsanweisungen',
		pricingMessage: 'Vollständige Meldung zum Preis',
		promoMessage: 'Vollständige Meldung zu Werbeaktion',
		refunds: 'Rückerstattung für Bestellung',
		initiateRefund: 'Rückerstattung einleiten',
	},
	entity: {
		approvalStatus: 'Genehmigungsstatus',
		billablePartyName: 'Name des Vertragspartners',
		billablePartyType: 'Art des Vertragspartners',
		created: 'Erstellt am',
		createdBy: 'Erstellt von',
		creationSource: 'Erstellungsquelle',
		purchaseType: 'Kauftyp',
		purchaseTypePlaceholder: 'Alle Kauftypen',
		creationSourcePlaceholder: 'Beliebige Quelle',
		date: 'Datum',
		discountApplied: 'Angewandter Rabatt',
		discountInformation: 'Rabattinformationen',
		gcOrderNumber: 'Commerce-Bestellnr.',
		paymentStatusPlaceholder: 'Beliebiger Status',
		noDiscount: 'Kein Rabatt',
		opportunityId: 'Geschäftsmöglichkeitsnummer',
		orderAfterDiscount: 'Bestellung nach Rabatt',
		orderNumber: 'Bestellnummer',
		orderNumberShort: 'Bestell-Nr.',
		orderStatusPlaceholder: 'Beliebiger Status',
		originateAsQuote: 'Als Angebot anlegen',
		quoteNumber: 'Angebotsnummer',
		quoteNumberShort: 'Angebotsnr.',
		quoteStatus: 'Angebotsstatus',
		quoteType: 'Angebotsart',
		statementId: ' Abrechnungs-ID',
		totalAmount: 'Wert',
	},
	error: {
		orderNotFound: 'Bestellnummer {{id}} nicht gefunden',
		orderNotEditable: 'Bestellnummer {{id}} kann nicht bearbeitet werden',
		noProductsInOrder: {
			caption: 'Keine Produkte in dieser Bestellung',
			text: 'Bitte Produkte hinzufügen oder die Bestellung stornieren. Die Änderungen werden erst wirksam, wenn Sie die Bestellung einreichen/speichern.',
		},
		orderChangesAreNotPersist:
			'Schließen Sie die Bestellung ab oder speichern Sie sie, um die Änderungen zu speichern.',
		orderCancellation: 'Die Bestellung konnte nicht storniert werden.',
		orderSubmissionCancellation: 'Die Einreichung der Bestellung konnte nicht storniert werden.',
	},
	discountType: {
		line: 'Zeilenrabatt',
		other: '{{type}} {{category}}',
	},
	tab: {
		customer: 'Kunde',
		discretionaryDiscount: 'Ermessens- Rabatt',
		itemsInOrder: 'Elemente in Bestellung',
		itemsInQuote: 'Elemente in Angebot',
		licenses: 'Lizenzen',
		notes: 'Hinweise',
		orderStatus: 'Status der Bestellung',
		partner: 'Avast-Partner',
		quoteStatus: 'Angebotsstatus',
	},
	licenses: {
		inProcess: 'Bitte warten, während wir Lizenzen für Ihre Bestellung anfordern',
		error: {
			title:
				'Wir konnten zu diesem Zeitpunkt keine Lizenzen für Ihre Bestellung abrufen. Versuchen Sie es bitte später erneut.',
			detail:
				'Wir versuchen automatisch erneut, Ihre Lizenzen abzurufen. Dies kann einige Minuten dauern. Sobald die Lizenzen generiert sind, werden sie hier angezeigt, und Sie erhalten sie auch per E-Mail.',
		},
	},
	paymentInstructions: {
		title: 'Zahlungsanweisungen',
		description: 'Teilen Sie Ihrer Bank die folgenden Anweisungen zur Überweisung mit',
		amount: 'Betrag',
		referenceNumber: {
			label: 'Zahlungsreferenznummer',
			note: 'Geben Sie diese Nummer unbedingt mit an. Anderenfalls kann Ihre Zahlung unter Umständen nicht empfangen werden. Sollte es bei Ihrer Bank nicht möglich sein, diese Nummer in ihrer vollen Länge anzugeben, kopieren Sie sie bitte und fügen Sie sie in das Feld "Nachricht an den Empfänger" ein.',
		},
		accountHolder: 'Kontoinhaber',
		bankName: 'Name der Bank',
		accountNumber: 'Kontonummer',
		iban: {
			label: 'IBAN',
			note: '*Die IBAN bzw. die internationale Bankkontonummer (International Bank Account Number) ist ein globaler Standard, der zur Identifizierung von Bankkonten genutzt wird. Er wird zur Erleichterung der Zahlungsabwicklung und zur Vermeidung von Fehlern bei der Überweisung verwendet.',
		},
		bic: 'BIC',
		aba: 'ABA/Bankleitzahl',
		ach: 'ACH',
		error: {
			notAvailable: 'Zahlungsanweisungen sind nicht verfügbar.',
		},
	},
	cancelOrder: {
		title: 'Stornierung der Bestellung',
		message:
			'Die Stornierung einer Bestellung kann nicht rückgängig gemacht werden. Sie können die Bestellung zwar noch sehen, aber nicht mehr bearbeiten.',
		success: 'Bestellung wurde erfolgreich storniert.',
		error: 'Bei der Stornierung der Bestellung ist ein Fehler aufgetreten.',
		loading: 'Stornierung der Bestellung Nr. {{orderNumber}}',
		wtoc: {
			title: 'WTOC-Bestellung stornieren',
		},
	},
	discountApproval: {
		approved: 'Der Ermessensrabatt ist genehmigt, Sie können die Bestellung abschließen.',
		rejected:
			'Der Ermessensrabatt ist abgelehnt. Sie können die Bestellung bearbeiten und die Genehmigung erneut beantragen.',
		alert: {
			caption: 'Ermessensrabatt wartet auf Ihre Genehmigung',
			text: 'Prüfen Sie den Rabatt und entscheiden Sie, ob Sie den Ermessensrabatt genehmigen oder ablehnen wollen.',
			reject: 'Ablehnen',
			approve: 'Genehmigen',
		},
		alertApprove: {
			caption: 'Ermessensrabatt wurde abgelehnt',
			text: 'Es ist immer noch möglich, den Rabatt zu genehmigen.',
		},
		confirm: {
			approve: {
				title: 'Rabatt genehmigen',
				message: 'Bitte bestätigen Sie Ihre Genehmigung dieses Rabatts.',
				button: 'Bestätigen – diesen Rabatt genehmigen',
			},
			reject: {
				title: 'Rabatt ablehnen',
				message: 'Bitte bestätigen Sie Ihre Ablehnung dieses Rabatts.',
				button: 'Bestätigen – diesen Rabatt ablehnen',
			},
		},
		status: {
			success: {
				approve: 'Der Rabatt wurde genehmigt.',
				reject: 'Der Rabatt wurde abgelehnt.',
			},
			error: 'Die Änderung des Rabattstatus ist fehlgeschlagen.',
		},
	},
	cancelLicenses: {
		loading: 'Lizenz wird storniert',
		loading_other: 'Lizenzen werden storniert',
		success: 'Lizenz wurde storniert',
		success_other: 'Lizenzen wurden storniert',
		confirm: {
			title: 'Lizenz stornieren',
			title_other: 'Lizenzen stornieren',
			message: 'Sind Sie sicher, dass Sie die Lizenz stornieren möchten?',
			message_other: 'Sind Sie sicher, dass Sie die Lizenzen stornieren möchten?',
			message_note:
				'Bitte beachten Sie, dass im Falle der Stornierung aller verbleibenden Lizenzen die gesamte Bestellung storniert wird. Die Stornierung kann nicht rückgängig gemacht werden.',
		},
	},
};
