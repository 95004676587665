import React from 'react';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { useTranslation } from 'react-i18next';
import { Button } from '@avast/react-ui-components';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { AsyncInitiateRefundModal } from 'submodule/refunds/initiate/AsyncInitiateRefundModal';
import { IEntityClosingBalance } from 'module/closingBalance';
import { isClosingBalanceRefundable } from 'module/closingBalance/utils/common';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { useAuthContext } from 'js/contexts';

export const InitiateRefundButton = () => {
	const {
		data: closingBalance,
		query: { isFetching, refetch },
	} = usePageDetailContext<IEntityClosingBalance>();
	const { isRoleSalesOperations, authPartnerGroups } = useAuthContext();
	const [t] = useTranslation(closingBalanceConfig.trNamespace);
	const modalRef = useAsyncModalRef();

	if (!isRoleSalesOperations || !isClosingBalanceRefundable(closingBalance, authPartnerGroups)) {
		return null;
	}

	return (
		<>
			<Button
				size="sm"
				loading={isFetching}
				variant="outline-primary"
				onClick={() => modalRef.current?.show()}
				testId="initiateRefund"
			>
				{t('common.initiateRefund')}
			</Button>

			<AsyncInitiateRefundModal
				forwardedRef={modalRef}
				id={closingBalance.id}
				chargeOrderId={closingBalance.chargeOrderId!}
				onSettled={refetch}
				periodActivityUuid={closingBalance.periodActivityUuid}
			/>
		</>
	);
};
