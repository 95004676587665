export default {
	actions: {
		addProductsToOrder: 'Ajouter des produits',
		cancel: 'Annuler la commande',
		cancelQuote: 'Annuler le devis',
		createQuote: 'Créer un devis',
		cancelLicense: 'Annuler la licence',
		cancelSelectedLicenses: 'Annuler la licence sélectionnée',
		cancelSelectedLicenses_zero: 'Annuler les licences sélectionnées',
		cancelSelectedLicenses_other: 'Annuler les licences sélectionnées ({{count}})',
		detail: 'Afficher les détails de la commande',
		detailQuote: 'Afficher les détails du devis',
		documents: 'Afficher les documents associés à la commande',
		edit: 'Modifier la commande',
		editQuote: 'Modifier le devis',
		export: {
			default: 'Exporter des commandes',
			detailed: 'Exportation détaillée',
			quotes: 'Exporter des devis',
		},
		exportQuotes: 'Exporter des devis',
		licenses: 'Afficher des licences',
		placeQuote: 'Convertir le devis en commande',
		priceDetails: 'Afficher les détails du prix',
		thisMonth: 'Ce mois-ci',
		viewClosingBalance: 'Afficher la commande de solde de clôture',
		pay: 'Payer',
		payEdit: 'Payer/Modifier',
		payEditQuote: 'Payer/Modifier le devis',
		payQuote: 'Payer le devis',
	},
	common: {
		flexibleBilling: 'Facturation flexible',
		documents: 'Documents associés à la commande',
		orderNumber: 'Commande : n° {{id}}',
		quoteNumber: 'Devis : n° {{id}}',
		priceDetails: 'Détails du prix',
		licenses: 'Licences',
		itemLicenses: 'Licences relative à l’article',
		paymentInstructions: 'Instructions de paiement',
		pricingMessage: 'Message sur la tarification complète',
		promoMessage: 'Message sur la promotion complète',
		refunds: 'Remboursements liés à la commande',
		initiateRefund: 'Initier le remboursement',
	},
	entity: {
		approvalStatus: 'État de l’autorisation',
		billablePartyName: 'Nom de la partie facturable',
		billablePartyType: 'Type de partie facturable',
		created: 'Date de création',
		createdBy: 'Créateur',
		creationSource: 'Source de la création',
		purchaseType: 'Type d’achat',
		purchaseTypePlaceholder: 'Tout type d’achat',
		creationSourcePlaceholder: 'Toutes les sources',
		date: 'Date',
		discountApplied: 'Remise appliquée',
		discountInformation: 'Informations sur la remise',
		gcOrderNumber: 'N° de commande de commerce',
		paymentStatusPlaceholder: 'Tout état',
		noDiscount: 'Aucune remise',
		opportunityId: 'Numéro d’opportunité',
		orderAfterDiscount: 'Commande après remise',
		orderNumber: 'Numéro de commande',
		orderNumberShort: 'N° de commande',
		orderStatusPlaceholder: 'Tout état',
		originateAsQuote: 'Origine : devis',
		quoteNumber: 'Numéro de devis',
		quoteNumberShort: 'N° de devis',
		quoteStatus: 'État du devis',
		quoteType: 'Type de devis',
		statementId: ' N° de relevé',
		totalAmount: 'Valeur',
	},
	error: {
		orderNotFound: 'Commande n° {{id}} introuvable',
		orderNotEditable: 'Commande n° {{id}} non modifiable',
		noProductsInOrder: {
			caption: 'Aucun produit dans la commande',
			text: 'Ajoutez des produits ou annulez la commande. Les modifications ne sont pas applicables tant que vous n’avez pas envoyé/enregistré la commande.',
		},
		orderChangesAreNotPersist: 'Finalisez ou enregistrez la commande pour sauvegarder les modifications.',
		orderCancellation: 'La commande n’a pas pu être annulée.',
		orderSubmissionCancellation: 'L’envoi de la commande n’a pas pu être annulé.',
	},
	discountType: {
		line: 'Remise sur l’article',
		other: '{{type}} {{category}}',
	},
	tab: {
		customer: 'Client',
		discretionaryDiscount: 'Remise discrétionnaire',
		itemsInOrder: 'Articles de la commande',
		itemsInQuote: 'Articles du devis',
		licenses: 'Licences',
		notes: 'Notes',
		orderStatus: 'État de la commande',
		partner: 'Partenaire Avast',
		quoteStatus: 'État du devis',
	},
	licenses: {
		inProcess: 'Veuillez patienter pendant que nous demandons des licences pour votre commande.',
		error: {
			title:
				'Nous n’avons pas pu récupérer les licences associées à votre commande pour le moment. Veuillez vérifier plus tard.',
			detail:
				'Nous récupérerons automatiquement vos licences, ce qui peut prendre quelques instants. Une fois les licences générées, elles s’afficheront ici et vous les recevrez également par e-mail.',
		},
	},
	paymentInstructions: {
		title: 'Instructions de paiement',
		description: 'Fournissez à votre banque les instructions de virement suivantes',
		amount: 'Montant',
		referenceNumber: {
			label: 'Numéro de référence du paiement',
			note: 'N’oubliez pas d’indiquer ce numéro, faute de quoi votre paiement pourrait ne pas nous parvenir. Si votre banque ne vous permet pas d’utiliser ce numéro dans son intégralité, copiez et collez-le dans le champ « Message pour le destinataire ».',
		},
		accountHolder: 'Titulaire du compte',
		bankName: 'Nom de la banque',
		accountNumber: 'Numéro de compte',
		iban: {
			label: 'IBAN',
			note: 'L’IBAN est une norme mondiale utilisée pour identifier les comptes bancaires. Il sert à faciliter l’acheminement des paiements et éviter les erreurs d’acheminement.',
		},
		bic: 'BIC',
		aba: 'ABA/Code guichet',
		ach: 'ACH',
		error: {
			notAvailable: 'Les instructions de paiement ne sont pas disponibles.',
		},
	},
	cancelOrder: {
		title: 'Annulation d’une commande',
		message:
			'L’annulation d’une commande ne peut être annulée. Vous pourrez toujours consulter la commande, mais aucune action ne pourra être effectuée.',
		success: 'Commande annulée.',
		error: 'Une erreur s’est produite lors de l’annulation de la commande.',
		loading: 'Annulation de la commande n° {{orderNumber}}',
		wtoc: {
			title: 'Annulation de la commande WTOC',
		},
	},
	discountApproval: {
		approved: 'La remise discrétionnaire est approuvée, vous pouvez finaliser la commande.',
		rejected:
			'La remise discrétionnaire a été rejetée. Vous pouvez modifier la commande et demander à nouveau une autorisation.',
		alert: {
			caption: 'Une remise discrétionnaire est en attente de votre autorisation.',
			text: 'Vérifiez la remise et décidez d’approuver ou de rejeter la remise discrétionnaire.',
			reject: 'Rejeter',
			approve: 'Approuver',
		},
		alertApprove: {
			caption: 'La remise discrétionnaire a été rejetée.',
			text: 'Il est encore possible d’approuver la remise.',
		},
		confirm: {
			approve: {
				title: 'Approuver la remise',
				message: 'Confirmez que vous avez approuvé cette remise.',
				button: 'Confirmer - Approuver cette remise',
			},
			reject: {
				title: 'Rejeter la remise',
				message: 'Confirmez que vous avez rejeté cette remise.',
				button: 'Confirmer - Rejeter cette remise',
			},
		},
		status: {
			success: {
				approve: 'La remise a été approuvée.',
				reject: 'La remise a été rejetée.',
			},
			error: 'La modification de l’état de la remise a échoué.',
		},
	},
	cancelLicenses: {
		loading: 'Annulation de la licence',
		loading_other: 'Annulation des licences',
		success: 'La licence a été annulée.',
		success_other: 'Les licences ont été annulées.',
		confirm: {
			title: 'Annuler la licence',
			title_other: 'Annuler les licences',
			message: 'Voulez-vous vraiment annuler la licence ?',
			message_other: 'Voulez-vous vraiment annuler les licences ?',
			message_note:
				'En cas d’annulation de toutes les licences restantes, la commande sera annulée dans son intégralité. La procédure d’annulation ne peut pas être annulée.',
		},
	},
};
