export default {
	common: {
		discountWaitingToApprove: 'La remise est en attente de l’autorisation de votre directeur des ventes.',
		selectCurrencyModalTitle: 'Sélectionnez la devise du client',
		otherCustomerInformation: 'Autre',
	},
	entity: {
		contactId: 'ID du client',
		customerType: 'Type de client',
		discount: 'Remise',
		industry: 'Secteur d’activité',
	},
	error: {
		createCustomer: 'Impossible de créer le client.',
		updateCustomer: 'Impossible de mettre à jour le client.',
	},
	select: {
		title: 'Sélectionner un client',
		button: {
			placeholder: 'Sélectionner le client',
			label: 'Client',
		},
		filter: {
			placeholder: 'Rechercher un client',
			placeholderEmail: 'Rechercher un client au moyen d’une adresse e-mail',
			placeholderType: 'Type de client',
		},
		type: {
			EMAIL: 'E-mail',
			ID: 'ID Salesforce',
		},
	},
	page: {
		create: {
			title: 'Nouveau client',
			success: 'Vous avez créé un nouveau client.',
			form: {
				email: 'Utilisez une adresse e-mail authentique à laquelle votre client a accès.',
			},
		},
		update: {
			success: 'Vous avez mis à jour le client.',
		},
	},
	actions: {
		edit: 'Modifier le client',
		exportAll: 'Exporter tous les clients',
		newCustomer: 'Créer un client',
		relatedLicenses: 'Afficher les licences associées',
		relatedOrders: 'Afficher les commandes associées',
		relatedQuotes: 'Afficher les devis associés',
		viewLicenses: 'Afficher des licences',
		viewOrders: 'Afficher les commandes',
		viewQuotes: 'Afficher les devis',
		form: {
			create: 'Enregistrer en tant que nouveau client',
			update: 'Enregistrer les modifications',
		},
	},
};
