import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IApiSortBy } from 'types/api';
import type { SortingState } from '@tanstack/react-table';
import { sortByToTableSortingState, tableSortingStateToSortBy } from 'js/utils/common';
import { useCompare } from 'js/hooks/useCompare';

export type TUseTableSortingState = {
	sorting: SortingState;
	setSorting: Dispatch<SetStateAction<SortingState>>;
};

export const useTableSortingState = <Data extends object>(
	setApiSortBy: Dispatch<SetStateAction<IApiSortBy<Data> | undefined>>,
	apiSortBy: IApiSortBy<Data> | undefined,
	onChange?: (apiSortBy?: IApiSortBy<Data>) => void,
): TUseTableSortingState => {
	const [sorting, setSorting] = useState<SortingState>(sortByToTableSortingState<Data>(apiSortBy) || []);

	const isApiSortByChanged = useCompare(apiSortBy, true);
	const isSortingChanged = useCompare(sorting, true);

	// Disable below hooks on first render
	const [active, setActive] = useState(false);
	useEffect(() => setActive(true), []);

	useEffect(() => {
		if (isApiSortByChanged && active) {
			setSorting(sortByToTableSortingState<Data>(apiSortBy) || []);
		}
	}, [apiSortBy, setApiSortBy, isApiSortByChanged, active]);

	useEffect(() => {
		if (isSortingChanged && active) {
			const value = tableSortingStateToSortBy<Data>(sorting);
			setApiSortBy(value);
			onChange?.(value);
		}
	}, [sorting, setApiSortBy, isSortingChanged, onChange, active]);

	return {
		sorting,
		setSorting,
	};
};
