import React from 'react';
import { Button, Toast } from '@avast/react-ui-components';
import { ordersConfig } from 'module/orders/ordersConfig';
import { useTranslation } from 'react-i18next';
import { TEntityLicenseId } from 'module/licenses';
import { toast } from 'react-toastify';
import { useCancelOrderLicenses } from 'module/orders/hooks';
import { IEntityOrder } from 'module/orders';
import { TCancelOrderLicensesResult } from 'module/orders/hooks/useCancelOrderLicenses';

type TCancelOrderLicensesButtonProps = {
	order: IEntityOrder | null;
	entitlementIds: TEntityLicenseId[];
	onCancel?(result: TCancelOrderLicensesResult): void;
	multiple?: boolean;
	hidden?: boolean;
};

export const CancelOrderLicensesButton = (props: TCancelOrderLicensesButtonProps) => {
	const { entitlementIds, onCancel, order, multiple, hidden } = props;
	const [t] = useTranslation(ordersConfig.trNamespace);
	const disabled = entitlementIds.length === 0;
	const cancelOrderLicenses = useCancelOrderLicenses(order);

	if (hidden) {
		return null;
	}

	return (
		<Button
			size="sm"
			disabled={disabled}
			variant={disabled ? 'outline-primary' : 'outline-danger'}
			onClick={async () => {
				const result = await cancelOrderLicenses(entitlementIds);
				if (Boolean(result)) {
					toast.success(<Toast>{t('cancelLicenses.success', { count: entitlementIds.length })}</Toast>);
					onCancel?.(result);
				}
			}}
			testId="cancelSelectedLicenses"
		>
			{t(multiple ? 'actions.cancelSelectedLicenses' : 'actions.cancelLicense', { count: entitlementIds.length })}
		</Button>
	);
};
