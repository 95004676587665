import { Col, Row } from 'js/components/atoms/Row';
import { FormikControl } from 'js/components/formik/FormikControl';
import { RefundReasonEnum, refundReasonEnumUtils } from 'submodule/refunds/enums';
import { PriceSummary } from 'js/components/molecules/PriceSummary';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import React from 'react';
import { useInitiateRefundContext } from 'submodule/refunds/initiate/InitiateRefundContext';
import { useFormikContext } from 'formik';
import { IInitiateRefundForm } from 'submodule/refunds';
import { getRefundAmount } from 'submodule/refunds/refundUtils';

type TStepReviewProps = {
	onCancel: () => void;
};

export const StepReview = (props: TStepReviewProps) => {
	const { onCancel } = props;
	const { t, currency } = useInitiateRefundContext();
	const { values } = useFormikContext<IInitiateRefundForm>();

	return (
		<>
			<Row size="md">
				<Col
					lg={5}
					md={6}
				>
					<FormikControl label={t('refunds.entity.reason')}>
						<FormikControl.SingleSelect<RefundReasonEnum>
							name="refundReason"
							options={refundReasonEnumUtils.getSelectOptions()}
							isClearable={false}
							required
						/>
					</FormikControl>
				</Col>
				<Col lg={12}>
					<FormikControl label={t('refunds.entity.reasonNote')}>
						<FormikControl.Textarea
							name="refundReasonNote"
							required
							rows={3}
						/>
					</FormikControl>
				</Col>
			</Row>
			<PriceSummary
				headers={[t('refunds.entity.refundAmount')]}
				rows={[{ values: [getRefundAmount(values)] }]}
				currencyCode={currency}
				className="mt-5"
			/>
			<FormikFooterControls
				cancel={{ onClick: onCancel, children: t('common:actions.back') }}
				submit={{
					children: t('refunds.initiate.submit'),
				}}
			/>
		</>
	);
};
