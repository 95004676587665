import React from 'react';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { FormMessage } from '@avast/react-ui-components';
import { isDefined } from 'js/utils/common';

type TFormikErrorsProps<Keys extends string> = {
	fields: Keys[];
};

/**
 * Render error messages for specified fields
 * @param {TFormikErrorsProps} props
 * @returns {JSX.Element | null}
 * @constructor
 */
export const FormikErrors = <Keys extends string = string>(props: TFormikErrorsProps<Keys>) => {
	const { errors, touched } = useFormikContext();
	const errorList: string[] = [];

	props.fields.forEach((key) => {
		const error = get(errors, key, null);
		const hasError = isDefined(error);
		const touch = get(touched, key, null);
		const isTouched = isDefined(touch);

		if (isTouched && hasError) {
			errorList.push(error);
		}
	});

	if (errorList.length === 0) {
		return null;
	}

	return (
		<>
			{errorList.map((error, key) => (
				<FormMessage
					type="danger"
					key={key}
				>
					{error}
				</FormMessage>
			))}
		</>
	);
};
