import React, { ReactElement } from 'react';
import { Alert } from '@avast/react-ui-components';
import { Trans, useTranslation } from 'react-i18next';
import { getProductGroupException } from 'module/purchase/utils/common';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { TransactionTypeEnum } from 'module/licenses/enums';
import { TPriceListCode } from 'js/priceList';

type TExceptionsProps = {
	caption?: string;
	productCode?: string;
	transactionType: TransactionTypeEnum;
	priceListCode?: TPriceListCode;
};

export const ProductGroupExceptions = (props: TExceptionsProps): ReactElement | null => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { caption = null, productCode, transactionType, priceListCode } = props;
	const exception = getProductGroupException(productCode, transactionType, priceListCode);

	if (exception?.message) {
		return (
			<Alert
				variant={exception.variant}
				caption={caption}
			>
				<Trans t={t}>{exception.message}</Trans>
			</Alert>
		);
	}

	return null;
};
