export default {
	error: {
		closingBalanceNotFound: 'Saldo di chiusura non trovato.',
	},
	page: {
		detail: {
			title: 'Dettaglio n. {{id}}',
		},
	},
	tab: {
		detail: 'Vista dettagli',
		info: 'Informazioni',
		statement: 'Vista rendiconto',
	},
	entity: {
		amountWOTax: 'Importo senza tasse',
		amountWTax: 'Importo con tasse',
		companyTaxId: 'Partita IVA azienda',
		lineDetailId: 'ID dettaglio linea',
		orderStatusPlaceholder: 'Qualsiasi stato',
		paymentStatusPlaceholder: 'Qualsiasi stato',
		period: 'Periodo',
		term: 'Termine',
		partnerDiscount: 'Sconto partner',
		itemPriceWOTax: 'Prezzo elemento linea escl. tasse',
		fulfillmentCompleteDate: 'Data ordine',
		srpTotalPrice: 'Prezzo totale SRP',
	},
	actions: {
		downloadInvoice: 'Scarica fattura',
		export: {
			list: 'Esporta rendiconti',
			line: 'Esporta voci',
			lineDetail: 'Esporta licenze',
		},
		viewRefunds: 'Visualizza rimborsi',
	},
	common: {
		documents: 'Altri documenti',
		initiateRefund: 'Avvia rimborso',
	},
	statement: {
		saleUnitPrice: 'Prezzo unitario di vendita',
		pricingInfo: 'Info sui prezzi',
	},
};
