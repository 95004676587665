import { IEntityOmsOrder, IEntityOrder, IOrderBillableParty } from 'module/orders';
import { IEntityCustomer } from 'module/customers';
import { IEntityPartner } from 'module/partners';
import { IEntityDistributionPartner } from 'module/distributionPartners';
import { CustomerTypeEnum, DiscountTypeEnum } from 'module/customers/enums';
import { defaults, isNull, omitBy } from 'lodash';
import { normalizeStateName } from 'js/utils/normalize';
import { PartnerTypeEnum } from 'module/partners/enums';
import { BillablePartyTypeEnum } from 'module/orders/enums';
import { CustomerSegmentEnum } from 'module/purchase/enums';

export const extractBillablePartyEntityFromOrder = (order: IEntityOmsOrder): IOrderBillableParty | undefined => {
	if (!order.billableParty) {
		return order.billableParty;
	}

	// TODO: temporally fix until https://butr.avast.com/browse/CRM-15246 will be done
	let { countryCode } = order.billableParty;
	if (!countryCode && order.billableParty.country?.length === 2) {
		countryCode = order.billableParty.country;
	}

	return {
		...order.billableParty,
		countryCode,
	};
};

export const extractCustomerEntityFromOrder = (order: IEntityOmsOrder): IEntityCustomer | null => {
	const partner = extractPartnerEntityFromOrder(order);
	let countryCode = order.customer?.countryCode;

	// TODO: temporally fix until https://butr.avast.com/browse/CRM-15246 will be done
	if (!countryCode && order.customer?.country?.length === 2) {
		countryCode = order.customer?.country;
	}

	// Prepare Customer data
	const customer: IEntityCustomer = {
		id: order.customer?.id,
		salesforcePartnerId: order.emsPartnerId,
		partnerId: partner?.id,
		companyName: order.customer?.name,
		contactType: order.customer?.name ? CustomerTypeEnum.BUSINESS : CustomerTypeEnum.CONSUMER,
		firstName: order.customer?.firstName,
		lastName: order.customer?.lastName,
		email: order.customer?.email,
		phone: null,
		website: null,
		requestedDiscountType: null,
		billing: {
			street: order.customer?.address1,
			city: order.customer?.city,
			postalCode: order.customer?.postalCode,
			state: order.customer?.state,
			stateCode: null,
			country: order.customer?.country,
			countryCode: countryCode!,
			discountType: customerSegmentToDiscountType(order.customerSegment),
		},
	};

	switch (order.billableParty?.type) {
		case BillablePartyTypeEnum.END_CUSTOMER:
			return defaults(omitBy(extractCustomerEntityFromBillableParty(order), isNull), customer);
		default:
			return order.customer?.id ? customer : null;
	}
};

const customerSegmentToDiscountType = (customerSegment?: CustomerSegmentEnum): DiscountTypeEnum | undefined => {
	if (customerSegment) {
		switch (customerSegment) {
			case CustomerSegmentEnum.NOT_FOR_PROFIT:
				return DiscountTypeEnum.NOT_FOR_PROFIT;
			case CustomerSegmentEnum.EDUCATION:
				return DiscountTypeEnum.EDUCATION;
			case CustomerSegmentEnum.GOVERNMENT:
				return DiscountTypeEnum.GOVERNMENT;
			case CustomerSegmentEnum.COMMERCIAL:
			default:
				return undefined;
		}
	}
};

export const extractCustomerEntityFromBillableParty = (order: IEntityOmsOrder): IEntityCustomer => {
	let countryCode = order.billableParty?.countryCode;

	// TODO: temporally fix until https://butr.avast.com/browse/CRM-15246 will be done
	if (!countryCode && order.billableParty?.country?.length === 2) {
		countryCode = order.billableParty?.country;
	}

	return {
		id: null,
		salesforcePartnerId: null,
		companyName: null,
		contactType: null,
		firstName: order.billableParty?.firstName,
		lastName: order.billableParty?.lastName,
		email: order.billableParty?.email,
		phone: order.billableParty?.phoneNumber,
		billing: {
			street: order.billableParty?.address1,
			city: order.billableParty?.city,
			postalCode: order.billableParty?.postalCode,
			state: normalizeStateName(order.billableParty?.state),
			stateCode: order.billableParty?.stateCode,
			country: order.billableParty?.country,
			countryCode: countryCode ?? null,
		},
	};
};

export const extractPartnerEntityFromOrder = (order: IEntityOmsOrder): IEntityOrder['partner'] => {
	switch (order.billableParty?.type) {
		case BillablePartyTypeEnum.PARTNER:
			return defaults(
				omitBy(extractPartnerEntityFromBillableParty(order), isNull),
				extractPartnerEntityFromDistributionPartner(order),
			);

		case BillablePartyTypeEnum.DISTRIBUTOR:
			return defaults(
				omitBy(extractPartnerEntityFromBillableParty(order), isNull),
				extractPartnerEntityFromDistributor(order),
			);

		case BillablePartyTypeEnum.END_CUSTOMER:
		default:
			return null;
	}
};

export const extractDistributionPartnerEntityFromOrder = (
	order: IEntityOmsOrder,
): IEntityDistributionPartner | null => {
	switch (order.billableParty?.type) {
		case BillablePartyTypeEnum.DISTRIBUTOR:
			return Boolean(order.partner?.id)
				? {
						distributorId: order.distributor?.id,
						id: `${order.partner?.id}`,
						name: order.partner?.name,
						contact: {
							firstName: order.partner?.firstName,
							lastName: order.partner?.lastName,
							email: order.partner?.email,
						},
						billing: {
							street: order.partner?.address1,
							city: order.partner?.city,
							postalCode: order.partner?.postalCode,
							country: order.partner?.country,
							countryCode: order.partner?.countryCode!,
							state: normalizeStateName(order.partner?.state),
							stateCode: null,
						},
				  }
				: null;
		case BillablePartyTypeEnum.PARTNER:
		case BillablePartyTypeEnum.END_CUSTOMER:
		default:
			return null;
	}
};

const extractPartnerEntityFromBillableParty = (order: IEntityOmsOrder): IEntityPartner => ({
	id: order.billableParty?.id ? Number(order.billableParty?.id) : null,
	parentCompanyId: null,
	salesforceId: order.emsPartnerId,
	firstName: order.billableParty?.firstName,
	lastName: order.billableParty?.lastName,
	email: order.billableParty?.email,
	phone: order.billableParty?.phoneNumber,
	address1: order.billableParty?.address1,
	city: order.billableParty?.city,
	postalCode: order.billableParty?.postalCode,
	stateName: normalizeStateName(order.billableParty?.state),
	stateCode: order.billableParty?.stateCode,
	countryName: order.billableParty?.country,
	countryCode: order.billableParty?.countryCode,
	companyName: order.billableParty?.name,
	identifiers: {
		partyTaxId: order.billableParty?.taxId,
	},
});

const extractPartnerEntityFromDistributionPartner = (order: IEntityOmsOrder): IEntityPartner => ({
	parentCompanyId: null,
	id: order.partner?.id ? Number(order.partner?.id) : null,
	companyName: order.partner?.name,
	firstName: order.partner?.firstName,
	lastName: order.partner?.lastName,
	email: order.partner?.email,
	address1: order.partner?.address1,
	city: order.partner?.city,
	postalCode: order.partner?.postalCode,
	currencyCode: order.partner?.currency,
	accountType: PartnerTypeEnum.RESELLER,
	stateName: normalizeStateName(order.partner?.state),
	stateCode: null,
	countryName: order.partner?.country,
	countryCode: order.partner?.countryCode,
});

const extractPartnerEntityFromDistributor = (order: IEntityOmsOrder): IEntityPartner => ({
	parentCompanyId: null,
	id: order.distributor?.id || null,
	companyName: order.distributor?.name,
	firstName: order.distributor?.firstName,
	lastName: order.distributor?.lastName,
	email: order.distributor?.email,
	phone: null,
	address1: order.distributor?.address1,
	city: order.distributor?.city,
	postalCode: order.distributor?.postalCode,
	currencyCode: order.currency,
	accountType: PartnerTypeEnum.DISTRIBUTOR,
	stateName: order.distributor?.state,
	stateCode: null,
	countryName: order.distributor?.country,
	countryCode: order.distributor?.countryCode,
});
