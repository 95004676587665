import { IPartnerRequestParams } from 'module/purchase';
import { CACHE_TIME } from 'appConstants';
import { IEntityPartnerPriceList } from 'js/priceList';
import { TUseApiListModule, useApiListQuery } from 'js/queries/useApiListQuery';
import { API_PRICE_LIST_KEYS } from 'js/priceList/priceListConfig';
import { useCallback } from 'react';
import { priceListEntityNormalizer } from 'js/priceList/normalizer';
import type { AxiosResponse } from 'axios';
import { TApiListResponse } from 'types/api';

export const useApiPartnerPriceLists: TUseApiListModule<IEntityPartnerPriceList, IPartnerRequestParams> = (props) =>
	useApiListQuery(API_PRICE_LIST_KEYS.PARTNER_PRICE_LISTS, props, {
		queryConfig: {
			staleTime: CACHE_TIME.MEDIUM,
			select: useCallback(
				(response: AxiosResponse<TApiListResponse<IEntityPartnerPriceList>>) => ({
					...response,
					data: priceListEntityNormalizer.normalizeList(response.data),
				}),
				[],
			),
		},
	});
