export default {
	error: {
		closingBalanceNotFound: '找不到期末結餘！',
	},
	page: {
		detail: {
			title: '詳細資料 #{{id}}',
		},
	},
	tab: {
		detail: '詳細檢視檢視',
		info: '資訊',
		statement: '結單檢視',
	},
	entity: {
		amountWOTax: 'WO 稅額',
		amountWTax: 'W 稅額',
		companyTaxId: '公司稅號 ID',
		lineDetailId: '單項詳細資料 ID',
		orderStatusPlaceholder: '任何狀態',
		paymentStatusPlaceholder: '任何狀態',
		period: '期間',
		term: '期限',
		partnerDiscount: '合作夥伴折扣',
		itemPriceWOTax: '細目價格（不含稅）',
		fulfillmentCompleteDate: '訂單日期',
		srpTotalPrice: 'SRP 總價',
	},
	actions: {
		downloadInvoice: '下載發票',
		export: {
			list: '匯出結單',
			line: '匯出項目',
			lineDetail: '匯出授權',
		},
		viewRefunds: '檢視退款',
	},
	common: {
		documents: '訂單文件',
		initiateRefund: '啟動退款',
	},
	statement: {
		saleUnitPrice: '銷售單價',
		pricingInfo: '定價資訊',
	},
};
