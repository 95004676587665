import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum CustomerTypeEnum {
	CONSUMER = 'NATURAL_PERSON',
	BUSINESS = 'LEGAL_PERSON',
}

export const customerTypeEnumUtils = generateEnumUtils<CustomerTypeEnum>(CustomerTypeEnum, {
	translatePath: 'enums:customer.type',
});
