import { useCallback, useState } from 'react';
import { useApiRegistrationInit } from 'module/registration/hooks/useApiRegistration';
import { useRegistrationContext } from 'module/registration/context/RegistrationContext';
import { RouteName } from 'module/RouteName';
import { useTranslation } from 'react-i18next';
import { registrationConfig } from 'module/registration/registrationConfig';
import { createAxiosInstance } from 'api/setupInterceptors';
import { logDebug, logError } from 'js/utils/app';
import { apiErrorResolve, apiErrorWrapper } from 'js/utils/apiError';
import { useNavigate } from 'react-router-dom';
import { ApiErrorLevelEnum, ApiTypeEnum } from 'js/enums';
import { useAppContext } from 'js/contexts';

const getApiDigitalRiverSessionTokenPromise = () => {
	const api = createAxiosInstance({ apiType: ApiTypeEnum.DR });

	return api.get<{ session_token: string }>('/store/avgb2b/SessionToken?format=json', {
		withCredentials: false,
	});
};

export const useInitRegistration = () => {
	const [t] = useTranslation(registrationConfig.trNamespace);
	const { apiErrorModalRef } = useAppContext();
	const { partnerId, gcShopperId } = useRegistrationContext();
	const navigate = useNavigate();
	const {
		mutate: initRegistration,
		isError,
		isSuccess,
	} = useApiRegistrationInit({
		config: {
			params: { partnerId },
		},
	});
	const [isReady, setIsReady] = useState(false);

	const handler = useCallback(async () => {
		if (gcShopperId) {
			logDebug('1st step of the registration was skipped, because of existing GC shopper id', gcShopperId);
			setIsReady(true);
			return;
		}

		const token = await getApiDigitalRiverSessionTokenPromise().catch(async (e) => {
			logError('Unable to get DR session token', e);
			await apiErrorModalRef.current?.show({
				error: {
					level: ApiErrorLevelEnum.CRITICAL,
					messages: [t('error.unableToRegistrationToken')],
				},
			});
			navigate(RouteName.SECURITY.LOGOUT);
		});

		if (!token) {
			return;
		}

		initRegistration(
			{ sessionToken: token.data.session_token },
			{
				async onError(e) {
					logError('Unable to init registration', e);
					const error = apiErrorWrapper(e, apiErrorResolve);
					if (error) {
						await apiErrorModalRef.current?.show({ error });
					}
					navigate(RouteName.SECURITY.LOGOUT);
				},
			},
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		isLoading: isReady ? false : !isSuccess && !isError,
		handler,
	};
};
