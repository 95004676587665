import React, { FunctionComponent } from 'react';
import { ModuleContainer } from 'js/layouts/container/ModuleContainer';
import { creditLimitConfig } from 'module/creditLimit/creditLimitConfig';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { CreditLimitRoutes } from 'module/creditLimit/CreditLimitRoutes';

export const CreditLimitContainer: FunctionComponent = () => (
	<DefaultContainer>
		<ModuleContainer moduleMenuConfig={creditLimitConfig} />
		<CreditLimitRoutes />
	</DefaultContainer>
);
