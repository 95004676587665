import React, { createContext, PropsWithChildren, useContext } from 'react';

export interface IDataTableContext {
	isLoading?: boolean;
}

const DataTableContext = createContext<IDataTableContext>({});
DataTableContext.displayName = 'DataTableContext';

export const useDataTableContext = () => useContext(DataTableContext);

export const DataTableContextProvider = (props: PropsWithChildren<IDataTableContext>) => {
	const value: IDataTableContext = {
		isLoading: props.isLoading,
	};

	return <DataTableContext.Provider value={value}>{props.children}</DataTableContext.Provider>;
};
