export default {
	common: {
		allPartnerType: 'Tutti i partner',
		avastId: 'ID partner Avast',
		loadingDetail: 'Caricamento dettagli partner',
	},
	error: {
		unableToLoadTerms: 'Impossibile caricare Termini e Condizioni.',
		unableToLoadPartnerDetail: 'Impossibile caricare i dettagli del partner',
	},
	entity: {
		accountType: 'Tipo',
		accountSubType: 'Sottotipo',
		company: 'Società',
		email: 'Indirizzo e-mail',
		id: 'ID partner',
		salesforceId: 'ID Salesforce',
		name: 'Nome completo',
	},
	select: {
		title: 'Seleziona partner',
		button: {
			label: 'di dist.',
			placeholder: 'Seleziona partner',
		},
		filter: {
			placeholder: 'Trova partner',
		},
	},
};
