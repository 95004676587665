import { IEntityCustomer } from 'module/customers';
import { TCustomerSalesforceId, TPartnerId } from 'types';
import { useApiCustomer, useApiPartnerCustomer } from 'module/customers/hooks/useApiCustomers';
import { useEffect, useState } from 'react';
import { useAuthContext } from 'js/contexts';

type TUseCustomerDetailProps = {
	customerId?: TCustomerSalesforceId | null;
	partnerId?: TPartnerId | null;
	enabled?: boolean;
	onData?: (customer: IEntityCustomer | null) => void;
};

export const useCustomerDetail = (props: TUseCustomerDetailProps) => {
	const { customerId, partnerId, enabled, onData } = props;
	const { isGroupPartner } = useAuthContext();
	const [partnerCustomerFlag, setPartnerCustomerFlag] = useState(isGroupPartner);

	useEffect(() => {
		setPartnerCustomerFlag(Boolean(partnerId));
	}, [partnerId]);

	// Internal company request
	const internalCompanyQuery = useApiCustomer({
		filter: { salesforceId: customerId! },
		queryConfig: {
			enabled: Boolean(customerId) && !partnerCustomerFlag && enabled,
		},
	});

	// Partner request
	const partnerQuery = useApiPartnerCustomer({
		filter: { salesforceId: customerId!, partnerId: partnerId! },
		config: { catchError: isGroupPartner },
		queryConfig: {
			enabled: Boolean(customerId) && Boolean(partnerId) && partnerCustomerFlag && enabled,
			onError() {
				setPartnerCustomerFlag(false);
			},
		},
	});

	const query = partnerCustomerFlag ? partnerQuery : internalCompanyQuery;
	const { data: customer } = query;

	// Pass returned value by callback
	useEffect(() => {
		if (enabled) {
			onData?.(customer);
		}
	}, [customer, onData, enabled]);

	return query;
};
